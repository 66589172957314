import React, { type FC, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { usePaystackPayment } from 'react-paystack';

import { ifCondition } from '../../utils/functions';
import { useAppDispatch } from '../../utils/hooks/useRedux';
import { updateSlice } from '../../utils/services/AmountSlice';
export interface IPaymentPriceModalProps {
  voteCount: number
  setChoosePaymentToggle: () => void
  setSuccessToggle: () => void
}

const PaymentPriceModal: FC<IPaymentPriceModalProps> = ({
  voteCount,
  setChoosePaymentToggle,
  setSuccessToggle
}) => {
  const dispatch = useAppDispatch() as any;
  const [customPrice, setCustomPrice] = useState<number>(0);
  const [voteCounts, setVoteCounts] = useState<number>(0);
  const [currency, setCurrency] = useState<string>('USD');
  const [amount, setAmount] = useState<number>(0);
  const { h1: H1 } = motion;
  useEffect(() => {
    setVoteCounts(
      Math.floor(
        ifCondition(
          currency === 'USD',
          customPrice * 8,
          ifCondition(
            currency === 'NGN',
            (customPrice / 480) * 8,
            ifCondition(
              currency === 'GHS',
              (customPrice / 28) * 8,
              ifCondition(
                currency === 'EUR',
                (customPrice * 1.04) * 8,
                (customPrice / 680) * 8
              )
            )
          )
        )
      )
    );
    setAmount(
      ifCondition(
        currency === 'USD',
        customPrice,
        ifCondition(
          currency === 'NGN',
          customPrice / 480,
          ifCondition(
            currency === 'GHS',
            customPrice / 28,
            ifCondition(
              currency === 'EUR',
              (customPrice * 1.04),
              customPrice / 680
            )
          )
        )
      )
    );
  }, [customPrice, currency]);
  return (
    <div className="flex h-3/5 w-11/12 flex-col items-start gap-6">
      <AnimatePresence>
        {customPrice > 0 && (
          <H1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="self-center text-xl font-bold"
          >
            {`${voteCounts} Attestation Units`}
          </H1>
        )}
      </AnimatePresence>
      <div className="mt-auto w-full gap-3">
        <h2 className="text-base font-bold">Enter Your budget amount </h2>
        <div className="flex h-fit w-full">
          <label htmlFor="customPrice">
            <select
              value={currency}
              onChange={(e) => { setCurrency(e.target.value); }}
              className="flex h-full items-center justify-center bg-darkPurple px-4 text-xl text-white"
            >
              <option value="USD">USD</option>
              <option value="GHS">GHS</option>
              <option value="NGN">NGN</option>
              <option value="XOF">XOF</option>
              <option value="EUR">EUR</option>
            </select>
          </label>
          <input
            value={customPrice || ''}
            onChange={(e) => {
              if (e.target.value === '') {
                setCustomPrice(0);
              } else {
                setCustomPrice(
                  ifCondition(
                    currency === 'USD',
                    ifCondition(
                      parseInt(e.target.value) % 0.125 === 0,
                      parseInt(e.target.value),
                      customPrice
                    ),
                    parseInt(e.target.value)
                  )
                );
              }
            }}
            type="number"
            id="customPrice"
            inputMode="numeric"
            className="flex h-12 w-full items-center justify-center border-2 border-l-0 border-gray-300 px-4 outline-none"
          />
        </div>
      </div>
      <button
        className="mx-auto w-full cursor-pointer rounded bg-darkPurple px-6 py-6 text-center font-bold text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple"
        onClick={() => {
          if (currency === 'USD') {
            dispatch(
              updateSlice({
                voteCount: voteCounts,
                amount: Math.round(amount),
                metadata: 'USD'
              })
            );
          } else {
            dispatch(
              updateSlice({
                voteCount: voteCounts,
                amount: customPrice,
                metadata: currency
              })
            );
          }
          setChoosePaymentToggle();
        }}
      >
        Buy
      </button>
    </div>
  );
};

export default PaymentPriceModal;
