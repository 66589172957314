import React, { type FC, type ReactNode } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

interface LayoutProps {
  children: ReactNode
  title?: string
  image?: string
}

const Layout: FC<LayoutProps> = ({ children, title: pageTitle, image }) => {
  const router = useRouter();
  const locationArr = router.pathname.split('?')[0]?.split(/\/|\d/ig).filter((val) => val);
  return (
    <>
      <Head>
        <meta name="author" content="Onyela Udochukwuka" />
        <meta
          name="description"
          content="The Connected Awards is a globally recognized tech personality award program that celebrates excellence and innovation in the technology sector through regional editions across various continents."
        />
        <meta
          name="keywords"
          content="Connected awards, Techie, Tech Africa, West Africa, Tech personality"
        />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        <title>
          {pageTitle ? pageTitle : (locationArr.length > 0
            ? locationArr
              .at(-1)
              ?.split(/-|_/gi)
              .map((val) => val[0]?.toUpperCase() + val?.substring(1))
              .join(' ')
            : 'The Connected Awards')}
        </title>
        <meta property="og:url" content="https://theconnectedawards.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="The Connected Awards" />
        <meta
          property="og:description"
          content="The Connected Awards is a globally recognized tech personality award program that celebrates excellence and innovation in the technology sector through regional editions across various continents."
        />
        <meta
          property="og:image"
          content={"https://theconnectedawards.com/_next/image?url=%2Fog_image.jpg&w=3840&q=75"}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="theconnectedawards.com" />
        <meta
          property="twitter:url"
          content="https://theconnectedawards.com/"
        />
        <meta name="twitter:title" content="The Connected Awards" />
        <meta
          name="twitter:description"
          content="The Connected Awards is a globally recognized tech personality award program that celebrates excellence and innovation in the technology sector through regional editions across various continents."
        />
        <meta
          name="twitter:image"
          content={"https://theconnectedawards.com/_next/image?url=%2Fog_image.jpg&w=3840&q=75"}
        />

        <meta property="og:title" content="The connected awards" />
        <meta property="og:type" content="website" />
        <meta name="theme-color" content="#3E0563" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="google-site-verification" content="EE4HW-uLM1j_5jp2U9sl2pUeoE86eeTf6vBYJqYGSEM" />
      </Head>
      <main className="w-screen">{children}</main>
    </>
  );
};

export default Layout;
