import React, { type FC } from 'react';

import { type IconProps, type Props, type PropTypes } from '../../typescript';

const Lighting: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
        <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2997 0H4.29942L0.700562 12.9577H4.30675L1.24051 24H2.32069L12.7589 11.0417H7.47099L13.2997 0Z" fill="#FA9706" />
        </svg>

  );
};

export default Lighting;
