import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Winners: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
    {...props}
      viewBox="0 0 75 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 78.3813C7 91.0783 7.28101 101.466 7.6242 101.466C7.96738 101.466 14.7457 98.9264 22.6862 95.822C30.6275 92.7177 37.7631 90.1778 38.5423 90.1778C39.3223 90.1778 46.5914 92.7177 54.696 95.822C62.8006 98.9264 69.5598 101.466 69.7157 101.466C69.8723 101.466 70 91.0322 70 78.2788V55.0913L66.8914 58.2061C51.6322 73.4933 27.039 73.8138 10.9375 58.9346L7 55.2962V78.3813Z"
        fill="#3E0563"
      />
      <circle cx="37.5" cy="37.5" r="37.5" fill="#FA9706" />
      <g filter="url(#filter0_d_327_2592)">
        <circle cx="37.8826" cy="37.1174" r="26.4031" fill="white" />
      </g>
      <path
        d="M35.2151 17.3341C35.8138 15.4915 38.4207 15.4915 39.0194 17.3341L42.4243 27.8133C42.692 28.6373 43.4599 29.1952 44.3264 29.1952H55.3448C57.2823 29.1952 58.0878 31.6745 56.5204 32.8133L47.6063 39.2898C46.9053 39.799 46.612 40.7018 46.8797 41.5258L50.2846 52.005C50.8833 53.8476 48.7744 55.3799 47.2069 54.2411L38.2928 47.7646C37.5918 47.2553 36.6427 47.2553 35.9417 47.7646L27.0276 54.2411C25.4601 55.3799 23.3512 53.8476 23.9499 52.005L27.3548 41.5258C27.6225 40.7018 27.3292 39.799 26.6282 39.2898L17.7141 32.8133C16.1467 31.6745 16.9522 29.1952 18.8897 29.1952H29.9081C30.7746 29.1952 31.5425 28.6373 31.8102 27.8133L35.2151 17.3341Z"
        fill="#F89506"
      />
      <defs>
        <filter
          id="filter0_d_327_2592"
          x="7.47949"
          y="10.7144"
          width="60.8057"
          height="60.8062"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_327_2592"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_327_2592"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Winners;
