import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import { type IconProps } from '../../typescript';

const FeaturesTopLeft: FC<IconProps> = (props) => {
  return (
    <motion.svg
      initial={{ scale: 1.1, rotate: 12 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      {...props}
      // width="228"
      // height="202"
      viewBox="0 0 228 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M145.954 29.3927L145.943 29.3857L145.932 29.3797L135.172 23.3967L135.165 23.3927L135.157 23.3887L124.17 17.9737L124.163 17.9697L124.058 18.1747L124.146 17.9617L112.965 13.1547L112.956 13.1507L112.947 13.1477L101.622 9.01172L101.375 9.65973L123.856 18.5927L166.21 43.5017L185.282 58.9657L185.726 58.4327L176.404 50.4547L176.397 50.4487L176.39 50.4427L166.62 42.9407L166.477 43.1217L166.605 42.9307L156.45 35.9137L156.443 35.9087L156.435 35.9047L145.954 29.3927Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M122.876 70.5157L122.675 69.7128L122.244 70.4188L118.438 76.6438L115.55 66.5287L115.33 65.7598L114.919 66.4457L110.756 73.3907L110.569 73.7018L110.888 73.8748L118.147 77.7988L125.164 82.1667L131.903 86.9608L132.627 87.4758L132.443 86.6057L129.975 74.9438L129.797 74.1017L129.341 74.8328L125.495 81.0008L122.876 70.5157Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M202.262 76.3473L202.744 75.8483L185.749 58.4543L185.735 58.4403L185.719 58.4273L166.627 42.9473L166.175 43.4733L202.262 76.3473Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M129.93 75.1998L130.114 74.9048L129.819 74.7218L123.015 70.4868L123.026 70.4698L122.714 70.2998L121.996 69.8528L122.012 69.9188L115.382 66.3188L114.656 65.9248L114.883 66.7188L117.988 77.5948L118.205 78.3548L118.618 77.6798L122.404 71.4868L125.02 81.9628L125.218 82.7558L125.651 82.0618L129.93 75.1998ZM136.792 79.7848L136.641 78.8968L136.158 79.6568L131.811 86.4918L131.646 86.7528L131.886 86.9468L138.288 92.1418L139.004 92.7228L138.848 91.8148L136.792 79.7848Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.265 43.5315L166.584 42.9165L124.185 17.9815L124.153 17.9625L124.117 17.9505L78.7051 3.06055L78.4541 3.70654L166.265 43.5315Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M107.578 62.6755L107.146 62.4735L107.167 62.5345L100.369 59.8735L100.171 59.3105L99.9435 59.7065L99.5175 59.5395L99.7155 60.1035L95.7235 67.0315L95.5215 67.3825L95.8995 67.5285L103.33 70.3905L103.525 70.9435L103.744 70.5705L110.77 73.8195L110.957 74.4055L111.191 74.0145L111.624 74.2145L111.432 73.6125L115.343 67.0865L122.365 70.8995L129.327 75.2325L131.766 86.7495L131.941 87.5805L132.397 86.8635L136.26 80.7875L138.142 91.7975L138.057 91.9285L138.185 92.0465L138.314 92.8065L138.573 92.4075L144.266 97.6965L144.967 98.3475L144.846 97.3985L143.286 85.1365L143.387 84.9805L143.253 84.8725L143.15 84.0705L142.844 84.5435L136.91 79.7665L136.921 79.7495L136.762 79.6385L134.329 67.9635L134.154 67.1165L133.695 67.8505L129.777 74.1345L127.168 63.4975L126.97 62.6885L126.535 63.3995L122.659 69.7385L119.786 59.4635L119.569 58.6875L119.154 59.3785L115.08 66.1765L108.21 62.9695L108.223 62.9485L107.999 62.8605L107.812 62.2775L107.578 62.6755Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M202.248 76.3333L202.752 75.8563L194.557 66.9163L194.55 66.9083L194.542 66.9013L185.741 58.4463L185.253 58.9393L202.248 76.3333Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.284 68.2179L134.468 67.9239L134.173 67.7399L127.014 63.2859L126.291 62.8369L126.494 63.6639L129.299 75.0989L129.495 75.8979L129.93 75.1999L134.284 68.2179Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M123.901 18.6107L124.137 17.9587L101.631 9.01569L101.611 9.00769L101.59 9.00169L78.6842 3.05469L78.4893 3.72069L123.901 18.6107Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M126.708 64.4472L126.945 64.0602L133.68 68.2512L136.082 79.7762L135.991 79.9192L136.136 80.0352L136.285 80.7502L136.535 80.3562L142.599 85.2382L144.142 97.3592L144.062 97.4802L144.172 97.5982L144.276 98.4152L144.549 98.0022L149.687 103.521L149.757 104.39L150.067 103.929L150.448 104.339L150.378 103.466L154.894 96.7542L155.049 96.5232L154.858 96.3222L149.441 90.5952L149.453 90.5782L149.335 90.4702L149.266 89.6022L149.227 89.6602L147.874 78.9222L147.914 78.9542L147.787 78.1942L147.885 78.0442L147.749 77.9352L147.648 77.1332L147.618 77.1802L145.715 65.7552L145.566 64.8622L145.08 65.6262L141.064 71.9412L141.04 71.9792L138.787 60.9962L138.898 60.8192L138.729 60.7152L138.585 60.0102L138.308 60.4532L131.673 56.3262L131.683 56.3092L131.499 56.2082L131.339 55.5432L131.084 55.9602L130.662 55.6972L130.677 55.7582L124.057 52.1342L123.881 51.4922L123.635 51.9032L123.211 51.6702L123.387 52.3162L119.325 59.0942L118.896 58.8602L118.913 58.9212L112.395 55.8482L112.407 55.8272L112.185 55.7392L112.001 55.1492L111.764 55.5512L111.337 55.3502L111.355 55.4092L104.513 52.6862L104.318 52.1142L104.086 52.5162L103.665 52.3482L103.858 52.9122L99.9326 59.7252L92.6616 57.4392L92.4586 56.9072L92.2336 57.3042L91.8176 57.1732L92.0156 57.6912L88.0616 64.6892L87.8496 65.0652L88.2616 65.1922L95.7596 67.4872L95.9596 68.0112L96.1866 67.6182L96.6006 67.7452L96.4066 67.2362L100.246 60.5712L107.441 63.3882L107.634 63.9512L107.857 63.5712L114.931 66.8742L115.116 67.4672L115.353 67.0712L115.781 67.2712L115.594 66.6692L119.335 60.4282L122.206 70.6942L122.42 71.4612L122.836 70.7812L126.708 64.4472Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.465 43.5582L167.592 43.3722L166.551 42.9002L78.7401 3.0752L78.4131 3.6852L147.333 46.6362L147.444 46.7052L147.573 46.6832L166.465 43.5582Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M116.494 48.6496L116.264 47.8926L115.863 48.5746L111.604 55.8236L111.412 56.1516L111.755 56.3136L119.304 59.8706L120.011 60.2046L119.783 59.4566L116.494 48.6496ZM145.665 65.9976L145.843 65.7186L145.572 65.5276L138.619 60.6456L137.904 60.1446L138.08 60.9996L140.533 72.9576L140.705 73.7986L141.165 73.0746L145.665 65.9976ZM153.867 83.8936L154.031 83.6456L153.811 83.4456L147.656 77.8616L146.961 77.2306L147.079 78.1616L148.656 90.6766L148.774 91.6056L149.29 90.8236L153.867 83.8936Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.604 43.5027L166.352 42.8737L147.46 45.9987L146.618 46.1378L147.319 46.6257L154.415 51.5638L154.612 51.7007L154.809 51.5658L166.604 43.5027ZM101.415 9.67375L101.601 9.00476L90.1758 5.61375L90.1648 5.61075L90.1528 5.60776L78.6718 3.05176L78.5098 3.72676L101.415 9.67375Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M124.06 52.5446L124.256 52.2176L123.911 52.0536L116.313 48.4376L115.603 48.0996L115.832 48.8516L119.121 59.6586L119.348 60.4056L119.75 59.7356L124.06 52.5446ZM154.981 97.4596L155.032 97.5126L154.954 96.5406L154.922 95.4706L154.874 95.5416L153.925 83.6746L153.845 82.6716L153.29 83.5116L148.712 90.4416L148.56 90.6716L148.75 90.8706L154.165 96.5966L149.736 103.179L149.595 103.39L149.752 103.588L154.723 109.866L155.373 110.686L155.342 109.64L154.981 97.4596ZM92.5625 58.1336L99.9915 60.4696L100.667 60.6816L100.421 60.0186L96.8305 50.3316L96.5705 49.6306L96.2025 50.2816L92.2245 57.3226L84.7595 55.5816L84.1035 55.4286L84.3605 56.0516L88.0435 64.9916L88.3155 65.6516L88.6665 65.0306L92.5625 58.1336Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.733 43.3385L167.119 42.3105L166.213 42.9305L154.418 50.9935L154.026 51.2616L154.401 51.5535L161.185 56.8336L161.557 57.1235L161.723 56.6826L166.733 43.3385Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M85.0005 55.788L84.7255 55.121L84.3765 55.752L80.3715 62.976L80.1465 63.382L80.5995 63.483L88.2875 65.198L88.9375 65.344L88.6845 64.728L85.0005 55.788ZM152.324 70.875L152.224 70.072L151.914 70.55L145.686 65.619L143.267 53.632L143.094 52.776L142.633 53.517L138.565 60.04L135.978 49.09L135.784 48.27L135.345 48.989L131.323 55.568L128.481 44.959L128.27 44.168L127.849 44.871L123.463 52.189L123.277 52.498L123.594 52.671L131.022 56.738L138.227 61.22L145.053 66.013L147.057 78.043L146.97 78.177L147.099 78.294L147.226 79.057L147.491 78.65L153.344 83.96L154.037 84.589L153.921 83.66L152.358 71.142L152.46 70.983L152.324 70.875ZM100.394 60.312L104.387 53.382L111.624 56.263L111.814 56.834L112.05 56.432L112.467 56.598L112.281 56.04L116.46 48.926L116.664 48.58L116.292 48.429L108.73 45.369L108.537 44.788L108.302 45.196L107.885 45.027L108.074 45.593L104.075 52.533L96.6105 50.122L95.9305 49.903L96.1785 50.573L99.7685 60.26L100.025 60.953L100.394 60.312Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.754 43.1908L166.084 43.0938L161.073 56.4377L160.989 56.6628L161.17 56.8207L167.579 62.4218L168.216 62.9778L168.153 62.1348L166.754 43.1908Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M143.119 53.4108L142.416 52.9198L142.429 52.9818L136.117 49.0568L136.127 49.0408L135.817 48.8698L135.111 48.4308L135.124 48.4898L128.63 44.9178L128.64 44.9008L128.306 44.7398L127.602 44.3528L127.617 44.4088L120.786 41.1258L120.603 40.5078L120.36 40.9218L119.942 40.7208L120.122 41.3268L115.863 48.5748L115.672 48.9008L116.013 49.0638L123.472 52.6138L123.653 53.2218L123.897 52.8158L124.315 53.0148L124.137 52.4148L128.024 45.9288L130.862 56.5278L131.072 57.3088L131.493 56.6198L135.498 50.0698L138.082 61.0098L138.273 61.8198L138.714 61.1138L142.76 54.6238L145.005 65.7438L144.912 65.8898L145.057 66.0048L145.203 66.7248L145.459 66.3228L151.672 71.2428L153.218 83.6188L153.136 83.7428L153.242 83.8538L154.251 96.4668L154.175 96.5788L154.263 96.6868L154.647 109.661L154.679 110.725L155.28 109.846L159.691 103.404L160.046 103.843L160.018 102.927L160.1 102.807L160.011 102.697L159.645 90.5158L159.694 90.5668L159.626 89.7188L159.715 89.5878L159.613 89.4818L159.585 88.5378L159.538 88.6078L158.592 76.7418L158.691 76.5908L158.572 76.4838L158.503 75.6178L158.169 76.1228L152.321 70.8818L150.316 58.6848L150.423 58.5158L150.271 58.4098L150.147 57.6568L149.854 58.1178L143.396 53.6048L143.406 53.5888L143.119 53.4108Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.741 43.1182L166.062 43.2422L167.461 62.1872L167.471 62.3162L167.563 62.4082L173.531 68.3122L174.108 67.9672L166.741 43.1182Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M96.6668 50.8692L103.968 53.2262L104.163 53.7712L104.397 53.3652L104.802 53.4962L104.618 52.9832L108.602 46.0662L115.882 49.0112L116.07 49.5922L116.31 49.1842L116.721 49.3512L116.54 48.7922L120.635 41.8232L127.988 45.3572L135.344 49.4022L135.512 50.0462L135.755 49.6492L142.622 53.9192L142.779 54.5952L143.029 54.1942L149.633 58.8112L151.635 70.9832L151.546 71.1192L151.676 71.2362L151.802 72.0002L152.069 71.5882L157.902 76.8162L158.924 89.6452L159.318 102.721L154.708 109.454L154.576 109.646L154.704 109.841L158.909 116.25L159.507 117.162L159.546 116.072L160.011 102.937L164.712 96.0712L164.86 95.8552L164.694 95.6542L159.703 89.6052L164.307 82.7612L164.464 82.5272L164.267 82.3252L158.68 76.6082L163.269 69.6622L163.435 69.4102L163.209 69.2112L157.11 63.8122L157.12 63.7972L156.983 63.6892L156.884 62.8862L156.855 62.9302L154.977 51.3542L155.085 51.1842L154.932 51.0782L154.81 50.3242L154.515 50.7882L147.986 46.2452L147.995 46.2302L147.828 46.1262L147.686 45.4142L147.415 45.8482L147.009 45.5652L147.021 45.6242L140.64 41.6582L140.65 41.6432L140.468 41.5422L140.311 40.8672L140.051 41.2922L139.638 41.0362L139.651 41.0912L132.91 37.3682L132.738 36.7142L132.486 37.1342L132.071 36.9052L132.239 37.5462L128.259 44.1872L125.244 33.7652L125.02 32.9912L124.611 33.6862L120.351 40.9372L113.024 37.9222L112.833 37.3312L112.595 37.7452L112.183 37.5762L112.366 38.1422L108.293 45.2122L100.953 42.7792L100.754 42.2252L100.522 42.6362L100.116 42.5022L100.303 43.0232L96.3308 50.0532L89.0128 48.2712L88.8088 47.7582L88.5818 48.1672L88.1859 48.0702L88.3738 48.5422L84.4929 55.5422L77.2339 54.4902L77.0269 54.0212L76.8048 54.4282L76.4239 54.3732L76.6078 54.7902L72.7068 61.9582L72.4648 62.4012L72.9648 62.4682L80.4359 63.4622L80.6399 63.9232L80.8639 63.5192L81.2419 63.5702L81.0619 63.1622L84.8568 56.3172L92.1429 58.0162L92.3438 58.5212L92.5718 58.1162L92.9659 58.2082L92.7819 57.7452L96.6668 50.8692Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M139.989 42.0707L132.444 37.9027L132.435 37.8977L132.426 37.8927L78.4141 3.68468L78.4121 3.68367L78.7731 3.10867V3.10768L139.989 42.0707ZM139.989 42.0707L78.7731 3.10768L139.989 42.0707ZM139.989 42.0707L147.334 46.6357L147.7 46.0467V46.0457L78.7891 3.10168L78.7831 3.09767L139.989 42.0707ZM78.7731 3.10768L78.7811 3.09668L78.7731 3.10768ZM166.642 42.9597L166.387 43.2047L166.076 43.3147L173.443 68.1637L173.465 68.2367L173.515 68.2947L178.967 74.4877L179.548 74.1267L167.585 45.1557L223.782 112.058L224.344 111.656L202.793 75.9107L202.767 75.8677L202.73 75.8337L166.642 42.9597Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M132.909 37.778L133.104 37.453L132.763 37.288L125.062 33.55L124.361 33.209L124.577 33.958L127.814 45.145L128.035 45.91L128.445 45.227L132.909 37.778ZM164.252 109.407L164.388 109.212L164.255 109.015L159.956 102.63L159.36 101.744L159.322 102.812L158.853 116.048L158.811 117.225L159.484 116.258L164.252 109.407ZM164.393 83.447L164.441 83.496L164.366 82.549L164.333 81.48L164.286 81.549L163.325 69.443L163.245 68.438L162.69 69.279L157.947 76.459L157.793 76.692L157.989 76.892L163.574 82.608L158.983 89.432L158.839 89.647L159.003 89.847L164.159 96.095L164.804 96.878L164.773 95.864L164.393 83.447ZM100.845 43.474L108.344 45.96L109.011 46.181L108.78 45.517L105.266 35.411L105.014 34.686L104.636 35.354L100.512 42.654L93.1674 40.788L92.9654 40.261L92.7344 40.678L92.3444 40.579L92.5264 41.053L88.4544 48.398L88.2334 48.796L88.6754 48.903L96.2464 50.746L96.4444 51.264L96.6774 50.851L97.0654 50.946L96.8874 50.481L100.845 43.474Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.7832 3.09766L78.4062 3.68066L78.4113 3.68365L124.72 34.1507L124.739 34.1637L124.759 34.1737L132.46 37.9117L132.797 37.3067L78.7882 3.10066L78.7832 3.09766Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M161.725 56.3738L161.626 55.5698L161.313 56.0528L154.827 51.0048L154.13 50.4628L154.271 51.3338L156.293 63.7968L156.203 63.9358L156.334 64.0508L156.458 64.8168L156.728 64.4008L162.75 69.7298L163.435 70.3368L163.324 69.4278L161.758 56.6418L161.862 56.4808L161.725 56.3738ZM113.045 38.3548L113.255 37.9888L112.856 37.8528L105.05 35.1958L104.377 34.9668L104.611 35.6378L108.125 45.7448L108.375 46.4608L108.753 45.8038L113.045 38.3548ZM164.773 95.8868L164.814 94.6938L164.14 95.6788L159.382 102.628L159.249 102.822L159.381 103.017L163.68 109.403L164.278 110.29L164.314 109.221L164.773 95.8868Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M215.514 94.478L216.083 94.08L210.042 85.042L210.035 85.031L210.026 85.02L202.768 75.875L202.216 76.295L215.514 94.478Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.0602 48.7342L89.3012 48.3012L88.8112 48.2242L81.2942 47.0432L81.0882 46.5592L80.8622 46.9752L80.4862 46.9162L80.6652 47.3372L76.6902 54.6412L76.4512 55.0782L76.9452 55.1502L84.4372 56.2352L84.6392 56.7112L84.8682 56.2982L85.2402 56.3522L85.0662 55.9402L89.0602 48.7342ZM116.965 30.1602L116.558 29.9902L116.736 30.5572L112.444 38.0092L112.246 38.3522L112.612 38.5032L120.215 41.6312L120.401 42.2222L120.644 41.8082L121.05 41.9752L120.875 41.4152L125.21 34.0372L125.411 33.6952L125.045 33.5422L117.396 30.3412L117.207 29.7402L116.965 30.1602Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.7875 3.10059L78.3965 3.67358L116.912 30.8816L116.943 30.9036L116.978 30.9176L124.776 34.1816L125.1 33.5716L78.7875 3.10059Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M164.312 109.248L163.683 109.011L158.915 115.862L158.795 116.034L158.892 116.22L162.167 122.526L162.691 123.535L162.82 122.405L164.312 109.248ZM169.141 75.5759L169.3 75.3399L169.099 75.1379L163.225 69.2259L162.559 68.5549L162.634 69.4979L163.673 82.5869L164.08 95.8849L164.112 96.9479L164.713 96.0709L169.557 88.9959L169.706 88.7779L169.536 88.5759L164.453 82.5449L169.141 75.5759ZM77.2989 54.9719L77.5638 54.4859L77.0108 54.4599L69.6498 54.1149L69.0898 54.0889L69.3159 54.6009L72.6938 62.2639L72.9789 62.9099L73.3159 62.2899L77.2989 54.9719Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.722 43.0693L166.088 43.3493L178.906 74.3913L178.923 74.4323L178.949 74.4673L183.652 80.7353L184.244 80.3793L166.722 43.0693Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M168.142 61.993L168.074 61.1289L167.737 61.6389L161.626 56.2979L160.943 55.7019L161.053 56.6019L162.619 69.3859L162.534 69.5149L162.649 69.631L162.749 70.4479L163.034 70.0169L168.406 75.4249L163.731 82.3739L163.585 82.5909L163.754 82.7919L169.005 89.0229L169.648 89.7859L169.617 88.7889L169.227 76.2509L169.273 76.2979L169.2 75.3629L169.166 74.2949L169.12 74.3629L168.163 62.2529L168.264 62.0989L168.142 61.993ZM69.9507 54.3219L69.6637 53.6699L69.3277 54.2979L65.3577 61.7159L65.0947 62.2069L65.6527 62.226L72.9997 62.4709L73.5507 62.4889L73.3287 61.9839L69.9507 54.3219ZM169.107 102.432L169.245 102.235L169.108 102.037L164.712 95.6779L164.116 94.8159L164.08 95.8629L163.621 109.197L163.581 110.372L164.252 109.407L169.107 102.432Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.7968 3.1084L78.3809 3.6624L109.054 28.1284L109.1 28.1654L109.156 28.1844L116.998 30.9264L117.313 30.3154L78.7968 3.1084Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M105.1 35.9458L112.471 38.4548L112.662 39.0208L112.903 38.6018L113.298 38.7358L113.124 38.2188L117.414 30.7708L117.623 30.4088L117.228 30.2708L109.546 27.5848L109.352 27.0088L109.112 27.4338L108.717 27.2958L108.894 27.8208L104.769 35.1208L97.3966 33.1668L97.1966 32.6268L96.9616 33.0518L96.5766 32.9498L96.7536 33.4278L92.7236 40.6968L85.4276 39.4598L85.2246 38.9598L84.9926 39.3858L84.6236 39.3228L84.7956 39.7478L80.8506 46.9958L73.6976 46.5608L73.1406 46.5268L73.3566 47.0418L76.6756 54.9408L76.9546 55.6058L77.2996 54.9718L81.2376 47.7358L88.5106 48.8778L88.7096 49.3678L88.9436 48.9458L89.3106 49.0038L89.1416 48.5888L93.0816 41.4818L100.422 43.3458L100.619 43.8768L100.856 43.4568L101.239 43.5538L101.066 43.0858L105.1 35.9458ZM169.618 88.8108L169.658 87.6218L168.985 88.6038L164.141 95.6788L164.006 95.8758L164.142 96.0718L168.401 102.233L164.007 108.547L163.749 108.067L163.634 109.083L163.562 109.186L163.612 109.278L162.131 122.327L162.759 122.566L167.297 116.14L167.558 116.625L167.672 115.609L167.745 115.506L167.694 115.413L169.065 103.193L169.135 103.294L169.17 102.245L169.618 88.8108ZM174.064 68.2588L174.224 68.0208L174.02 67.8188L168.052 61.9138L167.389 61.2578L167.463 62.1878L168.508 75.4098L168.587 76.4008L169.142 75.5758L174.064 68.2588Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.8134 3.12012L78.3555 3.63911L101.195 25.9421L101.259 26.0041L101.345 26.0281L109.178 28.1911L109.487 27.5861L78.8134 3.12012Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M174.491 81.7921L174.641 81.5721L174.467 81.3691L169.117 75.1561L168.477 74.4131L168.508 75.3931L168.925 88.8111L168.958 89.8721L169.558 88.9961L174.491 81.7921Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.72 43.0625L166.094 43.3635L166.097 43.3685L183.616 80.6735L183.624 80.6915L183.635 80.7085L187.407 86.8215L188.014 86.4865L166.722 43.0685L166.72 43.0625Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M73.9963 46.7726L73.7143 46.1016L73.3703 46.7436L69.3283 54.2976L69.0693 54.7816L69.6183 54.8075L76.9783 55.1525L77.5273 55.1785L77.3143 54.6716L73.9963 46.7726ZM174.051 95.3286L174.19 95.1286L174.049 94.9296L169.554 88.5986L168.958 87.7605L168.924 88.7876L168.476 102.222L168.437 103.395L169.107 102.432L174.051 95.3286ZM174.122 68.0546L174.088 66.9786L173.488 67.8716L168.565 75.1886L168.418 75.4075L168.59 75.6086L173.941 81.8215L174.581 82.5656L174.55 81.5845L174.122 68.0546Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.5643 3.73595L78.6453 3.04695L67.5173 1.48395L67.5013 1.48195L67.4854 1.48096L57.1183 1.00195L57.0693 1.69395L78.5643 3.73595Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M167.663 115.621L172.571 108.669L172.697 108.492L172.591 108.302L169.126 102.065L168.599 101.117L168.478 102.195L167.002 115.354L162.192 122.165L162.085 122.316L162.151 122.488L164.485 128.648L164.918 129.79L165.15 128.592L167.663 115.621ZM109.573 28.0275L109.791 27.6405L109.363 27.5225L101.703 25.4065L101.506 24.8535L101.266 25.2855L100.887 25.1815L101.058 25.6615L96.9505 33.0705L89.6365 31.7355L89.4355 31.2205L89.1995 31.6555L88.8345 31.5895L89.0025 32.0185L84.8735 39.6025L84.6415 40.0295L85.1205 40.1105L92.6555 41.3885L92.8525 41.8935L93.0915 41.4625L93.4525 41.5235L93.2895 41.1045L97.3055 33.8605L104.673 35.8125L104.867 36.3565L105.109 35.9285L105.486 36.0285L105.318 35.5565L109.573 28.0275ZM69.9395 54.6245L70.2345 54.0735L69.6105 54.1145L63.1075 54.5475L62.9225 54.0835L62.6625 54.5765L62.3795 54.5955L62.4975 54.8895L58.6075 62.2685L58.3125 62.8275L58.9425 62.7755L65.4365 62.2455L65.6185 62.6995L65.8805 62.2085L66.1605 62.1865L66.0455 61.8995L69.9395 54.6245ZM174.549 81.6065L174.588 80.4185L173.917 81.3995L168.985 88.6035L168.848 88.8025L168.988 89.0005L173.483 95.3305L174.079 96.1705L174.113 95.1415L174.549 81.6065Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.8394 3.14258L78.3164 3.59457L93.3874 24.3756L93.4674 24.4866L93.6014 24.5126L101.37 26.0336L102.511 26.2566L101.679 25.4446L78.8394 3.14258Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M174.111 95.1686L173.481 94.9316L168.538 102.035L168.414 102.213L168.519 102.402L171.984 108.638L172.512 109.589L172.632 108.508L174.111 95.1686Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.851 112.12L224.314 111.612L166.675 42.9932L166.098 43.3692L187.392 86.7932L187.432 86.8742L187.506 86.9252L223.851 112.12Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M85.4827 39.9352L85.7397 39.4642L85.2047 39.4232L77.8187 38.8682L77.2637 38.8262L77.4707 39.3422L80.7267 47.4852L81.0007 48.1702L81.3537 47.5222L85.4827 39.9352ZM179.513 74.4552L179.665 74.2322L179.487 74.0302L174.036 67.8362L173.398 67.1122L173.428 68.0772L173.856 81.6072L173.89 82.6672L174.489 81.7922L179.513 74.4552Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.75 112.014L224.361 111.687L216.108 94.1258L216.094 94.0948L216.075 94.0677L202.776 75.8857L202.199 76.2688L223.75 112.014Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.1152 39.0849L77.8382 38.3929L77.4872 39.0499L73.4622 46.5709L67.1532 46.8909L66.9702 46.4059L66.7032 46.9139L66.4262 46.9279L66.5382 47.2259L62.5722 54.7489L62.2832 55.2979L62.9022 55.2569L69.4002 54.8249L69.5792 55.2989L69.8492 54.7948L70.1232 54.7759L70.0142 54.4859L73.8772 47.2669L81.0282 47.7019L81.5752 47.7349L81.3712 47.2269L78.1152 39.0849ZM169.675 122.317L169.879 122.831L170.089 121.738L170.155 121.646L170.122 121.564L172.614 108.609L177.614 101.529L177.741 101.348L177.631 101.156L174.176 95.1479L178.808 88.4939L179.116 88.9169L179.145 88.0099L179.227 87.8909L179.151 87.7879L179.574 74.2699L179.611 73.0849L178.941 74.0629L174.194 80.9949L173.889 80.5769L173.86 81.4829L173.778 81.6019L173.853 81.7039L173.42 95.1059L171.952 108.343L167.423 114.758L167.222 114.251L167.01 115.342L166.945 115.435L166.977 115.515L164.469 128.46L165.091 128.727L169.675 122.317ZM101.74 25.8619L101.972 25.4449L101.504 25.3529L93.9252 23.8689L93.7272 23.3389L93.4852 23.7829L93.1262 23.7129L93.2872 24.1459L89.0792 31.8769L88.8502 32.2979L89.3222 32.3839L96.8772 33.7619L97.0722 34.2829L97.3162 33.8429L97.6722 33.9069L97.5142 33.4849L101.74 25.8619Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M85.9364 23.5155L86.0124 23.7135L86.2244 23.7355L93.6314 24.5185L94.4064 24.5995L93.9494 23.9695L78.8784 3.1875L78.2734 3.5155L85.9364 23.5155Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M178.88 74.248L178.458 87.783L173.481 94.933L173.356 95.113L173.465 95.304L176.919 101.31L172.367 107.757L172.165 107.271L171.961 108.331L171.894 108.426L171.927 108.507L169.436 121.458L164.527 128.324L164.436 128.451L164.471 128.604L165.845 134.564L166.517 134.579L170.08 121.75L174.728 115.251L174.932 115.744L175.135 114.681L175.203 114.587L175.169 114.505L177.496 102.312L177.559 102.423L177.665 101.456L182.751 94.253L182.881 94.069L182.766 93.876L179.213 87.911L184.215 80.725L184.358 80.519L184.207 80.319L179.504 74.051L178.911 73.26L178.88 74.248ZM89.6892 32.209L89.9412 31.745L89.4152 31.697L82.2342 31.05L82.0402 30.541L81.7892 31.01L81.4652 30.981L81.6072 31.352L77.5812 38.874L71.2742 39.091L71.0942 38.584L70.8192 39.106L70.5472 39.116L70.6542 39.419L66.6112 47.089L66.3272 47.628L66.9352 47.597L73.4352 47.267L73.6112 47.764L73.8892 47.244L74.1582 47.23L74.0542 46.936L77.9922 39.577L84.9332 40.099L85.1232 40.598L85.3762 40.132L85.6972 40.156L85.5602 39.795L89.6892 32.209ZM63.1862 55.072L63.5272 54.426L62.8102 54.571L57.0072 55.743L56.8442 55.263L56.5452 55.836L56.3212 55.881L56.4002 56.114L52.5002 63.607L52.1582 64.264L52.8822 64.106L58.6862 62.836L58.8452 63.305L59.1462 62.735L59.3682 62.687L59.2912 62.461L63.1862 55.072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M79.151 23.4046L79.166 23.7356H79.497L86.26 23.7366H86.765L86.584 23.2656L78.921 3.2666L78.251 3.4066L79.151 23.4046Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M171.069 128.317L171.207 128.876L171.543 127.66L171.601 127.58L171.584 127.512L175.143 114.634L174.526 114.34L169.918 120.784L169.782 120.233L169.445 121.446L169.387 121.526L169.404 121.593L165.848 134.393L166.464 134.689L171.069 128.317ZM184.275 80.5643L183.645 80.3293L178.516 87.6983L178.389 87.8823L178.503 88.0743L182.17 94.2313L182.701 95.1233L182.812 94.0903L184.275 80.5643ZM93.9721 24.3383L94.2211 23.8823L93.7041 23.8273L86.2971 23.0453L85.7461 22.9873L85.9341 23.5083L89.0581 32.1603L89.3201 32.8863L89.6891 32.2083L93.9721 24.3383Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.6003 3.73799L78.6303 3.04601L57.1343 1.00299L57.0963 1L57.0573 1.005L38.9053 3.377L38.9533 4.06799L78.6003 3.73799Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M67.2251 47.412L67.5581 46.779L66.8551 46.909L60.757 48.034L60.3721 48.105L60.4901 48.479L62.5481 55.015L62.7981 55.808L63.1861 55.072L67.2251 47.412ZM86.5861 23.272L86.3221 22.54L85.9541 23.2271L81.6811 31.2121L81.4341 31.674L81.9561 31.721L89.3531 32.388L89.8951 32.437L89.7101 31.925L86.5861 23.272Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.732 111.982L224.368 111.706L220.9 103.031L220.893 103.013L220.883 102.996L216.099 94.1094L215.48 94.4214L223.732 111.982Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M179.876 108.052L180.081 108.523L180.276 107.492L180.346 107.394L180.31 107.312L182.794 94.1923L187.986 86.8403L188.118 86.6533L187.997 86.4583L184.225 80.3453L183.694 79.4843L183.585 80.4903L182.132 93.9273L177.403 100.625L177.2 100.16L177.004 101.19L176.934 101.288L176.969 101.369L174.471 114.463L170.908 127.355L165.901 134.283L165.828 134.385L165.837 134.51L166.222 140.21L166.894 140.306L171.535 127.672L176.645 120.6L176.742 120.467L176.699 120.308L175.186 114.61L179.876 108.052ZM61.1507 48.2713L60.8987 47.4723L60.5127 48.2153L56.5307 55.8643L51.2617 57.8253L50.9697 57.9343L51.0467 58.2353L52.4717 63.8523L52.6947 64.7343L53.1147 63.9273L57.0077 56.4503L62.9477 55.2513L63.3257 55.1743L63.2097 54.8063L61.1507 48.2713ZM82.2927 31.5403L82.5707 31.0193L81.9807 31.0293L75.4727 31.1433L75.2967 30.6133L75.0127 31.1513L74.7457 31.1553L74.8487 31.4633L70.7247 39.2843L70.4457 39.8133L71.0437 39.7933L77.5457 39.5693L77.7177 40.0883L78.0037 39.5533L78.2687 39.5443L78.1687 39.2463L82.2927 31.5403Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M73.0879 24.0786L72.9648 24.5706L73.4678 24.5067L79.5409 23.7327L79.8578 23.6926L79.8438 23.3736L78.9438 3.37465L78.2608 3.30664L73.0879 24.0786Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.7959 58.3663L56.5259 56.6062L56.6479 57.1413L57.0229 56.4212L57.1819 56.3623L57.1429 56.1903L61.0499 48.6853L66.6719 47.6483L66.8239 48.1713L67.1459 47.5613L67.3579 47.5223L67.2899 47.2883L70.9259 40.3913L71.3389 39.6083L71.6649 38.9893L75.4359 31.8373L81.7329 31.7273L81.9019 32.2693L82.1969 31.7193L82.4559 31.7153L82.3609 31.4113L86.5659 23.5543L86.8389 23.0433H86.2599L79.7519 23.0423L79.5799 22.4893L79.2879 23.0423H79.0259L79.1229 23.3553L74.9999 31.1763L69.0909 32.0463L68.7059 32.1033L68.8069 32.4793L70.5979 39.1663L65.1879 40.0663L65.0329 39.5312L64.7139 40.1443L64.4989 40.1803L64.5689 40.4213L60.5759 48.0933L55.6929 49.8173L55.5679 49.2693L55.1959 49.9923L55.0339 50.0493L55.0749 50.2273L51.4939 57.1773L51.0739 57.9913L50.6639 58.7872L47.0929 65.7193L46.6719 66.5353L47.5269 66.2012L52.9339 64.0903L53.2189 63.9783L53.1439 63.6813L51.7959 58.3663ZM179.623 107.212L179.57 107.286L174.526 114.34L174.431 114.472L174.473 114.63L175.987 120.327L170.944 127.304L170.869 127.408L170.874 127.46L166.243 140.067L166.849 140.391L171.977 133.353L172.054 133.249L172.049 133.196L176.312 121.55L176.358 121.726L176.682 120.549L181.463 113.932L181.608 114.44L181.919 113.302L181.981 113.217L181.961 113.148L185.27 101.042L185.327 101.166L185.515 100.165L185.587 100.064L190.81 92.7603L190.929 92.5932L190.841 92.4093L188.015 86.4913L187.557 85.5323L187.375 86.5022L182.533 93.3593L182.33 92.9153L182.141 93.9153L182.069 94.0163L182.106 94.0972L179.623 107.212Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.1469 1.69293L57.0789 1.00293L47.5418 1.62494L47.5179 1.62694L47.4948 1.63094L38.8809 3.38194L38.9948 4.06494L57.1469 1.69293Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M69.4765 32.2995L69.2455 31.4375L68.8335 32.2285L64.6335 40.2975L64.3125 40.9135L64.9975 40.8005L71.0885 39.7875L71.4655 39.7255L71.3665 39.3555L69.4765 32.2995ZM172.012 133.294L171.417 132.945L166.288 139.981L166.235 140.055L166.224 140.145L165.589 145.514L166.249 145.701L172.012 133.294Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.874 112.135L224.245 111.55L187.9 86.3553L186.827 85.6113L187.389 86.7903L190.215 92.7083L190.261 92.8053L190.355 92.8593L223.874 112.135Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.6908 58.3089L52.2498 57.2239L55.6868 50.5549L60.5598 48.8349L60.6747 49.4049L61.0648 48.6559L61.2198 48.6009L61.1848 48.4259L65.2487 40.6179L65.6447 39.8579L64.8318 40.1289L59.8398 41.7909L59.7218 41.2079L59.3347 41.9599L59.1768 42.0119L59.2137 42.1939L55.1788 50.0249L50.6738 52.6399L50.4807 52.7529L50.5027 52.976L51.0148 57.9659L47.0428 60.3529L46.9677 59.7679L46.5027 60.6769L46.3928 60.743L46.4088 60.8609L42.4528 68.5959L41.8857 69.7049L42.9448 69.049L47.1258 66.4569L47.1988 67.0279L47.6648 66.1219L47.7737 66.0549L47.7588 65.9409L51.1568 59.3459L51.6908 58.3089ZM171.482 138.544L177.225 126.152L177.283 126.073L177.278 126.02L181.911 113.314L186.811 106.532L186.959 107.015L187.257 105.915L187.321 105.826L187.3 105.757L190.863 92.6489L190.246 92.3569L185.315 99.2519L185.17 98.7769L184.87 99.8739L184.807 99.963L184.827 100.031L181.283 112.998L176.083 120.194L176.006 120.3L176.011 120.352L171.388 132.984L171.362 133.02L171.358 133.055L165.619 145.409L166.214 145.76L171.423 138.656L171.478 138.58L171.482 138.544ZM79.8038 23.5509L80.1147 22.9609L79.4528 23.0449L73.6868 23.7799L73.5408 23.1869L73.1998 23.8419L72.9937 23.8679L73.0558 24.1189L68.8338 32.2289L68.5208 32.8309L69.1918 32.7319L74.9628 31.8829L75.1048 32.4629L75.4488 31.8109L75.6518 31.7809L75.5927 31.5389L79.8038 23.5509Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.91 112.153L224.22 111.534L190.701 92.2576L189.918 91.8066L190.198 92.6656L192.052 98.3386L192.101 98.4886L192.245 98.5496L223.91 112.153Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.794 131.299L182.555 118.84L182.614 118.758L187.968 111.411L188.051 111.298L188.032 111.16L187.302 105.854L192.663 98.4355L192.766 98.2925L192.711 98.1245L190.858 92.4515L190.497 91.3485L190.193 92.4676L186.621 105.612L181.32 112.948L181.241 113.057L181.247 113.11L176.616 125.812L176.588 125.85L176.585 125.885L170.843 138.275L165.654 145.351L165.623 145.394L165.606 145.444L163.914 150.4L164.543 150.684L171.434 138.641L176.734 131.413L176.791 131.335L176.794 131.299ZM51.1941 52.9055L51.0741 51.7386L50.5401 52.7826L46.4851 60.7126L42.7681 64.2025L42.6671 64.2975L42.6631 64.3625L38.6611 72.2275L39.2101 72.6356L43.0021 69.0056L43.1011 68.9106L43.1041 68.8475L47.0301 61.1705L51.5621 58.4475L51.7511 58.3345L51.7281 58.1155L51.1941 52.9055ZM69.4501 32.5495L69.8351 31.8105L69.0391 32.0585L64.0691 33.6105L63.9581 32.9885L63.5551 33.7706L63.4011 33.8185L63.4341 34.0035L59.3181 41.9935L54.8421 44.4975L54.6481 44.6055L54.6661 44.8275L55.0961 50.3015L55.1911 51.5175L55.7501 50.4335L59.8221 42.5295L64.6711 40.9146L64.7791 41.5215L65.1841 40.7435L65.3361 40.6935L65.3041 40.5145L69.4501 32.5495Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M223.958 112.17L224.184 111.516L192.519 97.913L191.941 97.665L192.039 98.286L192.887 103.656L192.922 103.879L193.14 103.937L223.958 112.17Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M192.708 98.3495L192.1 98.0285L186.658 105.56L186.577 105.672L186.596 105.81L187.344 111.253L188.014 111.324L192.708 98.3495ZM55.3569 44.7715L55.2609 43.5485L54.7029 44.6405L50.5679 52.7265L46.8869 56.1115L46.7859 56.2035L46.7759 56.3395L46.4199 60.8965L47.0749 61.0815L51.1109 53.1885L55.6149 50.5735L55.8039 50.4635L55.7869 50.2455L55.3569 44.7715ZM73.7319 24.3225L74.1059 23.6035L73.3269 23.8295L68.3799 25.2655L68.2769 24.6045L67.8579 25.4175L67.7079 25.4605L67.7379 25.6505L63.4879 33.8985L63.1079 34.6355L63.8999 34.3885L68.8609 32.8395L68.9619 33.4855L69.3829 32.6765L69.5309 32.6305L69.5019 32.4475L73.7319 24.3225ZM169.804 143.538L169.837 143.493L169.843 143.474L176.755 131.379L176.175 131.001L170.863 138.246L170.831 138.29L170.825 138.309L163.941 150.338L164.521 150.716L169.804 143.538Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M226.349 128.257L227.038 128.176L226.464 120.191L226.462 120.161L226.455 120.133L224.384 111.751L223.705 111.89L226.349 128.257Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.15 124.025L182.209 123.945L182.212 123.908L187.989 111.383L193.51 103.806L193.595 103.69L193.572 103.547L192.724 98.1776L192.055 98.1136L187.379 111.042L181.974 118.458L181.915 118.538L181.913 118.574L176.14 131.062L176.734 131.412L182.15 124.025ZM47.4673 56.3936L46.8123 56.2096L42.6963 64.2966L43.2433 64.7076L47.0043 61.1766L47.1023 61.0846L47.1123 60.9506L47.4673 56.3936ZM64.1053 34.2166L64.6173 33.2236L63.6323 33.7516L59.0933 36.1896L58.8993 36.2946L58.9113 36.5146L59.2323 42.2636L59.3033 43.5356L59.8873 42.4026L64.1053 34.2166Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M73.4152 24.5099L73.6931 24.517L73.7611 24.2469L78.9341 3.47495L79.0832 2.87695L78.4941 3.05995L16.4521 22.369L16.5471 23.047L73.4152 24.5099Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.6046 36.4773L59.5327 35.1973L58.9486 36.3383L54.7036 44.6413L54.1816 45.6613L55.1816 45.1023L59.7487 42.5473L59.9377 42.4413L59.9256 42.2253L59.6046 36.4773Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.7001 3.72195L78.5941 3.04395L38.9471 3.37495L38.8221 3.37595L38.7261 3.45695L16.3311 22.436L16.6591 23.0309L78.7001 3.72195ZM224.011 112.18L224.137 111.5L193.319 103.267L192.9 103.155L192.883 103.588L192.685 108.585L192.672 108.91L192.996 108.944L224.011 112.18Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M175.195 136.212L175.226 136.171L175.232 136.151L182.162 124.009L187.675 116.491L187.736 116.408L187.738 116.371L193.545 103.747L192.949 103.397L187.408 111.002L187.348 111.084L187.346 111.121L181.57 123.643L176.175 131.002L176.141 131.048L176.135 131.067L169.234 143.143L163.963 150.305L163.955 150.316L163.948 150.327L161.16 154.776L161.522 155.015L161.733 155.166L167.08 147.906L167.088 147.896L167.091 147.891L175.195 136.212ZM44.0217 60.4323L42.6917 64.3013L39.8997 68.5403L39.8747 68.5783L39.8687 68.5972L35.8027 76.5983L36.4017 76.9453L39.2597 72.5743L39.2847 72.5372L39.2907 72.5193L43.3147 64.6123L47.4017 56.5802L51.0827 53.1962L51.1807 53.1062L51.1867 53.0403L55.3197 44.9573L54.7797 44.5413L51.0887 47.8603L50.9877 47.9503L50.9817 48.0193L46.8217 56.1922L44.0607 60.3533L44.0357 60.3902L44.0217 60.4323ZM68.4067 25.8683L68.9017 24.9062L67.9397 25.4013L63.9167 27.4673L63.8887 26.6763L63.3307 27.7683L63.2347 27.8183L63.2387 27.9483L58.9487 36.3383L58.4427 37.3283L59.4217 36.8023L63.4687 34.6283L63.4957 35.3993L64.0557 34.3133L64.1507 34.2613L64.1457 34.1363L68.4067 25.8683Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.061 112.181L224.083 111.49L193.068 108.253L192.778 108.223L192.698 108.504L191.407 113.048L191.277 113.509L191.755 113.489L224.061 112.181Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M180.688 128.747L180.723 128.7L180.729 128.68L187.687 116.474L193.311 108.804L193.374 108.718L193.378 108.612L193.576 103.615L192.914 103.457L187.095 116.109L181.591 123.615L181.556 123.662L181.55 123.681L174.611 135.839L175.192 136.216L180.688 128.747ZM44.6769 60.6574L44.0399 60.3873L39.8799 68.5744L40.4789 68.9223L43.2949 64.6453L43.3189 64.6094L43.3329 64.5673L44.6769 60.6574ZM59.5659 36.6543L59.0279 36.2354L55.3749 39.4393L55.2749 39.5273L55.2669 39.5983L51.0109 47.9603L51.5519 48.3754L55.2429 45.0573L55.3409 44.9704L55.3489 44.9023L59.5659 36.6543Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.1055 4.03143L38.8255 3.39844L31.2545 6.30943L31.2215 6.32243L31.1915 6.34145L24.8125 10.4504L25.1565 11.0524L39.1055 4.03143Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M172.536 140.499L172.544 140.488L172.547 140.484L180.694 128.739L180.13 128.336L174.633 135.805L174.625 135.816L174.623 135.82L166.516 147.503L167.08 147.906L172.536 140.499ZM51.6289 48.2748L51.032 47.9238L48.3059 51.9968L48.282 52.0338L48.2669 52.0748L46.7939 56.2518L47.4309 56.5248L51.6289 48.2748Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M222.51 142.438L223.192 142.541L227.027 128.291L227.046 128.219L227.034 128.146L224.39 111.779L223.701 111.821L222.51 142.438Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M186.296 121.126L186.332 121.077L186.338 121.057L193.333 108.77L192.751 108.393L187.115 116.08L187.08 116.129L187.074 116.148L180.107 128.37L180.688 128.747L186.296 121.126ZM48.921 52.3057L48.285 52.0327L44.04 60.3867L44.638 60.7357L47.41 56.5587L47.434 56.5227L47.448 56.4827L48.921 52.3057ZM63.897 28.1827L63.363 27.7617L59.75 30.8467L59.649 30.9327L59.632 31.0637L58.913 36.4497L59.566 36.6537L63.897 28.1827Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.1 112.178L224.033 111.488L191.727 112.796L191.541 112.804L191.444 112.962L189.012 116.963L188.625 117.599L189.36 117.487L224.1 112.178Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.3199 31.1561L59.6669 30.9531L55.2949 39.5431L55.8329 39.9611L59.4859 36.7571L59.5839 36.6711L59.6009 36.5421L60.3199 31.1561ZM178.103 132.941L178.111 132.93L178.114 132.925L186.302 121.118L185.738 120.715L180.13 128.336L180.121 128.347L180.119 128.352L171.971 140.096L172.536 140.5L178.103 132.941Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.0782 26.0555L68.1382 26.0595L68.1952 26.0425L73.5211 24.4965L73.4332 23.8165L16.5652 22.3535L16.5352 23.0465L68.0782 26.0555Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.9131 39.8579L55.3172 39.5059L52.6402 43.4699L52.6161 43.5059L52.6082 43.5259L48.2852 52.0329L48.8831 52.3829L51.6082 48.3099L51.6321 48.2749L51.6392 48.2559L55.9131 39.8579ZM192.02 113.348L192.057 113.298L192.074 113.238L193.365 108.693L192.73 108.427L185.716 120.749L186.297 121.126L192.02 113.348Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.124 112.173L223.995 111.492L189.256 116.801L189.15 116.817L189.072 116.889L185.447 120.246L184.499 121.123L185.759 120.839L224.124 112.173Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M183.786 125.226L183.795 125.214L183.797 125.21L192.026 113.341L191.462 112.937L185.738 120.715L185.729 120.727L185.727 120.731L177.539 132.538L178.103 132.941L183.786 125.226ZM60.2853 31.2681L59.6893 30.915L57.0643 34.764L57.0403 34.799L57.0253 34.84L55.2793 39.58L55.9133 39.858L60.2853 31.2681Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.5506 28.3695L63.6546 28.3815L63.7476 28.3335L68.2576 26.0175L69.3876 25.4365L68.1186 25.3625L16.5766 22.3535L16.5176 23.0445L63.5506 28.3695Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.6761 35.0788L57.0412 34.8018L52.6182 43.5068L53.2151 43.8578L55.8922 39.8938L55.9152 39.8587L55.9301 39.8197L57.6761 35.0788Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.1735 3.9861L38.7935 3.41211L24.8455 10.4321L24.7675 10.4711L24.7175 10.5421L16.2715 22.5001L16.7795 22.9651L39.1735 3.9861ZM222.521 142.361L223.176 142.583L225.97 135.796L225.986 135.755L225.991 135.712L227.034 128.249L226.356 128.111L222.521 142.361ZM224.134 112.17L223.97 111.496L185.605 120.162L185.559 120.172L185.518 120.194L180.65 122.796L180.901 123.437L224.134 112.17Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M189.587 117.349L189.596 117.337L189.604 117.324L192.037 113.323L191.456 112.945L183.221 124.822L183.786 125.226L189.587 117.349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9102 31.4515L60.0742 31.4825L60.2012 31.3745L63.8142 28.2895L64.4212 27.7715L63.6282 27.6815L16.5942 22.3555L16.4893 23.0405L59.9102 31.4515Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M0.731171 58.4572L0.0871887 58.2012L-3.74283 66.6522L-3.75583 66.6822L-3.76382 66.7152L-5.84082 75.3662L-5.17581 75.5602L0.731171 58.4572Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.136 112.17L223.96 111.499H223.959L180.728 122.766L180.721 122.768L174.574 124.493L174.569 124.495L174.751 125.164L174.756 125.162L224.135 112.17H224.136ZM57.2511 35.2914L57.494 35.3644L57.6371 35.1554L60.2631 31.3064L60.5601 30.8704L60.0421 30.7704L16.6221 22.3594L16.4561 23.0324L57.2511 35.2914Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M193.783 179.13L193.301 178.637L186.487 183.895L186.43 183.815L186.169 184.14L185.937 184.319L185.979 184.377L180.218 191.544L180.702 192.034L187.596 186.645L187.651 186.722L187.913 186.398L188.143 186.218L188.103 186.162L193.783 179.13ZM4.64194 49.8283L3.99796 49.5713L0.0869446 58.2013L0.072937 58.2323L0.0699463 58.2483L-5.83105 75.3333L-5.18805 75.5903L-1.27805 67.0063L-1.26407 66.9753L-1.26007 66.9583L4.64194 49.8283Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.285 10.9419L24.748 10.5049L19.739 15.8499L19.706 15.8839L19.684 15.9269L16.248 22.5399L16.839 22.8999L25.285 10.9419Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M199.752 171.739L199.272 171.245L192.258 176.582L192.028 176.757L192.159 177.015L193.204 179.069L193.447 179.546L193.783 179.13L199.752 171.739Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.6749 39.8832L56.0219 40.0312L56.1409 39.6742L57.679 35.0692L57.7929 34.7302L57.45 34.6272L16.6549 22.3682L16.4189 23.0192L55.6749 39.8832Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.6355 41.0138L7.99149 40.7578L3.9985 49.5708L3.98349 49.6028L3.97549 49.6368L2.0625 58.0138L2.72949 58.2038L8.6355 41.0138Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M191.029 158.621L191.473 159.127L223.017 142.759L223.196 142.666L223.203 142.464L224.394 111.848L224.44 110.664L223.763 111.636L191.029 158.621Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.71567 58.2355L2.73065 58.2026L2.73868 58.1686L4.65167 49.7915L3.98566 49.6016L-1.91632 66.7326L-5.81934 75.3026L-5.84933 75.3685L-5.85034 75.4415L-5.98633 85.2986L-5.30032 85.3775L-1.26334 66.9715L2.71567 58.2355ZM192.777 176.7L192.534 176.222L192.198 176.64L186.143 184.172L186.626 184.664L193.725 179.186L193.953 179.011L193.822 178.754L192.777 176.7ZM205.849 164.191L205.371 163.696L198.447 168.883L198.244 169.035L198.328 169.275L199.155 171.636L199.361 172.224L199.752 171.739L205.849 164.191Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.2958 45.2099L55.7778 45.4859L55.8138 44.9309L56.1578 39.5859L56.1738 39.3419L55.9488 39.2449L16.6918 22.3809L16.3838 23.0009L55.2958 45.2099Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.7152 32.0109L12.0713 31.7559L7.99225 40.7579L7.97723 40.7909L7.97324 40.8069L2.07324 57.9789L2.71625 58.2359L6.79825 49.2729L6.81323 49.2399L6.81726 49.2239L12.7152 32.0109ZM186.684 184.607L186.269 184.074L177.094 188.3L176.634 188.512L176.998 188.864L180.247 192.01L180.52 192.275L180.759 191.978L186.684 184.607ZM198.982 169.046L198.776 168.457L198.385 168.943L192.197 176.64L192.678 177.134L199.692 171.797L199.893 171.645L199.81 171.407L198.982 169.046Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M191.002 158.972L191.261 159.501L191.598 159.018L224.332 112.033L224.871 111.259L223.959 111.499L174.574 124.493L174.165 124.601L174.351 124.981L191.002 158.972Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.73849 58.1647L2.08447 57.9476L-1.91052 66.7176L-1.94153 66.7857V66.8237L-5.97852 85.2297L-5.32452 85.4487L-1.31451 76.7486L-1.28253 76.6796V76.6416L2.73849 58.1647ZM177.48 188.366L177.205 188.1L176.967 188.399L170.988 195.916L171.407 196.445L180.635 192.074L181.088 191.86L180.728 191.511L177.48 188.366ZM212.076 156.48L211.6 155.982L204.773 161.013L204.591 161.147L204.64 161.368L205.239 164.049L205.396 164.75L205.848 164.191L212.076 156.48ZM16.8835 22.8116L16.2385 22.5566L12.0705 31.7547L12.0555 31.7887L12.0475 31.8257L10.3145 39.8956L10.9825 40.0806L16.8835 22.8116Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.2274 51.2544L56.8994 51.7304L56.7704 50.9174L55.8105 44.8554L55.7845 44.6904L55.6395 44.6084L16.7274 22.3994L16.3555 22.9834L56.2274 51.2544Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.9703 40.1131L10.9863 40.0791L10.9943 40.0421L12.7263 31.9721L12.0593 31.7861L6.1543 49.0171L6.79831 49.2731L10.9703 40.1131ZM192.738 177.075L192.326 176.541L183.211 180.614L182.779 180.807L183.092 181.16L186.154 184.619L186.426 184.927L186.684 184.607L192.738 177.075Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M211.208 152.996L211.65 153.529L218.375 148.663L218.414 148.635L218.444 148.597L223.129 142.666L222.618 142.199L211.208 152.996Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.367 161.437L211.534 156.894L211.561 157.119L212.049 156.514L212.176 156.421L212.169 156.366L218.442 148.6L217.968 148.101L211.72 152.621L211.692 152.387L211.205 152.994L211.077 153.086L211.084 153.144L205.161 160.513L204.765 161.006L195.862 164.715L195.479 164.875L195.704 165.223L198.142 169.004L189.715 172.646L189.609 172.507L189.385 172.789L189.059 172.929L189.163 173.067L183.338 180.391L183.081 180.714L177.042 188.305L165.984 190.951L165.843 190.825L165.689 191.021L165.162 191.148L165.414 191.373L159.613 198.79L158.989 199.589L159.972 199.34L171.166 196.513L171.303 196.635L171.459 196.439L171.978 196.308L171.735 196.091L177.459 188.895L186.559 184.704L186.983 184.509L186.673 184.159L183.925 181.054L192.358 177.287L192.46 177.421L192.686 177.14L193.008 176.996L192.908 176.864L198.624 169.752L198.898 169.412L205.787 164.251L205.966 164.116L205.917 163.897L205.367 161.437ZM2.05301 58.0179V58.0549L-1.95999 76.4929L-1.991 76.5609L-5.95401 85.1589L-6 85.2589L-5.98001 85.3669L-3.92599 96.3849L-3.62299 96.3719L-3.272 96.4689L0.862 87.6759L0.910004 87.5729L0.89801 87.5109L2.81 67.8019L2.81799 67.7849L6.97699 58.7629L7.01001 58.6909L7.009 58.6509L10.986 40.0789L15.236 30.7479L15.252 30.7119L15.26 30.6739L16.895 22.7699L16.227 22.5879L10.332 39.8409L6.16699 48.9859L6.134 49.0569L2.08499 57.9479L2.05301 58.0179Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9023 22.7249L16.2163 22.6299L14.5803 30.5339L14.5723 30.5759L14.5743 30.6199L14.9873 39.8209L15.6803 39.8299L16.9023 22.7249Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.2592 30.6759L14.6042 30.4609L10.3392 39.8259L10.3052 39.8989L10.3073 39.9379L6.32324 58.5449L6.97723 58.7629L11.2632 49.4639L11.2982 49.3889L11.2962 49.3499L15.2592 30.6759ZM183.623 181.147L184.237 180.376L183.276 180.592L171.95 183.144L171.33 183.283L171.783 183.729L176.996 188.862L177.271 189.133L177.511 188.831L183.623 181.147ZM196.287 164.848L196.025 164.441L195.724 164.82L189.331 172.857L189.74 173.391L198.793 169.479L199.168 169.317L198.946 168.973L196.287 164.848Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M191.154 158.512L191.406 159.154L211.539 153.582L211.622 153.559L211.685 153.5L223.095 142.703L222.697 142.143L191.154 158.512Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.00705 58.6512L6.34708 58.4732L2.15607 67.5662L2.10706 67.6702L2.12006 67.7312L0.208069 87.4362L-3.89893 96.1742L-3.96094 96.3062L-3.91095 96.4432L0.579071 108.542L1.25107 108.415L0.896057 87.6042L5.08905 78.6842L5.13907 78.5782L5.12607 78.5162L7.00705 58.6512ZM172.269 183.235L171.992 182.962L171.753 183.268L165.71 190.996L152.701 191.897L151.911 191.952L152.487 192.495L159.648 199.257L159.925 199.518L160.16 199.218L166.086 191.64L177.32 188.953L177.928 188.807L177.483 188.368L172.269 183.235ZM211.716 153.465L212.545 152.435L211.317 152.926L202.693 156.377L202.59 156.188L202.322 156.525L202.045 156.636L202.122 156.777L195.724 164.82L196.129 165.356L204.825 161.733L204.924 161.915L205.194 161.579L205.468 161.465L205.395 161.33L211.716 153.465ZM189.874 173.289L190.474 172.535L189.531 172.733L178.235 175.112L177.638 175.238L178.051 175.686L183.097 181.166L183.371 181.464L183.623 181.147L189.874 173.289ZM15.6481 39.9512L15.6841 39.8742L15.6801 39.7902L15.2661 30.5892L14.5811 30.5322L10.6091 49.2472L11.2631 49.4642L15.6481 39.9512Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M191.034 159.023L191.626 158.667L174.975 124.676L174.869 124.46L174.63 124.483L167.158 125.211L166.555 125.269L166.911 125.76L191.034 159.023Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.2939 49.3498L10.6339 49.1729L6.34692 58.4728L6.29791 58.5798L6.31091 58.6398L4.43591 78.4428L0.233917 87.3809L0.170898 87.5148L0.201904 87.5959L0.557922 108.427L1.21692 108.572L5.49792 99.7129L5.5639 99.5779L5.53192 99.4948L5.1239 78.6098L9.41391 69.4828L9.4649 69.3748L9.4509 69.3128L11.2939 49.3498ZM178.562 175.216L178.285 174.916L178.033 175.238L171.753 183.268L171.154 184.034L172.102 183.82L183.428 181.269L184.014 181.137L183.607 180.696L178.562 175.216ZM152.963 191.991L152.683 191.726L152.45 192.032L146.464 199.868L145.99 200.488L146.768 200.425L159.915 199.35L160.691 199.287L160.125 198.752L152.963 191.991ZM196.267 165.251L196.853 164.515L195.929 164.695L184.668 166.892L184.092 167.004L184.468 167.455L189.336 173.295L189.609 173.622L189.874 173.289L196.267 165.251Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.8974 22.6406L16.2094 22.6746L14.9874 39.7806L14.9824 39.8536L15.0074 39.9226L23.0184 62.1076L23.6864 61.9316L16.8974 22.6406ZM167.471 125.352L167.373 125.217L167.206 125.209L158.678 124.828L157.805 124.789L158.414 125.416L191.064 159.061L191.594 158.616L167.471 125.352Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M172.3 183.696L172.772 183.092L172.008 183.136L158.989 183.868L158.818 183.696L158.673 183.886L158.064 183.92L158.393 184.253L152.45 192.032L151.985 192.642L152.75 192.589L165.754 191.688L165.919 191.855L166.067 191.666L166.668 191.625L166.35 191.304L172.3 183.696ZM15.6796 39.8362L15.0186 39.6602L10.6336 49.1732L10.5836 49.2832L10.5986 49.3441L8.76062 69.2432L4.46262 78.3882L4.39761 78.5251L4.4306 78.6061L4.83862 99.4861L0.592621 108.27L0.515625 108.431L0.600616 108.587L7.52161 121.233L8.17062 121.024L5.54361 99.6222L9.87961 90.6491L9.94662 90.5111L9.9126 90.4272L9.44962 69.4102L13.8406 60.0692L13.8926 59.9582L13.8766 59.8951L15.6796 39.8362ZM185.001 167.011L184.726 166.681L184.462 167.019L178.034 175.238L177.448 175.987L178.379 175.791L189.675 173.412L190.239 173.293L189.87 172.851L185.001 167.011Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M211.354 152.913L191.419 158.43L158.912 124.933L158.832 124.85L158.718 124.832L149.519 123.337L148.275 123.135L149.24 123.945L191.09 159.085L191.305 158.855L191.374 159.161L202.597 157.154L202.632 157.148L202.665 157.135L211.575 153.57L211.354 152.913ZM56.7689 50.911L56.7439 50.77L56.6279 50.688L16.7559 22.417L16.2539 22.872L66.7449 111.313L67.3879 111.081L56.7689 50.911Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.001 192.453L153.391 191.942L152.75 191.897L138.505 190.888L138.313 190.686L138.181 190.865L137.48 190.816L137.894 191.252L132.134 199.016L131.752 199.531L132.392 199.569L146.582 200.417L146.768 200.613L146.903 200.436L147.595 200.477L147.194 200.055L153.001 192.453ZM202.808 157.029L203.38 156.31L202.475 156.472L191.462 158.441L191.319 158.25L191.122 158.501L190.697 158.577L190.876 158.816L184.461 167.019L183.888 167.751L184.8 167.573L195.849 165.418L195.988 165.602L196.187 165.352L196.606 165.27L196.434 165.041L202.808 157.029ZM178.58 175.665L179.043 175.073L178.292 175.105L165.268 175.661L165.097 175.476L164.945 175.675L164.359 175.7L164.669 176.035L158.575 184.013L158.118 184.612L158.87 184.57L171.882 183.837L172.048 184.017L172.202 183.819L172.78 183.787L172.481 183.463L178.58 175.665ZM12.5859 112.331L12.6679 112.166L12.6109 112.065L9.92392 90.5568L14.3639 81.3698L14.4309 81.2298L14.3959 81.1458L13.8739 59.9948L18.3689 50.4328L18.4229 50.3188L18.3909 50.1978L15.6689 39.7178L14.9879 39.7738L13.1859 59.8288L8.78592 69.1878L8.72092 69.3268L8.75491 69.4088L9.2179 90.4218L4.8739 99.4118L4.79492 99.5748L4.84991 99.6728L7.4809 121.108L7.62091 122.249L8.13589 121.221L12.5859 112.331Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M149.686 123.413L149.627 123.363L149.552 123.343L139.852 120.778L139.559 121.393L191.109 159.098L191.536 158.553L149.686 123.413ZM59.1449 57.6605L59.1409 57.6285L59.1299 57.5985L56.7539 50.8555L56.0859 51.0305L66.7039 111.193L66.7049 111.2L67.39 111.087L67.3879 111.08L59.1449 57.6605Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6149 112.102L12.4069 111.139L11.9649 112.02L7.51492 120.911L7.4209 121.099L7.54391 121.269L16.6239 133.823L17.2439 133.546L12.6149 112.102ZM159.127 184.434L159.512 183.93L158.88 183.878L144.449 182.69L143.577 182.618L144.163 183.268L152.468 192.475L152.747 192.785L153.001 192.453L159.127 184.434Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M139.968 120.834L139.928 120.805L139.88 120.788L129.869 117.214L129.559 117.829L191.12 159.108L191.518 158.54L139.968 120.834Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M185.007 167.446L185.462 166.865L184.724 166.886L171.533 167.262L170.804 167.282L171.281 167.835L178.044 175.678L178.32 175.998L178.58 175.665L185.007 167.446ZM144.678 182.804L144.394 182.489L144.142 182.829L138.067 191.018L137.691 191.526L138.321 191.571L152.701 192.589L153.559 192.65L152.983 192.01L144.678 182.804ZM17.1418 103.228L17.2248 103.061L17.1668 102.96L14.3948 81.1756L14.2478 80.0156L13.7388 81.0686L9.25482 90.3466L9.1748 90.5116L9.23181 90.6106L11.9308 112.218L12.0728 113.354L12.5858 112.331L17.1418 103.228Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.66 39.6875L14.998 39.8925L17.72 50.3715L17.727 50.4005L17.739 50.4275L23.029 62.1335L23.671 61.8725L15.66 39.6875Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.4016 50.2747L17.7415 50.1367L13.2115 59.7737L13.1445 59.9147L13.2076 60.0587L18.3246 71.8577L18.9895 71.7097L18.4016 50.2747Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M129.945 117.252L129.92 117.236L129.893 117.223L119.77 112.72L119.443 113.33L191.12 159.108L191.127 159.112L191.507 158.531L191.5 158.527L129.945 117.252ZM63.046 65.0547L63.04 64.9868L63.008 64.9258L59.109 57.5518L58.46 57.7668L66.704 111.194L67.392 111.109L63.046 65.0547Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.9557 71.8712L19.0247 71.7272L18.9617 71.5822L13.8447 59.7832L13.1797 59.9302L13.7057 81.2282L14.3647 81.3702L18.9557 71.8712ZM171.807 167.382L171.528 167.058L171.269 167.398L164.847 175.804L164.398 176.392L165.138 176.361L178.322 175.798L179.038 175.767L178.57 175.225L171.807 167.382ZM138.625 191.431L138.954 190.988L138.412 190.884L123.114 187.947L122.14 187.76L122.785 188.514L132.15 199.448L132.433 199.778L132.692 199.429L138.625 191.431Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M119.816 112.745L119.804 112.738L119.792 112.731L109.766 107.395L109.419 107.996L191.127 159.112L191.129 159.114L191.5 158.527L191.497 158.525L119.816 112.745Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M123.312 188.063L123.024 187.727L122.767 188.087L116.922 196.244L116.604 196.689L117.142 196.787L132.352 199.565L133.313 199.74L132.677 198.998L123.312 188.063Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M109.787 107.408L109.786 107.407L100.068 101.351L100.067 101.35H100.066L99.6992 101.938V101.939L191.13 159.114L191.314 158.82L191.498 158.526L191.497 158.525L191.493 158.523L109.787 107.408Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.8451 124.891L21.9451 124.699L21.8611 124.585L17.1701 103L16.9621 102.038L16.5211 102.918L11.9651 112.02L11.8701 112.21L11.9521 112.322L16.5661 133.693L16.7691 134.632L17.2131 133.78L21.8451 124.891ZM165.398 176.225L165.777 175.728L165.155 175.669L150.674 174.304L149.82 174.224L150.378 174.875L158.588 184.449L158.867 184.775L159.127 184.434L165.398 176.225ZM191.587 159.033L192.033 158.463L191.309 158.473L178.115 158.652L177.404 158.661L177.85 159.216L184.463 167.45L184.737 167.791L185.007 167.447L191.587 159.033Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.3835 72.7857L68.3865 72.6727L68.3225 72.5787L62.9855 64.8897L62.2305 63.8027L62.3555 65.1207L66.7015 111.174L67.3935 111.15L68.3835 72.7857Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M150.905 174.424L150.622 174.094L150.363 174.443L144.142 182.829L143.77 183.33L144.392 183.382L158.823 184.569L159.664 184.638L159.114 183.998L150.905 174.424ZM21.8702 124.63L21.6232 123.817L21.2302 124.57L16.5972 133.459L16.4862 133.673L16.6482 133.852L27.6792 146.038L28.2682 145.704L21.8702 124.63ZM21.8072 93.9066L21.8922 93.7375L21.8322 93.6355L18.9872 71.6745L18.8372 70.5195L18.3302 71.5685L13.7392 81.0685L13.6582 81.2355L13.7162 81.3345L16.4882 103.117L16.6312 104.248L17.1422 103.228L21.8072 93.9066Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.8569 22.5283L16.2139 22.7593L23.0029 62.0503L23.0199 62.1473L23.0859 62.2213L66.7879 111.372L67.3479 110.969L16.8569 22.5283Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6572 62.1416L23.7272 61.9956L23.6612 61.8476L18.3712 50.1416L17.7092 50.2936L18.2962 71.7286L18.9552 71.8706L23.6572 62.1416ZM178.391 158.781L178.113 158.436L177.844 158.788L171.268 167.398L170.826 167.976L171.553 167.955L184.744 167.579L185.441 167.56L185.004 167.015L178.391 158.781ZM144.699 183.242L145.024 182.804L144.489 182.696L129.223 179.618L129.014 179.365L128.882 179.549L128.144 179.4L128.596 179.948L122.91 187.884L107.379 183.192L107.156 182.904L107.031 183.087L106.234 182.846L106.743 183.504L101.164 191.605L100.893 192L101.352 192.135L116.998 196.746L117.214 197.026L117.342 196.847L118.135 197.081L117.636 196.437L123.2 188.67L138.158 191.542L138.36 191.787L138.494 191.606L139.226 191.747L138.786 191.213L144.699 183.242ZM26.5902 115.787L26.6912 115.593L26.5592 115.418L17.1092 102.865L16.4922 103.147L21.1992 124.804L21.4022 125.741L21.8452 124.891L26.5902 115.787Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M74.9152 80.4982L74.9582 80.3252L74.8422 80.1902L68.3012 72.5522L67.7142 71.8662L67.6912 72.7682L66.7002 111.132L67.3842 111.224L74.9152 80.4982Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.9748 157.217L33.1418 136.976L33.1838 136.899L33.0698 136.775L26.6178 115.539L21.8618 93.7981L26.5868 84.3591L26.6728 84.1871L26.5718 84.0231L18.9388 71.5391L18.2988 71.7641L21.1398 93.6911L16.5208 102.918L16.4248 103.11L16.5538 103.282L25.8728 115.661L21.2298 124.57L21.1178 124.786L21.2288 124.907L27.5618 145.767L27.5008 145.879L27.6318 145.998L27.8448 146.698L28.0298 146.358L40.4188 157.599L40.9748 157.217ZM171.819 167.819L172.193 167.329L171.58 167.263L157.194 165.728L157.002 165.493L156.855 165.692L156.214 165.624L156.576 166.068L150.363 174.443L149.996 174.937L150.609 174.995L164.945 176.346L165.131 176.574L165.281 176.378L165.914 176.437L165.564 176.007L171.819 167.819Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.47 88.0552L82.625 87.8172L82.422 87.6182L74.821 80.1682L74.386 79.7422L74.241 80.3332L66.71 111.059L67.338 111.33L82.47 88.0552Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.8708 95.2736L91.2488 95.0156L90.8979 94.7216L82.4019 87.5996L82.1018 87.3486L81.8889 87.6766L66.7559 110.952L67.2419 111.428L90.8708 95.2736ZM163.565 156.907L163.505 157.598L178.079 159.343L178.102 159.346L178.125 159.345L191.318 159.166L191.333 158.473L163.565 156.907Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M45.9582 148.342L45.7042 147.8L45.5852 148.008L32.9932 136.693L32.4372 137.076L40.2692 157.314L40.1992 157.435L40.3642 157.56L40.5922 158.147L40.7582 157.856L54.5242 168.232L55.0472 167.808L46.0642 148.569L46.1132 148.482L45.9582 148.342ZM112.895 174.571L112.114 174.329L112.609 174.986L106.887 183.295L106.618 183.686L107.073 183.824L122.839 188.587L123.055 188.874L123.185 188.692L123.962 188.927L123.478 188.283L129.316 180.135L129.596 179.744L129.136 179.602L113.246 174.679L113.024 174.384L112.895 174.571ZM31.4512 106.461L31.5532 106.265L31.4182 106.089L21.7722 93.541L21.1582 93.826L25.9432 115.701L26.1482 116.636L26.5902 115.787L31.4512 106.461Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M99.9796 101.977L100.663 101.78L100.087 101.363L90.8795 94.7075L90.6815 94.5645L90.4806 94.7025L66.8516 110.855L67.1436 111.474L99.9796 101.977Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.0089 128.046L38.1278 127.827L37.9579 127.644L26.5359 115.39L25.9499 115.728L32.4289 137.052L32.6699 137.844L33.0659 137.117L38.0089 128.046ZM75.5168 168.616L75.2238 168.144L74.9289 168.614L69.8409 176.698L60.2538 159.091L59.9638 158.558L59.6519 159.078L54.4359 167.777L54.2608 168.069L54.5489 168.249L69.3409 177.494L69.3239 177.52L69.6488 177.686L70.7559 178.378L70.6599 178.202L85.3669 185.707L86.4759 186.273L85.8188 185.215L75.5168 168.616ZM26.6148 84.1288L26.4048 83.1729L25.9659 84.0479L21.1869 93.5968L21.0898 93.7908L21.2219 93.9629L30.8679 106.512L31.4818 106.226L26.6148 84.1288ZM178.396 159.209L178.765 158.726L178.162 158.654L163.587 156.909L162.766 156.811L163.272 157.466L171.269 167.82L171.545 168.178L171.819 167.819L178.396 159.209Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M100.067 101.35L99.935 101.268L99.787 101.311L66.951 110.808L65.958 111.095L66.923 111.465L191.189 159.143L191.497 158.525L100.067 101.35Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M107.593 183.493L113.177 175.384L128.821 180.231L129.036 180.525L129.17 180.339L129.931 180.575L129.462 179.931L135.316 171.76L150.444 174.962L150.646 175.226L150.788 175.035L151.484 175.182L151.076 174.647L157.175 166.424L171.507 167.953L172.315 168.039L171.818 167.396L163.82 157.042L163.54 156.679L163.267 157.047L156.87 165.671L141.65 162.29L141.443 162.018L141.303 162.213L140.6 162.057L141.02 162.608L135.031 170.967L119.258 165.956L119.037 165.652L118.905 165.843L118.14 165.6L118.62 166.258L112.909 174.55L97.038 167.999L96.805 167.657L96.678 167.85L95.87 167.517L96.392 168.283L91.11 176.296L81.055 159.82L80.764 159.342L80.466 159.816L75.246 168.11L65.601 150.178L65.311 149.641L64.998 150.164L59.989 158.517L51.209 139.563L51.259 139.475L51.103 139.336L50.851 138.791L50.73 139.003L38.14 127.805L43.074 118.751L43.194 118.53L43.021 118.346L31.565 106.242L36.432 96.9026L36.535 96.7047L36.446 96.5907L31.539 74.4647L31.571 74.3997L31.499 74.2857L31.301 73.3907L31.139 73.7137L23.638 61.8057L23.001 62.0357L25.919 84.1437L25.868 84.2447L25.945 84.3427L26.08 85.3697L26.148 85.2346L30.777 106.252L25.975 115.467L25.861 115.684L26.028 115.864L37.28 127.934L32.456 136.785L32.325 137.026L32.529 137.209L45.233 148.624L45.22 148.647L45.385 148.771L45.612 149.359L45.642 149.307L54.419 168.102L54.695 168.693L55.031 168.133L59.935 159.955L69.515 177.55L69.798 178.07L70.113 177.569L75.22 169.453L85.23 185.581L85.516 186.042L85.814 185.589L91.217 177.394L92.036 177.815L91.943 177.662L106.945 183.774L107.171 184.106L107.3 183.918L108.107 184.247L107.593 183.493Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6268 61.7891L23.0508 62.1761L30.8798 74.6051L30.8908 74.6221L30.9028 74.6371L40.9598 87.1631L41.5118 86.744L23.6268 61.7891ZM131.583 147.792L131.427 148.467L163.451 157.588L163.488 157.598L163.526 157.6L191.294 159.166L191.375 158.478L131.583 147.792Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M113.455 174.981L119.185 166.66L134.95 171.669L135.165 171.971L135.301 171.78L136.047 172.017L135.592 171.375L141.6 162.99L156.812 166.368L157.013 166.643L157.159 166.445L157.839 166.596L157.446 166.059L163.824 157.461L164.139 157.036L163.625 156.916L148.098 153.3L147.891 153.017L147.748 153.218L147.062 153.058L147.466 153.61L141.317 162.193L125.422 157.013L125.201 156.701L125.066 156.897L124.317 156.652L124.782 157.31L118.919 165.823L102.895 159.12L102.663 158.772L102.533 158.969L101.74 158.637L102.248 159.401L96.8237 167.629L86.7317 150.803L86.4417 150.319L86.1407 150.796L80.7837 159.309L71.0807 141.042L70.7927 140.5L70.4767 141.026L65.3357 149.6L56.4817 130.332L56.5337 130.242L56.3767 130.103L56.1257 129.557L56.0027 129.774L43.2057 118.508L48.2657 109.222L48.3877 108.999L48.2117 108.815L36.5467 96.6821L41.5377 87.1061L41.6417 86.9051L41.5007 86.7291L31.4437 74.2031L30.8347 74.4951L35.7577 96.6951L30.8347 106.14L30.7207 106.36L30.8907 106.539L42.3437 118.64L37.3987 127.715L37.2667 127.957L37.4727 128.14L50.3777 139.619L50.3647 139.641L50.5307 139.765L50.7567 140.354L50.7857 140.302L59.6347 159.403L59.9097 159.997L60.2467 159.435L65.2787 151.044L74.9167 168.963L75.1987 169.487L75.5157 168.984L80.7537 160.659L90.8017 177.124L91.0867 177.591L91.3877 177.135L96.9277 168.731L97.7337 169.147L97.6457 169.001L112.806 175.258L113.031 175.596L113.163 175.405L113.955 175.732L113.455 174.981Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M191.438 158.496L67.2495 110.848L23.6035 61.7598L23.0625 62.1927L40.9475 87.1478L40.9675 87.1747L40.9925 87.1988L66.8095 111.394L66.9715 111.236L66.8735 111.442L131.35 148.435L131.402 148.465L131.461 148.475L191.252 159.161L191.438 158.496Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M102.547 158.948L86.6072 150.679L70.9712 140.917L56.3812 130.097L48.2902 108.943L41.5622 86.8442L41.3152 86.0332L40.9232 86.7852L35.8172 96.5832L35.7012 96.8042L35.8742 96.9832L47.5362 109.113L42.4652 118.419L42.3322 118.662L42.5402 118.845L55.8082 130.527L64.9802 150.487L65.2542 151.085L65.5932 150.521L70.7572 141.909L80.4532 160.163L80.7342 160.692L81.0532 160.185L86.4282 151.643L96.5122 168.458L96.7962 168.931L97.0992 168.471L102.796 159.83L118.813 166.53L119.037 166.875L119.171 166.68L119.949 167.006L119.462 166.257L125.491 157.503L125.732 157.153L125.341 156.987L108.901 150.016L108.67 149.661L108.537 149.862L107.758 149.531L108.253 150.293L102.547 158.948Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M131.617 147.8L131.412 148.463L147.785 153.937L147.8 153.942L147.816 153.946L163.467 157.592L163.641 156.92L131.617 147.8Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M76.2924 131.328L61.7874 120.631L53.6674 99.3101L53.7134 99.2251L53.5844 99.0941L53.3424 98.4571L53.2065 98.7061L41.4774 86.7031L40.8984 87.0471L47.5875 109.017L47.5234 109.134L47.6584 109.252L47.8705 109.948L47.9065 109.882L55.7094 130.285L55.6385 130.41L55.8044 130.533L56.0305 131.123L56.1995 130.826L70.3155 141.296L70.3015 141.318L70.5024 141.443L70.7325 141.949L70.8904 141.685L85.9554 151.091L85.9405 151.115L86.1785 151.239L86.4074 151.676L86.5445 151.458L87.3354 151.952L87.2545 151.797L102.415 159.661L102.647 160.055L102.781 159.851L103.575 160.263L103.072 159.41L108.956 150.485L109.168 150.164L108.827 149.986L92.5034 141.47L92.2645 141.065L92.1454 141.255L76.6574 131.589L76.4145 131.125L76.2924 131.328ZM131.384 147.722L130.649 147.476L131.1 148.133L124.919 157.11L124.655 157.492L125.097 157.636L141.231 162.894L141.444 163.205L141.585 163.009L142.315 163.248L141.874 162.605L148.176 153.81L148.449 153.428L148.004 153.279L131.741 147.841L131.522 147.521L131.384 147.722Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.4674 86.6924L40.9824 87.1884L53.0384 99.5274L53.0494 99.5374L53.0604 99.5474L66.8204 111.404L67.2844 110.888L41.4674 86.6924Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M114.708 140.501L98.4759 131.983L98.2389 131.572L98.1029 131.787L97.3249 131.379L97.8209 132.236L92.2849 141.033L82.5559 122.233L82.6189 122.128L82.4299 121.989L82.1809 121.509L82.0569 121.716L67.3249 110.914L67.0829 110.378L66.9539 110.603L53.5129 99.0215L52.9619 99.4075L61.1179 120.819L61.0459 120.945L61.2129 121.067L61.4379 121.658L61.6079 121.359L75.9349 131.925L75.9219 131.947L76.1219 132.071L76.3509 132.579L76.4959 132.338L77.2569 132.9L77.1819 132.733L91.7769 141.841L91.7619 141.864L92.0829 142.032L93.1449 142.695L93.0679 142.546L108.167 150.423L108.149 150.45L108.425 150.567L108.648 150.952L108.785 150.745L109.566 151.153L109.487 151.017L124.97 157.583L125.193 157.934L125.33 157.736L126.095 158.06L125.621 157.313L131.808 148.329L132.048 147.981L131.659 147.814L115.06 140.677L114.83 140.315L114.708 140.501Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M67.2205 110.841L66.8535 111.429L98.0325 132.532L98.0535 132.546L98.0715 132.555L114.665 141.262L114.677 141.268L114.689 141.273L131.386 148.453L131.484 148.237L131.696 147.833L67.2205 110.841Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M98.5195 132.429L98.7045 132.134L98.4095 131.95L82.3416 121.927L81.2695 121.258L81.8506 122.381L91.9475 141.891L92.2256 142.43L92.5486 141.917L98.5195 132.429Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M67.2408 110.854L66.8418 111.421L81.9538 122.501L81.9638 122.509L81.9748 122.516L98.0428 132.539L98.4208 131.957L67.2408 110.854Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};

export default FeaturesTopLeft;
