import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const FooTopLeft: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="141"
      height="185"
      viewBox="0 0 141 185"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.7418 18.7959L68.7308 18.8029L68.7208 18.8109L58.8788 26.2069L58.8728 26.2119L58.8658 26.2179L49.4498 34.0519L49.4428 34.0569L49.5878 34.2359L49.4298 34.0689L40.4898 42.3289L40.4828 42.3349L40.4758 42.3419L32.0918 51.0059L32.5828 51.4949L49.8878 34.5909L89.8698 6.02991L111.755 -5.10413L111.448 -5.72607L100.368 -0.455078L100.36 -0.451111L100.352 -0.447083L89.5238 5.42792L89.6318 5.6319L89.5088 5.43591L78.9678 11.8599L78.9608 11.8649L78.9538 11.8689L68.7418 18.7959Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M96.9228 56.605L96.1078 56.462L96.5778 57.144L100.716 63.152L90.3008 61.6769L89.5088 61.564L89.9678 62.2189L94.6198 68.847L94.8288 69.144L95.1158 68.923L101.654 63.8879L108.498 59.254L115.618 55.0469L116.383 54.595L115.514 54.41L103.856 51.921L103.014 51.741L103.496 52.455L107.567 58.4769L96.9228 56.605Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.539 -13.546L134.279 -14.189L111.477 -5.73798L111.458 -5.73096L111.44 -5.72198L89.5322 5.42303L89.8292 6.04999L134.539 -13.546Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M104.071 52.066L103.877 51.778L103.589 51.973L96.9537 56.4659L96.9417 56.449L96.6597 56.665L95.9587 57.14L96.0258 57.152L90.0407 61.7449L89.3857 62.2469L90.2037 62.363L101.402 63.949L102.185 64.0599L101.736 63.4089L97.6187 57.4319L108.252 59.3019L109.058 59.444L108.6 58.7659L104.071 52.066ZM111.051 47.662L110.179 47.4399L110.676 48.1899L115.152 54.9399L115.323 55.1979L115.599 55.058L122.948 51.322L123.77 50.9039L122.877 50.6769L111.051 47.662Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.92 5.99194L89.488 5.45093L49.463 34.0419L49.433 34.064L49.408 34.092L17.334 69.5209L17.822 70.0129L89.92 5.99194Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M83.539 67.3909L83.178 67.7039L83.242 67.7109L78.0471 72.8379L77.452 72.7889L77.721 73.1589L77.395 73.4809L77.991 73.5289L82.697 79.9929L82.9351 80.3209L83.222 80.0349L88.859 74.4099L89.444 74.4579L89.192 74.1059L95.018 69.0089L95.629 69.0759L95.367 68.7029L95.726 68.3889L95.098 68.3199L90.7271 62.0919L97.066 57.2279L103.856 52.6299L115.369 55.0879L116.2 55.2649L115.73 54.5569L111.751 48.5559L122.574 51.3149L122.659 51.4459L122.819 51.3779L123.566 51.5679L123.307 51.1699L130.455 48.1199L131.335 47.7449L130.418 47.4689L118.582 43.9069L118.481 43.7509L118.327 43.8299L117.553 43.5969L117.86 44.0689L111.083 47.5479L111.072 47.5309L110.905 47.6299L99.2501 45.1039L98.405 44.9209L98.889 45.6369L103.036 51.7729L92.2581 49.8299L91.438 49.6819L91.911 50.3679L96.126 56.4869L85.57 54.9329L84.772 54.8159L85.235 55.4759L89.789 61.9629L84.065 66.9339L84.05 66.9139L83.8781 67.0829L83.2711 67.0159L83.539 67.3909Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M99.4639 45.249L99.2698 44.9609L98.9819 45.1559L92.0018 49.884L91.2969 50.3619L92.1348 50.5129L103.723 52.601L104.532 52.7469L104.071 52.066L99.4639 45.249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.9224 34.5569L49.4233 34.0759L32.0994 50.999L32.0833 51.014L32.0703 51.0309L17.3203 69.538L17.8494 69.9869L49.9224 34.5569Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M92.937 50.6349L92.6801 50.2619L99.248 45.8139L110.754 48.3069L110.848 48.4479L111.013 48.3629L111.726 48.5179L111.469 48.1289L118.395 44.5749L130.096 48.0959L130.173 48.2179L130.326 48.1649L131.114 48.4029L130.848 47.9859L137.98 45.5369L138.802 45.8259L138.507 45.3559L139.037 45.1739L138.211 44.8829L133.915 38.0269L133.768 37.7919L133.506 37.8839L126.071 40.5029L126.06 40.4859L125.914 40.5489L125.093 40.2599L125.131 40.3189L114.77 37.1879L114.816 37.1639L114.07 36.9709L113.972 36.8199L113.818 36.8999L113.044 36.6659L113.074 36.7129L101.863 33.8049L100.987 33.5769L101.487 34.3319L105.623 40.5689L105.648 40.6069L94.6981 38.1979L94.5811 38.0249L94.4171 38.1359L93.715 37.9809L94.007 38.4139L87.538 42.7969L87.527 42.7809L87.36 42.9079L86.687 42.7839L86.9641 43.1859L86.553 43.4649L86.614 43.4759L80.61 48.0499L79.953 47.9489L80.228 48.3409L79.843 48.6339L80.505 48.7349L85.044 55.2029L84.656 55.4999L84.718 55.5089L79.26 60.2149L79.246 60.1949L79.075 60.3619L78.4611 60.2899L78.733 60.6689L78.3751 60.9779L78.436 60.9849L73.166 66.1279L72.564 66.0739L72.837 66.4489L72.512 66.7659L73.106 66.8189L77.733 73.1749L72.688 78.8879L72.119 78.8569L72.3911 79.2239L72.1021 79.5509L72.655 79.5809L77.44 86.0399L77.697 86.3859L77.981 86.0609L83.1281 80.1449L83.687 80.1749L83.42 79.8079L83.705 79.4809L83.161 79.4519L78.634 73.2339L84.134 67.8059L84.726 67.8589L84.47 67.5009L90.364 62.3819L90.981 62.4539L90.716 62.0769L91.0731 61.7669L90.447 61.6929L86.267 55.7369L96.813 57.2899L97.601 57.4059L97.149 56.7499L92.937 50.6349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0253 5.81982L90.3134 4.71484L89.4594 5.47284L17.3613 69.4948L17.7863 70.0408L85.0554 24.5488L85.1633 24.4758L85.1964 24.3499L90.0253 5.81982Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M74.3519 53.5409L73.5669 53.4439L74.0269 54.0869L78.9169 60.9269L79.1379 61.2359L79.4259 60.9879L85.7459 55.5389L86.3379 55.0289L85.5619 54.9319L74.3519 53.5409ZM102.065 33.9489L101.882 33.6729L101.598 33.8429L94.3099 38.2089L93.5609 38.6579L94.4139 38.8449L106.336 41.4679L107.174 41.6529L106.7 40.9379L102.065 33.9489ZM121.75 33.7349L121.59 33.4839L121.318 33.6039L113.713 36.9549L112.855 37.3339L113.752 37.6049L125.827 41.2539L126.723 41.5249L126.219 40.7349L121.75 33.7349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0309 5.67004L89.3539 5.64502L84.525 24.175L84.31 25.001L85.04 24.559L92.438 20.084L92.6429 19.96L92.6 19.725L90.0309 5.67004ZM32.612 51.464L32.077 51.022L24.332 60.08L24.325 60.089L24.317 60.099L17.313 69.547L17.863 69.97L32.612 51.464Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M80.9871 48.215L80.768 47.903L80.478 48.151L74.083 53.622L73.486 54.133L74.2661 54.23L85.4771 55.621L86.252 55.717L85.803 55.077L80.9871 48.215ZM134.595 38.236L134.665 38.211L133.745 37.887L132.754 37.481L132.8 37.554L121.573 33.594L120.624 33.259L121.165 34.108L125.635 41.108L125.783 41.34L126.042 41.249L133.475 38.631L137.687 45.353L137.822 45.568L138.067 45.505L145.824 43.517L146.838 43.257L145.87 42.86L134.595 38.236ZM73.282 79.262L78.438 73.426L78.906 72.895L78.2 72.85L67.89 72.19L67.144 72.142L67.5891 72.743L72.403 79.241L67.777 85.353L67.3701 85.89L68.044 85.909L77.709 86.18L78.4221 86.2L77.998 85.627L73.282 79.262Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.9333 5.48584L89.1514 4.71484L89.3494 5.79486L91.9174 19.8488L92.0034 20.3168L92.4224 20.0928L100.005 16.0428L100.421 15.8209L100.085 15.4898L89.9333 5.48584Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.0629 85.215L67.3428 85.195L67.7769 85.771L72.7468 92.368L73.0269 92.738L73.3029 92.366L77.9968 86.04L78.3939 85.505L77.7288 85.486L68.0629 85.215ZM109.23 29.848L108.455 29.613L108.766 30.091L101.728 33.774L89.7928 31.109L88.9409 30.919L89.4299 31.641L93.7349 38.011L82.6788 35.92L81.8508 35.764L82.3289 36.458L86.7028 42.808L75.8558 41.089L75.0479 40.96L75.5178 41.63L80.4199 48.614L80.6268 48.909L80.9138 48.691L87.6498 43.559L94.6749 38.799L101.83 34.513L113.635 37.575L113.723 37.709L113.882 37.639L114.631 37.833L114.366 37.426L121.598 34.239L122.454 33.861L121.559 33.589L109.487 29.926L109.383 29.767L109.23 29.848ZM78.4578 72.992L73.7509 66.526L79.3259 61.086L79.9258 61.145L79.6539 60.766L79.9749 60.453L79.3898 60.395L74.5908 53.684L74.3578 53.357L74.0679 53.636L68.1979 59.299L67.5878 59.239L67.8649 59.62L67.5419 59.932L68.1339 59.99L72.8478 66.465L67.6099 72.305L67.1318 72.837L67.8458 72.882L78.1548 73.543L78.8928 73.59L78.4578 72.992Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.8073 5.40601L89.4463 5.98004L99.5983 15.984L99.7693 16.153L99.9873 16.052L107.712 12.475L108.479 12.12L107.683 11.834L89.8073 5.40601Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.5297 31.155L88.7957 31.597L88.8577 31.611L82.7047 35.781L82.6937 35.765L82.4117 35.979L81.7238 36.446L81.7827 36.457L75.8787 40.937L75.8667 40.921L75.5847 41.161L74.9437 41.647L75.0017 41.656L69.2247 46.56L68.5847 46.477L68.8637 46.866L68.5107 47.167L69.1367 47.249L74.0267 54.087L74.2467 54.395L74.5347 54.149L80.8107 48.779L81.4397 48.861L81.1677 48.473L81.5208 48.172L80.8997 48.091L76.5557 41.902L87.3927 43.62L88.1917 43.746L87.7327 43.081L83.3787 36.759L94.4237 38.848L95.2417 39.002L94.7758 38.313L90.4927 31.976L101.564 34.449L101.66 34.593L101.825 34.507L102.541 34.667L102.278 34.27L109.3 30.594L121.234 34.216L121.315 34.342L121.459 34.29L133.392 38.498L133.463 38.613L133.598 38.577L145.606 43.502L146.591 43.906L146.033 43L141.942 36.35L142.487 36.204L141.639 35.856L141.563 35.733L141.426 35.769L130.149 31.15L130.216 31.126L129.414 30.843L129.33 30.708L129.192 30.758L128.318 30.4L128.363 30.472L117.138 26.509L117.041 26.358L116.894 26.423L116.075 26.134L116.401 26.644L109.234 29.855L97.2747 26.725L97.1647 26.559L97.0057 26.655L96.2677 26.462L96.5697 26.917L89.8197 30.98L89.8098 30.965L89.5297 31.155Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.7353 5.38892L89.5723 6.0589L107.448 12.4859L107.571 12.5299L107.692 12.4829L115.513 9.43292L115.432 8.76587L89.7353 5.38892Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.3152 72.557L73.4382 66.846L74.0152 66.889L73.7392 66.51L74.0232 66.194L73.4792 66.154L68.7812 59.7L74.4332 54.248L75.0402 54.312L74.7652 53.928L75.0852 53.619L74.5002 53.557L69.8002 46.982L76.0192 41.702L82.7062 36.628L83.3632 36.735L83.0992 36.352L89.7922 31.815L90.4742 31.947L90.2092 31.556L97.1132 27.4L109.047 30.523L109.136 30.659L109.295 30.588L110.044 30.784L109.776 30.372L116.925 27.17L129.061 31.454L141.166 36.413L145.442 43.363L145.564 43.561L145.794 43.524L153.36 42.289L154.436 42.113L153.456 41.635L141.645 35.867L137.285 28.78L137.148 28.557L136.897 28.627L129.341 30.726L124.961 23.737L124.812 23.498L124.547 23.596L117.051 26.374L112.572 19.357L112.41 19.103L112.136 19.228L104.723 22.604L104.713 22.589L104.559 22.67L103.785 22.434L103.814 22.479L92.4742 19.485L92.3632 19.318L92.2052 19.414L91.4652 19.219L91.7692 19.677L84.9632 23.794L84.9542 23.78L84.7912 23.89L84.0822 23.73L84.3692 24.154L83.9452 24.41L84.0042 24.423L77.7862 28.639L77.7752 28.624L77.6102 28.75L76.9292 28.618L77.2122 29.029L76.8092 29.301L76.8652 29.312L70.7222 33.957L70.0552 33.847L70.3362 34.248L69.9582 34.534L70.6122 34.642L75.0602 40.979L64.3132 39.494L63.5142 39.384L63.9832 40.04L68.8742 46.881L63.1402 52.348L62.5232 52.282L62.8042 52.668L62.4822 52.975L63.0742 53.038L67.8762 59.635L62.6672 65.351L62.0812 65.308L62.3612 65.687L62.0742 66.003L62.6262 66.044L67.4332 72.532L62.8292 78.493L62.2762 78.471L62.5572 78.844L62.3092 79.166L62.8152 79.187L67.6322 85.579L63.7192 91.783L63.2062 91.781L63.4882 92.15L63.2822 92.475L63.7382 92.477L68.7002 98.957L69.0062 99.357L69.2712 98.927L73.2182 92.507L73.7222 92.508L73.4442 92.139L73.6442 91.814L73.1982 91.813L68.4882 85.562L73.0042 79.597L73.5472 79.619L73.2702 79.245L73.5142 78.923L73.0162 78.903L68.3152 72.557Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.8979 29.4021L71.022 34.5991L71.014 34.6061L71.0049 34.6111L17.7859 70.0411L17.784 70.0421L17.405 69.4781L77.8979 29.4021ZM77.8979 29.4021L17.405 69.4781L77.8979 29.4021ZM77.8979 29.4021L85.055 24.5491H85.056L84.667 23.9741L84.666 23.9751L17.4059 69.4611L17.4 69.4651L77.8979 29.4021ZM17.405 69.4781L17.3979 69.4661L17.405 69.4781ZM89.551 5.4151L89.671 5.74811L89.645 6.07605L115.342 9.45306L115.418 9.46307L115.491 9.4411L123.366 6.9801L123.272 6.30206L91.9399 5.44708L175.916 -18.6799L175.777 -19.3579L134.357 -14.2079L134.307 -14.2019L134.261 -14.1819L89.551 5.4151Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.0958 34.1238L70.8788 33.8128L70.5888 34.0579L64.0418 39.5728L63.4458 40.0748L64.2178 40.1808L75.7538 41.7748L76.5428 41.8839L76.0858 41.2318L71.0958 34.1238ZM149.281 34.6238L149.158 34.4208L148.925 34.4619L141.343 35.7928L140.291 35.9768L141.25 36.4459L153.152 42.2578L154.21 42.7748L153.601 41.7668L149.281 34.6238ZM125.623 23.9368L125.687 23.9128L124.791 23.5969L123.801 23.1918L123.846 23.2628L112.395 19.2169L111.445 18.8809L111.987 19.7299L116.617 26.9828L116.768 27.2188L117.03 27.1218L124.523 24.3438L128.89 31.3138L129.028 31.5328L129.277 31.4638L137.082 29.2958L138.059 29.0239L137.121 28.6398L125.623 23.9368ZM63.2588 65.7318L68.5788 59.8928L69.0528 59.3729L68.3518 59.3138L57.6898 58.4138L56.9248 58.3499L57.3818 58.9658L62.3728 65.7028L57.6808 71.6538L57.1178 71.6248L57.4038 72.0048L57.1548 72.3209L57.6628 72.3478L62.7168 79.0548L62.9898 79.4178L63.2678 79.0588L68.0308 72.8918L68.5848 72.9208L68.3028 72.5408L68.5468 72.2249L68.0488 72.1989L63.2588 65.7318Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.3999 69.465L17.7789 70.046L17.7839 70.043L64.4529 40.1299L64.4719 40.118L64.4889 40.103L71.0359 34.588L70.6199 34.0339L17.4049 69.462L17.3999 69.465Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M99.8049 15.363L99.0298 15.126L99.3448 15.6089L92.0948 19.481L91.3159 19.897L92.1699 20.1219L104.377 23.345L104.467 23.483L104.626 23.41L105.376 23.608L105.106 23.192L112.423 19.8589L113.256 19.48L112.381 19.212L100.064 15.442L99.9589 15.2809L99.8049 15.363ZM63.5438 52.5049L63.2949 52.1639L63.0089 52.4739L57.4069 58.524L56.9238 59.045L57.6318 59.105L68.2938 60.0049L69.0499 60.069L68.6028 59.455L63.5438 52.5049ZM137.142 28.649L136.069 28.127L136.694 29.1429L141.107 36.316L141.231 36.517L141.463 36.476L149.044 35.145L150.098 34.96L149.136 34.4919L137.142 28.649Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.2711 78.638L62.972 78.242L62.703 78.658L58.567 85.045L58.041 85.036L58.329 85.412L58.122 85.731L58.579 85.739L63.635 92.341L63.937 92.737L64.203 92.316L68.243 85.912L68.759 85.922L68.475 85.545L68.675 85.226L68.229 85.218L63.2711 78.638ZM57.652 45.591L57.3311 45.893L57.9221 45.961L62.983 52.914L63.216 53.234L63.5031 52.961L69.454 47.287L70.0681 47.358L69.789 46.968L70.1071 46.664L69.524 46.597L64.548 39.636L64.317 39.313L64.028 39.585L57.992 45.271L57.367 45.199L57.652 45.591Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4053 69.4619L17.7693 70.0519L58.2903 45.9319L58.3233 45.9129L58.3503 45.8869L64.5033 40.0899L64.0782 39.5459L17.4053 69.4619Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M149.161 34.5049L148.688 34.983L153.007 42.126L153.115 42.3049L153.325 42.293L160.418 41.8649L161.553 41.7969L160.573 41.22L149.161 34.5049ZM120.363 16.3979L120.212 16.157L119.946 16.2579L112.156 19.22L111.272 19.556L112.164 19.871L124.544 24.2449L136.858 29.282L137.842 29.6849L137.285 28.78L132.792 21.4769L132.654 21.252L132.4 21.325L124.823 23.515L120.363 16.3979ZM64.1851 91.92L63.8491 91.48L63.6 91.975L60.291 98.559L60.04 99.059L60.6 99.061L68.9741 99.092L69.6801 99.095L69.251 98.535L64.1851 91.92Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6827 5.38599L89.6797 6.07996L123.253 6.99493L123.297 6.99695L123.34 6.98694L130.979 5.23993L130.895 4.55493L89.6827 5.38599Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M107.548 11.7861L106.731 11.4971L107.06 12.0121L99.6957 15.4221L98.8727 15.8041L99.7397 16.0691L112.056 19.8381L112.139 19.9681L112.292 19.9101L113.079 20.1511L112.801 19.7171L119.927 17.0081L124.374 24.1051L124.513 24.3271L124.764 24.2541L132.593 21.9921L133.552 21.7151L132.628 21.3371L121.015 16.5941L121.077 16.5711L120.193 16.2581L119.204 15.8541L119.248 15.9241L107.794 11.8731L107.695 11.7181L107.548 11.7861ZM60.6017 98.3681L59.8896 98.3651L60.3267 98.9271L65.4886 105.571L65.8307 106.011L66.0746 105.509L69.2867 98.8971L69.5276 98.4011L68.9767 98.3991L60.6017 98.3681ZM144.884 27.3521L144.759 27.1471L144.523 27.1911L136.926 28.6201L135.896 28.8141L136.838 29.2731L148.833 35.1161L149.89 35.6311L149.282 34.6241L144.884 27.3521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4141 69.4559L17.7511 70.062L52.5781 51.991L52.6311 51.9639L52.6711 51.921L58.3651 45.8719L57.9351 45.3359L17.4141 69.4559Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.1108 58.7839L63.4008 53.0709L63.9968 53.1269L63.7118 52.7359L63.9948 52.4299L63.4518 52.3789L58.3928 45.4299L58.1468 45.0919L57.8598 45.3959L52.2818 51.3219L51.6768 51.2649L51.9668 51.6569L51.6798 51.9619L52.2318 52.0139L57.2228 58.7509L52.4398 64.6909L51.8648 64.6539L52.1578 65.0419L51.9078 65.3519L52.4158 65.3849L57.4178 72.0229L53.3198 78.1849L52.7808 78.1669L53.0758 78.5519L52.8678 78.8639L53.3258 78.8789L58.3428 85.4309L55.0358 91.7889L54.7788 92.2839L55.3368 92.2959L63.9028 92.4769L64.6238 92.4929L64.1848 91.9199L59.1768 85.3789L63.1778 79.1999L63.7068 79.2169L63.4168 78.8319L63.6178 78.5199L63.1708 78.5049L58.2798 72.0149L62.9698 66.0679L63.5338 66.1039L63.2468 65.7159L63.4908 65.4059L62.9928 65.3739L58.1108 58.7839ZM132.648 21.3459L131.577 20.8259L132.201 21.8399L136.694 29.1429L136.819 29.3459L137.054 29.3019L144.415 27.9169L148.395 34.4999L147.852 34.5399L148.733 35.0579L148.798 35.1659L148.902 35.1579L160.221 41.8179L160.695 41.3419L156.67 34.5819L157.22 34.5409L156.337 34.0239L156.273 33.9159L156.167 33.9239L145.561 27.7009L145.683 27.6789L144.738 27.2199L132.648 21.3459ZM115.681 8.92487L115.529 8.68188L115.261 8.78589L107.44 11.8369L106.57 12.1759L107.45 12.4869L119.954 16.9089L120.891 17.2409L120.363 16.3979L115.681 8.92487Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4316 69.446L17.7197 70.0759L47.3837 58.282L47.4667 58.249L47.5237 58.1799L52.6857 51.9039L52.2587 51.376L17.4316 69.446Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M128.217 14.04L128.078 13.813L127.822 13.89L119.97 16.25L119.03 16.532L119.938 16.903L132.366 21.979L133.349 22.381L132.792 21.476L128.217 14.04Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6768 5.38599L89.6968 6.07996L89.7028 6.07898L130.909 5.24799H130.928L130.948 5.24493L138.067 4.28595L138.008 3.59497L89.6828 5.38599H89.6768Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.3521 91.6018L54.624 91.5869L55.07 92.1619L60.327 98.9268L60.664 99.3608L60.911 98.8698L64.22 92.2858L64.467 91.7949L63.9171 91.7838L55.3521 91.6018ZM140.406 19.9468L140.279 19.7379L140.04 19.7858L132.428 21.3178L131.421 21.5208L132.345 21.9698L144.435 27.8438L145.491 28.3568L144.884 27.3518L140.406 19.9468ZM115.518 8.78784L114.522 8.38184L115.093 9.29382L119.776 16.7658L119.916 16.9908L120.169 16.9138L128.022 14.5538L128.962 14.2708L128.053 13.9008L115.518 8.78784Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.8939 69.9238L17.2969 69.5698L11.3428 79.0999L11.3339 79.1138L11.3268 79.1288L6.67285 88.4038L7.28485 88.7299L17.8939 69.9238Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M156.345 34.0361L151.992 26.7251L151.88 26.5381L151.663 26.5571L144.556 27.1861L143.476 27.2821L144.411 27.8311L155.833 34.5321L160.099 41.6961L160.193 41.8551L160.377 41.8651L166.954 42.2381L168.173 42.3071L167.172 41.6081L156.345 34.0361ZM52.6966 51.4771L52.4326 51.1201L52.1506 51.4631L47.1026 57.6001L46.5166 57.5551L46.8146 57.9501L46.5636 58.2541L47.0726 58.2931L52.1706 65.0591L47.9766 71.1971L47.4246 71.1711L47.7256 71.5651L47.5166 71.8701L47.9756 71.8921L53.2256 78.7481L53.5216 79.1341L53.7906 78.7301L58.0226 72.3661L58.5636 72.3911L58.2666 71.9971L58.4696 71.6921L58.0216 71.6711L53.0366 65.0561L57.8166 59.1201L58.3916 59.1641L58.0986 58.7681L58.3436 58.4641L57.8456 58.4261L52.6966 51.4771ZM60.8746 98.5021L60.4906 98.0081L60.2756 98.5951L58.0256 104.711L57.5266 104.692L57.8716 105.13L57.7736 105.396L58.0906 105.408L63.2486 111.963L63.6396 112.459L63.8486 111.863L66.0056 105.714L66.4936 105.733L66.1526 105.294L66.2456 105.029L65.9376 105.017L60.8746 98.5021ZM128.073 13.9101L127.004 13.3921L127.626 14.4041L132.201 21.8401L132.328 22.0461L132.565 21.9981L140.177 20.4661L141.187 20.2631L140.26 19.8141L128.073 13.9101Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4639 69.431L17.6638 70.093L42.7778 64.778L42.9119 64.75L42.9899 64.638L47.5399 58.159L48.2078 57.208L47.1279 57.637L17.4639 69.431Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M140.284 19.8269L139.812 20.3059L144.29 27.7109L144.402 27.8959L144.617 27.8769L151.724 27.2479L152.807 27.1519L151.869 26.6029L140.284 19.8269Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176 -18.7172L175.724 -19.3472L89.5938 5.39886L89.7027 6.07886L138.034 4.28784L138.124 4.28485L138.201 4.23785L176 -18.7172Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.7769 78.3268L53.4509 77.9008L53.1959 78.3729L49.6849 84.8948L49.4209 85.3839L49.9769 85.4058L58.7399 85.7418L59.4769 85.7708L59.0279 85.1848L53.7769 78.3268ZM123.558 6.46686L123.417 6.23682L123.159 6.31781L115.284 8.77881L114.363 9.06683L115.256 9.43085L127.791 14.5428L128.773 14.9438L128.217 14.0399L123.558 6.46686Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.0028 84.7119L49.2588 84.6838L49.7158 85.2719L54.9498 92.0068L52.6758 97.9019L52.1588 97.8708L52.5138 98.3218L52.4138 98.5808L52.7328 98.5999L57.9908 105.282L58.3748 105.77L58.5898 105.187L60.8378 99.0758L61.3438 99.1058L60.9928 98.6538L61.0878 98.3958L60.7778 98.3768L55.7548 91.9119L59.0608 85.5558L59.3128 85.0698L58.7658 85.0488L50.0028 84.7119ZM163.281 34.9218L163.833 34.9448L162.92 34.3079L162.863 34.2098L162.775 34.2068L151.953 26.6608L147.518 19.2138L147.405 19.0239L147.185 19.0458L140.292 19.7589L136.096 12.8209L136.608 12.7108L135.791 12.3168L135.717 12.1928L135.591 12.2198L123.413 6.33582L122.346 5.81982L122.967 6.82983L127.369 13.9858L126.863 14.0948L127.679 14.4898L127.755 14.6129L127.878 14.5858L139.945 20.4329L151.441 27.1569L155.459 33.9048L154.914 33.8818L155.825 34.5189L155.883 34.6158L155.969 34.6198L166.775 42.1758L167.272 41.7158L163.281 34.9218ZM47.5328 57.7509L47.2458 57.3698L46.9718 57.7598L42.5338 64.0798L41.9678 64.0458L42.2758 64.4478L42.0658 64.7468L42.5268 64.7748L47.8778 71.7639L48.1698 72.1448L48.4398 71.7488L52.7728 65.4069L53.3268 65.4409L53.0238 65.0388L53.2278 64.7398L52.7778 64.7128L47.5328 57.7509Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9611 71.234L39.173 71.245L39.2791 71.061L43.007 64.612L43.3961 63.938L42.635 64.099L17.52 69.414L17.5731 70.1L38.9611 71.234Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M123.112 6.96082L135.304 12.8518L139.812 20.3058L139.926 20.4938L140.144 20.4719L147.036 19.7589L151.074 26.5399L150.548 26.5269L151.434 27.1448L151.493 27.2448L151.581 27.2468L162.399 34.7899L166.674 42.0678L166.754 42.2028L166.907 42.2328L172.911 43.4019L173.198 42.7938L162.928 34.3209L158.881 27.4318L159.414 27.4448L158.526 26.8278L158.467 26.7268L158.378 26.7249L148.186 19.6398L148.313 19.6268L147.473 19.1368L142.962 11.5618L142.846 11.3679L142.623 11.3948L135.729 12.2138L131.198 4.72186L131.069 4.50684L130.824 4.56281L123.185 6.31085L122.222 6.53082L123.112 6.96082ZM48.4289 71.3419L48.1079 70.9229L47.8509 71.3839L44.3389 77.6808L43.7949 77.6508L44.1209 78.0709L43.9629 78.3549L44.3589 78.3768L49.5939 85.1138L47.2259 90.9638L46.6899 90.9218L47.0559 91.3859L46.9539 91.6389L47.2739 91.6638L52.6359 98.4768L53.0129 98.9558L53.2319 98.3878L55.5749 92.3148L56.0999 92.3568L55.7379 91.8908L55.8349 91.6398L55.5239 91.6158L50.4029 85.0248L53.7029 78.8958L54.2359 78.9258L53.9139 78.5048L54.0659 78.2219L53.6799 78.1998L48.4289 71.3419ZM58.5369 104.853L58.0849 104.279L57.9259 104.992L56.6359 110.771L56.1309 110.725L56.5329 111.23L56.4829 111.454L56.7289 111.476L61.9869 118.087L62.4479 118.667L62.5979 117.941L63.7989 112.122L64.2929 112.167L63.8939 111.661L63.9399 111.438L63.7019 111.417L58.5369 104.853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.0999 77.3879L36.4089 77.5099L36.5438 77.207L39.2958 71.0289L39.5009 70.5679L38.9968 70.541L17.6099 69.407L17.4648 70.077L36.0999 77.3879Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.329 36.0891L169.896 36.1901L168.922 35.3881L168.872 35.3031L168.803 35.2911L158.486 26.8011L157.966 27.2451L161.979 34.076L161.42 33.9761L162.391 34.7781L162.441 34.8631L162.509 34.8751L172.756 43.3291L173.277 42.8881L169.329 36.0891ZM131.076 4.60205L130.605 5.08105L135.251 12.7641L135.366 12.9551L135.588 12.9291L142.704 12.0831L143.735 11.9611L142.838 11.4391L131.076 4.60205ZM42.9813 64.2281L42.6663 63.8151L42.4063 64.2651L38.6783 70.7141L38.4014 71.1941L38.9543 71.2341L48.1293 71.8991L48.8983 71.9551L48.4293 71.3421L42.9813 64.2281Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.91 69.892L17.29 69.583L6.68103 88.39L6.66205 88.424L6.65002 88.461L1.43506 106.009L2.08606 106.246L17.91 69.892Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.181 98.0479L52.739 97.4858L52.571 98.1808L51.119 104.209L51.027 104.59L51.416 104.635L58.225 105.412L59.051 105.507L58.537 104.853L53.181 98.0479ZM39.004 70.5418L38.2271 70.4849L38.705 71.0999L44.262 78.2518L44.583 78.6658L44.8391 78.2078L48.457 71.7219L48.722 71.2468L48.179 71.2068L39.004 70.5418Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M154.398 19.8009L154.912 19.8049L154.049 19.2069L153.988 19.1029L153.898 19.1019L142.923 11.4969L138.319 3.76392L138.202 3.56689L137.974 3.5979L130.855 4.55792L129.853 4.69287L130.727 5.20087L142.412 11.9939L146.607 19.0389L146.1 19.0349L146.961 19.6329L147.022 19.7369H147.111L158.056 27.3459L168.385 35.8439L172.677 43.2359L172.74 43.3439L172.858 43.3869L178.223 45.3539L178.584 44.7789L168.929 35.4009L164.547 27.8559L164.465 27.7129L164.302 27.6869L158.482 26.7529L154.398 19.8009ZM51.4952 103.946L50.6631 103.851L51.1852 104.506L56.5532 111.256L56.2012 116.866L56.1821 117.177L56.4891 117.23L62.2001 118.212L63.0961 118.367L62.5302 117.655L57.2831 111.058L58.6031 105.143L58.6871 104.767L58.3041 104.723L51.4952 103.946ZM44.8102 77.8269L44.4482 77.3599L44.2172 77.9039L41.6732 83.8949L41.1182 83.8409L41.4941 84.3189L41.3892 84.5649L41.7122 84.5959L47.1802 91.5439L47.5502 92.0139L47.7742 91.4599L50.2151 85.4289L50.7592 85.4829L50.3871 85.0039L50.4861 84.7589L50.1732 84.7279L44.8102 77.8269Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2509 83.2019L34.6499 83.5139L34.7959 83.0279L36.5589 77.1649L36.6509 76.8589L36.3539 76.7429L17.7179 69.4309L17.3779 70.0269L34.2509 83.2019Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.913 116.599L57.2289 111.561L57.767 111.667L57.262 111.032L57.2729 110.863L57.1 110.828L51.8329 104.207L53.172 98.649L53.712 98.722L53.285 98.18L53.336 97.971L53.094 97.938L48.2729 91.811L47.725 91.115L47.293 90.566L42.292 84.212L44.754 78.414L45.318 78.48L44.935 77.987L45.0359 77.749L44.7209 77.712L39.253 70.675L38.897 70.218L38.662 70.746L36.014 76.691L35.438 76.624L35.825 77.116L35.718 77.355L36.043 77.393L41.512 84.341L39.903 90.093L39.798 90.468L40.183 90.529L47.021 91.612L45.642 96.92L45.09 96.845L45.521 97.386L45.4659 97.596L45.715 97.63L51.099 104.399L50.688 109.561L50.137 109.452L50.646 110.086L50.632 110.257L50.811 110.292L55.704 116.391L56.277 117.105L56.837 117.804L61.717 123.885L62.291 124.601L62.334 123.685L62.605 117.887L62.619 117.581L62.317 117.529L56.913 116.599ZM153.528 19.69L153.574 19.768L157.966 27.245L158.049 27.386L158.21 27.412L164.03 28.346L168.353 35.79L168.418 35.901L168.467 35.918L178.1 45.277L178.642 44.855L174.299 37.307L174.235 37.195L174.185 37.178L165.279 28.547L165.459 28.576L164.516 27.801L160.416 20.742L160.938 20.816L160.025 20.069L159.972 19.978L159.901 19.968L150.188 12.021L150.325 12.02L149.486 11.441L149.424 11.334L144.875 3.59204L144.772 3.41504L144.567 3.42102L138.011 3.59503L136.949 3.62305L137.761 4.18402L142.056 11.397L141.568 11.401L142.404 11.981L142.467 12.087H142.556L153.528 19.69Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.3147 88.6589L6.65771 88.4399L3.34674 97.4059L3.33875 97.428L3.33374 97.4509L1.42871 106.032L2.09973 106.206L7.3147 88.6589Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.2922 89.8439L39.4102 89.704L39.9661 90.402L45.6292 97.5209L46.0612 98.0649L46.2361 97.3929L47.7891 91.4169L47.8851 91.0469L47.5071 90.9869L40.2922 89.8439ZM174.26 37.252L173.698 37.653L178.041 45.2009L178.087 45.2789L178.165 45.3259L182.811 48.0889L183.25 47.5629L174.26 37.252Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.024 -18.733L175.64 -19.3101L137.841 3.64496L136.724 4.32294L138.03 4.28796L144.585 4.11395L144.693 4.11096L144.781 4.04797L176.024 -18.733Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.8182 116.671L56.0552 115.719L51.3591 109.867L51.7701 104.715L52.3381 104.842L51.8132 104.181L51.8262 104.017L51.6512 103.978L46.1722 97.09L45.6382 96.419L45.5552 97.272L45.0432 102.508L44.4622 102.378L44.9911 103.038L44.9752 103.203L45.1561 103.243L50.6691 110.115L51.2261 115.294L51.2502 115.517L51.4631 115.586L56.2302 117.149L56.7952 121.749L56.2291 121.579L56.8712 122.374L56.8871 122.5L57.0012 122.535L62.4581 129.294L63.2411 130.263L63.0721 129.029L62.4042 124.156L62.9562 124.321L62.3182 123.526L62.3011 123.4L62.1912 123.367L57.5482 117.581L56.8182 116.671ZM178.84 39.871L169.855 29.584L169.806 29.499L169.756 29.482L160.033 20.082L155.83 12.846L156.331 12.908L155.448 12.188L155.393 12.094L155.321 12.085L144.796 3.49902L144.277 3.94299L148.571 11.252L148.078 11.191L148.959 11.912L149.014 12.006L149.084 12.014L159.489 20.526L163.947 28.204L164.013 28.318L164.063 28.334L173.722 37.695L173.744 37.734L173.775 37.751L182.727 48.019L183.289 47.619L178.918 39.971L178.871 39.889L178.84 39.871ZM36.5002 76.851L36.0872 76.327L35.8951 76.966L34.2211 82.532L33.6191 82.424L34.0792 83.002L34.0201 83.201L34.2742 83.247L39.9661 90.402L40.3882 90.933L40.5712 90.28L42.1422 84.663L42.7302 84.768L42.2751 84.189L42.3301 83.991L42.0842 83.947L36.5002 76.851Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M174.381 32.071L165.342 21.741L165.292 21.653L160.758 13.774L160.688 13.653L160.554 13.614L155.41 12.1219L150.813 4.20795L150.725 4.05493L150.549 4.03699L144.612 3.42297L143.458 3.30298L144.357 4.03595L154.912 12.6459L159.457 20.472L159.525 20.5889L159.575 20.605L169.296 30.002L169.319 30.043L169.35 30.0599L178.333 40.345L182.687 47.963L182.714 48.009L182.753 48.045L186.591 51.606L187.107 51.147L178.908 39.9529L174.461 32.1719L174.413 32.088L174.381 32.071ZM51.6788 114.927L50.5638 114.562L51.3008 115.475L56.8958 122.404L58.5718 127.219L58.6178 127.35L58.6758 127.38L64.2328 134.236L64.8288 133.9L63.0548 128.959L63.0088 128.83L62.9518 128.801L57.5358 122.093L56.8918 116.845L56.8648 116.627L56.6558 116.558L51.6788 114.927ZM40.5088 89.97L39.9898 89.3179L39.8928 90.1459L39.2878 95.317L38.6758 95.166L39.2258 95.8519L39.2068 96.012L39.3898 96.0569L45.0148 103.067L45.4818 108.174L45.5018 108.395L45.7108 108.47L50.8868 110.304L52.0368 110.711L51.2728 109.76L45.7088 102.825L46.2058 97.738L46.8048 97.886L46.2588 97.199L46.2748 97.041L46.0978 96.9969L40.5088 89.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M150.754 4.13184L150.213 4.55585L154.88 12.5908L154.95 12.7108L155.083 12.7498L160.36 14.2798L160.697 13.6968L150.754 4.13184ZM45.9429 107.816L44.7859 107.406L45.5569 108.361L51.2619 115.426L52.8569 120.166L52.8999 120.296L53.0199 120.361L57.0379 122.538L57.4729 122.016L51.9049 115.118L51.3479 109.94L51.3239 109.723L51.1189 109.65L45.9429 107.816ZM34.7349 82.7128L34.2309 82.0778L34.1199 82.8809L33.4199 87.9849L32.7739 87.8109L33.3459 88.5228L33.3249 88.6779L33.5099 88.7278L39.3169 95.9648L39.8359 96.6118L39.9319 95.7878L40.5349 90.6259L41.1659 90.7968L40.5979 90.0828L40.6159 89.9288L40.4379 89.8809L34.7349 82.7128ZM182.719 43.4348L182.692 43.3868L182.677 43.3738L174.439 32.1389L173.859 32.5158L178.316 40.3148L178.343 40.3618L178.358 40.3759L186.547 51.5568L187.129 51.1808L182.719 43.4348Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.916 24.2189L169.866 24.1329L169.834 24.1159L160.74 13.7439L156.065 5.61792L155.993 5.49292L155.854 5.45587L150.603 4.04688L150.273 4.6319L160.181 14.1619L164.758 22.1169L164.807 22.2019L164.839 22.2189L173.899 32.5729L174.461 32.1719L169.916 24.2189ZM53.3511 119.751L52.9161 120.274L58.6301 127.324L59.2272 126.991L57.5311 122.119L57.4872 121.992L57.3691 121.928L53.3511 119.751ZM39.8582 95.5309L39.1592 94.6599L39.2411 95.7739L39.6232 100.912L39.6392 101.132L39.8452 101.211L45.2272 103.256L46.4182 103.708L45.6212 102.714L39.8582 95.5309Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.7773 83.0781L34.8973 82.826L34.6773 82.6551L17.8054 69.4801L17.3193 69.1011L17.2473 69.7131L9.65234 134.245L10.3104 134.435L34.7773 83.0781Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.0916 100.562L38.8926 100.107L39.6986 101.105L45.5566 108.361L46.2766 109.252L46.1716 108.111L45.6956 102.9L45.6756 102.684L45.4736 102.607L40.0916 100.562Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9359 69.7941L17.2739 69.6151L1.44989 105.969L1.3999 106.084L1.43494 106.205L9.66388 134.382L10.3409 134.326L17.9359 69.7941ZM176.121 -18.8399L175.55 -19.2319L155.494 5.57306L155.222 5.9101L155.61 6.10205L160.095 8.3161L160.386 8.45911L160.549 8.1781L176.121 -18.8399Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M178.22 35.531L178.194 35.486L178.179 35.472L169.906 24.202L165.279 16.108L165.228 16.019L165.196 16.002L156.025 5.56201L155.463 5.96399L160.156 14.12L160.207 14.208L160.24 14.225L169.331 24.594L173.859 32.516L173.887 32.565L173.902 32.579L182.126 43.795L186.526 51.524L186.532 51.535L186.539 51.546L189.47 55.903L189.835 55.669L190.059 55.537L185.602 47.7L185.596 47.688L185.593 47.684L178.22 35.531ZM55.6379 124.541L58.6309 127.329L61.3688 131.604L61.3928 131.642L61.4078 131.656L67.0638 138.624L67.6238 138.218L64.7938 133.829L64.7698 133.792L64.7559 133.779L59.1688 126.887L53.4949 119.886L51.8998 115.146L51.8569 115.021L51.7988 114.988L46.0969 107.925L45.4968 108.25L47.0269 112.971L47.0688 113.099L47.1288 113.134L52.9039 120.259L55.5809 124.473L55.6049 124.51L55.6379 124.541ZM33.9808 88.206L33.3038 87.362L33.3638 88.442L33.6149 92.958L32.8809 92.661L33.6519 93.615L33.6578 93.723L33.7789 93.772L39.6989 101.105L40.3969 101.969L40.3138 100.861L39.9749 96.279L40.6898 96.568L39.9249 95.615L39.9169 95.507L39.8008 95.46L33.9808 88.206Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M173.634 27.476L173.605 27.4249L173.59 27.4109L165.269 16.0909L160.549 7.83295L160.497 7.74097L160.402 7.69397L155.917 5.47998L155.503 6.01996L164.694 16.483L169.313 24.564L169.342 24.614L169.357 24.6279L177.641 35.912L178.222 35.535L173.634 27.476ZM56.1097 124.033L55.6047 124.506L61.3907 131.636L61.9527 131.23L59.1917 126.918L59.1677 126.881L59.1357 126.852L56.1097 124.033ZM40.2377 100.669L39.6367 100.99L41.0777 105.63L41.1177 105.758L41.1787 105.794L47.0867 113.083L47.6867 112.758L46.1567 108.036L46.1157 107.911L46.0567 107.876L40.2377 100.669Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.11395 106.091L1.42096 106.09L1.00195 114.19L1 114.225L1.00494 114.261L2.16498 121.759L2.85394 121.689L2.11395 106.091Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M181.055 39.704L181.048 39.692L181.045 39.688L173.629 27.467L173.031 27.819L177.619 35.878L177.626 35.89L177.629 35.894L185.004 48.051L185.602 47.7L181.055 39.704ZM47.626 112.646L47.063 113.049L49.675 117.196L49.699 117.233L49.73 117.263L52.947 120.307L53.455 119.837L47.626 112.646Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M168.953 19.2528L168.923 19.1998L168.908 19.1868L160.527 7.79883L159.947 8.17682L164.677 16.4518L164.707 16.5048L164.722 16.5178L173.053 27.8528L173.634 27.4758L168.953 19.2528ZM50.2072 116.759L49.6992 117.23L55.6052 124.506L56.1672 124.101L53.4781 119.87L53.4551 119.833L53.4241 119.804L50.2072 116.759ZM34.2621 93.2668L33.6592 93.5828L35.0082 98.1378L35.0461 98.2658L35.1592 98.3348L39.7872 101.182L40.2382 100.669L34.2621 93.2668Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.5223 97.7439L35.0713 98.2579L41.1403 105.746L41.7413 105.424L40.3003 100.784L40.2613 100.66L40.1503 100.591L35.5223 97.7439ZM176.414 31.5429L176.407 31.5309L176.404 31.5269L168.948 19.2439L168.35 19.5959L173.032 27.8189L173.039 27.8309L173.041 27.8349L180.457 40.0549L181.055 39.7039L176.414 31.5429Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.0176 88.5818L34.0456 88.5288L34.0536 88.4698L34.8076 82.9758L34.1506 82.7788L9.68359 134.136L10.3046 134.445L34.0176 88.5818Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6792 105.309L41.1152 105.711L43.6482 109.768L43.6712 109.805L43.6862 109.82L49.7002 117.23L50.2622 116.826L47.6502 112.68L47.6282 112.644L47.6132 112.629L41.6792 105.309ZM164.176 10.86L164.145 10.806L164.097 10.766L160.471 7.73804L159.969 8.21002L168.373 19.63L168.953 19.253L164.176 10.86Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M171.678 23.2141L171.67 23.201L171.667 23.1971L164.171 10.8521L163.573 11.2041L168.351 19.5961L168.358 19.6091L168.361 19.6131L175.816 31.8951L176.414 31.5431L171.678 23.2141ZM35.6099 97.8211L35.0459 98.2221L37.4939 102.186L37.5169 102.222L37.5469 102.252L41.1679 105.776L41.6789 105.309L35.6099 97.8211Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.291 93.66L34.344 93.57L34.338 93.465L34.056 88.403L33.986 87.134L33.402 88.263L9.68896 134.126L10.296 134.461L34.291 93.66Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.031 101.755L37.52 102.222L43.6721 109.803L44.236 109.401L41.704 105.344L41.681 105.308L41.6511 105.279L38.031 101.755Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.10089 106.01L1.4209 106.124L2.16089 121.722L2.16589 121.809L2.2099 121.883L9.69891 134.463L10.3299 134.188L2.10089 106.01ZM203.086 -5.20404L203.556 -5.71204L198.491 -11.024L198.461 -11.0551L198.424 -11.079L192.031 -15.067L191.628 -14.5031L203.086 -5.20404ZM176.162 -18.957L175.48 -19.0811L167.792 19.491L167.782 19.537L167.786 19.584L168.183 25.0889L168.871 25.1199L176.162 -18.957Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.6252 98.2379L35.7202 98.1009L35.6732 97.9409L34.3242 93.3859L34.0982 92.6199L33.6932 93.3089L9.69824 134.109L10.2812 134.484L35.6252 98.2379Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.226 163.285L35.73 163.769L41.8929 170.706L41.915 170.73L41.941 170.75L49 176.167L49.448 175.638L36.226 163.285Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.162 -18.9581L175.478 -19.0701L175.477 -19.069L168.186 25.007L168.185 25.0129L167.261 31.3309L167.26 31.337L167.946 31.4429L167.947 31.437L176.162 -18.957V-18.9581ZM38.0519 102.23L38.2179 102.037L38.0839 101.821L35.6359 97.8569L35.3589 97.408L35.0559 97.8409L9.71191 134.086L10.2599 134.511L38.0519 102.23Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.987 36.0059L224.341 36.2448L226.373 44.6088L226.277 44.6278L226.468 44.9988L226.537 45.2828L226.606 45.2689L230.81 53.4468L231.455 53.2048L229.337 44.7148L229.429 44.6959L229.239 44.3248L229.169 44.0408L229.101 44.0548L224.987 36.0059ZM29.933 156.203L29.437 156.687L35.73 163.769L35.7531 163.795L35.7661 163.804L48.9741 176.145L49.471 175.661L43.2191 168.598L43.196 168.572L43.183 168.562L29.933 156.203Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.80597 121.528L2.18799 121.841L5.03296 128.591L5.05194 128.635L5.08197 128.672L9.72498 134.501L10.295 134.108L2.80597 121.528Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6049 105.536L41.8819 105.28L41.6039 105.026L38.0229 101.748L37.7589 101.506L37.5259 101.777L9.73389 134.059L10.2319 134.54L41.6049 105.536Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.5058 148.97L23.0098 149.454L29.4368 156.687L29.4598 156.713L29.4878 156.734L36.3628 161.889L36.8078 161.358L23.5058 148.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.831 161.382L36.808 161.355L36.779 161.334L29.905 156.179L29.46 156.71L42.71 169.07L48.951 176.121L48.999 176.176L49.066 176.206L58.016 180.339L58.3669 179.745L43.194 168.57L36.831 161.382ZM222.359 35.936L221.823 35.9639L222.068 36.441L226.486 45.0359L227.132 44.795L225.015 36.082L224.948 35.8019L224.66 35.817L222.359 35.936ZM216.248 18.907L215.601 19.142L217.523 27.577L217.58 27.825L217.833 27.8459L220.326 28.0499L220.947 28.101L220.664 27.5469L216.248 18.907Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3177 108.05L46.7657 107.722L46.2737 107.464L41.5307 104.975L41.3137 104.861L41.1337 105.027L9.76074 134.031L10.2017 134.565L46.3177 108.05Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9413 141.581L16.4453 142.065L23.0114 149.454L23.0353 149.481L23.0483 149.491L36.3353 161.865L36.8314 161.382L30.3043 154.007L30.2803 153.98L30.2664 153.97L16.9413 141.581ZM227.103 44.719L226.448 44.882L226.577 54.981L226.583 55.488L227.053 55.299L231.248 53.61L231.601 53.468L231.427 53.13L227.103 44.719ZM217.89 27.154L217.268 27.103L217.553 27.659L222.068 36.442L222.714 36.203L220.692 27.625L220.635 27.38L220.383 27.359L217.89 27.154Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7763 161.332L36.3113 161.841L42.6993 169.058L42.7483 169.114L42.7833 169.129L57.9553 180.304L58.4223 179.796L52.1053 172.593L52.0553 172.537L52.0203 172.521L36.7763 161.332ZM226.794 54.6551L226.439 54.7981L226.616 55.1371L231.051 63.6571L231.705 63.4891L231.465 53.2811L231.454 52.7801L230.989 52.9671L226.794 54.6551ZM211.736 10.0811L211.088 10.313L212.907 18.5961L212.956 18.8171L213.177 18.8621L215.87 19.4051L216.575 19.5471L216.248 18.9071L211.736 10.0811ZM10.2323 134.031L9.7373 134.516L16.4453 142.065L16.4703 142.093L16.5003 142.115L23.1683 146.98L23.6093 146.446L10.2323 134.031Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.218 109.658L52.926 109.238L52.131 109.024L46.203 107.436L46.0421 107.393L45.907 107.491L9.79102 134.006L10.173 134.584L52.218 109.658Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6328 146.47L23.6077 146.442L23.5767 146.42L16.9088 141.555L16.4688 142.089L29.8077 154.491L30.3037 154.007L23.6328 146.47ZM222.685 36.124L222.03 36.283L222.043 46.268L222.044 46.74L222.494 46.598L226.899 45.208L227.291 45.084L227.103 44.719L222.685 36.124Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M213.536 18.227L211.894 10.746L212.11 10.812L211.757 10.12L211.723 9.96704L211.67 9.95001L207.126 1.06104L206.478 1.29102L208.066 8.83704L207.841 8.76703L208.197 9.45905L208.23 9.61304L208.285 9.63104L212.608 18.039L212.897 18.602L212.665 28.243L212.655 28.658L213.065 28.594L217.51 27.905L217.41 37.085L217.239 37.125L217.406 37.444L217.402 37.799L217.57 37.76L221.891 46.061L222.082 46.427L226.561 55.031L224.48 66.21L224.307 66.287L224.424 66.507L224.325 67.04L224.633 66.901L229.05 75.218L229.525 76.113L229.698 75.114L231.669 63.738L231.836 63.662L231.72 63.44L231.812 62.913L231.515 63.046L227.269 54.89L227.141 44.873L227.135 44.406L226.69 44.546L222.736 45.794L222.724 36.558L222.888 36.52L222.723 36.199V35.846L222.561 35.884L218.39 27.769L218.19 27.38L216.277 18.988L216.227 18.769L216.007 18.725L213.536 18.227ZM36.3618 161.899L36.3967 161.914L51.6077 173.079L51.6577 173.136L57.8997 180.253L57.9727 180.336L58.0797 180.362L68.9807 182.967L69.0917 182.684L69.3237 182.403L62.9717 175.05L62.8977 174.964L62.8358 174.95L45.6087 165.187L45.5967 165.173L39.0457 157.704L38.9927 157.644L38.9567 157.629L23.6077 146.442L16.8117 138.764L16.7857 138.735L16.7547 138.713L10.1987 134.004L9.76074 134.54L23.1237 146.943L29.7838 154.467L29.8367 154.526L36.3107 161.841L36.3618 161.899Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.16 133.979L9.79395 134.567L16.35 139.276L16.3849 139.301L16.426 139.317L25 142.682L25.29 142.053L10.16 133.979Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.756 138.714L16.293 139.224L23.113 146.93L23.167 146.991L23.2029 147.005L38.581 158.213L39.046 157.704L32.2939 150.006L32.24 149.944L32.2029 149.929L16.756 138.714ZM222.698 46.1069L222.243 45.2339L222.05 46.199L219.774 57.584L219.649 58.207L220.241 57.975L227.051 55.2999L227.41 55.1589L227.231 54.817L222.698 46.1069ZM212.959 27.9089L212.481 27.983L212.704 28.412L217.446 37.5209L218.101 37.3649L218.209 27.504L218.213 27.095L217.809 27.157L212.959 27.9089Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9556 157.631L38.5246 158.161L45.1266 165.689L45.2026 165.776L45.2626 165.788L62.4866 175.55L68.7986 182.857L68.8936 182.967L69.0396 182.976L81.9186 183.795L82.0766 183.13L62.9196 174.99L56.4756 167.531L56.3996 167.443L56.3376 167.429L38.9556 157.631ZM219.987 57.3289L219.625 57.4708L219.808 57.8148L224.409 66.4778L219.941 78.7288L219.67 79.4728L220.401 79.1678L229.49 75.3748L229.842 75.2288L229.663 74.8928L225.151 66.3958L227.265 55.0408L227.379 54.4258L226.797 54.6538L219.987 57.3289ZM208.836 9.18384L208.231 8.00781L208.181 9.32886L207.825 18.6108L207.611 18.6288L207.81 19.0109L207.799 19.3088L207.959 19.2968L212.704 28.4119L213.358 28.2598L213.586 18.8428L213.793 18.8258L213.595 18.4418L213.602 18.1448L213.449 18.1578L208.836 9.18384ZM218.061 37.2008L217.617 36.3469L217.415 37.2888L214.993 48.5748L214.865 49.1718L215.443 48.9768L222.501 46.5958L222.885 46.4669L222.698 46.1068L218.061 37.2008ZM25.3866 142.131L25.3316 142.067L25.2526 142.036L16.6786 138.672L16.3486 139.275L31.8296 150.515L32.2936 150.006L25.3866 142.131Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.2029 149.932L31.7729 150.463L38.524 158.161L38.602 158.25L38.663 158.262L55.991 168.03L62.448 175.504L62.5439 175.616L62.631 175.62L81.805 183.768L82.206 183.225L75.855 175.711L75.758 175.596L75.668 175.591L56.423 167.469L49.831 159.838L49.753 159.747L49.6899 159.735L32.2029 149.932ZM215.221 48.3188L214.834 48.4498L215.026 48.8109L219.808 57.8138L220.263 58.6729L220.454 57.7198L222.73 46.3348L222.848 45.7468L222.279 45.9388L215.221 48.3188ZM220.133 78.5269L219.778 78.6758L219.963 79.0139L224.686 87.6688L225.06 88.3538L225.318 87.6179L229.684 75.1699L229.942 74.4348L229.223 74.7349L220.133 78.5269ZM213.319 28.0908L212.885 27.2568L212.674 28.1738L210.1 39.3558L209.969 39.9268L210.533 39.7668L217.848 37.6948L218.258 37.5789L218.061 37.2008L213.319 28.0908Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.082 133.949L9.83301 134.591L24.963 142.665L25.028 142.7L25.101 142.705L48.627 144.41L48.7371 143.728L10.082 133.949ZM165.157 38.1689L164.994 38.2039L164.919 38.3529L161.102 45.9889L160.711 46.7699L161.531 46.4689L205.548 30.3259L205.357 29.6609L165.157 38.1689Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M220.42 57.4889L220.061 56.8129L219.789 57.5289L215.164 69.7199L214.937 69.8059L215.052 70.0149L214.835 70.5859L215.273 70.4209L219.962 79.0139L220.33 79.6869L220.593 78.9659L225.059 66.7199L225.279 66.6369L225.166 66.4259L225.373 65.8599L224.951 66.0189L220.42 57.4889ZM25.2952 142.056L24.8652 142.588L31.7722 150.463L31.8522 150.554L31.9142 150.565L49.3452 160.337L55.9512 167.984L56.0502 168.098L56.1372 168.102L75.3782 176.222L81.6762 183.673L81.7912 183.809L81.9692 183.794L96.3362 182.615L96.4092 181.938L75.7892 175.633L69.3562 168.021L69.2572 167.905L69.1672 167.901L49.7772 159.776L43.0302 151.966L42.9502 151.873L42.8862 151.862L25.2952 142.056ZM210.344 39.0999L209.93 39.2169L210.132 39.5959L215.026 48.8109L215.472 49.6499L215.671 48.7209L218.093 37.4339L218.214 36.8699L217.659 37.0269L210.344 39.0999Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M208.184 9.29102L205.116 29.746L161.292 45.818L161.185 45.858L161.122 45.954L156.014 53.75L155.324 54.804L156.456 54.252L205.58 30.313L205.457 30.023L205.765 30.084L208.497 19.015L208.505 18.981L208.507 18.945L208.874 9.35602L208.184 9.29102ZM52.1254 109.023L51.9874 108.989L51.8643 109.061L9.82031 133.987L10.0313 134.631L111.363 124.477L111.412 123.795L52.1254 109.023Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M220.571 78.681L220.263 78.117L219.961 78.684L213.245 91.287L212.983 91.38L213.093 91.574L212.762 92.194L213.329 91.994L218.079 100.413L218.394 100.971L218.689 100.402L225.236 87.784L225.49 87.694L225.384 87.499L225.703 86.883L225.154 87.078L220.571 78.681ZM208.468 18.772L208.044 17.957L207.823 18.849L205.143 29.711L204.91 29.764L205.06 30.046L204.957 30.466L205.247 30.399L210.132 39.596L210.568 40.417L210.776 39.511L213.301 28.541L213.526 28.489L213.378 28.205L213.474 27.789L213.195 27.853L208.468 18.772ZM215.638 48.485L215.286 47.821L215.009 48.521L210.22 60.645L209.982 60.726L210.101 60.945L209.886 61.491L210.319 61.344L215.128 70.156L215.489 70.818L215.757 70.112L220.38 57.928L220.611 57.849L220.494 57.628L220.699 57.087L220.282 57.228L215.638 48.485ZM90.264 174.368L90.147 174.226L90.031 174.236L69.289 167.943L62.703 160.151L62.603 160.032L62.512 160.03L42.9771 151.904L36.069 143.908L35.9871 143.813L35.864 143.793L25.183 142.017L24.957 142.662L42.545 152.466L49.306 160.291L49.406 160.408L49.494 160.41L68.88 168.533L75.325 176.159L75.442 176.297L75.554 176.286L96.2061 182.601L97.3051 182.938L96.576 182.05L90.264 174.368Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M156.152 53.6279L156.083 53.662L156.034 53.723L149.745 61.541L150.188 62.059L205.601 30.3019L205.277 29.689L156.152 53.6279ZM59.2581 109.597L59.2271 109.589L59.195 109.586L52.069 109.014L51.957 109.696L111.237 124.466L111.245 124.468L111.42 123.797L111.412 123.795L59.2581 109.597Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0665 174.249L89.1016 174.047L89.7275 174.808L96.0396 182.49L96.1725 182.652L96.3785 182.609L111.54 179.42V178.741L90.0665 174.249ZM215.737 69.8229L215.433 69.2659L215.128 69.8229L208.174 82.5229L207.754 83.2899L208.586 83.0189L220.374 79.1769L220.771 79.0479L220.571 78.6809L215.737 69.8229Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M210.745 39.2709L210.398 38.6189L210.117 39.3009L205.096 51.5039L204.818 52.1789L205.516 51.9689L215.432 48.9799L215.836 48.8579L215.638 48.4849L210.745 39.2709ZM208.371 82.3599L207.968 82.4909L208.176 82.8599L213.186 91.7399L213.497 92.2909L213.794 91.7319L220.573 79.0109L220.978 78.2509L220.16 78.5179L208.371 82.3599ZM83.8019 166.504L83.683 166.359L83.567 166.372L62.538 160.043L61.4189 159.706L62.174 160.599L68.826 168.469L68.944 168.61L69.0569 168.598L89.895 174.92L90.991 175.252L90.264 174.368L83.8019 166.504Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1514 142.013L25.0693 142.701L35.7504 144.477L35.7794 144.482H35.8093L48.6544 144.411L48.6773 143.718L25.1514 142.013Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.939 143.814L35.5439 144.361L42.5049 152.419L42.608 152.538L42.764 152.539L55.624 152.663L55.7599 151.996L35.939 143.814Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M142.494 69.156L142.469 69.172L142.447 69.192L134.216 76.609L134.639 77.156L205.613 30.294L205.62 30.29L205.244 29.707L205.237 29.711L142.494 69.156ZM67.6002 109.041L67.5352 109.019L67.4661 109.024L59.1442 109.586L59.0762 110.267L111.237 124.466L111.439 123.803L67.6002 109.041Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.8918 152.093L55.7898 151.971L55.6308 151.97L42.7708 151.846L42.6338 152.513L62.3048 160.695L62.7028 160.151L55.8918 152.093ZM205.316 51.3039L204.907 51.4279L205.112 51.8029L210.179 61.0879L210.534 61.7379L210.806 61.0489L215.654 48.7749L215.918 48.1089L215.232 48.3159L205.316 51.3039ZM213.79 91.3989L213.519 90.9179L213.204 91.3709L204.298 104.152L203.732 104.966L204.682 104.683L218.48 100.575L218.897 100.451L218.684 100.072L213.79 91.3989Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.257 76.577L134.245 76.584L134.234 76.593L125.282 83.582L125.689 84.1429L205.62 30.29L205.622 30.2889L205.237 29.7109L205.235 29.713L134.257 76.577Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M125.301 83.5679H125.3L115.814 89.9829L115.813 89.9839L116.201 90.5589L205.621 30.2879L205.428 30.0009L205.234 29.7129L205.229 29.7159L125.301 83.5679Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M105.504 171.018L105.37 170.848L105.231 170.879L83.6036 166.384L82.6406 166.184L83.2656 166.944L89.7276 174.808L89.8626 174.973L89.9976 174.943L111.398 179.421L112.338 179.617L111.74 178.865L105.504 171.018ZM210.788 60.7549L210.488 60.2059L210.181 60.751L203.045 73.4249L202.624 74.173L203.446 73.9269L215.532 70.322L215.942 70.199L215.737 69.8229L210.788 60.7549ZM205.735 29.838L205.395 29.199L205.11 29.864L199.907 41.989L199.627 42.6429L200.314 42.462L210.527 39.769L210.95 39.657L210.745 39.2709L205.735 29.838Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M76.834 107.309L76.731 107.26L76.6201 107.282L67.425 109.029L66.124 109.277L67.379 109.699L111.218 124.46L111.478 123.818L76.834 107.309Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M203.248 73.262L202.831 73.387L203.045 73.765L208.176 82.86L208.483 83.403L208.782 82.856L215.737 70.156L216.142 69.416L215.333 69.657L203.248 73.262ZM105.276 170.889L104.433 170.784L104.961 171.449L111.197 179.297L111.347 179.486L111.577 179.411L127.196 174.289L127.131 173.616L105.276 170.889ZM77.1843 158.45L77.0634 158.304L76.9464 158.317L55.7274 151.984L54.6113 151.651L55.3624 152.541L62.1733 160.599L62.2943 160.741L62.4073 160.728L83.4343 167.056L84.5253 167.385L83.8023 166.504L77.1843 158.45Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.9621 133.94L9.91211 134.622L48.5671 144.4L48.6631 144.424L48.7571 144.395L111.433 124.462L111.294 123.786L9.9621 133.94Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.9169 143.84L48.8118 143.716L48.6498 143.717L35.8049 143.788L35.6738 144.456L55.4949 152.637L55.8918 152.093L48.9169 143.84ZM200.137 41.7909L199.709 41.9039L199.921 42.2919L205.112 51.8029L205.46 52.4409L205.737 51.7689L210.759 39.5659L211.024 38.9199L210.35 39.0979L200.137 41.7909ZM208.78 82.5189L208.512 82.0429L208.195 82.4889L199.174 95.1829L198.858 95.2699L198.973 95.4659L198.537 96.0799L199.221 95.8899L204.158 104.312L193.555 116.592L193.201 116.678L193.317 116.867L192.773 117.497L193.581 117.299L198.713 125.692L198.963 126.1L199.273 125.736L209.849 113.318L210.193 113.234L210.082 113.045L210.618 112.415L209.826 112.609L204.995 104.367L213.701 91.8699L214.008 91.7849L213.897 91.5889L214.323 90.9779L213.657 91.1629L208.78 82.5189ZM99.1168 162.981L98.9808 162.809L98.7678 162.858L83.4559 166.386L83.4628 167.064L105.162 171.573L106.101 171.768L105.504 171.018L99.1168 162.981Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M86.5347 104.48L86.3937 104.371L86.2238 104.421L76.5847 107.29L75.7197 107.547L76.5347 107.935L111.179 124.445L111.541 123.857L86.5347 104.48Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M142.817 166.69L121.14 165.613L121.087 165.543L120.926 165.597L98.9023 162.854L77.1063 158.355L70.4053 150.2L70.2833 150.052L70.0923 150.077L55.5823 151.973L55.5283 152.649L76.7153 158.972L83.2664 166.944L83.4023 167.11L83.6113 167.062L98.7114 163.583L104.961 171.449L105.113 171.64L105.268 171.588L126.901 174.286L126.978 174.387L127.14 174.316L127.866 174.407L127.631 174.099L142.939 167.353L142.817 166.69ZM205.721 51.47L205.425 50.929L205.116 51.462L197.862 63.981L197.569 64.0599L197.691 64.2759L197.368 64.8329L197.921 64.683L203.045 73.765L203.347 74.301L203.649 73.765L210.715 61.218L210.999 61.1409L210.88 60.924L211.192 60.3699L210.657 60.515L205.721 51.47Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M96.5119 100.651L96.3569 100.413L96.0929 100.518L86.1949 104.431L85.6289 104.655L86.1099 105.028L111.116 124.406L111.62 123.943L96.5119 100.651Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7507 143.731L47.6387 143.402L48.3867 144.288L55.3627 152.541L55.4847 152.685L55.6717 152.661L70.1816 150.764L70.2347 150.088L48.7507 143.731ZM180.779 128.871L180.139 129.485L181.059 129.309L186.389 137.641L186.618 137.999L186.922 137.703L199.176 125.832L199.566 125.757L199.45 125.566L200.085 124.951L199.18 125.124L194.041 116.721L193.816 116.353L193.505 116.652L181.058 128.602L180.657 128.679L180.779 128.871ZM203.649 73.4241L203.384 72.9541L203.066 73.3911L193.853 86.0951L193.528 86.1781L193.646 86.3801L193.214 86.9761L193.893 86.8011L199.086 95.6591L199.358 96.1221L199.668 95.6851L208.688 82.9941L209.002 82.9121L208.889 82.7111L209.311 82.1171L208.65 82.2871L203.649 73.4241Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M106.524 95.913L106.442 95.463L106.03 95.664L96.0679 100.529L95.7158 100.7L95.9299 101.029L111.037 124.32L111.67 124.069L106.524 95.913ZM192.396 54.572L193.002 54.909L200.524 42.304L200.536 42.284L200.545 42.263L205.747 30.138L205.12 29.842L192.396 54.572Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M136.736 158.528L136.137 158.539L136.279 158.732L120.82 165.634L120.944 166.297L142.617 167.374L142.7 167.487L142.881 167.387L143.51 167.419L143.312 167.148L158.39 158.792L158.216 158.142L136.986 158.524L136.927 158.443L136.736 158.528ZM187.922 108.046L187.384 108.661L188.186 108.477L193.449 117.083L193.697 117.488L194.007 117.129L204.772 104.663L205.121 104.583L205.008 104.389L205.538 103.775L204.754 103.955L199.685 95.3081L199.441 94.8931L199.124 95.2551L188.164 107.77L187.804 107.853L187.922 108.046ZM92.574 154.747L92.437 154.573L92.222 154.625L76.835 158.333L76.8459 159.01L98.776 163.536L99.713 163.729L99.118 162.98L92.574 154.747Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M116.352 90.319L116.449 89.615L115.834 89.972L106.008 95.676L105.798 95.798L105.841 96.038L110.987 124.194L111.672 124.179L116.352 90.319Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M114.961 157.535L114.809 157.337L114.573 157.418L98.7331 162.868L98.8031 163.54L120.919 166.295L121.74 166.397L121.237 165.74L114.961 157.535ZM167.279 139.771L166.73 139.846L167.038 140.307L172.355 148.243L152.37 149.84L151.765 149.888L152.113 150.385L157.938 158.688L158.134 158.967L158.416 158.776L172.878 149.024L172.895 149.05L173.179 148.821L174.261 148.091L174.062 148.107L186.899 137.724L187.867 136.941L186.634 137.111L167.279 139.771ZM70.2071 150.081L69.2471 149.884L69.8691 150.641L76.6481 158.89L76.7861 159.058L76.9971 159.007L92.3841 155.3L92.3731 154.623L70.2071 150.081ZM200.53 41.96L200.239 41.427L199.928 41.948L192.406 54.553L191.982 55.263L192.786 55.068L205.498 51.973L205.937 51.867L205.721 51.47L200.53 41.96Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M115.814 89.9839L115.686 90.0699L115.664 90.2239L110.985 124.084L110.844 125.107L111.574 124.377L205.674 30.2459L205.235 29.7129L115.814 89.9839Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M193.917 116.519L188.781 108.119L199.571 95.799L199.927 95.722L199.811 95.524L200.336 94.9249L199.558 95.092L194.474 86.42L203.552 73.903L203.876 73.8259L203.759 73.618L204.177 73.0419L203.521 73.197L198.49 64.28L205.716 51.8099L206.124 51.1069L205.334 51.299L192.622 54.394L192.177 54.502L192.402 54.9009L197.678 64.2529L188.399 76.782L188.066 76.861L188.188 77.0679L187.759 77.647L188.433 77.4869L193.634 86.358L182.64 98.7289L182.273 98.808L182.394 99.006L181.861 99.606L182.657 99.4349L187.909 108.025L175.469 119.859L175.062 119.933L175.187 120.128L174.554 120.73L175.466 120.565L180.638 128.65L161.496 131.134L160.941 131.206L161.253 131.671L166.707 139.813L146.402 141.331L145.794 141.376L146.144 141.876L151.737 149.849L130.852 150.161L130.791 150.078L130.6 150.164L130 150.173L130.145 150.37L114.794 157.317L108.53 149.127L108.376 148.927L108.139 149.011L92.4207 154.553L85.8687 146.309L85.7297 146.133L85.5897 146.169L63.3807 141.652L63.3347 141.596L63.2007 141.616L62.3027 141.433L62.5317 141.712L48.6027 143.721L48.5537 144.397L69.9367 150.723L70.0087 150.811L70.1297 150.78L71.1227 151.074L71.0267 150.957L92.1097 155.277L98.5747 163.412L98.7267 163.604L98.9587 163.524L114.562 158.156L120.686 166.162L120.853 166.38L121.103 166.268L136.698 159.305L136.714 159.326L136.894 159.225L137.524 159.258L137.488 159.209L158.228 158.836L158.881 158.824L158.506 158.29L153.03 150.483L173 148.888L173.59 148.841L173.26 148.349L167.924 140.382L186.728 137.798L187.265 137.724L186.973 137.267L181.684 128.999L182.402 128.421L182.225 128.444L193.91 117.225L194.305 117.153L194.186 116.959L194.815 116.356L193.917 116.519Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.582 143.724L48.702 144.407L63.2401 142.311L63.26 142.308L63.2791 142.303L78.813 138.21L78.6541 137.535L48.582 143.724ZM171.06 80.0819L171.613 80.4989L192.97 54.9529L192.995 54.9239L193.013 54.8899L205.737 30.1589L205.141 29.8059L171.06 80.0819Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M188.525 107.701L183.254 99.0831L194.242 86.7171L194.606 86.6451L194.487 86.4421L195.007 85.8571L194.234 86.0111L189.018 77.1121L198.292 64.5901L198.624 64.5181L198.503 64.3041L198.918 63.7441L198.267 63.8851L193.006 54.5611L192.747 54.1001L192.428 54.5221L182.809 67.2341L182.466 67.3091L182.591 67.5221L182.166 68.0831L182.836 67.938L188.175 77.0461L176.977 89.4611L176.603 89.5361L176.727 89.7381L176.199 90.3241L176.988 90.1661L182.381 98.9851L169.74 110.897L169.327 110.968L169.455 111.166L168.829 111.756L169.733 111.602L175.044 119.905L155.568 122.279L155.007 122.348L155.322 122.817L160.919 131.173L140.285 132.607L139.672 132.65L140.025 133.153L145.767 141.337L124.563 141.588L124.502 141.504L124.312 141.591L123.71 141.598L123.858 141.799L108.361 148.907L101.936 140.508L101.782 140.306L101.543 140.392L85.7141 146.114L78.996 137.659L78.855 137.482L78.636 137.54L63.1021 141.632L63.122 142.308L85.405 146.839L92.031 155.178L92.1851 155.372L92.418 155.29L108.132 149.749L114.41 157.957L114.578 158.176L114.829 158.062L130.564 150.942L130.579 150.963L130.76 150.862L131.39 150.895L131.355 150.847L152.402 150.533L153.057 150.523L152.681 149.987L147.063 141.977L167.352 140.46L167.946 140.416L167.615 139.921L162.141 131.75L181.269 129.268L181.811 129.197L181.517 128.737L176.093 120.258L176.801 119.69L176.632 119.711L188.514 108.407L188.915 108.339L188.794 108.141L189.415 107.55L188.525 107.701Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.183 29.7559L111.143 123.827L48.5469 143.733L48.7219 144.404L78.7938 138.215L78.8278 138.208L78.8588 138.194L111.463 124.451L111.385 124.238L111.533 124.412L171.552 80.5568L171.601 80.5208L171.634 80.4708L205.716 30.1948L205.183 29.7559Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.441 111.145L155.404 122.344L140.126 132.658L124.307 141.585L101.691 140.372L78.7668 137.531L77.9238 137.426L78.4518 138.091L85.3259 146.74L85.4808 146.935L85.7148 146.851L101.54 141.131L107.978 149.548L108.147 149.768L108.398 149.653L124.467 142.283L146.432 142.023L147.09 142.015L146.712 141.477L140.945 133.257L161.565 131.823L162.162 131.782L161.829 131.284L156.212 122.9L175.675 120.526L176.222 120.46L175.925 119.995L170.349 111.277L182.984 99.3698L183.39 99.3048L183.267 99.1028L183.881 98.5248L182.999 98.6649L177.454 89.5969L177.232 89.2349L176.921 89.5248L163.866 101.708L163.447 101.774L163.577 101.977L162.958 102.555L163.855 102.412L169.441 111.145Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M133.531 123.895L117.859 132.795L95.078 131.542L95.02 131.465L94.847 131.529L94.1671 131.492L94.3391 131.717L78.604 137.549L78.681 138.219L101.474 141.044L101.554 141.15L101.717 141.075L102.439 141.164L102.393 141.105L124.207 142.275L124.292 142.39L124.472 142.289L125.102 142.323L124.9 142.047L140.206 133.41L140.221 133.431L140.416 133.299L140.972 133.294L140.796 133.043L155.515 123.106L155.531 123.13L155.741 122.963L156.233 122.931L156.09 122.718L156.864 122.196L156.688 122.207L170.04 111.555L170.494 111.503L170.362 111.298L171.061 110.74L170.077 110.852L164.317 101.847L164.11 101.523L163.809 101.762L149.39 113.211L148.923 113.264L149.047 113.45L133.918 123.668L133.395 123.702L133.531 123.895ZM170.915 80.2349L170.392 80.8058L171.176 80.6608L176.862 89.9589L177.104 90.3558L177.415 90.0109L188.781 77.4099L189.152 77.3408L189.03 77.1339L189.545 76.5628L188.779 76.7048L183.307 67.3708L183.069 66.9648L182.752 67.3118L171.17 79.9558L170.788 80.0269L170.915 80.2349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.5889 137.555L78.8448 138.2L95.0198 132.205L95.0339 132.2L95.0479 132.193L111.476 124.445L111.194 123.812L78.5889 137.555Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M157.536 92.5328L143.153 103.897L142.681 103.947L142.822 104.158L142.133 104.702L143.117 104.598L148.902 113.234L127.77 114.475L127.7 114.375L127.496 114.491L126.956 114.523L127.095 114.72L111.236 123.785L110.647 123.788L110.8 123.997L94.752 131.566L94.881 132.226L117.759 133.484L117.844 133.601L118.024 133.499L118.655 133.534L118.452 133.256L133.931 124.466L133.946 124.486L134.141 124.355L134.699 124.351L134.537 124.121L135.36 123.654L135.177 123.655L149.433 114.027L149.449 114.05L149.733 113.824L150.77 113.124L150.603 113.134L163.94 102.544L163.957 102.571L164.177 102.366L164.62 102.32L164.486 102.111L165.176 101.563L165.02 101.58L177.315 90.1058L177.727 90.0449L177.601 89.8388L178.208 89.2719L177.334 89.4019L171.643 80.0958L171.423 79.7349L171.112 80.0219L157.841 92.2828L157.416 92.3448L157.536 92.5328Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M111.124 123.851L111.512 124.426L143.472 104.525L143.495 104.512L143.509 104.499L158.213 92.8821L158.223 92.8741L158.233 92.8651L171.583 80.5311L171.426 80.3541L171.143 79.9961L111.124 123.851Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M143.576 104.038L143.383 103.749L143.094 103.944L127.402 114.545L126.355 115.253L127.617 115.179L149.547 113.891L150.152 113.855L149.815 113.351L143.576 104.038Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M111.145 123.837L111.501 124.433L127.77 115.134L127.781 115.127L127.792 115.12L143.483 104.519L143.106 103.937L111.145 123.837Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default FooTopLeft;
