import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const RightCenterGroup: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="158"
      height="153"
      viewBox="0 0 158 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.5733 60.9228L40.5703 60.9289L40.568 60.9351L38.1596 66.8646L38.1583 66.8684L38.1568 66.873L36.0554 72.8838L36.0536 72.8879L36.1661 72.9289L36.0511 72.8967L34.2759 78.97L34.2743 78.9746L34.2731 78.9796L32.8538 85.0842L33.2034 85.1708L36.3949 73.0068L46.5757 49.5805L53.3521 38.7632L53.0489 38.5681L49.5202 43.8816L49.5177 43.8855L49.5152 43.8894L46.2605 49.4048L46.3632 49.4668L46.2559 49.4123L43.2759 55.0956L43.2742 55.0997L43.2721 55.1033L40.5733 60.9228Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M63.2489 70.2367L62.8474 70.391L63.2393 70.5693L66.691 72.1403L61.6541 74.2689L61.2708 74.4304L61.6506 74.5996L65.4973 76.3095L65.6699 76.3859L65.7387 76.2107L67.306 72.2174L69.1169 68.321L71.1651 64.5411L71.3852 64.135L70.9484 64.285L65.0862 66.2942L64.6627 66.4392L65.0685 66.6285L68.4941 68.2237L63.2489 70.2367Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.2497 28.905L60.9619 28.6879L53.059 38.5546L53.0524 38.5629L53.0468 38.5717L46.2631 49.4001L46.5632 49.6001L61.2497 28.905Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.2211 66.301L65.0576 66.2246L64.9814 66.3885L63.2259 70.1661L63.216 70.1618L63.1481 70.3335L62.9627 70.7327L62.9958 70.7202L61.5567 74.3685L61.3991 74.7675L61.7947 74.6004L67.2103 72.3117L67.589 72.1517L67.2147 71.9816L63.7807 70.4188L69.0204 68.4079L69.4177 68.2556L69.0322 68.0759L65.2211 66.301ZM67.1542 62.4708L66.7061 62.6051L67.1283 62.8064L70.9291 64.6177L71.0744 64.687L71.1599 64.5508L73.4362 60.9195L73.6908 60.5133L73.232 60.651L67.1542 62.4708Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5881 49.5501L46.2508 49.4245L36.0588 72.8757L36.0513 72.8935L36.0477 72.9127L31.2289 97.2848L31.5781 97.3736L46.5881 49.5501Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.1682 78.625L60.0911 78.8611L60.1215 78.8471L59.1774 82.5221L58.8991 82.6594L59.118 82.7524L59.0588 82.9831L59.3373 82.8451L63.1641 84.4674L63.358 84.5499L63.4094 84.3457L64.4172 80.3305L64.6908 80.1953L64.4843 80.1059L65.7177 76.2754L66.0079 76.1418L65.7914 76.0457L65.8674 75.8097L65.569 75.9469L61.9546 74.3402L63.479 70.4764L65.2755 66.6105L71.0647 64.6262L71.4825 64.4828L71.0836 64.2929L67.7047 62.6825L73.2669 61.017L73.3399 61.0527L73.393 60.9795L73.7768 60.8644L73.5549 60.7563L75.925 57.4846L76.217 57.0821L75.7344 57.2043L69.5058 58.7829L69.4191 58.7404L69.3716 58.8168L68.9642 58.92L69.2273 59.0483L67.1373 62.4119L67.1278 62.4073L67.0799 62.4961L61.2091 64.488L60.7835 64.6325L61.1908 64.8222L64.6807 66.4478L59.3568 68.465L58.9517 68.6184L59.3461 68.7977L62.8617 70.3975L57.7409 72.5288L57.3539 72.6901L57.7368 72.8604L61.502 74.5336L60.2804 78.2806L60.2684 78.2757L60.237 78.397L59.9485 78.5295L60.1682 78.625Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.2429 28.9134L60.9655 28.6834L56.8695 33.4759L56.8661 33.4804L56.8622 33.4851L53.0554 38.5593L53.3403 38.7806L61.2429 28.9134Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.3438 64.4952L61.1803 64.4188L61.1041 64.5827L59.2577 68.5574L59.0713 68.959L59.4852 68.8022L65.2089 66.6329L65.6086 66.4816L65.221 66.3014L61.3438 64.4952Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M36.4012 72.9822L36.0501 72.9013L32.8554 85.0789L32.8522 85.0899L32.851 85.1009L31.2273 97.2959L31.5832 97.3545L36.4012 72.9822Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.8755 68.642L59.6612 68.5445L61.3988 64.8048L67.1943 62.838L67.274 62.8757L67.3248 62.7937L67.684 62.6721L67.4655 62.5674L69.6018 59.1305L75.7593 57.5698L75.8263 57.6035L75.8803 57.539L76.2952 57.4343L76.0651 57.3196L78.5889 54.3201L79.0326 54.229L78.7754 54.0984L78.9629 53.8755L78.5169 53.9667L74.768 52.06L74.6396 51.9946L74.5474 52.1057L71.934 55.262L71.9245 55.2574L71.8763 55.3245L71.433 55.4154L71.4658 55.4315L66.0099 56.8076L66.024 56.7846L65.6399 56.8981L65.5558 56.857L65.5086 56.9339L65.101 57.0367L65.1269 57.0496L59.3519 58.7525L58.9004 58.8852L59.3253 59.088L62.8373 60.7616L62.8586 60.7718L57.3334 62.6273L57.2349 62.5815L57.1915 62.6749L56.8372 62.7936L57.0831 62.9084L55.3722 66.5926L55.363 66.5884L55.3226 66.6897L54.9894 66.8145L55.2204 66.9196L55.1119 67.1539L55.142 67.1425L53.6894 70.7874L53.3695 70.9182L53.597 71.0193L53.5037 71.2529L53.8258 71.1208L57.5793 72.7896L57.4858 73.0258L57.5159 73.0132L56.342 76.5709L56.3304 76.5657L56.2988 76.6859L56.0059 76.8181L56.2285 76.9142L56.1516 77.1477L56.1807 77.1346L55.2074 80.8367L54.9247 80.9737L55.1467 81.0679L55.0866 81.2961L55.3656 81.1608L59.1283 82.7561L58.4079 86.6522L58.1459 86.7906L58.3654 86.8814L58.324 87.1045L58.5785 86.9699L62.4392 88.5689L62.6464 88.6543L62.686 88.4335L63.3976 84.4196L63.6548 84.2835L63.4376 84.1913L63.4771 83.9693L63.2269 84.1019L59.5457 82.5414L60.5452 78.6506L60.8232 78.5159L60.6133 78.4248L61.8711 74.5663L62.1654 74.4334L61.9464 74.3362L62.0226 74.1025L61.7238 74.237L58.2673 72.7004L63.3835 70.5715L63.7657 70.4124L63.3888 70.2409L59.8755 68.642Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5882 49.4457L46.421 48.8761L46.2431 49.4424L31.2333 97.2664L31.5688 97.396L49.3832 59.1234L49.4118 59.0619L49.3928 58.997L46.5882 49.4457Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M52.3688 74.9089L51.9929 75.0757L52.37 75.2392L56.3794 76.9799L56.5606 77.0585L56.6226 76.8709L57.9819 72.7514L58.1094 72.3658L57.7375 72.5301L52.3688 74.9089ZM59.4802 58.7625L59.3248 58.6885L59.2437 58.8402L57.1632 62.7359L56.9494 63.1364L57.3797 62.9916L63.3954 60.9714L63.8184 60.8297L63.4159 60.6378L59.4802 58.7625ZM68.1973 53.4011L68.0588 53.332L67.9697 53.4583L65.4763 56.9864L65.1953 57.3849L65.6676 57.2657L72.026 55.662L72.4978 55.5431L72.0619 55.3258L68.1973 53.4011Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5514 49.3768L46.2429 49.5468L49.0475 59.098L49.1727 59.5237L49.3798 59.1314L51.4802 55.1577L51.5384 55.0476L51.4564 54.9543L46.5514 49.3768ZM33.2083 85.149L32.8516 85.0952L31.8225 91.2045L31.8218 91.2104L31.8209 91.217L31.2265 97.3019L31.5848 97.3434L33.2083 85.149Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.9022 70.7597L53.7211 70.6792L53.6582 70.8673L52.2713 75.0167L52.1419 75.4041L52.5155 75.2387L57.8847 72.8596L58.2558 72.6951L57.8845 72.5299L53.9022 70.7597ZM75.1276 51.9708L75.1521 51.9409L74.6553 52.0426L74.105 52.1268L74.145 52.147L68.0814 53.3853L67.5688 53.4898L68.0371 53.7235L71.9021 55.6479L72.0301 55.7117L72.1212 55.6019L74.734 52.4465L78.4096 54.316L78.5273 54.3757L78.6197 54.2821L81.5454 51.3208L81.9278 50.9337L81.3902 51.0157L75.1276 51.9708ZM58.7733 86.6595L59.5103 82.6789L59.5768 82.3171L59.2501 82.4859L54.478 84.9497L54.1327 85.1279L54.4918 85.2768L58.3758 86.8853L57.9489 90.8472L57.9112 91.1954L58.2167 91.0236L62.5972 88.5588L62.9204 88.377L62.5781 88.235L58.7733 86.6595Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.4591 49.3207L45.9043 49.1863L46.2815 49.6147L51.1857 55.1921L51.3493 55.3777L51.4761 55.1657L53.7727 51.332L53.8987 51.1217L53.6604 51.0641L46.4591 49.3207Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.0393 90.7094L57.713 90.8931L58.0605 91.0337L62.0406 92.6447L62.2644 92.7347L62.2879 92.4951L62.6879 88.4196L62.7217 88.0749L62.4202 88.2444L58.0393 90.7094ZM61.5774 55.0177L61.1691 55.1203L61.4356 55.2501L59.2837 58.7746L53.251 60.7794L52.8204 60.9227L53.2315 61.1137L56.8544 62.8014L51.367 64.827L50.9562 64.9789L51.3549 65.1604L55.0033 66.8207L49.7086 68.9562L49.3139 69.1148L49.7026 69.2878L53.7559 71.0894L53.9271 71.1655L53.9967 70.9916L55.6263 66.9021L57.4842 62.9011L59.5269 59.0767L65.6079 57.2835L65.683 57.3197L65.7351 57.246L66.1209 57.132L65.8939 57.0215L68.2648 53.6663L68.5453 53.2688L68.0736 53.387L61.7128 54.9837L61.6239 54.9407L61.5774 55.0177ZM59.4028 82.4804L55.575 80.8574L56.6047 76.9412L56.8879 76.8071L56.6653 76.7109L56.7247 76.4855L56.4484 76.6161L52.514 74.9086L52.3227 74.8252L52.2681 75.0271L51.1666 79.1216L50.8786 79.2581L51.104 79.3538L51.0435 79.5792L51.3229 79.4467L55.1562 81.0718L54.3837 85.0761L54.313 85.4411L54.6433 85.2702L59.4152 82.807L59.7567 82.6306L59.4028 82.4804Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3806 49.3192L46.3733 49.6716L53.5746 51.415L53.696 51.4445L53.7662 51.3412L56.2526 47.6803L56.4994 47.3169L56.0682 47.4023L46.3806 49.3192Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.1461 60.8702L52.9372 61.2636L52.9686 61.2533L51.3415 64.758L51.3323 64.7538L51.2639 64.9246L51.0822 65.3168L51.1114 65.3059L49.6782 68.8822L49.6686 68.8783L49.6071 69.0607L49.4514 69.4488L49.4797 69.4373L48.2165 73.1686L47.9091 73.3028L48.1375 73.4016L48.0607 73.6302L48.3616 73.4993L52.3705 75.2391L52.551 75.3175L52.6136 75.1308L53.9744 71.0583L54.2767 70.9266L54.0517 70.8264L54.1285 70.5978L53.83 70.7278L50.2382 69.1313L55.5281 66.998L55.918 66.8404L55.5355 66.6668L51.9036 65.0136L57.3855 62.9901L57.7913 62.8399L57.3993 62.6574L53.795 60.9786L59.3913 59.1192L59.4726 59.1577L59.5231 59.0753L59.8851 58.955L59.6616 58.8484L61.8082 55.3314L68.0965 53.7534L68.1663 53.7879L68.2166 53.7262L74.6613 52.4096L74.7237 52.4419L74.7742 52.3897L81.4441 51.3726L81.9912 51.2892L81.5001 51.0346L77.8977 49.1649L78.1016 48.954L77.6305 49.0258L77.5637 48.9913L77.5122 49.044L71.2501 50.0019L71.2735 49.9733L70.8403 50.0617L70.7668 50.024L70.7186 50.0832L70.2333 50.1574L70.2726 50.1775L64.2091 51.4138L64.1255 51.3725L64.0773 51.4408L63.635 51.5311L63.9167 51.6712L61.5811 55.0197L55.4133 56.8238L55.3198 56.7792L55.2746 56.8645L54.894 56.9759L55.1504 57.0979L53.2286 60.7147L53.2201 60.7106L53.1461 60.8702Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3442 49.3307L46.4507 49.673L56.138 47.7556L56.2046 47.7423L56.2459 47.689L58.9161 44.2373L58.7015 43.9617L46.3442 49.3307Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.7651 84.9998L55.5208 81.0837L55.7895 80.9486L55.5651 80.8534L55.6071 80.6366L55.3539 80.7643L51.5333 79.1443L52.5942 75.2022L52.8819 75.0683L52.6565 74.9708L52.7165 74.7474L52.4392 74.8763L48.5853 73.2029L49.9449 69.1857L51.5681 65.1352L51.8896 65.0072L51.6695 64.9071L53.4391 61.0943L53.7784 60.9707L53.5557 60.8673L55.5212 57.1679L61.6761 55.3674L61.7521 55.4043L61.804 55.3301L62.1903 55.2171L61.9606 55.1051L64.2906 51.7655L70.846 50.4284L77.5682 49.4006L81.3334 51.3545L81.4408 51.4102L81.5334 51.3322L84.5754 48.7576L85.008 48.3914L84.4433 48.4404L77.6357 49.0291L73.7964 47.0365L73.6757 46.9738L73.5825 47.0721L70.7761 50.029L66.9541 48.0855L66.8238 48.0188L66.7319 48.1334L64.1338 51.3769L60.2602 49.4474L60.1201 49.3775L60.0314 49.5065L57.6303 52.9944L57.6218 52.9904L57.5748 53.0677L57.1667 53.1696L57.1916 53.1819L51.3361 54.8809L51.2419 54.8362L51.1972 54.9213L50.8152 55.0323L51.0732 55.1551L49.1409 58.8109L49.1331 58.8071L49.0899 58.8997L48.7311 59.0181L48.9724 59.1303L48.8519 59.3578L48.8817 59.3478L47.238 62.8904L47.229 62.8867L47.1892 62.987L46.8504 63.1103L47.0864 63.2178L46.9796 63.4469L47.0075 63.4368L45.5119 67.1506L45.1852 67.28L45.4178 67.3835L45.3257 67.6121L45.6462 67.4853L49.324 69.12L44.1365 71.3331L43.7509 71.4978L44.1354 71.6647L48.1456 73.4056L47.0522 77.3763L46.7595 77.512L46.9881 77.6089L46.9267 77.8318L47.2074 77.7015L51.1126 79.3574L50.3199 83.2987L50.0472 83.4363L50.2733 83.5304L50.23 83.748L50.487 83.6186L54.3653 85.2246L53.9077 89.1132L53.6554 89.2514L53.8804 89.3425L53.856 89.5523L54.0871 89.4263L57.9442 90.9869L57.8597 94.799L57.6305 94.9353L57.8549 95.0243L57.85 95.2243L58.0538 95.1032L61.999 96.6642L62.2424 96.7606L62.2455 96.498L62.2874 92.5806L62.5123 92.4462L62.2897 92.3561L62.2919 92.1577L62.0928 92.2765L58.3212 90.7503L58.7384 86.8834L58.9863 86.7479L58.7628 86.6553L58.7854 86.4465L58.5581 86.5708L54.7651 84.9998Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M47.4918 63.2007L45.8172 67.3566L45.8155 67.3618L45.8128 67.3665L31.5693 97.3956L31.5687 97.3966L31.2489 97.2466L47.4918 63.2007ZM47.4918 63.2007L31.2489 97.2466L47.4918 63.2007ZM47.4918 63.2007L49.3837 59.1229L49.3841 59.1227L49.0569 58.9704L49.0567 58.9712L31.2448 97.2387L31.2432 97.2421L47.4918 63.2007ZM31.2489 97.2466L31.2425 97.2431L31.2489 97.2466ZM46.2691 49.3915L46.4117 49.5079L46.4878 49.661L58.8452 44.292L58.8818 44.2761L58.9084 44.2468L61.7603 41.0431L61.537 40.7661L47.3425 48.7667L78.3192 15.5478L78.0758 15.2828L60.9912 28.6587L60.9705 28.6748L60.9554 28.6959L46.2691 49.3915Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.7233 67.125L45.5434 67.0444L45.4797 67.2312L44.0368 71.4409L43.9054 71.8241L44.2779 71.6648L49.8463 69.2893L50.2271 69.1268L49.849 68.9584L45.7233 67.125ZM80.7067 46.4324L80.5976 46.3748L80.5047 46.4554L77.4812 49.077L77.0615 49.4404L77.6145 49.3929L84.4744 48.7996L85.0843 48.747L84.5432 48.4606L80.7067 46.4324ZM67.3027 47.9976L67.3248 47.9698L66.8409 48.0687L66.2912 48.153L66.3303 48.1726L60.1438 49.4325L59.6305 49.5368L60.0992 49.7703L64.1032 51.7646L64.2336 51.8294L64.3245 51.716L66.9212 48.4733L70.7323 50.4119L70.8524 50.4726L70.9449 50.3752L73.8439 47.3209L74.2066 46.9383L73.6858 47.0181L67.3027 47.9976ZM50.6856 83.3103L51.4949 79.2845L51.5671 78.9259L51.2389 79.0871L46.2457 81.5382L45.8876 81.7143L46.256 81.8666L50.283 83.5344L49.7835 87.4421L49.5248 87.5798L49.754 87.6727L49.7275 87.8801L49.9612 87.7563L54.0081 89.3938L54.2269 89.4826L54.2548 89.2482L54.7281 85.2252L54.9828 85.0899L54.7554 84.9959L54.7796 84.7898L54.5507 84.9115L50.6856 83.3103Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.2429 97.2423L31.5673 97.3999L31.5687 97.3972L44.3685 71.5794L44.3738 71.569L44.3774 71.5578L45.8202 67.3481L45.4866 67.2125L31.2443 97.2397L31.2429 97.2423Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.5002 51.0831L53.0913 51.1848L53.3609 51.3158L51.1652 54.9812L50.9292 55.375L51.3701 55.2468L57.6733 53.4179L57.7504 53.4553L57.8017 53.3803L58.189 53.2679L57.9573 53.1547L60.3272 49.7117L60.5971 49.3199L60.1354 49.4345L53.6367 51.049L53.5469 51.0054L53.5002 51.0831ZM47.2734 77.3389L47.0712 77.2535L47.0266 77.4682L46.1481 81.6635L46.0722 82.0249L46.4038 81.8623L51.397 79.4112L51.7511 79.2375L51.3876 79.0834L47.2734 77.3389ZM73.6967 47.017L73.0788 47.0714L73.6292 47.357L77.5151 49.3738L77.6241 49.4302L77.7165 49.3498L80.7396 46.7286L81.1599 46.3641L80.6059 46.4129L73.6967 47.017Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M69.7688 20.9232L69.5277 20.6549L65.2449 24.3421L65.2399 24.3469L65.2345 24.352L60.9733 28.6743L61.2252 28.9324L69.7688 20.9232Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.1432 89.0599L53.904 88.9634L53.8954 89.2208L53.7604 93.1742L53.5236 93.3109L53.7525 93.4014L53.7456 93.599L53.9514 93.4803L57.9712 95.0705L58.2117 95.1662L58.2177 94.9074L58.3053 90.9722L58.5379 90.8386L58.3105 90.7465L58.3143 90.5508L58.1134 90.6666L54.1432 89.0599ZM42.7981 75.8329L42.7358 76.0534L43.0174 75.9256L47.1333 77.6709L47.3228 77.7513L47.3777 77.5528L48.5124 73.4317L48.8051 73.2991L48.5763 73.1999L48.6368 72.9793L48.359 73.1054L44.2789 71.3338L44.0895 71.2516L44.0334 71.4502L42.864 75.5994L42.5662 75.7345L42.7981 75.8329Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.2446 97.2395L31.5647 97.4051L43.1739 75.8141L43.1835 75.7968L43.1886 75.778L44.3804 71.548L44.0454 71.4193L31.2446 97.2395Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M80.6213 46.4115L80.5384 46.7511L84.3744 48.7796L84.4704 48.8304L84.5608 48.7689L87.6079 46.6807L88.0956 46.3467L87.5044 46.3517L80.6213 46.4115ZM62.9412 46.0444L62.8095 45.9774L62.7179 46.0936L60.038 49.4978L59.7339 49.884L60.2157 49.7858L66.904 48.4236L73.7403 47.3746L74.2867 47.291L73.7963 47.0367L69.84 44.9834L69.7183 44.92L69.6246 45.0205L66.8331 48.0236L62.9412 46.0444ZM58.1039 94.7355L57.8365 94.6293L57.8579 94.9165L58.1443 98.7364L58.1661 99.0264L58.4163 98.8775L62.1572 96.6512L62.4727 96.4637L62.1316 96.3288L58.1039 94.7355Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3198 49.3437L46.5042 49.6538L61.7135 41.0804L61.7337 41.0696L61.7502 41.0536L64.6878 38.2314L64.4671 37.9485L46.3198 49.3437Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.9949 47.4171L55.5534 47.5068L55.8379 47.6484L53.4677 51.1383L53.203 51.5287L53.6604 51.4149L60.1583 49.8002L60.2301 49.8359L60.2828 49.7691L60.698 49.666L60.458 49.547L62.9096 46.4332L66.7903 48.4069L66.9117 48.4687L67.004 48.369L69.8885 45.2664L70.2419 44.8864L69.729 44.9651L63.2838 45.9576L63.3053 45.9307L62.8275 46.0277L62.2786 46.1122L62.317 46.1316L56.1278 47.3901L56.0422 47.3475L55.9949 47.4171ZM58.2313 98.5683L57.9131 98.7574L58.2583 98.891L62.3365 100.472L62.6067 100.576L62.5811 100.287L62.244 96.4807L62.2188 96.1952L61.9726 96.3417L58.2313 98.5683ZM76.8009 44.3675L76.6904 44.3096L76.597 44.3923L73.593 47.0616L73.1858 47.4236L73.7285 47.3762L80.6381 46.7718L81.247 46.7186L80.7066 46.4324L76.8009 44.3675Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.2468 97.2347L31.5592 97.4146L42.2485 80.0431L42.2649 80.0169L42.2713 79.987L43.1911 75.7676L42.856 75.6437L31.2468 97.2347Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.532 81.5906L47.3617 77.629L47.6423 77.4946L47.4107 77.3965L47.455 77.1844L47.1993 77.3069L43.0854 75.5629L42.8853 75.478L42.8387 75.6903L41.9377 79.8239L41.6527 79.9604L41.8869 80.0575L41.8406 80.2702L42.1005 80.1457L46.1274 81.8135L45.5845 85.7407L45.3183 85.878L45.5527 85.9725L45.5242 86.1776L45.7594 86.0564L49.7648 87.6771L49.5865 91.52L49.3415 91.6562L49.576 91.7488L49.5667 91.9436L49.7749 91.8277L53.7639 93.4061L53.9907 97.1247L54.0086 97.4141L54.2605 97.2702L58.127 95.0593L58.4527 94.8736L58.1038 94.7356L54.1217 93.1598L54.2521 89.3353L54.4925 89.2013L54.2602 89.1073L54.2663 88.9145L54.0631 89.0273L50.1468 87.4429L50.6464 83.5375L50.9075 83.4027L50.6757 83.3065L50.7016 83.1031L50.471 83.222L46.532 81.5906ZM69.7405 44.9636L69.1241 45.0183L69.6735 45.3034L73.6298 47.3567L73.7398 47.4137L73.8328 47.3312L76.7433 44.7447L80.2784 46.6143L80.0471 46.7774L80.5783 46.7726L80.6362 46.8033L80.6804 46.7719L87.5073 46.7125L87.5912 46.3736L83.9888 44.4372L84.223 44.2718L83.6911 44.2775L83.6337 44.2465L83.5886 44.2784L77.1964 44.3419L77.2449 44.2994L76.7009 44.3476L69.7405 44.9636ZM58.855 43.9661L58.7223 43.8984L58.6306 44.0165L55.9607 47.4686L55.6636 47.8525L56.139 47.7557L62.8955 46.3817L63.4019 46.279L62.9411 46.0445L58.855 43.9661Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.2514 97.2256L31.5483 97.4293L41.6156 84.2368L41.6437 84.1999L41.6507 84.1539L42.2726 79.9756L41.9411 79.8545L31.2514 97.2256Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.811 42.8926L65.6883 42.8286L65.5948 42.9314L62.7262 46.0838L62.3827 46.461L62.8866 46.3834L69.7841 45.3213L70.3298 45.2376L69.8394 44.9832L65.811 42.8926Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3175 49.3451L46.5121 49.6491L46.5145 49.6471L64.6591 38.2535L64.6676 38.2484L64.6757 38.2417L67.5923 35.9098L67.3812 35.6176L46.3202 49.3435L46.3175 49.3451Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.0816 96.9566L53.7531 97.1447L54.1057 97.2817L58.2586 98.8908L58.5249 98.9941L58.5037 98.7091L58.2173 94.8892L58.1961 94.6043L57.948 94.7465L54.0816 96.9566ZM72.8244 42.2645L72.7119 42.2053L72.6182 42.2907L69.6351 45.0098L69.2405 45.3697L69.7725 45.3227L76.7328 44.7067L77.3407 44.6528L76.8013 44.3672L72.8244 42.2645ZM58.7457 43.9485L58.1932 44.0339L58.6917 44.2877L62.7779 46.3654L62.9005 46.4283L62.9927 46.3263L65.8617 43.1736L66.205 42.796L65.7008 42.8742L58.7457 43.9485Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.5855 97.3148L31.2247 97.3167L31.1202 103.157L31.12 103.166L31.1209 103.175L31.5276 108.554L31.8876 108.535L31.5855 97.3148Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.6973 44.2809L79.8012 42.1867L79.7013 42.1333L79.6096 42.1998L76.6101 44.3814L76.1544 44.7131L76.718 44.7077L83.6018 44.639L87.4198 46.691L87.5042 46.7367L87.5888 46.692L90.6202 45.0988L91.182 44.8035L90.5489 44.7597L83.6973 44.2809ZM42.1636 79.7822L41.9504 79.6937L41.9165 79.922L41.3081 84.0079L41.0349 84.1446L41.2734 84.2409L41.2428 84.4436L41.4802 84.3248L45.5625 85.9769L45.3351 89.8347L45.0821 89.9708L45.3216 90.0659L45.3101 90.2578L45.5205 90.1448L49.6947 91.7963L49.9299 91.8892L49.9417 91.6371L50.1256 87.6684L50.3735 87.5348L50.1357 87.4386L50.1446 87.2483L49.9393 87.3588L45.9477 85.7439L46.4903 81.8193L46.7584 81.6851L46.5219 81.5869L46.5498 81.3859L46.3176 81.5022L42.1636 79.7822ZM58.3886 98.5551L58.0853 98.4376L58.1465 98.7568L58.7797 102.085L58.5522 102.21L58.8231 102.313L58.8506 102.458L58.9951 102.378L63.0478 103.92L63.3547 104.037L63.2885 103.715L62.605 100.397L62.8276 100.275L62.5581 100.171L62.5287 100.028L62.3882 100.105L58.3886 98.5551ZM65.7116 42.8732L65.0965 42.9283L65.6444 43.213L69.6729 45.3036L69.7846 45.3615L69.8774 45.2767L72.8605 42.5575L73.2564 42.1968L72.7231 42.2447L65.7116 42.8732Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.263 97.2099L31.5292 97.4515L41.3015 88.364L41.3538 88.3157L41.3586 88.2449L41.6535 84.1398L41.6968 83.5372L41.3302 84.0174L31.263 97.2099Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M72.7378 42.2439L72.6555 42.5837L76.6324 44.6864L76.7318 44.7389L76.8226 44.6729L79.8222 42.4913L80.2792 42.1589L79.7142 42.1651L72.7378 42.2439Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3467 15.5091L78.0551 15.3021L46.284 49.3732L46.5144 49.6471L67.5781 35.9196L67.6174 35.8942L67.6392 35.8526L78.3467 15.5091Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.8282 91.4608L49.5689 91.3581L49.5816 91.6368L49.7613 95.4831L49.7744 95.7717L50.0282 95.6327L54.024 93.4383L54.3603 93.254L54.0034 93.113L49.8282 91.4608ZM61.7085 40.7631L61.5841 40.6982L61.4908 40.8034L58.639 44.007L58.3055 44.3818L58.8009 44.3051L65.7557 43.2304L66.3007 43.1465L65.811 42.8923L61.7085 40.7631Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.298 15.567L78.0908 15.2724L69.5495 20.6386L69.5347 20.6482L69.5223 20.6598L60.9787 28.6691L61.2131 28.9425L78.298 15.567Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.8541 95.3166L49.515 95.5032L49.876 95.643L54.0106 97.2449L54.574 100.481L54.3353 100.605L54.6142 100.711L54.6389 100.854L54.7862 100.777L58.9174 102.349L59.2191 102.463L59.1589 102.146L58.5262 98.8206L58.7598 98.6987L58.4824 98.5911L58.4557 98.4507L58.3124 98.5251L54.3441 96.9872L54.1173 93.2697L54.0997 92.9857L53.8502 93.1226L49.8541 95.3166ZM87.0264 42.8202L87.2786 42.6828L86.7013 42.6431L86.6497 42.6146L86.6096 42.6369L79.7672 42.1684L75.7982 40.0354L75.6971 39.981L75.6049 40.0496L72.7232 42.2114L68.9969 40.2414L69.1957 40.0554L68.7261 40.0983L68.6599 40.0628L68.611 40.1086L61.6088 40.7436L60.9952 40.7991L61.5422 41.0832L65.4186 43.0952L65.2222 43.2792L65.6916 43.237L65.7584 43.2715L65.806 43.2265L72.7488 42.6047L79.6717 42.5264L83.2679 44.4594L83.0188 44.5949L83.5953 44.6352L83.6471 44.6629L83.6865 44.6416L90.5244 45.1189L90.6229 44.7809L87.0264 42.8202ZM41.5407 83.96L41.3109 83.8669L41.2931 84.114L41.0056 88.1183L40.7442 88.2546L40.989 88.3513L40.9754 88.5408L41.1884 88.43L45.4432 90.1137L45.6752 90.2054L45.6896 89.9567L45.9244 85.9708L46.1805 85.8377L45.9379 85.7395L45.9488 85.5517L45.741 85.6601L41.5407 83.96Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M41.3267 92.2631L41.4241 92.2113L41.4221 92.1009L41.3586 88.2291L41.3517 87.8246L41.0555 88.0999L31.2828 97.1876L31.4899 97.4792L41.3267 92.2631Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6416 41.1028L68.6519 40.4671L72.6553 42.5837L72.7564 42.637L72.8477 42.5689L75.729 40.4074L79.3429 42.3497L79.1049 42.4846L79.6652 42.523L79.7182 42.5518L79.758 42.5292L86.5978 42.9974L90.4502 45.0978L90.522 45.1366L90.5982 45.109L93.5871 44.024L93.5524 43.6762L86.7081 42.6468L83.0613 40.6588L83.3024 40.522L82.7415 40.4845L82.6882 40.4553L82.648 40.4782L76.2097 40.0467L76.2629 40.0069L75.7574 40.0132L71.7202 37.8435L71.6166 37.7881L71.5245 37.8598L68.6706 40.0691L64.6469 37.9417L64.5318 37.8804L64.4376 37.9709L61.5003 40.7936L61.1299 41.1493L61.6416 41.1028ZM45.5756 89.7783L45.3205 89.6774L45.3292 89.9517L45.4484 93.698L45.1978 93.8301L45.4555 93.9301L45.4611 94.099L45.6434 94.0028L49.7791 95.6053L50.2885 98.8463L50.0384 98.971L50.3257 99.0799L50.3479 99.22L50.4972 99.1455L54.7098 100.748L55.006 100.861L54.9517 100.549L54.3714 97.2151L54.6166 97.0933L54.3306 96.9824L54.3067 96.8446L54.1617 96.9171L50.1159 95.3492L49.9472 91.7345L50.1928 91.6053L49.9367 91.5038L49.9287 91.337L49.7508 91.4304L45.5756 89.7783ZM59.0458 102.011L58.6907 101.876L58.8106 102.237L59.7815 105.158L59.5442 105.272L59.8584 105.39L59.8961 105.503L60.0116 105.447L64.1238 106.987L64.4844 107.122L64.3571 106.759L63.3357 103.844L63.568 103.732L63.2548 103.613L63.2156 103.501L63.1039 103.555L59.0458 102.011Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6976 95.7713L41.868 95.743L41.8471 95.5719L41.4211 92.0819L41.3891 91.8216L41.1572 91.9444L31.321 97.1602L31.4356 97.4977L41.6976 95.7713Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0342 41.7224L90.3139 41.6157L89.6652 41.5188L89.6202 41.4943L89.5862 41.5074L82.7164 40.4831L82.6034 40.8201L86.2195 42.7914L85.9436 42.8963L86.591 42.994L86.636 43.0186L86.6695 43.0057L93.4985 44.0328L93.6127 43.6968L90.0342 41.7224ZM64.5603 37.9207L64.4785 38.2603L68.6047 40.442L68.7071 40.4963L68.7991 40.4254L71.7446 38.1446L72.1715 37.8145L71.632 37.8218L64.5603 37.9207ZM41.2441 88.0647L40.9932 87.9648L40.9977 88.235L41.0612 92.1068L41.0662 92.3949L41.3234 92.2647L45.5909 90.1067L45.9486 89.926L45.5756 89.7782L41.2441 88.0647Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.5848 97.296L31.2258 97.3241L31.5281 108.545L31.5288 108.565L31.5333 108.585L33.9031 117.802L34.2567 117.733L31.5848 97.296Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.8378 100.411L54.4904 100.279L54.6015 100.634L55.5668 103.709L55.6277 103.903L55.8132 103.819L59.056 102.344L59.4496 102.166L59.0456 102.012L54.8378 100.411ZM41.1604 91.9433L40.7988 92.1257L41.1764 92.272L45.5666 93.9733L45.8204 94.072L45.812 93.7993L45.6896 89.9404L45.6806 89.6578L45.4279 89.7853L41.1604 91.9433Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.2828 15.5773L78.1001 15.2668L73.8428 17.6036L73.8336 17.6085L73.8253 17.6147L69.5417 20.644L69.7417 20.9441L78.2828 15.5773Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M79.0216 38.4568L79.2518 38.321L78.7071 38.2854L78.6521 38.2553L78.6117 38.2789L71.6853 37.8251L67.5645 35.6099L67.4597 35.5533L67.3663 35.6282L64.45 37.9605L64.0394 38.2887L64.5649 38.2813L71.5905 38.1833L75.345 40.2013L75.118 40.3351L75.6617 40.3713L75.7167 40.4014L75.7564 40.3776L82.6705 40.8412L89.5477 41.8659L93.4383 44.0127L93.4953 44.0439L93.5594 44.0315L96.4769 43.4731L96.484 43.1202L89.6717 41.523L85.7002 39.3321L85.6253 39.2903L85.5457 39.3224L82.7017 40.4629L79.0216 38.4568ZM55.6641 103.491L55.2679 103.671L55.6758 103.823L59.8742 105.396L61.218 107.991L61.2927 108.135L61.4438 108.076L64.252 106.986L64.6929 106.815L64.2501 106.649L60.1466 105.113L59.1526 102.123L59.0895 101.933L58.907 102.016L55.6641 103.491ZM45.6972 93.6371L45.4109 93.5258L45.4535 93.8301L45.9222 97.181L45.6604 97.3054L45.9559 97.4179L45.9749 97.5556L46.1271 97.4831L50.4231 99.1173L50.7137 99.2278L50.6654 98.9209L50.14 95.5797L50.397 95.4582L50.103 95.3443L50.0816 95.2086L49.9338 95.2785L45.6972 93.6371Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M42.4294 98.8574L42.6907 98.8897L42.6258 98.634L41.8432 95.5491L41.8023 95.388L41.6389 95.4158L31.3761 97.142L31.384 97.4986L42.4294 98.8574Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.4636 107.682L60.2567 105.351L60.5249 105.255L60.1299 105.107L60.0896 105.029L60.0031 105.059L55.8843 103.517L54.9943 100.681L55.2545 100.569L54.9192 100.442L54.886 100.335L54.7693 100.385L50.9814 98.944L50.5509 98.7803L50.2115 98.6512L46.2826 97.1569L45.8289 93.9139L46.098 93.7924L45.7954 93.6751L45.7767 93.542L45.6264 93.6098L41.3067 91.9359L41.0257 91.8275L41.0623 92.1257L41.4723 95.4839L41.1977 95.6082L41.5018 95.7239L41.518 95.8591L41.673 95.7891L45.9694 97.423L46.791 100.417L46.8445 100.613L47.0324 100.537L50.3701 99.1902L51.1754 101.925L50.9093 102.039L51.2461 102.165L51.2777 102.273L51.3978 102.222L55.6085 103.799L56.8062 106.21L56.5314 106.309L56.9279 106.455L56.9674 106.535L57.0565 106.503L60.8691 107.912L61.3155 108.077L61.7521 108.239L65.554 109.644L66.0014 109.81L65.7755 109.39L64.3453 106.733L64.2697 106.593L64.1211 106.65L61.4636 107.682ZM78.6037 38.6401L78.6451 38.6626L82.603 40.8204L82.6777 40.8611L82.7564 40.8296L85.6004 39.689L89.5187 41.8506L89.5774 41.8826L89.6038 41.8771L96.4012 43.4718L96.5299 43.1387L92.5749 40.9361L92.5164 40.9033L92.4896 40.9091L86.2109 39.4444L86.2989 39.4092L85.6713 39.316L81.9554 37.2664L82.2079 37.1597L81.6011 37.071L81.5531 37.0446L81.5188 37.0592L75.0635 36.1153L75.1242 36.0782L74.5954 36.0446L74.5391 36.0135L70.4404 33.7795L70.3471 33.7282L70.2574 33.7857L67.3817 35.617L66.9158 35.9136L67.4278 35.9464L71.2718 38.0126L71.0554 38.1449L71.5832 38.1798L71.6396 38.2102L71.6793 38.1864L78.6037 38.6401Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.8829 108.495L31.5314 108.574L32.4541 113.456L32.4565 113.468L32.4604 113.479L33.9068 117.814L34.2524 117.712L31.8829 108.495Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.8982 100.202L46.4676 100.376L46.9022 100.538L51.3308 102.196L51.6689 102.323L51.5671 101.977L50.6607 98.8976L50.6045 98.707L50.42 98.7814L46.8982 100.202ZM92.5432 40.9218L92.4 41.2508L96.355 43.4534L96.3964 43.4759L96.4437 43.476L99.2538 43.4647L99.3087 43.1128L92.5432 40.9218Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3528 15.4957L78.0273 15.3412L67.3198 35.6848L67.0033 36.2858L67.576 35.9208L70.4513 34.0897L70.4986 34.0595L70.521 34.0079L78.3528 15.4957Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.4413 107.739L60.8465 107.518L57.1878 106.166L55.9928 103.76L56.28 103.665L55.8691 103.51L55.8311 103.434L55.7426 103.463L51.4578 101.859L51.0403 101.703L51.2315 102.105L52.404 104.576L52.1102 104.673L52.5226 104.826L52.5596 104.904L52.651 104.873L56.9466 106.461L58.5804 108.621L58.6507 108.714L58.7641 108.688L61.3071 108.109L62.7895 110.008L62.4917 110.084L62.9905 110.267L63.0314 110.318L63.0915 110.304L67.332 111.856L67.9403 112.079L67.5348 111.574L65.9335 109.581L66.2237 109.507L65.7266 109.323L65.6853 109.271L65.6275 109.286L62.0101 107.949L61.4413 107.739ZM95.2856 40.8639L88.5288 38.6822L88.4842 38.6574L88.4573 38.6632L81.6089 37.0744L77.7997 34.9734L78.0396 34.867L77.4534 34.7823L77.4037 34.7551L77.3693 34.7704L70.381 33.7589L70.2685 34.0956L74.1377 36.2048L73.9016 36.3095L74.4872 36.3952L74.5369 36.4224L74.5702 36.4072L81.4851 37.4178L85.5262 39.6476L85.5861 39.6807L85.6127 39.6745L92.4222 41.2631L92.4424 41.2746L92.4608 41.2739L99.1979 43.4559L99.3413 43.1273L95.3471 40.8876L95.3042 40.8636L95.2856 40.8639ZM41.7325 95.4249L41.4082 95.3019L41.4936 95.638L42.2364 98.5668L41.9392 98.6797L42.2988 98.8143L42.3258 98.9188L42.4513 98.8713L46.9025 100.538L47.2326 100.662L47.1395 100.322L46.3371 97.3977L46.6273 97.2872L46.2696 97.1509L46.2411 97.0479L46.1197 97.0941L41.7325 95.4249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3598 15.476L78.0205 15.3552L70.1886 33.8674L70.0059 34.3002L70.4314 34.1012L73.242 32.7866L73.3158 32.7521L73.3385 32.6736L78.3598 15.476Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M91.2115 38.5802L84.4191 36.3938L84.3733 36.3679L80.2446 34.0689L80.181 34.0337L80.1108 34.0522L77.4188 34.7632L73.2527 32.4654L73.1725 32.4208L73.0893 32.4599L70.2787 33.7744L69.7322 34.0296L70.329 34.1158L77.337 35.13L81.4565 37.4025L81.5181 37.4364L81.5446 37.4302L88.3914 39.0182L88.4127 39.0304L88.431 39.0296L95.1864 41.211L99.165 43.4418L99.1894 43.4551L99.2164 43.4607L101.88 44.0212L101.987 43.6786L95.3378 40.8824L91.2742 38.6038L91.2303 38.5792L91.2115 38.5802ZM58.6841 108.336L58.0895 108.472L58.6622 108.682L63.0097 110.273L65.0448 111.971L65.1004 112.017L65.1342 112.015L69.4453 113.585L69.621 113.275L67.5085 111.548L67.4535 111.502L67.4203 111.505L63.2118 109.964L61.5208 107.797L61.4505 107.707L61.3388 107.732L58.6841 108.336ZM47.0289 100.2L46.6232 100.048L46.8014 100.443L47.9151 102.91L47.6019 103.007L48.0306 103.165L48.0649 103.242L48.1585 103.213L52.541 104.833L54.1154 106.984L54.1834 107.077L54.2967 107.055L57.0944 106.488L57.7159 106.361L57.1209 106.142L52.7856 104.539L51.6463 102.139L51.9529 102.044L51.5258 101.884L51.4906 101.81L51.4 101.837L47.0289 100.2Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3661 15.4501L78.0144 15.3743L72.9927 32.5723L72.901 32.8862L73.2156 32.7962L75.9327 32.0198L76.0453 31.9873L76.0618 31.8713L78.3661 15.4501Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M73.2058 32.4472L73.0781 32.7809L77.3078 35.1139L77.3711 35.1487L77.4408 35.1305L80.2023 34.4008L80.1965 34.0508L73.2058 32.4472ZM54.2249 106.701L53.5995 106.828L54.1986 107.047L58.6315 108.67L60.6105 110.356L60.6644 110.403L60.7353 110.4L63.1086 110.295L63.1629 109.946L58.8357 108.361L57.2022 106.202L57.1335 106.112L57.0226 106.134L54.2249 106.701ZM42.5137 98.51L42.1192 98.3618L42.2845 98.7495L43.3379 101.212L43.0034 101.307L43.4488 101.471L43.4809 101.546L43.5768 101.519L48.1011 103.191L48.5056 103.341L48.3279 102.948L47.2158 100.486L47.5428 100.393L47.0986 100.227L47.0654 100.153L46.9733 100.179L42.5137 98.51ZM97.9678 41.4148L97.9429 41.4007L97.9327 41.3989L91.2553 38.5948L91.0976 38.918L95.1706 41.202L95.1952 41.2157L95.2056 41.218L101.847 44.011L102.006 43.6878L97.9678 41.4148Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.5156 13.201L86.4307 12.8506L82.3465 13.6503L82.3314 13.6533L82.3171 13.6589L78.123 15.2563L78.2373 15.5981L86.5156 13.201Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M87.1205 36.2746L87.0752 36.2496L87.0564 36.2506L80.2283 34.0602L75.9706 31.6888L75.9051 31.6524L75.8332 31.673L73.1158 32.4497L73.1252 32.7988L80.0909 34.3961L84.2591 36.7175L84.3037 36.7423L84.3225 36.7413L91.1307 38.9327L91.2739 38.6036L87.1205 36.2746ZM60.7196 110.039L60.6656 110.389L65.0985 112.002L65.2755 111.694L63.2162 109.976L63.1627 109.932L63.0929 109.935L60.7196 110.039ZM48.2261 102.853L47.6816 102.652L48.0161 103.126L49.5609 105.314L49.6269 105.408L49.7398 105.388L52.6858 104.86L53.3376 104.743L52.7165 104.513L48.2261 102.853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M42.6305 98.6616L42.6165 98.5172L42.4727 98.4999L31.4279 97.1408L31.1098 97.1019L31.2414 97.3939L45.1193 128.19L45.4634 128.098L42.6305 98.6616Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.6762 105.033L49.02 105.151L49.6463 105.38L54.1985 107.047L54.7577 107.252L54.4057 106.771L52.7995 104.576L52.7328 104.485L52.6222 104.505L49.6762 105.033Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.5701 97.2456L31.2271 97.3429L33.899 117.78L33.9075 117.845L33.9555 117.889L45.1611 128.248L45.4479 128.041L31.5701 97.2456ZM78.3677 15.4219L78.0083 15.3999L75.7044 31.8216L75.6733 32.0445L75.8976 32.0263L78.489 31.8134L78.657 31.7993L78.6544 31.6304L78.3677 15.4219Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M93.8489 39.0949L93.8253 39.0818L93.8149 39.0796L87.1124 36.2693L82.8848 33.8993L82.8382 33.8733L82.8194 33.8743L75.9388 31.6742L75.7958 32.0037L80.0695 34.3837L80.1157 34.4092L80.135 34.408L86.961 36.5978L91.0985 38.9177L91.1241 38.932L91.1346 38.9342L97.8007 41.7336L101.83 44.0016L101.835 44.0049L101.841 44.0079L104.313 45.1659L104.413 44.9639L104.478 44.8452L100.395 42.5443L100.389 42.5405L100.386 42.5396L93.8489 39.0949ZM63.0213 111.562L65.1013 112.004L67.4653 113.177L67.4861 113.188L67.4966 113.19L71.8817 114.783L72.0227 114.452L69.5871 113.253L69.5665 113.243L69.5568 113.24L65.2228 111.663L60.8209 110.06L58.8419 108.374L58.7893 108.33L58.7546 108.331L54.3236 106.708L54.1431 107.013L56.088 108.708L56.1409 108.754L56.1771 108.754L60.6572 110.385L62.9777 111.547L62.9983 111.557L63.0213 111.562ZM43.648 101.16L43.1204 100.965L43.4361 101.43L44.7561 103.376L44.3494 103.44L44.9483 103.659L44.9799 103.705L45.0469 103.695L49.6473 105.38L50.1896 105.578L49.8562 105.106L48.4793 103.155L48.8753 103.092L48.2794 102.872L48.247 102.826L48.1827 102.836L43.648 101.16Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3646 15.3963L78.0061 15.4284L78.2933 31.6366L78.2959 31.7885L78.4457 31.8119L80.8717 32.1912L81.1179 32.2296L81.0777 31.9839L78.3646 15.3963Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6498 36.7314L89.6232 36.7164L89.6128 36.7142L82.8756 33.8944L78.5626 31.4763L78.5148 31.4492L78.4599 31.4536L75.8685 31.6666L75.8284 32.018L82.7242 34.223L86.9448 36.5893L86.9711 36.6039L86.9815 36.6061L93.6926 39.4197L93.8507 39.0962L89.6498 36.7314ZM63.0956 111.209L62.9971 111.555L67.4835 113.186L67.6253 112.854L65.2412 111.671L65.2206 111.661L65.1985 111.656L63.0956 111.209ZM49.7708 105.041L49.5887 105.345L51.4723 107.028L51.5244 107.074L51.5612 107.074L56.1445 108.742L56.325 108.437L54.3798 106.741L54.3281 106.697L54.2925 106.697L49.7708 105.041Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2273 117.657L33.9181 117.842L35.8982 121.564L35.9067 121.581L35.9185 121.595L38.4414 124.627L38.7298 124.412L34.2273 117.657Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.2283 40.1969L96.222 40.1934L96.2196 40.1924L89.6447 36.729L89.4723 37.0459L93.6732 39.4107L93.6796 39.4142L93.682 39.4152L100.221 42.861L100.394 42.5446L96.2283 40.1969ZM56.2674 108.404L56.1243 108.734L58.3979 109.884L58.4185 109.894L58.4404 109.899L60.6886 110.395L60.7893 110.05L56.2674 108.404Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M94.076 14.287L94.0866 13.929L86.4916 12.8488L86.4531 12.8434L86.416 12.8542L78.1377 15.2512L78.2029 15.6044L94.076 14.287Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M85.3628 34.3186L85.3353 34.303L85.3251 34.3013L78.543 31.4673L78.3856 31.7909L82.7076 34.214L82.7351 34.2296L82.7453 34.2314L89.491 37.0551L89.6491 36.7317L85.3628 34.3186ZM58.5179 109.546L58.4175 109.892L62.9964 111.556L63.1386 111.225L60.8081 110.058L60.788 110.048L60.7664 110.043L58.5179 109.546ZM45.1262 103.341L44.942 103.643L46.7618 105.312L46.813 105.359L46.8818 105.36L49.7063 105.391L49.7701 105.041L45.1262 103.341Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3601 15.3765L78.0084 15.4543L80.7218 32.0422L80.737 32.1373L80.8252 32.1772L83.0435 33.1801L83.3957 33.3397L83.2915 32.9669L78.3601 15.3765Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.8854 104.999L46.8219 105.349L51.5301 107.063L51.7119 106.759L49.8283 105.076L49.7778 105.031L49.7098 105.03L46.8854 104.999ZM91.9761 37.801L91.9697 37.7975L91.9673 37.7965L85.3581 34.3161L85.1857 34.633L89.4724 37.0458L89.4787 37.0493L89.4807 37.0505L96.0552 40.5135L96.2279 40.1971L91.9761 37.801Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.7646 101.318L43.7629 101.286L43.7507 101.258L42.6171 98.6075L42.2715 98.6954L45.1044 128.132L45.4639 128.104L43.7646 101.318Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M51.6537 106.724L51.5098 107.054L53.7242 108.185L53.7443 108.195L53.755 108.198L58.4179 109.892L58.5604 109.562L56.287 108.413L56.2676 108.402L56.2569 108.4L51.6537 106.724ZM80.9884 31.8557L80.9601 31.8399L80.928 31.8349L78.5018 31.4552L78.4043 31.7999L85.2052 34.642L85.3629 34.3188L80.9884 31.8557Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3571 15.3639L78.0137 15.4736L82.9446 33.0643L82.9597 33.1177L83.002 33.1529L84.9626 34.8124L85.4752 35.2463L85.249 34.6138L78.3571 15.3639Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M87.6372 35.3552L87.6302 35.3515L87.6278 35.3505L80.9841 31.8532L80.8117 32.1701L85.1864 34.6325L85.193 34.6364L85.1954 34.6374L91.804 38.1176L91.9763 37.8008L87.6372 35.3552ZM46.9455 105.01L46.8014 105.34L48.9529 106.452L48.9728 106.462L48.9942 106.467L51.5509 107.069L51.6538 106.724L46.9455 105.01Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.2449 103.508L45.2445 103.454L45.2137 103.408L43.7339 101.228L43.3632 100.681L43.4049 101.34L45.1042 128.126L45.4643 128.113L45.2449 103.508Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.0774 106.116L48.9745 106.461L53.7447 108.194L53.8886 107.864L51.6747 106.733L51.6548 106.723L51.6337 106.718L49.0774 106.116Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2002 117.624L33.9276 117.857L38.4301 124.612L38.4556 124.649L38.495 124.67L45.1984 128.274L45.4061 127.983L34.2002 117.624ZM94.0348 14.2863L94.1084 13.9341L90.4297 12.9213L90.4081 12.9155L90.3851 12.9148L86.4687 12.8474L86.44 13.2066L94.0348 14.2863ZM78.3548 15.3586L78.0176 15.4858L84.9093 34.7353L84.9172 34.7585L84.9315 34.7784L86.5811 37.1259L86.8961 36.9557L78.3548 15.3586Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.2061 32.859L83.1996 32.855L83.1921 32.8517L80.9743 31.8485L80.816 32.1727L87.464 35.6722L87.6366 35.3558L83.2061 32.859Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M47.0638 105.192L47.0695 105.105L47.0058 105.047L45.186 103.377L44.8803 103.096L44.8841 103.512L45.1033 128.117L45.4634 128.128L47.0638 105.192Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.7323 134.025L64.6407 134.373L69.2435 135.817L69.2597 135.822L69.2767 135.823L73.8722 136.35L73.9304 135.994L64.7323 134.025Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.3547 15.358L78.0198 15.4911L78.0197 15.4918L86.5607 37.0884L86.5619 37.0914L87.8402 40.1547L87.8413 40.1577L88.1755 40.0214L88.1743 40.0184L78.355 15.3585L78.3547 15.358ZM49.2138 106.322L49.2361 106.191L49.1186 106.131L46.9671 105.019L46.7235 104.893L46.7042 105.167L45.1036 128.103L45.4615 128.145L49.2138 106.322Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M114.821 26.7961L114.597 27.0755L117.74 30.26L117.702 30.2941L117.887 30.4084L117.994 30.5165L118.021 30.4919L122.082 33.0124L122.305 32.732L119.09 29.5143L119.126 29.4813L118.942 29.3667L118.834 29.2589L118.808 29.2833L114.821 26.7961ZM60.0331 132.551L59.9415 132.9L64.641 134.373L64.6582 134.379L64.6664 134.379L73.8551 136.347L73.9471 135.998L69.2709 134.522L69.2537 134.517L69.2453 134.516L60.0331 132.551Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M38.6661 124.352L38.4744 124.657L41.5482 126.905L41.5684 126.919L41.5917 126.928L45.2206 128.284L45.3695 127.956L38.6661 124.352Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M110.631 24.1823L110.406 24.461L113.602 27.7436L113.707 27.8514L113.832 27.7674L114.826 27.0988L115.057 26.9432L114.821 26.7958L110.631 24.1823Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M51.6815 106.845L51.7365 106.657L51.5446 106.618L49.0716 106.115L48.8892 106.078L48.8578 106.261L45.1058 128.085L45.4565 128.166L51.6815 106.845Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.2333 131.047L55.1417 131.395L59.9413 132.9L59.9585 132.905L59.9766 132.907L64.4201 133.366L64.4764 133.01L55.2333 131.047Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.412 29.5107L104.645 29.2496L94.2088 14.0058L94.1497 13.9194L94.0452 13.9282L78.1718 15.2451L77.5581 15.2963L78.1026 15.5843L104.412 29.5107Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.4932 133.015L64.4757 133.009L64.4572 133.007L60.0142 132.548L59.9579 132.904L69.1703 134.869L73.8384 136.342L73.8745 136.354L73.9124 136.349L79.0073 135.797L79.0048 135.439L69.2523 134.517L64.4932 133.015ZM113.631 27.4681L113.399 27.6239L113.636 27.771L117.905 30.4202L118.128 30.14L114.854 26.8226L114.749 26.7157L114.625 26.7995L113.631 27.4681ZM106.351 21.5125L106.126 21.7903L109.239 25.0359L109.331 25.1312L109.449 25.0728L110.615 24.4969L110.905 24.3535L110.631 24.1822L106.351 21.5125Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.4554 106.705L54.5674 106.439L54.279 106.455L51.4991 106.615L51.3718 106.622L51.336 106.744L45.111 128.065L45.4504 128.185L54.4554 106.705Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.3303 129.51L50.2387 129.858L55.1421 131.395L55.16 131.401L55.1685 131.402L64.4011 133.363L64.493 133.015L59.6108 131.474L59.5929 131.468L59.584 131.467L50.3303 129.51ZM118.095 30.114L117.846 30.3619L120.606 34.8289L120.744 35.0533L120.903 34.8433L122.321 32.9682L122.44 32.8105L122.272 32.7064L118.095 30.114ZM109.289 24.7493L108.998 24.893L109.274 25.0645L113.636 27.7716L113.86 27.4923L110.664 24.2097L110.573 24.1157L110.455 24.1738L109.289 24.7493Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.595 29.5025L104.852 29.3355L104.581 29.192L78.2717 15.2653L77.8383 15.0362L78.0196 15.4918L87.8402 40.1547L87.9217 40.3592L88.1062 40.2394L104.595 29.5025Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.4553 133.007L64.3841 133.359L69.1621 134.867L69.199 134.878L69.2186 134.876L78.9707 135.798L79.043 135.447L74.3004 133.926L74.2631 133.914L74.2432 133.917L64.4553 133.007ZM120.615 34.6254L120.495 34.7841L120.665 34.8879L124.921 37.4991L125.167 37.2493L122.33 32.7634L122.191 32.5431L122.033 32.7508L120.615 34.6254ZM101.979 18.7855L101.752 19.0622L104.779 22.2677L104.86 22.3531L104.971 22.314L106.316 21.8356L106.668 21.7103L106.351 21.5125L101.979 18.7855ZM45.3203 127.939L45.2294 128.288L50.2388 129.858L50.2575 129.864L50.2767 129.866L54.5504 130.25L54.6041 129.894L45.3203 127.939Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M57.5151 105.843L57.7184 105.467L57.3068 105.584L54.2396 106.462L54.1564 106.486L54.1224 106.566L45.1174 128.046L45.4423 128.201L57.5151 105.843Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.6209 129.899L54.6023 129.893L54.5826 129.892L50.309 129.507L50.2557 129.862L59.5191 131.822L59.6107 131.474L54.6209 129.899ZM113.826 27.4648L113.577 27.7109L116.254 32.1581L116.38 32.3682L116.543 32.1845L118.135 30.3865L118.276 30.2264L118.095 30.114L113.826 27.4648Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.237 19.4535L100.484 19.1917L97.5486 16.0284L97.5315 16.0102L97.5103 15.9969L94.1542 13.9538L93.9454 14.2464L100.237 19.4535Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.961 21.9346L102.228 19.0393L102.342 19.011L101.999 18.797L101.943 18.7379L101.915 18.7444L97.5118 15.9979L97.2845 16.2738L100.011 19.2125L99.892 19.2415L100.236 19.4547L100.292 19.5145L100.321 19.5078L104.497 22.0991L104.777 22.2727L107.252 26.6321L107.359 26.8197L107.524 26.6815L109.321 25.1853L111.733 29.3039L111.667 29.3675L111.827 29.465L111.92 29.6243L111.985 29.562L116.131 32.1061L116.314 32.2181L120.612 34.855L122.675 40.3946L122.619 40.4752L122.73 40.542L122.828 40.806L122.928 40.6617L127.122 43.1872L127.573 43.4591L127.383 42.9675L125.218 37.3696L125.272 37.291L125.161 37.2231L125.061 36.9636L124.965 37.1023L120.89 34.6028L118.153 30.1721L118.026 29.9656L117.865 30.147L116.436 31.761L113.96 27.6475L114.023 27.5866L113.864 27.4877L113.769 27.3304L113.707 27.3906L109.677 24.8893L109.484 24.7694L106.386 21.5406L106.306 21.4563L106.196 21.4956L104.961 21.9346ZM64.4228 133.37L64.4424 133.368L74.2093 134.275L74.2468 134.287L78.9329 135.79L78.9877 135.807L79.0423 135.79L84.5982 134.035L84.5719 133.879L84.6002 133.692L79.8018 132.114L79.7458 132.095L79.7145 132.106L69.4241 132.362L69.415 132.359L64.4969 130.783L64.4573 130.77L64.4372 130.773L54.603 129.893L49.5198 128.288L49.5004 128.282L49.4807 128.281L45.2988 127.936L45.2469 128.292L54.5212 130.245L59.5026 131.817L59.542 131.83L64.3845 133.358L64.4228 133.37Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.274 127.936L45.2681 128.296L49.4501 128.641L49.4724 128.642L49.4949 128.639L54.2169 127.845L54.1779 127.487L45.274 127.936Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.4815 128.281L49.4115 128.632L54.5129 130.243L54.5533 130.255L54.573 130.252L64.4258 131.134L64.4969 130.783L59.428 129.158L59.3874 129.144L59.3669 129.148L49.4815 128.281ZM116.503 31.9107L116.067 31.6433L116.239 32.1251L118.27 37.8087L118.381 38.1198L118.583 37.858L120.903 34.8439L121.025 34.685L120.854 34.5805L116.503 31.9107ZM107.294 26.4046L107.101 26.5655L107.315 26.697L111.865 29.4886L112.116 29.2439L109.526 24.8196L109.418 24.6363L109.255 24.772L107.294 26.4046Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.348 29.4536L104.663 29.2828L100.518 19.2461L100.501 19.2043L100.466 19.176L94.1755 13.9694L93.9119 14.21L104.348 29.4536Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.4368 130.774L64.3865 131.126L69.3431 132.715L69.4002 132.734L69.4302 132.723L79.7189 132.467L84.4871 134.035L84.5589 134.059L84.6264 134.024L90.5861 130.943L90.4786 130.605L79.7621 132.101L74.8944 130.5L74.837 130.481L74.8056 130.492L64.4368 130.774ZM118.296 37.6382L118.173 37.7983L118.347 37.9027L122.715 40.5333L124.001 47.1892L124.079 47.5933L124.323 47.2618L127.36 43.1397L127.478 42.9805L127.308 42.8786L123.024 40.2982L120.928 34.6714L120.815 34.3668L120.616 34.6241L118.296 37.6382ZM100.447 19.1614L99.8623 18.799L100.193 19.4013L102.518 23.6338L102.427 23.6991L102.618 23.8161L102.693 23.9519L102.761 23.9037L107.314 26.6972L107.565 26.4545L105.148 22.196L105.235 22.1331L105.044 22.0149L104.968 21.8806L104.903 21.9274L100.447 19.1614ZM112.053 29.1817L111.627 28.9198L111.789 29.3937L113.729 35.0722L113.831 35.3725L114.037 35.131L116.546 32.1816L116.682 32.0214L116.503 31.9109L112.053 29.1817ZM54.2422 127.495L54.2006 127.482L54.1571 127.489L49.4354 128.283L49.4496 128.64L59.3569 129.509L59.4275 129.158L54.2422 127.495Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.619 29.4835L104.398 29.2002L87.9093 39.9367L87.8047 40.0049L87.8318 40.1269L88.6784 43.9367L88.7469 44.2437L88.9773 44.0296L104.619 29.4835Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.3668 129.149L59.3171 129.501L64.3856 131.126L64.4442 131.145L64.4746 131.134L74.8113 130.853L79.6889 132.457L79.7616 132.481L79.8014 132.459L90.5276 130.962L90.5611 130.613L85.7202 128.962L85.6462 128.937L85.6047 128.959L74.8538 130.487L69.8742 128.849L69.8151 128.829L69.7837 128.841L59.3668 129.149ZM113.761 34.8972L113.624 35.0591L113.806 35.1687L118.346 37.9024L118.779 38.1635L118.609 37.6877L116.578 32.0041L116.473 31.7105L116.271 31.9482L113.761 34.8972ZM124.032 47.0479L123.913 47.2093L124.086 47.3105L128.507 49.9048L128.857 50.1101L128.775 49.713L127.391 42.9966L127.309 42.5999L127.069 42.926L124.032 47.0479ZM107.502 26.3897L107.086 26.1341L107.237 26.5993L109.081 32.272L109.175 32.5616L109.384 32.3394L112.09 29.459L112.242 29.2976L112.053 29.1818L107.502 26.3897Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.2311 127.943L45.2918 128.296L54.1957 127.847L54.234 127.845L54.2679 127.828L65.2104 122.294L65.077 121.961L45.2311 127.943ZM88.7309 43.7658L88.6676 43.825L88.674 43.9115L89.0154 48.3362L89.05 48.7889L89.335 48.4354L104.636 29.4648L104.373 29.2195L88.7309 43.7658Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M118.532 37.5935L118.192 37.3883L118.262 37.7802L119.462 44.4514L119.383 44.5504L119.491 44.6128L119.547 44.9254L119.698 44.7347L124.086 47.3105L124.431 47.512L124.355 47.1203L123.07 40.4671L123.145 40.3713L123.039 40.3075L122.979 39.9998L122.834 40.1836L118.532 37.5935ZM54.1814 127.486L54.132 127.839L59.3174 129.501L59.3774 129.52L59.4079 129.509L69.7917 129.201L74.7819 130.843L74.8565 130.867L74.8963 130.845L85.6449 129.318L90.4454 130.954L90.533 130.984L90.6084 130.929L96.6968 126.561L96.5483 126.239L85.6705 128.945L80.7668 127.273L80.6917 127.248L80.6505 127.27L69.8342 128.836L64.7375 127.159L64.677 127.139L64.6455 127.151L54.1814 127.486ZM109.122 32.0924L108.969 32.2553L109.16 32.3702L113.807 35.1684L114.23 35.4231L114.07 34.9558L112.13 29.2769L112.033 28.9931L111.828 29.2115L109.122 32.0924Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.185 19.3835L104.289 29.3217L89.0548 48.209L89.0178 48.2555L89.0154 48.3152L88.8241 53.1565L88.7985 53.8109L89.1554 53.262L104.648 29.4503L104.515 29.354L104.669 29.2988L102.925 23.6341L102.92 23.6168L102.911 23.6002L100.51 19.2279L100.185 19.3835ZM57.3035 105.585L57.2329 105.607L57.1973 105.672L45.1249 128.03L45.3913 128.26L87.842 96.6267L87.6814 96.3096L57.3035 105.585Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M124.269 46.9991L123.981 46.8301L123.998 47.1636L124.376 54.5779L124.284 54.6894L124.385 54.7465L124.403 55.1113L124.602 54.8705L128.972 57.3525L129.261 57.5169L129.241 57.1844L128.783 49.8087L128.872 49.7006L128.773 49.6421L128.75 49.2822L128.558 49.5159L124.269 46.9991ZM102.848 23.5334L102.441 23.2835L102.581 23.7402L104.292 29.2987L104.202 29.3847L104.344 29.4703L104.411 29.685L104.522 29.5776L109.16 32.3702L109.574 32.6195L109.424 32.16L107.615 26.5949L107.702 26.5115L107.56 26.4245L107.491 26.2134L107.384 26.3166L102.848 23.5334ZM113.992 34.8594L113.658 34.6576L113.721 35.0437L114.83 41.7289L114.746 41.8287L114.857 41.8945L114.908 42.1953L115.061 42.014L119.562 44.6553L119.9 44.8538L119.831 44.4674L118.632 37.7999L118.714 37.7029L118.602 37.6357L118.549 37.3397L118.401 37.5141L113.992 34.8594ZM91.7841 124.509L91.6939 124.477L91.6449 124.512L80.716 127.256L75.6959 125.545L75.6196 125.519L75.5785 125.542L64.6972 127.146L59.4791 125.43L59.4171 125.409L59.3569 125.433L54.1209 127.499L54.1928 127.847L64.655 127.512L69.7619 129.191L69.8378 129.217L69.8775 129.194L80.6915 127.629L85.6043 129.304L85.6934 129.334L85.7404 129.299L96.6351 126.589L97.2151 126.445L96.6526 126.244L91.7841 124.509Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M88.8531 53.0651L88.8314 53.0988L88.8259 53.139L88.1141 58.3062L88.4502 58.4186L104.654 29.4397L104.346 29.2531L88.8531 53.0651ZM60.6366 103.933L60.6206 103.938L60.6056 103.945L57.2763 105.596L57.4088 105.93L87.7831 96.6554L87.7872 96.6541L87.6857 96.3082L87.6816 96.3095L60.6366 103.933Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M91.6644 124.509L91.1802 124.677L91.6628 124.849L96.5313 126.584L96.6339 126.621L96.7143 126.546L102.619 121.069L102.437 120.767L91.6644 124.509ZM119.744 44.344L119.46 44.1771L119.473 44.5069L119.771 52.0281L119.789 52.4823L120.087 52.1389L124.314 47.273L124.456 47.1093L124.269 46.9992L119.744 44.344Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M88.135 58.2427L88.1225 58.2653L88.1168 58.2912L86.9042 63.6825L87.2411 63.8034L104.657 29.433L104.339 29.2638L88.135 58.2427Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M109.346 32.0613L109.017 31.8636L109.074 32.2427L110.101 39.0252L110.158 39.4004L110.413 39.1201L114.033 35.1352L114.18 34.9727L113.992 34.8594L109.346 32.0613ZM119.815 51.9027L119.671 52.0688L119.862 52.1777L124.471 54.7955L124.757 54.958L124.739 54.6293L124.358 47.1457L124.335 46.6986L124.042 47.0364L119.815 51.9027ZM86.8001 122.732L86.7083 122.7L86.66 122.736L75.5936 125.541L75.0047 125.69L75.5801 125.886L80.6505 127.614L80.7408 127.646L80.7879 127.61L91.7674 124.854L92.3447 124.708L91.7842 124.509L86.8001 122.732Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.1059 127.506L54.2534 127.834L59.4894 125.768L59.5036 125.763L59.517 125.755L65.2235 122.287L65.0483 121.972L54.1059 127.506Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.3963 125.422L59.3665 125.772L64.6249 127.501L64.7027 127.527L64.7725 127.486L70.5378 124.101L70.42 123.767L59.3963 125.422Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.9189 63.6405L86.9121 63.6543L86.9076 63.6692L85.2229 69.1771L85.5578 69.3077L104.657 29.4333L104.659 29.4296L104.336 29.2703L104.334 29.274L86.9189 63.6405ZM64.2059 101.454L64.1711 101.461L64.1416 101.482L60.5826 103.959L60.7345 104.281L87.7828 96.6557L87.6955 96.3062L64.2059 101.454Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M70.5045 123.775L70.4264 123.748L70.3552 123.79L64.5899 127.175L64.7073 127.509L75.6641 125.894L75.696 125.545L70.5045 123.775ZM110.145 38.8771L109.996 39.0418L110.188 39.1541L114.93 41.9372L115.263 42.132L115.199 41.7521L114.077 34.9843L114.016 34.6168L113.766 34.8926L110.145 38.8771ZM124.649 54.4819L124.399 54.34L124.38 54.6262L123.829 62.7056L123.795 63.2198L124.142 62.8395L129.194 57.3173L129.346 57.1504L129.15 57.0385L124.649 54.4819Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M85.2326 69.1516L85.2291 69.1579L85.2266 69.1649L83.106 74.6749L83.4375 74.816L104.659 29.4293L104.66 29.4284L104.333 29.2737L104.333 29.2751L85.2326 69.1516Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M123.876 62.5965L123.72 62.7661L123.922 62.8764L128.5 65.3776L128.75 65.5138L128.767 65.23L129.24 57.2064L129.27 56.699L128.927 57.074L123.876 62.5965Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.1111 74.6634L83.1107 74.6637L80.5986 80.0607L80.5984 80.0614L80.9252 80.2139L104.659 29.4281L104.497 29.3518L104.333 29.2753L104.332 29.278L83.1111 74.6634Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M97.6805 118.939L97.5753 118.899L97.5217 118.95L86.6793 122.732L86.1966 122.9L86.6785 123.072L91.6625 124.849L91.7668 124.886L91.819 124.836L102.556 121.108L103.027 120.944L102.559 120.768L97.6805 118.939ZM115.112 41.6261L114.832 41.4616L114.841 41.7867L115.05 49.3449L115.063 49.791L115.363 49.4614L119.786 44.6214L119.936 44.4569L119.744 44.3441L115.112 41.6261ZM104.589 29.1971L104.267 29.0032L104.318 29.3759L105.242 36.1723L105.292 36.5387L105.55 36.2742L109.382 32.3418L109.541 32.1787L109.346 32.0614L104.589 29.1971Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M67.8582 98.2116L67.7992 98.2173L67.7556 98.2568L64.1244 101.495L63.6109 101.954L64.2832 101.806L87.7724 96.6582L87.7166 96.3025L67.8582 98.2116Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M115.097 49.2182L114.945 49.3855L115.141 49.4967L119.861 52.1781L120.144 52.338L120.131 52.0142L119.833 44.4928L119.816 44.0546L119.52 44.3785L115.097 49.2182ZM97.5444 118.943L97.1406 119.121L97.5538 119.277L102.433 121.106L102.55 121.151L102.633 121.056L108.224 114.594L108.015 114.312L97.5444 118.943ZM81.6952 120.913L81.6023 120.88L81.5536 120.918L70.4014 123.771L69.8149 123.921L70.3877 124.117L75.5792 125.887L75.6711 125.918L75.718 125.882L86.7833 123.077L87.3576 122.932L86.7996 122.733L81.6952 120.913Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.1756 127.971L45.3358 128.288L65.1814 122.306L65.2306 122.291L65.2647 122.253L87.8692 96.6015L87.6264 96.3374L45.1756 127.971Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.1881 121.962L65.1081 121.935L65.0362 121.979L59.3298 125.447L59.45 125.78L70.4735 124.124L70.5049 123.775L65.1881 121.962ZM105.293 36.0223L105.132 36.1872L105.33 36.3034L110.188 39.1541L110.514 39.3454L110.458 38.9718L109.432 32.189L109.377 31.8302L109.124 32.0898L105.293 36.0223ZM120.04 51.8641L119.793 51.7236L119.771 52.0072L119.146 60.0786L119.029 60.2019L119.132 60.2585L119.102 60.6488L119.356 60.3811L123.81 62.8144L122.369 71.1244L122.234 71.2575L122.336 71.3107L122.262 71.737L122.57 71.4326L127.102 73.8008L127.323 73.9158L127.364 73.6706L128.756 65.3062L128.887 65.1768L128.787 65.1222L128.857 64.698L128.556 64.9963L124.198 62.615L124.735 54.7157L124.849 54.5957L124.747 54.538L124.774 54.1517L124.527 54.4123L120.04 51.8641ZM92.6844 117.065L92.5778 117.025L92.496 117.104L86.6147 122.772L86.7992 123.073L97.6774 119.278L98.1481 119.113L97.6813 118.939L92.6844 117.065Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M71.4259 94.3553L71.3339 94.3444L71.2715 94.4121L67.7426 98.2695L67.4258 98.6155L67.8929 98.5704L87.7515 96.6617L87.7556 96.3028L71.4259 94.3553Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M113.155 107.028L103.204 112.347L103.162 112.33L103.105 112.397L92.554 117.066L81.6352 120.891L76.4668 119.049L76.3728 119.016L76.2944 119.078L70.334 123.805L70.4907 124.121L81.626 121.271L86.6786 123.072L86.7837 123.11L86.864 123.032L92.6639 117.442L97.554 119.276L97.6728 119.321L97.728 119.256L108.092 114.672L108.154 114.696L108.207 114.621L108.555 114.467L108.368 114.393L113.386 107.291L113.155 107.028ZM110.37 38.843L110.093 38.681L110.098 39.0013L110.214 46.5219L110.104 46.6355L110.217 46.6992L110.222 47.0339L110.428 46.8191L115.142 49.4965L115.42 49.6546L115.411 49.3349L115.204 41.852L115.31 41.7417L115.199 41.6769L115.19 41.3464L114.99 41.5542L110.37 38.843Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M74.8492 89.979L74.7164 89.9144L74.6268 90.0318L71.2617 94.4239L71.0694 94.6751L71.3835 94.7127L87.7134 96.6607L87.8142 96.3195L74.8492 89.979Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.0842 121.959L64.5006 122.11L65.071 122.304L70.3882 124.117L70.4811 124.148L70.558 124.088L76.5181 119.361L76.3609 119.045L65.0842 121.959ZM119.958 80.016L119.837 80.4609L120.2 80.1363L124.805 82.4243L125.003 82.5226L125.059 82.3094L127.346 73.74L127.499 73.6022L127.396 73.5481L127.515 73.1041L127.158 73.4233L122.619 71.0526L122.421 70.9487L122.362 71.1652L120.011 79.8214L119.853 79.9631L119.958 80.016ZM115.319 49.1834L115.076 49.0448L115.051 49.3246L114.343 57.4518L114.22 57.5758L114.327 57.6342L114.294 58.0154L114.549 57.7558L119.234 60.3149L119.479 60.4485L119.5 60.1708L120.126 52.101L120.244 51.9805L120.139 51.9211L120.169 51.5435L119.92 51.7961L115.319 49.1834Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.0436 85.1893L77.8867 85.0107L77.7568 85.2105L74.6179 90.0439L74.5067 90.2144L74.6901 90.3037L87.6545 96.644L87.8695 96.3628L78.0436 85.1893ZM105.26 43.7904L105.621 43.7785L105.602 36.1477L105.601 36.1356L105.6 36.1238L104.675 29.3277L104.316 29.3635L105.26 43.7904Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M108.261 105.017L107.997 105.182L108.112 105.23L103.067 112.442L103.3 112.704L113.248 107.387L113.316 107.415L113.37 107.322L113.659 107.168L113.498 107.1L117.983 99.3418L117.732 99.0987L108.371 104.948L108.323 104.928L108.261 105.017ZM117.571 68.8224L117.496 69.2405L117.804 68.9439L122.453 71.372L122.671 71.4862L122.714 71.2432L124.177 62.8069L124.311 62.6779L124.209 62.6217L124.281 62.2062L123.98 62.4962L119.407 59.9979L119.187 59.8782L119.143 60.1244L117.605 68.6347L117.467 68.768L117.571 68.8224ZM87.5647 115.146L87.4571 115.105L87.3752 115.185L81.5086 120.954L81.6946 121.253L92.6804 117.404L93.1496 117.239L92.6841 117.065L87.5647 115.146Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M80.9278 80.0668L80.7827 79.7271L80.6041 80.0507L77.7502 85.2218L77.6892 85.3323L77.7726 85.4278L87.5984 96.6013L87.8997 96.4113L80.9278 80.0668Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M98.2888 110.399L98.1681 110.352L98.0845 110.451L92.4821 117.118L92.693 117.399L103.288 112.71L103.681 112.536L103.281 112.378L98.2888 110.399ZM116.857 88.4857L116.632 88.666L116.893 88.7891L121.385 90.9041L112.905 96.9622L112.648 97.1454L112.936 97.2738L117.754 99.4165L117.916 99.4884L117.99 99.3278L121.828 91.1123L121.842 91.1193L121.907 90.9413L122.194 90.3264L122.11 90.3868L125.054 82.3247L125.276 81.7167L124.772 82.1223L116.857 88.4857ZM76.3463 119.049L75.8657 119.218L76.3455 119.389L81.5738 121.253L81.6803 121.291L81.7607 121.211L87.6275 115.443L87.4415 115.144L76.3463 119.049ZM105.512 35.9929L105.24 35.8332L105.24 36.1486L105.26 43.7794L105.261 44.2092L105.567 43.9072L110.405 39.1271L110.572 38.9624L110.37 38.8432L105.512 35.9929Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M80.5988 80.0612L80.5648 80.1338L80.5962 80.2083L87.5686 96.5526L87.7794 97.0463L87.9095 96.5256L104.672 29.3953L104.334 29.2751L80.5988 80.0612Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M122.51 70.9954L117.974 68.6252L119.487 60.2473L119.625 60.1178L119.521 60.0605L119.595 59.653L119.292 59.9356L114.706 57.4303L115.404 49.4225L115.528 49.3015L115.42 49.2401L115.453 48.8716L115.202 49.1161L110.574 46.4874L110.459 38.996L110.453 38.5735L110.152 38.8704L105.314 43.6506L105.144 43.8178L105.351 43.9355L110.205 46.6926L109.42 54.7594L109.293 54.8837L109.403 54.9433L109.366 55.3162L109.624 55.0646L114.315 57.6273L112.724 66.0826L112.582 66.216L112.689 66.2718L112.612 66.6819L112.921 66.3927L117.56 68.8166L115.181 77.4192L115.019 77.5611L115.127 77.6146L115.006 78.0523L115.368 77.7347L119.836 79.9549L111.969 86.1829L111.741 86.3634L112.004 86.4872L116.613 88.6574L107.969 94.7658L107.71 94.9486L107.999 95.0778L112.625 97.1354L103.4 102.862L103.35 102.841L103.288 102.93L103.023 103.095L103.14 103.144L98.1563 110.347L93.1733 108.372L93.0511 108.324L92.968 108.425L87.4445 115.1L82.3186 113.178L82.2096 113.137L82.1568 113.19L71.0492 117.118L71.0137 117.105L70.9593 117.15L70.5101 117.309L70.6868 117.372L65.0156 121.994L65.1746 122.308L76.398 119.408L76.4536 119.428L76.4993 119.381L77.0206 119.247L76.9465 119.22L87.4995 115.506L92.5574 117.402L92.6765 117.447L92.7585 117.35L98.2773 110.783L103.149 112.713L103.281 112.766L103.363 112.649L108.451 105.373L108.464 105.378L108.517 105.285L108.807 105.131L108.778 105.119L117.923 99.4048L118.21 99.2248L117.9 99.0871L113.371 97.0721L121.846 91.0189L122.096 90.8402L121.817 90.7091L117.308 88.5854L124.998 82.4033L125.218 82.2267L124.965 82.1011L120.396 79.8307L120.561 79.381L120.489 79.4386L122.696 71.312L122.853 71.1742L122.748 71.1196L122.867 70.6825L122.51 70.9954Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.0073 122L65.2435 122.273L71.1629 117.449L71.171 117.443L71.1781 117.435L77.0072 111.455L76.7558 111.197L65.0073 122ZM102.575 60.8686L102.933 60.9065L105.618 43.8065L105.622 43.7869L105.621 43.7669L104.676 29.3396L104.316 29.3417L102.575 60.8686Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M117.748 68.5072L113.093 66.0759L114.683 57.6246L114.826 57.4951L114.719 57.4364L114.794 57.0366L114.49 57.312L109.785 54.7408L110.569 46.6784L110.698 46.5574L110.586 46.4944L110.622 46.1338L110.369 46.3708L105.53 43.6222L105.291 43.486L105.262 43.7594L104.375 51.9988L104.242 52.124L104.355 52.1855L104.315 52.5492L104.575 52.3053L109.392 54.9368L107.721 63.4662L107.575 63.5997L107.684 63.6566L107.605 64.059L107.915 63.7775L112.678 66.2658L110.23 74.957L110.065 75.0991L110.175 75.1531L110.054 75.5836L110.415 75.2731L115.004 77.5533L106.958 83.8215L106.726 84.0024L106.992 84.1271L111.722 86.3544L102.909 92.5135L102.647 92.6966L102.939 92.8264L107.688 94.9382L98.3033 100.722L98.2537 100.701L98.1922 100.791L97.9258 100.955L98.0455 101.005L93.0395 108.319L87.9288 106.294L87.8061 106.245L87.7226 106.348L82.1978 113.132L76.9415 111.161L76.8313 111.12L76.7492 111.204L70.9199 117.184L71.1096 117.48L82.254 113.538L87.4382 115.483L87.5588 115.528L87.6407 115.429L93.1627 108.756L98.1567 110.735L98.2902 110.787L98.3716 110.669L103.48 103.287L103.493 103.292L103.546 103.198L103.836 103.045L103.808 103.033L113.105 97.2622L113.394 97.0826L113.083 96.9442L108.436 94.8768L117.074 88.773L117.327 88.5945L117.047 88.4624L112.421 86.2847L120.283 80.0614L120.506 79.8848L120.252 79.7584L115.566 77.43L115.73 76.9874L115.66 77.042L117.932 68.8248L118.093 68.6872L117.986 68.6313L118.104 68.2018L117.748 68.5072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.322 29.308L87.5703 96.3956L64.9943 122.014L65.2517 122.266L77.0002 111.463L77.0135 111.45L77.0236 111.436L87.8798 96.5881L87.7881 96.514L87.9006 96.552L102.921 60.9485L102.934 60.9193L102.935 60.8882L104.677 29.3611L104.322 29.308Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M110.163 75.147L106.902 83.8939L102.852 92.5783L98.1885 100.789L87.7833 106.299L76.8053 111.165L76.4015 111.343L76.8147 111.499L82.1924 113.515L82.3137 113.56L82.3955 113.46L87.9191 106.677L93.0404 108.707L93.1746 108.76L93.2557 108.641L98.4466 101.057L108.168 95.0656L108.459 94.886L108.146 94.7473L103.377 92.6262L112.184 86.4709L112.439 86.2929L112.158 86.16L107.411 83.9256L115.451 77.6608L115.678 77.485L115.421 77.3572L110.603 74.963L113.05 66.2756L113.213 66.138L113.105 66.0809L113.224 65.659L112.868 65.9574L107.971 63.3988L107.775 63.2968L107.714 63.5093L105.154 72.432L104.985 72.5736L105.097 72.6292L104.976 73.0525L105.337 72.7488L110.163 75.147Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M102.577 60.8506L102.931 60.9147L104.723 52.1208L104.725 52.1125L104.726 52.104L105.62 43.7979L105.262 43.7506L102.577 60.8506Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M97.5673 90.4368L92.9627 98.5963L82.4732 104.132L82.4268 104.113L82.3668 104.188L82.0538 104.353L82.1906 104.408L76.7371 111.217L76.9507 111.495L87.866 106.656L87.93 106.682L87.9826 106.605L88.3283 106.452L88.292 106.438L98.3282 101.124L98.3969 101.152L98.4501 101.059L98.74 100.906L98.5761 100.837L103.088 92.8923L103.1 92.8977L103.152 92.7867L103.398 92.6357L103.253 92.5709L107.155 84.2041L107.169 84.2105L107.218 84.0799L107.429 83.934L107.308 83.8773L107.513 83.4376L107.438 83.4896L110.54 75.1698L110.728 75.0252L110.614 74.9691L110.777 74.5334L110.368 74.8465L105.392 72.3736L105.213 72.2845L105.142 72.4716L101.778 81.4321L101.584 81.5806L101.689 81.6304L97.6791 90.2321L97.4551 90.3872L97.5673 90.4368ZM102.551 60.9754L102.471 61.3698L102.781 61.0954L107.803 63.7188L108.017 63.831L108.064 63.594L109.759 54.9368L109.906 54.8067L109.796 54.7471L109.873 54.3548L109.569 54.623L104.633 51.9264L104.419 51.8091L104.37 52.0486L102.59 60.7828L102.439 60.9166L102.551 60.9754Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M76.7321 111.223L77.0187 111.442L82.6247 104.443L82.6297 104.437L82.634 104.43L87.8838 96.5822L87.5888 96.3755L76.7321 111.223Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M99.8773 70.0364L96.5064 78.9494L96.3094 79.0979L96.4286 79.1542L96.2671 79.581L96.6778 79.2714L101.567 81.5732L92.4794 87.7795L92.4214 87.7536L92.3616 87.8599L92.1294 88.0186L92.2441 88.0693L87.6002 96.3523L87.3385 96.5112L87.4626 96.5634L82.3342 104.23L82.5683 104.49L93.1023 98.9305L93.1715 98.9599L93.2245 98.8663L93.5151 98.7131L93.3502 98.6435L97.8983 90.5846L97.9103 90.5895L97.9622 90.479L98.2098 90.3279L98.0761 90.2688L98.318 89.8404L98.2367 89.8898L102.015 81.7846L102.029 81.7906L102.095 81.6139L102.37 81.0245L102.298 81.0736L105.41 72.7855L105.425 72.793L105.468 72.6427L105.653 72.5037L105.537 72.4464L105.698 72.0175L105.633 72.0669L108.044 63.6634L108.212 63.526L108.1 63.4679L108.219 63.0528L107.864 63.3446L102.838 60.719L102.644 60.6169L102.582 60.828L99.9464 69.8434L99.7736 69.9847L99.8773 70.0364Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M87.5677 96.4118L87.8945 96.5643L96.8164 79.144L96.8232 79.1321L96.8259 79.1225L100.272 70.0109L100.275 70.0047L100.277 69.998L102.928 60.929L102.81 60.8921L102.588 60.8083L87.5677 96.4118Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.7333 78.8985L96.5695 78.8216L96.4933 78.9855L92.3347 87.9086L92.0574 88.5042L92.6002 88.1337L102.031 81.693L102.291 81.5151L102.006 81.3806L96.7333 78.8985Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M87.574 96.3995L87.8921 96.57L92.6562 88.0729L92.6592 88.0668L92.6622 88.0608L96.8203 79.138L96.4966 78.9794L87.574 96.3995Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default RightCenterGroup;
