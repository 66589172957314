import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const LeftCenterGroup: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="74"
      height="67"
      viewBox="0 0 74 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1082 18.1431L21.1055 18.1455L21.1031 18.1481L18.7448 20.6158L18.7434 20.6174L18.7418 20.6194L16.5198 23.1876L16.5182 23.1893L16.5659 23.2317L16.5151 23.1932L14.4426 25.8564L14.441 25.8584L14.4394 25.8606L12.5372 28.6093L12.6936 28.7209L16.664 23.3153L26.3112 13.6967L31.795 9.66264L31.6829 9.50637L28.8901 11.4486L28.888 11.4501L28.886 11.4516L26.1894 13.5477L26.2281 13.5986L26.1856 13.5505L23.5922 15.7839L23.5906 15.7856L23.5888 15.787L21.1082 18.1431Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M30.5246 27.2131L30.2952 27.2108L30.4546 27.3761L31.8579 28.8327L28.9422 28.8996L28.7205 28.9044L28.8756 29.0629L30.4475 30.6659L30.5181 30.7377L30.5866 30.6643L32.1477 28.9917L33.8106 27.4149L35.5683 25.9426L35.7571 25.7844L35.5111 25.773L32.2096 25.6186L31.9712 25.6074L32.1352 25.781L33.5208 27.2444L30.5246 27.2131Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.6464 6.32443L37.5462 6.16027L31.6904 9.50183L31.6855 9.50461L31.681 9.50788L26.1914 13.546L26.3009 13.7041L37.6464 6.32443Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.2748 25.6486L32.2087 25.5785L32.1388 25.6449L30.5267 27.1736L30.5226 27.1695L30.4553 27.2413L30.2849 27.4029L30.3038 27.4032L28.874 28.9299L28.7175 29.0968L28.9465 29.0916L32.0814 29.0197L32.3006 29.0147L32.1484 28.8569L30.7522 27.4078L33.7453 27.4391L33.9722 27.4416L33.8163 27.2768L32.2748 25.6486ZM33.9853 24.1286L33.7367 24.1073L33.9066 24.29L35.4358 25.9343L35.4943 25.9972L35.5634 25.9464L37.405 24.5925L37.611 24.441L37.3565 24.4193L33.9853 24.1286Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3232 13.6841L26.1806 13.5556L16.523 23.1843L16.5158 23.1917L16.5102 23.2005L9.33642 34.3406L9.49213 34.4532L26.3232 13.6841Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.3505 30.768L27.2659 30.8699L27.2837 30.8689L26.0942 32.506L25.9292 32.5195L26.0195 32.6086L25.9448 32.7114L26.11 32.6976L27.6893 34.2533L27.7692 34.3323L27.8348 34.2411L29.1228 32.4478L29.285 32.4345L29.2001 32.3496L30.5636 30.6922L30.7338 30.6829L30.6453 30.5927L30.7293 30.4906L30.5544 30.5001L29.0775 28.9938L30.5918 27.377L32.2416 25.8125L35.502 25.965L35.7373 25.9759L35.5767 25.8034L34.2173 24.3416L37.3025 24.6075L37.3317 24.6395L37.3724 24.6136L37.5853 24.6319L37.4965 24.5347L39.314 23.3776L39.5378 23.2352L39.2745 23.2011L35.8759 22.7613L35.8412 22.7232L35.8027 22.7518L35.5804 22.723L35.6857 22.8382L33.989 24.096L33.9852 24.0918L33.944 24.1264L30.6417 23.9618L30.4023 23.9499L30.567 24.1239L31.9786 25.6151L28.9425 25.5704L28.7115 25.5669L28.8719 25.7333L30.3012 27.2167L27.3434 27.2684L27.1199 27.2724L27.2763 27.432L28.815 29.0009L27.4737 30.6192L27.4687 30.6144L27.4293 30.6684L27.2603 30.6775L27.3505 30.768Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.6413 6.3271L37.5489 6.15859L34.5769 7.73114L34.5744 7.73267L34.5716 7.73427L31.6877 9.50329L31.7856 9.66895L37.6413 6.3271Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M30.7071 23.9918L30.641 23.9218L30.571 23.9881L28.8751 25.5967L28.7039 25.7593L28.9399 25.7628L32.2041 25.8106L32.432 25.814L32.2752 25.6485L30.7071 23.9918Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.672 23.3045L16.5137 23.1954L12.539 28.6071L12.5353 28.6119L12.5325 28.6172L9.33348 34.3459L9.49848 34.4448L16.672 23.3045Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.1646 25.7599L29.0775 25.6695L30.6733 24.1561L33.9333 24.3184L33.9654 24.3528L34.0067 24.3221L34.2087 24.3323L34.1209 24.2375L35.855 22.9525L39.2148 23.3872L39.2414 23.4171L39.2808 23.3957L39.5071 23.4252L39.4155 23.3231L41.2559 22.3311L41.4938 22.373L41.3919 22.2578L41.5286 22.1841L41.2896 22.1418L39.8047 20.4604L39.7539 20.4027L39.6864 20.4397L37.7708 21.4919L37.7671 21.4878L37.73 21.5116L37.4923 21.4696L37.5054 21.484L34.5297 21.0955L34.5412 21.0868L34.3284 21.0677L34.2948 21.0308L34.2563 21.0597L34.034 21.0306L34.0443 21.0421L30.8462 20.753L30.5963 20.7302L30.7672 20.9141L32.1803 22.4334L32.1888 22.4427L29.0847 22.2782L29.0448 22.2362L29.005 22.274L28.8059 22.2633L28.9054 22.3685L27.3337 23.8597L27.33 23.8558L27.2901 23.8981L27.1003 23.8945L27.1943 23.992L27.0945 24.0869L27.1116 24.0871L25.6758 25.6095L25.4915 25.6116L25.5845 25.7064L25.4924 25.8039L25.678 25.8016L27.2118 27.366L27.1191 27.4647L27.1364 27.4644L25.8559 28.9982L25.8512 28.9934L25.812 29.0468L25.6408 29.0548L25.7323 29.1462L25.6483 29.2469L25.6653 29.2461L24.4559 30.8909L24.2888 30.9034L24.3805 30.9936L24.3059 31.095L24.4707 31.0827L26.0235 32.6124L24.9015 34.403L24.7444 34.4202L24.8354 34.5083L24.7711 34.6109L24.9237 34.5941L26.5244 36.1449L26.6103 36.2279L26.6733 36.1262L27.8141 34.2754L27.9684 34.2583L27.8788 34.17L27.9419 34.0677L27.7918 34.0843L26.2727 32.5878L27.532 30.8546L27.6964 30.8424L27.6102 30.756L28.9913 29.0895L29.1633 29.0814L29.0738 28.9902L29.1575 28.8893L28.9829 28.8973L27.5705 27.4568L30.5255 27.4054L30.7462 27.4015L30.593 27.2425L29.1646 25.7599Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3441 13.6323L26.373 13.317L26.1736 13.5629L9.34261 34.3323L9.48352 34.4624L25.8304 18.9801L25.8567 18.9552L25.86 18.9193L26.3441 13.6323Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M24.2119 27.3942L23.9927 27.4031L24.1476 27.5582L25.7942 29.2085L25.8686 29.283L25.9362 29.2022L27.4189 27.4262L27.5578 27.2599L27.3412 27.2684L24.2119 27.3942ZM30.9079 20.7832L30.8454 20.716L30.7754 20.7753L28.979 22.2988L28.7943 22.4554L29.0361 22.4681L32.4159 22.6472L32.6534 22.6599L32.4915 22.4857L30.9079 20.7832ZM36.2831 19.8357L36.228 19.7742L36.159 19.8193L34.23 21.0795L34.0124 21.2219L34.27 21.2555L37.738 21.7084L37.9953 21.742L37.8218 21.5487L36.2831 19.8357Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3389 13.591L26.1526 13.6147L25.6685 18.9017L25.647 19.1374L25.8267 18.9835L27.6484 17.4253L27.6988 17.3821L27.6765 17.3197L26.3389 13.591ZM12.7001 28.7111L12.5338 28.6144L10.8243 31.442L10.8227 31.4448L10.821 31.4479L9.33176 34.3487L9.50132 34.4396L12.7001 28.7111Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M25.7864 25.6377L25.7124 25.5622L25.6443 25.6431L24.142 27.4285L24.0018 27.5953L24.2195 27.5866L27.3491 27.4608L27.5655 27.4521L27.4137 27.2973L25.7864 25.6377ZM40.0002 20.4868L40.0182 20.4768L39.7519 20.4298L39.4625 20.3635L39.4784 20.3813L36.2284 19.8051L35.9537 19.7563L36.14 19.9642L37.6789 21.6771L37.7299 21.7339L37.7966 21.6973L39.7117 20.6455L41.1674 22.2941L41.2141 22.3468L41.2783 22.3185L43.3104 21.4243L43.576 21.3074L43.2933 21.2425L40.0002 20.4868ZM25.0809 34.4785L26.2277 32.6493L26.3318 32.4829L26.1366 32.5024L23.2865 32.7875L23.0803 32.8081L23.2291 32.9524L24.8395 34.5125L23.8501 36.3934L23.763 36.5586L23.9482 36.5334L26.6043 36.171L26.8003 36.1443L26.6584 36.0067L25.0809 34.4785Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3039 13.5451L26.0552 13.3695L26.1581 13.656L27.4953 17.3845L27.54 17.5086L27.6445 17.4284L29.5359 15.9781L29.6396 15.8986L29.5328 15.8232L26.3039 13.5451Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.922 36.3427L23.7242 36.3697L23.8689 36.5077L25.5264 38.0879L25.6197 38.1764L25.6784 38.0622L26.6767 36.1197L26.7612 35.9554L26.5784 35.9803L23.922 36.3427ZM32.6828 19.3378L32.4602 19.3086L32.5668 19.4253L30.8079 20.7506L27.4227 20.5606L27.1811 20.5471L27.3475 20.7225L28.8128 22.2706L25.694 22.1979L25.4604 22.1927L25.6225 22.3609L27.1058 23.9004L24.061 23.9201L23.8341 23.9213L23.993 24.0833L25.6493 25.7724L25.7193 25.8438L25.7879 25.7712L27.3988 24.0631L29.1055 22.4437L30.8692 20.9481L34.2367 21.2526L34.2669 21.2853L34.3072 21.259L34.5208 21.2782L34.43 21.1789L36.2644 19.9804L36.4814 19.8384L36.2243 19.8044L32.7566 19.3476L32.721 19.3088L32.6828 19.3378ZM26.2136 32.5296L24.634 30.9734L25.9133 29.2335L26.0801 29.2226L25.9886 29.1312L26.0623 29.0311L25.8996 29.0416L24.2838 27.4226L24.2053 27.3436L24.1385 27.4331L22.7886 29.2473L22.619 29.2584L22.712 29.3501L22.6377 29.45L22.8022 29.4392L24.3842 30.9975L23.2151 32.8316L23.1083 32.9987L23.3057 32.9788L26.1556 32.694L26.3596 32.6736L26.2136 32.5296Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.2657 13.5289L26.1929 13.7023L29.4218 15.9804L29.4762 16.0189L29.5313 15.9814L31.4829 14.654L31.6767 14.5223L31.446 14.48L26.2657 13.5289Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.353 20.5851L27.1721 20.7392L27.1897 20.7402L25.6949 22.1588L25.6912 22.1549L25.6237 22.2262L25.4566 22.385L25.4732 22.3854L24.0605 23.8775L24.0565 23.8737L23.9902 23.952L23.8368 24.1139L23.8531 24.1138L22.4943 25.7162L22.3154 25.7224L22.4093 25.8162L22.3264 25.9145L22.5013 25.9086L24.1478 27.5583L24.2219 27.6326L24.2896 27.5523L25.7638 25.7999L25.9396 25.794L25.8477 25.7001L25.9306 25.6018L25.7571 25.6077L24.2893 24.1109L27.3313 24.0914L27.5556 24.0898L27.4 23.9286L25.9235 22.3959L29.0392 22.4685L29.2699 22.4737L29.1113 22.3063L27.6535 20.7662L30.7936 20.9427L30.8264 20.9778L30.8676 20.9468L31.0707 20.9582L30.9809 20.8615L32.7357 19.5388L36.1638 19.9906L36.1917 20.0214L36.2287 20.0007L39.6831 20.6129L39.7077 20.6411L39.743 20.6252L43.2502 21.4301L43.5379 21.4961L43.3443 21.2735L41.9249 19.6392L42.0674 19.5746L41.8197 19.5177L41.7934 19.4875L41.7575 19.5035L38.4641 18.7493L38.4813 18.7397L38.2491 18.6985L38.2201 18.6654L38.1846 18.6853L37.9293 18.6268L37.9449 18.6445L34.6953 18.0674L34.662 18.0304L34.6247 18.0549L34.3876 18.0128L34.4998 18.1376L32.6843 19.3396L29.2715 19.0235L29.234 18.9831L29.1948 19.0165L28.9842 18.997L29.0874 19.1079L27.4244 20.5242L27.421 20.5205L27.353 20.5851Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.2453 13.5275L26.2309 13.7181L31.4112 14.6689L31.4468 14.6754L31.4778 14.6571L33.4795 13.4695L33.4272 13.2907L26.2453 13.5275Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.4192 32.8687L24.5627 31.0751L24.7224 31.0608L24.6298 30.9696L24.6932 30.8703L24.5426 30.8839L22.966 29.3306L24.2659 27.584L24.4348 27.574L24.3422 27.4814L24.4158 27.3824L24.253 27.3919L22.6704 25.8056L24.1331 24.0804L25.7332 22.3904L25.9178 22.39L25.8283 22.2971L27.4542 20.7537L27.6467 20.7591L27.5566 20.6641L29.2575 19.2154L32.6631 19.5307L32.6936 19.5639L32.7339 19.5373L32.9476 19.5571L32.8557 19.4565L34.6667 18.2577L38.18 18.8815L41.7152 19.6914L43.1988 21.3994L43.2411 21.4481L43.3024 21.4276L45.3163 20.748L45.6027 20.6513L45.313 20.5648L41.8216 19.5204L40.3089 17.7786L40.2613 17.7238L40.1958 17.7543L38.2237 18.6697L36.7099 16.9557L36.6583 16.8971L36.5903 16.9358L34.6652 18.0343L33.1231 16.3171L33.0673 16.2549L32.998 16.3015L31.1226 17.5598L31.1192 17.5561L31.0807 17.5852L30.8583 17.5556L30.8683 17.5666L27.631 17.2598L27.5931 17.2191L27.5542 17.2525L27.343 17.2326L27.4468 17.3441L25.771 18.7777L25.7679 18.7743L25.7283 18.8117L25.5271 18.8L25.6248 18.903L25.5203 18.9922L25.5371 18.9931L24.0265 20.4272L24.0228 20.4236L23.9834 20.4655L23.7912 20.4601L23.8871 20.5598L23.7892 20.6524L23.805 20.6529L22.3344 22.2009L22.1469 22.201L22.2419 22.298L22.1514 22.3933L22.3352 22.3933L23.8381 23.9259L20.8312 24.005L20.6077 24.011L20.7656 24.1693L22.4125 25.8198L21.0909 27.5742L20.9191 27.584L21.0134 27.6769L20.9392 27.7755L21.104 27.766L22.7155 29.3537L21.5487 31.1526L21.3865 31.1673L21.4802 31.2583L21.416 31.3577L21.5688 31.344L23.1768 32.9017L22.1866 34.7402L22.0343 34.7592L22.128 34.8485L22.0747 34.9478L22.2141 34.9307L23.8205 36.4617L23.0302 38.3355L22.8898 38.3581L22.9836 38.4464L22.942 38.5446L23.0668 38.5245L24.7168 40.073L24.8186 40.1686L24.8716 40.039L25.6614 38.1046L25.7993 38.0821L25.7066 37.9937L25.7466 37.8958L25.6246 37.9156L24.0538 36.4184L25.0198 34.5827L25.1693 34.5642L25.0767 34.4744L25.1289 34.3753L24.9917 34.3923L23.4192 32.8687Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M24.0917 20.6309L22.4455 22.363L22.4437 22.3653L22.4414 22.3671L9.48345 34.4625L9.48296 34.4628L9.3538 34.3257L24.0917 20.6309ZM24.0917 20.6309L9.3538 34.3257L24.0917 20.6309ZM24.0917 20.6309L25.8304 18.9801L25.8306 18.9801L25.6983 18.8403L25.698 18.8407L9.3533 34.321L9.35185 34.3223L24.0917 20.6309ZM9.3538 34.3257L9.35134 34.3227L9.3538 34.3257ZM26.1961 13.543L26.244 13.6286L26.2517 13.7195L33.4336 13.4828L33.4549 13.4821L33.4738 13.4728L35.5169 12.4439L35.4606 12.2627L26.8511 13.4438L48.7331 3.05132L48.6644 2.87214L37.5666 6.15153L37.5532 6.15545L37.5415 6.16298L26.1961 13.543Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.4443 22.2297L22.3709 22.1545L22.3026 22.2346L20.7607 24.0389L20.6204 24.2031L20.8363 24.1973L24.064 24.1123L24.2848 24.1065L24.1303 23.9488L22.4443 22.2297ZM43.8544 18.8355L43.8116 18.7855L43.7497 18.8073L41.7357 19.5138L41.4563 19.6116L41.7398 19.6966L45.2581 20.7489L45.5708 20.8426L45.3587 20.5944L43.8544 18.8355ZM36.9001 16.9805L36.9165 16.9711L36.6571 16.9251L36.368 16.859L36.3835 16.8764L33.0684 16.2868L32.7933 16.2378L32.98 16.4455L34.574 18.2205L34.626 18.2782L34.6933 18.2398L36.6175 17.1415L38.1269 18.8509L38.1746 18.9046L38.2396 18.8745L40.2767 17.929L40.5317 17.8104L40.2578 17.7477L36.9001 16.9805ZM21.728 31.23L22.9195 29.3925L23.0257 29.2289L22.8313 29.2444L19.874 29.4797L19.6619 29.4968L19.8147 29.6446L21.4843 31.2621L20.4695 33.1019L20.3142 33.1194L20.4096 33.2104L20.3558 33.3081L20.496 33.2926L22.1814 34.899L22.2725 34.986L22.3323 34.8752L23.3567 32.9732L23.5096 32.9561L23.4153 32.8648L23.4677 32.7674L23.3303 32.7828L21.728 31.23Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.35161 34.3223L9.48154 34.4641L9.48277 34.4631L20.8981 24.1727L20.9028 24.1685L20.9068 24.1637L22.4486 22.3594L22.3098 22.2266L9.35285 34.3212L9.35161 34.3223Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.4503 15.8011L29.2276 15.7713L29.3356 15.8892L27.5272 17.2758L27.3329 17.4248L27.5767 17.4478L31.0614 17.7781L31.0923 17.8118L31.1325 17.7847L31.3466 17.805L31.2539 17.7033L33.105 16.4612L33.3157 16.3199L33.0643 16.2861L29.5247 15.811L29.4887 15.7717L29.4503 15.8011ZM21.2085 27.599L21.125 27.5169L21.0608 27.6146L19.8016 29.5226L19.693 29.687L19.8893 29.6714L22.8466 29.4361L23.0563 29.4194L22.9063 29.2717L21.2085 27.599ZM40.2639 17.7493L39.9468 17.655L40.1636 17.9047L41.6947 19.6675L41.7377 19.7169L41.7993 19.6952L43.813 18.9888L44.093 18.8905L43.8087 18.806L40.2639 17.7493Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.4379 4.03848L43.3711 3.85814L40.5234 4.84589L40.52 4.84731L40.5163 4.84876L37.5548 6.1557L37.629 6.33313L43.4379 4.03848Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.314 34.76L22.2143 34.6651L22.1595 34.7911L21.3166 36.7251L21.1723 36.7463L21.268 36.8362L21.2258 36.9328L21.3512 36.9144L23.0324 38.492L23.1329 38.5867L23.1867 38.4595L24.0026 36.5252L24.1442 36.5047L24.0495 36.4144L24.0898 36.3181L23.9674 36.336L22.314 34.76ZM19.2844 25.9737L19.2103 26.0708L19.375 26.0627L21.0734 27.7362L21.1516 27.8132L21.2178 27.7255L22.5895 25.9047L22.7606 25.8964L22.6667 25.8023L22.74 25.7048L22.5774 25.7128L20.9019 24.0333L20.8241 23.9554L20.7573 24.0428L19.363 25.8708L19.1887 25.8794L19.2844 25.9737Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.35303 34.3213L9.47925 34.4662L19.4745 26.0382L19.4826 26.0315L19.4888 26.0232L20.9102 24.1595L20.7693 24.0299L9.35303 34.3213Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.8161 18.8084L43.7083 18.9605L45.2123 20.7194L45.25 20.7635L45.3069 20.7507L47.2278 20.3133L47.5353 20.2435L47.2411 20.1299L43.8161 18.8084ZM35.1206 15.1558L35.0685 15.0967L35.0003 15.1363L33.0031 16.2984L32.7764 16.4302L33.0346 16.4761L36.6187 17.1135L40.2147 17.9353L40.5021 18.0011L40.3089 17.7787L38.75 15.9838L38.7021 15.9285L38.6359 15.96L36.6621 16.9012L35.1206 15.1558ZM23.1639 38.352L23.0521 38.2468L23.0064 38.3935L22.3985 40.3439L22.3524 40.4921L22.5057 40.4673L24.7978 40.0976L24.9911 40.0665L24.8485 39.9327L23.1639 38.352Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.2307 13.5291L26.2613 13.7191L35.4865 12.4531L35.4986 12.4517L35.5099 12.447L37.5207 11.6241L37.4667 11.4405L26.2307 13.5291Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.4068 14.473L31.1703 14.4309L31.2836 14.5569L29.4231 15.8223L29.2153 15.9639L29.4644 15.9973L33.0037 16.4721L33.0322 16.5039L33.0715 16.4811L33.2976 16.5115L33.202 16.4053L35.0289 15.3424L36.5659 17.083L36.614 17.1375L36.6794 17.1061L38.7188 16.1338L38.9686 16.0147L38.6988 15.953L35.3079 15.1801L35.3238 15.171L35.0679 15.1253L34.7791 15.0594L34.7943 15.0766L31.4781 14.4857L31.444 14.4478L31.4068 14.473ZM22.475 40.2777L22.2801 40.3091L22.425 40.4431L24.1371 42.0274L24.2506 42.1323L24.2946 41.984L24.8747 40.0302L24.9182 39.8836L24.7674 39.908L22.475 40.2777ZM42.3231 17.0449L42.2797 16.9945L42.2171 17.0172L40.2035 17.7512L39.9305 17.8508L40.2089 17.9338L43.754 18.9904L44.0664 19.0836L43.8546 18.8356L42.3231 17.0449Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.35543 34.3192L9.47499 34.4698L18.1858 27.9536L18.1991 27.9438L18.2081 27.9302L19.4925 26.0184L19.3507 25.8912L9.35543 34.3192Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M20.0061 29.5619L21.1952 27.7602L21.3608 27.7486L21.2652 27.6545L21.3288 27.558L21.1779 27.5686L19.4802 25.8962L19.3976 25.8149L19.3329 25.911L18.0746 27.7839L17.9065 27.7957L18.0036 27.8898L17.9389 27.9862L18.0922 27.9755L19.7617 29.593L18.7216 31.4339L18.5627 31.4497L18.6603 31.5427L18.6059 31.6387L18.7464 31.6248L20.4145 33.2148L19.5718 35.0854L19.4235 35.1049L19.5216 35.1969L19.4788 35.2916L19.6048 35.275L21.2731 36.8407L20.6556 38.7293L20.6076 38.8763L20.7608 38.8544L23.1122 38.5171L23.3102 38.4889L23.1642 38.352L21.4988 36.7889L22.3143 34.9179L22.4597 34.8987L22.363 34.8064L22.4039 34.712L22.281 34.7281L20.6499 33.1736L21.6643 31.335L21.8202 31.3194L21.7242 31.2262L21.7769 31.1304L21.6392 31.1441L20.0061 29.5619ZM38.705 15.9545L38.3885 15.8606L38.605 16.1098L40.1639 17.9046L40.2072 17.9545L40.2695 17.9319L42.2206 17.2206L43.6066 18.8416L43.4599 18.8771L43.7243 18.979L43.7469 19.0056L43.775 18.9987L47.1721 20.3093L47.2803 20.1577L45.874 18.4903L46.0226 18.4543L45.7577 18.3527L45.7353 18.3261L45.7066 18.333L42.5243 17.1097L42.5567 17.0982L42.2775 17.0153L38.705 15.9545ZM33.5027 13.323L33.4501 13.2634L33.3815 13.3039L31.3799 14.4918L31.1572 14.6238L31.412 14.6691L35.0321 15.314L35.3035 15.3625L35.1209 15.1558L33.5027 13.323Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.35954 34.3157L9.46678 34.475L17.0487 29.9091L17.0699 29.8963L17.0824 29.8748L18.211 27.9249L18.0704 27.7997L9.35954 34.3157Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.163 14.1561L37.1147 14.1002L37.0482 14.1329L35.0068 15.133L34.7624 15.2526L35.0276 15.3131L38.6565 16.1404L38.9436 16.206L38.7503 15.9835L37.163 14.1561Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.2293 13.5294L26.2661 13.7183L26.2677 13.7178L37.5021 11.6295L37.5073 11.6286L37.5127 11.6269L39.4167 11.043L39.3694 10.8567L26.231 13.5291L26.2293 13.5294Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M20.7334 38.6638L20.5336 38.6926L20.6816 38.8298L22.4252 40.4429L22.5369 40.5464L22.5823 40.4009L23.1902 38.4505L23.2356 38.305L23.0846 38.3268L20.7334 38.6638ZM40.7638 15.2214L40.7197 15.1699L40.6565 15.1939L38.6434 15.9567L38.3771 16.0577L38.6501 16.1388L42.2226 17.1996L42.5347 17.2923L42.3232 17.0448L40.7638 15.2214ZM33.4518 13.2928L33.161 13.2268L33.3584 13.4504L34.9769 15.2829L35.0253 15.3381L35.091 15.3056L37.1327 14.3054L37.377 14.1855L37.1116 14.1254L33.4518 13.2928Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.50786 34.4256L9.32857 34.3557L8.13024 37.2315L8.12841 37.2358L8.12717 37.2402L7.27295 39.9875L7.45509 40.0491L9.50786 34.4256Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.76 18.3557L44.239 16.5524L44.1999 16.5063L44.1414 16.5213L42.2257 17.0143L41.9346 17.0894L42.2152 17.1973L45.6423 18.5145L47.1328 20.2816L47.1657 20.3208L47.2165 20.3152L49.0325 20.1203L49.3691 20.0841L49.0637 19.9381L45.76 18.3557ZM18.1947 27.8077L18.1064 27.7219L18.0447 27.8285L16.941 29.7353L16.7787 29.7494L16.878 29.844L16.8231 29.9385L16.9641 29.9262L18.6642 31.5468L17.7942 33.4153L17.642 33.4331L17.7421 33.5273L17.6987 33.6201L17.8253 33.6054L19.5711 35.2438L19.6695 35.336L19.7248 35.2133L20.595 33.2813L20.7442 33.2637L20.6451 33.1694L20.6869 33.0768L20.5634 33.0913L18.901 31.5069L19.9405 29.6672L20.0998 29.6533L20.0017 29.5582L20.055 29.4639L19.9171 29.4761L18.1947 27.8077ZM22.5556 40.302L22.4282 40.1842L22.3959 40.3545L22.0567 42.1292L21.9193 42.1465L22.0334 42.2508L22.0187 42.3279L22.1059 42.3169L23.8129 43.8771L23.9423 43.9951L23.9725 43.8226L24.2849 42.0432L24.4192 42.0263L24.3061 41.9216L24.3196 41.8449L24.2348 41.8556L22.5556 40.302ZM37.1172 14.1271L36.8014 14.0337L37.0173 14.2824L38.6046 16.1099L38.6486 16.1605L38.7113 16.1367L40.7244 15.3738L40.9915 15.2727L40.7176 15.1917L37.1172 14.1271Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.36794 34.3102L9.45253 34.4822L16.0824 31.8941L16.1178 31.8804L16.1341 31.8462L17.0862 29.8684L17.2259 29.5781L16.9499 29.7442L9.36794 34.3102Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M40.7248 15.1941L40.6173 15.3465L42.1767 17.1699L42.2157 17.2154L42.2737 17.2005L44.1894 16.7075L44.4813 16.6323L44.1999 16.5245L40.7248 15.1941Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7547 3.03731L48.6507 2.87744L26.2074 13.5366L26.2679 13.7176L39.408 11.045L39.4325 11.0401L39.4514 11.0238L48.7547 3.03731Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M19.7032 35.1036L19.5948 35.0018L19.5464 35.1424L18.8805 37.0851L18.8303 37.2308L18.9834 37.2117L21.3957 36.9078L21.5986 36.8825L21.4493 36.7424L19.7032 35.1036ZM35.5464 12.2948L35.4975 12.2382L35.4306 12.2721L33.3875 13.3009L33.1486 13.4213L33.4093 13.4805L37.0691 14.3128L37.3558 14.3782L37.1629 14.156L35.5464 12.2948Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7191 3.05668L48.6742 2.86992L43.3852 3.8544L43.3759 3.85622L43.3675 3.85957L37.5586 6.15422L37.6212 6.33583L48.7191 3.05668Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.9592 37.0207L18.7544 37.0467L18.9059 37.1869L20.6419 38.7928L20.2861 40.5081L20.1432 40.523L20.2607 40.6303L20.245 40.7057L20.3332 40.6965L22.0733 42.2869L22.2004 42.403L22.2328 42.2338L22.5718 40.4606L22.7116 40.446L22.5951 40.3382L22.6095 40.2633L22.5238 40.2721L20.8578 38.7305L21.4751 36.8425L21.5221 36.6982L21.3715 36.7171L18.9592 37.0207ZM47.6977 18.2847L47.8498 18.2661L47.5713 18.1331L47.5512 18.1089L47.527 18.112L44.2259 16.5366L42.6764 14.6997L42.6369 14.6529L42.5777 14.6688L40.7243 15.1752L39.2631 13.4668L39.3982 13.4135L39.157 13.3427L39.1311 13.3121L39.0979 13.3252L35.5008 12.2656L35.1857 12.1726L35.4011 12.4209L36.9285 14.1796L36.795 14.2322L37.0361 14.3034L37.0624 14.3337L37.0948 14.3207L40.6598 15.3752L44.1082 16.6954L45.5121 18.3598L45.362 18.3781L45.64 18.5112L45.6602 18.5352L45.6839 18.5324L48.9811 20.1113L49.0963 19.963L47.6977 18.2847ZM17.0659 29.7571L16.9702 29.6658L16.9128 29.7849L15.9842 31.7141L15.8279 31.7304L15.9303 31.8265L15.8863 31.9177L16.0137 31.9046L17.7931 33.5748L17.8902 33.6658L17.9462 33.5453L18.845 31.6147L18.9981 31.599L18.8971 31.5027L18.9394 31.4117L18.8151 31.4247L17.0659 29.7571Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M15.33 33.8325L15.3884 33.8259L15.4091 33.7708L16.1377 31.8383L16.2136 31.6364L16.0127 31.7148L9.38256 34.303L9.42806 34.4882L15.33 33.8325Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M35.4467 12.4501L39.0478 13.511L40.6176 15.3464L40.6573 15.3927L40.716 15.3769L42.5691 14.8705L43.9799 16.5431L43.8354 16.5633L44.1057 16.6924L44.1264 16.717L44.1505 16.7136L47.4505 18.2885L48.9486 20.0863L48.9766 20.1196L49.0198 20.1209L50.7149 20.1695L50.766 19.9902L47.574 18.1363L46.1558 16.4345L46.3022 16.414L46.0314 16.2853L46.0107 16.2604L45.9863 16.2639L42.8783 14.7861L42.9124 14.7768L42.6605 14.6807L41.0844 12.8122L41.0439 12.7644L40.9842 12.7819L39.1353 13.3173L37.5575 11.4725L37.5125 11.4195L37.448 11.4458L35.4373 12.269L35.1838 12.3727L35.4467 12.4501ZM17.9247 33.4344L17.818 33.3343L17.7685 33.472L17.0922 35.3532L16.942 35.3695L17.0502 35.4697L17.0198 35.5545L17.1291 35.5426L18.8654 37.1491L18.4818 38.8563L18.3333 38.869L18.4544 38.9795L18.4379 39.0533L18.5266 39.0456L20.301 40.6672L20.4258 40.7812L20.4601 40.6159L20.8267 38.8488L20.9722 38.8366L20.8521 38.7255L20.8673 38.6524L20.7812 38.6599L19.0827 37.0882L19.7086 35.2626L19.8557 35.2468L19.7486 35.1461L19.7774 35.0619L19.6709 35.0733L17.9247 33.4344ZM22.2032 42.1448L22.0536 42.0082L22.0423 42.2105L21.9505 43.8496L21.8102 43.8598L21.943 43.9798L21.9395 44.0433L22.0077 44.0382L23.7446 45.6092L23.8969 45.747L23.9052 45.5417L23.9709 43.8956L24.1081 43.8856L23.9761 43.7652L23.9786 43.7021L23.9125 43.7071L22.2032 42.1448Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.8252 35.6451L14.9153 35.6645L14.9385 35.5756L15.4123 33.7613L15.4476 33.6259L15.3085 33.6413L9.40686 34.297L9.39745 34.4868L14.8252 35.6451Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.4049 18.3309L49.5645 18.3329L49.2619 18.1575L49.2443 18.1365L49.2249 18.1363L46.0193 16.2798L45.8971 16.4248L47.3034 18.1122L47.1459 18.1101L47.4478 18.2856L47.4653 18.3066L47.4844 18.3068L50.6693 20.1566L50.7919 20.0124L49.4049 18.3309ZM37.5187 11.4452L37.4115 11.5975L39.0294 13.4894L39.0695 13.5364L39.129 13.5193L41.0374 12.9665L41.3139 12.8865L41.045 12.7843L37.5187 11.4452ZM16.1132 31.7345L16.0084 31.6357L15.9576 31.7706L15.229 33.703L15.1749 33.8469L15.328 33.8328L17.8679 33.6004L18.0807 33.581L17.9248 33.4345L16.1132 31.7345Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.51092 34.4161L9.32737 34.3596L7.27464 39.9834L7.27098 39.9935L7.26936 40.0042L6.63532 45.04L6.8241 45.0754L9.51092 34.4161Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M20.4307 40.5253L20.2844 40.3915L20.2698 40.5892L20.1449 42.3037L20.1369 42.4121L20.2453 42.4068L22.1431 42.3119L22.3733 42.3006L22.2032 42.1449L20.4307 40.5253ZM15.3103 33.6413L15.0952 33.6608L15.2537 33.8074L17.0969 35.5129L17.2034 35.6116L17.2527 35.4748L17.9495 33.5371L18.0005 33.3952L17.8502 33.4088L15.3103 33.6413Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7092 3.05882L48.6795 2.86898L46.1097 3.1921L46.1042 3.19272L46.0988 3.19417L43.3799 3.8555L43.4203 4.04358L48.7092 3.05882Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M44.5847 14.5496L44.7255 14.5274L44.4624 14.4028L44.441 14.3771L44.4163 14.3809L41.0707 12.7962L39.462 10.8888L39.4211 10.8402L39.3601 10.859L37.4561 11.4431L37.1881 11.5253L37.4501 11.6248L40.9533 12.9553L42.4191 14.693L42.2802 14.7148L42.5427 14.8395L42.5641 14.8652L42.5884 14.8611L45.9261 16.4483L49.1354 18.3064L50.6433 20.1347L50.6654 20.1614L50.6996 20.1678L52.256 20.4636L52.3288 20.29L49.2642 18.1607L47.7247 16.2946L47.6958 16.2592L47.6501 16.2595L46.0158 16.2668L44.5847 14.5496ZM20.2358 42.2148L20.0039 42.2264L20.1762 42.382L21.9495 43.986L22.1066 45.5365L22.1154 45.6224L22.2018 45.6231L23.8084 45.6338L24.0605 45.6357L23.8735 45.4665L22.1402 43.8989L22.2342 42.2212L22.2402 42.1145L22.1334 42.1198L20.2358 42.2148ZM17.2275 35.3718L17.1074 35.2604L17.0687 35.4197L16.6434 37.1734L16.4891 37.1837L16.6136 37.2975L16.596 37.3695L16.6857 37.3634L18.4953 39.0171L18.6177 39.1289L18.654 38.9673L19.0493 37.2072L19.2006 37.1975L19.0772 37.0832L19.0932 37.0117L19.0062 37.0174L17.2275 35.3718Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5818 37.3191L14.705 37.3864L14.723 37.2468L14.9405 35.5634L14.9518 35.4755L14.8653 35.4572L9.43724 34.2987L9.37116 34.4771L14.5818 37.3191Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.2894 45.4313L22.1483 44.0389L22.3002 44.0436L22.1334 43.8927L22.1288 43.846L22.0799 43.8442L20.3401 42.2709L20.4554 40.6901L20.6064 40.6856L20.4651 40.5566L20.4696 40.4972L20.4019 40.4991L18.8064 39.0408L18.6251 38.8751L18.4821 38.7445L16.8271 37.2322L17.2387 35.5349L17.396 35.5275L17.269 35.41L17.2858 35.3403L17.198 35.3444L15.3844 33.6664L15.2664 33.5574L15.226 33.7125L14.7701 35.4583L14.6095 35.466L14.7376 35.5831L14.7191 35.6533L14.8097 35.6491L16.6196 37.3027L16.4392 38.9488L16.4274 39.0561L16.5355 39.0554L18.4549 39.0429L18.3174 40.5572L18.163 40.5616L18.3054 40.6901L18.2998 40.75L18.3695 40.7481L20.148 42.3566L20.2687 43.7872L20.113 43.7823L20.2809 43.9327L20.2848 43.9801L20.3353 43.9816L21.9492 45.429L22.1382 45.5985L22.323 45.7644L23.9325 47.2075L24.1219 47.3774L24.0923 47.1249L23.9046 45.5266L23.8946 45.4422L23.8096 45.4417L22.2894 45.4313ZM44.3418 14.5585L44.3579 14.5778L45.897 16.4247L45.926 16.4596L45.9713 16.4594L47.6055 16.4521L49.1243 18.2931L49.1471 18.3205L49.1612 18.323L52.219 20.4481L52.3482 20.3082L50.8193 18.4396L50.7967 18.4118L50.7823 18.4094L47.9563 16.4506L48.0068 16.4504L47.7139 16.281L46.2735 14.5352L46.4196 14.5319L46.1361 14.3688L46.1175 14.3463L46.0977 14.3467L43.0818 12.6115L43.1192 12.605L42.8635 12.4845L42.8417 12.4581L41.2477 10.5457L41.2115 10.5019L41.1557 10.5128L39.3702 10.8565L39.081 10.9121L39.3284 11.0289L40.8291 12.8081L40.6958 12.8312L40.9506 12.9521L40.9727 12.9782L40.997 12.9742L44.3418 14.5585Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M7.45985 40.0283L7.27024 39.998L6.76945 42.6002L6.76825 42.6066L6.76792 42.6131L6.63434 45.0465L6.82576 45.0638L7.45985 40.0283Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.5344 38.8632L16.2868 38.8647L16.4704 39.0306L18.3411 40.7222L18.4838 40.8515L18.5013 40.6598L18.6563 38.9549L18.6658 38.8494L18.5597 38.85L16.5344 38.8632ZM50.8062 18.4263L50.6706 18.5614L52.1995 20.43L52.2156 20.4493L52.239 20.4586L53.6347 21.0046L53.7311 20.8409L50.8062 18.4263Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.76 3.03195L48.6289 2.89145L39.3256 10.8779L39.0507 11.1138L39.4064 11.0452L41.1916 10.7016L41.2211 10.6959L41.2423 10.6747L48.76 3.03195Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.2668 45.4553L22.0151 45.2294L20.4662 43.8407L20.3459 42.4128L20.507 42.4219L20.3336 42.2647L20.3297 42.2193L20.2801 42.2165L18.4702 40.5798L18.2939 40.4203L18.3097 40.6574L18.4061 42.1128L18.2413 42.1035L18.4158 42.2602L18.4189 42.306L18.4702 42.3088L20.2886 43.9396L20.6749 45.3312L20.6915 45.3911L20.7529 45.4004L22.1275 45.6127L22.4898 46.8455L22.3273 46.8245L22.5388 47.013L22.5489 47.0467L22.5817 47.0512L24.3797 48.6537L24.6376 48.8834L24.5357 48.5534L24.1329 47.2506L24.2913 47.2708L24.0809 47.0821L24.0705 47.0484L24.039 47.0444L22.5076 45.6713L22.2668 45.4553ZM52.1774 18.9359L49.255 16.5277L49.2378 16.5066L49.2233 16.5042L46.1391 14.372L44.6625 12.5824L44.8024 12.5767L44.5283 12.4196L44.509 12.3964L44.4889 12.3972L41.222 10.5238L41.1001 10.6687L42.6048 12.4742L42.4672 12.4798L42.7408 12.6372L42.7601 12.6604L42.7796 12.6595L46.0103 14.518L47.5765 16.417L47.5998 16.4452L47.6142 16.4473L50.6791 18.5718L50.6869 18.5815L50.6962 18.5847L53.6087 20.9892L53.7444 20.8544L52.2033 18.9597L52.1867 18.9394L52.1774 18.9359ZM14.9104 35.4802L14.7738 35.3555L14.7501 35.539L14.5436 37.1372L14.374 37.1348L14.526 37.2721L14.5188 37.3292L14.5904 37.3303L16.4705 39.0305L16.61 39.1567L16.6305 38.9698L16.8066 37.3624L16.9722 37.3645L16.8216 37.2267L16.8277 37.17L16.7584 37.1691L14.9104 35.4802Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7677 3.02345L48.6232 2.89697L41.1055 10.5397L40.9299 10.7185L41.18 10.7033L42.8318 10.6032L42.8752 10.6005L42.9018 10.566L48.7677 3.02345Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.6054 17.0036L47.6663 14.586L47.6486 14.5642L46.0525 12.6137L46.0279 12.5837L45.9895 12.5791L44.515 12.4033L42.9 10.446L42.8691 10.4081L42.8201 10.4112L41.1683 10.5113L40.8472 10.5306L41.1262 10.6905L44.4024 12.5691L45.9991 14.5046L46.023 14.5336L46.0374 14.5357L49.121 16.6672L49.1291 16.6774L49.1384 16.6806L52.0602 19.0884L53.5952 20.9757L53.6047 20.9871L53.617 20.9951L54.8277 21.7959L54.9482 21.6471L52.1998 18.9552L50.6319 17.0276L50.6149 17.0067L50.6054 17.0036ZM20.7823 45.2103L20.4608 45.1608L20.7036 45.3773L22.5471 47.02L23.223 48.2615L23.2415 48.2953L23.2587 48.3008L25.0884 49.9253L25.2363 49.8065L24.5279 48.535L24.5095 48.5018L24.4926 48.4964L22.7081 46.9061L22.2949 45.4996L22.2777 45.4411L22.2174 45.4317L20.7823 45.2103ZM16.5996 38.8878L16.4282 38.7329L16.439 38.9638L16.5071 40.4056L16.3328 40.3919L16.5143 40.5548L16.5163 40.5994L16.5684 40.6034L18.4237 42.267L18.7821 43.6429L18.7975 43.7025L18.8581 43.7135L20.3568 43.9815L20.6898 44.0409L20.4378 43.8152L18.6026 42.1694L18.5088 40.7554L18.6794 40.7689L18.499 40.6056L18.4962 40.5617L18.4458 40.5576L16.5996 38.8878Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7755 3.01195L48.616 2.90537L42.7498 10.448L42.6428 10.5857L42.8164 10.6028L44.3162 10.7511L44.3785 10.7572L44.4094 10.7029L48.7755 3.01195Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M42.8804 10.4279L42.7515 10.5683L44.3911 12.5555L44.4156 12.5851L44.4538 12.5898L45.9664 12.77L46.0323 12.5953L42.8804 10.4279ZM18.8919 43.5241L18.5569 43.4642L18.8109 43.6906L20.6906 45.3656L21.341 46.5902L21.3586 46.6238L21.3944 46.6362L22.5918 47.0503L22.6873 46.8878L20.8526 45.2523L20.4664 43.861L20.45 43.8028L20.3906 43.7921L18.8919 43.5241ZM14.6923 37.1634L14.5257 37.0124L14.5316 37.2371L14.5706 38.6649L14.3861 38.6465L14.5747 38.8154L14.5759 38.8588L14.6288 38.8641L16.5442 40.5815L16.7154 40.7351L16.7044 40.5053L16.6362 39.066L16.8166 39.0843L16.6289 38.9147L16.6269 38.8717L16.576 38.8666L14.6923 37.1634ZM53.3994 19.7356L53.3899 19.7237L53.3852 19.7208L50.6243 17.0195L50.4826 17.1488L52.0541 19.0809L52.0636 19.0926L52.0683 19.0957L54.8134 21.7845L54.9556 21.6553L53.3994 19.7356Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.2582 3.49655L53.2849 3.30612L51.1026 2.90095L51.0945 2.89948L51.0863 2.89944L48.6929 2.86829L48.6824 3.06025L53.2582 3.49655Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.0292 15.0573L49.0116 15.036L49.0021 15.0328L46.046 12.6063L44.4002 10.5945L44.3749 10.5636L44.3352 10.5597L42.8351 10.4114L42.7713 10.5864L45.912 12.7459L47.5233 14.7152L47.5405 14.7363L47.5501 14.7395L50.496 17.1626L50.6317 17.0276L49.0292 15.0573ZM21.4573 46.4544L21.3619 46.6171L23.2434 48.2876L23.3917 48.1696L22.7077 46.9134L22.6899 46.8807L22.6547 46.8685L21.4573 46.4544ZM16.6724 40.4384L16.4419 40.2317L16.5146 40.5328L16.8512 41.921L16.8655 41.9805L16.9254 41.9928L18.49 42.3091L18.8362 42.379L18.5733 42.143L16.6724 40.4384Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.72 37.2615L14.7414 37.1871L14.6735 37.1503L9.4632 34.3082L9.31313 34.2265L9.32106 34.3972L10.1579 52.3929L10.3465 52.4152L14.72 37.2615Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9638 41.8041L16.6152 41.7338L16.8808 41.9704L18.8109 43.6907L19.0481 43.9019L18.9679 43.5945L18.6023 42.1906L18.5871 42.1324L18.5284 42.1204L16.9638 41.8041Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.51329 34.3882L9.32412 34.3691L6.63728 45.0284L6.6288 45.0621L6.64385 45.0936L10.1674 52.4298L10.3501 52.3839L9.51329 34.3882ZM48.7819 2.99835L48.608 2.9169L44.2419 10.6081L44.1827 10.7126L44.2975 10.7475L45.6244 11.1507L45.7105 11.1766L45.7424 11.0924L48.7819 2.99835Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M51.8116 17.7767L51.8025 17.7656L51.7978 17.7624L49.0257 15.0531L47.3945 13.048L47.3765 13.0259L47.367 13.0227L44.3867 10.581L44.2511 10.7164L45.9033 12.7355L45.9212 12.7573L45.931 12.7605L48.8861 15.1863L50.4825 17.1489L50.4924 17.161L50.4971 17.1642L53.2534 19.8609L54.806 21.7765L54.8082 21.7792L54.8106 21.7819L55.8091 22.8414L55.8984 22.7609L55.9537 22.7147L54.3806 20.7721L54.3784 20.7691L54.3774 20.7682L51.8116 17.7767ZM22.2992 47.6615L23.2439 48.289L24.1859 49.3347L24.1942 49.3441L24.1989 49.3472L26.0608 50.9979L26.1957 50.8615L25.2233 49.7887L25.2151 49.7797L25.2107 49.7767L23.3711 48.1438L21.5028 46.4849L20.8524 45.2603L20.835 45.228L20.8176 45.2216L18.9389 43.5471L18.7894 43.6631L19.4212 44.8854L19.4384 44.9185L19.4564 44.9254L21.358 46.6136L22.2806 47.6455L22.2888 47.6545L22.2992 47.6615ZM14.734 38.7001L14.5107 38.4998L14.5759 38.7925L14.8485 40.0166L14.6343 39.9685L14.8883 40.1946L14.8948 40.2239L14.9301 40.2318L16.8807 41.9704L17.1106 42.1752L17.0379 41.8759L16.7382 40.6378L16.9469 40.6845L16.6946 40.4584L16.6875 40.4292L16.6537 40.4216L14.734 38.7001Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7857 2.98498L48.6016 2.9305L45.5624 11.0245L45.5339 11.1003L45.6036 11.1413L46.7322 11.8056L46.8467 11.873L46.875 11.7433L48.7857 2.98498Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.1932 15.7803L50.183 15.7677L50.1782 15.7645L47.3908 13.0437L45.7267 10.9979L45.7083 10.9751L45.6802 10.9665L44.3533 10.5635L44.2645 10.7299L47.2512 13.1769L48.8797 15.1789L48.8899 15.1913L48.8946 15.1944L51.6703 17.907L51.8122 17.7777L50.1932 15.7803ZM22.4052 47.5012L22.2885 47.6534L24.1932 49.3425L24.3286 49.206L23.3786 48.1512L23.3704 48.1421L23.3603 48.1356L22.4052 47.5012ZM17.0083 41.8267L16.8584 41.9417L17.4621 43.1459L17.4788 43.1791L17.4971 43.1862L19.4425 44.9133L19.5919 44.7973L18.9601 43.5747L18.9433 43.5423L18.9256 43.5354L17.0083 41.8267Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M6.8243 45.0317L6.63468 45.0627L6.88588 47.2974L6.88693 47.3071L6.88991 47.3167L7.54586 49.3154L7.73116 49.2651L6.8243 45.0317Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M52.7755 18.7902L52.7731 18.7873L52.7721 18.7863L50.1915 15.7782L50.0438 15.9015L51.6628 17.8988L51.6653 17.9018L51.6663 17.9027L54.2327 20.8952L54.3805 20.7722L52.7755 18.7902ZM19.5702 44.7695L19.4344 44.9052L20.3362 45.9216L20.3445 45.9307L20.3543 45.9374L21.3718 46.6248L21.4895 46.4733L19.5702 44.7695Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M56.794 5.51909L56.8696 5.34362L53.3152 3.31708L53.2972 3.30684L53.2767 3.3049L48.701 2.86859L48.664 3.05651L56.794 5.51909Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.5416 13.7423L48.531 13.7291L48.5263 13.7263L45.7194 10.9896L45.5778 11.1192L47.2454 13.1692L47.256 13.1823L47.2607 13.1852L50.0516 15.9097L50.1935 15.7803L48.5416 13.7423ZM20.4622 45.7779L20.3445 45.9297L22.2887 47.6533L22.4242 47.5172L21.4975 46.4812L21.4896 46.4721L21.4798 46.4656L20.4622 45.7779ZM15.0395 40.0718L14.8889 40.1855L15.4636 41.3706L15.4798 41.4039L15.5138 41.4176L16.9084 41.9874L17.0086 41.8267L15.0395 40.0718Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7871 2.97437L48.5975 2.94387L46.6868 11.7024L46.6757 11.7526L46.7117 11.7896L47.6148 12.7224L47.7582 12.8707L47.7796 12.6654L48.7871 2.97437Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M15.5866 41.2396L15.4864 41.4005L17.4848 43.1748L17.6346 43.0596L17.0309 41.8554L17.0146 41.8232L16.9811 41.8094L15.5866 41.2396ZM51.1378 16.7673L51.1354 16.7643L51.1344 16.7633L48.54 13.7401L48.3923 13.8634L50.0445 15.9013L50.0469 15.9043L50.0477 15.9053L52.6282 18.9131L52.7759 18.7901L51.1378 16.7673Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.7609 38.8013L14.7662 38.7855L14.7657 38.769L14.7239 37.2321L14.5352 37.2079L10.1617 52.3616L10.3455 52.4181L14.7609 38.8013Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.6121 43.0309L17.476 43.1664L18.3521 44.1618L18.36 44.1708L18.3648 44.1743L20.3446 45.9297L20.4801 45.7938L19.5783 44.7776L19.5707 44.7688L19.5659 44.7653L17.6121 43.0309ZM46.8556 11.6621L46.8447 11.6488L46.8298 11.64L45.7011 10.9755L45.5851 11.1272L48.3998 13.8716L48.5414 13.7423L46.8556 11.6621Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7878 2.96758L48.596 2.95457L47.5882 12.6457L47.5852 12.6751L47.5993 12.7009L48.2458 13.9087L48.4149 14.2245L48.4268 13.8664L48.7878 2.96758Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.4657 14.7028L49.463 14.6996L49.462 14.6987L46.8539 11.6602L46.7062 11.7835L48.3922 13.8634L48.3947 13.8666L48.3957 13.8676L50.9897 16.8906L51.1374 16.7673L49.4657 14.7028ZM15.6138 41.2567L15.4776 41.3919L16.3262 42.3657L16.3342 42.3745L16.3437 42.3814L17.4934 43.1818L17.6121 43.031L15.6138 41.2567Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M15.0651 40.1781L15.0756 40.151L15.0692 40.1226L14.7634 38.7506L14.687 38.4067L14.5782 38.7419L10.1628 52.3586L10.344 52.4229L15.0651 40.1781Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.4537 42.2235L16.3351 42.3743L18.3603 44.1703L18.4964 44.0348L17.6206 43.0394L17.6127 43.0306L17.6032 43.024L16.4537 42.2235Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M6.81735 45.0101L6.63649 45.0721L7.54335 49.3055L7.54865 49.329L7.56403 49.3473L10.1808 52.4503L10.341 52.3466L6.81735 45.0101ZM56.7741 5.5106L56.8798 5.35041L55.2543 4.12606L55.2447 4.11893L55.2335 4.11404L53.3046 3.31185L53.2198 3.48432L56.7741 5.5106ZM48.788 2.96442L48.5958 2.96129L48.2347 13.8599L48.2341 13.8729L48.2373 13.8856L48.5945 15.3735L48.7841 15.351L48.788 2.96442Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M47.7586 12.595L47.7561 12.5917L47.753 12.5886L46.8501 11.6558L46.708 11.7855L49.3178 14.8259L49.4655 14.7028L47.7586 12.595Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M15.6366 41.3701L15.6564 41.3283L15.6363 41.2867L15.0616 40.1016L14.9651 39.9023L14.8855 40.109L10.1643 52.3535L10.3407 52.4298L15.6366 41.3701Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.7387 59.1366L18.6249 59.2914L20.6241 60.9107L20.6312 60.9163L20.6392 60.9205L22.8148 62.0836L22.9135 61.9186L18.7387 59.1366Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.788 2.96411L48.5958 2.96436L48.5956 2.96469L48.5917 15.351L48.5917 15.3526L48.6243 17.1227L48.6243 17.1244L48.8167 17.1224L48.8167 17.1207L48.788 2.96439L48.788 2.96411ZM16.4811 42.3525L16.5178 42.2922L16.4714 42.2392L15.6227 41.2653L15.5266 41.155L15.4633 41.2872L10.1674 52.3466L10.3365 52.4381L16.4811 42.3525Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.626 15.7945L64.4601 15.889L65.3937 18.0852L65.3683 18.0948L65.4373 18.1876L65.469 18.2622L65.4872 18.2553L67.0066 20.3025L67.1721 20.2072L66.2093 17.9804L66.2336 17.971L66.1649 17.8781L66.1329 17.8036L66.1149 17.8105L64.626 15.7945ZM16.6974 57.4835L16.5835 57.6383L18.6248 59.2913L18.6323 59.2974L18.6363 59.2993L22.8067 62.0787L22.9207 61.9238L20.8915 60.2741L20.8841 60.268L20.8801 60.2659L16.6974 57.4835Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M7.71104 49.2233L7.55613 49.3368L8.63922 51.0548L8.6464 51.0659L8.65628 51.0747L10.1896 52.4595L10.3278 52.3263L7.71104 49.2233Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M63.0617 13.6758L62.8955 13.7698L63.836 16.025L63.8671 16.0992L63.9454 16.082L64.5695 15.9455L64.7146 15.9137L64.6263 15.7942L63.0617 13.6758Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.602 43.0964L17.6662 43.0138L17.5787 42.9569L16.451 42.222L16.3679 42.1677L16.3164 42.2523L10.1718 52.3382L10.3298 52.4473L17.602 43.0964Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.6127 55.7952L14.4989 55.95L16.5837 57.6383L16.5911 57.6444L16.5998 57.6488L18.7132 58.7485L18.811 58.5832L14.6127 55.7952Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.9319 15.0975L59.0987 15.0137L56.9156 5.40575L56.9033 5.35129L56.8497 5.33513L48.7197 2.87227L48.4053 2.77722L48.6187 3.02691L58.9319 15.0975Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.8185 58.5887L18.811 58.5824L18.8021 58.5779L16.6889 57.4782L16.5912 57.6436L20.7739 60.4263L22.7996 62.0732L22.8152 62.0861L22.8349 62.0913L25.4698 62.8176L25.539 62.6393L20.8837 60.2676L18.8185 58.5887ZM63.9043 15.8941L63.7589 15.9259L63.8475 16.0454L65.4442 18.197L65.61 18.1019L64.6374 15.8141L64.6065 15.7405L64.5284 15.7576L63.9043 15.8941ZM61.4636 11.5118L61.2972 11.6053L62.2039 13.8259L62.2307 13.8911L62.3008 13.8855L62.992 13.8287L63.1642 13.8146L63.0617 13.6758L61.4636 11.5118Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M19.005 43.5712L19.1128 43.4613L18.9665 43.4129L17.5567 42.9462L17.4922 42.9248L17.4504 42.9784L10.1782 52.3293L10.323 52.4555L19.005 43.5712Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M12.483 54.0704L12.3692 54.2252L14.499 55.9499L14.5068 55.9562L14.5108 55.9584L18.7043 58.7432L18.8182 58.5887L16.6997 56.866L16.6919 56.8597L16.6876 56.8576L12.483 54.0704ZM65.5983 18.0824L65.4265 18.1566L65.9179 20.9133L65.9425 21.0517L66.0625 20.9788L67.1338 20.3273L67.2239 20.2725L67.161 20.1879L65.5983 18.0824ZM62.2849 13.6936L62.1124 13.7077L62.2155 13.8469L63.8472 16.0456L64.0131 15.9511L63.0726 13.6959L63.046 13.6314L62.9761 13.637L62.2849 13.6936Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.0241 15.1292L59.1843 15.0969L59.0781 14.9725L48.7652 2.9019L48.5953 2.70318L48.5957 2.96473L48.6244 17.1227L48.6247 17.2401L48.7397 17.2169L59.0241 15.1292Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.8011 58.5775L18.6969 58.7377L20.7703 60.4234L20.7862 60.4365L20.7964 60.4391L25.4514 62.8108L25.5562 62.6507L23.5029 60.9656L23.4867 60.9526L23.4764 60.9498L18.8011 58.5775ZM65.9627 20.8143L65.8721 20.8695L65.9358 20.9542L67.5338 23.0846L67.7051 23.0091L67.1785 20.2275L67.1528 20.091L67.0341 20.1631L65.9627 20.8143ZM59.8306 9.30114L59.6638 9.39387L60.5355 11.5775L60.5588 11.6358L60.6213 11.6381L61.3825 11.665L61.5818 11.672L61.4634 11.5117L59.8306 9.30114ZM10.307 52.3081L10.1935 52.4631L12.3694 54.2252L12.3775 54.2317L12.3867 54.2364L14.4305 55.2661L14.527 55.1001L10.307 52.3081Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M20.6916 43.7447L20.8663 43.5978L20.6392 43.5752L18.9458 43.4085L18.8999 43.404L18.8674 43.4369L10.1853 52.3212L10.3159 52.4621L20.6916 43.7447Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5345 55.1056L14.5264 55.099L14.5169 55.0944L12.4732 54.0647L12.3769 54.2307L16.5859 57.0208L16.6997 56.866L14.5345 55.1056ZM64.0017 15.9308L63.8297 16.0038L64.2842 18.7346L64.3058 18.8637L64.4225 18.8046L65.5647 18.2254L65.6663 18.1738L65.5984 18.0824L64.0017 15.9308Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.8352 9.29046L59.0092 9.20918L58.1746 7.06437L58.1697 7.05196L58.1617 7.0412L56.8985 5.36925L56.7376 5.47339L58.8352 9.29046Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.6908 11.4482L59.9038 9.47598L59.9658 9.48427L59.838 9.31091L59.8218 9.27061L59.8065 9.26834L58.1621 7.04198L57.9952 7.13416L58.7704 9.12665L58.7057 9.11767L58.8343 9.29089L58.8503 9.33152L58.8661 9.33396L60.4284 11.4387L60.5329 11.5797L60.9049 14.2275L60.9209 14.3414L61.0301 14.3054L62.2149 13.9162L62.6022 16.4319L62.5572 16.4506L62.6173 16.5303L62.6322 16.6276L62.6764 16.6093L64.2333 18.6849L64.3021 18.7764L65.9159 20.9277L65.8515 24.0797L65.8077 24.1086L65.8496 24.1635L65.8466 24.3138L65.9246 24.2619L67.5085 26.3375L67.6788 26.5609L67.681 26.2798L67.7064 23.0789L67.7487 23.0505L67.7069 22.995L67.7083 22.8467L67.6331 22.8965L66.1032 20.8572L65.6158 18.1228L65.5931 17.9953L65.4777 18.0537L64.4524 18.5737L64.032 16.0477L64.0752 16.0299L64.0155 15.9496L63.9996 15.853L63.957 15.8707L62.4495 13.8393L62.3772 13.7419L61.4749 11.5326L61.4514 11.475L61.3892 11.4729L60.6908 11.4482ZM18.7134 58.7513L18.7236 58.7538L23.3888 61.1211L23.4051 61.1344L25.434 62.7994L25.4577 62.8188L25.4882 62.821L28.5878 63.0413L28.6054 62.9589L28.6561 62.8716L26.5865 61.147L26.5623 61.1268L26.5448 61.1258L21.3914 59.2331L21.3875 59.2299L19.2581 57.4826L19.2409 57.4686L19.2304 57.4661L14.5264 55.0991L12.3206 53.3057L12.3122 53.2989L12.3027 53.2943L10.2967 52.3022L10.2011 52.4686L14.4167 55.258L16.5783 57.0154L16.5955 57.0291L18.6968 58.7378L18.7134 58.7513Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M10.2852 52.2971L10.2116 52.4745L12.2177 53.4666L12.2284 53.4718L12.2403 53.4744L14.7377 54.0076L14.7886 53.8225L10.2852 52.2971Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M12.3034 53.2944L12.1997 53.4549L14.4134 55.2548L14.4309 55.2691L14.4414 55.2713L19.1542 57.6427L19.2584 57.4825L17.0638 55.6817L17.0462 55.6671L17.0354 55.6647L12.3034 53.2944ZM64.4565 18.661L64.2926 18.4427L64.2834 18.7154L64.175 21.9326L64.1689 22.1086L64.3204 22.0184L66.0624 20.9791L66.1543 20.9243L66.0898 20.8389L64.4565 18.661ZM60.9705 14.1227L60.8431 14.1646L60.9235 14.2719L62.632 16.5495L62.8042 16.4772L62.3884 13.7749L62.371 13.6628L62.2633 13.698L60.9705 14.1227Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.9111 15.0563L59.1012 15.0336L59.0157 9.24275L59.0154 9.21864L59.0038 9.19778L56.9063 5.38098L56.7283 5.44858L58.9111 15.0563Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M19.23 57.4666L19.1361 57.631L21.282 59.3921L21.3067 59.4125L21.3237 59.4131L26.4762 61.3056L28.5329 63.0194L28.5638 63.0452L28.6042 63.041L32.1642 62.6834L32.1774 62.4944L26.5694 61.1329L24.4697 59.3835L24.445 59.3628L24.4274 59.3618L19.23 57.4666ZM64.2212 21.8532L64.1286 21.9084L64.1942 21.9942L65.8441 24.1562L65.1751 27.7092L65.1346 27.925L65.3208 27.8085L67.6358 26.3605L67.7255 26.3046L67.6613 26.2208L66.0433 24.1002L66.1088 20.8986L66.1122 20.7252L65.9633 20.8139L64.2212 21.8532ZM58.9965 9.1867L58.7779 8.89233L58.8239 9.25596L59.1457 11.8111L59.088 11.8257L59.1597 11.9212L59.1701 12.0032L59.2133 11.9927L60.923 14.2718L61.095 14.2007L60.7321 11.6144L60.788 11.6004L60.7165 11.5043L60.705 11.4227L60.6637 11.4332L58.9965 9.1867ZM62.7853 16.4341L62.6253 16.2206L62.6126 16.4874L62.4598 19.6841L62.4517 19.8531L62.601 19.7737L64.4242 18.8036L64.5234 18.751L64.456 18.6609L62.7853 16.4341ZM14.8182 53.8394L14.8003 53.8243L14.7773 53.8194L12.2799 53.2864L12.2169 53.4663L16.9593 55.8418L17.0633 55.6816L14.8182 53.8394Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.04 15.1246L58.986 14.9407L48.7015 17.0281L48.6363 17.0414L48.6258 17.1072L48.2978 19.1627L48.2715 19.3283L48.4277 19.2674L59.04 15.1246Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.0354 55.6655L16.9417 55.8301L19.1361 57.631L19.1615 57.6519L19.1787 57.6524L24.3599 59.5419L26.4638 61.2948L26.4951 61.3211L26.5191 61.3182L32.1321 62.6812L32.2173 62.5145L30.1406 60.7459L30.1089 60.7188L30.084 60.7215L24.4528 59.3689L22.3049 57.5791L22.2794 57.5578L22.2617 57.5573L17.0354 55.6655ZM62.5109 19.6037L62.4109 19.657L62.4798 19.7471L64.1945 21.9939L64.3577 22.2083L64.3669 21.939L64.4754 18.7219L64.4811 18.5557L64.3341 18.6339L62.5109 19.6037ZM65.2188 27.6453L65.1284 27.7021L65.1943 27.7862L66.8772 29.9404L67.0104 30.1109L67.0478 29.898L67.6799 26.2956L67.7173 26.0829L67.5341 26.1975L65.2188 27.6453ZM61.077 14.1562L60.9206 13.9477L60.9043 14.2081L60.7052 17.3832L60.6952 17.5453L60.8422 17.476L62.7497 16.5789L62.8566 16.5286L62.7856 16.4341L61.077 14.1562Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M10.2624 52.2924L10.2233 52.4792L14.7267 54.0046L14.7461 54.0112L14.7663 54.0093L21.2788 53.4132L21.2781 53.2217L10.2624 52.2924ZM48.3575 19.0882L48.3145 19.1051L48.3007 19.1493L47.6014 21.4105L47.5298 21.6418L47.7405 21.5224L59.0523 15.1186L58.97 14.9453L48.3575 19.0882Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M64.3472 21.8774L64.2184 21.7087L64.1764 21.9168L63.4618 25.4606L63.4035 25.4943L63.4444 25.5463L63.4109 25.7123L63.5232 25.6474L65.194 27.7863L65.3251 27.9537L65.3644 27.7446L66.0331 24.193L66.0895 24.1604L66.049 24.1078L66.0801 23.9436L65.9719 24.0062L64.3472 21.8774ZM14.7899 53.823L14.6963 53.988L16.9414 55.8302L16.9674 55.8515L16.9848 55.8517L22.1944 57.7376L24.3469 59.5311L24.3791 59.5578L24.4031 59.555L30.0332 60.9073L32.0925 62.6611L32.1301 62.6931L32.1781 62.6809L36.055 61.7095L36.0444 61.5211L30.119 60.7276L28.0155 58.9358L27.9832 58.9086L27.9584 58.9115L22.2873 57.5647L20.0889 55.7329L20.0628 55.7111L20.0448 55.711L14.7899 53.823ZM60.7604 17.3021L60.6524 17.3529L60.7248 17.4474L62.4797 19.7471L62.6396 19.9565L62.6521 19.6934L62.8049 16.4964L62.8125 16.3367L62.6678 16.4047L60.7604 17.3021Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.823 9.24552L58.9076 14.9795L47.6454 21.3552L47.6179 21.371L47.605 21.4001L46.5598 23.7634L46.4187 24.0829L46.7034 23.8808L59.0601 15.1137L59.0134 15.0399L59.1004 15.0427L59.3478 11.8914L59.3485 11.8817L59.3474 11.8718L59.0147 9.23214L58.823 9.24552ZM20.6372 43.5752L20.5979 43.5721L20.5675 43.5974L10.1921 52.3147L10.2789 52.4813L37.5397 45.1273L37.5223 44.9386L20.6372 43.5752Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.3454 27.6677L65.2357 27.5273L65.1787 27.6961L63.9107 31.447L63.8433 31.4842L63.8821 31.5323L63.8196 31.7169L63.9656 31.6366L65.6452 33.7251L65.7566 33.8635L65.8116 33.6945L67.0327 29.9472L67.0981 29.9111L67.0603 29.8625L67.1197 29.6796L66.9784 29.7577L65.3454 27.6677ZM59.329 11.8262L59.1762 11.6224L59.1561 11.8764L58.9135 14.9687L58.8522 14.9938L58.9059 15.0641L58.8967 15.1837L58.973 15.1522L60.7247 17.4475L60.881 17.6523L60.897 17.3951L61.0923 14.2802L61.1515 14.2559L61.0982 14.1849L61.1057 14.0667L61.0322 14.0968L59.329 11.8262ZM62.6323 19.6304L62.506 19.4647L62.4619 19.6686L61.6994 23.2014L61.6379 23.2343L61.6804 23.2889L61.6462 23.448L61.758 23.3882L63.4715 25.5815L63.6001 25.7463L63.6416 25.5411L64.3559 21.9994L64.4156 21.9673L64.3736 21.9121L64.4052 21.7549L64.2975 21.8123L62.6323 19.6304ZM34.0214 59.7279L33.983 59.6943L33.9517 59.7023L27.9935 58.9176L25.84 57.0835L25.8073 57.0555L25.7823 57.0591L20.0715 55.7184L17.8207 53.8431L17.794 53.8208L17.7594 53.8209L14.7574 53.8175L14.7247 54.0041L19.9787 55.8917L22.1816 57.7269L22.2142 57.7544L22.2384 57.7509L27.9083 59.0975L30.0157 60.8925L30.0539 60.9249L30.0841 60.9169L36.0187 61.7117L36.3345 61.7542L36.095 61.5442L34.0214 59.7279Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5925 23.7237L46.5751 23.7362L46.5645 23.7551L45.1972 26.1777L45.3418 26.2994L59.0658 15.1097L58.9495 14.9566L46.5925 23.7237ZM22.6148 43.41L22.606 43.4092L22.5971 43.4099L20.6219 43.5752L20.6221 43.7668L37.5052 45.1299L37.5075 45.1301L37.525 44.9387L37.5228 44.9385L22.6148 43.41Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M33.9623 59.7041L33.6892 59.6925L33.8948 59.8724L35.9684 61.6887L36.0121 61.727L36.0665 61.706L40.0698 60.1489L40.0391 59.9632L33.9623 59.7041ZM63.6232 25.4628L63.5149 25.3242L63.4566 25.4903L62.1279 29.2785L62.0476 29.5073L62.263 29.3956L65.3141 27.8122L65.4169 27.759L65.3456 27.6676L63.6232 25.4628Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.2199 26.1504L45.2093 26.1591L45.2014 26.1708L43.5417 28.6063L43.6851 28.7324L59.0684 15.107L58.9439 14.9606L45.2199 26.1504Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.8776 17.3309L60.7532 17.1682L60.7072 17.3674L59.8848 20.9323L59.8392 21.1295L60.0207 21.0406L62.5982 19.7751L62.7032 19.7235L62.6322 19.6304L60.8776 17.3309ZM62.1742 29.2251L62.0699 29.2791L62.1434 29.3707L63.915 31.5735L64.0249 31.7102L64.0809 31.5439L65.3608 27.7579L65.4373 27.5317L65.2256 27.6417L62.1742 29.2251ZM31.8985 57.8685L31.8594 57.8342L31.8282 57.843L25.7899 57.0614L25.4686 57.0198L25.7154 57.23L27.8905 59.0824L27.9292 59.1156L27.9595 59.1072L33.9453 59.8955L34.2601 59.9369L34.0213 59.7279L31.8985 57.8685Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.7485 53.8178L14.7571 54.0097L17.7592 54.0132L17.7673 54.0132L17.7755 54.0119L21.2861 53.4124L21.261 53.2217L14.7485 53.8178Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.7809 53.8232L17.6976 53.9907L19.9657 55.8806L19.9992 55.9085L20.0419 55.9017L23.5654 55.3548L23.5725 55.1663L17.7809 53.8232Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.5571 28.5883L43.551 28.5938L43.5459 28.6003L41.6293 31.0009L41.7697 31.1315L59.0683 15.107L59.07 15.1056L58.9408 14.9631L58.9391 14.9645L43.5571 28.5883ZM24.8712 42.8812L24.8524 42.8782L24.8338 42.8827L22.5827 43.4122L22.5948 43.6016L37.5047 45.13L37.53 44.9395L24.8712 42.8812Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6129 55.1868L23.5795 55.1581L23.536 55.165L20.0125 55.7118L20.0052 55.9005L25.7557 57.2503L25.84 57.0835L23.6129 55.1868ZM59.936 20.8677L59.8297 20.9201L59.9027 21.0134L61.7081 23.3244L61.8346 23.4862L61.8779 23.2855L62.6498 19.709L62.6919 19.5148L62.5136 19.6025L59.936 20.8677ZM64.0649 31.4529L63.969 31.3336L63.9033 31.4718L62.0443 35.3702L61.9262 35.6184L62.1733 35.4981L65.7622 33.7512L65.8707 33.6985L65.7953 33.6044L64.0649 31.4529Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6391 30.9903L41.6361 30.9927L41.6335 30.9957L39.5003 33.3118L39.637 33.4469L59.07 15.1055L59.0705 15.1052L58.9391 14.9644L58.9386 14.9651L41.6391 30.9903Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M62.089 35.3251L61.9787 35.3788L62.0572 35.4731L63.8365 37.6121L63.9336 37.7287L63.9974 37.5911L65.8074 33.7052L65.9219 33.4596L65.6782 33.5782L62.089 35.3251Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M39.5052 33.3071L39.5049 33.3072L37.1997 35.4904L37.1995 35.4907L37.3316 35.6305L59.0705 15.105L59.0047 15.0352L58.9387 14.9651L58.9374 14.9662L39.5052 33.3071Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M38.0389 58.1232L37.9946 58.0827L37.958 58.0975L31.8389 57.8446L31.5664 57.8334L31.7717 58.0131L33.8946 59.8724L33.939 59.9115L33.9745 59.8972L40.0307 60.1557L40.2967 60.1668L40.0992 59.9881L38.0389 58.1232ZM61.8596 23.2059L61.7528 23.0692L61.6934 23.2322L60.3138 27.0214L60.2324 27.2451L60.4461 27.1406L63.5894 25.6087L63.696 25.5565L63.623 25.4629L61.8596 23.2059ZM59.0811 14.9767L58.9592 14.8173L58.9113 15.0121L58.0356 18.5638L57.9886 18.7554L58.1683 18.6749L60.8405 17.4769L60.9511 17.4272L60.8776 17.3309L59.0811 14.9767Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.3191 41.9903L27.2888 41.9816L27.2594 41.9926L24.823 42.8858L24.4783 43.0124L24.8407 43.0711L37.4995 45.1291L37.5416 44.9418L27.3191 41.9903Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.3622 26.9677L60.2538 27.0207L60.3294 27.1145L62.1437 29.3707L62.2522 29.5053L62.3093 29.3422L63.6383 25.554L63.7156 25.3333L63.5052 25.4358L60.3622 26.9677ZM37.971 58.0982L37.7356 58.1076L37.9101 58.2656L39.9704 60.1308L40.02 60.1757L40.0795 60.1448L44.1208 58.0383L44.0726 57.8572L37.971 58.0982ZM29.7246 55.9642L29.685 55.9298L29.6535 55.9386L23.5631 55.1645L23.2427 55.1238L23.4884 55.3334L25.7154 57.23L25.7549 57.2634L25.7853 57.2547L31.823 58.0361L32.1363 58.0769L31.8987 57.8685L29.7246 55.9642Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M10.2291 52.2954L10.2462 52.4842L21.2619 53.4132L21.2892 53.4155L21.3136 53.4033L37.5584 45.12L37.4899 44.9414L10.2291 52.2954Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.3323 53.2442L21.2979 53.215L21.2537 53.2226L17.7431 53.8221L17.7374 54.0108L23.529 55.3535L23.613 55.1868L21.3323 53.2442ZM58.0897 18.4992L57.9778 18.5495L58.0533 18.646L59.9028 21.0133L60.0268 21.1722L60.0722 20.9758L60.8949 17.4109L60.9382 17.2222L60.7619 17.3013L58.0897 18.4992ZM62.2934 29.2501L62.1986 29.1319L62.132 29.2683L60.2376 33.1481L60.1551 33.1862L60.1954 33.2346L60.1039 33.4223L60.2824 33.3394L62.0133 35.4203L59.6674 39.2583L59.5745 39.2978L59.6147 39.3443L59.4944 39.5412L59.7065 39.4505L61.4894 41.5147L61.5762 41.615L61.6445 41.5014L63.9768 37.6268L64.0671 37.5883L64.0282 37.5416L64.1464 37.3451L63.9385 37.4339L62.2447 35.3975L64.0618 31.5858L64.142 31.5487L64.1028 31.5L64.1917 31.3137L64.0179 31.3944L62.2934 29.2501ZM35.9289 56.2131L35.8839 56.1722L35.8279 56.1952L31.7986 57.8518L31.8312 58.037L37.9706 58.2904L38.2362 58.3013L38.0391 58.1231L35.9289 56.2131Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.8452 40.7784L29.8017 40.755L29.7575 40.7763L27.2503 41.9965L27.0253 42.1058L27.2657 42.1752L37.4882 45.1269L37.5607 44.9497L29.8452 40.7784Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.0508 55.2541L42.0724 55.9385L42.0547 55.9217L42.0131 55.9438L35.8646 56.1881L29.6991 55.9417L27.4977 54.0136L27.4577 53.9786L27.4066 53.994L23.523 55.168L23.5387 55.3554L29.62 56.1282L31.7721 58.013L31.8168 58.0523L31.8718 58.0297L35.8453 56.3961L37.9102 58.2656L37.9604 58.311L38.0004 58.2898L44.04 58.0508L44.0656 58.0749L44.1067 58.0482L44.3095 58.0403L44.2313 57.9667L48.1141 55.43L48.0508 55.2541ZM60.0546 20.8948L59.9492 20.7601L59.8887 20.9199L58.4698 24.6721L58.3932 24.7069L58.4363 24.7605L58.3731 24.9275L58.5176 24.8615L60.3294 27.1144L60.4363 27.2474L60.4947 27.0871L61.8609 23.3358L61.9351 23.3019L61.8928 23.2479L61.9531 23.0823L61.8133 23.1461L60.0546 20.8948Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.4014 39.2804L32.3482 39.2223L32.2807 39.2629L29.7499 40.7804L29.6052 40.8672L29.7536 40.9475L37.4691 45.119L37.5861 44.9696L32.4014 39.2804Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.2819 53.222L20.9628 53.1822L21.2075 53.3908L23.4885 55.3333L23.5284 55.3672L23.5784 55.3522L27.462 54.178L27.4459 53.9907L21.2819 53.222ZM56.7271 43.1943L56.5797 43.3912L56.8234 43.3015L58.6577 45.34L58.7366 45.4276L58.8063 45.3329L61.6223 41.5321L61.7256 41.494L61.6852 41.447L61.8312 41.2501L61.5914 41.3383L59.8061 39.2717L59.728 39.1812L59.6564 39.2771L56.7912 43.1081L56.685 43.1473L56.7271 43.1943ZM60.479 26.9939L60.3853 26.8773L60.3181 27.0112L58.3716 30.9025L58.2864 30.9399L58.3278 30.9898L58.2366 31.1723L58.4144 31.0938L60.235 33.2824L60.3303 33.3967L60.3954 33.2632L62.2896 29.3842L62.3718 29.3476L62.3318 29.2977L62.4205 29.1162L62.2473 29.1925L60.479 26.9939Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.9263 37.5322L34.8835 37.4128L34.7799 37.4864L32.2745 39.2671L32.186 39.3298L32.2594 39.4101L37.4438 45.0991L37.6056 45.0019L34.9263 37.5322ZM56.5495 22.3452L56.7305 22.41L58.2189 18.6222L58.2213 18.6162L58.2228 18.61L59.0982 15.0583L58.9133 15.0057L56.5495 22.3452Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.0186 53.2961L45.8553 53.3261L45.9028 53.3725L41.9857 55.9587L42.0496 56.1345L48.0269 55.4503L48.0547 55.4775L48.0997 55.4419L48.2732 55.4223L48.2068 55.3571L51.954 52.3904L51.877 52.2204L46.0868 53.2837L46.067 53.2642L46.0186 53.2961ZM57.7407 37.1751L57.6213 37.3676L57.8324 37.281L59.6607 39.3975L59.7468 39.4971L59.8154 39.3849L62.1972 35.4886L62.2891 35.451L62.2494 35.403L62.3666 35.2111L62.1603 35.2958L60.3832 33.1593L60.2977 33.0568L60.2273 33.1702L57.7944 37.0886L57.6997 37.1276L57.7407 37.1751ZM33.7673 54.2563L33.722 54.2148L33.6655 54.2388L29.6239 55.948L29.6575 56.1327L35.8608 56.3804L36.1259 56.3909L35.9293 56.2129L33.7673 54.2563Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.3622 35.558L37.3569 35.3611L37.2048 35.4865L34.7745 37.4906L34.7226 37.5335L34.7452 37.5972L37.4245 45.0669L37.6112 45.0318L37.3622 35.558Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M40.0169 54.0078L39.9663 53.9605L39.9054 53.9934L35.8185 56.1996L35.868 56.3802L42.0423 56.1351L42.2715 56.1259L42.1042 55.9689L40.0169 54.0078ZM53.5262 46.7857L53.3794 46.831L53.4845 46.9432L55.2973 48.874L49.9026 50.2134L49.7392 50.2538L49.8569 50.3741L51.8253 52.3823L51.8915 52.4498L51.96 52.3848L55.4757 49.064L55.4815 49.0703L55.5488 48.9949L55.8119 48.7463L55.7581 48.7597L58.8008 45.3396L59.0302 45.0818L58.7006 45.1839L53.5262 46.7857ZM27.4377 53.99L27.1662 53.9795L27.3706 54.1584L29.5975 56.1088L29.6429 56.1485L29.6983 56.1251L33.74 54.4161L33.7064 54.2314L27.4377 53.99ZM58.2046 18.5278L58.1009 18.3951L58.0394 18.5517L56.551 22.3395L56.4671 22.5529L56.6782 22.4632L60.0158 21.0425L60.1311 20.9937L60.0541 20.8948L58.2046 18.5278Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.1997 35.4907L37.1686 35.52L37.1695 35.5631L37.4187 45.0369L37.4264 45.3231L37.5931 45.0904L59.083 15.0911L58.9388 14.9651L37.1997 35.4907Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.7632 39.2221L57.9788 37.1562L60.374 33.2988L60.4679 33.2616L60.4273 33.2127L60.5438 33.0251L60.3386 33.106L58.5562 30.9634L60.4742 27.1293L60.5593 27.0936L60.5179 27.042L60.6062 26.8656L60.4338 26.9376L58.6549 24.7256L60.0683 20.9881L60.1482 20.7773L59.9408 20.8655L56.6032 22.2863L56.4863 22.3359L56.5659 22.4349L58.4315 24.7549L56.4591 28.6012L56.3716 28.6379L56.4143 28.689L56.3231 28.8668L56.5002 28.7925L58.3236 30.9843L55.8749 34.8649L55.7781 34.9031L55.8201 34.9518L55.7014 35.14L55.9114 35.0573L57.7361 37.1699L54.8675 40.9689L54.7595 41.0075L54.8025 41.0552L54.6566 41.2485L54.8986 41.1622L56.6785 43.1402L51.5544 44.6842L51.4059 44.729L51.5122 44.8421L53.3719 46.823L47.886 48.1553L47.7217 48.1951L47.84 48.316L49.7301 50.2445L44.0311 51.273L44.0106 51.2531L43.9623 51.2852L43.7986 51.3148L43.8471 51.3621L39.9616 53.9558L37.8782 51.9983L37.827 51.9505L37.766 51.9842L33.7166 54.2101L31.552 52.2508L31.506 52.209L31.4693 52.2251L25.1901 51.9925L25.175 51.9793L25.1392 51.9908L24.8853 51.9813L24.9605 52.0473L21.241 53.2259L21.2581 53.4131L27.3931 54.1778L27.4168 54.1986L27.4485 54.1847L27.7334 54.2203L27.7019 54.1926L33.6642 54.4222L35.8001 56.3555L35.8504 56.4012L35.9102 56.3688L39.936 54.1957L41.9728 56.1092L42.0283 56.1613L42.0917 56.1194L46.0432 53.5103L46.0485 53.5154L46.0932 53.4796L46.267 53.4602L46.255 53.4484L51.9116 52.4097L52.0896 52.3769L51.9629 52.2478L50.1124 50.3595L55.5032 49.0213L55.6624 48.9818L55.5499 48.8621L53.7305 46.9237L58.7576 45.3676L58.9012 45.3231L58.8006 45.2113L56.9804 43.1885L57.1507 42.9979L57.1034 43.0122L59.7931 39.4155L59.8979 39.378L59.8566 39.3303L60.0014 39.1369L59.7632 39.2221Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.2356 53.2276L21.2993 53.4091L25.1815 52.1791L25.1868 52.1774L25.1918 52.1752L29.2563 50.354L29.1823 50.1765L21.2356 53.2276ZM51.8653 30.287L52.0354 30.3761L56.7239 22.4234L56.7294 22.4144L56.7328 22.4043L59.0965 15.0644L58.9176 14.9948L51.8653 30.287Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M57.8899 37.0534L56.0588 34.934L58.5061 31.055L58.6024 31.0189L58.5607 30.9687L58.6765 30.7852L58.472 30.8623L56.6433 28.6635L58.6147 24.8193L58.7022 24.7846L58.6595 24.7315L58.7477 24.5596L58.576 24.6276L56.7157 22.3146L56.6241 22.2002L56.5559 22.33L54.4987 26.2418L54.4082 26.2778L54.4521 26.3304L54.3611 26.5031L54.5379 26.4331L56.4097 28.6835L53.9072 32.5854L53.8082 32.6228L53.8513 32.6724L53.7333 32.8566L53.942 32.7777L55.8156 34.9466L52.8956 38.776L52.7858 38.8141L52.8298 38.8625L52.6852 39.0522L52.9255 38.9692L54.7533 41.0006L49.5329 42.5296L49.3825 42.5738L49.4899 42.6879L51.3983 44.7209L45.8186 46.0451L45.6529 46.0845L45.7722 46.2062L47.7125 48.1856L41.9235 49.2119L41.903 49.1916L41.855 49.224L41.6906 49.2531L41.7402 49.3014L37.822 51.9457L35.6851 49.9383L35.6338 49.89L35.5723 49.9243L31.5007 52.2045L29.2812 50.195L29.2346 50.1529L29.1773 50.1787L25.1128 51.9996L25.1488 52.1836L31.449 52.4167L33.6381 54.3986L33.689 54.4447L33.749 54.4118L37.7974 52.1864L39.8854 54.1481L39.9412 54.2005L40.0048 54.1579L43.9875 51.4996L43.9926 51.5047L44.0375 51.4689L44.2113 51.4495L44.1996 51.4379L49.9428 50.4015L50.1215 50.3692L49.9945 50.2395L48.0959 48.3021L53.5775 46.9708L53.738 46.932L53.6251 46.8115L51.7587 44.8236L56.879 43.2807L57.0241 43.2368L56.9229 43.1243L55.0562 41.0498L55.2242 40.8625L55.1789 40.8758L57.9188 37.247L58.0254 37.2103L57.9833 37.1616L58.1265 36.9719L57.8899 37.0534Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.9264 14.9792L37.4502 44.9594L21.2261 53.2316L21.3043 53.4073L29.2509 50.3562L29.2599 50.3527L29.2678 50.3475L37.5659 45.1156L37.535 45.0609L37.5833 45.1018L52.0209 30.3946L52.0327 30.3826L52.0395 30.3674L59.092 15.0752L58.9264 14.9792Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M52.8253 38.8573L49.4912 42.5547L45.7777 46.0661L41.8536 49.2225L35.6122 49.9121L29.213 50.1702L28.9777 50.1796L29.1522 50.3377L31.4232 52.3931L31.4744 52.4395L31.5346 52.4059L35.6052 50.1265L37.7464 52.1383L37.8026 52.1908L37.8661 52.148L41.9289 49.4062L47.9257 48.3431L48.1053 48.3112L47.9776 48.1811L46.0288 46.193L51.6046 44.8695L51.7661 44.8313L51.6525 44.7101L49.7374 42.6703L54.9542 41.1419L55.1009 41.0992L54.9987 40.9854L53.0796 38.8524L55.9982 35.0246L56.1063 34.9885L56.0635 34.9388L56.2054 34.753L55.9704 34.8311L54.0441 32.601L53.967 32.512L53.895 32.6053L50.874 36.5276L50.7624 36.5646L50.8071 36.6142L50.6639 36.8003L50.9028 36.7207L52.8253 38.8573Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M51.8695 30.2783L52.033 30.3798L54.6476 26.3706L54.6501 26.3668L54.6522 26.3628L56.7261 22.4193L56.558 22.3257L51.8695 30.2783Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.5778 43.9668L39.6927 47.1092L33.4044 47.7953L33.385 47.7769L33.3406 47.8022L33.1529 47.8228L33.2101 47.8766L29.1692 50.1825L29.2205 50.3623L35.5831 50.1057L35.6098 50.1311L35.651 50.1032L35.8525 50.095L35.8373 50.0809L41.8573 49.4158L41.8858 49.4434L41.9305 49.4076L42.1043 49.3885L42.0366 49.3221L45.8335 46.2682L45.8385 46.2733L45.8859 46.2284L46.0378 46.2019L45.9783 46.1412L49.5559 42.7582L49.5614 42.764L49.6113 42.7088L49.7444 42.6779L49.6957 42.6261L49.8838 42.4483L49.8362 42.4593L53.0076 38.9424L53.1294 38.9077L53.0841 38.8576L53.2501 38.6733L52.9859 38.7484L51.0036 36.5452L50.9324 36.466L50.8608 36.5449L47.4335 40.328L47.3082 40.3636L47.3505 40.4089L43.6734 43.8873L43.5319 43.9202L43.5778 43.9668ZM51.8324 30.3353L51.7152 30.5151L51.9231 30.44L53.8984 32.7267L53.9825 32.8244L54.052 32.716L56.5921 28.7556L56.6905 28.72L56.6478 28.6689L56.7628 28.4894L56.5597 28.5629L54.6413 26.2567L54.5579 26.1564L54.4868 26.2656L51.8896 30.2474L51.7883 30.2841L51.8324 30.3353Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.1651 50.1849L29.2643 50.3498L33.4182 47.9794L33.4218 47.9774L33.4253 47.9748L37.5693 45.1134L37.4635 44.953L29.1651 50.1849Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7281 34.3037L45.3069 38.0619L45.18 38.0969L45.2281 38.1483L45.0642 38.3282L45.3287 38.2553L47.3012 40.3564L41.5766 41.6503L41.5529 41.6261L41.5024 41.667L41.3561 41.7001L41.403 41.7478L37.4742 44.9437L37.3132 44.9711L37.3645 45.0214L33.3164 47.8167L33.3815 47.9914L39.6966 47.3023L39.7251 47.3305L39.7698 47.2944L39.9439 47.2755L39.8759 47.2086L43.7132 44.105L43.7182 44.1098L43.7656 44.0652L43.9181 44.0389L43.8634 43.9833L44.0674 43.8184L44.0174 43.8269L47.4823 40.5494L47.4877 40.5549L47.5552 40.4803L47.8072 40.242L47.762 40.2522L50.9321 36.753L50.938 36.7596L50.9889 36.6936L51.108 36.661L51.0619 36.6099L51.2259 36.4288L51.184 36.4405L54.0291 32.7465L54.139 32.7113L54.0953 32.6606L54.2357 32.4781L54.0025 32.5531L52.0254 30.2645L51.9489 30.1757L51.8768 30.2682L48.8003 34.2216L48.6868 34.2577L48.7281 34.3037Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.4464 44.9669L37.5785 45.1066L45.4224 38.2193L45.4281 38.2147L45.4314 38.2105L48.929 34.3687L48.9314 34.366L48.9337 34.3631L52.0285 30.3862L51.9776 30.3449L51.884 30.2597L37.4464 44.9669Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.4289 38.0814L45.3628 38.0111L45.2928 38.0775L41.4791 41.6861L41.2246 41.927L41.5665 41.8498L47.5073 40.507L47.6712 40.4699L47.5562 40.3472L45.4289 38.0814Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.4515 44.9621L37.5758 45.109L41.6062 41.8306L41.6089 41.8281L41.6116 41.8257L45.4251 38.2172L45.2957 38.075L37.4515 44.9621Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default LeftCenterGroup;
