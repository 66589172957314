import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const WatermarkTop404: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
        <svg {...props} width="669" height="966" viewBox="0 0 669 966" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1287.61 -9.42882C1160.46 -281.367 832.443 -184.227 688.736 -387.253C610.068 -498.391 483.518 -745.995 120.726 -432.423C-112.762 -229.13 14.8463 37.6067 327.231 156.803C409.416 200.271 446.106 258.9 469.089 337.084C492.071 415.267 440.642 585.643 534.716 742.083C817.815 1212.86 1763.77 1008.97 1287.61 -9.42882Z" fill="#1F0231"/>
</svg>
  );
};
export default WatermarkTop404;
