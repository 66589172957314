import { type ChangeEvent, useState } from 'react';
export type UseInput<T> = [
  T,
  (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  () => void
]
const useInput = <T = string>(initial: T): UseInput<T> => {
  const [value, setValue] = useState<T>(initial);

  return [
    value,
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => { setValue(e.target.value as any); },
    () => { setValue(initial); }
  ];
};

export default useInput;
