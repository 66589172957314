import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const Loading: FC<IconProps> = ({ className, ...props }) => {
  return (
    <div {...props} className={`mx-auto flex w-fit gap-8 ${className}`}>
      <span className="animate-bouncing h-8 w-8 rounded-full bg-darkPurple"></span>
      <span className="animate-bouncing delays-300 h-8 w-8 rounded-full bg-darkPurple"></span>
      <span className="animate-bouncing delays-500 h-8 w-8 rounded-full bg-darkPurple"></span>
    </div>
  );
};

export default Loading;
