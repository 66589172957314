import Link from "next/link";
import React, { type ReactNode, type FC } from 'react';

export interface ITheEditionsCardProps {
  name: string
  dataAos: string
  link: string
  icon: ReactNode
}

const TheEditionsCard: FC<ITheEditionsCardProps> = ({
  name,
  dataAos,
  link,
  icon
}) => {
  return (
    <span
      key={name}
      data-aos={dataAos}
      className="flex cursor-pointer flex-col items-center justify-between gap-6 lg:p-6 p-4 shadow-[0px_2px_15px_rgba(230,230,230,0.5)] transition-all duration-500 ease-in hover:shadow-2xl lg:basis-1/5 lg:w-auto mx-auto lg:mx-0 min-w-[240px] w-96"
    >
      <span className="bg-[#EEE8F2] p-2 w-fit rounded-lg">{icon}</span>
      <h2 className="font-semibold text-darkPurple rounded-sm text-xs w-fit break-keep">
        {name}
      </h2>
      <Link href={`/edition/${link}`} className="block w-full">
        <span className="block w-full cursor-pointer rounded bg-darkPurple p-3 text-center text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple">
          Read More
        </span>
      </Link>
    </span>
  );
};
export default TheEditionsCard;
