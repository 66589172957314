import React, { type FC } from 'react';

import List from './List';

export interface IVotingEndedProps {
}

const VotingEnded: FC<IVotingEndedProps> = (props) => {
  const faqArr = [
    {
      heading: 'Data',
      details:
        'Here, our focus narrows on professionals adept in data analytics, encompassing roles like Data Analysts, Data Scientists, Data Engineers, Machine Learning Engineers, Artificial Intelligence Engineers, and related positions.'
    },
    {
      heading: 'Design',
      details:
        'This category highlights individuals specializing in creative design, including Product Designers, Graphic Designers, Animators, and various other design-related roles.'
    },
    {
      heading: 'Development',
      details:
        "This segment highlights professionals specializing in diverse development stacks, including Frontend, Backend, Mobile, Platform, and other development-related roles."
      },
      {
        heading: 'Growth',
        details:
          'This category zeroes in on professionals excelling in customer success, creative roles, growth leadership, account management, solution consultancy, and related fields.'
      },
    {
      heading: 'Operation',
      details:
        'This category acknowledges professionals engaged in Human Resources, Operations Management, Strategy formulation, Partnerships, and other related areas.'
    },
    {
      heading: 'Product',
      details:
        "Within this segment, we spotlight professionals encompassing roles such as Product Managers, Product Owners, Business Analysts, Technical Product Managers, and other associated experts."
    },
    {
      heading: 'Security',
      details:
        'Within this domain, we recognize professionals skilled in areas such as Cloud Engineering, Cybersecurity, DevOps, Site Reliability Engineering, Network Engineering, Solution Architecture, and associated disciplines.'
    }
  ];
  return (
    <section className="mb-12 mt-6 flex flex-col lg:ml-12 gap-4 mx-auto w-11/12">
        {faqArr.map((data: { heading: string, details: string }) => (
          <List {...data} key={data.heading} />
        ))}
    </section>
  );
};
export default VotingEnded;
