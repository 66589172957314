import React, { type FC } from 'react';

import { motion } from 'framer-motion';
import Link from 'next/link';

import { type PropTypes } from '../../typescript';
import { type Search } from '../../utils/services/ApiConnection';
import { Arrow, ArrowRight, MenuIcon } from '../icons';
export const parser = (arg: string): string => {
  const string = arg?.replace(' ', '-');
  return arg?.includes(' ') ? parser(string) : string?.toLowerCase();
};
const CategoriesCard: FC<PropTypes<Search, { i?: number }>> = ({
  name,
  i,
  id
}) => {
  const { span: Span } = motion;
  return (
    <Link href={{ pathname: `/attest/${parser(name)}`, query: { id } }}>
      <Span
        initial={{
          y: Number(i) % 2 !== 0 ? -50 : 50,
          opacity: 0,
        }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0 }}
        className="group flex cursor-pointer h-fit select-none flex-col items-center gap-6 p-6 shadow-[0px_2px_15px_rgba(230,230,230,0.35)] transition-all duration-500 ease-in hover:shadow-[0px_2px_15px_rgba(230,230,230,0.65)]"
      >
        <MenuIcon />
        <span className="text-center capitalize">{name.toLowerCase()}</span>
        <ArrowRight className="float-right ml-auto mr-8 h-4 w-4 cursor-pointer transition-all duration-500 ease-in group-hover:mr-4" />
      </Span>
    </Link>
  );
};

export default CategoriesCard;
