import React, { type FC } from 'react';

import type { IconProps } from '../../typescript';

const MenuIcon: FC<IconProps> = (props) => {
  return (
    <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#EEE8F2" />
      <path d="M18 11H12C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4804 18.8946 11.7348 19 12 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V12C19 11.7348 18.8946 11.4804 18.7071 11.2929C18.5196 11.1054 18.2652 11 18 11ZM17 17H13V13H17V17ZM28 21H22C21.7348 21 21.4804 21.1054 21.2929 21.2929C21.1054 21.4804 21 21.7348 21 22V28C21 28.2652 21.1054 28.5196 21.2929 28.7071C21.4804 28.8946 21.7348 29 22 29H28C28.2652 29 28.5196 28.8946 28.7071 28.7071C28.8946 28.5196 29 28.2652 29 28V22C29 21.7348 28.8946 21.4804 28.7071 21.2929C28.5196 21.1054 28.2652 21 28 21ZM27 27H23V23H27V27ZM25 11C22.794 11 21 12.794 21 15C21 17.206 22.794 19 25 19C27.206 19 29 17.206 29 15C29 12.794 27.206 11 25 11ZM25 17C23.897 17 23 16.103 23 15C23 13.897 23.897 13 25 13C26.103 13 27 13.897 27 15C27 16.103 26.103 17 25 17ZM15 21C12.794 21 11 22.794 11 25C11 27.206 12.794 29 15 29C17.206 29 19 27.206 19 25C19 22.794 17.206 21 15 21ZM15 27C13.897 27 13 26.103 13 25C13 23.897 13.897 23 15 23C16.103 23 17 23.897 17 25C17 26.103 16.103 27 15 27Z" fill="#3E0563" />
    </svg>
  );
};

export default MenuIcon;
