import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const BottomRainbow: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="180"
      height="170"
      viewBox="0 0 180 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 170.408H45.7112C45.7112 153.713 49.1847 137.181 55.9335 121.757C62.6822 106.333 72.574 92.3189 85.044 80.5142C97.5139 68.7094 112.318 59.3456 128.611 52.9575C144.903 46.5693 162.365 43.2819 180 43.283V0C80.5899 0 0 76.2951 0 170.408Z"
        fill="#3E0563"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0031 170.407H116.288C116.288 154.42 122.996 139.088 134.936 127.784C146.877 116.48 163.072 110.129 179.959 110.129V75.7781C153.452 75.7884 128.035 85.7616 109.292 103.506C90.5486 121.25 80.014 145.313 80.0031 170.407Z"
        fill="#FA9706"
      />
    </svg>
  );
};

export default BottomRainbow;
