import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const BottomRightGroup: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="182"
      height="206"
      viewBox="0 0 182 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.7418 39.7959L68.7308 39.8029L68.7208 39.8109L58.8788 47.2069L58.8728 47.2119L58.8658 47.2179L49.4498 55.0519L49.4428 55.0569L49.5878 55.2359L49.4298 55.0689L40.4898 63.3289L40.4828 63.3349L40.4758 63.3419L32.0918 72.0059L32.5828 72.4949L49.8878 55.5909L89.8698 27.0299L111.755 15.8959L111.448 15.2739L100.368 20.5449L100.36 20.5489L100.352 20.5529L89.5238 26.4279L89.6318 26.6319L89.5088 26.4359L78.9678 32.8599L78.9608 32.8649L78.9538 32.8689L68.7418 39.7959Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.9228 77.605L96.1078 77.462L96.5778 78.144L100.716 84.152L90.3008 82.6769L89.5088 82.564L89.9678 83.2189L94.6198 89.847L94.8288 90.144L95.1158 89.923L101.654 84.8879L108.498 80.254L115.618 76.0469L116.383 75.595L115.514 75.41L103.856 72.921L103.014 72.741L103.496 73.455L107.567 79.4769L96.9228 77.605Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M134.539 7.45404L134.279 6.81104L111.477 15.262L111.458 15.269L111.44 15.278L89.5322 26.423L89.8292 27.05L134.539 7.45404Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.071 73.066L103.877 72.778L103.589 72.973L96.9537 77.4659L96.9417 77.449L96.6597 77.665L95.9587 78.14L96.0258 78.152L90.0407 82.7449L89.3857 83.2469L90.2037 83.363L101.402 84.949L102.185 85.0599L101.736 84.4089L97.6187 78.4319L108.252 80.3019L109.058 80.444L108.6 79.7659L104.071 73.066ZM111.051 68.662L110.179 68.4399L110.676 69.1899L115.152 75.9399L115.323 76.1979L115.599 76.058L122.948 72.322L123.77 71.9039L122.877 71.6769L111.051 68.662Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.92 26.9919L89.488 26.4509L49.463 55.0419L49.433 55.064L49.408 55.092L17.334 90.5209L17.822 91.0129L89.92 26.9919Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.539 88.3909L83.178 88.7039L83.242 88.7109L78.0471 93.8379L77.452 93.7889L77.721 94.1589L77.395 94.4809L77.991 94.5289L82.697 100.993L82.9351 101.321L83.222 101.035L88.859 95.4099L89.444 95.4579L89.192 95.1059L95.018 90.0089L95.629 90.0759L95.367 89.7029L95.726 89.3889L95.098 89.3199L90.7271 83.0919L97.066 78.2279L103.856 73.6299L115.369 76.0879L116.2 76.2649L115.73 75.5569L111.751 69.5559L122.574 72.3149L122.659 72.4459L122.819 72.3779L123.566 72.5679L123.307 72.1699L130.455 69.1199L131.335 68.7449L130.418 68.4689L118.582 64.9069L118.481 64.7509L118.327 64.8299L117.553 64.5969L117.86 65.0689L111.083 68.5479L111.072 68.5309L110.905 68.6299L99.2501 66.1039L98.405 65.9209L98.889 66.6369L103.036 72.7729L92.2581 70.8299L91.438 70.6819L91.911 71.3679L96.126 77.4869L85.57 75.9329L84.772 75.8159L85.235 76.4759L89.789 82.9629L84.065 87.9339L84.05 87.9139L83.8781 88.0829L83.2711 88.0159L83.539 88.3909Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M134.52 7.46088L134.29 6.80688L122.79 10.6569L122.78 10.6609L122.769 10.6649L111.467 15.2659L111.718 15.9129L134.52 7.46088Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M99.4639 66.249L99.2698 65.9609L98.9819 66.1559L92.0018 70.884L91.2969 71.3619L92.1348 71.5129L103.723 73.601L104.532 73.7469L104.071 73.066L99.4639 66.249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M49.9224 55.5569L49.4233 55.0759L32.0994 71.999L32.0833 72.014L32.0703 72.0309L17.3203 90.538L17.8494 90.9869L49.9224 55.5569Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M92.937 71.6349L92.6801 71.2619L99.248 66.8139L110.754 69.3069L110.848 69.4479L111.013 69.3629L111.726 69.5179L111.469 69.1289L118.395 65.5749L130.096 69.0959L130.173 69.2179L130.326 69.1649L131.114 69.4029L130.848 68.9859L137.98 66.5369L138.802 66.8259L138.507 66.3559L139.037 66.1739L138.211 65.8829L133.915 59.0269L133.768 58.7919L133.506 58.8839L126.071 61.5029L126.06 61.4859L125.914 61.5489L125.093 61.2599L125.131 61.3189L114.77 58.1879L114.816 58.1639L114.07 57.9709L113.972 57.8199L113.818 57.8999L113.044 57.6659L113.074 57.7129L101.863 54.8049L100.987 54.5769L101.487 55.3319L105.623 61.5689L105.648 61.6069L94.6981 59.1979L94.5811 59.0249L94.4171 59.1359L93.715 58.9809L94.007 59.4139L87.538 63.7969L87.527 63.7809L87.36 63.9079L86.687 63.7839L86.9641 64.1859L86.553 64.4649L86.614 64.4759L80.61 69.0499L79.953 68.9489L80.228 69.3409L79.843 69.6339L80.505 69.7349L85.044 76.2029L84.656 76.4999L84.718 76.5089L79.26 81.2149L79.246 81.1949L79.075 81.3619L78.4611 81.2899L78.733 81.6689L78.3751 81.9779L78.436 81.9849L73.166 87.1279L72.564 87.0739L72.837 87.4489L72.512 87.7659L73.106 87.8189L77.733 94.1749L72.688 99.8879L72.119 99.8569L72.3911 100.224L72.1021 100.551L72.655 100.581L77.44 107.04L77.697 107.386L77.981 107.061L83.1281 101.145L83.687 101.175L83.42 100.808L83.705 100.481L83.161 100.452L78.634 94.2339L84.134 88.8059L84.726 88.8589L84.47 88.5009L90.364 83.3819L90.981 83.4539L90.716 83.0769L91.0731 82.7669L90.447 82.6929L86.267 76.7369L96.813 78.2899L97.601 78.4059L97.149 77.7499L92.937 71.6349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0253 26.8198L90.3134 25.7148L89.4594 26.4728L17.3613 90.4948L17.7863 91.0408L85.0554 45.5488L85.1633 45.4758L85.1964 45.3499L90.0253 26.8198Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M74.3519 74.5409L73.5669 74.4439L74.0269 75.0869L78.9169 81.9269L79.1379 82.2359L79.4259 81.9879L85.7459 76.5389L86.3379 76.0289L85.5619 75.9319L74.3519 74.5409ZM102.065 54.9489L101.882 54.6729L101.598 54.8429L94.3099 59.2089L93.5609 59.6579L94.4139 59.8449L106.336 62.4679L107.174 62.6529L106.7 61.9379L102.065 54.9489ZM121.75 54.7349L121.59 54.4839L121.318 54.6039L113.713 57.9549L112.855 58.3339L113.752 58.6049L125.827 62.2539L126.723 62.5249L126.219 61.7349L121.75 54.7349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0309 26.67L89.3539 26.645L84.525 45.175L84.31 46.001L85.04 45.559L92.438 41.084L92.6429 40.96L92.6 40.725L90.0309 26.67ZM32.612 72.464L32.077 72.022L24.332 81.08L24.325 81.089L24.317 81.099L17.313 90.547L17.863 90.97L32.612 72.464Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M80.9871 69.215L80.768 68.903L80.478 69.151L74.083 74.622L73.486 75.133L74.2661 75.23L85.4771 76.621L86.252 76.717L85.803 76.077L80.9871 69.215ZM134.595 59.236L134.665 59.211L133.745 58.887L132.754 58.481L132.8 58.554L121.573 54.594L120.624 54.259L121.165 55.108L125.635 62.108L125.783 62.34L126.042 62.249L133.475 59.631L137.687 66.353L137.822 66.568L138.067 66.505L145.824 64.517L146.838 64.257L145.87 63.86L134.595 59.236ZM73.282 100.262L78.438 94.426L78.906 93.895L78.2 93.85L67.89 93.19L67.144 93.142L67.5891 93.743L72.403 100.241L67.777 106.353L67.3701 106.89L68.044 106.909L77.709 107.18L78.4221 107.2L77.998 106.627L73.282 100.262Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.9333 26.4858L89.1514 25.7148L89.3494 26.7949L91.9174 40.8488L92.0034 41.3168L92.4224 41.0928L100.005 37.0428L100.421 36.8209L100.085 36.4898L89.9333 26.4858Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M68.0629 106.215L67.3428 106.195L67.7769 106.771L72.7468 113.368L73.0269 113.738L73.3029 113.366L77.9968 107.04L78.3939 106.505L77.7288 106.486L68.0629 106.215ZM109.23 50.848L108.455 50.613L108.766 51.091L101.728 54.774L89.7928 52.109L88.9409 51.919L89.4299 52.641L93.7349 59.011L82.6788 56.92L81.8508 56.764L82.3289 57.458L86.7028 63.808L75.8558 62.089L75.0479 61.96L75.5178 62.63L80.4199 69.614L80.6268 69.909L80.9138 69.691L87.6498 64.559L94.6749 59.799L101.83 55.513L113.635 58.575L113.723 58.709L113.882 58.639L114.631 58.833L114.366 58.426L121.598 55.239L122.454 54.861L121.559 54.589L109.487 50.926L109.383 50.767L109.23 50.848ZM78.4578 93.992L73.7509 87.526L79.3259 82.086L79.9258 82.145L79.6539 81.766L79.9749 81.453L79.3898 81.395L74.5908 74.684L74.3578 74.357L74.0679 74.636L68.1979 80.299L67.5878 80.239L67.8649 80.62L67.5419 80.932L68.1339 80.99L72.8478 87.465L67.6099 93.305L67.1318 93.837L67.8458 93.882L78.1548 94.543L78.8928 94.59L78.4578 93.992Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.8073 26.406L89.4463 26.98L99.5983 36.984L99.7693 37.153L99.9873 37.052L107.712 33.475L108.479 33.12L107.683 32.834L89.8073 26.406Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.5297 52.155L88.7957 52.597L88.8577 52.611L82.7047 56.781L82.6937 56.765L82.4117 56.979L81.7238 57.446L81.7827 57.457L75.8787 61.937L75.8667 61.921L75.5847 62.161L74.9437 62.647L75.0017 62.656L69.2247 67.56L68.5847 67.477L68.8637 67.866L68.5107 68.167L69.1367 68.249L74.0267 75.087L74.2467 75.395L74.5347 75.149L80.8107 69.779L81.4397 69.861L81.1677 69.473L81.5208 69.172L80.8997 69.091L76.5557 62.902L87.3927 64.62L88.1917 64.746L87.7327 64.081L83.3787 57.759L94.4237 59.848L95.2417 60.002L94.7758 59.313L90.4927 52.976L101.564 55.449L101.66 55.593L101.825 55.507L102.541 55.667L102.278 55.27L109.3 51.594L121.234 55.216L121.315 55.342L121.459 55.29L133.392 59.498L133.463 59.613L133.598 59.577L145.606 64.502L146.591 64.906L146.033 64L141.942 57.35L142.487 57.204L141.639 56.856L141.563 56.733L141.426 56.769L130.149 52.15L130.216 52.126L129.414 51.843L129.33 51.708L129.192 51.758L128.318 51.4L128.363 51.472L117.138 47.509L117.041 47.358L116.894 47.423L116.075 47.134L116.401 47.644L109.234 50.855L97.2747 47.725L97.1647 47.559L97.0057 47.655L96.2677 47.462L96.5697 47.917L89.8197 51.98L89.8098 51.965L89.5297 52.155Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.7353 26.3889L89.5723 27.0589L107.448 33.4859L107.571 33.5299L107.692 33.4829L115.513 30.4329L115.432 29.7659L89.7353 26.3889Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M68.3152 93.557L73.4382 87.846L74.0152 87.889L73.7392 87.51L74.0232 87.194L73.4792 87.154L68.7812 80.7L74.4332 75.248L75.0402 75.312L74.7652 74.928L75.0852 74.619L74.5002 74.557L69.8002 67.982L76.0192 62.702L82.7062 57.628L83.3632 57.735L83.0992 57.352L89.7922 52.815L90.4742 52.947L90.2092 52.556L97.1132 48.4L109.047 51.523L109.136 51.659L109.295 51.588L110.044 51.784L109.776 51.372L116.925 48.17L129.061 52.454L141.166 57.413L145.442 64.363L145.564 64.561L145.794 64.524L153.36 63.289L154.436 63.113L153.456 62.635L141.645 56.867L137.285 49.78L137.148 49.557L136.897 49.627L129.341 51.726L124.961 44.737L124.812 44.498L124.547 44.596L117.051 47.374L112.572 40.357L112.41 40.103L112.136 40.228L104.723 43.604L104.713 43.589L104.559 43.67L103.785 43.434L103.814 43.479L92.4742 40.485L92.3632 40.318L92.2052 40.414L91.4652 40.219L91.7692 40.677L84.9632 44.794L84.9542 44.78L84.7912 44.89L84.0822 44.73L84.3692 45.154L83.9452 45.41L84.0042 45.423L77.7862 49.639L77.7752 49.624L77.6102 49.75L76.9292 49.618L77.2122 50.029L76.8092 50.301L76.8652 50.312L70.7222 54.957L70.0552 54.847L70.3362 55.248L69.9582 55.534L70.6122 55.642L75.0602 61.979L64.3132 60.494L63.5142 60.384L63.9832 61.04L68.8742 67.881L63.1402 73.348L62.5232 73.282L62.8042 73.668L62.4822 73.975L63.0742 74.038L67.8762 80.635L62.6672 86.351L62.0812 86.308L62.3612 86.687L62.0742 87.003L62.6262 87.044L67.4332 93.532L62.8292 99.493L62.2762 99.471L62.5572 99.844L62.3092 100.166L62.8152 100.187L67.6322 106.579L63.7192 112.783L63.2062 112.781L63.4882 113.15L63.2822 113.475L63.7382 113.477L68.7002 119.957L69.0062 120.357L69.2712 119.927L73.2182 113.507L73.7222 113.508L73.4442 113.139L73.6442 112.814L73.1982 112.813L68.4882 106.562L73.0042 100.597L73.5472 100.619L73.2702 100.245L73.5142 99.923L73.0162 99.903L68.3152 93.557Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M77.8979 50.4021L71.022 55.5991L71.014 55.6061L71.0049 55.6111L17.7859 91.0411L17.784 91.0421L17.405 90.4781L77.8979 50.4021ZM77.8979 50.4021L17.405 90.4781L77.8979 50.4021ZM77.8979 50.4021L85.055 45.5491H85.056L84.667 44.9741L84.666 44.9751L17.4059 90.4611L17.4 90.4651L77.8979 50.4021ZM17.405 90.4781L17.3979 90.4661L17.405 90.4781ZM89.551 26.4151L89.671 26.7481L89.645 27.076L115.342 30.4531L115.418 30.4631L115.491 30.4411L123.366 27.9801L123.272 27.3021L91.9399 26.4471L175.916 2.32007L175.777 1.64209L134.357 6.79205L134.307 6.7981L134.261 6.81805L89.551 26.4151Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M71.0958 55.1238L70.8788 54.8128L70.5888 55.0579L64.0418 60.5728L63.4458 61.0748L64.2178 61.1808L75.7538 62.7748L76.5428 62.8839L76.0858 62.2318L71.0958 55.1238ZM149.281 55.6238L149.158 55.4208L148.925 55.4619L141.343 56.7928L140.291 56.9768L141.25 57.4459L153.152 63.2578L154.21 63.7748L153.601 62.7668L149.281 55.6238ZM125.623 44.9368L125.687 44.9128L124.791 44.5969L123.801 44.1918L123.846 44.2628L112.395 40.2169L111.445 39.8809L111.987 40.7299L116.617 47.9828L116.768 48.2188L117.03 48.1218L124.523 45.3438L128.89 52.3138L129.028 52.5328L129.277 52.4638L137.082 50.2958L138.059 50.0239L137.121 49.6398L125.623 44.9368ZM63.2588 86.7318L68.5788 80.8928L69.0528 80.3729L68.3518 80.3138L57.6898 79.4138L56.9248 79.3499L57.3818 79.9658L62.3728 86.7028L57.6808 92.6538L57.1178 92.6248L57.4038 93.0048L57.1548 93.3209L57.6628 93.3478L62.7168 100.055L62.9898 100.418L63.2678 100.059L68.0308 93.8918L68.5848 93.9208L68.3028 93.5408L68.5468 93.2249L68.0488 93.1989L63.2588 86.7318Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.3999 90.465L17.7789 91.046L17.7839 91.043L64.4529 61.1299L64.4719 61.118L64.4889 61.103L71.0359 55.588L70.6199 55.0339L17.4049 90.462L17.3999 90.465Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M99.8049 36.363L99.0298 36.126L99.3448 36.6089L92.0948 40.481L91.3159 40.897L92.1699 41.1219L104.377 44.345L104.467 44.483L104.626 44.41L105.376 44.608L105.106 44.192L112.423 40.8589L113.256 40.48L112.381 40.212L100.064 36.442L99.9589 36.2809L99.8049 36.363ZM63.5438 73.5049L63.2949 73.1639L63.0089 73.4739L57.4069 79.524L56.9238 80.045L57.6318 80.105L68.2938 81.0049L69.0499 81.069L68.6028 80.455L63.5438 73.5049ZM137.142 49.649L136.069 49.127L136.694 50.1429L141.107 57.316L141.231 57.517L141.463 57.476L149.044 56.145L150.098 55.96L149.136 55.4919L137.142 49.649Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M156.492 2.72205L156.36 2.04102L145.646 3.883L145.633 3.88605L145.62 3.88904L134.312 6.79999L134.472 7.47504L156.492 2.72205Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M63.2711 99.638L62.972 99.242L62.703 99.658L58.567 106.045L58.041 106.036L58.329 106.412L58.122 106.731L58.579 106.739L63.635 113.341L63.937 113.737L64.203 113.316L68.243 106.912L68.759 106.922L68.475 106.545L68.675 106.226L68.229 106.218L63.2711 99.638ZM57.652 66.591L57.3311 66.893L57.9221 66.961L62.983 73.914L63.216 74.234L63.5031 73.961L69.454 68.287L70.0681 68.358L69.789 67.968L70.1071 67.664L69.524 67.597L64.548 60.636L64.317 60.313L64.028 60.585L57.992 66.271L57.367 66.199L57.652 66.591Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4053 90.4619L17.7693 91.0519L58.2903 66.9319L58.3233 66.9129L58.3503 66.8869L64.5033 61.0899L64.0782 60.5459L17.4053 90.4619Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M149.161 55.5049L148.688 55.983L153.007 63.126L153.115 63.3049L153.325 63.293L160.418 62.8649L161.553 62.7969L160.573 62.22L149.161 55.5049ZM120.363 37.3979L120.212 37.157L119.946 37.2579L112.156 40.22L111.272 40.556L112.164 40.871L124.544 45.2449L136.858 50.282L137.842 50.6849L137.285 49.78L132.792 42.4769L132.654 42.252L132.4 42.325L124.823 44.515L120.363 37.3979ZM64.1851 112.92L63.8491 112.48L63.6 112.975L60.291 119.559L60.04 120.059L60.6 120.061L68.9741 120.092L69.6801 120.095L69.251 119.535L64.1851 112.92Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6827 26.386L89.6797 27.08L123.253 27.9949L123.297 27.9969L123.34 27.9869L130.979 26.2399L130.895 25.5549L89.6827 26.386Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M107.548 32.7861L106.731 32.4971L107.06 33.0121L99.6957 36.4221L98.8727 36.8041L99.7397 37.0691L112.056 40.8381L112.139 40.9681L112.292 40.9101L113.079 41.1511L112.801 40.7171L119.927 38.0081L124.374 45.1051L124.513 45.3271L124.764 45.2541L132.593 42.9921L133.552 42.7151L132.628 42.3371L121.015 37.5941L121.077 37.5711L120.193 37.2581L119.204 36.8541L119.248 36.9241L107.794 32.8731L107.695 32.7181L107.548 32.7861ZM60.6017 119.368L59.8896 119.365L60.3267 119.927L65.4886 126.571L65.8307 127.011L66.0746 126.509L69.2867 119.897L69.5276 119.401L68.9767 119.399L60.6017 119.368ZM144.884 48.3521L144.759 48.1471L144.523 48.1911L136.926 49.6201L135.896 49.8141L136.838 50.2731L148.833 56.1161L149.89 56.6311L149.282 55.6241L144.884 48.3521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4141 90.4559L17.7511 91.062L52.5781 72.991L52.6311 72.9639L52.6711 72.921L58.3651 66.8719L57.9351 66.3359L17.4141 90.4559Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M58.1108 79.7839L63.4008 74.0709L63.9968 74.1269L63.7118 73.7359L63.9948 73.4299L63.4518 73.3789L58.3928 66.4299L58.1468 66.0919L57.8598 66.3959L52.2818 72.3219L51.6768 72.2649L51.9668 72.6569L51.6798 72.9619L52.2318 73.0139L57.2228 79.7509L52.4398 85.6909L51.8648 85.6539L52.1578 86.0419L51.9078 86.3519L52.4158 86.3849L57.4178 93.0229L53.3198 99.1849L52.7808 99.1669L53.0758 99.5519L52.8678 99.8639L53.3258 99.8789L58.3428 106.431L55.0358 112.789L54.7788 113.284L55.3368 113.296L63.9028 113.477L64.6238 113.493L64.1848 112.92L59.1768 106.379L63.1778 100.2L63.7068 100.217L63.4168 99.8319L63.6178 99.5199L63.1708 99.5049L58.2798 93.0149L62.9698 87.0679L63.5338 87.1039L63.2468 86.7159L63.4908 86.4059L62.9928 86.3739L58.1108 79.7839ZM132.648 42.3459L131.577 41.8259L132.201 42.8399L136.694 50.1429L136.819 50.3459L137.054 50.3019L144.415 48.9169L148.395 55.4999L147.852 55.5399L148.733 56.0579L148.798 56.1659L148.902 56.1579L160.221 62.8179L160.695 62.3419L156.67 55.5819L157.22 55.5409L156.337 55.0239L156.273 54.9159L156.167 54.9239L145.561 48.7009L145.683 48.6789L144.738 48.2199L132.648 42.3459ZM115.681 29.9249L115.529 29.6819L115.261 29.7859L107.44 32.8369L106.57 33.1759L107.45 33.4869L119.954 37.9089L120.891 38.2409L120.363 37.3979L115.681 29.9249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4316 90.446L17.7197 91.0759L47.3837 79.282L47.4667 79.249L47.5237 79.1799L52.6857 72.9039L52.2587 72.376L17.4316 90.446Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M128.217 35.04L128.078 34.813L127.822 34.89L119.97 37.25L119.03 37.532L119.938 37.903L132.366 42.979L133.349 43.381L132.792 42.476L128.217 35.04Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6768 26.386L89.6968 27.08L89.7028 27.079L130.909 26.248H130.928L130.948 26.2449L138.067 25.2859L138.008 24.595L89.6828 26.386H89.6768Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.3521 112.602L54.624 112.587L55.07 113.162L60.327 119.927L60.664 120.361L60.911 119.87L64.22 113.286L64.467 112.795L63.9171 112.784L55.3521 112.602ZM140.406 40.9468L140.279 40.7379L140.04 40.7858L132.428 42.3178L131.421 42.5208L132.345 42.9698L144.435 48.8438L145.491 49.3568L144.884 48.3518L140.406 40.9468ZM115.518 29.7878L114.522 29.3818L115.093 30.2938L119.776 37.7658L119.916 37.9908L120.169 37.9138L128.022 35.5538L128.962 35.2708L128.053 34.9008L115.518 29.7878Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.8939 90.9238L17.2969 90.5698L11.3428 100.1L11.3339 100.114L11.3268 100.129L6.67285 109.404L7.28485 109.73L17.8939 90.9238Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M156.345 55.0361L151.992 47.7251L151.88 47.5381L151.663 47.5571L144.556 48.1861L143.476 48.2821L144.411 48.8311L155.833 55.5321L160.099 62.6961L160.193 62.8551L160.377 62.8651L166.954 63.2381L168.173 63.3071L167.172 62.6081L156.345 55.0361ZM52.6966 72.4771L52.4326 72.1201L52.1506 72.4631L47.1026 78.6001L46.5166 78.5551L46.8146 78.9501L46.5636 79.2541L47.0726 79.2931L52.1706 86.0591L47.9766 92.1971L47.4246 92.1711L47.7256 92.5651L47.5166 92.8701L47.9756 92.8921L53.2256 99.7481L53.5216 100.134L53.7906 99.7301L58.0226 93.3661L58.5636 93.3911L58.2666 92.9971L58.4696 92.6921L58.0216 92.6711L53.0366 86.0561L57.8166 80.1201L58.3916 80.1641L58.0986 79.7681L58.3436 79.4641L57.8456 79.4261L52.6966 72.4771ZM60.8746 119.502L60.4906 119.008L60.2756 119.595L58.0256 125.711L57.5266 125.692L57.8716 126.13L57.7736 126.396L58.0906 126.408L63.2486 132.963L63.6396 133.459L63.8486 132.863L66.0056 126.714L66.4936 126.733L66.1526 126.294L66.2456 126.029L65.9376 126.017L60.8746 119.502ZM128.073 34.9101L127.004 34.3921L127.626 35.4041L132.201 42.8401L132.328 43.0461L132.565 42.9981L140.177 41.4661L141.187 41.2631L140.26 40.8141L128.073 34.9101Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4639 90.431L17.6638 91.093L42.7778 85.778L42.9119 85.75L42.9899 85.638L47.5399 79.159L48.2078 78.208L47.1279 78.637L17.4639 90.431Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M140.284 40.8269L139.812 41.3059L144.29 48.7109L144.402 48.8959L144.617 48.8769L151.724 48.2479L152.807 48.1519L151.869 47.6029L140.284 40.8269Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176 2.28284L175.724 1.65283L89.5938 26.3989L89.7027 27.0789L138.034 25.2878L138.124 25.2849L138.201 25.2379L176 2.28284Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.7769 99.3268L53.4509 98.9008L53.1959 99.3729L49.6849 105.895L49.4209 106.384L49.9769 106.406L58.7399 106.742L59.4769 106.771L59.0279 106.185L53.7769 99.3268ZM123.558 27.4669L123.417 27.2368L123.159 27.3178L115.284 29.7788L114.363 30.0668L115.256 30.4308L127.791 35.5428L128.773 35.9438L128.217 35.0399L123.558 27.4669Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M175.863 2.33087L175.813 1.63989L156.413 2.03589L156.379 2.03693L156.347 2.04388L134.327 6.79688L134.443 7.47992L175.863 2.33087Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.0028 105.712L49.2588 105.684L49.7158 106.272L54.9498 113.007L52.6758 118.902L52.1588 118.871L52.5138 119.322L52.4138 119.581L52.7328 119.6L57.9908 126.282L58.3748 126.77L58.5898 126.187L60.8378 120.076L61.3438 120.106L60.9928 119.654L61.0878 119.396L60.7778 119.377L55.7548 112.912L59.0608 106.556L59.3128 106.07L58.7658 106.049L50.0028 105.712ZM163.281 55.9218L163.833 55.9448L162.92 55.3079L162.863 55.2098L162.775 55.2068L151.953 47.6608L147.518 40.2138L147.405 40.0239L147.185 40.0458L140.292 40.7589L136.096 33.8209L136.608 33.7108L135.791 33.3168L135.717 33.1928L135.591 33.2198L123.413 27.3358L122.346 26.8198L122.967 27.8298L127.369 34.9858L126.863 35.0948L127.679 35.4898L127.755 35.6129L127.878 35.5858L139.945 41.4329L151.441 48.1569L155.459 54.9048L154.914 54.8818L155.825 55.5189L155.883 55.6158L155.969 55.6198L166.775 63.1758L167.272 62.7158L163.281 55.9218ZM47.5328 78.7509L47.2458 78.3698L46.9718 78.7598L42.5338 85.0798L41.9678 85.0458L42.2758 85.4478L42.0658 85.7468L42.5268 85.7748L47.8778 92.7639L48.1698 93.1448L48.4398 92.7488L52.7728 86.4069L53.3268 86.4409L53.0238 86.0388L53.2278 85.7398L52.7778 85.7128L47.5328 78.7509Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9611 92.234L39.173 92.245L39.2791 92.061L43.007 85.612L43.3961 84.938L42.635 85.099L17.52 90.414L17.5731 91.1L38.9611 92.234Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M123.112 27.9608L135.304 33.8518L139.812 41.3058L139.926 41.4938L140.144 41.4719L147.036 40.7589L151.074 47.5399L150.548 47.5269L151.434 48.1448L151.493 48.2448L151.581 48.2468L162.399 55.7899L166.674 63.0678L166.754 63.2028L166.907 63.2328L172.911 64.4019L173.198 63.7938L162.928 55.3209L158.881 48.4318L159.414 48.4448L158.526 47.8278L158.467 47.7268L158.378 47.7249L148.186 40.6398L148.313 40.6268L147.473 40.1368L142.962 32.5618L142.846 32.3679L142.623 32.3948L135.729 33.2138L131.198 25.7219L131.069 25.5068L130.824 25.5628L123.185 27.3109L122.222 27.5308L123.112 27.9608ZM48.4289 92.3419L48.1079 91.9229L47.8509 92.3839L44.3389 98.6808L43.7949 98.6508L44.1209 99.0709L43.9629 99.3549L44.3589 99.3768L49.5939 106.114L47.2259 111.964L46.6899 111.922L47.0559 112.386L46.9539 112.639L47.2739 112.664L52.6359 119.477L53.0129 119.956L53.2319 119.388L55.5749 113.315L56.0999 113.357L55.7379 112.891L55.8349 112.64L55.5239 112.616L50.4029 106.025L53.7029 99.8958L54.2359 99.9258L53.9139 99.5048L54.0659 99.2219L53.6799 99.1998L48.4289 92.3419ZM58.5369 125.853L58.0849 125.279L57.9259 125.992L56.6359 131.771L56.1309 131.725L56.5329 132.23L56.4829 132.454L56.7289 132.476L61.9869 139.087L62.4479 139.667L62.5979 138.941L63.7989 133.122L64.2929 133.167L63.8939 132.661L63.9399 132.438L63.7019 132.417L58.5369 125.853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M36.0999 98.3879L36.4089 98.5099L36.5438 98.207L39.2958 92.0289L39.5009 91.5679L38.9968 91.541L17.6099 90.407L17.4648 91.077L36.0999 98.3879Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M169.329 57.0891L169.896 57.1901L168.922 56.3881L168.872 56.3031L168.803 56.2911L158.486 47.8011L157.966 48.2451L161.979 55.076L161.42 54.9761L162.391 55.7781L162.441 55.8631L162.509 55.8751L172.756 64.3291L173.277 63.8881L169.329 57.0891ZM131.076 25.6021L130.605 26.0811L135.251 33.7641L135.366 33.9551L135.588 33.9291L142.704 33.0831L143.735 32.9611L142.838 32.4391L131.076 25.6021ZM42.9813 85.2281L42.6663 84.8151L42.4063 85.2651L38.6783 91.7141L38.4014 92.1941L38.9543 92.2341L48.1293 92.8991L48.8983 92.9551L48.4293 92.3421L42.9813 85.2281Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.91 90.892L17.29 90.583L6.68103 109.39L6.66205 109.424L6.65002 109.461L1.43506 127.009L2.08606 127.246L17.91 90.892Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.181 119.048L52.739 118.486L52.571 119.181L51.119 125.209L51.027 125.59L51.416 125.635L58.225 126.412L59.051 126.507L58.537 125.853L53.181 119.048ZM39.004 91.5418L38.2271 91.4849L38.705 92.0999L44.262 99.2518L44.583 99.6658L44.8391 99.2078L48.457 92.7219L48.722 92.2468L48.179 92.2068L39.004 91.5418Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M175.827 2.33282L175.833 1.63983L166.498 1.27985L166.478 1.27881L166.458 1.28082L156.394 2.03687L156.427 2.72986L175.827 2.33282Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M154.398 40.8009L154.912 40.8049L154.049 40.2069L153.988 40.1029L153.898 40.1019L142.923 32.4969L138.319 24.7639L138.202 24.5669L137.974 24.5979L130.855 25.5579L129.853 25.6929L130.727 26.2009L142.412 32.9939L146.607 40.0389L146.1 40.0349L146.961 40.6329L147.022 40.7369H147.111L158.056 48.3459L168.385 56.8439L172.677 64.2359L172.74 64.3439L172.858 64.3869L178.223 66.3539L178.584 65.7789L168.929 56.4009L164.547 48.8559L164.465 48.7129L164.302 48.6869L158.482 47.7529L154.398 40.8009ZM51.4952 124.946L50.6631 124.851L51.1852 125.506L56.5532 132.256L56.2012 137.866L56.1821 138.177L56.4891 138.23L62.2001 139.212L63.0961 139.367L62.5302 138.655L57.2831 132.058L58.6031 126.143L58.6871 125.767L58.3041 125.723L51.4952 124.946ZM44.8102 98.8269L44.4482 98.3599L44.2172 98.9039L41.6732 104.895L41.1182 104.841L41.4941 105.319L41.3892 105.565L41.7122 105.596L47.1802 112.544L47.5502 113.014L47.7742 112.46L50.2151 106.429L50.7592 106.483L50.3871 106.004L50.4861 105.759L50.1732 105.728L44.8102 98.8269Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2509 104.202L34.6499 104.514L34.7959 104.028L36.5589 98.1649L36.6509 97.8589L36.3539 97.7429L17.7179 90.4309L17.3779 91.0269L34.2509 104.202Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M56.913 137.599L57.2289 132.561L57.767 132.667L57.262 132.032L57.2729 131.863L57.1 131.828L51.8329 125.207L53.172 119.649L53.712 119.722L53.285 119.18L53.336 118.971L53.094 118.938L48.2729 112.811L47.725 112.115L47.293 111.566L42.292 105.212L44.754 99.414L45.318 99.48L44.935 98.987L45.0359 98.749L44.7209 98.712L39.253 91.675L38.897 91.218L38.662 91.746L36.014 97.691L35.438 97.624L35.825 98.116L35.718 98.355L36.043 98.393L41.512 105.341L39.903 111.093L39.798 111.468L40.183 111.529L47.021 112.612L45.642 117.92L45.09 117.845L45.521 118.386L45.4659 118.596L45.715 118.63L51.099 125.399L50.688 130.561L50.137 130.452L50.646 131.086L50.632 131.257L50.811 131.292L55.704 137.391L56.277 138.105L56.837 138.804L61.717 144.885L62.291 145.601L62.334 144.685L62.605 138.887L62.619 138.581L62.317 138.529L56.913 137.599ZM153.528 40.69L153.574 40.768L157.966 48.245L158.049 48.386L158.21 48.412L164.03 49.346L168.353 56.79L168.418 56.901L168.467 56.918L178.1 66.277L178.642 65.855L174.299 58.307L174.235 58.195L174.185 58.178L165.279 49.547L165.459 49.576L164.516 48.801L160.416 41.742L160.938 41.816L160.025 41.069L159.972 40.978L159.901 40.968L150.188 33.021L150.325 33.02L149.486 32.441L149.424 32.334L144.875 24.592L144.772 24.415L144.567 24.421L138.011 24.595L136.949 24.623L137.761 25.184L142.056 32.397L141.568 32.401L142.404 32.981L142.467 33.087H142.556L153.528 40.69Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M7.3147 109.659L6.65771 109.44L3.34674 118.406L3.33875 118.428L3.33374 118.451L1.42871 127.032L2.09973 127.206L7.3147 109.659Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M40.2922 110.844L39.4102 110.704L39.9661 111.402L45.6292 118.521L46.0612 119.065L46.2361 118.393L47.7891 112.417L47.8851 112.047L47.5071 111.987L40.2922 110.844ZM174.26 58.252L173.698 58.653L178.041 66.2009L178.087 66.2789L178.165 66.3259L182.811 69.0889L183.25 68.5629L174.26 58.252Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.024 2.26697L175.64 1.68994L137.841 24.645L136.724 25.3229L138.03 25.288L144.585 25.114L144.693 25.111L144.781 25.048L176.024 2.26697Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M56.8182 137.671L56.0552 136.719L51.3591 130.867L51.7701 125.715L52.3381 125.842L51.8132 125.181L51.8262 125.017L51.6512 124.978L46.1722 118.09L45.6382 117.419L45.5552 118.272L45.0432 123.508L44.4622 123.378L44.9911 124.038L44.9752 124.203L45.1561 124.243L50.6691 131.115L51.2261 136.294L51.2502 136.517L51.4631 136.586L56.2302 138.149L56.7952 142.749L56.2291 142.579L56.8712 143.374L56.8871 143.5L57.0012 143.535L62.4581 150.294L63.2411 151.263L63.0721 150.029L62.4042 145.156L62.9562 145.321L62.3182 144.526L62.3011 144.4L62.1912 144.367L57.5482 138.581L56.8182 137.671ZM178.84 60.871L169.855 50.584L169.806 50.499L169.756 50.482L160.033 41.082L155.83 33.846L156.331 33.908L155.448 33.188L155.393 33.094L155.321 33.085L144.796 24.499L144.277 24.943L148.571 32.252L148.078 32.191L148.959 32.912L149.014 33.006L149.084 33.014L159.489 41.526L163.947 49.204L164.013 49.318L164.063 49.334L173.722 58.695L173.744 58.734L173.775 58.751L182.727 69.019L183.289 68.619L178.918 60.971L178.871 60.889L178.84 60.871ZM36.5002 97.851L36.0872 97.327L35.8951 97.966L34.2211 103.532L33.6191 103.424L34.0792 104.002L34.0201 104.201L34.2742 104.247L39.9661 111.402L40.3882 111.933L40.5712 111.28L42.1422 105.663L42.7302 105.768L42.2751 105.189L42.3301 104.991L42.0842 104.947L36.5002 97.851Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.055 2.24109L175.615 1.70605L144.372 24.4871L143.642 25.0201L144.541 25.1131L150.478 25.7271L150.634 25.7431L150.749 25.6361L176.055 2.24109Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M174.381 53.071L165.342 42.741L165.292 42.653L160.758 34.774L160.688 34.653L160.554 34.614L155.41 33.1219L150.813 25.2079L150.725 25.0549L150.549 25.037L144.612 24.423L143.458 24.303L144.357 25.0359L154.912 33.6459L159.457 41.472L159.525 41.5889L159.575 41.605L169.296 51.002L169.319 51.043L169.35 51.0599L178.333 61.345L182.687 68.963L182.714 69.009L182.753 69.045L186.591 72.606L187.107 72.147L178.908 60.9529L174.461 53.1719L174.413 53.088L174.381 53.071ZM51.6788 135.927L50.5638 135.562L51.3008 136.475L56.8958 143.404L58.5718 148.219L58.6178 148.35L58.6758 148.38L64.2328 155.236L64.8288 154.9L63.0548 149.959L63.0088 149.83L62.9518 149.801L57.5358 143.093L56.8918 137.845L56.8648 137.627L56.6558 137.558L51.6788 135.927ZM40.5088 110.97L39.9898 110.318L39.8928 111.146L39.2878 116.317L38.6758 116.166L39.2258 116.852L39.2068 117.012L39.3898 117.057L45.0148 124.067L45.4818 129.174L45.5018 129.395L45.7108 129.47L50.8868 131.304L52.0368 131.711L51.2728 130.76L45.7088 123.825L46.2058 118.738L46.8048 118.886L46.2588 118.199L46.2748 118.041L46.0978 117.997L40.5088 110.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.09 2.20496L175.585 1.73193L150.278 25.127L149.816 25.554L150.424 25.717L155.674 27.126L155.892 27.184L156.034 27.009L176.09 2.20496Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M150.754 25.1318L150.213 25.5558L154.88 33.5908L154.95 33.7108L155.083 33.7498L160.36 35.2798L160.697 34.6968L150.754 25.1318ZM45.9429 128.816L44.7859 128.406L45.5569 129.361L51.2619 136.426L52.8569 141.166L52.8999 141.296L53.0199 141.361L57.0379 143.538L57.4729 143.016L51.9049 136.118L51.3479 130.94L51.3239 130.723L51.1189 130.65L45.9429 128.816ZM34.7349 103.713L34.2309 103.078L34.1199 103.881L33.4199 108.985L32.7739 108.811L33.3459 109.523L33.3249 109.678L33.5099 109.728L39.3169 116.965L39.8359 117.612L39.9319 116.788L40.5349 111.626L41.1659 111.797L40.5979 111.083L40.6159 110.929L40.4379 110.881L34.7349 103.713ZM182.719 64.4348L182.692 64.3868L182.677 64.3738L174.439 53.1389L173.859 53.5158L178.316 61.3148L178.343 61.3618L178.358 61.3759L186.547 72.5568L187.129 72.1808L182.719 64.4348Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M191.758 6.56299L191.964 5.901L184.436 3.177L184.409 3.16699L184.379 3.16205L175.88 1.64502L175.73 2.32202L191.758 6.56299Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M169.916 45.2189L169.866 45.1329L169.834 45.1159L160.74 34.7439L156.065 26.6179L155.993 26.4929L155.854 26.4559L150.603 25.0469L150.273 25.6319L160.181 35.1619L164.758 43.1169L164.807 43.2019L164.839 43.2189L173.899 53.5729L174.461 53.1719L169.916 45.2189ZM53.3511 140.751L52.9161 141.274L58.6301 148.324L59.2272 147.991L57.5311 143.119L57.4872 142.992L57.3691 142.928L53.3511 140.751ZM39.8582 116.531L39.1592 115.66L39.2411 116.774L39.6232 121.912L39.6392 122.132L39.8452 122.211L45.2272 124.256L46.4182 124.708L45.6212 123.714L39.8582 116.531Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.7773 104.078L34.8973 103.826L34.6773 103.655L17.8054 90.4801L17.3193 90.1011L17.2473 90.7131L9.65234 155.245L10.3104 155.435L34.7773 104.078Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M40.0916 121.562L38.8926 121.107L39.6986 122.105L45.5566 129.361L46.2766 130.252L46.1716 129.111L45.6956 123.9L45.6756 123.684L45.4736 123.607L40.0916 121.562Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9359 90.7941L17.2739 90.6151L1.44989 126.969L1.3999 127.084L1.43494 127.205L9.66388 155.382L10.3409 155.326L17.9359 90.7941ZM176.121 2.1601L175.55 1.76807L155.494 26.5731L155.222 26.9101L155.61 27.1021L160.095 29.3161L160.386 29.4591L160.549 29.1781L176.121 2.1601Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M178.22 56.531L178.194 56.486L178.179 56.472L169.906 45.202L165.279 37.108L165.228 37.019L165.196 37.002L156.025 26.562L155.463 26.964L160.156 35.12L160.207 35.208L160.24 35.225L169.331 45.594L173.859 53.516L173.887 53.565L173.902 53.579L182.126 64.795L186.526 72.524L186.532 72.535L186.539 72.546L189.47 76.903L189.835 76.669L190.059 76.537L185.602 68.7L185.596 68.688L185.593 68.684L178.22 56.531ZM55.6379 145.541L58.6309 148.329L61.3688 152.604L61.3928 152.642L61.4078 152.656L67.0638 159.624L67.6238 159.218L64.7938 154.829L64.7698 154.792L64.7559 154.779L59.1688 147.887L53.4949 140.886L51.8998 136.146L51.8569 136.021L51.7988 135.988L46.0969 128.925L45.4968 129.25L47.0269 133.971L47.0688 134.099L47.1288 134.134L52.9039 141.259L55.5809 145.473L55.6049 145.51L55.6379 145.541ZM33.9808 109.206L33.3038 108.362L33.3638 109.442L33.6149 113.958L32.8809 113.661L33.6519 114.615L33.6578 114.723L33.7789 114.772L39.6989 122.105L40.3969 122.969L40.3138 121.861L39.9749 117.279L40.6898 117.568L39.9249 116.615L39.9169 116.507L39.8008 116.46L33.9808 109.206Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.142 2.11499L175.519 1.81299L159.948 28.831L159.802 29.084L160.026 29.271L163.652 32.298L164.02 32.605L164.197 32.16L176.142 2.11499Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M173.634 48.476L173.605 48.4249L173.59 48.4109L165.269 37.0909L160.549 28.8329L160.497 28.741L160.402 28.694L155.917 26.48L155.503 27.02L164.694 37.483L169.313 45.564L169.342 45.614L169.357 45.6279L177.641 56.912L178.222 56.535L173.634 48.476ZM56.1097 145.033L55.6047 145.506L61.3907 152.636L61.9527 152.23L59.1917 147.918L59.1677 147.881L59.1357 147.852L56.1097 145.033ZM40.2377 121.669L39.6367 121.99L41.0777 126.63L41.1177 126.758L41.1787 126.794L47.0867 134.083L47.6867 133.758L46.1567 129.036L46.1157 128.911L46.0567 128.876L40.2377 121.669Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M2.11395 127.091L1.42096 127.09L1.00195 135.19L1 135.225L1.00494 135.261L2.16498 142.759L2.85394 142.689L2.11395 127.091Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M181.055 60.704L181.048 60.692L181.045 60.688L173.629 48.467L173.031 48.819L177.619 56.878L177.626 56.89L177.629 56.894L185.004 69.051L185.602 68.7L181.055 60.704ZM47.626 133.646L47.063 134.049L49.675 138.196L49.699 138.233L49.73 138.263L52.947 141.307L53.455 140.837L47.626 133.646Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M203.152 15.8379L203.524 15.2579L192.066 5.95789L192.008 5.91089L191.936 5.89191L175.909 1.65088L175.667 2.29791L203.152 15.8379Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M168.953 40.2528L168.923 40.1998L168.908 40.1868L160.527 28.7988L159.947 29.1768L164.677 37.4518L164.707 37.5048L164.722 37.5178L173.053 48.8528L173.634 48.4758L168.953 40.2528ZM50.2072 137.759L49.6992 138.23L55.6052 145.506L56.1672 145.101L53.4781 140.87L53.4551 140.833L53.4241 140.804L50.2072 137.759ZM34.2621 114.267L33.6592 114.583L35.0082 119.138L35.0461 119.266L35.1592 119.335L39.7872 122.182L40.2382 121.669L34.2621 114.267Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.154 2.07788L175.497 1.85791L163.552 31.9039L163.483 32.0759L163.589 32.2289L166.255 36.0789L166.678 36.6909L166.875 35.9729L176.154 2.07788Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M35.5223 118.744L35.0713 119.258L41.1403 126.746L41.7413 126.424L40.3003 121.784L40.2613 121.66L40.1503 121.591L35.5223 118.744ZM176.414 52.5429L176.407 52.5309L176.404 52.5269L168.948 40.2439L168.35 40.5959L173.032 48.8189L173.039 48.8309L173.041 48.8349L180.457 61.0549L181.055 60.7039L176.414 52.5429Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.0176 109.582L34.0456 109.529L34.0536 109.47L34.8076 103.976L34.1506 103.779L9.68359 155.136L10.3046 155.445L34.0176 109.582Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6792 126.309L41.1152 126.711L43.6482 130.768L43.6712 130.805L43.6862 130.82L49.7002 138.23L50.2622 137.826L47.6502 133.68L47.6282 133.644L47.6132 133.629L41.6792 126.309ZM164.176 31.86L164.145 31.806L164.097 31.766L160.471 28.738L159.969 29.21L168.373 40.63L168.953 40.253L164.176 31.86Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.16 2.05402L175.485 1.89502L166.205 35.79L166.177 35.893L166.212 35.993L167.803 40.671L168.219 41.894L168.472 40.627L176.16 2.05402Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M171.678 44.2141L171.67 44.201L171.667 44.1971L164.171 31.8521L163.573 32.2041L168.351 40.5961L168.358 40.6091L168.361 40.6131L175.816 52.8951L176.414 52.5431L171.678 44.2141ZM35.6099 118.821L35.0459 119.222L37.4939 123.186L37.5169 123.222L37.5469 123.252L41.1679 126.776L41.6789 126.309L35.6099 118.821Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.291 114.66L34.344 114.57L34.338 114.465L34.056 109.403L33.986 108.134L33.402 109.263L9.68896 155.126L10.296 155.461L34.291 114.66Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M38.031 122.755L37.52 123.222L43.6721 130.803L44.236 130.401L41.704 126.344L41.681 126.308L41.6511 126.279L38.031 122.755Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M2.10089 127.01L1.4209 127.124L2.16089 142.722L2.16589 142.809L2.2099 142.883L9.69891 155.463L10.3299 155.188L2.10089 127.01ZM203.086 15.796L203.556 15.288L198.491 9.97595L198.461 9.94495L198.424 9.92096L192.031 5.93298L191.628 6.49695L203.086 15.796ZM176.162 2.04297L175.48 1.91895L167.792 40.491L167.782 40.537L167.786 40.584L168.183 46.0889L168.871 46.1199L176.162 2.04297Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M166.841 35.71L166.834 35.697L166.825 35.684L164.16 31.834L163.578 32.212L171.079 44.5649L171.677 44.2139L166.841 35.71Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M35.6252 119.238L35.7202 119.101L35.6732 118.941L34.3242 114.386L34.0982 113.62L33.6932 114.309L9.69824 155.109L10.2812 155.484L35.6252 119.238Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M36.226 184.285L35.73 184.769L41.8929 191.706L41.915 191.73L41.941 191.75L49 197.167L49.448 196.638L36.226 184.285Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M176.162 2.04193L175.478 1.92993L175.477 1.93097L168.186 46.007L168.185 46.0129L167.261 52.3309L167.26 52.337L167.946 52.4429L167.947 52.437L176.162 2.04297V2.04193ZM38.0519 123.23L38.2179 123.037L38.0839 122.821L35.6359 118.857L35.3589 118.408L35.0559 118.841L9.71191 155.086L10.2599 155.511L38.0519 123.23Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M224.987 57.0059L224.341 57.2448L226.373 65.6088L226.277 65.6278L226.468 65.9988L226.537 66.2828L226.606 66.2689L230.81 74.4468L231.455 74.2048L229.337 65.7148L229.429 65.6959L229.239 65.3248L229.169 65.0408L229.101 65.0548L224.987 57.0059ZM29.933 177.203L29.437 177.687L35.73 184.769L35.7531 184.795L35.7661 184.804L48.9741 197.145L49.471 196.661L43.2191 189.598L43.196 189.572L43.183 189.562L29.933 177.203Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M2.80597 142.528L2.18799 142.841L5.03296 149.591L5.05194 149.635L5.08197 149.672L9.72498 155.501L10.295 155.108L2.80597 142.528Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6049 126.536L41.8819 126.28L41.6039 126.026L38.0229 122.748L37.7589 122.506L37.5259 122.777L9.73389 155.059L10.2319 155.54L41.6049 126.536Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.5058 169.97L23.0098 170.454L29.4368 177.687L29.4598 177.713L29.4878 177.734L36.3628 182.889L36.8078 182.358L23.5058 169.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M205.132 51.1798L205.775 50.9799L203.651 15.5059L203.639 15.3048L203.458 15.2158L175.973 1.6748L174.91 1.15186L175.523 2.16583L205.132 51.1798Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M36.831 182.382L36.808 182.355L36.779 182.334L29.905 177.179L29.46 177.71L42.71 190.07L48.951 197.121L48.999 197.176L49.066 197.206L58.016 201.339L58.3669 200.745L43.194 189.57L36.831 182.382ZM222.359 56.936L221.823 56.9639L222.068 57.441L226.486 66.0359L227.132 65.795L225.015 57.082L224.948 56.8019L224.66 56.817L222.359 56.936ZM216.248 39.907L215.601 40.142L217.523 48.577L217.58 48.825L217.833 48.8459L220.326 49.0499L220.947 49.101L220.664 48.5469L216.248 39.907Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3177 129.05L46.7657 128.722L46.2737 128.464L41.5307 125.975L41.3137 125.861L41.1337 126.027L9.76074 155.031L10.2017 155.565L46.3177 129.05Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9413 162.581L16.4453 163.065L23.0114 170.454L23.0353 170.481L23.0483 170.491L36.3353 182.865L36.8314 182.382L30.3043 175.007L30.2803 174.98L30.2664 174.97L16.9413 162.581ZM227.103 65.719L226.448 65.882L226.577 75.981L226.583 76.488L227.053 76.299L231.248 74.61L231.601 74.468L231.427 74.13L227.103 65.719ZM217.89 48.154L217.268 48.103L217.553 48.659L222.068 57.442L222.714 57.203L220.692 48.625L220.635 48.38L220.383 48.359L217.89 48.154Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M205.441 51.347L206.03 51.326L205.725 50.821L176.117 1.80701L175.629 1L175.477 1.93103L167.261 52.331L167.193 52.749L167.616 52.734L205.441 51.347Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7763 182.332L36.3113 182.841L42.6993 190.058L42.7483 190.114L42.7833 190.129L57.9553 201.304L58.4223 200.796L52.1053 193.593L52.0553 193.537L52.0203 193.521L36.7763 182.332ZM226.794 75.6551L226.439 75.7981L226.616 76.1371L231.051 84.6571L231.705 84.4891L231.465 74.2811L231.454 73.7801L230.989 73.9671L226.794 75.6551ZM211.736 31.0811L211.088 31.313L212.907 39.5961L212.956 39.8171L213.177 39.8621L215.87 40.4051L216.575 40.5471L216.248 39.9071L211.736 31.0811ZM10.2323 155.031L9.7373 155.516L16.4453 163.065L16.4703 163.093L16.5003 163.115L23.1683 167.98L23.6093 167.446L10.2323 155.031Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M52.218 130.658L52.926 130.238L52.131 130.024L46.203 128.436L46.0421 128.393L45.907 128.491L9.79102 155.006L10.173 155.584L52.218 130.658Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6328 167.47L23.6077 167.442L23.5767 167.42L16.9088 162.555L16.4688 163.089L29.8077 175.491L30.3037 175.007L23.6328 167.47ZM222.685 57.124L222.03 57.283L222.043 67.268L222.044 67.74L222.494 67.598L226.899 66.208L227.291 66.084L227.103 65.719L222.685 57.124Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M213.536 39.227L211.894 31.746L212.11 31.812L211.757 31.12L211.723 30.967L211.67 30.95L207.126 22.061L206.478 22.291L208.066 29.837L207.841 29.767L208.197 30.459L208.23 30.613L208.285 30.631L212.608 39.039L212.897 39.602L212.665 49.243L212.655 49.658L213.065 49.594L217.51 48.905L217.41 58.085L217.239 58.125L217.406 58.444L217.402 58.799L217.57 58.76L221.891 67.061L222.082 67.427L226.561 76.031L224.48 87.21L224.307 87.287L224.424 87.507L224.325 88.04L224.633 87.901L229.05 96.218L229.525 97.113L229.698 96.114L231.669 84.738L231.836 84.662L231.72 84.44L231.812 83.913L231.515 84.046L227.269 75.89L227.141 65.873L227.135 65.406L226.69 65.546L222.736 66.794L222.724 57.558L222.888 57.52L222.723 57.199V56.846L222.561 56.884L218.39 48.769L218.19 48.38L216.277 39.988L216.227 39.769L216.007 39.725L213.536 39.227ZM36.3618 182.899L36.3967 182.914L51.6077 194.079L51.6577 194.136L57.8997 201.253L57.9727 201.336L58.0797 201.362L68.9807 203.967L69.0917 203.684L69.3237 203.403L62.9717 196.05L62.8977 195.964L62.8358 195.95L45.6087 186.187L45.5967 186.173L39.0457 178.704L38.9927 178.644L38.9567 178.629L23.6077 167.442L16.8117 159.764L16.7857 159.735L16.7547 159.713L10.1987 155.004L9.76074 155.54L23.1237 167.943L29.7838 175.467L29.8367 175.526L36.3107 182.841L36.3618 182.899Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M10.16 154.979L9.79395 155.567L16.35 160.276L16.3849 160.301L16.426 160.317L25 163.682L25.29 163.053L10.16 154.979Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.756 159.714L16.293 160.224L23.113 167.93L23.167 167.991L23.2029 168.005L38.581 179.213L39.046 178.704L32.2939 171.006L32.24 170.944L32.2029 170.929L16.756 159.714ZM222.698 67.1069L222.243 66.2339L222.05 67.199L219.774 78.584L219.649 79.207L220.241 78.975L227.051 76.2999L227.41 76.1589L227.231 75.817L222.698 67.1069ZM212.959 48.9089L212.481 48.983L212.704 49.412L217.446 58.5209L218.101 58.3649L218.209 48.504L218.213 48.095L217.809 48.157L212.959 48.9089Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9556 178.631L38.5246 179.161L45.1266 186.689L45.2026 186.776L45.2626 186.788L62.4866 196.55L68.7986 203.857L68.8936 203.967L69.0396 203.976L81.9186 204.795L82.0766 204.13L62.9196 195.99L56.4756 188.531L56.3996 188.443L56.3376 188.429L38.9556 178.631ZM219.987 78.3289L219.625 78.4708L219.808 78.8148L224.409 87.4778L219.941 99.7288L219.67 100.473L220.401 100.168L229.49 96.3748L229.842 96.2288L229.663 95.8928L225.151 87.3958L227.265 76.0408L227.379 75.4258L226.797 75.6538L219.987 78.3289ZM208.836 30.1838L208.231 29.0078L208.181 30.3289L207.825 39.6108L207.611 39.6288L207.81 40.0109L207.799 40.3088L207.959 40.2968L212.704 49.4119L213.358 49.2598L213.586 39.8428L213.793 39.8258L213.595 39.4418L213.602 39.1448L213.449 39.1578L208.836 30.1838ZM218.061 58.2008L217.617 57.3469L217.415 58.2888L214.993 69.5748L214.865 70.1718L215.443 69.9768L222.501 67.5958L222.885 67.4669L222.698 67.1068L218.061 58.2008ZM25.3866 163.131L25.3316 163.067L25.2526 163.036L16.6786 159.672L16.3486 160.275L31.8296 171.515L32.2936 171.006L25.3866 163.131Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M205.5 51.3398L205.416 50.6538L167.591 52.0399L167.351 52.0488L167.275 52.2769L164.9 59.3989L164.709 59.9728L165.301 59.8478L205.5 51.3398Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.2029 170.932L31.7729 171.463L38.524 179.161L38.602 179.25L38.663 179.262L55.991 189.03L62.448 196.504L62.5439 196.616L62.631 196.62L81.805 204.768L82.206 204.225L75.855 196.711L75.758 196.596L75.668 196.591L56.423 188.469L49.831 180.838L49.753 180.747L49.6899 180.735L32.2029 170.932ZM215.221 69.3188L214.834 69.4498L215.026 69.8109L219.808 78.8138L220.263 79.6729L220.454 78.7198L222.73 67.3348L222.848 66.7468L222.279 66.9388L215.221 69.3188ZM220.133 99.5269L219.778 99.6758L219.963 100.014L224.686 108.669L225.06 109.354L225.318 108.618L229.684 96.1699L229.942 95.4348L229.223 95.7349L220.133 99.5269ZM213.319 49.0908L212.885 48.2568L212.674 49.1738L210.1 60.3558L209.969 60.9268L210.533 60.7668L217.848 58.6948L218.258 58.5789L218.061 58.2008L213.319 49.0908Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M10.082 154.949L9.83301 155.591L24.963 163.665L25.028 163.7L25.101 163.705L48.627 165.41L48.7371 164.728L10.082 154.949ZM165.157 59.1689L164.994 59.2039L164.919 59.3529L161.102 66.9889L160.711 67.7699L161.531 67.4689L205.548 51.3259L205.357 50.6609L165.157 59.1689Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M220.42 78.4889L220.061 77.8129L219.789 78.5289L215.164 90.7199L214.937 90.8059L215.052 91.0149L214.835 91.5859L215.273 91.4209L219.962 100.014L220.33 100.687L220.593 99.9659L225.059 87.7199L225.279 87.6369L225.166 87.4259L225.373 86.8599L224.951 87.0189L220.42 78.4889ZM25.2952 163.056L24.8652 163.588L31.7722 171.463L31.8522 171.554L31.9142 171.565L49.3452 181.337L55.9512 188.984L56.0502 189.098L56.1372 189.102L75.3782 197.222L81.6762 204.673L81.7912 204.809L81.9692 204.794L96.3362 203.615L96.4092 202.938L75.7892 196.633L69.3562 189.021L69.2572 188.905L69.1672 188.901L49.7772 180.776L43.0302 172.966L42.9502 172.873L42.8862 172.862L25.2952 163.056ZM210.344 60.0999L209.93 60.2169L210.132 60.5959L215.026 69.8109L215.472 70.6499L215.671 69.7209L218.093 58.4339L218.214 57.8699L217.659 58.0269L210.344 60.0999Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M208.184 30.291L205.116 50.746L161.292 66.818L161.185 66.858L161.122 66.954L156.014 74.75L155.324 75.804L156.456 75.252L205.58 51.313L205.457 51.023L205.765 51.084L208.497 40.015L208.505 39.981L208.507 39.945L208.874 30.356L208.184 30.291ZM52.1254 130.023L51.9874 129.989L51.8643 130.061L9.82031 154.987L10.0313 155.631L111.363 145.477L111.412 144.795L52.1254 130.023Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M220.571 99.681L220.263 99.117L219.961 99.684L213.245 112.287L212.983 112.38L213.093 112.574L212.762 113.194L213.329 112.994L218.079 121.413L218.394 121.971L218.689 121.402L225.236 108.784L225.49 108.694L225.384 108.499L225.703 107.883L225.154 108.078L220.571 99.681ZM208.468 39.772L208.044 38.957L207.823 39.849L205.143 50.711L204.91 50.764L205.06 51.046L204.957 51.466L205.247 51.399L210.132 60.596L210.568 61.417L210.776 60.511L213.301 49.541L213.526 49.489L213.378 49.205L213.474 48.789L213.195 48.853L208.468 39.772ZM215.638 69.485L215.286 68.821L215.009 69.521L210.22 81.645L209.982 81.726L210.101 81.945L209.886 82.491L210.319 82.344L215.128 91.156L215.489 91.818L215.757 91.112L220.38 78.928L220.611 78.849L220.494 78.628L220.699 78.087L220.282 78.228L215.638 69.485ZM90.264 195.368L90.147 195.226L90.031 195.236L69.289 188.943L62.703 181.151L62.603 181.032L62.512 181.03L42.9771 172.904L36.069 164.908L35.9871 164.813L35.864 164.793L25.183 163.017L24.957 163.662L42.545 173.466L49.306 181.291L49.406 181.408L49.494 181.41L68.88 189.533L75.325 197.159L75.442 197.297L75.554 197.286L96.2061 203.601L97.3051 203.938L96.576 203.05L90.264 195.368Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M156.152 74.6279L156.083 74.662L156.034 74.723L149.745 82.541L150.188 83.059L205.601 51.3019L205.277 50.689L156.152 74.6279ZM59.2581 130.597L59.2271 130.589L59.195 130.586L52.069 130.014L51.957 130.696L111.237 145.466L111.245 145.468L111.42 144.797L111.412 144.795L59.2581 130.597Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0665 195.249L89.1016 195.047L89.7275 195.808L96.0396 203.49L96.1725 203.652L96.3785 203.609L111.54 200.42V199.741L90.0665 195.249ZM215.737 90.8229L215.433 90.2659L215.128 90.8229L208.174 103.523L207.754 104.29L208.586 104.019L220.374 100.177L220.771 100.048L220.571 99.6809L215.737 90.8229Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M149.843 82.457L149.8 82.4819L149.765 82.519L142.428 90.2109L142.864 90.744L205.613 51.2939L205.256 50.7L149.843 82.457Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M210.745 60.2709L210.398 59.6189L210.117 60.3009L205.096 72.5039L204.818 73.1789L205.516 72.9689L215.432 69.9799L215.836 69.8579L215.638 69.4849L210.745 60.2709ZM208.371 103.36L207.968 103.491L208.176 103.86L213.186 112.74L213.497 113.291L213.794 112.732L220.573 100.011L220.978 99.2509L220.16 99.5179L208.371 103.36ZM83.8019 187.504L83.683 187.359L83.567 187.372L62.538 181.043L61.4189 180.706L62.174 181.599L68.826 189.469L68.944 189.61L69.0569 189.598L89.895 195.92L90.991 196.252L90.264 195.368L83.8019 187.504Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1514 163.013L25.0693 163.701L35.7504 165.477L35.7794 165.482H35.8093L48.6544 165.411L48.6773 164.718L25.1514 163.013Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M35.939 164.814L35.5439 165.361L42.5049 173.419L42.608 173.538L42.764 173.539L55.624 173.663L55.7599 172.996L35.939 164.814Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M142.494 90.156L142.469 90.172L142.447 90.192L134.216 97.609L134.639 98.156L205.613 51.294L205.62 51.29L205.244 50.707L205.237 50.711L142.494 90.156ZM67.6002 130.041L67.5352 130.019L67.4661 130.024L59.1442 130.586L59.0762 131.267L111.237 145.466L111.439 144.803L67.6002 130.041Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.8918 173.093L55.7898 172.971L55.6308 172.97L42.7708 172.846L42.6338 173.513L62.3048 181.695L62.7028 181.151L55.8918 173.093ZM205.316 72.3039L204.907 72.4279L205.112 72.8029L210.179 82.0879L210.534 82.7379L210.806 82.0489L215.654 69.7749L215.918 69.1089L215.232 69.3159L205.316 72.3039ZM213.79 112.399L213.519 111.918L213.204 112.371L204.298 125.152L203.732 125.966L204.682 125.683L218.48 121.575L218.897 121.451L218.684 121.072L213.79 112.399Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M134.257 97.577L134.245 97.584L134.234 97.593L125.282 104.582L125.689 105.143L205.62 51.29L205.622 51.2889L205.237 50.7109L205.235 50.713L134.257 97.577Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M125.301 104.568H125.3L115.814 110.983L115.813 110.984L116.201 111.559L205.621 51.2879L205.428 51.0009L205.234 50.7129L205.229 50.7159L125.301 104.568Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M105.504 192.018L105.37 191.848L105.231 191.879L83.6036 187.384L82.6406 187.184L83.2656 187.944L89.7276 195.808L89.8626 195.973L89.9976 195.943L111.398 200.421L112.338 200.617L111.74 199.865L105.504 192.018ZM210.788 81.7549L210.488 81.2059L210.181 81.751L203.045 94.4249L202.624 95.173L203.446 94.9269L215.532 91.322L215.942 91.199L215.737 90.8229L210.788 81.7549ZM205.735 50.838L205.395 50.199L205.11 50.864L199.907 62.989L199.627 63.6429L200.314 63.462L210.527 60.769L210.95 60.657L210.745 60.2709L205.735 50.838Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M76.834 128.309L76.731 128.26L76.6201 128.282L67.425 130.029L66.124 130.277L67.379 130.699L111.218 145.46L111.478 144.818L76.834 128.309Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M203.248 94.262L202.831 94.387L203.045 94.765L208.176 103.86L208.483 104.403L208.782 103.856L215.737 91.156L216.142 90.416L215.333 90.657L203.248 94.262ZM105.276 191.889L104.433 191.784L104.961 192.449L111.197 200.297L111.347 200.486L111.577 200.411L127.196 195.289L127.131 194.616L105.276 191.889ZM77.1843 179.45L77.0634 179.304L76.9464 179.317L55.7274 172.984L54.6113 172.651L55.3624 173.541L62.1733 181.599L62.2943 181.741L62.4073 181.728L83.4343 188.056L84.5253 188.385L83.8023 187.504L77.1843 179.45Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M9.9621 154.94L9.91211 155.622L48.5671 165.4L48.6631 165.424L48.7571 165.395L111.433 145.462L111.294 144.786L9.9621 154.94Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.9169 164.84L48.8118 164.716L48.6498 164.717L35.8049 164.788L35.6738 165.456L55.4949 173.637L55.8918 173.093L48.9169 164.84ZM200.137 62.7909L199.709 62.9039L199.921 63.2919L205.112 72.8029L205.46 73.4409L205.737 72.7689L210.759 60.5659L211.024 59.9199L210.35 60.0979L200.137 62.7909ZM208.78 103.519L208.512 103.043L208.195 103.489L199.174 116.183L198.858 116.27L198.973 116.466L198.537 117.08L199.221 116.89L204.158 125.312L193.555 137.592L193.201 137.678L193.317 137.867L192.773 138.497L193.581 138.299L198.713 146.692L198.963 147.1L199.273 146.736L209.849 134.318L210.193 134.234L210.082 134.045L210.618 133.415L209.826 133.609L204.995 125.367L213.701 112.87L214.008 112.785L213.897 112.589L214.323 111.978L213.657 112.163L208.78 103.519ZM99.1168 183.981L98.9808 183.809L98.7678 183.858L83.4559 187.386L83.4628 188.064L105.162 192.573L106.101 192.768L105.504 192.018L99.1168 183.981Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.5347 125.48L86.3937 125.371L86.2238 125.421L76.5847 128.29L75.7197 128.547L76.5347 128.935L111.179 145.445L111.541 144.857L86.5347 125.48Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M142.817 187.69L121.14 186.613L121.087 186.543L120.926 186.597L98.9023 183.854L77.1063 179.355L70.4053 171.2L70.2833 171.052L70.0923 171.077L55.5823 172.973L55.5283 173.649L76.7153 179.972L83.2664 187.944L83.4023 188.11L83.6113 188.062L98.7114 184.583L104.961 192.449L105.113 192.64L105.268 192.588L126.901 195.286L126.978 195.387L127.14 195.316L127.866 195.407L127.631 195.099L142.939 188.353L142.817 187.69ZM205.721 72.47L205.425 71.929L205.116 72.462L197.862 84.981L197.569 85.0599L197.691 85.2759L197.368 85.8329L197.921 85.683L203.045 94.765L203.347 95.301L203.649 94.765L210.715 82.218L210.999 82.1409L210.88 81.924L211.192 81.3699L210.657 81.515L205.721 72.47Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.5119 121.651L96.3569 121.413L96.0929 121.518L86.1949 125.431L85.6289 125.655L86.1099 126.028L111.116 145.406L111.62 144.943L96.5119 121.651Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7507 164.731L47.6387 164.402L48.3867 165.288L55.3627 173.541L55.4847 173.685L55.6717 173.661L70.1816 171.764L70.2347 171.088L48.7507 164.731ZM180.779 149.871L180.139 150.485L181.059 150.309L186.389 158.641L186.618 158.999L186.922 158.703L199.176 146.832L199.566 146.757L199.45 146.566L200.085 145.951L199.18 146.124L194.041 137.721L193.816 137.353L193.505 137.652L181.058 149.602L180.657 149.679L180.779 149.871ZM203.649 94.4241L203.384 93.9541L203.066 94.3911L193.853 107.095L193.528 107.178L193.646 107.38L193.214 107.976L193.893 107.801L199.086 116.659L199.358 117.122L199.668 116.685L208.688 103.994L209.002 103.912L208.889 103.711L209.311 103.117L208.65 103.287L203.649 94.4241Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M106.524 116.913L106.442 116.463L106.03 116.664L96.0679 121.529L95.7158 121.7L95.9299 122.029L111.037 145.32L111.67 145.069L106.524 116.913ZM192.396 75.572L193.002 75.909L200.524 63.304L200.536 63.284L200.545 63.263L205.747 51.138L205.12 50.842L192.396 75.572Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M136.736 179.528L136.137 179.539L136.279 179.732L120.82 186.634L120.944 187.297L142.617 188.374L142.7 188.487L142.881 188.387L143.51 188.419L143.312 188.148L158.39 179.792L158.216 179.142L136.986 179.524L136.927 179.443L136.736 179.528ZM187.922 129.046L187.384 129.661L188.186 129.477L193.449 138.083L193.697 138.488L194.007 138.129L204.772 125.663L205.121 125.583L205.008 125.389L205.538 124.775L204.754 124.955L199.685 116.308L199.441 115.893L199.124 116.255L188.164 128.77L187.804 128.853L187.922 129.046ZM92.574 175.747L92.437 175.573L92.222 175.625L76.835 179.333L76.8459 180.01L98.776 184.536L99.713 184.729L99.118 183.98L92.574 175.747Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M116.352 111.319L116.449 110.615L115.834 110.972L106.008 116.676L105.798 116.798L105.841 117.038L110.987 145.194L111.672 145.179L116.352 111.319Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M114.961 178.535L114.809 178.337L114.573 178.418L98.7331 183.868L98.8031 184.54L120.919 187.295L121.74 187.397L121.237 186.74L114.961 178.535ZM167.279 160.771L166.73 160.846L167.038 161.307L172.355 169.243L152.37 170.84L151.765 170.888L152.113 171.385L157.938 179.688L158.134 179.967L158.416 179.776L172.878 170.024L172.895 170.05L173.179 169.821L174.261 169.091L174.062 169.107L186.899 158.724L187.867 157.941L186.634 158.111L167.279 160.771ZM70.2071 171.081L69.2471 170.884L69.8691 171.641L76.6481 179.89L76.7861 180.058L76.9971 180.007L92.3841 176.3L92.3731 175.623L70.2071 171.081ZM200.53 62.96L200.239 62.427L199.928 62.948L192.406 75.553L191.982 76.263L192.786 76.068L205.498 72.973L205.937 72.867L205.721 72.47L200.53 62.96Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M115.814 110.984L115.686 111.07L115.664 111.224L110.985 145.084L110.844 146.107L111.574 145.377L205.674 51.2459L205.235 50.7129L115.814 110.984Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M193.917 137.519L188.781 129.119L199.571 116.799L199.927 116.722L199.811 116.524L200.336 115.925L199.558 116.092L194.474 107.42L203.552 94.903L203.876 94.8259L203.759 94.618L204.177 94.0419L203.521 94.197L198.49 85.28L205.716 72.8099L206.124 72.1069L205.334 72.299L192.622 75.394L192.177 75.502L192.402 75.9009L197.678 85.2529L188.399 97.782L188.066 97.861L188.188 98.0679L187.759 98.647L188.433 98.4869L193.634 107.358L182.64 119.729L182.273 119.808L182.394 120.006L181.861 120.606L182.657 120.435L187.909 129.025L175.469 140.859L175.062 140.933L175.187 141.128L174.554 141.73L175.466 141.565L180.638 149.65L161.496 152.134L160.941 152.206L161.253 152.671L166.707 160.813L146.402 162.331L145.794 162.376L146.144 162.876L151.737 170.849L130.852 171.161L130.791 171.078L130.6 171.164L130 171.173L130.145 171.37L114.794 178.317L108.53 170.127L108.376 169.927L108.139 170.011L92.4207 175.553L85.8687 167.309L85.7297 167.133L85.5897 167.169L63.3807 162.652L63.3347 162.596L63.2007 162.616L62.3027 162.433L62.5317 162.712L48.6027 164.721L48.5537 165.397L69.9367 171.723L70.0087 171.811L70.1297 171.78L71.1227 172.074L71.0267 171.957L92.1097 176.277L98.5747 184.412L98.7267 184.604L98.9587 184.524L114.562 179.156L120.686 187.162L120.853 187.38L121.103 187.268L136.698 180.305L136.714 180.326L136.894 180.225L137.524 180.258L137.488 180.209L158.228 179.836L158.881 179.824L158.506 179.29L153.03 171.483L173 169.888L173.59 169.841L173.26 169.349L167.924 161.382L186.728 158.798L187.265 158.724L186.973 158.267L181.684 149.999L182.402 149.421L182.225 149.444L193.91 138.225L194.305 138.153L194.186 137.959L194.815 137.356L193.917 137.519Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.582 164.724L48.702 165.407L63.2401 163.311L63.26 163.308L63.2791 163.303L78.813 159.21L78.6541 158.535L48.582 164.724ZM171.06 101.082L171.613 101.499L192.97 75.9529L192.995 75.9239L193.013 75.8899L205.737 51.1589L205.141 50.8059L171.06 101.082Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M188.525 128.701L183.254 120.083L194.242 107.717L194.606 107.645L194.487 107.442L195.007 106.857L194.234 107.011L189.018 98.1121L198.292 85.5901L198.624 85.5181L198.503 85.3041L198.918 84.7441L198.267 84.8851L193.006 75.5611L192.747 75.1001L192.428 75.5221L182.809 88.2341L182.466 88.3091L182.591 88.5221L182.166 89.0831L182.836 88.938L188.175 98.0461L176.977 110.461L176.603 110.536L176.727 110.738L176.199 111.324L176.988 111.166L182.381 119.985L169.74 131.897L169.327 131.968L169.455 132.166L168.829 132.756L169.733 132.602L175.044 140.905L155.568 143.279L155.007 143.348L155.322 143.817L160.919 152.173L140.285 153.607L139.672 153.65L140.025 154.153L145.767 162.337L124.563 162.588L124.502 162.504L124.312 162.591L123.71 162.598L123.858 162.799L108.361 169.907L101.936 161.508L101.782 161.306L101.543 161.392L85.7141 167.114L78.996 158.659L78.855 158.482L78.636 158.54L63.1021 162.632L63.122 163.308L85.405 167.839L92.031 176.178L92.1851 176.372L92.418 176.29L108.132 170.749L114.41 178.957L114.578 179.176L114.829 179.062L130.564 171.942L130.579 171.963L130.76 171.862L131.39 171.895L131.355 171.847L152.402 171.533L153.057 171.523L152.681 170.987L147.063 162.977L167.352 161.46L167.946 161.416L167.615 160.921L162.141 152.75L181.269 150.268L181.811 150.197L181.517 149.737L176.093 141.258L176.801 140.69L176.632 140.711L188.514 129.407L188.915 129.339L188.794 129.141L189.415 128.55L188.525 128.701Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M205.183 50.7559L111.143 144.827L48.5469 164.733L48.7219 165.404L78.7938 159.215L78.8278 159.208L78.8588 159.194L111.463 145.451L111.385 145.238L111.533 145.412L171.552 101.557L171.601 101.521L171.634 101.471L205.716 51.1948L205.183 50.7559Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M169.441 132.145L155.404 143.344L140.126 153.658L124.307 162.585L101.691 161.372L78.7668 158.531L77.9238 158.426L78.4518 159.091L85.3259 167.74L85.4808 167.935L85.7148 167.851L101.54 162.131L107.978 170.548L108.147 170.768L108.398 170.653L124.467 163.283L146.432 163.023L147.09 163.015L146.712 162.477L140.945 154.257L161.565 152.823L162.162 152.782L161.829 152.284L156.212 143.9L175.675 141.526L176.222 141.46L175.925 140.995L170.349 132.277L182.984 120.37L183.39 120.305L183.267 120.103L183.881 119.525L182.999 119.665L177.454 110.597L177.232 110.235L176.921 110.525L163.866 122.708L163.447 122.774L163.577 122.977L162.958 123.555L163.855 123.412L169.441 132.145Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M171.081 101.054L171.603 101.511L183.263 88.7798L183.274 88.7678L183.284 88.7548L192.981 75.9398L192.438 75.5078L171.081 101.054Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M133.531 144.895L117.859 153.795L95.078 152.542L95.02 152.465L94.847 152.529L94.1671 152.492L94.3391 152.717L78.604 158.549L78.681 159.219L101.474 162.044L101.554 162.15L101.717 162.075L102.439 162.164L102.393 162.105L124.207 163.275L124.292 163.39L124.472 163.289L125.102 163.323L124.9 163.047L140.206 154.41L140.221 154.431L140.416 154.299L140.972 154.294L140.796 154.043L155.515 144.106L155.531 144.13L155.741 143.963L156.233 143.931L156.09 143.718L156.864 143.196L156.688 143.207L170.04 132.555L170.494 132.503L170.362 132.298L171.061 131.74L170.077 131.852L164.317 122.847L164.11 122.523L163.809 122.762L149.39 134.211L148.923 134.264L149.047 134.45L133.918 144.668L133.395 144.702L133.531 144.895ZM170.915 101.235L170.392 101.806L171.176 101.661L176.862 110.959L177.104 111.356L177.415 111.011L188.781 98.4099L189.152 98.3408L189.03 98.1339L189.545 97.5628L188.779 97.7048L183.307 88.3708L183.069 87.9648L182.752 88.3118L171.17 100.956L170.788 101.027L170.915 101.235Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.5889 158.555L78.8448 159.2L95.0198 153.205L95.0339 153.2L95.0479 153.193L111.476 145.445L111.194 144.812L78.5889 158.555Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M157.536 113.533L143.153 124.897L142.681 124.947L142.822 125.158L142.133 125.702L143.117 125.598L148.902 134.234L127.77 135.475L127.7 135.375L127.496 135.491L126.956 135.523L127.095 135.72L111.236 144.785L110.647 144.788L110.8 144.997L94.752 152.566L94.881 153.226L117.759 154.484L117.844 154.601L118.024 154.499L118.655 154.534L118.452 154.256L133.931 145.466L133.946 145.486L134.141 145.355L134.699 145.351L134.537 145.121L135.36 144.654L135.177 144.655L149.433 135.027L149.449 135.05L149.733 134.824L150.77 134.124L150.603 134.134L163.94 123.544L163.957 123.571L164.177 123.366L164.62 123.32L164.486 123.111L165.176 122.563L165.02 122.58L177.315 111.106L177.727 111.045L177.601 110.839L178.208 110.272L177.334 110.402L171.643 101.096L171.423 100.735L171.112 101.022L157.841 113.283L157.416 113.345L157.536 113.533Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M111.124 144.851L111.512 145.426L143.472 125.525L143.495 125.512L143.509 125.499L158.213 113.882L158.223 113.874L158.233 113.865L171.583 101.531L171.426 101.354L171.143 100.996L111.124 144.851Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.576 125.038L143.383 124.749L143.094 124.944L127.402 135.545L126.355 136.253L127.617 136.179L149.547 134.891L150.152 134.855L149.815 134.351L143.576 125.038Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M111.145 144.837L111.501 145.433L127.77 136.134L127.781 136.127L127.792 136.12L143.483 125.519L143.106 124.937L111.145 144.837Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default BottomRightGroup;
