import React, { type ChangeEvent, type FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

export interface IInputProps {
  label: string
  placeholder: string
  type: string
  value: string
  setValue: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
  error?: boolean
  errorMessage?: string
}

const Input: FC<IInputProps> = ({
  label,
  placeholder,
  type,
  value,
  setValue,
  error,
  errorMessage
}) => {
  const { p: P } = motion;
  return (
    <div className="gap flex flex-col">
      <label className="after:ml-1 after:text-red-700 after:content-['*']">
        {label}
      </label>
      <input
        {...{ type, placeholder, value }}
        onChange={setValue}
        className="rounded border border-dark px-4 py-3 focus:outline-none"
      />
      <AnimatePresence>
        {error && (
          <P
            initial={{
              scale: 0.5,
              opacity: 0
            }}
            animate={{
              scale: 1,
              opacity: 1
            }}
            exit={{
              scale: 0,
              opacity: 0
            }}
            transition={{
              type: 'spring',
              stiffness: 500,
              damping: 30,
              duration: 0.5
            }}
            className="text-sm text-red-700"
          >
            {errorMessage}
          </P>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Input;
