import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import type { IconProps, Props, PropTypes } from '../../typescript';

const FeaturesTopLeftMobile: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <motion.svg
      initial={{ scale: 1.1, rotate: 12 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 1 }
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
      width="59"
      height="88"
      viewBox="0 0 59 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2031 13.183L23.1983 13.18L23.1936 13.1774L18.5765 10.6101L18.5735 10.6084L18.5701 10.6067L13.8556 8.2831L13.8525 8.28138L13.8075 8.36935L13.8452 8.27795L9.04748 6.21526L9.04362 6.21355L9.03976 6.21226L4.18021 4.4375L4.07422 4.71556L13.7208 8.54871L31.8949 19.2372L40.0787 25.8728L40.2692 25.644L36.2691 22.2207L36.2661 22.2181L36.2631 22.2155L32.0708 18.9964L32.0095 19.0741L32.0644 18.9921L27.7069 15.9811L27.7039 15.979L27.7005 15.9773L23.2031 13.183Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3005 30.8299L13.2142 30.4853L13.0293 30.7882L11.3961 33.4594L10.1569 29.119L10.0625 28.7891L9.88612 29.0834L8.09978 32.0635L8.01953 32.197L8.15641 32.2712L11.2713 33.955L14.2822 35.8293L17.174 37.8864L17.4846 38.1074L17.4057 37.7341L16.3466 32.7299L16.2703 32.3686L16.0746 32.6823L14.4243 35.329L13.3005 30.8299Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.3648 33.3319L47.5716 33.1178L40.2791 25.6541L40.2731 25.648L40.2662 25.6425L32.0738 19L31.8799 19.2257L47.3648 33.3319Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.3275 32.8393L16.4065 32.7127L16.2799 32.6342L13.3603 30.8169L13.365 30.8096L13.2311 30.7367L12.923 30.5449L12.9299 30.5732L10.085 29.0284L9.77344 28.8594L9.87084 29.2001L11.2032 33.867L11.2963 34.1931L11.4735 33.9034L13.0981 31.246L14.2206 35.7413L14.3056 36.0816L14.4914 35.7838L16.3275 32.8393ZM19.272 34.8067L19.2072 34.4257L18.9999 34.7518L17.1346 37.6847L17.0638 37.7967L17.1668 37.8799L19.9139 40.1091L20.2212 40.3584L20.1542 39.9688L19.272 34.8067Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.9189 19.2509L32.0558 18.987L13.8624 8.28736L13.8487 8.27921L13.8332 8.27406L-5.65305 1.88477L-5.76074 2.16196L31.9189 19.2509Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.73616 27.4652L6.55079 27.3785L6.55938 27.4047L3.64277 26.2628L3.55781 26.0212L3.45998 26.1912L3.27718 26.1195L3.36214 26.3615L1.64918 29.3343L1.5625 29.4849L1.7247 29.5476L4.91334 30.7757L4.99701 31.013L5.09098 30.8529L8.10584 32.2471L8.18609 32.4985L8.2865 32.3307L8.47229 32.4165L8.38991 32.1582L10.0681 29.3579L13.0813 30.9941L16.0686 32.8534L17.1148 37.7953L17.1903 38.1519L17.386 37.8442L19.0436 35.237L19.8512 39.9614L19.8147 40.0176L19.8696 40.0682L19.925 40.3944L20.0361 40.2232L22.479 42.4927L22.7798 42.772L22.7279 42.3648L22.0585 37.1032L22.1018 37.0362L22.0439 36.9899L22.0001 36.6457L21.8688 36.8487L19.3225 34.7989L19.3268 34.7916L19.259 34.744L18.215 29.7342L18.1395 29.3708L17.943 29.6858L16.2618 32.3822L15.1422 27.8179L15.0573 27.4707L14.8706 27.7758L13.2074 30.4959L11.9746 26.0869L11.8815 25.7539L11.7034 26.0504L9.95526 28.9674L7.00735 27.5913L7.01292 27.5823L6.91639 27.5445L6.83657 27.2944L6.73616 27.4652Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.359 33.3257L47.5752 33.121L44.0588 29.2849L44.0557 29.2814L44.0523 29.2784L40.2758 25.6504L40.0664 25.8619L47.359 33.3257Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.1954 29.8441L18.2744 29.718L18.1478 29.639L15.0759 27.7278L14.7656 27.5352L14.8527 27.89L16.0564 32.7968L16.1405 33.1396L16.3271 32.8401L18.1954 29.8441Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.7402 8.55594L13.8414 8.27617L4.18413 4.43872L4.17554 4.43529L4.16653 4.43272L-5.66243 1.88086L-5.74609 2.16664L13.7402 8.55594Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.9454 28.225L15.0471 28.0589L17.9371 29.8573L18.9678 34.8027L18.9288 34.864L18.991 34.9138L19.0549 35.2206L19.1622 35.0515L21.7643 37.1464L22.4264 42.3475L22.392 42.3995L22.4392 42.4501L22.4839 42.8007L22.601 42.6234L24.8057 44.9916L24.8358 45.3645L24.9688 45.1667L25.1323 45.3426L25.1022 44.968L27.0401 42.0879L27.1066 41.9888L27.0246 41.9026L24.7002 39.4451L24.7053 39.4378L24.6547 39.3915L24.6251 39.019L24.6083 39.0439L24.0278 34.4362L24.0449 34.45L23.9904 34.1238L24.0325 34.0595L23.9741 34.0127L23.9308 33.6686L23.9179 33.6887L23.1013 28.7863L23.0374 28.4031L22.8289 28.7309L21.1056 31.4407L21.0953 31.457L20.1285 26.7442L20.1762 26.6682L20.1037 26.6236L20.0419 26.3211L19.923 26.5112L17.0759 24.7403L17.0802 24.733L17.0013 24.6897L16.9326 24.4043L16.8232 24.5832L16.6421 24.4704L16.6485 24.4966L13.8079 22.9415L13.7324 22.666L13.6268 22.8424L13.4449 22.7424L13.5204 23.0196L11.7774 25.928L11.5933 25.8276L11.6006 25.8538L8.80373 24.5352L8.80889 24.5262L8.71363 24.4884L8.63468 24.2352L8.53298 24.4077L8.34975 24.3215L8.35748 24.3468L5.42157 23.1784L5.3379 22.9329L5.23835 23.1054L5.0577 23.0333L5.14051 23.2753L3.4563 26.1988L0.336305 25.2179L0.249209 24.9896L0.152659 25.1599L-0.0258529 25.1037L0.059108 25.326L-1.63754 28.3288L-1.72852 28.4902L-1.55173 28.5447L1.66567 29.5295L1.75148 29.7543L1.84889 29.5857L2.02654 29.6402L1.94329 29.4218L3.5906 26.5618L6.67798 27.7706L6.76079 28.0122L6.85648 27.8491L9.89194 29.2664L9.97132 29.5209L10.073 29.351L10.2567 29.4368L10.1764 29.1785L11.7817 26.5005L13.0136 30.9056L13.1055 31.2347L13.284 30.9429L14.9454 28.225Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.0048 19.2619L32.4884 19.1821L32.0417 18.9795L-5.63801 1.89062L-5.77832 2.15238L23.7952 20.5826L23.8429 20.6122L23.8982 20.6028L32.0048 19.2619Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.5625 21.4459L10.4638 21.1211L10.2918 21.4137L8.46423 24.5243L8.38184 24.665L8.52902 24.7345L11.7683 26.2608L12.0717 26.4042L11.9738 26.0832L10.5625 21.4459ZM23.0798 28.8899L23.1562 28.7702L23.0399 28.6883L20.0564 26.5934L19.7496 26.3784L19.8251 26.7453L20.8777 31.8765L20.9515 32.2374L21.1489 31.9267L23.0798 28.8899ZM26.5993 36.5691L26.6697 36.4627L26.5752 36.3769L23.9341 33.9808L23.6359 33.71L23.6866 34.1095L24.3632 39.4797L24.4139 39.8783L24.6353 39.5428L26.5993 36.5691Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.0648 19.2384L31.9566 18.9685L23.8501 20.3094L23.4888 20.3691L23.7896 20.5784L26.8345 22.6973L26.919 22.7561L27.0036 22.6982L32.0648 19.2384ZM4.0922 4.72236L4.17202 4.43529L-0.730456 2.98021L-0.73517 2.97892L-0.740316 2.97764L-5.66682 1.88086L-5.73633 2.1705L4.0922 4.72236Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.8082 23.1183L13.8923 22.978L13.7443 22.9076L10.484 21.356L10.1793 21.2109L10.2776 21.5336L11.6889 26.1709L11.7863 26.4914L11.9588 26.2039L13.8082 23.1183ZM27.0764 42.3913L27.0983 42.4141L27.0648 41.997L27.0511 41.5378L27.0305 41.5683L26.6233 36.4762L26.5889 36.0458L26.3508 36.4062L24.3864 39.3799L24.3211 39.4786L24.4027 39.564L26.7262 42.021L24.8258 44.8453L24.7653 44.9359L24.8326 45.0208L26.9657 47.7147L27.2446 48.0666L27.2313 47.6177L27.0764 42.3913ZM0.292847 25.5165L3.48062 26.5189L3.77027 26.6099L3.66471 26.3254L2.12423 22.1687L2.01268 21.8679L1.85476 22.1472L0.147806 25.1685L-3.05542 24.4215L-3.33691 24.3558L-3.22663 24.6231L-1.64626 28.4593L-1.52954 28.7425L-1.37892 28.476L0.292847 25.5165Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.1196 19.1677L32.2852 18.7266L31.8964 18.9926L26.8352 22.4524L26.667 22.5674L26.8279 22.6927L29.7389 24.9584L29.8985 25.0828L29.9698 24.8936L32.1196 19.1677Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.95133 24.5096L-3.06933 24.2234L-3.21908 24.4941L-4.93763 27.594L-5.03418 27.7682L-4.8398 27.8115L-1.54088 28.5474L-1.26197 28.6101L-1.37053 28.3457L-2.95133 24.5096ZM25.9374 30.9834L25.8945 30.6388L25.7615 30.8439L23.089 28.7281L22.051 23.5844L21.9768 23.2171L21.779 23.5351L20.0334 26.3341L18.9233 21.6355L18.8401 21.2836L18.6517 21.5921L16.9259 24.4152L15.7064 19.8629L15.6158 19.5234L15.4352 19.8251L13.5531 22.9652L13.4733 23.0978L13.6093 23.1721L16.7967 24.9172L19.8884 26.8404L22.8174 28.8971L23.6773 34.0592L23.64 34.1167L23.6953 34.1669L23.7498 34.4943L23.8635 34.3197L26.3751 36.5982L26.6724 36.8681L26.6227 36.4694L25.952 31.098L25.9957 31.0297L25.9374 30.9834ZM3.65423 26.4508L5.36763 23.4772L8.47302 24.7134L8.55455 24.9584L8.65582 24.7859L8.83475 24.8571L8.75495 24.6177L10.5481 21.5651L10.6357 21.4166L10.4761 21.3518L7.2312 20.0388L7.14839 19.7895L7.04756 19.9646L6.86861 19.892L6.94972 20.1349L5.23374 23.1129L2.03051 22.0783L1.73873 21.9843L1.84515 22.2718L3.38562 26.4285L3.49589 26.7259L3.65423 26.4508Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.129 19.1041L31.8415 19.0625L29.6913 24.7884L29.6553 24.885L29.7329 24.9527L32.483 27.3561L32.7564 27.5947L32.7293 27.233L32.129 19.1041Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.9875 23.4898L21.6858 23.2791L21.6914 23.3057L18.9829 21.6215L18.9872 21.6146L18.8542 21.5413L18.5512 21.3529L18.5568 21.3782L15.7702 19.8455L15.7745 19.8382L15.6312 19.7691L15.3291 19.603L15.3356 19.627L12.4044 18.2183L12.3259 17.9531L12.2216 18.1308L12.0422 18.0445L12.1195 18.3046L10.2919 21.4147L10.21 21.5546L10.3563 21.6245L13.5569 23.1478L13.6346 23.4087L13.7393 23.2345L13.9187 23.3199L13.8423 23.0624L15.5102 20.2793L16.728 24.8273L16.8181 25.1624L16.9988 24.8668L18.7173 22.0562L19.8261 26.7505L19.908 27.0981L20.0973 26.7951L21.8334 24.0103L22.7967 28.7819L22.7568 28.8445L22.8191 28.8939L22.8817 29.2028L22.9916 29.0303L25.6575 31.1415L26.3209 36.452L26.2857 36.5053L26.3312 36.5529L26.7642 41.9651L26.7316 42.0132L26.7693 42.0595L26.9341 47.6267L26.9479 48.0832L27.2057 47.7061L29.0985 44.9418L29.2508 45.1302L29.2388 44.7371L29.274 44.6856L29.2358 44.6384L29.0788 39.4115L29.0998 39.4334L29.0706 39.0696L29.1088 39.0133L29.065 38.9679L29.053 38.5628L29.0328 38.5928L28.6269 33.5011L28.6694 33.4363L28.6183 33.3904L28.5887 33.0188L28.4454 33.2355L25.936 30.9866L25.0757 25.7529L25.1216 25.6804L25.0564 25.6349L25.0032 25.3118L24.8774 25.5096L22.1063 23.5731L22.1106 23.5662L21.9875 23.4898Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.1234 19.0723L31.832 19.1255L32.4323 27.2548L32.4366 27.3101L32.4761 27.3496L35.037 29.883L35.2846 29.735L32.1234 19.0723Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.05402 22.398L5.18687 23.4094L5.27055 23.6433L5.37097 23.469L5.54475 23.5253L5.4658 23.3051L7.17532 20.337L10.2992 21.6007L10.3798 21.85L10.4828 21.675L10.6592 21.7466L10.5815 21.5068L12.3387 18.5164L15.4939 20.0328L18.6503 21.7685L18.7224 22.0449L18.8267 21.8745L21.7733 23.7068L21.8407 23.9968L21.9479 23.8248L24.7817 25.8059L25.6408 31.0289L25.6026 31.0873L25.6584 31.1375L25.7124 31.4653L25.827 31.2885L28.33 33.5319L28.7685 39.0368L28.9376 44.6477L26.9594 47.5368L26.9028 47.6192L26.9577 47.7029L28.7621 50.453L29.0187 50.8443L29.0354 50.3766L29.2349 44.7404L31.2521 41.7942L31.3156 41.7015L31.2444 41.6152L29.1028 39.0196L31.0783 36.0829L31.1457 35.9825L31.0612 35.8958L28.6638 33.4426L30.6329 30.4621L30.7042 30.354L30.6072 30.2686L27.9901 27.9519L27.9944 27.9454L27.9356 27.8991L27.8931 27.5545L27.8807 27.5734L27.0748 22.6061L27.1212 22.5332L27.0555 22.4877L27.0032 22.1642L26.8766 22.3633L24.075 20.4139L24.0789 20.4074L24.0072 20.3628L23.9463 20.0573L23.83 20.2435L23.6558 20.1221L23.6609 20.1474L20.9228 18.4456L20.9271 18.4391L20.849 18.3958L20.7817 18.1062L20.6701 18.2885L20.4929 18.1787L20.4985 18.2023L17.6059 16.6047L17.5321 16.3241L17.4239 16.5043L17.2459 16.4061L17.318 16.6811L15.6101 19.5308L14.3164 15.0587L14.2203 14.7266L14.0448 15.0248L12.2168 18.1362L9.0728 16.8425L8.99085 16.5889L8.88873 16.7665L8.71194 16.694L8.79046 16.9369L7.04274 19.9706L3.89314 18.9266L3.80775 18.6889L3.7082 18.8652L3.53399 18.8077L3.61423 19.0313L1.90984 22.0479L-1.23031 21.2832L-1.31785 21.0631L-1.41525 21.2386L-1.58517 21.197L-1.50451 21.3995L-3.16984 24.4032L-6.28467 23.9518L-6.3735 23.7505L-6.46876 23.9252L-6.63224 23.9016L-6.55329 24.0805L-8.22721 27.1563L-8.33105 27.3464L-8.1165 27.3751L-4.9107 27.8017L-4.82316 27.9995L-4.72704 27.8261L-4.56484 27.848L-4.64208 27.6729L-3.01365 24.7357L0.112776 25.4648L0.19902 25.6815L0.296854 25.5077L0.465924 25.5472L0.386974 25.3485L2.05402 22.398Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.6444 18.6241L17.4068 16.8356L17.4029 16.8335L17.3991 16.8313L-5.77746 2.1527L-5.77832 2.15227L-5.62341 1.90554V1.90511L20.6444 18.6241ZM20.6444 18.6241L-5.62341 1.90511L20.6444 18.6241ZM20.6444 18.6241L23.7961 20.583L23.9532 20.3302V20.3298L-5.61654 1.90254L-5.61912 1.90082L20.6444 18.6241ZM-5.62341 1.90511L-5.61997 1.90039L-5.62341 1.90511ZM32.0812 19.0056L31.9717 19.1107L31.8383 19.1579L34.9995 29.8206L35.0089 29.852L35.0304 29.8768L37.3698 32.5343L37.6191 32.3793L32.4858 19.9479L56.5999 48.6555L56.8411 48.483L47.5935 33.1449L47.5824 33.1264L47.5665 33.1118L32.0812 19.0056Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.606 16.7809L17.6897 16.6414L17.5434 16.5706L14.2389 14.9666L13.9381 14.8203L14.0307 15.1417L15.4197 19.942L15.5146 20.2703L15.6905 19.9772L17.606 16.7809ZM31.0553 47.5169L31.1136 47.4332L31.0566 47.3486L29.2119 44.6089L28.9561 44.2287L28.9398 44.687L28.7386 50.3665L28.7206 50.8716L29.0093 50.4566L31.0553 47.5169ZM31.1158 36.3774L31.1364 36.3985L31.1042 35.9921L31.09 35.5334L31.0699 35.563L30.6575 30.3683L30.6232 29.9371L30.385 30.2979L28.3498 33.3789L28.2837 33.4789L28.3678 33.5647L30.7644 36.0174L28.7944 38.9456L28.7326 39.0378L28.8029 39.1237L31.0154 41.8047L31.2922 42.1407L31.2789 41.7056L31.1158 36.3774ZM3.84735 19.225L7.06517 20.2918L7.35138 20.3866L7.25226 20.1017L5.7444 15.7652L5.63627 15.4541L5.47406 15.7407L3.70446 18.8732L0.552721 18.0725L0.466045 17.8463L0.366928 18.0253L0.199573 17.9828L0.277672 18.1862L-1.46962 21.3379L-1.56445 21.5087L-1.3748 21.5546L1.87392 22.3454L1.95888 22.5677L2.05887 22.3905L2.22536 22.4313L2.14897 22.2317L3.84735 19.225Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.61949 1.90039L-5.78125 2.15056L-5.7791 2.15184L14.0921 15.2252L14.1002 15.2308L14.1088 15.2351L17.4133 16.8391L17.5579 16.5795L-5.61734 1.90168L-5.61949 1.90039Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.9703 24.7619L29.9278 24.4169L29.7935 24.6242L27.0104 22.4581L26.7113 22.2255L26.7718 22.5992L27.6394 27.9471L27.6008 28.0068L27.657 28.0561L27.7103 28.3848L27.8261 28.2063L30.4101 30.493L30.7041 30.7534L30.6564 30.3634L29.9845 24.8769L30.0291 24.8078L29.9703 24.7619ZM9.08174 17.03L9.17185 16.8729L9.00063 16.8146L5.65108 15.6744L5.3623 15.5762L5.4627 15.8641L6.97056 20.201L7.07784 20.5082L7.24004 20.2263L9.08174 17.03ZM31.2782 41.7169L31.2958 41.205L31.0066 41.6277L28.9649 44.6095L28.9079 44.6927L28.9645 44.7764L30.8092 47.5166L31.0658 47.8973L31.0813 47.4385L31.2782 41.7169Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.0519 41.1114L53.296 40.9407L50.7038 37.0625L50.7008 37.0577L50.697 37.053L47.5826 33.1289L47.3457 33.3091L53.0519 41.1114Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-1.2096 21.4823L-1.10618 21.2965L-1.31644 21.2635L-4.54198 20.7567L-4.63037 20.5491L-4.72735 20.7276L-4.8887 20.7022L-4.81188 20.8829L-6.51756 24.017L-6.62012 24.2046L-6.40813 24.2355L-3.19332 24.701L-3.10664 24.9053L-3.00838 24.7281L-2.84876 24.7512L-2.92341 24.5744L-1.2096 21.4823ZM10.7644 13.5123L10.5898 13.4393L10.6662 13.6826L8.82447 16.8803L8.73951 17.0274L8.89656 17.0922L12.159 18.4345L12.2388 18.6881L12.3431 18.5104L12.5173 18.5821L12.4422 18.3418L14.3024 15.1759L14.3886 15.0291L14.2316 14.9635L10.9494 13.5899L10.8683 13.332L10.7644 13.5123Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.61738 1.90234L-5.78516 2.14822L10.742 13.8232L10.7554 13.8326L10.7704 13.8386L14.1165 15.2392L14.2555 14.9774L-5.61738 1.90234Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.0805 47.45L30.8105 47.3483L28.7646 50.288L28.7131 50.3618L28.7547 50.4417L30.16 53.1476L30.3849 53.5805L30.4402 53.0956L31.0805 47.45ZM33.1526 33.0013L33.2208 32.9001L33.1345 32.8134L30.614 30.2765L30.3282 29.9886L30.3604 30.3932L30.8063 36.0097L30.9809 41.7159L30.9946 42.172L31.2525 41.7957L33.3311 38.7598L33.395 38.6663L33.3221 38.5796L31.1409 35.9917L33.1526 33.0013ZM-6.25681 24.1601L-6.14311 23.9516L-6.3804 23.9404L-9.539 23.7924L-9.7793 23.7812L-9.68231 24.001L-8.23282 27.2891L-8.11052 27.5663L-7.96592 27.3003L-6.25681 24.1601Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.1148 19.0527L31.8428 19.1729L37.343 32.493L37.3503 32.5106L37.3614 32.5256L39.3795 35.2152L39.6335 35.0624L32.1148 19.0527Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.7243 27.173L32.6951 26.8022L32.5505 27.0211L29.9283 24.7292L29.6352 24.4735L29.6824 24.8597L30.3544 30.3453L30.3179 30.4006L30.3672 30.4504L30.4101 30.801L30.5324 30.6161L32.8376 32.9366L30.8315 35.9184L30.7689 36.0116L30.8414 36.0978L33.0946 38.7715L33.3705 39.0989L33.3572 38.6711L33.1899 33.2911L33.2096 33.3112L33.1783 32.91L33.1637 32.4517L33.1439 32.4809L32.7333 27.2845L32.7766 27.2184L32.7243 27.173ZM-9.40946 23.8813L-9.53261 23.6016L-9.67678 23.871L-11.3803 27.0541L-11.4932 27.2648L-11.2537 27.273L-8.10113 27.3781L-7.8647 27.3858L-7.95995 27.1691L-9.40946 23.8813ZM33.1384 44.5253L33.1976 44.4408L33.1388 44.3558L31.2525 41.6272L30.9967 41.2573L30.9813 41.7066L30.7843 47.4282L30.7672 47.9324L31.0551 47.5183L33.1384 44.5253Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.61349 1.9043L-5.79199 2.14202L7.36979 12.6404L7.38952 12.6562L7.41355 12.6644L10.7786 13.841L10.9137 13.5788L-5.61349 1.9043Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.67258 15.995L8.83547 17.0716L8.91744 17.3145L9.02085 17.1347L9.19034 17.1922L9.11568 16.9704L10.9565 13.7744L11.0462 13.6191L10.8767 13.5599L7.58036 12.4073L7.49712 12.1602L7.39412 12.3425L7.22464 12.2833L7.30059 12.5086L5.53055 15.641L2.36722 14.8025L2.28141 14.5708L2.18056 14.7532L2.01536 14.7094L2.09131 14.9145L0.362037 18.0337L-2.76867 17.5029L-2.85578 17.2883L-2.95533 17.4711L-3.11366 17.4441L-3.03986 17.6265L-4.73266 20.7366L-7.80202 20.5499L-8.04102 20.5353L-7.94834 20.7563L-6.52416 24.1458L-6.40443 24.4311L-6.25639 24.1591L-4.5666 21.0541L-1.44575 21.5441L-1.36036 21.7544L-1.25996 21.5733L-1.10248 21.5982L-1.175 21.4201L0.515655 18.3705L3.66525 19.1704L3.74979 19.3982L3.85148 19.218L4.01583 19.2596L3.94159 19.0588L5.67258 15.995ZM33.3572 38.6794L33.3744 38.1692L33.0856 38.5906L31.0071 41.6264L30.9491 41.711L31.0075 41.7951L32.835 44.4388L30.9496 47.1481L30.8389 46.9421L30.7895 47.3781L30.7586 47.4223L30.7801 47.4618L30.1446 53.0611L30.414 53.1637L32.3613 50.4062L32.4733 50.6144L32.5222 50.1784L32.5535 50.1342L32.5317 50.0943L33.1199 44.8507L33.15 44.894L33.165 44.4439L33.3572 38.6794ZM35.265 29.8605L35.3337 29.7584L35.2461 29.6717L32.6853 27.1379L32.4008 26.8564L32.4325 27.2555L32.8809 32.929L32.9148 33.3543L33.153 33.0002L35.265 29.8605Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.60719 1.91016L-5.80371 2.13286L3.99693 11.7031L4.02439 11.7297L4.06129 11.74L7.42243 12.6681L7.55502 12.4085L-5.60719 1.91016Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.4478 35.6683L35.5122 35.5739L35.4375 35.4868L33.1418 32.8208L32.8672 32.502L32.8805 32.9225L33.0594 38.6801L33.0736 39.1354L33.331 38.7595L35.4478 35.6683Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.1143 19.0488L31.8457 19.178L31.847 19.1801L39.3644 35.1877L39.3678 35.1954L39.3725 35.2027L40.9911 37.8258L41.2516 37.6821L32.1152 19.0514L32.1143 19.0488Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-7.67391 20.6414L-7.79492 20.3535L-7.94253 20.629L-9.67695 23.8704L-9.78809 24.0781L-9.55251 24.0892L-6.39434 24.2373L-6.15876 24.2484L-6.25016 24.0309L-7.67391 20.6414ZM35.2597 41.4768L35.3193 41.391L35.2588 41.3056L33.33 38.589L33.0743 38.2294L33.0597 38.6701L32.8674 44.4346L32.8507 44.9379L33.1382 44.5247L35.2597 41.4768ZM35.2901 29.7735L35.2755 29.3118L35.0181 29.695L32.9056 32.8347L32.8425 32.9287L32.9164 33.015L35.2125 35.681L35.4871 36.0002L35.4738 35.5793L35.2901 29.7735Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5188 50.1844L34.6248 47.2017L34.6788 47.1254L34.6334 47.0438L33.1465 44.3675L32.9204 43.9608L32.8685 44.4233L32.2351 50.0699L30.1712 52.9929L30.1252 53.0577L30.1536 53.1315L31.1551 55.7743L31.3409 56.2643L31.4404 55.7503L32.5188 50.1844ZM7.59234 12.5983L7.68589 12.4322L7.50224 12.3816L4.21533 11.4736L4.1308 11.2363L4.02781 11.4217L3.86518 11.3771L3.93856 11.583L2.17624 14.7622L-0.962194 14.1894L-1.04844 13.9684L-1.1497 14.1551L-1.30633 14.1267L-1.23424 14.3108L-3.006 17.5651L-3.10555 17.7483L-2.90001 17.7831L0.333263 18.3315L0.417792 18.5482L0.520353 18.3632L0.675255 18.3894L0.605314 18.2096L2.32858 15.1012L5.48976 15.9388L5.573 16.1723L5.67685 15.9886L5.83861 16.0315L5.76652 15.829L7.59234 12.5983ZM-9.41417 24.0111L-9.28758 23.7746L-9.55535 23.7922L-12.3458 23.978L-12.4252 23.7789L-12.5367 23.9905L-12.6582 23.9986L-12.6075 24.1248L-14.2767 27.2911L-14.4033 27.531L-14.133 27.5086L-11.3464 27.2812L-11.2683 27.476L-11.1559 27.2653L-11.0357 27.2559L-11.0851 27.1328L-9.41417 24.0111ZM35.474 35.589L35.4903 35.0793L35.2023 35.5002L33.086 38.5914L33.0272 38.6768L33.0873 38.7618L35.0161 41.478L35.2723 41.8384L35.2864 41.3969L35.474 35.589Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.5959 1.91992L-5.82031 2.11387L0.646642 11.031L0.680978 11.0786L0.738478 11.0898L4.07215 11.7424L4.56174 11.8381L4.20475 11.4897L-5.5959 1.91992Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.2854 41.4083L35.0151 41.3066L32.894 44.3545L32.8408 44.4309L32.8859 44.512L34.3727 47.1879L34.5993 47.5959L34.6508 47.1321L35.2854 41.4083Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.6285 48.6819L56.8271 48.4639L32.0943 19.0195L31.8467 19.1809L40.9839 37.8141L41.0011 37.8489L41.0328 37.8707L56.6285 48.6819Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.74472 17.7063L-2.63444 17.5042L-2.86401 17.4866L-6.03334 17.2485L-6.27148 17.2305L-6.18266 17.4519L-4.78552 20.946L-4.66794 21.24L-4.51647 20.9619L-2.74472 17.7063ZM37.6035 32.5189L37.6688 32.4232L37.5924 32.3365L35.2534 29.6787L34.9796 29.368L34.9925 29.7821L35.1761 35.5878L35.1907 36.0426L35.4478 35.6672L37.6035 32.5189Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.5854 48.6353L56.8476 48.495L53.3062 40.9596L53.3002 40.9463L53.2921 40.9347L47.5855 33.1328L47.3379 33.2972L56.5854 48.6353Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.90669 17.3425L-6.02554 17.0456L-6.17616 17.3275L-7.90328 20.5548L-10.6105 20.6921L-10.689 20.484L-10.8036 20.702L-10.9224 20.708L-10.8744 20.8358L-12.5762 24.064L-12.7002 24.2995L-12.4346 24.2819L-9.64629 24.0966L-9.56947 24.3L-9.45362 24.0837L-9.33604 24.0755L-9.38282 23.9511L-7.7252 20.8534L-4.65671 21.0401L-4.42199 21.0543L-4.50953 20.8363L-5.90669 17.3425ZM33.3817 53.0574L33.4692 53.2779L33.5593 52.8089L33.5877 52.7694L33.5735 52.7343L34.6428 47.1753L36.7883 44.1372L36.8428 44.0596L36.7956 43.9772L35.3131 41.3992L37.3007 38.5439L37.4328 38.7254L37.4453 38.3362L37.4805 38.2852L37.4479 38.241L37.6294 32.4404L37.6452 31.9319L37.3577 32.3516L35.3208 35.3261L35.1899 35.1467L35.1775 35.5355L35.1423 35.5866L35.1745 35.6303L34.9887 41.3811L34.3588 47.0611L32.4154 49.8138L32.3291 49.5963L32.2382 50.0644L32.2103 50.1043L32.224 50.1386L31.1478 55.6933L31.4147 55.8079L33.3817 53.0574ZM4.2308 11.6686L4.33036 11.4896L4.12953 11.4501L0.877381 10.8134L0.79242 10.5859L0.68859 10.7765L0.534539 10.7464L0.603616 10.9322L-1.20203 14.2496L-1.30029 14.4302L-1.09776 14.4671L2.1441 15.0584L2.22776 15.282L2.33247 15.0932L2.48522 15.1207L2.41743 14.9396L4.2308 11.6686Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.55068 10.6602L-2.51807 10.7452L-2.4271 10.7546L0.751248 11.0906L1.0838 11.1254L0.887699 10.8551L-5.57927 1.9375L-5.83887 2.07825L-2.55068 10.6602Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.3325 32.4304L37.1514 38.2383L35.0157 41.3064L34.9621 41.3836L35.0089 41.4656L36.491 44.0427L34.5377 46.8091L34.451 46.6006L34.3635 47.0555L34.3348 47.0962L34.3489 47.131L33.28 52.6883L31.1736 55.6344L31.1345 55.6889L31.1496 55.7546L31.7391 58.312L32.0275 58.3185L33.5564 52.8135L35.5508 50.0248L35.6384 50.2364L35.7255 49.7802L35.7547 49.7399L35.7401 49.7047L36.7386 44.4727L36.7656 44.5203L36.8111 44.1054L38.9935 41.0146L39.0493 40.9356L38.9999 40.8528L37.4753 38.2932L39.6217 35.2097L39.6831 35.1213L39.6183 35.0355L37.6002 32.3459L37.3458 32.0065L37.3325 32.4304ZM-0.939396 14.3915L-0.831257 14.1924L-1.05696 14.1718L-4.13833 13.8942L-4.22157 13.6758L-4.32928 13.877L-4.46831 13.8646L-4.40738 14.0238L-6.13494 17.2515L-8.84127 17.3446L-8.91851 17.127L-9.03651 17.351L-9.15322 17.3553L-9.10731 17.4853L-10.8422 20.7765L-10.964 21.0078L-10.7031 20.9945L-7.91398 20.8529L-7.83846 21.0662L-7.71917 20.843L-7.60375 20.837L-7.64836 20.7109L-5.95857 17.5531L-2.98019 17.7771L-2.89866 17.9912L-2.7901 17.7913L-2.65236 17.8016L-2.71115 17.6467L-0.939396 14.3915ZM-12.3118 24.202L-12.1655 23.9248L-12.4732 23.987L-14.9632 24.49L-15.0332 24.284L-15.1615 24.5299L-15.2576 24.5492L-15.2237 24.6491L-16.8972 27.8644L-17.0439 28.1463L-16.7333 28.0785L-14.2428 27.5336L-14.1745 27.7348L-14.0454 27.4902L-13.9501 27.4696L-13.9832 27.3726L-12.3118 24.202Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.9802 55.6326L34.0395 55.8725L34.1836 55.3507L34.2085 55.3164L34.2012 55.2872L35.7284 49.7613L35.4636 49.6351L33.4864 52.4002L33.428 52.1638L33.2834 52.6843L33.2585 52.7186L33.2658 52.7474L31.7399 58.2398L32.0042 58.3669L33.9802 55.6326ZM39.6469 35.1418L39.3766 35.041L37.1758 38.203L37.1213 38.282L37.1702 38.3644L38.7437 41.0063L38.9715 41.3891L39.0192 40.9458L39.6469 35.1418ZM0.897945 11.0153L1.00479 10.8196L0.782944 10.796L-2.3954 10.4604L-2.63184 10.4355L-2.55117 10.6591L-1.21065 14.3717L-1.09823 14.6832L-0.939898 14.3923L0.897945 11.0153Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.578 48.6224L56.851 48.504L55.3628 44.7816L55.3598 44.7738L55.3555 44.7665L53.3027 40.9531L53.0371 41.087L56.578 48.6224Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.7598 46.9361L37.8478 47.1382L37.9315 46.6958L37.9615 46.6538L37.9461 46.6186L39.0119 40.9888L41.2398 37.834L41.2965 37.7538L41.2446 37.6701L39.626 35.047L39.3981 34.6776L39.3514 35.1092L38.7279 40.8751L36.6987 43.7492L36.6116 43.5496L36.5275 43.9916L36.4974 44.0337L36.5124 44.0684L35.4406 49.6871L33.9117 55.219L31.7632 58.1918L31.7318 58.2356L31.7357 58.2892L31.9009 60.7351L32.1893 60.7763L34.1807 55.355L36.3734 52.3204L36.415 52.2634L36.3966 52.1951L35.7474 49.7501L37.7598 46.9361ZM-13.185 21.2841L-13.2932 20.9412L-13.4588 21.26L-15.1675 24.5422L-17.4284 25.3837L-17.5537 25.4305L-17.5207 25.5596L-16.9092 27.9699L-16.8135 28.3484L-16.6333 28.0021L-14.9628 24.7937L-12.414 24.2792L-12.2518 24.2462L-12.3015 24.0882L-13.185 21.2841ZM-4.11302 14.1048L-3.99372 13.8812L-4.2469 13.8855L-7.03948 13.9345L-7.115 13.707L-7.23686 13.9379L-7.35143 13.9396L-7.30724 14.0718L-9.07684 17.4278L-9.19656 17.6548L-8.93996 17.6462L-6.14996 17.5501L-6.07615 17.7728L-5.95343 17.5432L-5.83971 17.5393L-5.88262 17.4115L-4.11302 14.1048Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-17.1997 25.6155L-15.1701 24.8603L-15.1177 25.0899L-14.9568 24.7809L-14.8886 24.7556L-14.9053 24.6818L-13.2288 21.4614L-10.8164 21.0164L-10.7512 21.2408L-10.613 20.9791L-10.5221 20.9624L-10.5513 20.8619L-8.99105 17.9024L-8.81383 17.5665L-8.67394 17.3009L-7.05581 14.2319L-4.35377 14.1847L-4.28125 14.4173L-4.15467 14.1813L-4.04353 14.1796L-4.08429 14.0491L-2.27992 10.6777L-2.16279 10.4584H-2.41123L-5.20381 10.458L-5.27762 10.2207L-5.40292 10.458H-5.51534L-5.47371 10.5923L-7.2429 13.9483L-9.77845 14.3216L-9.94365 14.3461L-9.90031 14.5074L-9.1318 17.3768L-11.4532 17.763L-11.5197 17.5334L-11.6566 17.7965L-11.7489 17.8119L-11.7188 17.9153L-13.4322 21.2074L-15.5275 21.9471L-15.5812 21.712L-15.7408 22.0222L-15.8103 22.0467L-15.7927 22.1231L-17.3293 25.1053L-17.5095 25.4546L-17.6855 25.7962L-19.2178 28.7707L-19.3984 29.1208L-19.0316 28.9775L-16.7114 28.0717L-16.5891 28.0236L-16.6213 27.8962L-17.1997 25.6155ZM37.6508 46.5753L37.6281 46.6071L35.4637 49.6339L35.4229 49.6906L35.441 49.7584L36.0906 52.203L33.9267 55.1968L33.8945 55.2414L33.8966 55.2637L31.9095 60.6734L32.1695 60.8124L34.3699 57.7924L34.403 57.7478L34.4008 57.7251L36.2301 52.7278L36.2498 52.8033L36.3888 52.2982L38.4404 49.4589L38.5026 49.6769L38.636 49.1885L38.6626 49.1521L38.6541 49.1225L40.0739 43.9278L40.0984 43.981L40.1791 43.5515L40.21 43.5081L42.4512 40.374L42.5022 40.3023L42.4645 40.2234L41.2518 37.684L41.0553 37.2724L40.9772 37.6887L38.8995 40.631L38.8124 40.4405L38.7313 40.8696L38.7004 40.9129L38.7163 40.9477L37.6508 46.5753Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-9.61323 14.4304L-9.71235 14.0605L-9.88913 14.4L-11.6914 17.8624L-11.8291 18.1267L-11.5352 18.0782L-8.92152 17.6435L-8.75974 17.6169L-8.80222 17.4582L-9.61323 14.4304ZM34.3845 57.7674L34.1292 57.6172L31.9284 60.6368L31.9056 60.6685L31.9009 60.7071L31.6284 63.011L31.9116 63.0912L34.3845 57.7674Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.6391 48.6881L56.7982 48.4371L41.2026 37.6259L40.7422 37.3066L40.9833 37.8126L42.196 40.352L42.2157 40.3936L42.2561 40.4168L56.6391 48.6881Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-17.2448 25.5916L-17.005 25.1261L-15.5301 22.2644L-13.4391 21.5264L-13.3898 21.7709L-13.2224 21.4495L-13.1559 21.4259L-13.1709 21.3509L-11.4271 18.0004L-11.2572 17.6743L-11.606 17.7906L-13.7481 18.5038L-13.7987 18.2536L-13.9648 18.5763L-14.0326 18.5986L-14.0167 18.6767L-15.7481 22.037L-17.6812 23.1591L-17.764 23.2076L-17.7546 23.3033L-17.5349 25.4445L-19.2393 26.4687L-19.2715 26.2177L-19.471 26.6078L-19.5182 26.6361L-19.5113 26.6867L-21.2088 30.0058L-21.4521 30.4817L-20.9977 30.2002L-19.2037 29.088L-19.1723 29.333L-18.9724 28.9442L-18.9256 28.9155L-18.932 28.8665L-17.474 26.0366L-17.2448 25.5916ZM34.1575 60.0205L36.6218 54.7031L36.6467 54.6692L36.6445 54.6464L38.6326 49.1943L40.7351 46.2841L40.7987 46.4914L40.9265 46.0194L40.954 45.9812L40.945 45.9516L42.4739 40.3269L42.2091 40.2016L40.0932 43.1603L40.031 42.9565L39.9023 43.4272L39.8752 43.4654L39.8838 43.4945L38.3631 49.0587L36.1318 52.1465L36.0987 52.192L36.1009 52.2143L34.1171 57.6347L34.106 57.6501L34.1043 57.6651L31.6417 62.9662L31.897 63.1169L34.1322 60.0685L34.1558 60.0359L34.1575 60.0205ZM-5.18153 10.677L-5.04809 10.4238L-5.33215 10.4599L-7.80633 10.7753L-7.86898 10.5208L-8.01531 10.8019L-8.10371 10.813L-8.0771 10.9207L-9.88875 14.4007L-10.0231 14.659L-9.73514 14.6166L-7.2588 14.2523L-7.19787 14.5011L-7.05026 14.2214L-6.96316 14.2085L-6.98848 14.1046L-5.18153 10.677Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.6543 48.6953L56.7874 48.4297L42.4043 40.1584L42.0684 39.9648L42.1885 40.3334L42.9841 42.7677L43.0051 42.8321L43.0669 42.8583L56.6543 48.6953Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.4369 56.911L38.9089 51.5648L38.9343 51.5296L41.2317 48.3771L41.2673 48.3286L41.2591 48.2693L40.9459 45.9925L43.2463 42.8095L43.2905 42.7481L43.2669 42.676L42.4718 40.2417L42.3169 39.7684L42.1864 40.2486L40.6537 45.8887L38.379 49.0366L38.3451 49.0833L38.3477 49.1061L36.3605 54.5565L36.3485 54.5728L36.3472 54.5878L33.8833 59.9044L31.6567 62.9407L31.6434 62.9592L31.6361 62.9806L30.9101 65.1072L31.18 65.2291L34.1369 60.0614L36.4112 56.9599L36.4356 56.9264L36.4369 56.911ZM-17.458 23.2726L-17.5095 22.7718L-17.7387 23.2198L-19.4787 26.6226L-21.0736 28.1201L-21.117 28.1609L-21.1187 28.1888L-22.8359 31.5636L-22.6004 31.7387L-20.9732 30.1811L-20.9307 30.1403L-20.9294 30.1133L-19.2448 26.8191L-17.3001 25.6506L-17.219 25.6021L-17.2289 25.5082L-17.458 23.2726ZM-9.62438 14.5378L-9.45919 14.2207L-9.80075 14.3271L-11.9334 14.9931L-11.981 14.7262L-12.1539 15.0617L-12.22 15.0823L-12.2059 15.1617L-13.972 18.5902L-15.8927 19.6647L-15.9759 19.711L-15.9682 19.8063L-15.7837 22.1552L-15.7429 22.677L-15.5031 22.2118L-13.7558 18.8202L-11.6751 18.1272L-11.6287 18.3877L-11.4549 18.0539L-11.3897 18.0324L-11.4034 17.9556L-9.62438 14.5378Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.675 48.7026L56.772 48.422L43.1845 42.5849L42.9365 42.4785L42.9786 42.745L43.3424 45.0492L43.3575 45.1449L43.451 45.1698L56.675 48.7026Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.2653 42.7727L43.0044 42.635L40.6693 45.8665L40.6345 45.9146L40.6427 45.9738L40.9636 48.3094L41.2511 48.3399L43.2653 42.7727ZM-15.672 19.7824L-15.7132 19.2576L-15.9526 19.7262L-17.7269 23.1959L-19.3065 24.6484L-19.3498 24.6879L-19.3541 24.7462L-19.5068 26.7016L-19.2258 26.781L-17.4939 23.3941L-15.5613 22.272L-15.4802 22.2248L-15.4875 22.1313L-15.672 19.7824ZM-7.78727 11.0077L-7.62679 10.6992L-7.96105 10.7962L-10.0838 11.4124L-10.128 11.1287L-10.3078 11.4776L-10.3722 11.4961L-10.3593 11.5776L-12.183 15.1168L-12.346 15.433L-12.0062 15.3271L-9.87742 14.6624L-9.83407 14.9396L-9.65343 14.5924L-9.58991 14.5727L-9.60235 14.4942L-7.78727 11.0077ZM33.4372 62.1629L33.4514 62.1436L33.4539 62.1354L36.4199 56.9454L36.171 56.7837L33.8916 59.8921L33.8779 59.911L33.8753 59.9191L30.9214 65.0808L31.1703 65.2429L33.4372 62.1629Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.7009 55.6061L57.9966 55.5714L57.7503 52.145L57.7494 52.1322L57.7464 52.1201L56.8577 48.5234L56.5664 48.5831L57.7009 55.6061Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.7352 53.7908L38.7605 53.7565L38.7618 53.7406L41.2407 48.3661L43.6098 45.1148L43.6462 45.0651L43.6364 45.0037L43.2725 42.6999L42.9854 42.6724L40.9789 48.2198L38.6597 51.402L38.6343 51.4363L38.6335 51.4518L36.1563 56.8104L36.4112 56.9606L38.7352 53.7908ZM-19.0573 24.7704L-19.3383 24.6914L-21.1045 28.1615L-20.8698 28.3379L-19.2559 26.8228L-19.2139 26.7833L-19.2096 26.7258L-19.0573 24.7704ZM-11.9179 15.2542L-11.6982 14.8281L-12.1209 15.0547L-14.0686 16.1008L-14.1518 16.1459L-14.1466 16.2403L-14.0089 18.7072L-13.9784 19.253L-13.7278 18.7668L-11.9179 15.2542Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-5.65544 2.16789L-5.70093 1.87695L-22.7134 2.01899L-22.7671 2.01941L-22.8083 2.05418L-32.418 10.1981L-32.2772 10.4534L-5.65544 2.16789ZM56.6975 48.7072L56.7516 48.4154L43.5275 44.8826L43.3478 44.8346L43.3405 45.0204L43.2555 47.1646L43.2499 47.304L43.389 47.3186L56.6975 48.7072Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.7506 59.0192L35.7639 59.0016L35.7664 58.993L38.7401 53.7829L41.1057 50.5569L41.1319 50.5213L41.1328 50.5054L43.6246 45.0885L43.3688 44.9383L40.9912 48.2016L40.9654 48.2368L40.9646 48.2527L38.4861 53.6258L36.1711 56.7836L36.1565 56.8033L36.1539 56.8115L33.1927 61.9933L30.9309 65.0665L30.9275 65.0712L30.9245 65.0759L29.7282 66.985L29.8835 67.0876L29.974 67.1524L32.2684 64.0371L32.2719 64.0328L32.2731 64.0307L35.7506 59.0192ZM-20.5357 26.502L-21.1064 28.1622L-22.3045 29.9812L-22.3152 29.9975L-22.3178 30.0056L-24.0625 33.4388L-23.8055 33.5877L-22.5791 31.7121L-22.5684 31.6963L-22.5658 31.6886L-20.8391 28.2957L-19.0854 24.8491L-17.5059 23.397L-17.4638 23.3584L-17.4612 23.3301L-15.6878 19.8617L-15.9195 19.6832L-17.5033 21.1074L-17.5466 21.146L-17.5492 21.1756L-19.3343 24.6826L-20.519 26.4681L-20.5297 26.484L-20.5357 26.502ZM-10.0721 11.6706L-9.85973 11.2578L-10.2725 11.4702L-11.9988 12.3567L-12.0108 12.0173L-12.2502 12.4859L-12.2914 12.5074L-12.2897 12.5631L-14.1306 16.1633L-14.3477 16.5881L-13.9276 16.3624L-12.191 15.4295L-12.1794 15.7604L-11.9391 15.2944L-11.8984 15.272L-11.9005 15.2184L-10.0721 11.6706Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.719 48.7081L56.7284 48.4116L43.4199 47.0226L43.2954 47.0098L43.2611 47.1303L42.7072 49.0802L42.6514 49.278L42.8565 49.2694L56.719 48.7081Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.1082 55.816L38.1232 55.7959L38.1258 55.7873L41.1115 50.5497L43.5247 47.2585L43.5517 47.2216L43.5535 47.1761L43.6384 45.0319L43.3544 44.9641L40.8574 50.3931L38.4957 53.6139L38.4806 53.634L38.4781 53.6422L35.5005 58.8592L35.7499 59.021L38.1082 55.816ZM-20.2541 26.5986L-20.5274 26.4828L-22.3125 29.9958L-22.0555 30.1451L-20.8471 28.3099L-20.8368 28.2944L-20.8308 28.2764L-20.2541 26.5986ZM-13.8652 16.2989L-14.0961 16.1191L-15.6636 17.494L-15.7065 17.5317L-15.7099 17.5622L-17.5362 21.1503L-17.304 21.3284L-15.7202 19.9047L-15.6782 19.8673L-15.6748 19.8381L-13.8652 16.2989Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.6102 60.8587L34.6136 60.854L34.6149 60.8523L38.1108 55.8125L37.8687 55.6395L35.51 58.8445L35.5065 58.8492L35.5057 58.8509L32.027 63.8641L32.269 64.037L34.6102 60.8587ZM-17.271 21.2854L-17.5272 21.1348L-18.6969 22.8825L-18.7072 22.8984L-18.7136 22.916L-19.3457 24.7083L-19.0724 24.8255L-17.271 21.2854Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.0537 61.6909L56.3464 61.7351L57.9919 55.6205L58.0001 55.5896L57.9949 55.5582L56.8604 48.5352L56.5648 48.5532L56.0537 61.6909Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.5147 52.5469L40.5301 52.5258L40.5327 52.5172L43.5343 47.2449L43.2845 47.0831L40.8661 50.3816L40.8511 50.4026L40.8485 50.4108L37.859 55.6552L38.1083 55.817L40.5147 52.5469ZM-18.4329 23.0162L-18.7058 22.8991L-20.5273 26.4838L-20.2707 26.6335L-19.0813 24.8412L-19.071 24.8257L-19.065 24.8086L-18.4329 23.0162ZM-12.0067 12.665L-12.2358 12.4844L-13.7862 13.8082L-13.8295 13.8451L-13.8368 13.9013L-14.1453 16.2124L-13.8651 16.2999L-12.0067 12.665Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.736 48.7062L56.7072 48.4102L42.8447 48.9714L42.7649 48.9748L42.7233 49.0426L41.6797 50.7595L41.5137 51.0324L41.8291 50.9843L56.736 48.7062Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-13.542 13.9406L-13.8222 13.8535L-15.6982 17.5395L-15.4674 17.7188L-13.8999 16.344L-13.8578 16.3071L-13.8505 16.2517L-13.542 13.9406ZM36.9986 57.6165L37.0021 57.6118L37.0034 57.6097L40.5168 52.5433L40.2748 52.3704L37.8684 55.6405L37.8646 55.6453L37.8637 55.6474L34.3674 60.6868L34.6098 60.8601L36.9986 57.6165Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-15.4329 17.6745L-15.6886 17.5234L-16.8373 19.2244L-16.8476 19.2398L-16.8511 19.2484L-18.7061 22.8988L-18.4495 23.049L-17.2802 21.3012L-17.2699 21.2862L-17.2669 21.2781L-15.4329 17.6745ZM42.9706 49.209L42.9865 49.1876L42.9938 49.1618L43.5477 47.2116L43.2753 47.0974L40.2656 52.3848L40.5149 52.5466L42.9706 49.209Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.7463 48.7043L56.6909 48.4121L41.7844 50.6902L41.7389 50.6971L41.7054 50.728L40.1499 52.1684L39.7432 52.5448L40.2838 52.4229L56.7463 48.7043Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.4373 54.3048L39.4411 54.2996L39.442 54.2979L42.973 49.2049L42.731 49.0316L40.2749 52.3691L40.271 52.3742L40.2701 52.376L36.7567 57.4423L36.9987 57.6153L39.4373 54.3048ZM-13.557 13.9874L-13.8127 13.8359L-14.9391 15.4875L-14.9494 15.5026L-14.9559 15.5202L-15.7051 17.5541L-15.433 17.6734L-13.557 13.9874Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-22.6161 2.28146L-22.7792 2.03516L-28.7643 5.04743L-28.7977 5.06417L-28.8192 5.09463L-32.4434 10.2258L-32.2254 10.4253L-22.6161 2.28146ZM56.0585 61.6581L56.3395 61.7534L57.538 58.8411L57.5449 58.8235L57.5474 58.8051L57.995 55.6027L57.7041 55.5435L56.0585 61.6581ZM56.7506 48.7032L56.6802 48.414L40.2178 52.1325L40.1981 52.1368L40.1805 52.1463L38.0916 53.2628L38.1993 53.5379L56.7506 48.7032Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.926 50.9249L41.9298 50.9198L41.9333 50.9142L42.9773 49.1974L42.728 49.0352L39.1943 54.1316L39.4368 54.3049L41.926 50.9249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.7512 48.7039L56.6757 48.416H56.6752L38.1248 53.2506L38.1218 53.2515L35.4841 53.9917L35.482 53.9926L35.5601 54.2796L35.5622 54.2788L56.7508 48.7039H56.7512ZM-14.8591 15.7151L-14.7549 15.7465L-14.6935 15.6568L-13.5667 14.0052L-13.4392 13.8181L-13.6615 13.7752L-32.293 10.166L-32.3643 10.4548L-14.8591 15.7151Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.7266 77.4355L43.5198 77.224L40.5959 79.4802L40.5714 79.4459L40.4594 79.5853L40.3599 79.6621L40.3779 79.687L37.9058 82.7624L38.1135 82.9726L41.0718 80.6602L41.0954 80.6932L41.2078 80.5542L41.3065 80.477L41.2893 80.453L43.7266 77.4355ZM-37.4338 21.9521L-37.7101 21.8418L-39.3883 25.5449L-39.3943 25.5582L-39.3956 25.5651L-41.9277 32.8963L-41.6518 33.0065L-39.974 29.3232L-39.968 29.3099L-39.9663 29.3026L-37.4338 21.9521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.288 74.2647L46.082 74.0527L43.0723 76.3428L42.9736 76.4179L43.0298 76.5286L43.4783 77.41L43.5825 77.6147L43.7267 77.4362L46.288 74.2647Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5449 68.635L42.7354 68.8521L56.271 61.8286L56.3478 61.7887L56.3508 61.702L56.8618 48.5647L56.8816 48.0566L56.5911 48.4737L42.5449 68.635Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-38.2601 25.5603L-38.2537 25.5462L-38.2502 25.5316L-37.4294 21.937L-37.7152 21.8555L-40.2477 29.2064L-41.9225 32.8838L-41.9354 32.9121L-41.9358 32.9434L-41.9941 37.173L-41.6998 37.2069L-39.9675 29.3089L-38.2601 25.5603ZM43.295 76.3932L43.1908 76.1881L43.0466 76.3674L40.4484 79.5994L40.6556 79.8105L43.7018 77.4599L43.7996 77.3848L43.7434 77.2746L43.295 76.3932ZM48.9042 71.0256L48.6991 70.8132L45.728 73.0389L45.6409 73.1041L45.677 73.2071L46.0318 74.2202L46.1202 74.4725L46.288 74.2644L48.9042 71.0256Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-33.9697 14.3067L-34.246 14.1973L-35.9963 18.06L-36.0027 18.0742L-36.0044 18.081L-38.5361 25.4496L-38.2602 25.5598L-36.5086 21.7138L-36.5022 21.6997L-36.5005 21.6928L-33.9697 14.3067ZM40.6804 79.7856L40.5023 79.5569L36.5653 81.3703L36.368 81.4612L36.5242 81.6123L37.9183 82.9622L38.0354 83.0759L38.138 82.9485L40.6804 79.7856ZM45.9575 73.1084L45.8691 72.8556L45.7013 73.0642L43.046 76.367L43.2524 76.5789L46.2622 74.2888L46.3484 74.2236L46.3128 74.1215L45.9575 73.1084Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5333 68.7861L42.6445 69.0131L42.7891 68.8059L56.8353 48.6446L57.0665 48.3125L56.6752 48.4155L35.4841 53.9912L35.3086 54.0376L35.3884 54.2006L42.5333 68.7861Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-38.2498 25.5294L-38.5304 25.4363L-40.2447 29.1995L-40.258 29.2287V29.245L-41.9902 37.143L-41.7096 37.2369L-39.9889 33.5038L-39.9752 33.4742V33.4579L-38.2498 25.5294ZM36.732 81.3986L36.614 81.2845L36.5118 81.4128L33.9463 84.6383L34.1261 84.8653L38.0858 82.9897L38.2802 82.8979L38.1257 82.7481L36.732 81.3986ZM51.5771 67.7163L51.3729 67.5026L48.4434 69.6614L48.3653 69.7189L48.3863 69.8138L48.6434 70.9642L48.7107 71.265L48.9047 71.0251L51.5771 67.7163ZM-32.1802 10.3594L-32.4569 10.25L-34.2454 14.1969L-34.2519 14.2115L-34.2553 14.2273L-34.9989 17.6902L-34.7123 17.7695L-32.1802 10.3594Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-34.7186 17.784L-34.7117 17.7695L-34.7083 17.7536L-33.9651 14.2908L-34.2513 14.2109L-36.7852 21.6048L-36.5088 21.7146L-34.7186 17.784ZM43.278 76.5544L43.1012 76.3253L39.19 78.073L39.0046 78.1558L39.1389 78.3073L40.4528 79.7915L40.5695 79.9237L40.6802 79.7864L43.278 76.5544Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.2041 66.2209L51.3938 66.4496L54.2795 64.3616L54.2962 64.3496L54.3091 64.3333L56.3194 61.7883L56.1001 61.5879L51.2041 66.2209Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.6975 69.8438L51.3438 67.8944L51.3554 67.9909L51.5648 67.7313L51.6193 67.6914L51.6163 67.6678L54.308 64.3354L54.1046 64.1213L51.4236 66.0608L51.4116 65.9604L51.2026 66.2209L51.1477 66.2603L51.1507 66.2852L48.6091 69.4473L48.4392 69.6588L44.6189 71.2503L44.4546 71.319L44.5511 71.4683L45.5973 73.0907L41.9813 74.6535L41.9358 74.5939L41.8397 74.7149L41.6998 74.775L41.7444 74.8342L39.2449 77.9769L39.1346 78.1155L36.5433 81.3728L31.7983 82.5082L31.7378 82.4541L31.6717 82.5382L31.4456 82.5927L31.5537 82.6893L29.0645 85.8719L28.7968 86.2148L29.2186 86.1079L34.0219 84.8949L34.0807 84.9472L34.1476 84.8631L34.3703 84.8069L34.2661 84.7138L36.7222 81.626L40.627 79.8276L40.809 79.7439L40.6759 79.5938L39.4968 78.2614L43.1154 76.645L43.1591 76.7025L43.2561 76.5819L43.3943 76.5201L43.3514 76.4635L45.8041 73.4117L45.9217 73.2658L48.8778 71.0512L48.9546 70.9933L48.9335 70.8993L48.6975 69.8438ZM-38.5445 25.4667V25.4826L-40.2664 33.3943L-40.2797 33.4235L-41.9803 37.1129L-42 37.1558L-41.9914 37.2021L-41.11 41.93L-40.98 41.9244L-40.8294 41.966L-39.0555 38.1929L-39.0349 38.1487L-39.0401 38.1221L-38.2196 29.665L-38.2162 29.6577L-36.4316 25.7864L-36.4174 25.7555L-36.4178 25.7383L-34.7113 17.7691L-32.8876 13.7651L-32.8808 13.7497L-32.8773 13.7334L-32.1758 10.3418L-32.4624 10.2637L-34.9919 17.6669L-36.7791 21.591L-36.7933 21.6215L-38.5307 25.4366L-38.5445 25.4667Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-32.8775 13.7348L-33.1585 13.6426L-34.9887 17.6611L-35.0032 17.6924L-35.0024 17.7092L-36.7119 25.6934L-36.4313 25.787L-34.5922 21.7968L-34.5771 21.7646L-34.578 21.7478L-32.8775 13.7348ZM39.3675 78.3019L39.6309 77.9711L39.2186 78.0638L34.3586 79.1588L34.0925 79.2185L34.2869 79.4099L36.5238 81.6124L36.6418 81.7287L36.7448 81.5991L39.3675 78.3019ZM44.8016 71.308L44.6892 71.1334L44.56 71.296L41.8168 74.7447L41.9923 74.9738L45.8769 73.2952L46.0378 73.2257L45.9426 73.078L44.8016 71.308Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5986 68.5884L42.7068 68.8639L51.3458 66.4729L51.3815 66.463L51.4085 66.4377L56.3045 61.8047L56.1337 61.5645L42.5986 68.5884Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-36.4186 25.7377L-36.7018 25.6613L-38.5002 29.5632L-38.5212 29.6078L-38.5156 29.634L-39.3361 38.0894L-41.0984 41.8388L-41.125 41.8955L-41.1036 41.9543L-39.1769 47.1459L-38.8885 47.0915L-39.0409 38.1615L-37.2417 34.3339L-37.2202 34.2884L-37.2258 34.2618L-36.4186 25.7377ZM34.4953 79.1967L34.3764 79.0796L34.2738 79.2109L31.6808 82.5269L26.0986 82.9136L25.7597 82.9372L26.0068 83.1702L29.0796 86.0717L29.1984 86.1837L29.2993 86.055L31.8421 82.8033L36.6626 81.6503L36.9235 81.5876L36.7326 81.3993L34.4953 79.1967ZM51.422 66.4224L51.7777 65.9804L51.2508 66.1911L47.5502 67.6719L47.506 67.5908L47.391 67.7354L47.2721 67.7831L47.3052 67.8436L44.5598 71.2948L44.7336 71.5248L48.465 69.9702L48.5075 70.0483L48.6234 69.9041L48.7409 69.8552L48.7096 69.7973L51.422 66.4224ZM42.0496 74.9289L42.307 74.6053L41.9024 74.6903L37.0553 75.7111L36.7991 75.7652L36.9763 75.9574L39.1416 78.3089L39.2591 78.4368L39.3673 78.3007L42.0496 74.9289ZM-32.7108 17.7136L-32.6953 17.6805L-32.6971 17.6445L-32.8747 13.6963L-33.1686 13.6719L-34.873 21.7025L-34.5924 21.7956L-32.7108 17.7136Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5469 68.8075L42.8009 68.6548L35.656 54.0692L35.6105 53.9766L35.5079 53.9864L32.3017 54.2988L32.043 54.3237L32.1957 54.5344L42.5469 68.8075Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-34.5787 21.7478L-34.8619 21.6719L-36.7014 25.6625L-36.7225 25.7084L-36.7169 25.7342L-37.5214 34.2316L-39.3245 38.0669L-39.3516 38.1244L-39.3383 38.1592L-39.1855 47.0978L-38.9027 47.16L-37.0657 43.3586L-37.0374 43.3006L-37.0511 43.265L-37.2262 34.3033L-35.3854 30.3869L-35.3635 30.3405L-35.3695 30.3139L-34.5787 21.7478ZM37.196 75.7569L37.0771 75.6282L36.969 75.7663L34.2742 79.212L34.0172 79.5407L34.424 79.4489L39.284 78.3542L39.5354 78.2976L39.3608 78.1084L37.196 75.7569ZM26.2115 82.955L26.0913 82.8413L25.9913 82.9726L23.4227 86.3351L23.2193 86.6011L23.5532 86.5741L29.1946 86.1128L29.5275 86.0858L29.2847 85.8562L26.2115 82.955ZM44.7932 71.4809L45.0446 71.1651L44.6482 71.2423L39.8161 72.1851L39.5689 72.2331L39.7302 72.4267L41.8191 74.9326L41.9363 75.0729L42.05 74.93L44.7932 71.4809Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-32.1753 10.2871L-32.4706 10.3017L-32.9949 17.6419L-32.9971 17.6732L-32.9863 17.7028L-29.5488 27.2224L-29.2622 27.1469L-32.1753 10.2871ZM32.4359 54.3604L32.3939 54.3025L32.3222 54.299L28.6629 54.1355L28.2883 54.1188L28.5496 54.3878L42.5597 68.8249L42.7871 68.634L32.4359 54.3604Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.5084 79.3956L34.7109 79.1365L34.3831 79.1554L28.7966 79.4695L28.7233 79.3956L28.661 79.4772L28.3997 79.4918L28.5409 79.6347L25.9907 82.9726L25.7912 83.2344L26.1195 83.2116L31.6995 82.825L31.7703 82.8967L31.8338 82.8156L32.0917 82.798L31.9552 82.6602L34.5084 79.3956ZM-32.6972 17.6654L-32.9809 17.5898L-34.8625 21.6719L-34.8839 21.7191L-34.8775 21.7452L-35.6662 30.2839L-37.5104 34.208L-37.5383 34.2668L-37.5242 34.3016L-37.3491 43.2612L-39.1711 47.0304L-39.2041 47.0995L-39.1676 47.1664L-36.1978 52.5928L-35.9193 52.5031L-37.0466 43.3195L-35.186 39.4692L-35.1573 39.41L-35.1719 39.374L-35.3705 30.3556L-33.4864 26.3473L-33.464 26.2997L-33.4709 26.2727L-32.6972 17.6654ZM39.9584 72.2361L39.8404 72.0945L39.7271 72.2396L36.9688 75.7663L36.7174 76.0877L37.1169 76.0036L41.964 74.9828L42.206 74.9317L42.0477 74.7421L39.9584 72.2361Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.2662 66.1853L42.7121 68.5526L28.7633 54.179L28.729 54.1434L28.6801 54.1357L24.7328 53.4942L24.199 53.4075L24.6131 53.7551L42.5709 68.8337L42.6632 68.735L42.6928 68.8663L47.5086 68.0051L47.5236 68.0025L47.5378 67.9969L51.361 66.4672L51.2662 66.1853ZM-15.0662 22.4162L-15.0769 22.3557L-15.1267 22.3205L-32.2358 10.1895L-32.4512 10.3847L-10.7855 48.3347L-10.5096 48.2352L-15.0662 22.4162Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.227 83.1518L26.3943 82.9326L26.1193 82.9133L20.0068 82.4803L19.9244 82.3936L19.8677 82.4704L19.5669 82.4494L19.7446 82.6365L17.273 85.968L17.1091 86.189L17.3837 86.2053L23.4726 86.5692L23.5524 86.6533L23.6104 86.5774L23.9073 86.5949L23.7352 86.4139L26.227 83.1518ZM47.5992 67.9514L47.8446 67.6429L47.4563 67.7124L42.7306 68.5573L42.6692 68.4753L42.5847 68.5831L42.4023 68.6157L42.4792 68.7182L39.7265 72.2381L39.4806 72.5522L39.8719 72.4758L44.6131 71.5511L44.6727 71.6301L44.7581 71.5228L44.9379 71.4876L44.8641 71.3894L47.5992 67.9514ZM37.2029 75.9481L37.4016 75.6941L37.0794 75.7078L31.4908 75.9464L31.4174 75.867L31.3522 75.9524L31.1007 75.9631L31.2337 76.1069L28.6188 79.5302L28.4227 79.7873L28.7454 79.7693L34.3288 79.4547L34.4001 79.532L34.4661 79.447L34.7142 79.4333L34.5859 79.2942L37.2029 75.9481ZM-34.0251 48.7715L-33.9899 48.7007L-34.0143 48.6574L-35.1673 39.4283L-33.2621 35.4861L-33.2334 35.4261L-33.2484 35.39L-33.4724 26.3141L-31.5436 22.2111L-31.5204 22.1622L-31.5341 22.1102L-32.7021 17.6133L-32.9944 17.6373L-33.7676 26.2429L-35.6556 30.2588L-35.6835 30.3185L-35.6689 30.3537L-35.4703 39.3704L-37.3343 43.228L-37.3682 43.2979L-37.3446 43.34L-36.2156 52.5377L-36.1555 53.0273L-35.9346 52.5862L-34.0251 48.7715Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.8044 53.5272L24.7791 53.5057L24.7469 53.4972L20.5846 52.3965L20.4589 52.6604L42.579 68.8396L42.7622 68.6058L24.8044 53.5272ZM-14.0468 25.3126L-14.0485 25.2989L-14.0532 25.286L-15.0727 22.3926L-15.3594 22.4677L-10.8032 48.2836L-10.8028 48.2866L-10.5088 48.2381L-10.5097 48.2351L-14.0468 25.3126Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-34.0125 48.673L-34.1017 48.2598L-34.2914 48.6378L-36.2009 52.4529L-36.2412 52.5336L-36.1884 52.6065L-32.2922 57.9935L-32.0262 57.8746L-34.0125 48.673ZM28.8558 79.7106L29.021 79.4944L28.7498 79.4721L22.5575 78.9623L22.1833 78.9314L22.4348 79.2103L25.9984 83.161L26.1182 83.2941L26.2271 83.1516L28.8558 79.7106Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.6345 52.4205L20.6173 52.4081L20.5967 52.4008L16.301 50.8672L16.168 51.1311L42.5838 68.8439L42.7546 68.6002L20.6345 52.4205Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.9612 72.4224L40.1564 72.1731L39.8398 72.1822L34.1795 72.3435L33.8667 72.3521L34.0714 72.5894L36.9734 75.9548L37.0918 76.0921L37.2034 75.9492L39.9612 72.4224ZM22.656 79.0126L22.5342 78.8774L22.426 79.0233L19.8193 82.5372L19.6579 82.7552L19.9283 82.7745L26.0987 83.2113L26.4669 83.2375L26.2197 82.9629L22.656 79.0126ZM-32.0696 44.8665L-32.034 44.7949L-32.0589 44.7515L-33.2484 35.404L-33.3114 34.9062L-33.5299 35.3581L-35.4539 39.3393L-35.4883 39.4101L-35.4638 39.4526L-34.3057 48.7241L-34.2447 49.2116L-34.0246 48.7726L-32.0696 44.8665Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.3344 50.8829L16.3236 50.876L16.312 50.8704L11.9683 48.9382L11.828 49.1999L42.5845 68.8433L42.5876 68.845L42.7506 68.5957L42.7476 68.594L16.3344 50.8829ZM-12.372 28.4852L-12.3746 28.456L-12.3883 28.4298L-14.0614 25.2656L-14.3398 25.3579L-10.8023 48.2834L-10.5071 48.2469L-12.372 28.4852Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-31.292 31.4116L-31.2624 31.3498L-31.2895 31.2876L-33.4852 26.2246L-33.7705 26.2877L-33.5448 35.4267L-33.262 35.4876L-31.292 31.4116ZM34.2963 72.3953L34.1766 72.2563L34.0654 72.4022L31.3098 76.0092L31.1171 76.2615L31.4346 76.2482L37.0919 76.0066L37.3991 75.9933L37.1983 75.7608L34.2963 72.3953ZM20.0579 82.7148L20.1991 82.5247L19.9665 82.48L13.4021 81.2198L12.9842 81.1395L13.261 81.4631L17.2795 86.1548L17.4009 86.2964L17.5121 86.1467L20.0579 82.7148Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.9877 48.95L11.9826 48.947L11.9774 48.944L7.67526 46.6543L7.52637 46.9122L42.5873 68.8461L42.5881 68.8469L42.7473 68.595L42.746 68.5942L11.9877 48.95Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.4878 81.2692L13.3642 81.125L13.2539 81.2795L10.7458 84.7796L10.6094 84.9706L10.8402 85.0126L17.3668 86.2047L17.7792 86.2798L17.5063 85.9614L13.4878 81.2692Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.68422 46.66L7.6838 46.6596L3.5138 44.061L3.51338 44.0605H3.51295L3.35547 44.3128V44.3133L42.5885 68.8471L42.6674 68.7209L42.7464 68.5948L42.746 68.5943L42.7443 68.5935L7.68422 46.66Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-30.0518 54.1617L-30.0088 54.0793L-30.0449 54.0304L-32.0578 44.7683L-32.1471 44.3555L-32.3363 44.7331L-34.2913 48.6387L-34.332 48.7203L-34.2968 48.7683L-32.317 57.9386L-32.2299 58.3415L-32.0393 57.976L-30.0518 54.1617ZM31.5468 76.1891L31.7094 75.9758L31.4425 75.9505L25.2287 75.3648L24.8623 75.3305L25.1017 75.6098L28.6246 79.718L28.7444 79.8579L28.8559 79.7116L31.5468 76.1891ZM42.7845 68.812L42.9759 68.5674L42.6652 68.5717L37.0037 68.6485L36.6986 68.6524L36.89 68.8905L39.7276 72.4238L39.8452 72.5701L39.961 72.4225L42.7845 68.812Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.3282 75.4153L25.2068 75.2737L25.0956 75.4234L22.4262 79.0219L22.2666 79.2368L22.5335 79.2591L28.7258 79.7685L29.0867 79.7981L28.8507 79.5235L25.3282 75.4153ZM-30.0407 54.0487L-30.1467 53.6998L-30.3153 54.0229L-32.3033 57.8372L-32.351 57.929L-32.2814 58.0058L-27.548 63.2348L-27.2953 63.0915L-30.0407 54.0487ZM-30.0677 40.8654L-30.0312 40.7929L-30.057 40.7491L-31.2778 31.3257L-31.3421 30.8301L-31.5597 31.2802L-33.5297 35.3567L-33.5645 35.4283L-33.5396 35.4708L-32.3501 44.8175L-32.2887 45.3028L-32.0695 44.8651L-30.0677 40.8654Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-29.2741 27.2351L-29.2441 27.1725L-29.2724 27.109L-31.5424 22.0859L-31.8264 22.1512L-31.5745 31.3489L-31.2918 31.4098L-29.2741 27.2351ZM37.1222 68.7029L37.0029 68.5549L36.8875 68.7059L34.0657 72.4005L33.8761 72.6485L34.188 72.6395L39.8483 72.4781L40.1474 72.47L39.9598 72.2361L37.1222 68.7029ZM22.665 79.1991L22.8044 79.0112L22.5749 78.9648L16.0242 77.6441L15.9345 77.5355L15.8779 77.6145L15.5612 77.5505L15.7552 77.7857L13.3153 81.191L6.65096 79.1777L6.55527 79.0541L6.50164 79.1326L6.15965 79.0292L6.37806 79.3115L3.98411 82.7877L3.86782 82.9572L4.06477 83.0151L10.7785 84.9937L10.8712 85.1138L10.9261 85.037L11.2664 85.1374L11.0522 84.8611L13.4398 81.5283L19.8582 82.7606L19.9449 82.8658L20.0024 82.7881L20.3165 82.8486L20.1277 82.6195L22.665 79.1991ZM-28.0156 50.2542L-27.9722 50.1709L-28.0289 50.0959L-32.0839 44.7094L-32.3486 44.8304L-30.3289 54.1234L-30.2418 54.5255L-30.0517 54.1607L-28.0156 50.2542Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-21.8425 68.032L-25.2037 59.3466L-25.1857 59.3135L-25.2346 59.2603L-28.0031 50.148L-30.0439 40.8189L-28.0164 36.7686L-27.9795 36.6948L-28.0229 36.6245L-31.2982 31.2676L-31.5728 31.3641L-30.3537 40.773L-32.3358 44.7323L-32.377 44.8147L-32.3216 44.8885L-28.3228 50.2003L-30.3151 54.0232L-30.3632 54.1159L-30.3156 54.1678L-27.5981 63.1188L-27.6242 63.1669L-27.568 63.2179L-27.4766 63.5183L-27.3972 63.3724L-22.0811 68.1959L-21.8425 68.032ZM34.3026 72.5813L34.4631 72.371L34.2 72.3427L28.027 71.6841L27.9446 71.5832L27.8815 71.6686L27.6065 71.6394L27.7618 71.8299L25.0958 75.4237L24.9383 75.6356L25.2014 75.6605L31.353 76.2402L31.4328 76.3381L31.4971 76.254L31.7688 76.2793L31.6186 76.0948L34.3026 72.5813Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-0.432536 41.4533L-0.27034 41.3426L-0.420947 41.2165L-4.06658 38.1604L-4.19532 38.0527L-4.28671 38.1935L-10.7803 48.1808L-10.5717 48.385L-0.432536 41.4533ZM30.7604 67.9001L30.7347 68.1966L36.9884 68.9453L36.9983 68.9466L37.0081 68.9462L42.6692 68.8694L42.6757 68.572L30.7604 67.9001Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.705 64.2241L-19.814 63.9915L-19.8651 64.0808L-25.2683 59.2255L-25.5069 59.3898L-22.1461 68.074L-22.1762 68.1259L-22.1054 68.1795L-22.0075 68.4314L-21.9363 68.3065L-16.0293 72.7589L-15.8049 72.5769L-19.6595 64.3215L-19.6385 64.2842L-19.705 64.2241ZM9.01765 75.4789L8.68253 75.3751L8.89494 75.657L6.43962 79.2224L6.3242 79.3902L6.51943 79.4494L13.2846 81.4932L13.3773 81.6164L13.4331 81.5383L13.7665 81.6391L13.5588 81.3628L16.0639 77.8665L16.1841 77.6987L15.9867 77.6377L9.16827 75.5253L9.073 75.3987L9.01765 75.4789ZM-25.93 46.253L-25.8862 46.1689L-25.9441 46.0933L-30.0832 40.709L-30.3467 40.8313L-28.2934 50.2178L-28.2055 50.619L-28.0158 50.2547L-25.93 46.253Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.47596 44.3294L3.76902 44.2448L3.52187 44.0659L-0.428854 41.2098L-0.513815 41.1484L-0.60006 41.2077L-10.7393 48.1389L-10.614 48.4045L3.47596 44.3294Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-23.116 55.5148L-23.065 55.4208L-23.1379 55.3423L-28.0391 50.0841L-28.2905 50.2291L-25.5104 59.3792L-25.407 59.7191L-25.2371 59.4071L-23.116 55.5148ZM-7.02136 72.9233L-7.14709 72.7208L-7.27366 72.9225L-9.45693 76.3913L-13.5707 68.8362L-13.6952 68.6074L-13.829 68.8306L-16.0672 72.5633L-16.1423 72.6886L-16.0187 72.7659L-9.67148 76.7329L-9.67877 76.744L-9.53932 76.8153L-9.0643 77.1122L-9.1055 77.0367L-2.79473 80.2571L-2.31885 80.5L-2.60077 80.046L-7.02136 72.9233ZM-28.0052 36.67L-28.0953 36.2598L-28.2837 36.6352L-30.3343 40.7327L-30.376 40.8159L-30.3193 40.8898L-26.1802 46.2745L-25.9168 46.1518L-28.0052 36.67ZM37.124 68.8868L37.2823 68.6795L37.0236 68.6486L30.7695 67.8999L30.4172 67.8578L30.6343 68.1389L34.0658 72.5818L34.1842 72.7354L34.3018 72.5813L37.124 68.8868Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.51313 44.0606L3.45648 44.0254L3.39297 44.0438L-10.697 48.119L-11.123 48.2422L-10.709 48.4009L42.6136 68.8595L42.7457 68.5944L3.51313 44.0606Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.74274 79.3077L9.13884 75.8281L15.8517 77.9079L15.9439 78.0341L16.0014 77.9543L16.328 78.0556L16.1267 77.7792L18.6387 74.273L25.1301 75.647L25.2168 75.7603L25.2777 75.6783L25.5764 75.7414L25.4013 75.5118L28.0184 71.9834L34.1682 72.6395L34.5149 72.6764L34.3017 72.4004L30.8697 67.9575L30.7496 67.8018L30.6324 67.9597L27.8875 71.6602L21.3566 70.2095L21.2678 70.0927L21.2077 70.1764L20.906 70.1095L21.0863 70.3459L18.5164 73.9328L11.7482 71.7825L11.6534 71.6521L11.5967 71.734L11.2685 71.6298L11.4744 71.9121L9.02383 75.4702L2.21359 72.6592L2.11361 72.5124L2.05912 72.5953L1.7124 72.4524L1.93639 72.781L-0.330105 76.2194L-4.64471 69.1496L-4.76957 68.9445L-4.89745 69.1479L-7.13735 72.7068L-11.276 65.0122L-11.4005 64.7818L-11.5348 65.0062L-13.6841 68.5905L-17.4516 60.4573L-17.4302 60.4195L-17.4971 60.3599L-17.6052 60.126L-17.6572 60.217L-23.0595 55.4119L-20.9423 51.5269L-20.8909 51.4321L-20.9651 51.3531L-25.8809 46.1593L-23.7924 42.1519L-23.7482 42.0669L-23.7864 42.018L-25.892 32.5238L-25.8783 32.4959L-25.9092 32.447L-25.9941 32.0629L-26.0636 32.2015L-29.2823 27.0918L-29.5557 27.1905L-28.3036 36.677L-28.3254 36.7204L-28.2924 36.7624L-28.2345 37.2031L-28.2053 37.1452L-26.219 46.1636L-28.2795 50.1177L-28.3284 50.2108L-28.2568 50.2881L-23.4286 55.4673L-25.4985 59.2653L-25.5547 59.3687L-25.4672 59.4472L-20.0159 64.3454L-20.0215 64.3553L-19.9507 64.4085L-19.8533 64.6608L-19.8404 64.6385L-16.0742 72.7034L-15.9558 72.957L-15.8116 72.7167L-13.7073 69.2075L-9.59651 76.7575L-9.47508 76.9807L-9.33992 76.7657L-7.14851 73.2831L-2.85321 80.2036L-2.7305 80.4014L-2.60262 80.2071L-0.284193 76.6906L0.0672398 76.8712L0.0273266 76.8056L6.46469 79.4282L6.56166 79.5707L6.61701 79.49L6.96329 79.6312L6.74274 79.3077Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-29.287 27.084L-29.5342 27.25L-26.1748 32.5833L-26.17 32.5906L-26.1649 32.5971L-21.8494 37.972L-21.6126 37.7922L-29.287 27.084ZM17.0369 63.9879L16.9699 64.2775L30.7114 68.1913L30.7273 68.1956L30.7436 68.1965L42.6589 68.8684L42.6936 68.5732L17.0369 63.9879Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.25796 75.6559L11.7167 72.0854L18.4815 74.2348L18.5737 74.3643L18.6321 74.2824L18.9522 74.3841L18.757 74.1086L21.335 70.5106L27.8624 71.9601L27.9487 72.0781L28.0113 71.9931L28.3031 72.0579L28.1345 71.8275L30.8713 68.1381L31.0065 67.9557L30.7859 67.9042L24.1233 66.3526L24.0344 66.2312L23.9731 66.3174L23.6787 66.2488L23.8521 66.4856L21.2136 70.1686L14.393 67.9459L14.2982 67.812L14.2402 67.8961L13.9189 67.791L14.1184 68.0733L11.6026 71.7262L4.72667 68.85L4.62712 68.7006L4.57134 68.7852L4.23106 68.6427L4.44905 68.9706L2.12161 72.5012L-2.20887 65.2812L-2.3333 65.0735L-2.46247 65.2781L-4.76115 68.9311L-8.92471 61.0927L-9.04828 60.8601L-9.18388 61.0858L-11.3899 64.7649L-15.1891 56.4971L-15.1668 56.4584L-15.2342 56.3988L-15.3419 56.1645L-15.3947 56.2576L-20.8859 51.4234L-18.7146 47.4387L-18.6623 47.3431L-18.7378 47.2641L-23.7432 42.0578L-21.6016 37.9488L-21.557 37.8625L-21.6175 37.787L-25.9329 32.4121L-26.1943 32.5374L-24.0818 42.0634L-26.1943 46.1163L-26.2432 46.2107L-26.1702 46.2875L-21.2557 51.48L-23.3776 55.3741L-23.4343 55.4779L-23.3459 55.5565L-17.8084 60.4821L-17.8139 60.4915L-17.7427 60.5447L-17.6457 60.7975L-17.6333 60.7752L-13.8362 68.9714L-13.7182 69.2263L-13.5736 68.9851L-11.4143 65.3846L-7.27868 73.0736L-7.15767 73.2985L-7.02165 73.0826L-4.77402 69.5104L-0.462427 76.5755L-0.340132 76.7759L-0.210976 76.5802L2.16624 72.974L2.51209 73.1526L2.47433 73.0899L8.97948 75.7748L9.07603 75.9198L9.13267 75.8379L9.47251 75.9782L9.25796 75.6559Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.7204 68.5817L-10.5687 48.1359L-29.2971 27.0723L-29.5293 27.2581L-21.8548 37.9663L-21.8463 37.9778L-21.8355 37.9881L-10.7575 48.3702L-10.6879 48.3024L-10.73 48.3908L16.9366 64.2645L16.959 64.2774L16.9843 64.2817L42.6406 68.867L42.7204 68.5817Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.57716 68.7761L-2.26269 65.2279L-8.97209 61.039L-15.2327 56.3961L-18.7045 47.319L-21.5915 37.8363L-21.6975 37.4883L-21.8657 37.811L-24.0567 42.0153L-24.1064 42.1101L-24.0322 42.1869L-19.028 47.3919L-21.204 51.3851L-21.2611 51.4894L-21.1718 51.5679L-15.4785 56.5807L-11.5428 65.1455L-11.4253 65.4021L-11.2798 65.1601L-9.06392 61.4647L-4.90336 69.2975L-4.78279 69.5244L-4.6459 69.3069L-2.33949 65.6415L1.98755 72.8568L2.10941 73.0598L2.23943 72.8624L4.68401 69.1546L11.5569 72.0295L11.653 72.1776L11.7105 72.0939L12.0444 72.2338L11.8354 71.9124L14.4224 68.156L14.5258 68.0059L14.3581 67.9346L7.30367 64.9434L7.20455 64.7911L7.14747 64.8773L6.8132 64.7353L7.0256 65.0622L4.57716 68.7761Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.0518 63.9922L16.9639 64.2767L23.9895 66.6256L23.996 66.6277L24.0028 66.6294L30.7187 68.1939L30.7933 67.9056L17.0518 63.9922Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-6.68842 56.924L-12.9125 52.3339L-16.3968 43.185L-16.3771 43.1486L-16.4324 43.0924L-16.5363 42.819L-16.5946 42.9259L-21.6275 37.7754L-21.876 37.923L-19.0057 47.3503L-19.0332 47.4005L-18.9753 47.4512L-18.8843 47.7498L-18.8688 47.7215L-15.5206 56.4764L-15.551 56.5301L-15.4798 56.5828L-15.3828 56.836L-15.3103 56.7086L-9.25314 61.2012L-9.25915 61.2107L-9.17291 61.2643L-9.07421 61.4814L-9.00642 61.3682L-2.54203 65.4043L-2.54846 65.4146L-2.44633 65.4678L-2.34808 65.6553L-2.28928 65.5617L-1.94987 65.7737L-1.98462 65.7072L4.52096 69.0817L4.62051 69.2507L4.67801 69.1632L5.01871 69.34L4.80288 68.9739L7.3277 65.1442L7.41867 65.0065L7.27235 64.9301L0.267719 61.2759L0.165173 61.1021L0.114101 61.1836L-6.5318 57.036L-6.63606 56.8369L-6.68842 56.924ZM16.9516 63.9586L16.6362 63.8531L16.8297 64.135L14.1774 67.987L14.0641 68.1509L14.2538 68.2127L21.1769 70.4689L21.2683 70.6024L21.3288 70.5183L21.642 70.6208L21.4528 70.3449L24.157 66.571L24.2741 66.4071L24.0832 66.3431L17.1047 64.0097L17.0108 63.8724L16.9516 63.9586Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.79598 60.8596L2.83083 57.2045L2.72914 57.0282L2.67077 57.1204L2.33694 56.9454L2.54977 57.3131L0.174273 61.0879L-4.00044 53.0208L-3.97341 52.9758L-4.05451 52.9161L-4.16135 52.7101L-4.21456 52.799L-10.5361 48.1638L-10.6399 47.9338L-10.6953 48.0304L-16.4628 43.0605L-16.6992 43.2262L-13.1995 52.4141L-13.2304 52.4681L-13.1587 52.5205L-13.0622 52.7741L-12.9892 52.6458L-6.84151 57.1796L-6.84709 57.1891L-6.76126 57.2423L-6.663 57.4603L-6.60079 57.3569L-6.27425 57.598L-6.30642 57.5264L-0.0437088 61.4346L-0.0501499 61.4445L0.0875969 61.5165L0.543293 61.801L0.510254 61.7371L6.98923 65.1171L6.9815 65.1287L7.09994 65.1789L7.19564 65.3441L7.25442 65.2553L7.58955 65.4304L7.55565 65.372L14.1994 68.1895L14.2951 68.3401L14.3539 68.2551L14.6821 68.3942L14.4787 68.0736L17.1336 64.2186L17.2366 64.0693L17.0696 63.9976L9.94702 60.9351L9.84832 60.7798L9.79598 60.8596Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-10.5818 48.1328L-10.7393 48.3851L2.63965 57.4404L2.64866 57.4464L2.65639 57.4503L9.77643 61.1865L9.78158 61.189L9.78672 61.1912L16.9514 64.2721L16.9935 64.1794L17.0844 64.0061L-10.5818 48.1328Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.84924 57.397L2.92862 57.2704L2.80204 57.1915L-4.09273 52.8906L-4.55273 52.6035L-4.30342 53.0854L0.0291972 61.4571L0.148493 61.6884L0.287092 61.4683L2.84924 57.397Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-10.572 48.1387L-10.7432 48.382L-4.25861 53.1364L-4.25433 53.1398L-4.2496 53.1428L2.64517 57.4437L2.80737 57.194L-10.572 48.1387Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};
export default FeaturesTopLeftMobile;
