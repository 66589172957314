import React, {
  type FC,
  type ReactNode,
  useEffect,
  useId,
  useState,
  useRef,
  useMemo,
} from 'react';

import { type NextFont } from 'next/dist/compiled/@next/font';
import { DM_Sans } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

import { type Props } from '../typescript';

import {
  Africa,
  Globe,
  People,
  Target,
  Trophy,
  WesternEurope,
  Nigeria,
} from './icons';
import TheEditionsCard from './TheEditionsCard';
import COMPANY_ICONS from '../public/logos';
// If loading a variable font, you don't need to specify the font weight
const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const WINNER_STATS = [
  {
    title: 'Winners',
    number: 100,
    Icon: Trophy,
  },
  {
    title: 'Countries',
    number: 42,
    Icon: Globe,
  },
  {
    title: 'Nominees',
    number: 35700,
    Icon: People,
  },
  {
    title: 'Attestations',
    number: 592508,
    Icon: Target,
  },
];
const ConnectedAwards: FC<Props & { font: NextFont }> = ({ font }) => {
  const Id = useId();
  const divRef = useRef<HTMLDivElement>(null);
  const companies_ref = useRef<HTMLDivElement>(null);
  const { ref: companiesRef, inView: isCompanyInView } = useInView({
    threshold: 0,
  });
  const { ref: editionContainerRef, inView: editionContainerInView } =
    useInView({
      threshold: 0,
    });
  const [first, setFirst] = useState<boolean>(true);
  const [second, setSecond] = useState<boolean>(false);
  const [third, setThird] = useState<boolean>(false);
  const [fourth, setFourth] = useState<boolean>(false);
  const [last, setLast] = useState<boolean>(false);
  const [scrolledAmount, setScrolledAmount] = useState<number>(0);
  const { ref: commentsRef, inView: commentsInView } = useInView({
    threshold: 0,
  });
  const [loading, setLoading] = useState(false);
  const testimonials = useMemo(
    () => [
      {
        image: 'testimonial-1.png',
        name: 'Esther Omikunle',
        position: 'Technical Product Manager at Cowrywise.',
        details:
          'Winning the Exceptional Product Manager Award is by no means a personal win,it is a community win.',
      },
      {
        image: 'testimonial-2.png',
        name: 'Yousra Nachchach',
        position: 'Data Engineer at Eni Energies and Service',
        details:
          'As the first Moroccan to win this trophy, I believe this will be a motivation to tech experts and women all over the world',
      },
      {
        image: 'testimonial-3.png',
        name: 'Arnaud Gahimbare',
        position: 'Senior Information Technology Officer at CASSOA',
        details:
          'Thank you for the ride,  it was an excellent experience for the contestant and the attesters. ',
      },
      {
        image: 'testimonial-4.png',
        name: 'Olaide Olaniyi',
        position: 'Product Manager at GoProposal by Sage',
        details:
          'Well done to all the winners and to the organizers who put up a transparent competition and being supportive when needed.',
      },
    ],
    []
  );
  const marks = useMemo(
    () => [
      {
        first: true,
        second: false,
        third: false,
        last: false,
      },
      {
        first: false,
        second: true,
        third: false,
        last: false,
      },
      {
        first: false,
        second: false,
        third: true,
        last: false,
      },
      {
        first: false,
        second: false,
        third: false,
        last: true,
      },
    ],
    []
  );
  const [testimonial, setTestimonial] = useState<{
    image: string;
    details: string;
    position: string;
    name: string;
  }>(testimonials[0]);
  const [index, setIndex] = useState(0);
  const [marked, setMarked] = useState<Record<string, boolean>>(marks[0]);
  const imageClasses = [
    '0px 0px 100px 0px',
    '0px 0px 0px 100px',
    '0px 100px 0px 0px',
    '100px 0px 0px 0px',
  ];
  const images = ['award-1.png', 'award-2.png', 'award-3.png', 'award-4.png'];
  const theCompletedEditions = useMemo(
    () => [
      {
        name: 'West Africa Edition',
        icon: <Africa className="h-auto w-6 lg:h-auto lg:w-8" />,
        link: 'west_africa',
        dataAos: 'fade-left',
      },
      {
        name: 'North Africa Edition',
        icon: <Africa className="h-auto w-6 lg:h-auto lg:w-8" />,
        link: 'north_africa',
        dataAos: 'fade-down',
      },
      {
        name: 'East Africa Edition',
        icon: <Africa className="h-auto w-6 lg:h-auto lg:w-8" />,
        link: 'east_africa',
        dataAos: 'fade-right',
      },
      {
        name: 'African in Europe Edition',
        icon: <WesternEurope className="h-auto w-6 lg:h-auto lg:w-8" />,
        link: 'europe_edition',
        dataAos: 'fade-up',
      },
      {
        name: 'Nigeria Edition',
        icon: <Nigeria className="h-auto w-6 lg:h-auto lg:w-8" />,
        link: 'nigeria',
        dataAos: 'fade-left',
      },
    ],
    []
  );
  const onStart = (): void => {
    setLoading(true);
  };

  const onEnd = (): void => {
    setLoading(false);
  };

  const containerProps = {
    'aria-busy': loading,
  };

  function handleScroll(): void {
    const { scrollLeft, clientWidth, scrollWidth } = divRef?.current as {
      scrollLeft: number;
      clientWidth: number;
      scrollWidth: number;
    };
    if (scrollLeft < (scrollWidth - clientWidth) / 5) {
      setFirst(true);
      setSecond(false);
      setThird(false);
      setFourth(false);
      setLast(false);
    } else if (
      scrollLeft >= (scrollWidth - clientWidth) / 5 &&
      scrollLeft < (2 * (scrollWidth - clientWidth)) / 5
    ) {
      setFirst(false);
      setSecond(true);
      setThird(false);
      setFourth(false);
      setLast(false);
    } else if (
      scrollLeft >= (2 * (scrollWidth - clientWidth)) / 5 &&
      scrollLeft < (3 * (scrollWidth - clientWidth)) / 5
    ) {
      setFirst(false);
      setSecond(false);
      setThird(true);
      setFourth(false);
      setLast(false);
    } else if (
      scrollLeft >= (4 * (scrollWidth - clientWidth)) / 5 &&
      scrollLeft < (5 * (scrollWidth - clientWidth)) / 5
    ) {
      setFirst(false);
      setSecond(false);
      setThird(false);
      setFourth(true);
      setLast(false);
    } else if (scrollLeft >= (5 * (scrollWidth - clientWidth)) / 5) {
      setFirst(false);
      setSecond(false);
      setThird(false);
      setFourth(false);
      setLast(true);
    }
  }
  useEffect(() => {
    const divReference = divRef.current;
    divReference?.addEventListener('scroll', handleScroll);
    return () => {
      divReference?.removeEventListener('scroll', handleScroll);
    };
  }, [divRef]);
  useEffect(() => {
    if (isCompanyInView) {
    const upper_bounds = companies_ref.current?.scrollWidth ?? 0;
    const lower_bounds = companies_ref.current?.clientWidth ?? 0;
    const interval = setInterval(() => {
      if (
        (companies_ref.current?.scrollLeft ?? 0) >
        (9.5 * (upper_bounds - lower_bounds)) / 10
      ) {
        companies_ref.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        companies_ref.current?.scrollBy({
          top: 0,
          left: (upper_bounds - lower_bounds) / 10,
          behavior: 'smooth',
        });
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }
  }, [companies_ref, isCompanyInView]);
  console.log({ isCompanyInView });
  useEffect(() => {
    const interval = setInterval(() => {
      if (editionContainerInView) {
        if (
          scrolledAmount <=
          (divRef.current?.scrollWidth ?? 0) -
            (divRef.current?.clientWidth ?? 0)
        ) {
          divRef.current?.scrollBy({
            top: 0,
            left:
              (1.5 *
                (divRef.current?.scrollWidth - divRef.current?.clientWidth)) /
              5,
            behavior: 'smooth',
          });
          setScrolledAmount(
            (prev) =>
              prev +
              (1.5 *
                ((divRef.current?.scrollWidth ?? 0) -
                  (divRef.current?.clientWidth ?? 0))) /
                5
          );
        } else {
          divRef.current?.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
          setScrolledAmount(0);
        }
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [divRef, editionContainerInView, scrolledAmount]);
  const handleTestimonial = (): void => {
    if (commentsInView) {
      if (index === testimonials.length) {
        setTestimonial(testimonials[0]);
        setMarked(marks[0]);
        setIndex(0);
      } else {
        setTestimonial(testimonials[index]);
        setMarked(marks[index]);
        setIndex((currentIndex) => currentIndex + 1);
      }
    }
  };
  const FeaturedOn = ['/punch.png', '/tribune.png'];
  useEffect(() => {
    const interval = setInterval(handleTestimonial, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [index, commentsInView]);
  return (
    <div
      className={`flex w-full flex-col gap-8 pt-12 text-[#0c0114] ${dmSans.className}`}
    >
      <div
        data-aos="fade-up"
        className="mx-auto flex w-full flex-col-reverse overflow-hidden lg:flex-row-reverse lg:pr-16"
      >
        <div className="group relative block h-[640px] w-fit basis-7/12 overflow-hidden p-6 lg:p-12">
          <Image
            src="/images2.png"
            className="absolute max-h-[640px]  min-h-[360px] origin-left scale-x-0 transition-transform duration-1000 group-hover:origin-right group-hover:scale-x-100 lg:h-[473px] lg:w-[515px]"
            width={2056 / 4}
            height={1853 / 4}
            alt="illustration"
            priority
          />
          <Image
            src="/home/award.jpg"
            className="max-h-[640px] min-h-[360px] lg:h-[473px] lg:w-[515px]"
            width={2056 / 4}
            height={1853 / 4}
            alt="illustration"
            priority
          />
        </div>
        <div className="flex w-full basis-7/12 flex-col gap-6 p-6 lg:py-12 lg:pl-16 lg:pr-0">
          <h2
            className={`relative w-fit text-2xl font-medium after:absolute after:-bottom-2 after:left-1 after:h-1 after:w-5/12 after:rounded-full after:bg-golden md:text-3xl lg:text-4xl ${font.className}`}
          >
            The Brand
          </h2>
          <p className="w-full text-justify text-sm font-normal leading-[30px] lg:p-0 lg:text-lg">
            The Connected Awards is a leading global platform that recognizes
            exceptional digital technology professionals. Since our inception in
            2021, we have successfully hosted six (6) editions, where we
            identified and awarded leaders in various tech niches. Our goal is
            to expand our reach and cover all 237 countries in the world, with
            each regional edition taking place once every four (4) years. We
            meticulously screen each expert to ensure they meet our rigorous
            entry criteria, after which we give the power to the public by
            opening up the attestation process. We strive to empower and celebrate
            the brightest minds in the tech industry, who leverage cutting-edge
            technologies such as AI, machine learning, blockchain, and
            cybersecurity to create innovative solutions that transform the
            world we live in.
          </p>

          <Link
            href="/about-us"
            className="mx-auto w-fit cursor-pointer  rounded bg-darkPurple px-6 py-3 text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple lg:ml-0"
          >
            Learn more
          </Link>
        </div>
      </div>
      <div
        data-aos="zoom-in"
        className="bg-winnerStats grid w-full grid-cols-2 justify-center gap-12 py-12 lg:flex lg:flex-row lg:gap-36 lg:py-28"
      >
        {WINNER_STATS.map(({ title, number, Icon }, i) => (
          <div
            key={title}
            className={`flex flex-col items-center gap-2 ${font.className}`}
          >
            <Icon className="mx-auto h-20 w-20" />
            <span className="text-4xl font-bold text-white">
              <CountUp
                start={0}
                end={number}
                duration={20}
                onStart={onStart}
                onEnd={onEnd}
                containerProps={containerProps}
                enableScrollSpy
                scrollSpyOnce
                scrollSpyDelay={1}
                className="text-medium"
              />
            </span>{' '}
            <span className="text-base font-medium text-golden">{title}</span>
          </div>
        ))}
      </div>
      <div
        data-aos="fade-left"
        className="flex flex-col items-center gap-4 lg:items-start lg:px-16"
      >
        <h2
          className={`relative w-fit text-2xl font-medium after:absolute after:-bottom-2 after:left-1 after:h-1 after:w-5/12 after:rounded-full after:bg-golden md:text-3xl lg:text-4xl ${font.className}`}
        >
          The Editions
        </h2>
        {/* <div className="lg:flex hidden flex-col w-full lg:space-x-12 lg:space-y-0 space-y-12 mx-auto py-12">
          {theCompletedEditions.map((data) => (
            <TheEditionsCard key={data.name} {...data} />
          ))}
        </div> */}
        <section ref={editionContainerRef} className="mx-auto block w-11/12">
          <div
            ref={divRef}
            className="imageContainer relative flex w-full gap-10 overflow-x-scroll pb-16 pt-6 scrollbar-hide"
          >
            {theCompletedEditions.map((data) => (
              <TheEditionsCard key={data.name} {...data} />
            ))}
          </div>
          <div className="mx-auto mt-8 flex w-fit flex-row gap-4">
            <div
              onClick={() => {
                // divRef.current!.scrollLeft = 99;
                divRef.current?.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
              className={`h-3 w-3 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                first ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <div
              onClick={() => {
                divRef.current?.scroll({
                  top: 0,
                  left:
                    (1.5 *
                      (divRef.current?.scrollWidth -
                        divRef.current?.clientWidth)) /
                    5,
                  behavior: 'smooth',
                });
              }}
              className={`h-3 w-3 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                second ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <div
              onClick={() => {
                divRef.current?.scroll({
                  top: 0,
                  left:
                    (2.5 *
                      (divRef.current?.scrollWidth -
                        divRef.current?.clientWidth)) /
                    5,
                  behavior: 'smooth',
                });
              }}
              className={`h-3 w-3 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                third ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <div
              onClick={() => {
                divRef.current?.scroll({
                  top: 0,
                  left:
                    (4.5 *
                      (divRef.current?.scrollWidth -
                        divRef.current?.clientWidth)) /
                    5,
                  behavior: 'smooth',
                });
              }}
              className={`h-3 w-3 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                fourth ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <div
              onClick={() => {
                divRef.current?.scroll({
                  top: 0,
                  left:
                    (5 *
                      (divRef.current?.scrollWidth -
                        divRef.current?.clientWidth)) /
                    5,
                  behavior: 'smooth',
                });
              }}
              className={`h-3 w-3 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                last ? 'bg-golden' : 'bg-transparent'
              } `}
            />
          </div>
        </section>
      </div>
      <div
        data-aos="fade-down"
        className="relative mx-auto flex w-full flex-col items-center py-16 pb-32 text-[#0c0114] lg:flex-row lg:pr-16"
      >
        <div className="absolute bottom-0 left-0 -z-10">
          <svg
            className="h-36 w-auto"
            viewBox="0 0 180 180"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M180 180H134.289C134.289 162.365 130.815 144.903 124.067 128.611C117.318 112.319 107.426 97.5156 94.956 85.0464C82.4861 72.5772 67.6821 62.6863 51.3895 55.9385C35.0969 49.1908 17.6347 45.7183 0 45.7194V0C99.4101 0 180 80.5899 180 180Z"
              fill="#3E0563"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.9969 180H63.7124C63.7124 163.113 57.0042 146.918 45.0635 134.977C33.1228 123.036 16.9277 116.328 0.0410156 116.328V80.0437C26.5476 80.0547 51.9654 90.5892 70.7084 109.332C89.4514 128.075 99.986 153.493 99.9969 180Z"
              fill="#FA9706"
            />
          </svg>
        </div>
        <div className="flex w-full flex-col gap-6 p-6 lg:p-12 lg:pb-32 lg:pl-16">
          <span className="flex flex-col gap-4">
            <h2
              className={`relative w-fit text-2xl font-medium after:absolute after:-bottom-2 after:left-1 after:h-1 after:w-5/12 after:rounded-full after:bg-golden md:text-3xl lg:text-4xl ${font.className}`}
            >
              The Winners
            </h2>
          </span>
          <p className="w-full text-justify text-sm font-normal leading-[30px] lg:p-0 lg:text-lg">
            The attestation process is fiercely contested by industry top-shot to
            decide a leader in the category. We give equal opportunities to
            everybody impacting in the digital sector regardless of their years
            of experience or niche. Our categories are classified under a
            broader segment and lower churn with industrial experience serving
            as a marker. We have winners residing and impacting in United State
            of America, United Kingdom, France, Canada, Germany, Nigeria,
            Rwanda, Kenya, Uganda, Algeria and Australia.
          </p>

          <Link
            href="/winners"
            className="mx-auto w-fit cursor-pointer rounded bg-darkPurple px-6 py-3 text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple lg:ml-0"
          >
            Learn more
          </Link>
        </div>
        <div className="grid h-fit w-full grid-cols-2 gap-1 p-6 lg:gap-5">
          {images.map((image: string, i) => (
            <Image
              src={`/home/${image}`}
              key={`${i}-${Id}`}
              width={144}
              height={144}
              className="h-auto w-full rounded-br-3xl"
              alt={image}
              style={{ borderRadius: imageClasses[i] }}
            />
          ))}
        </div>
      </div>
      <div className="flex w-full flex-col">
        <div
          className={`bg-whatPeopleSay flex flex-col items-center gap-6 py-10 text-white ${font.className}`}
          ref={commentsRef}
          data-aos="zoom-out"
        >
          <h2 className="text-xl font-medium md:text-2xl lg:text-3xl">
            The Comments
          </h2>
          <div className="mx-auto flex w-10/12 flex-col items-center gap-6 text-center text-base lg:text-lg">
            {(function (): ReactNode {
              switch (testimonial.name) {
                case testimonials[0].name:
                  return (
                    <Image
                      src={`/home/testimonials/${testimonials[0].image}`}
                      width={144}
                      height={144}
                      alt={testimonial.name}
                      className="h-auto w-36 rounded-full"
                    />
                  );
                case testimonials[1].name:
                  return (
                    <Image
                      src={`/home/testimonials/${testimonials[1].image}`}
                      width={144}
                      height={144}
                      alt={testimonial.name}
                      className="h-auto w-36 rounded-full"
                    />
                  );
                case testimonials[2].name:
                  return (
                    <Image
                      src={`/home/testimonials/${testimonials[2].image}`}
                      width={516}
                      height={144}
                      alt={testimonial.name}
                      className="h-auto w-36 rounded-full"
                    />
                  );
                case testimonials[3].name:
                  return (
                    <Image
                      src={`/home/testimonials/${testimonials[3].image}`}
                      width={144}
                      height={144}
                      alt={testimonial.name}
                      className="h-auto w-36 rounded-full"
                    />
                  );

                default:
                  return (
                    <Image
                      src={`/home/testimonials/${testimonials[0].image}`}
                      width={144}
                      height={144}
                      alt={testimonial.name}
                      className="h-auto w-36 rounded-full"
                    />
                  );
              }
            })()}
            <h3 className="font-medium">{testimonial.details}</h3>
            <div className="flex flex-col items-center font-semibold">
              <span>{testimonial.name}</span>
              <span>{testimonial.position}</span>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <span
              onClick={() => {
                setTestimonial(testimonials[0]);
                setMarked(marks[0]);
                setIndex(1);
              }}
              className={`h-3 w-6 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                marked.first ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <span
              onClick={() => {
                setTestimonial(testimonials[1]);
                setMarked(marks[1]);
                setIndex(2);
              }}
              className={`h-3 w-6 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                marked.second ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <span
              onClick={() => {
                setTestimonial(testimonials[2]);
                setMarked(marks[2]);
                setIndex(3);
              }}
              className={`h-3 w-6 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                marked.third ? 'bg-golden' : 'bg-transparent'
              } `}
            />
            <span
              onClick={() => {
                setTestimonial(testimonials[3]);
                setMarked(marks[3]);
                setIndex(0);
              }}
              className={`h-3 w-6 cursor-pointer rounded-full border-2 border-golden transition-colors duration-300 ease-in hover:bg-golden ${
                marked.last ? 'bg-golden' : 'bg-transparent'
              } `}
            />
          </div>
        </div>
        <div className="mx-auto flex w-full flex-col gap-16 bg-lightPurple200 px-6 py-16 lg:justify-around lg:px-36 lg:py-24">
          <h2
          ref={companiesRef}
            className={`relative w-fit text-2xl font-medium after:absolute after:-bottom-2 after:left-1 after:h-1 after:w-5/12 after:rounded-full after:bg-golden lg:text-4xl ${font.className}`}
          >
            Winners From
          </h2>
          <div
            ref={companies_ref}
            className="flex items-center justify-between gap-16 overflow-x-scroll scrollbar-hide lg:flex-row lg:gap-24"
          >
            {COMPANY_ICONS.map((image, i) => (
              <Image
                src={`/logos/${image}`}
                key={`${i}-${Id}-${image?.split('.')[0]?.replace('/', '')}`}
                width={372}
                height={102}
                className="h-auto w-40"
                alt={image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectedAwards;
