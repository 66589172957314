import React, { type FC } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { LinkedInStroked } from './icons';
import { twMerge } from 'tailwind-merge';

export interface IEditionsProps {
  Category: string
  Edition: string
  Id: string | number
  Name: string
  Linkedin: string
  user_image: string
  user_image_url: string
  className?: string
}

const Editions: FC<Partial<IEditionsProps>> = ({
  Category,
  Edition,
  Name,
  Linkedin,
  user_image,
  user_image_url,
  className
}) => {
  return (
    <div className={twMerge("flex flex-col gap-2 text-center items-center relative group w-[280px] h-72", className)}>
      <>
        {Linkedin && <div className="w-full h-full absolute inset-0 bg-[#1F0231B2] group-hover:opacity-100 z-10 opacity-0 transition-opacity duration-500 ease-in" />}
        <Image
          decoding="async"
          src={user_image_url ?? `https://ui-avatars.com/api/?background=0C6AFF&color=fff&name=${Name?.split(' ')[0]}+${Name?.split(' ')[0]}`}
          alt={Name + ' image'}
          quality={100}
          width={544 * 10}
          height={560 * 10}
          className="w-auto h-72"
          priority
        />
        {Linkedin && <div className="flex absolute flex-col inset-0 m-auto space-y-1 w-fit h-fit z-10 items-center text-white group-hover:opacity-100 opacity-0 transition-opacity duration-500 ease-in">
          <Link
            href={
              Linkedin?.includes('https://')
                ? Linkedin
                : 'https://www.' + Linkedin
            }
            target="_blank"
            className="mb-2"
          >
            <LinkedInStroked className="w-8 h-8" />
          </Link>
          <h3 className="text-base font-medium">{Name}</h3>
          <h5 className="text-xs font-light">{Category}</h5>
          <p className="text-xs font-light">{Edition}</p>
        </div>}
        <div className="absolute w-9/12 bg-[#1F0231]/[0.40] backdrop-blur-md text-white bottom-8 mx-auto py-3 px-1.5 group-hover:opacity-0 opacity-100 transition-opacity duration-500 ease-in">
          <h3 className="text-sm font-medium">{Name}</h3>
          <h5 className="text-xs text-golden font-medium">{Category}</h5>
        </div>
      </>
    </div>
  );
};
export default Editions;
