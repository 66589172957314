import React, { useState } from 'react';
interface Toggle { toggle: boolean, setToggle: () => void }
export type UseToggle = [Toggle, () => void]
const useToggle = (initial: boolean): UseToggle => {
  const [toggle, setToggle] = useState(initial);
  return [
    { toggle, setToggle: () => { setToggle(true); } },
    () => { setToggle(initial); }
  ];
};

export default useToggle;
