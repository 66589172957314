import React, { type FC, useEffect, useRef, useState } from 'react';

import { motion } from 'framer-motion';

import { Props, PropTypes } from '../typescript';

import DropDown from './icons/DropDown';
interface ListProps {
  details: string;
  heading: string;
  isRichText?: boolean;
}
const List: FC<ListProps> = ({ details, heading, isRichText }) => {
  const [height, setHeight] = useState<number>(0);
  const paraEl = useRef<HTMLParagraphElement>(null);
  const [toggle, useToggle] = useState<boolean>(false);
  const styleHeight: string = height + 'px';
  useEffect(() => {
    if (paraEl.current != null) {
      setHeight(paraEl.current.getBoundingClientRect().height);
    }
  }, [toggle]);

  return (
    <div className="mx-auto flex flex-col p-4 bg-lightPurple w-full">
      <div
        className="flex justify-between items-center"
        onClick={() => {
          useToggle((prev) => !prev);
        }}
      >
        <span className="text-base font-medium lg:text-2xl">{heading}</span>
        <DropDown
          className={`h-3 w-3 lg:h-5 lg:w-5 ${toggle ? 'rotate-0' : 'rotate-180'
            } transition-transform duration-200 ease-in-out flex-shrink-0`}
        />
      </div>
      <motion.p
        ref={paraEl}
        initial={{
          height: 0,
        }}
        animate={{
          height: toggle ? 'auto' : 0,
        }}
        transition={{ delay: 0, easeIn: [0.4, 0, 1, 1], duration: 0.3 }}
        className={`overflow-hidden text-sm lg:text-[20px] font-light leading-8 ${toggle ? 'pt-5' : 'pt-0'
          }`}
      >
        {isRichText ? <p dangerouslySetInnerHTML={{ __html: details }} /> : details}
      </motion.p>
    </div>
  );
};

export default List;
