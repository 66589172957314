import { setServers } from 'dns';

import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { DownArrow } from '../icons';

export interface ISelectInputProps {
  label: string
  options: string[]
  selectedOption: string
  setSelectedOption: Dispatch<SetStateAction<string>>
  toggle: boolean
  setToggle: Dispatch<SetStateAction<boolean>>
  error?: boolean
  errorMessage?: string
  placeholder?: string
}

const SelectInput: FC<ISelectInputProps> = ({
  options,
  label,
  selectedOption,
  setSelectedOption,
  toggle,
  setToggle,
  error,
  errorMessage,
  placeholder
}) => {
  const dropDownEl = useRef<HTMLDivElement>(null);
  const [dropDownElementHeight, setDropDownElementHeight] = useState(0);
  const { p: P } = motion;
  useLayoutEffect(() => {
    setDropDownElementHeight(dropDownEl.current?.scrollHeight || 0);
  }, [dropDownEl]);
  return (
    <section>
      <p className="after:ml-2 after:text-red-700 after:content-['*']">
        {label}
      </p>
      <select
        className={`flex w-full cursor-pointer items-center justify-between rounded border border-dark py-3 px-4 bg-white
        ${
          !selectedOption && 'text-[#9da4b0]'
        }
        `}
        value={selectedOption}
        onChange={(e) => { setSelectedOption(e.target.value); }}
      >
        {options.map((option) => (
          <option
            key={option}
            onClick={() => { setSelectedOption(option); }}
            aria-role="button"
            value={option}
          >
            {option || placeholder}
          </option>
        ))}
      </select>
      <AnimatePresence>
        {error && (
          <P
            initial={{
              scale: 0.5,
              opacity: 0
            }}
            animate={{
              scale: 1,
              opacity: 1
            }}
            exit={{
              scale: 0,
              opacity: 0
            }}
            transition={{
              ease: [0.6, 0.01, 0.05, 0.95],
        duration: 0.5,
            }}
            className="text-sm text-red-700"
          >
            {errorMessage}
          </P>
        )}
      </AnimatePresence>
    </section>
  );
};
export default SelectInput;
