import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const Group17: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="85"
      height="101"
      viewBox="0 0 85 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.4528 8.08489L40.4492 8.09067L40.4464 8.09668L37.5216 13.7893L37.52 13.7929L37.518 13.7973L34.8919 19.5981L34.8898 19.602L34.9982 19.6528L34.8864 19.6106L32.5796 25.5026L32.5777 25.507L32.576 25.5118L30.6209 31.4666L30.9615 31.5839L35.2191 19.7508L47.4375 -2.68039L55.1465 -12.8542L54.8618 -13.0754L50.8758 -8.09575L50.8729 -8.09208L50.8701 -8.08838L47.139 -2.88343L47.2358 -2.81253L47.1338 -2.87628L43.6615 2.52032L43.6594 2.52422L43.657 2.52769L40.4528 8.08489Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.2132 19.3732L61.7996 19.4913L62.1741 19.7036L65.4729 21.5745L60.267 23.2481L59.871 23.375L60.2343 23.5772L63.9142 25.6215L64.0793 25.7129L64.1634 25.5444L66.0786 21.7059L68.228 17.9854L70.6033 14.4021L70.8586 14.0171L70.4101 14.1277L64.3928 15.6092L63.9582 15.7161L64.3456 15.9406L67.6162 17.8333L62.2132 19.3732Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M64.5264 15.628L64.3703 15.5373L64.2799 15.6939L62.1962 19.301L62.1868 19.2958L62.1039 19.4608L61.8838 19.842L61.9179 19.8324L60.161 23.3387L59.9686 23.7222L60.3775 23.5908L65.9747 21.7914L66.3661 21.6656L66.0084 21.463L62.7265 19.6019L68.1239 18.0635L68.5331 17.947L68.1651 17.7338L64.5264 15.628ZM66.7916 11.9843L66.3333 12.0783L66.736 12.3163L70.3612 14.4576L70.4997 14.5394L70.5971 14.4113L73.1864 10.9963L73.476 10.6142L73.0068 10.7107L66.7916 11.9843Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.4523 -2.70939L47.1275 -2.86439L34.8958 19.5906L34.8868 19.6077L34.8815 19.6265L27.9203 43.4753L28.2601 43.5946L47.4523 -2.70939Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.4012 27.455L58.3034 27.6833L58.3349 27.672L57.0687 31.2488L56.7792 31.3609L56.989 31.4729L56.9096 31.6975L57.1993 31.5847L60.8672 33.54L61.0529 33.6394L61.1222 33.4405L62.4822 29.5305L62.7667 29.4202L62.5689 29.3128L64.1371 25.6068L64.4381 25.4994L64.2309 25.3845L64.3276 25.1561L64.0182 25.2664L60.5606 23.3454L62.4215 19.632L64.5538 15.9407L70.4963 14.4776L70.9251 14.3718L70.5446 14.1473L67.3218 12.2436L73.0099 11.0779L73.0794 11.1199L73.1387 11.0518L73.5312 10.9712L73.3198 10.8438L75.9708 7.79519L76.2973 7.42011L75.8057 7.49911L69.4617 8.51912L69.3791 8.46908L69.325 8.54096L68.9101 8.60764L69.1608 8.75872L66.7806 11.9238L66.7716 11.9183L66.716 12.0025L60.6917 13.4659L60.255 13.5721L60.6438 13.7972L63.9758 15.7259L58.494 17.263L58.0769 17.3799L58.4538 17.5934L61.8137 19.4987L56.524 21.1674L56.1242 21.2938L56.4906 21.4974L60.0925 23.498L58.5435 27.1219L58.5319 27.1159L58.4899 27.234L58.1908 27.3404L58.4012 27.455Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.8247 13.4852L60.6686 13.3946L60.5781 13.5511L58.3865 17.3464L58.1652 17.7299L58.5914 17.6104L64.485 15.9573L64.8965 15.842L64.5264 15.6281L60.8247 13.4852Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.2275 19.727L34.8849 19.6152L30.6228 31.4615L30.6187 31.4722L30.6165 31.4831L27.9176 43.4861L28.267 43.576L35.2275 19.727Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.9942 17.4853L58.7894 17.3692L60.8519 13.7984L66.799 12.3533L66.875 12.3979L66.9329 12.3207L67.3015 12.2314L67.093 12.1078L69.5258 8.87388L75.7974 7.86536L75.8611 7.90494L75.9207 7.84544L76.3432 7.77793L76.1242 7.64326L78.9041 4.8794L79.3541 4.82801L79.1095 4.67514L79.3161 4.46974L78.8637 4.52107L75.2986 2.28941L75.1765 2.21285L75.0749 2.31534L72.1918 5.22746L72.1829 5.22202L72.1288 5.28462L71.6793 5.33576L71.7105 5.35474L66.154 6.24157L66.1701 6.2199L65.7774 6.29894L65.6973 6.25055L65.6435 6.32291L65.2283 6.38912L65.253 6.40429L59.3497 7.58831L58.8883 7.68048L59.2935 7.92013L62.6432 9.89861L62.6635 9.9107L56.9955 11.2689L56.9016 11.2145L56.85 11.3037L56.4865 11.3905L56.7213 11.5267L54.6904 15.0447L54.6816 15.0397L54.6324 15.137L54.2894 15.2317L54.5102 15.3569L54.3813 15.5806L54.4123 15.5719L52.6422 19.0737L52.312 19.1756L52.5296 19.2965L52.416 19.5209L52.7486 19.4179L56.3392 21.413L56.2252 21.6399L56.2562 21.6301L54.7714 25.0697L54.7603 25.0635L54.7183 25.1804L54.4148 25.2861L54.628 25.4016L54.5307 25.6274L54.5608 25.6168L53.2631 29.2181L52.9693 29.3295L53.1821 29.4429L53.102 29.6649L53.3918 29.5549L56.9983 31.4776L55.9352 35.2945L55.662 35.4091L55.8725 35.519L55.8115 35.7375L56.077 35.6261L59.7807 37.5611L59.9794 37.6646L60.0385 37.4482L61.1032 33.5132L61.3715 33.4004L61.1633 33.2894L61.2224 33.0717L60.9614 33.1816L57.4331 31.3007L58.7736 27.514L59.0625 27.4044L58.8616 27.2951L60.4566 23.5634L60.7615 23.457L60.552 23.3408L60.6486 23.1148L60.339 23.2223L57.0324 21.3852L62.3173 19.7183L62.7121 19.5938L62.3519 19.3895L58.9942 17.4853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.4623 -2.81391L47.3462 -3.39607L47.1188 -2.84775L27.9268 43.4567L28.2495 43.6157L49.388 7.07355L49.4219 7.0149L49.4088 6.94849L47.4623 -2.81391Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.9614 23.062L50.5723 23.1947L50.9333 23.3911L54.7726 25.4805L54.9461 25.5748L55.0245 25.3935L56.7438 21.4108L56.905 21.0379L56.52 21.1686L50.9614 23.062ZM59.4769 7.60982L59.3286 7.52226L59.2344 7.66621L56.8166 11.3621L56.5681 11.742L57.0095 11.6359L63.1807 10.1572L63.6146 10.0536L63.2307 9.82675L59.4769 7.60982ZM68.6351 3.04261L68.5032 2.96151L68.4033 3.07935L65.6068 6.37244L65.2916 6.74442L65.7726 6.66761L72.2482 5.63407L72.7287 5.55749L72.3137 5.30238L68.6351 3.04261Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.4311 -2.88545L47.1088 -2.74352L49.0553 7.01885L49.1422 7.45398L49.3833 7.08159L51.8279 3.3098L51.8956 3.20528L51.8222 3.10513L47.4311 -2.88545ZM30.9681 31.5626L30.6176 31.4773L29.0508 37.4713L29.0495 37.4771L29.0481 37.4836L27.9163 43.4919L28.2695 43.5649L30.9681 31.5626Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.8561 19.0655L52.6828 18.9693L52.6035 19.1511L50.854 23.1611L50.6908 23.5355L51.0776 23.4039L56.6367 21.5104L57.0209 21.3794L56.6657 21.182L52.8561 19.0655ZM75.6641 2.23301L75.6912 2.20542L75.1874 2.26267L74.6318 2.29767L74.6699 2.32137L68.5203 3.017L68.0004 3.0757L68.4461 3.35001L72.1253 5.60956L72.2471 5.68448L72.3476 5.58313L75.23 2.67193L78.7253 4.86L78.8373 4.9299L78.9376 4.84484L82.1144 2.15476L82.5296 1.80305L81.9868 1.83704L75.6641 2.23301ZM56.2979 35.3348L57.385 31.4352L57.4834 31.0807L57.143 31.2198L52.1712 33.2508L51.8114 33.3976L52.1559 33.5777L55.882 35.5244L55.1054 39.4328L55.0369 39.7763L55.3564 39.6322L59.9383 37.5657L60.2763 37.4133L59.948 37.2415L56.2979 35.3348Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.3436 -2.94918L46.8029 -3.13232L47.1405 -2.67213L51.5309 3.3182L51.6773 3.51758L51.8224 3.31773L54.4499 -0.297266L54.5941 -0.495495L54.3619 -0.574082L47.3436 -2.94918Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.2079 39.3032L54.8666 39.4573L55.2003 39.6282L59.0218 41.5858L59.2367 41.6953L59.2814 41.4587L60.0412 37.4348L60.1055 37.0944L59.7902 37.2365L55.2079 39.3032ZM61.8974 4.06588L61.4816 4.13185L61.7355 4.28481L59.2796 7.60452L53.0928 9.06648L52.6512 9.17096L53.0437 9.39766L56.5027 11.4L50.8573 12.931L50.4346 13.0459L50.8157 13.262L54.3024 15.2394L48.8391 16.8969L48.432 17.0199L48.8038 17.2266L52.6814 19.3806L52.8451 19.4716L52.9299 19.3046L54.9158 15.3757L57.1212 11.5552L59.495 7.92699L65.7111 6.68019L65.7827 6.72291L65.8411 6.65408L66.2355 6.57483L66.0192 6.44462L68.6784 3.31289L68.9929 2.94185L68.5126 3.01772L62.0353 4.04403L61.9506 3.99327L61.8974 4.06588ZM57.2959 31.2276L53.6271 29.2715L55 25.4621L55.294 25.3535L55.0808 25.238L55.1599 25.0188L54.8731 25.1244L51.1057 23.0747L50.9225 22.9746L50.8502 23.1709L49.3899 27.1516L49.091 27.262L49.307 27.3773L49.2267 27.5965L49.5168 27.4893L53.1909 29.4479L52.0663 33.368L51.9635 33.7253L52.3076 33.5843L57.2792 31.554L57.635 31.4085L57.2959 31.2276Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.2665 -2.95789L47.2279 -2.6075L54.2462 -0.232399L54.3646 -0.192164L54.4436 -0.288882L57.2449 -3.71487L57.523 -4.05497L57.0859 -4.00808L47.2665 -2.95789Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.9802 9.14783L52.7372 9.52113L52.7694 9.5136L50.8379 12.8602L50.8291 12.8552L50.7458 13.0193L50.53 13.3939L50.5601 13.3856L48.8154 16.8207L48.8061 16.816L48.7287 16.9922L48.5392 17.365L48.5684 17.356L46.9793 20.9606L46.6612 21.067L46.8799 21.1857L46.7831 21.4065L47.0945 21.3028L50.9334 23.3913L51.1061 23.4854L51.185 23.305L52.9017 19.3692L53.2145 19.2649L52.9992 19.1451L53.096 18.9242L52.7872 19.0273L49.351 17.1184L54.8094 15.4627L55.2117 15.3403L54.8461 15.1334L51.3751 13.1647L57.0149 11.6353L57.4324 11.5217L57.0581 11.3052L53.6169 9.31327L59.356 7.95758L59.4336 8.00313L59.4913 7.92547L59.8624 7.83781L59.6494 7.71182L62.0994 4.39903L68.5029 3.38488L68.5694 3.42544L68.6249 3.36844L75.1609 2.62858L75.2202 2.66625L75.2752 2.61878L82.009 2.19723L82.5614 2.16266L82.0948 1.86554L78.6724 -0.316299L78.8941 -0.508269L78.4185 -0.478597L78.3551 -0.518874L78.2992 -0.470937L71.9767 -0.0721813L72.0026 -0.0985952L71.5633 -0.0489658L71.4933 -0.0930519L71.4401 -0.038356L70.9501 -0.00745222L70.9875 0.0160246L64.8382 0.709794L64.7586 0.661193L64.7046 0.724948L64.256 0.775643L64.5242 0.940186L61.9008 4.0684L55.5973 5.31834L55.5081 5.26565L55.4556 5.34664L55.0666 5.42386L55.3111 5.56811L53.0761 9.00019L53.068 8.99542L52.9802 9.14783Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.229 -2.94959L47.3047 -2.59924L57.1239 -3.64992L57.1914 -3.65725L57.2373 -3.70668L60.2031 -6.908L60.0138 -7.20159L47.229 -2.94959Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.4532 33.326L53.5532 29.4924L53.8328 29.3816L53.6177 29.267L53.6789 29.0547L53.4153 29.1595L49.7535 27.207L51.1597 23.3745L51.4581 23.2667L51.2424 23.1495L51.3219 22.9324L51.0343 23.0361L47.344 21.0275L49.0544 17.1468L51.0305 13.2562L51.3621 13.1571L51.1517 13.0379L53.2525 9.39708L53.6014 9.30406L53.3887 9.18133L55.6746 5.67083L61.9649 4.42327L62.0374 4.46668L62.0956 4.3974L62.4904 4.3191L62.2716 4.18722L64.8886 1.06733L71.5368 0.316945L78.3237 -0.110704L81.9007 2.16949L82.0027 2.2344L82.1019 2.16491L85.3603 -0.129683L85.8236 -0.456156L85.2568 -0.457345L78.4238 -0.474781L74.7764 -2.79997L74.6617 -2.87318L74.5602 -2.78349L71.5025 -0.0870888L67.868 -2.36193L67.744 -2.43987L67.6424 -2.33392L64.7669 0.666438L61.0796 -1.59903L60.9462 -1.68105L60.8465 -1.56041L58.1455 1.70078L58.1374 1.69603L58.0838 1.76889L57.6682 1.83414L57.692 1.8486L51.7088 3.02169L51.619 2.96876L51.5669 3.04952L51.1765 3.12624L51.4226 3.27146L49.1737 6.74149L49.1663 6.73699L49.115 6.82542L48.7472 6.91149L48.9776 7.04465L48.8374 7.26062L48.8679 7.25331L46.9165 10.6362L46.9079 10.6317L46.8594 10.728L46.5109 10.8209L46.7365 10.9488L46.6098 11.1675L46.6385 11.1599L44.8195 14.7265L44.4825 14.8264L44.705 14.9502L44.593 15.1697L44.9235 15.0718L48.4419 17.0262L43.0785 18.7705L42.6798 18.9004L43.048 19.1008L46.888 21.1904L45.4468 25.0486L45.1432 25.1577L45.3623 25.2745L45.2813 25.4912L45.5725 25.3863L49.3155 27.382L48.1763 31.2375L47.8925 31.3503L48.1095 31.4641L48.0469 31.677L48.3144 31.5709L52.035 33.5145L51.2344 37.3473L50.9708 37.4624L51.1868 37.5731L51.1439 37.78L51.3853 37.675L55.0888 39.5715L54.6666 43.3611L54.4262 43.4765L54.6418 43.5851L54.6192 43.7839L54.8329 43.6813L58.6242 45.586L58.8581 45.7037L58.8845 45.4424L59.2736 41.5441L59.5095 41.4302L59.2958 41.3207L59.3156 41.1233L59.1067 41.224L55.4853 39.3693L56.2438 35.5546L56.5028 35.4416L56.2883 35.3296L56.3294 35.1236L56.092 35.2273L52.4532 33.326Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.142 10.9676L45.1055 14.9586L45.1033 14.9636L45.1002 14.968L28.2497 43.6158L28.249 43.6167L27.9437 43.4389L47.142 10.9676ZM47.142 10.9676L27.9437 43.4389L47.142 10.9676ZM47.142 10.9676L49.3881 7.07363L49.3886 7.07341L49.0762 6.89274L49.0759 6.89346L27.9403 43.4307L27.9384 43.434L47.142 10.9676ZM27.9437 43.4389L27.9377 43.4349L27.9437 43.4389ZM47.1488 -2.89567L47.2805 -2.76715L47.3428 -2.60787L60.1277 -6.85985L60.1655 -6.87242L60.1947 -6.89926L63.3194 -9.83735L63.1215 -10.1331L48.2734 -3.42284L82.0741 -33.7638L81.8553 -34.0494L63.6517 -22.2413L63.6297 -22.2271L63.6127 -22.2073L47.1488 -2.89567Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M45.0321 14.7195L44.86 14.6233L44.78 14.8037L42.9695 18.8688L42.8046 19.2388L43.1897 19.1132L48.9469 17.2409L49.3407 17.1128L48.979 16.9116L45.0321 14.7195ZM81.7129 -2.78914L81.6093 -2.85618L81.5097 -2.78413L78.2656 -0.441027L77.8153 -0.116257L78.3703 -0.114514L85.2558 -0.0971609L85.8679 -0.0954331L85.3544 -0.428673L81.7129 -2.78914ZM68.2228 -2.41879L68.2473 -2.44453L67.7565 -2.38898L67.2016 -2.35376L67.2387 -2.33077L60.9649 -1.6245L60.4444 -1.56606L60.8905 -1.29199L64.7019 1.04958L64.8261 1.12568L64.9266 1.02086L67.8007 -1.97881L71.4249 0.29009L71.5391 0.361198L71.6399 0.2724L74.7983 -2.51275L75.1936 -2.86166L74.6677 -2.82842L68.2228 -2.41879ZM48.5395 31.2811L49.7026 27.3429L49.8063 26.9922L49.4651 27.1237L44.2742 29.1223L43.9019 29.2659L44.2554 29.4503L48.1185 31.4686L47.2745 35.3167L47.0046 35.4309L47.2246 35.5437L47.1799 35.748L47.4236 35.6454L51.3094 37.6353L51.5195 37.7432L51.568 37.5122L52.3962 33.547L52.6619 33.4348L52.4438 33.3211L52.4862 33.1179L52.2474 33.2188L48.5395 31.2811Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.9383 43.434L28.2474 43.6197L28.2491 43.6172L43.2881 19.0362L43.2943 19.0263L43.2989 19.0154L45.1094 14.9503L44.7891 14.7856L27.94 43.4315L27.9383 43.434Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.2022 -0.569858L53.7859 -0.504827L54.0428 -0.350424L51.5307 3.10577L51.2607 3.47712L51.7112 3.38854L58.1518 2.12583L58.2253 2.16995L58.2831 2.09972L58.6788 2.02213L58.4581 1.88883L61.1239 -1.33048L61.4275 -1.69675L60.9574 -1.62354L54.3412 -0.591688L54.2556 -0.64317L54.2022 -0.569858ZM45.6714 25.0303L45.4776 24.9273L45.4141 25.1372L44.167 29.2381L44.0593 29.5914L44.4041 29.4587L49.5949 27.4601L49.9631 27.3185L49.6147 27.1328L45.6714 25.0303ZM74.6798 -2.82883L74.0595 -2.82949L74.5823 -2.49614L78.2741 -0.142735L78.3777 -0.076863L78.4769 -0.148693L81.7205 -2.49156L82.1715 -2.81727L81.6154 -2.81786L74.6798 -2.82883Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.474 37.3147L51.2443 37.1974L51.2129 37.453L50.7279 41.3788L50.4798 41.494L50.6999 41.6045L50.6754 41.8006L50.891 41.7007L54.7539 43.6411L54.985 43.7578L55.0139 43.5005L55.4501 39.5885L55.6937 39.4761L55.4754 39.3642L55.4965 39.1696L55.2861 39.2671L51.474 37.3147ZM41.3466 23.1337L41.2651 23.3478L41.5569 23.2454L45.5018 25.3489L45.6834 25.4457L45.7557 25.2529L47.2514 21.2487L47.5546 21.1425L47.3356 21.0235L47.4154 20.8091L47.1275 20.9101L43.2206 18.7836L43.0392 18.6849L42.9658 18.8778L41.433 22.9069L41.1244 23.015L41.3466 23.1337Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.94 43.4315L28.2441 43.6248L41.7223 23.1484L41.7335 23.132L41.7402 23.1138L43.3025 19.0062L42.9802 18.8482L27.94 43.4315Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.6301 -2.81763L81.5173 -2.48672L85.1583 -0.12602L85.2494 -0.0668375L85.3449 -0.12008L88.5652 -1.92989L89.0806 -2.21926L88.4913 -2.26673L81.6301 -2.81763ZM64.0521 -4.7512L63.9268 -4.82964L63.8253 -4.72206L60.8541 -1.56891L60.5169 -1.21117L61.0055 -1.26625L67.7883 -2.02993L74.6907 -2.46851L75.2423 -2.50335L74.7765 -2.8002L71.0178 -5.19627L70.9022 -5.27017L70.8 -5.17838L67.7532 -2.43467L64.0521 -4.7512ZM54.9157 43.3192L54.6587 43.1896L54.6546 43.4776L54.601 47.3079L54.5971 47.5987L54.8595 47.4726L58.7831 45.5868L59.114 45.428L58.7862 45.2634L54.9157 43.3192Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.0118 -3.99994L56.5641 -3.9497L56.8348 -3.78349L54.1645 -0.517521L53.8663 -0.152087L54.3319 -0.224888L60.9474 -1.25699L61.0157 -1.21502L61.0741 -1.27686L61.4969 -1.34275L61.2684 -1.48263L63.9865 -4.36676L67.6769 -2.05661L67.7923 -1.98434L67.8931 -2.07543L71.0414 -4.91002L71.4271 -5.25718L70.9092 -5.2243L64.4014 -4.80729L64.4252 -4.83209L63.9407 -4.77791L63.3865 -4.74242L63.4229 -4.71966L57.1466 -4.01505L57.0651 -4.06509L57.0118 -3.99994ZM54.703 47.1482L54.3693 47.3084L54.7013 47.4721L58.6233 49.4081L58.8831 49.5363L58.8833 49.2461L58.8851 45.4247L58.8852 45.1381L58.6271 45.2622L54.703 47.1482ZM78.0063 -5.19233L77.9013 -5.25983L77.8009 -5.18569L74.5721 -2.79337L74.1344 -2.4689L74.6791 -2.46798L81.6152 -2.45721L82.2264 -2.4562L81.7135 -2.78922L78.0063 -5.19233Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.9428 43.4268L28.2379 43.6337L40.4258 27.2786L40.4445 27.2539L40.4534 27.2247L41.7438 23.1034L41.4211 22.9503L27.9428 43.4268Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.5552 29.1998L45.733 25.3274L46.0244 25.2183L45.8024 25.1001L45.8653 24.8928L45.5998 24.9922L41.6567 22.8901L41.465 22.7879L41.3997 22.9952L40.1357 27.0326L39.8398 27.1433L40.0644 27.2608L39.9994 27.4686L40.2693 27.3676L44.1324 29.3859L43.2434 33.2495L42.9661 33.3627L43.1911 33.4776L43.1446 33.6794L43.3896 33.5795L47.2355 35.549L46.7171 39.361L46.461 39.4749L46.6863 39.588L46.6598 39.7811L46.8775 39.6842L50.7108 41.6101L50.6069 45.3342L50.599 45.624L50.8627 45.503L54.9101 43.6438L55.251 43.4877L54.9157 43.3193L51.089 41.3965L51.5581 37.5986L51.8094 37.4865L51.5864 37.3723L51.6096 37.1807L51.3971 37.2752L47.6368 35.3497L48.4808 31.5039L48.7528 31.3928L48.5304 31.2765L48.5742 31.0761L48.3341 31.1741L44.5552 29.1998ZM70.9206 -5.22471L70.3017 -5.22488L70.8237 -4.89223L74.5823 -2.49619L74.6869 -2.42961L74.7868 -2.50352L77.9153 -4.82173L81.2706 -2.64601L81.0257 -2.50407L81.5553 -2.46172L81.6102 -2.42597L81.6571 -2.45331L88.4623 -1.90704L88.576 -2.23721L85.1595 -4.4855L85.4074 -4.62949L84.8771 -4.6709L84.8226 -4.7069L84.7749 -4.67911L78.4022 -5.18282L78.4543 -5.22078L77.9082 -5.221L70.9206 -5.22471ZM60.1664 -7.18374L60.0402 -7.2629L59.9385 -7.15344L56.9729 -3.95163L56.6429 -3.59566L57.1251 -3.64991L63.9767 -4.41927L64.4903 -4.47661L64.0521 -4.75107L60.1664 -7.18374Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.949 43.4177L28.2267 43.647L39.4242 31.3993L39.4556 31.365L39.4666 31.3198L40.4566 27.213L40.1371 27.063L27.949 43.4177Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.7122 45.175L50.3683 45.3332L50.7074 45.501L54.7013 47.472L54.9574 47.5985L54.9615 47.3128L55.015 43.4825L55.0191 43.1968L54.7594 43.3165L50.7122 45.175ZM74.2315 -7.63955L74.1247 -7.70848L74.0238 -7.63181L70.8113 -5.1879L70.3864 -4.86443L70.9205 -4.8641L77.908 -4.86042L78.5183 -4.86012L78.0063 -5.19242L74.2315 -7.63955ZM60.059 -7.21081L59.5011 -7.17469L59.9751 -6.87772L63.861 -4.44577L63.9775 -4.37228L64.0783 -4.46572L67.2157 -7.35158L67.5911 -7.69726L67.082 -7.66403L60.059 -7.21081Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.2736 43.5364L27.9141 43.5063L27.292 49.3147L27.291 49.3233L27.2911 49.3319L27.2192 54.726L27.5794 54.7396L28.2736 43.5364Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M84.8836 -4.66718L81.1886 -7.09872L81.0937 -7.16077L80.9966 -7.10265L77.8153 -5.19566L77.332 -4.90567L77.8939 -4.8611L84.7567 -4.319L88.3776 -1.93646L88.4577 -1.88343L88.546 -1.92052L91.7067 -3.23857L92.2925 -3.48294L91.6657 -3.5827L84.8836 -4.66718ZM40.3649 27.0109L40.1605 26.9038L40.1064 27.1282L39.1381 31.1441L38.8538 31.256L39.0828 31.3731L39.0344 31.5723L39.2814 31.475L43.2011 33.4826L42.6325 37.3051L42.3684 37.4182L42.5986 37.5342L42.57 37.7242L42.7897 37.6304L46.801 39.6456L47.027 39.7589L47.0611 39.5089L47.5963 35.5721L47.855 35.461L47.6267 35.3441L47.6524 35.1554L47.4381 35.2473L43.6055 33.2847L44.494 29.4236L44.7729 29.3137L44.546 29.195L44.5916 28.9972L44.3501 29.0925L40.3649 27.0109ZM54.8612 47.1488L54.5695 47.0049L54.6022 47.3282L54.9377 50.6993L54.7 50.8036L54.9608 50.9302L54.9753 51.0768L55.1263 51.0105L59.0262 52.9058L59.3217 53.0491L59.2842 52.7229L58.8976 49.3575L59.1302 49.2556L58.871 49.1277L58.8544 48.9826L58.7076 49.0469L54.8612 47.1488ZM67.0935 -7.66441L66.4759 -7.66409L66.9965 -7.33194L70.8237 -4.89229L70.9298 -4.82476L71.0298 -4.90098L74.2423 -7.34491L74.6686 -7.66906L74.1332 -7.66868L67.0935 -7.66441Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.9607 43.4035L28.2044 43.6678L38.7442 35.4828L38.8005 35.4393L38.8116 35.3692L39.4693 31.3064L39.5659 30.71L39.1582 31.1558L27.9607 43.4035Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.963 39.3237L46.7139 39.1984L46.7017 39.4771L46.5396 43.3242L46.5271 43.6128L46.7922 43.4969L50.9669 41.6654L51.3182 41.5118L50.9752 41.3396L46.963 39.3237ZM63.2926 -10.1209L63.1745 -10.1965L63.0722 -10.1L59.9475 -7.16194L59.5821 -6.81823L60.0824 -6.85063L67.1051 -7.30429L67.6554 -7.33958L67.1902 -7.63622L63.2926 -10.1209Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.6469 43.1662L46.2926 43.322L46.6398 43.4933L50.6161 45.4555L50.8903 48.7287L50.6415 48.8315L50.9099 48.9618L50.9218 49.1057L51.0754 49.0422L55.0509 50.9742L55.3412 51.1153L55.3095 50.7938L54.9741 47.4255L55.2176 47.3247L54.9508 47.193L54.9367 47.0507L54.7874 47.1122L50.9711 45.2284L51.0749 41.5054L51.0825 41.2209L50.8219 41.3352L46.6469 43.1662ZM88.3286 -5.82691L88.592 -5.94142L88.0204 -6.03215L87.9715 -6.06506L87.9297 -6.04648L81.1558 -7.11994L77.3915 -9.5965L77.2956 -9.6597L77.1977 -9.5995L74.1356 -7.7018L70.5987 -9.99451L70.8132 -10.1622L70.3417 -10.161L70.2789 -10.2023L70.2261 -10.161L63.1952 -10.1494L62.579 -10.1486L63.0987 -9.81717L66.7814 -7.46923L66.5695 -7.30342L67.0408 -7.30387L67.1042 -7.26356L67.1556 -7.30413L74.1263 -7.3078L81.0289 -6.77176L84.4394 -4.52751L84.1793 -4.41459L84.7499 -4.32339L84.7991 -4.2912L84.8402 -4.30886L91.6089 -3.22708L91.7369 -3.55502L88.3286 -5.82691ZM39.3734 31.117L39.1527 31.0039L39.1131 31.2485L38.4716 35.2115L38.1992 35.3241L38.4345 35.4422L38.4041 35.6297L38.6261 35.5381L42.7148 37.5926L42.9378 37.7046L42.9742 37.4581L43.5616 33.5086L43.8284 33.3988L43.5955 33.2795L43.623 33.0934L43.4064 33.1829L39.3734 31.117Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.4241 39.3683L38.5257 39.3253L38.5336 39.2152L38.8137 35.353L38.8427 34.9495L38.5233 35.1975L27.983 43.3827L28.1635 43.6915L38.4241 39.3683Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.1962 -9.78869L70.2353 -9.80018L74.0352 -7.3369L74.1312 -7.27485L74.2282 -7.3346L77.2898 -9.23207L80.7172 -6.97692L80.4682 -6.86364L81.0228 -6.77566L81.0731 -6.7423L81.1147 -6.76133L87.8861 -5.68835L91.5371 -3.25455L91.6052 -3.20956L91.6835 -3.23024L94.7569 -4.04597L94.7532 -4.39549L88.0271 -6.02775L84.5709 -8.33139L84.8232 -8.44625L84.2679 -8.53333L84.2173 -8.56718L84.1753 -8.5479L77.8006 -9.54871L77.8571 -9.58362L77.353 -9.62216L73.5242 -12.1413L73.4259 -12.2057L73.3278 -12.1425L70.2892 -10.195L66.47 -12.6708L66.3608 -12.7421L66.2589 -12.6603L63.0828 -10.1092L62.6823 -9.78783L63.1962 -9.78869ZM42.8767 37.2704L42.6315 37.1472L42.6159 37.4212L42.4023 41.1633L42.141 41.2727L42.3888 41.3951L42.3794 41.5638L42.5696 41.4842L46.5468 43.4472L46.7668 46.7206L46.5066 46.8226L46.7831 46.9566L46.7928 47.098L46.9482 47.0371L51.0021 49.0068L51.2871 49.1452L51.2606 48.8299L50.9783 45.4578L51.2334 45.3583L50.9583 45.2225L50.9467 45.0831L50.7958 45.1425L46.905 43.222L47.0576 39.6065L47.3137 39.4996L47.0675 39.3758L47.0744 39.209L46.8889 39.2862L42.8767 37.2704ZM55.2089 50.6497L54.8672 50.4838L54.9546 50.8534L55.6627 53.849L55.4161 53.9421L55.7187 54.0871L55.7462 54.2032L55.8662 54.1576L59.8257 56.0564L60.1729 56.2231L60.0783 55.8495L59.3195 52.8554L59.5607 52.7643L59.2593 52.6182L59.2302 52.5035L59.1141 52.5476L55.2089 50.6497Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.4825 42.896L38.6547 42.8829L38.649 42.7106L38.5342 39.1966L38.5255 38.9344L38.2836 39.0362L28.0235 43.3592L28.1077 43.7054L38.4825 42.896Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M91.4222 -6.65338L91.7103 -6.7348L91.0728 -6.88887L91.0301 -6.91725L90.9951 -6.90722L84.2431 -8.53678L84.1007 -8.21113L87.5277 -5.92692L87.2436 -5.84683L87.8798 -5.69208L87.9224 -5.66366L87.9569 -5.6735L94.668 -4.04487L94.8116 -4.36937L91.4222 -6.65338ZM66.3858 -12.6992L66.2742 -12.3683L70.1906 -9.82921L70.2878 -9.76598L70.3857 -9.82852L73.5219 -11.839L73.9764 -12.1301L73.4384 -12.1706L66.3858 -12.6992ZM38.7144 35.1795L38.4733 35.0578L38.4538 35.3273L38.1737 39.1895L38.1531 39.4769L38.4208 39.3701L42.8629 37.599L43.2353 37.4507L42.8768 37.2704L38.7144 35.1795Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.2738 43.5179L27.9137 43.5141L27.2197 54.7177L27.2186 54.7379L27.2213 54.7579L28.7644 64.1486L29.1227 64.1117L28.2738 43.5179Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M51.1597 48.6824L50.8254 48.5198L50.9046 48.883L51.5934 52.0318L51.6368 52.2309L51.8289 52.1636L55.1899 50.9819L55.5977 50.8389L55.2089 50.6496L51.1597 48.6824ZM38.2871 39.0349L37.9108 39.1846L38.2739 39.3637L42.4959 41.4477L42.74 41.5685L42.7558 41.2962L42.9761 37.4416L42.9922 37.1593L42.7292 37.2638L38.2871 39.0349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M80.7424 -10.8832L80.9837 -10.998L80.4444 -11.0818L80.3923 -11.1166L80.3499 -11.0967L73.4911 -12.163L69.583 -14.735L69.4835 -14.8006L69.3839 -14.7343L66.2722 -12.6698L65.8342 -12.3793L66.3583 -12.34L73.3649 -11.8146L76.9256 -9.47161L76.6876 -9.35841L77.226 -9.27414L77.2781 -9.23933L77.3197 -9.25952L84.1655 -8.18452L90.9248 -6.55394L94.6097 -4.07064L94.6637 -4.03445L94.7286 -4.0411L97.6842 -4.33862L97.7226 -4.68948L91.0787 -6.88458L87.3171 -9.41899L87.2463 -9.46726L87.1641 -9.44243L84.2302 -8.55855L80.7424 -10.8832ZM51.7094 51.8235L51.2987 51.9678L51.6915 52.1557L55.7339 54.0946L56.8423 56.7982L56.904 56.948L57.0596 56.9031L59.9535 56.0664L60.4078 55.9356L59.9815 55.731L56.0304 53.8363L55.3055 50.7703L55.2594 50.5754L55.0703 50.6417L51.7094 51.8235ZM42.6556 41.1245L42.3803 40.9883L42.3957 41.2951L42.5654 44.6744L42.2936 44.7751L42.5779 44.9134L42.5846 45.0522L42.7428 44.9934L46.8769 47.0022L47.1566 47.1381L47.1356 46.8281L46.9087 43.4535L47.1754 43.3553L46.8927 43.2157L46.8834 43.0786L46.7299 43.1351L42.6556 41.1245Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.937 46.0346L39.1944 46.09L39.1524 45.8295L38.6465 42.6873L38.6201 42.5233L38.4548 42.5365L28.0794 43.3457L28.0557 43.7016L38.937 46.0346Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.1149 56.5119L56.1194 54.0839L56.3951 54.0114L56.0148 53.829L55.9816 53.7475L55.8927 53.7704L51.9268 51.869L51.2918 48.9656L51.5609 48.8772L51.2382 48.7206L51.2147 48.6113L51.094 48.6507L47.4488 46.8792L47.0345 46.678L46.7079 46.5193L42.927 44.6824L42.7627 41.4119L43.0415 41.3148L42.7505 41.1711L42.7437 41.0369L42.588 41.0911L38.4337 39.0407L38.1635 38.9078L38.1734 39.208L38.284 42.5894L37.9994 42.6888L38.2921 42.8311L38.2963 42.9672L38.4569 42.9112L42.5915 44.9197L43.1442 47.9751L43.1802 48.1743L43.3741 48.1154L46.8181 47.0702L47.3776 49.8657L47.1024 49.9559L47.4268 50.1111L47.4487 50.2218L47.5729 50.1812L51.6271 52.1253L52.6063 54.6329L52.3238 54.7069L52.7058 54.8879L52.738 54.9711L52.8297 54.9468L56.5022 56.689L56.9322 56.8929L57.3528 57.0928L61.0151 58.8294L61.4461 59.0341L61.2583 58.5959L60.0693 55.8226L60.0064 55.6763L59.8534 55.7206L57.1149 56.5119ZM80.3104 -10.7376L80.3497 -10.7115L84.1006 -8.21119L84.1714 -8.16406L84.2526 -8.18843L87.1866 -9.07235L90.8977 -6.57177L90.9533 -6.53463L90.9801 -6.53778L97.6093 -4.34656L97.7671 -4.66692L94.0229 -7.2116L93.9676 -7.24947L93.9404 -7.24607L87.8164 -9.26179L87.9071 -9.28908L87.2902 -9.43754L83.7707 -11.8086L84.0317 -11.8925L83.4351 -12.0347L83.3897 -12.0652L83.3542 -12.0538L77.008 -13.5665L77.0718 -13.598L76.5481 -13.6784L76.4948 -13.7144L72.6103 -16.303L72.522 -16.3624L72.4274 -16.3131L69.4007 -14.744L68.9103 -14.4899L69.4174 -14.4118L73.063 -12.0129L72.8357 -11.9003L73.3583 -11.8187L73.4119 -11.7834L73.4535 -11.8036L80.3104 -10.7376Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.5774 54.6996L27.2204 54.7462L27.7065 59.691L27.7078 59.7032L27.7106 59.715L28.7669 64.1607L29.1203 64.0898L27.5774 54.6996Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.2697 47.7703L42.8254 47.905L43.2439 48.1053L47.508 50.1498L47.8335 50.3062L47.7628 49.9522L47.133 46.8047L47.094 46.6099L46.9036 46.6676L43.2697 47.7703ZM93.9923 -7.22846L93.8204 -6.91339L97.5645 -4.36873L97.6037 -4.34269L97.6509 -4.33841L100.451 -4.10046L100.537 -4.4461L93.9923 -7.22846Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.0867 56.5675L56.5138 56.2954L52.9895 54.624L52.0126 52.1212L52.307 52.0517L51.9115 51.8617L51.8804 51.782L51.7897 51.8035L47.664 49.8253L47.262 49.6326L47.4168 50.0504L48.3655 52.6155L48.0642 52.6865L48.4614 52.8752L48.4914 52.956L48.5851 52.9336L52.723 54.8966L54.1588 57.1924L54.2207 57.2913L54.3359 57.2752L56.9202 56.9245L58.2283 58.9477L57.925 58.9967L58.4057 59.2228L58.4417 59.2781L58.503 59.2686L62.5891 61.1914L63.1752 61.4669L62.8161 60.9281L61.3979 58.8006L61.6935 58.7525L61.2147 58.5255L61.1781 58.4704L61.1192 58.4799L57.6346 56.8274L57.0867 56.5675ZM96.7286 -7.04258L90.1918 -9.81487L90.1496 -9.84351L90.1224 -9.84013L83.4418 -12.0301L79.8339 -14.4606L80.0823 -14.5453L79.506 -14.6817L79.4589 -14.7132L79.4232 -14.701L72.5522 -16.3283L72.4102 -16.0029L76.0771 -13.5588L75.8327 -13.4755L76.4084 -13.3382L76.4555 -13.3067L76.49 -13.3189L83.2881 -11.699L87.1155 -9.1196L87.1722 -9.08129L87.1992 -9.08513L93.8411 -6.89889L93.8602 -6.88563L93.8786 -6.88471L100.396 -4.11381L100.568 -4.42842L96.7877 -7.01352L96.7472 -7.04119L96.7286 -7.04258ZM38.5476 42.5543L38.2355 42.403L38.2907 42.7454L38.7708 45.7285L38.4648 45.8146L38.8111 45.9805L38.8286 46.087L38.9579 46.0509L43.2437 48.1056L43.5616 48.2582L43.499 47.9113L42.9591 44.9277L43.2579 44.8433L42.9137 44.6758L42.8945 44.5707L42.7695 44.6059L38.5476 42.5543Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M92.8731 -9.67903L86.3014 -12.4592L86.258 -12.489L82.3495 -15.1451L82.2893 -15.1859L82.2177 -15.1737L79.4733 -14.7042L75.5273 -17.3624L75.4515 -17.414L75.3651 -17.3824L72.449 -16.3223L71.882 -16.1166L72.4688 -15.9778L79.3593 -14.3461L83.261 -11.7172L83.3193 -11.678L83.3463 -11.6818L90.0253 -9.49278L90.0454 -9.47882L90.0637 -9.47792L96.5991 -6.70608L100.364 -4.13118L100.387 -4.11579L100.414 -4.1078L103.017 -3.31327L103.154 -3.64504L96.7791 -7.01992L92.9335 -9.64998L92.8919 -9.67836L92.8731 -9.67903ZM54.2875 56.9177L53.6832 57.0001L54.2351 57.2598L58.4243 59.2309L60.3008 61.1025L60.3521 61.1534L60.386 61.1542L64.5408 63.0998L64.7434 62.8074L62.7924 60.899L62.7416 60.8491L62.7083 60.8485L58.653 58.9402L57.1609 56.6318L57.0988 56.536L56.9854 56.5512L54.2875 56.9177ZM43.3998 47.7801L43.0091 47.5929L43.1516 48.0022L44.0421 50.558L43.7216 50.6262L44.1345 50.8223L44.1619 50.9014L44.2577 50.8809L48.4793 52.8831L49.8567 55.1656L49.9162 55.2645L50.031 55.2521L52.868 54.9353L53.4982 54.8647L52.9251 54.5933L48.749 52.6124L47.8271 50.1204L48.1408 50.0537L47.7295 49.8563L47.7012 49.7787L47.6084 49.7983L43.3998 47.7801Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M75.4825 -17.3845L75.3257 -17.0634L79.3318 -14.3645L79.3918 -14.3243L79.4628 -14.3362L82.2781 -14.8182L82.3034 -15.1673L75.4825 -17.3845ZM49.9911 54.8936L49.3569 54.9644L49.9342 55.2361L54.2057 57.2458L56.0273 59.1007L56.0769 59.1518L56.1478 59.1549L58.5211 59.2613L58.6061 58.9185L54.4365 56.9558L53.001 54.6604L52.9405 54.5644L52.8281 54.5768L49.9911 54.8936ZM39.0524 45.6963L38.6726 45.5136L38.8029 45.9144L39.6338 48.4604L39.2921 48.5256L39.7212 48.7288L39.7466 48.8061L39.8444 48.7875L44.2027 50.8545L44.5923 51.0393L44.4502 50.6321L43.5608 48.081L43.8947 48.0178L43.467 47.8127L43.4405 47.7366L43.3464 47.7546L39.0524 45.6963ZM99.3517 -6.25622L99.3281 -6.27253L99.3181 -6.27519L92.9157 -9.66039L92.7299 -9.35246L96.5843 -6.71628L96.6076 -6.70042L96.6178 -6.69726L102.985 -3.32622L103.172 -3.63413L99.3517 -6.25622Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.003 -12.3383L88.9601 -12.3671L88.9413 -12.3678L82.3343 -15.1551L78.3037 -17.8948L78.2417 -17.9369L78.1683 -17.9227L75.3926 -17.3901L75.371 -17.0416L82.1677 -14.8328L86.1136 -12.1509L86.1558 -12.1222L86.1746 -12.1216L92.7616 -9.33495L92.9335 -9.65003L89.003 -12.3383ZM56.1642 58.7945L56.0795 59.1378L60.3518 61.1384L60.5555 60.8472L58.6566 58.9534L58.6072 58.904L58.5375 58.9009L56.1642 58.7945ZM44.3573 50.5287L43.8327 50.2799L44.1239 50.7823L45.4685 53.0987L45.5259 53.198L45.6402 53.1882L48.6214 52.9233L49.281 52.8644L48.6827 52.5804L44.3573 50.5287Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.155 45.8574L39.154 45.7123L39.0122 45.6823L28.1314 43.3491L27.8181 43.2821L27.9233 43.5847L39.0153 75.49L39.3662 75.4296L39.155 45.8574Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M45.6083 52.8287L44.9442 52.8879L45.5477 53.1718L49.9341 55.2361L50.473 55.4894L50.165 54.9796L48.7598 52.6504L48.7015 52.554L48.5895 52.5638L45.6083 52.8287Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.2638 43.466L27.9135 43.5325L28.7624 64.1264L28.7651 64.1915L28.809 64.2402L39.0519 75.5512L39.3558 75.3714L28.2638 43.466ZM82.1337 -33.8853L81.7777 -33.9391L78.0264 -17.7864L77.9757 -17.567L78.2007 -17.5653L80.8008 -17.5475L80.9694 -17.5467L80.9818 -17.7151L82.1337 -33.8853Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M95.454 -8.93198L95.4316 -8.94712L95.4214 -8.95025L88.9946 -12.3439L84.9938 -15.0795L84.9497 -15.1095L84.9309 -15.1102L78.2725 -17.9119L78.1008 -17.5963L82.1466 -14.8467L82.1904 -14.8171L82.2097 -14.8167L88.8147 -12.0301L92.7301 -9.35247L92.7544 -9.3359L92.7646 -9.33274L99.1562 -5.95325L102.968 -3.33687L102.973 -3.33309L102.979 -3.32953L105.339 -1.95689L105.456 -2.14916L105.531 -2.26171L101.668 -4.9157L101.663 -4.91995L101.66 -4.92113L95.454 -8.93198ZM58.3211 60.5158L60.3536 61.1406L62.6042 62.5187L62.6241 62.531L62.6343 62.5341L66.8609 64.5096L67.0307 64.1926L64.7111 62.7821L64.6914 62.7702L64.6819 62.7673L60.5049 60.8118L56.2624 58.825L54.4408 56.97L54.3923 56.9213L54.3577 56.9191L50.088 54.9096L49.8811 55.1978L51.6681 57.0586L51.7168 57.1089L51.7528 57.1117L56.0706 59.1336L58.279 60.497L58.2986 60.5088L58.3211 60.5158ZM39.9465 48.4364L39.4383 48.1953L39.7115 48.6869L40.8537 50.742L40.443 50.7697L41.0201 51.0409L41.0474 51.0901L41.1151 51.0855L45.548 53.1718L46.0705 53.4175L45.7803 52.9181L44.5819 50.852L44.9819 50.8249L44.4078 50.5528L44.3796 50.5041L44.3147 50.5084L39.9465 48.4364Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M91.481 -11.6587L91.4559 -11.676L91.4457 -11.6791L84.9851 -15.0852L80.9035 -17.8764L80.8583 -17.9076L80.8032 -17.908L78.2032 -17.9258L78.1321 -17.5793L84.8051 -14.7714L88.7992 -12.0401L88.8241 -12.0233L88.8343 -12.0201L95.2695 -8.62244L95.4557 -8.93061L91.481 -11.6587ZM58.4263 60.1711L58.2975 60.5069L62.6216 62.5286L62.7923 62.2112L60.5225 60.821L60.5029 60.8091L60.4813 60.8028L58.4263 60.1711ZM45.7009 52.8455L45.4927 53.132L47.2196 54.9751L47.2674 55.0259L47.304 55.0289L51.7213 57.0973L51.9282 56.8091L50.1411 54.9478L50.0935 54.8987L50.058 54.8957L45.7009 52.8455Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.1007 64.0328L28.7764 64.1896L30.4185 68.0731L30.4255 68.0899L30.436 68.1056L32.6801 71.3492L32.9864 71.1601L29.1007 64.0328Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M97.7268 -7.62347L97.7208 -7.62749L97.7185 -7.6287L91.4767 -11.6616L91.2769 -11.3612L95.2515 -8.63318L95.2575 -8.62915L95.2598 -8.62794L101.468 -4.61569L101.668 -4.91557L97.7268 -7.62347ZM51.8743 56.7708L51.7025 57.087L53.8652 58.4339L53.8848 58.4457L53.9061 58.4527L56.1015 59.1464L56.2324 58.8113L51.8743 56.7708Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M87.4255 -14.4424L87.3995 -14.4603L87.3895 -14.463L80.8854 -17.8873L80.6999 -17.5789L84.79 -14.782L84.816 -14.7641L84.826 -14.7614L91.2947 -11.3505L91.4809 -11.6587L87.4255 -14.4424ZM54.0147 58.1086L53.884 58.4441L58.2974 60.5069L58.4683 60.1899L56.2505 58.8213L56.2313 58.8092L56.2103 58.8027L54.0147 58.1086ZM41.2261 50.7397L41.0158 51.0243L42.6804 52.8485L42.7272 52.8998L42.7957 52.9064L45.6063 53.1877L45.7008 52.8455L41.2261 50.7397Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.8315 52.5477L42.7372 52.8904L47.2749 55.0153L47.4829 54.7284L45.756 52.8852L45.7096 52.8361L45.642 52.829L42.8315 52.5477ZM93.7041 -10.3874L93.6981 -10.3914L93.6958 -10.3926L87.4212 -14.4454L87.2214 -14.1451L91.2773 -11.3616L91.2832 -11.3576L91.2851 -11.3562L97.5267 -7.32378L97.7267 -7.62363L93.7041 -10.3874Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.0488 48.6039L40.0499 48.5727L40.0402 48.5433L39.1461 45.8026L38.7941 45.8596L39.0053 75.4317L39.3659 75.4353L40.0488 48.6039Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.5989 -13.208L89.5922 -13.2123L89.5899 -13.2135L83.2826 -17.2862L83.0828 -16.9859L87.2218 -14.1452L87.2281 -14.1407L87.2304 -14.1395L93.5043 -10.0869L93.7041 -10.3873L89.5989 -13.208ZM42.8901 52.5638L42.7173 52.8794L44.7618 54.1778L44.7807 54.1894L44.8015 54.1966L47.2948 55.0231L47.4278 54.6888L42.8901 52.5638Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.3291 50.9171L41.3334 50.8629L41.3068 50.8152L40.0262 48.5117L39.7055 47.934L39.6885 48.5946L39.0056 75.4261L39.3655 75.445L41.3291 50.9171Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.9151 53.8543L44.7821 54.1887L49.3798 56.3382L49.5524 56.0222L47.4475 54.6993L47.4286 54.6877L47.4079 54.6809L44.9151 53.8543Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.076 63.9979L28.7839 64.2055L32.6696 71.3328L32.6917 71.3724L32.7291 71.397L39.0865 75.5812L39.3192 75.3094L29.076 63.9979ZM97.8396 -33.6268L97.9441 -33.971L94.3698 -35.3061L94.3487 -35.3138L94.3259 -35.3166L90.4309 -35.731L90.3704 -35.3758L97.8396 -33.6268ZM82.1262 -33.9493L81.7791 -33.8525L86.9365 -14.0676L86.9423 -14.0438L86.9548 -14.0227L88.3897 -11.5381L88.7185 -11.6798L82.1262 -33.9493Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.9917 52.7554L43.0051 52.6698L42.9468 52.6056L41.2822 50.7814L41.0026 50.4744L40.9696 50.8885L39.0058 75.416L39.3635 75.4591L42.9917 52.7554Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.034 83.0415L57.9118 83.3804L62.3685 85.2263L62.3842 85.2325L62.4009 85.236L66.9317 86.1676L67.0212 85.8185L58.034 83.0415Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.1261 -33.9496L81.7808 -33.8468L81.7805 -33.8461L88.3727 -11.577L88.3736 -11.574L89.3752 -8.40938L89.376 -8.40632L89.721 -8.51245L89.72 -8.51546L82.1263 -33.9491L82.1261 -33.9496ZM45.0328 54.0717L45.0666 53.9438L44.955 53.8732L42.9105 52.5747L42.679 52.4276L42.6356 52.6989L39.0072 75.4021L39.3599 75.4765L45.0328 54.0717Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M117.435 -19.323L117.187 -19.0646L120.035 -15.6138L119.995 -15.5832L120.168 -15.453L120.265 -15.3358L120.294 -15.358L124.116 -12.4872L124.363 -12.7467L121.446 -16.2369L121.484 -16.2666L121.311 -16.397L121.214 -16.514L121.185 -16.492L117.435 -19.323ZM53.4836 81.1572L53.3615 81.4961L57.9119 83.3803L57.9285 83.3873L57.9367 83.3885L66.9146 86.1631L67.0372 85.824L62.5104 83.9394L62.4937 83.9324L62.4853 83.9307L53.4836 81.1572Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.9273 71.0956L32.7093 71.3822L35.5717 73.8936L35.5906 73.9099L35.613 73.9204L39.1073 75.593L39.2848 75.2798L32.9273 71.0956Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.4446 54.8118L47.5161 54.6292L47.3284 54.5735L44.9097 53.853L44.7313 53.7997L44.6838 53.9793L39.0111 75.3846L39.3532 75.4965L47.4446 54.8118Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.8362 79.2331L48.7141 79.5721L53.3614 81.4965L53.3781 81.5034L53.3959 81.5069L57.7812 82.3578L57.8688 82.0084L48.8362 79.2331Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.885 82.0143L57.8681 82.0069L57.8498 82.0037L53.465 81.1526L53.3773 81.5019L62.3793 84.2759L66.8983 86.1574L66.9332 86.1723L66.9714 86.1711L72.0952 86.0731L72.1246 85.7156L62.4922 83.9322L57.885 82.0143ZM116.19 -18.759L115.945 -18.6243L116.168 -18.4568L120.185 -15.4395L120.432 -15.6988L117.465 -19.2935L117.37 -19.4093L117.239 -19.3369L116.19 -18.759ZM109.467 -25.3368L109.218 -25.0801L112.031 -21.5711L112.114 -21.4681L112.237 -21.5157L113.449 -21.986L113.751 -22.1031L113.493 -22.298L109.467 -25.3368Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.2195 54.918L50.3546 54.663L50.0659 54.6539L47.2827 54.5662L47.1554 54.5621L47.1089 54.6806L39.0175 75.3653L39.3449 75.515L50.2195 54.918Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.0891 77.267L43.967 77.606L48.7147 79.5718L48.7321 79.579L48.7404 79.5807L57.7628 82.3529L57.8852 82.0144L53.1589 80.0463L53.1416 80.0391L53.1327 80.0376L44.0891 77.267ZM120.402 -15.7277L120.132 -15.5028L122.484 -10.8087L122.602 -10.573L122.779 -10.768L124.358 -12.51L124.491 -12.6565L124.333 -12.7751L120.402 -15.7277ZM112.107 -21.8522L111.804 -21.7349L112.064 -21.5395L116.169 -18.4563L116.416 -18.7147L113.524 -22.2678L113.442 -22.3694L113.319 -22.3221L112.107 -21.8522Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.8482 82.0033L57.7462 82.3469L62.3716 84.2727L62.4072 84.2878L62.427 84.2868L72.0589 86.0704L72.1621 85.7269L67.573 83.7915L67.537 83.7767L67.5169 83.7771L57.8482 82.0033ZM122.512 -11.0106L122.378 -10.8632L122.538 -10.7448L126.546 -7.76641L126.814 -7.9934L124.385 -12.7133L124.266 -12.9451L124.091 -12.7521L122.512 -11.0106ZM105.354 -28.4409L105.104 -28.1854L107.834 -24.7242L107.907 -24.6319L108.021 -24.661L109.404 -25.0182L109.765 -25.1118L109.467 -25.3369L105.354 -28.4409ZM39.238 75.2582L39.1166 75.5973L43.967 77.6058L43.9851 77.6132L44.0041 77.6167L48.2268 78.3789L48.3118 78.0291L39.238 75.2582Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.3439 54.3311L53.5798 53.974L53.1594 54.0543L50.0265 54.6568L49.9414 54.6732L49.9005 54.7497L39.026 75.3467L39.3358 75.5303L53.3439 54.3311Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.3285 78.035L48.3104 78.0276L48.2909 78.0243L44.0683 77.2621L43.9836 77.6117L53.0367 80.3851L53.1589 80.0462L48.3285 78.035ZM116.385 -18.7451L116.115 -18.5221L118.387 -13.855L118.494 -13.6345L118.672 -13.803L120.417 -15.4528L120.572 -15.5997L120.402 -15.7278L116.385 -18.7451Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M108.044 -25.0395L105.579 -28.1658L105.695 -28.1839L105.373 -28.4275L105.322 -28.4913L105.293 -28.4873L101.151 -31.6135L100.9 -31.3589L103.355 -28.1899L103.234 -28.1716L103.558 -27.9287L103.608 -27.8642L103.638 -27.8683L107.568 -24.9169L107.831 -24.7191L109.91 -20.1573L110 -19.961L110.177 -20.084L112.099 -21.4149L114.136 -17.0987L114.065 -17.0412L114.215 -16.9299L114.294 -16.7629L114.364 -16.8193L118.269 -13.9175L118.441 -13.7896L122.488 -10.7819L124.052 -5.08123L123.988 -5.00594L124.093 -4.92961L124.168 -4.65785L124.28 -4.79278L128.233 -1.90524L128.659 -1.59442L128.513 -2.10093L126.853 -7.86878L126.914 -7.94223L126.809 -8.01975L126.733 -8.28709L126.624 -8.15749L122.787 -11.0085L120.454 -15.6645L120.346 -15.8815L120.169 -15.7151L118.603 -14.2341L116.502 -18.5511L116.57 -18.6061L116.42 -18.7188L116.34 -18.8839L116.272 -18.8293L112.48 -21.6782L112.298 -21.8148L109.499 -25.3056L109.426 -25.3967L109.313 -25.3673L108.044 -25.0395ZM57.7831 82.3625L57.8029 82.3615L67.4508 84.1316L67.4871 84.1469L72.0216 86.059L72.0745 86.0813L72.1305 86.0692L77.8201 84.8138L77.8078 84.6562L77.8525 84.4722L73.2131 82.4746L73.1589 82.4512L73.1268 82.4587L62.8541 81.8018L62.8453 81.798L58.0865 79.7914L58.0481 79.7753L58.0278 79.7765L48.3104 78.0274L43.3895 75.9786L43.3707 75.971L43.3512 75.9677L39.2164 75.253L39.1332 75.6031L48.1977 78.3716L53.02 80.3792L53.0582 80.3948L57.7461 82.3469L57.7831 82.3625Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.1924 75.2504L39.1547 75.6085L43.2896 76.3232L43.3116 76.3269L43.3344 76.3251L48.1081 75.9532L48.101 75.5932L39.1924 75.2504Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.3518 75.9681L43.251 76.3117L48.1894 78.3682L48.2285 78.3845L48.2485 78.3829L57.9843 80.1351L58.0863 79.7916L53.1815 77.7234L53.1421 77.7067L53.1214 77.7081L43.3518 75.9681ZM118.656 -14.0789L118.245 -14.3839L118.374 -13.8888L119.893 -8.04749L119.976 -7.72775L120.2 -7.9706L122.778 -10.7671L122.914 -10.9145L122.753 -11.0338L118.656 -14.0789ZM109.972 -20.3801L109.765 -20.237L109.966 -20.0869L114.251 -16.9028L114.522 -17.1243L112.335 -21.7609L112.244 -21.9531L112.069 -21.8324L109.972 -20.3801Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.0277 79.7777L57.9464 80.1234L62.7425 82.1461L62.7977 82.1695L62.8286 82.1615L73.0996 82.8185L77.7099 84.8037L77.7793 84.8336L77.8496 84.8047L84.059 82.2651L83.982 81.9182L73.175 82.4584L68.4684 80.4321L68.4129 80.4082L68.3807 80.4157L58.0277 79.7777ZM119.935 -8.21525L119.798 -8.06671L119.962 -7.94735L124.079 -4.93973L124.77 1.80402L124.812 2.2135L125.085 1.90496L128.475 -1.93162L128.606 -2.07978L128.446 -2.1963L124.408 -5.14648L122.82 -10.937L122.733 -11.2505L122.513 -11.0118L119.935 -8.21525ZM103.794 -28.2023L103.244 -28.6151L103.521 -27.9859L105.46 -23.5639L105.364 -23.5069L105.544 -23.3734L105.607 -23.2315L105.679 -23.2734L109.967 -20.0871L110.238 -20.3066L108.208 -24.7627L108.3 -24.8176L108.121 -24.9523L108.057 -25.0928L107.988 -25.052L103.794 -28.2023ZM114.466 -17.1921L114.065 -17.4908L114.184 -17.0044L115.613 -11.1762L115.688 -10.868L115.914 -11.0904L118.675 -13.8056L118.825 -13.9531L118.656 -14.079L114.466 -17.1921ZM48.164 75.6075L48.1237 75.59L48.0798 75.5935L43.3063 75.9658L43.2888 76.3227L53.08 78.0666L53.1815 77.7233L48.164 75.6075Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.1221 77.7093L53.0415 78.0552L57.9459 80.1236L58.0026 80.1475L58.0338 80.1393L68.3549 80.7756L73.0709 82.8059L73.1412 82.8365L73.1828 82.8186L83.9996 82.2783L84.0639 81.9334L79.3884 79.8603L79.3169 79.8285L79.2737 79.8466L68.4296 80.4152L63.6148 78.342L63.5577 78.3172L63.5255 78.3259L53.1221 77.7093ZM115.661 -11.3477L115.51 -11.1986L115.682 -11.0733L119.961 -7.94779L120.369 -7.64929L120.242 -8.13834L118.723 -13.9796L118.645 -14.2814L118.422 -14.0625L115.661 -11.3477ZM124.814 1.666L124.681 1.81625L124.845 1.93236L129.018 4.90852L129.348 5.14402L129.302 4.74126L128.519 -2.07148L128.473 -2.47381L128.205 -2.17031L124.814 1.666ZM110.181 -20.3768L109.789 -20.6684L109.898 -20.1916L111.232 -14.3776L111.3 -14.0808L111.528 -14.2836L114.479 -16.9127L114.644 -17.06L114.467 -17.1921L110.181 -20.3768Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.1491 75.254L39.1783 75.6108L48.0869 75.9536L48.1253 75.9552L48.1606 75.941L59.5507 71.3996L59.4474 71.0557L39.1491 75.254ZM89.9429 -4.7338L89.8746 -4.68042L89.8733 -4.59372L89.8209 -0.156162L89.8153 0.297848L90.1305 -0.0290161L107.054 -17.5679L106.814 -17.8356L89.9429 -4.7338Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M120.174 -8.2386L119.853 -8.47331L119.888 -8.0767L120.491 -1.32536L120.405 -1.23363L120.506 -1.16198L120.534 -0.84569L120.701 -1.02224L124.844 1.93266L125.169 2.16391L125.129 1.767L124.438 -4.97395L124.522 -5.0627L124.422 -5.13573L124.39 -5.44743L124.229 -5.2773L120.174 -8.2386ZM48.1041 75.5934L48.0237 75.9398L53.0412 78.0555L53.0992 78.0799L53.1307 78.071L63.5008 78.6858L68.3258 80.7632L68.398 80.7941L68.4396 80.7762L79.2813 80.2076L83.9178 82.2633L84.0024 82.3008L84.0823 82.2533L90.5342 78.4416L90.4147 78.1084L79.3399 79.8389L74.6038 77.7385L74.5311 77.7068L74.4881 77.7253L63.5756 78.3254L58.6476 76.2037L58.5891 76.1784L58.5567 76.1878L48.1041 75.5934ZM111.288 -14.5526L111.121 -14.404L111.302 -14.2726L115.682 -11.0733L116.081 -10.7821L115.963 -11.2617L114.534 -17.0903L114.463 -17.3816L114.239 -17.1822L111.288 -14.5526Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M103.513 -28.004L106.72 -17.7409L89.871 -0.279027L89.83 -0.236018L89.8223 -0.176807L89.2024 4.62854L89.1189 5.27809L89.5231 4.76303L107.066 -17.581L106.943 -17.6888L107.101 -17.7301L105.866 -23.5271L105.862 -23.5448L105.855 -23.5621L103.851 -28.1302L103.513 -28.004ZM53.1563 54.0554L53.0841 54.0709L53.0428 54.1325L39.0352 75.3315L39.28 75.5848L84.3691 47.8404L84.2372 47.5104L53.1563 54.0554Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M125.054 1.63854L124.782 1.44463L124.769 1.77836L124.488 9.1969L124.387 9.29984L124.482 9.36563L124.468 9.73071L124.688 9.5085L128.82 12.3682L129.094 12.5577L129.103 12.2246L129.301 4.8374L129.4 4.73768L129.306 4.67053L129.315 4.31003L129.103 4.52581L125.054 1.63854ZM105.798 -23.6346L105.415 -23.9195L105.514 -23.4522L106.725 -17.7638L106.628 -17.6861L106.762 -17.5883L106.81 -17.3685L106.93 -17.4656L111.302 -14.2727L111.692 -13.9877L111.584 -14.4586L110.275 -20.1623L110.369 -20.2377L110.235 -20.337L110.186 -20.5533L110.07 -20.46L105.798 -23.6346ZM115.894 -11.3648L115.579 -11.5954L115.608 -11.2052L116.12 -4.44801L116.027 -4.35613L116.132 -4.28069L116.155 -3.97654L116.325 -4.14356L120.573 -1.11347L120.892 -0.885766L120.858 -1.27678L120.255 -8.02435L120.345 -8.11374L120.24 -8.19056L120.213 -8.49009L120.05 -8.32952L115.894 -11.3648ZM85.823 75.9622L85.736 75.9224L85.684 75.9534L74.5548 77.7171L69.7063 75.5673L69.6325 75.5344L69.5895 75.5541L58.6089 76.1866L53.5635 74.0145L53.5036 73.9886L53.4415 74.0072L48.0429 75.6004L48.0836 75.9534L58.5344 76.5475L63.4723 78.673L63.5456 78.705L63.5872 78.686L74.4974 78.0859L79.2423 80.19L79.3284 80.228L79.3783 80.1974L90.4704 78.4644L91.0608 78.3726L90.5184 78.1227L85.823 75.9622Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.2393 4.53988L89.2147 4.57148L89.2057 4.6111L88.0384 9.69477L88.3632 9.83651L107.073 -17.5912L106.783 -17.8044L89.2393 4.53988ZM56.6227 52.7049L56.6064 52.7082L56.5907 52.7141L53.1281 54.0637L53.2305 54.4081L84.3077 47.8636L84.3119 47.8627L84.2414 47.5092L84.2372 47.5101L56.6227 52.7049Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M85.7036 75.9513L85.2064 76.0758L85.6719 76.2896L90.3673 78.4501L90.4663 78.4957L90.5529 78.4289L96.92 73.4969L96.766 73.1793L85.7036 75.9513ZM120.782 -1.40744L120.514 -1.59896L120.498 -1.26923L120.127 6.24869L120.105 6.70274L120.433 6.38718L125.074 1.9152L125.23 1.76478L125.053 1.63852L120.782 -1.40744Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M88.0653 9.63332L88.0508 9.65474L88.0428 9.68002L86.3569 14.9426L86.6817 15.0929L107.077 -17.5976L106.775 -17.7944L88.0653 9.63332Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M111.515 -14.5638L111.204 -14.79L111.228 -14.4072L111.649 -7.56044L111.672 -7.18167L111.951 -7.4383L115.91 -11.0865L116.071 -11.2352L115.894 -11.3647L111.515 -14.5638ZM120.182 6.12781L120.024 6.28053L120.205 6.40592L124.563 9.42218L124.834 9.60932L124.846 9.28047L125.129 1.79247L125.146 1.34511L124.824 1.6556L120.182 6.12781ZM81.0159 73.7507L80.9274 73.7099L80.8761 73.7423L69.6045 75.5543L69.0046 75.6506L69.5604 75.8969L74.4575 78.0682L74.5447 78.1074L74.5948 78.0761L85.7755 76.3042L86.3634 76.2107L85.8228 75.9623L81.0159 73.7507Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.0269 75.6059L48.1447 75.9463L53.5432 74.3531L53.5579 74.3488L53.5719 74.342L59.5635 71.3939L59.417 71.0646L48.0269 75.6059Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.4811 74.0002L53.4205 74.3457L58.5048 76.5348L58.58 76.567L58.6532 76.5321L64.696 73.6718L64.6082 73.329L53.4811 74.0002Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M86.3751 14.9017L86.3671 14.9149L86.3613 14.9292L84.1947 20.266L84.5167 20.4259L107.077 -17.5978L107.079 -17.6012L106.771 -17.7886L106.769 -17.7851L86.3751 14.9017ZM60.398 50.5518L60.3627 50.5562L60.3315 50.5742L56.5668 52.7256L56.6896 53.0595L84.3076 47.8635L84.2516 47.5076L60.398 50.5518Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M64.6919 73.3445L64.6166 73.3106L64.542 73.3462L58.4992 76.2065L58.5864 76.5496L69.6434 75.9124L69.7061 75.5676L64.6919 73.3445ZM111.706 -7.70364L111.543 -7.55282L111.724 -7.42395L116.201 -4.23118L116.514 -4.00773L116.485 -4.39171L115.967 -11.2325L115.94 -11.6039L115.666 -11.3514L111.706 -7.70364ZM124.768 9.12594L124.532 8.96246L124.488 9.24581L123.223 17.2446L123.143 17.7537L123.523 17.4058L129.044 12.3533L129.211 12.2006L129.025 12.0717L124.768 9.12594Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M84.2067 20.2418L84.2027 20.2478L84.1996 20.2545L81.5987 25.5548L81.9164 25.7248L107.08 -17.6012L107.08 -17.6021L106.769 -17.7851L106.769 -17.7837L84.2067 20.2418Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.6048 25.5436L81.6043 25.5438L78.6235 30.6968L78.6232 30.6975L78.9352 30.8784L107.08 -17.6026L106.925 -17.6931L106.768 -17.7838L106.767 -17.7812L81.6048 25.5436Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M92.1903 70.9373L92.089 70.8882L92.0311 70.9342L80.896 73.7397L80.4002 73.8647L80.865 74.0783L85.6719 76.29L85.7725 76.3365L85.8288 76.2918L96.8538 73.5299L97.3379 73.4082L96.8876 73.1922L92.1903 70.9373ZM116.41 -4.52518L116.145 -4.71387L116.125 -4.38929L115.664 3.15773L115.637 3.60314L115.965 3.30153L120.8 -1.12721L120.964 -1.27777L120.782 -1.40713L116.41 -4.52518ZM107.031 -17.8385L106.727 -18.0602L106.744 -17.6845L107.062 -10.8329L107.08 -10.4634L107.36 -10.704L111.526 -14.2812L111.698 -14.4295L111.514 -14.5636L107.031 -17.8385Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M64.3239 47.6465L64.2646 47.6469L64.2177 47.6824L60.3136 50.5861L59.7614 50.9973L60.4442 50.9099L84.2975 47.8653L84.2734 47.506L64.3239 47.6465Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M115.722 3.03538L115.555 3.18847L115.741 3.3167L120.205 6.4061L120.472 6.59038L120.487 6.26669L120.858 -1.25144L120.88 -1.68944L120.556 -1.39312L115.722 3.03538ZM92.0545 70.9286L91.6364 71.0708L92.0343 71.262L96.7318 73.5174L96.8449 73.5718L96.9354 73.4845L103.078 67.5445L102.895 67.2445L92.0545 70.9286ZM76.093 71.4858L76.0033 71.445L75.9515 71.4776L64.5902 73.3309L63.9926 73.4284L64.5459 73.6742L69.56 75.8973L69.6488 75.9363L69.6987 75.9046L80.9691 74.0925L81.554 73.9988L81.016 73.7509L76.093 71.4858Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.0909 75.277L39.2223 75.6073L59.5203 71.4086L59.5706 71.398L59.608 71.3631L84.3984 47.8173L84.18 47.5327L39.0909 75.277Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.5569 71.0672L59.4796 71.033L59.4041 71.0702L53.4126 74.0183L53.5029 74.3605L64.6298 73.6888L64.692 73.3443L59.5569 71.0672ZM107.125 -10.9779L106.95 -10.8279L107.138 -10.6945L111.724 -7.42418L112.031 -7.20476L112.008 -7.58192L111.588 -14.4289L111.565 -14.7912L111.291 -14.555L107.125 -10.9779ZM120.41 6.10942L120.176 5.9476L120.129 6.22813L118.791 14.2123L118.663 14.3247L118.761 14.3903L118.696 14.7764L118.973 14.5323L123.194 17.3509L121.021 25.5005L120.875 25.621L120.972 25.6831L120.861 26.1012L121.194 25.8252L125.499 28.5861L125.708 28.7202L125.771 28.4796L127.899 20.2716L128.041 20.1542L127.946 20.0911L128.054 19.6748L127.727 19.9452L123.597 17.1867L124.833 9.36616L124.958 9.25676L124.861 9.19026L124.922 8.80783L124.652 9.0455L120.41 6.10942ZM87.3792 68.6276L87.2766 68.578L87.1881 68.6493L80.8273 73.7741L80.9844 74.0896L92.1563 71.2743L92.6397 71.1524L92.1903 70.9371L87.3792 68.6276Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.2189 44.1219L68.1283 44.1029L68.0601 44.1648L64.203 47.6941L63.8567 48.0106L64.3259 48.0071L84.2757 47.8671L84.3115 47.5099L68.2189 44.1219Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M108.66 60.4456L98.2768 64.8611L98.2361 64.8403L98.1731 64.9021L87.2499 68.6171L76.0349 71.4591L71.0502 69.1656L70.9595 69.1241L70.8759 69.1791L64.5198 73.3587L64.6479 73.6871L75.992 71.8364L80.865 74.0783L80.9663 74.1251L81.0532 74.0552L87.326 69.0014L92.0342 71.2621L92.1486 71.3169L92.2093 71.2574L102.939 67.6101L102.998 67.6399L103.058 67.5699L103.418 67.4477L103.238 67.357L108.867 60.728L108.66 60.4456ZM111.933 -7.71796L111.672 -7.90383L111.648 -7.58441L111.097 -0.0831001L110.977 0.020335L111.084 0.0936741L111.059 0.427485L111.283 0.231858L115.741 3.31673L116.004 3.49889L116.023 3.17967L116.481 -4.29209L116.596 -4.39256L116.491 -4.46703L116.512 -4.79695L116.294 -4.60773L111.933 -7.71796Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0166 40.0667L71.8901 39.9905L71.7904 40.0996L68.0491 44.1758L67.8352 44.409L68.1448 44.4743L84.2376 47.8629L84.3683 47.5319L72.0166 40.0667Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.4544 71.0539L58.8597 71.1524L59.4106 71.397L64.5461 73.6739L64.6359 73.7135L64.7179 73.6599L71.0738 69.4799L70.9452 69.1517L59.4544 71.0539ZM117.832 34.1427L117.672 34.5751L118.063 34.284L122.446 36.9714L122.635 37.0869L122.71 36.8794L125.747 28.5465L125.912 28.4229L125.815 28.3599L125.972 27.9282L125.588 28.2145L121.278 25.4506L121.089 25.3295L121.011 25.5399L117.902 33.9536L117.732 34.0806L117.832 34.1427ZM115.946 3.02006L115.715 2.8604L115.666 3.13692L114.24 11.1694L114.107 11.2819L114.208 11.3496L114.141 11.7264L114.419 11.4905L118.858 14.4549L119.09 14.6098L119.136 14.335L120.474 6.35249L120.603 6.2429L120.504 6.17451L120.567 5.80093L120.296 6.03046L115.946 3.02006Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M75.6239 35.5791L75.4834 35.3872L75.3363 35.5747L71.7811 40.1108L71.6552 40.2706L71.83 40.3759L84.181 47.841L84.4201 47.5799L75.6239 35.5791ZM106.405 -3.24319L106.765 -3.22309L107.423 -10.8255L107.424 -10.8375L107.423 -10.8494L107.104 -17.7008L106.744 -17.6969L106.405 -3.24319Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M103.963 58.0081L103.686 58.1491L103.796 58.2072L98.1319 64.9434L98.3404 65.2253L108.721 60.8108L108.786 60.8448L108.848 60.757L109.149 60.6292L108.995 60.5474L114.151 53.2176L113.922 52.9531L104.079 57.9495L104.033 57.925L103.963 58.0081ZM116.447 22.7818L116.335 23.1916L116.669 22.9235L121.083 25.7542L121.291 25.8874L121.354 25.6491L123.56 17.3758L123.705 17.2592L123.609 17.1941L123.717 16.7867L123.391 17.0488L119.058 14.1549L118.85 14.0161L118.784 14.2574L116.498 22.5978L116.348 22.7184L116.447 22.7818ZM82.4506 66.2614L82.347 66.2111L82.2583 66.2842L75.9032 71.5102L76.0619 71.8244L87.3458 68.9646L87.8278 68.8422L87.3795 68.627L82.4506 66.2614Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.9511 30.7321L78.8367 30.3808L78.6301 30.6874L75.3289 35.585L75.2583 35.6897L75.3329 35.7922L84.1292 47.793L84.4461 47.6306L78.9511 30.7321Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M93.5536 62.4851L93.4376 62.427L93.3456 62.5185L87.1739 68.662L87.3592 68.9604L98.3281 65.2302L98.7352 65.0916L98.3509 64.8984L93.5536 62.4851ZM113.992 42.3043L113.752 42.464L114.001 42.6097L118.288 45.1148L109.304 50.3969L109.032 50.5567L109.307 50.7102L113.916 53.2716L114.071 53.3577L114.159 53.2043L118.71 45.3614L118.724 45.3697L118.805 45.1982L119.145 44.6112L119.056 44.6638L122.704 36.8946L122.979 36.3087L122.44 36.668L113.992 42.3043ZM70.9304 69.1549L70.4367 69.2806L70.8994 69.4935L75.9419 71.8133L76.0446 71.8605L76.1317 71.7888L82.487 66.5632L82.3283 66.2491L70.9304 69.1549ZM107.347 -10.9878L107.09 -11.1711L107.063 -10.8568L106.405 -3.25445L106.368 -2.82619L106.7 -3.09984L111.943 -7.43211L112.124 -7.58131L111.933 -7.71796L107.347 -10.9878Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M121.174 25.3843L116.866 22.6211L119.116 14.4104L119.265 14.2936L119.166 14.2273L119.276 13.828L118.95 14.0827L114.604 11.1805L116.009 3.26615L116.144 3.15659L116.042 3.08589L116.106 2.72162L115.835 2.94298L111.458 -0.0858435L112.008 -7.5579L112.039 -7.97929L111.713 -7.71022L106.47 -3.37792L106.287 -3.22643L106.482 -3.09089L111.072 0.0857818L109.576 8.05134L109.438 8.16385L109.542 8.23296L109.473 8.60114L109.752 8.37334L114.197 11.3421L111.863 19.6229L111.709 19.7432L111.81 19.8083L111.697 20.2099L112.031 19.9492L116.436 22.775L113.304 31.1328L113.13 31.2598L113.233 31.3226L113.073 31.7478L113.463 31.4637L117.716 34.0714L109.327 39.5771L109.084 39.7366L109.335 39.8833L113.734 42.4537L104.582 47.7715L104.307 47.9305L104.585 48.0849L109.01 50.5447L99.3127 55.4301L99.2653 55.4051L99.1955 55.4887L98.9169 55.629L99.0294 55.6883L93.4262 62.421L88.6379 60.012L88.5205 59.9534L88.4287 60.0465L82.335 66.2054L77.3998 63.8365L77.2949 63.7857L77.2376 63.8343L65.8253 66.7616L65.7911 66.7458L65.7329 66.7856L65.2714 66.9038L65.4418 66.9823L59.3831 71.0828L59.5136 71.4101L70.95 69.5164L71.0037 69.5412L71.0532 69.4992L71.5844 69.4114L71.5129 69.3785L82.3539 66.6146L87.2236 68.9523L87.3383 69.0075L87.4287 68.9175L93.5081 62.8661L98.1891 65.2208L98.3167 65.2848L98.4082 65.1757L104.122 58.3801L104.134 58.3863L104.195 58.2982L104.498 58.1707L104.47 58.156L114.085 53.275L114.388 53.1212L114.091 52.9565L109.758 50.5478L118.737 45.27L119.002 45.1142L118.736 44.9589L114.432 42.4437L122.641 36.9679L122.875 36.8114L122.635 36.664L118.285 33.9972L118.489 33.564L118.412 33.6149L121.331 25.7161L121.499 25.5927L121.4 25.529L121.557 25.1042L121.174 25.3843Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.374 71.0889L59.5851 71.3811L65.909 67.1017L65.9176 67.0958L65.9254 67.0891L72.2619 61.6497L72.0343 61.37L59.374 71.0889ZM102.215 13.5294L102.568 13.5989L106.76 -3.19557L106.765 -3.21481L106.766 -3.23479L107.105 -17.689L106.746 -17.7189L102.215 13.5294Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M116.651 22.4836L112.23 19.6491L114.563 11.372L114.717 11.2557L114.615 11.1877L114.726 10.7961L114.399 11.0436L109.94 8.06512L111.436 0.103997L111.575 -0.00502269L111.47 -0.0776532L111.537 -0.433738L111.264 -0.220063L106.688 -3.38704L106.462 -3.54387L106.409 -3.27412L104.795 4.85416L104.651 4.96708L104.758 5.03835L104.687 5.39716L104.967 5.17728L109.531 8.22556L107.111 16.5732L106.953 16.6932L107.057 16.7595L106.943 17.1534L107.276 16.9005L111.8 19.8013L108.591 28.2412L108.414 28.3682L108.518 28.4317L108.36 28.8497L108.747 28.5725L113.116 31.2507L104.545 36.7807L104.299 36.9403L104.552 37.0881L109.066 39.7262L99.7411 45.0793L99.4642 45.2385L99.7434 45.3937L104.286 47.9183L94.4259 52.8475L94.3783 52.8221L94.3092 52.9059L94.0293 53.0458L94.1441 53.1062L88.5091 59.9474L83.5981 57.4772L83.4803 57.4176L83.388 57.5121L77.2832 63.7804L72.2225 61.3504L72.1164 61.2997L72.0271 61.3765L65.6904 66.8155L65.8532 67.1271L77.3032 64.1896L82.2946 66.5861L82.4107 66.6419L82.501 66.5507L88.5931 60.3932L93.392 62.8074L93.5202 62.8717L93.6118 62.7614L99.3553 55.8606L99.3671 55.867L99.4288 55.7787L99.731 55.6512L99.7037 55.6367L109.476 50.7136L109.78 50.5603L109.483 50.395L105.038 47.9235L114.183 42.6099L114.45 42.4545L114.183 42.2981L109.769 39.7187L118.152 34.2171L118.389 34.0609L118.147 33.9125L113.686 31.1777L113.888 30.7514L113.814 30.7996L116.806 22.8163L116.978 22.6935L116.877 22.6283L117.033 22.211L116.651 22.4836Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M106.754 -17.7517L84.1187 47.5861L59.3597 71.1011L59.5938 71.3752L72.2541 61.6564L72.2684 61.6454L72.2797 61.6318L84.41 47.8053L84.3252 47.7234L84.4339 47.7712L102.553 13.64L102.568 13.612L102.572 13.5811L107.103 -17.6673L106.754 -17.7517Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M108.507 28.4252L104.483 36.8484L99.6781 45.1394L94.3053 52.9044L83.4525 57.4693L72.0861 61.3427L71.668 61.4849L72.0659 61.6761L77.2436 64.1613L77.3603 64.2173L77.4507 64.125L83.5541 57.8585L88.4752 60.3342L88.6042 60.3988L88.6955 60.288L94.5386 53.1945L104.753 48.0884L105.059 47.9353L104.76 47.7695L100.197 45.2337L109.516 39.8837L109.786 39.729L109.517 39.5717L104.987 36.9251L113.551 31.398L113.792 31.243L113.548 31.093L108.962 28.2809L112.169 19.8447L112.344 19.7221L112.241 19.6556L112.397 19.2459L112.016 19.5116L107.365 16.5288L107.179 16.4098L107.099 16.616L103.758 25.2766L103.577 25.4026L103.684 25.468L103.525 25.8788L103.913 25.6084L108.507 28.4252Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M94.6051 42.5372L89.295 50.2563L78.3559 54.8399L78.3113 54.8171L78.2449 54.8862L77.9185 55.0232L78.05 55.0895L72.0141 61.3878L72.2022 61.6837L83.5036 57.8326L83.5651 57.864L83.6243 57.7919L83.9822 57.6697L83.9473 57.6526L94.4152 53.2495L94.4811 53.284L94.5424 53.1959L94.8447 53.0689L94.6876 52.9856L99.8863 45.4727L99.8981 45.4791L99.9593 45.3731L100.218 45.2446L100.079 45.1672L104.708 37.1794L104.721 37.187L104.781 37.0612L105.004 36.9346L104.889 36.8674L105.132 36.4477L105.053 36.4927L108.88 28.4808L109.081 28.3534L108.972 28.2875L109.173 27.8679L108.738 28.1436L104 25.2391L103.83 25.1345L103.744 25.3146L99.5979 33.9414L99.3915 34.0722L99.4917 34.131L94.7346 42.3433L94.4977 42.4778L94.6051 42.5372ZM102.182 13.6338L102.067 14.0195L102.401 13.7738L107.17 16.8322L107.373 16.9629L107.441 16.731L109.897 8.25818L110.055 8.1417L109.951 8.07258L110.062 7.68863L109.736 7.92889L105.059 4.80512L104.855 4.66924L104.786 4.90346L102.238 13.4454L102.075 13.5653L102.182 13.6338Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0083 61.3942L72.2744 61.6378L78.4791 55.1633L78.4845 55.1578L78.4895 55.1513L84.4146 47.7996L84.1391 47.5675L72.0083 61.3942Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M98.7154 22.4217L94.5673 31.0006L94.3579 31.1311L94.4717 31.1978L94.2729 31.6086L94.7095 31.3366L99.375 34.0629L89.7731 39.4388L89.7176 39.4079L89.6486 39.5085L89.4033 39.646L89.513 39.7066L84.1528 47.5452L83.878 47.6803L83.997 47.7433L78.2089 54.9251L78.419 55.2045L89.4046 50.6012L89.4709 50.6366L89.532 50.5481L89.835 50.4212L89.677 50.3373L94.9219 42.7135L94.9334 42.7195L94.9949 42.614L95.255 42.4854L95.127 42.4146L95.4059 42.0094L95.3206 42.0515L99.8033 34.3133L99.816 34.3204L99.8975 34.1503L100.224 33.5876L100.148 33.6301L103.982 25.6506L103.996 25.6594L104.053 25.5136L104.25 25.3915L104.14 25.3242L104.338 24.9113L104.269 24.9546L107.415 16.7981L107.594 16.6761L107.489 16.6083L107.644 16.2054L107.265 16.4645L102.491 13.4035L102.306 13.2846L102.226 13.4894L98.8013 22.2355L98.6166 22.361L98.7154 22.4217Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M84.1152 47.6017L84.4272 47.7826L94.8588 31.2221L94.8667 31.2108L94.8702 31.2016L99.1111 22.4315L99.114 22.4255L99.1166 22.419L102.561 13.6208L102.448 13.5737L102.234 13.4705L84.1152 47.6017Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M84.1217 47.5903L84.4235 47.7882L89.9223 39.7471L89.9259 39.7414L89.9295 39.7356L94.8625 31.2167L94.5541 31.03L84.1217 47.5903Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default Group17;
