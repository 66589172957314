import React, { type FC, type ReactNode } from 'react';

import {
  FeaturesTopLeft,
  FeaturesBottomLeft,
  FeaturesTopRight,
  FeaturesRightGroup
} from './icons';

const Features: FC<{ feature: Array<{ icon: ReactNode, text: string }>, slides: boolean }> = ({ feature, slides }) => {
  return (
    <div className="relative flex flex-col items-center gap-8 px-3 py-12 text-white lg:px-12  lg:py-36">
      <h1 className="z-10 text-3xl font-bold">
        {slides ? 'The Target' : 'The Achievement'}
      </h1>
      <div
        className={`h-auto w-full ${
          slides
            ? 'grid grid-cols-2 gap-6 overflow-hidden lg:grid-cols-4'
            : 'flex flex-wrap'
        } gap-8 overflow-hidden`}
      >
        {feature.map((feature: any) => (
          <div
            className={`z-10 h-auto w-full flex flex-col gap-6 items-center ${
              slides
                ? ''
                : ' basis-1/3 flex-col lg:basis-1/4'
            } mx-auto items-center gap-6 rounded border-2 border-[#735786] p-8`}
            key={feature.text}
          >
            <span className="mx-auto h-24 w-24">{feature.icon}</span>
            <span className="mt-auto text-center font-bold">
              {feature.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
