import React, { type FC } from 'react';

import Image from 'next/legacy/image';

import { theconnectedLogo } from '../../public';
import { type IconProps } from '../../typescript';

const ImageIcon: FC<IconProps> = (props) => {
  return (
    <span {...props}>
      <Image src={theconnectedLogo} alt="image Icon" layout={'responsive'} />
    </span>
  );
};

export default ImageIcon;
