import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const People: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 81 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.796 0.413845C18.3726 0.994286 16.0043 2.37629 14.0215 4.394C11.1024 7.29621 9.61532 10.8618 9.61532 14.8972C9.61532 19.0432 11.1299 22.5535 14.1317 25.5386L16.0869 27.501L14.6274 28.1644C6.94406 31.5641 1.51893 39.2757 0.692771 47.9823L0.5 50H2.42771H4.35542L4.52065 48.6733C5.23666 42.703 7.66007 38.0319 11.8184 34.5216C16.0043 31.039 21.7324 29.3529 26.8546 30.0716C31.6188 30.7349 35.2814 32.5868 38.6687 36.0142C41.9182 39.3033 43.6807 42.9518 44.3417 47.6782L44.6721 50H46.5998H48.5L48.3072 47.9823C47.7014 42.0397 45.2229 36.8157 41.1196 32.8356C38.9165 30.7626 37.457 29.7122 34.9509 28.4408C33.9596 27.9433 33.1334 27.501 33.0783 27.4734C33.0232 27.4458 33.7943 26.6442 34.7857 25.6768C39.5775 21.0609 40.7341 14.0127 37.6773 7.93193C36.5757 5.74837 33.7392 2.87381 31.4811 1.68529C28.3417 0.0545273 24.2935 -0.442993 20.796 0.413845ZM27.1024 4.22816C30.4071 5.11264 33.0232 7.29621 34.4552 10.3642C37.9251 17.827 32.0318 26.4231 23.7978 25.8979C16.0594 25.4004 11.2126 16.9978 14.6549 10.0602C15.9768 7.35149 18.1248 5.52724 21.154 4.47692C23.2194 3.75829 25.1196 3.67537 27.1024 4.22816Z"
          fill="#FA9706"
        />
        <path
          d="M52.796 0.413845C50.3726 0.994286 48.0043 2.37629 46.0215 4.394C43.1024 7.29621 41.6153 10.8618 41.6153 14.8972C41.6153 19.0432 43.1299 22.5535 46.1317 25.5386L48.0869 27.501L46.6274 28.1644C38.9441 31.5641 33.5189 39.2757 32.6928 47.9823L32.5 50H34.4277H36.3554L36.5207 48.6733C37.2367 42.703 39.6601 38.0319 43.8184 34.5216C48.0043 31.039 53.7324 29.3529 58.8546 30.0716C63.6188 30.7349 67.2814 32.5868 70.6687 36.0142C73.9182 39.3033 75.6807 42.9518 76.3417 47.6782L76.6721 50H78.5998H80.5L80.3072 47.9823C79.7014 42.0397 77.2229 36.8157 73.1196 32.8356C70.9165 30.7626 69.457 29.7122 66.9509 28.4408C65.9596 27.9433 65.1334 27.501 65.0783 27.4734C65.0232 27.4458 65.7943 26.6442 66.7857 25.6768C71.5775 21.0609 72.7341 14.0127 69.6773 7.93193C68.5757 5.74837 65.7392 2.87381 63.4811 1.68529C60.3417 0.0545273 56.2935 -0.442993 52.796 0.413845ZM59.1024 4.22816C62.4071 5.11264 65.0232 7.29621 66.4552 10.3642C69.9251 17.827 64.0318 26.4231 55.7978 25.8979C48.0594 25.4004 43.2126 16.9978 46.6549 10.0602C47.9768 7.35149 50.1248 5.52724 53.154 4.47692C55.2194 3.75829 57.1196 3.67537 59.1024 4.22816Z"
          fill="#FA9706"
        />
      </svg>
  );
};
export default People;
