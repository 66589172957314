import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const FootBottom: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="227"
      height="114"
      viewBox="0 0 227 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.9857 73.7321L64.9769 73.7391L64.969 73.7464L57.139 80.8405L57.1338 80.8451L57.1284 80.8505L49.6974 88.2857L49.6922 88.2903L49.8298 88.4322L49.681 88.3019L42.6906 96.0625L42.6848 96.0687L42.6799 96.0752L36.1951 104.138L36.6536 104.517L50.1138 88.7116L82.0386 61.1622L99.8661 49.9282L99.5554 49.4204L90.5007 54.7956L90.4939 54.7996L90.4871 54.8036L81.6957 60.6743L81.8034 60.8404L81.6835 60.6837L73.1801 67.0009L73.1741 67.0052L73.1686 67.0106L64.9857 73.7321Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M92.0398 103.829L91.3318 103.771L91.7867 104.316L95.7958 109.126L86.779 108.683L86.0934 108.65L86.5376 109.173L91.0333 114.472L91.2345 114.71L91.4631 114.498L96.6541 109.681L102.139 105.183L107.894 101.029L108.512 100.583L107.754 100.492L97.5963 99.2815L96.863 99.1939L97.3313 99.7661L101.283 104.593L92.0398 103.829Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M118.675 40.9231L118.402 40.3938L99.5791 49.4075L99.5638 49.415L99.5488 49.4245L81.7028 60.6702L82.0056 61.1827L118.675 40.9231Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M97.7919 99.3887L97.6029 99.1578L97.3728 99.347L92.0545 103.708L92.0437 103.695L91.8195 103.901L91.2582 104.362L91.3162 104.367L86.5619 108.762L86.0415 109.243L86.7494 109.278L96.4444 109.754L97.1219 109.787L96.6872 109.266L92.6986 104.481L101.934 105.244L102.633 105.302L102.188 104.759L97.7919 99.3887ZM103.411 95.0768L102.647 94.9546L103.132 95.5567L107.487 100.975L107.654 101.181L107.878 101.039L113.865 97.2693L114.535 96.8477L113.754 96.724L103.411 95.0768Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.0781 61.1258L81.6663 60.6969L49.708 88.2758L49.6837 88.2965L49.6641 88.3225L25.038 121.121L25.4937 121.503L82.0781 61.1258Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.4488 114.099L81.1655 114.395L81.2206 114.395L77.1833 119.185L76.6712 119.191L76.9301 119.485L76.6769 119.785L77.1899 119.78L81.719 124.935L81.9485 125.196L82.1714 124.929L86.5469 119.679L87.0501 119.673L86.8073 119.393L91.3856 114.579L91.9134 114.589L91.6602 114.291L91.9421 113.994L91.4 113.984L87.1752 109.005L92.2107 104.351L97.6522 99.8879L107.684 101.083L108.408 101.169L107.951 100.601L104.079 95.7858L113.545 97.2932L113.628 97.3984L113.76 97.3269L114.413 97.4317L114.16 97.1111L120.028 93.9434L120.751 93.5533L119.946 93.3899L109.551 91.2756L109.452 91.1505L109.327 91.2304L108.647 91.0921L108.947 91.4712L103.428 94.976L103.417 94.9632L103.284 95.061L93.1246 93.818L92.3877 93.7274L92.8579 94.3021L96.8841 99.2194L87.5201 98.4057L86.808 98.3435L87.2658 98.8931L91.3483 103.791L82.2057 103.292L81.5152 103.255L81.9623 103.782L86.3631 108.968L81.862 113.667L81.8486 113.65L81.715 113.809L81.1899 113.799L81.4488 114.099Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M118.659 40.9306L118.411 40.3894L108.885 44.5834L108.877 44.5869L108.869 44.5915L99.5701 49.4118L99.8362 49.9443L118.659 40.9306Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M93.3188 93.9246L93.1306 93.6939L92.8997 93.8829L87.3055 98.4723L86.7412 98.936L87.4693 98.9992L97.5358 99.8736L98.2392 99.9347L97.7919 99.3887L93.3188 93.9246Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.1409 88.6801L49.6762 88.3085L36.2009 104.132L36.1889 104.146L36.1782 104.161L25.0274 121.136L25.5149 121.478L50.1409 88.6801Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M88.1648 99.0411L87.9157 98.7418L93.1792 94.4244L103.208 95.6512L103.299 95.7646L103.433 95.6788L104.055 95.7549L103.804 95.443L109.444 91.8616L119.72 93.9508L119.796 94.0492L119.922 93.9923L120.615 94.1332L120.355 93.7974L126.257 91.1441L126.983 91.3269L126.693 90.9478L127.132 90.7513L126.403 90.5674L122.194 85.0461L122.049 84.8564L121.833 84.9562L115.685 87.7788L115.674 87.7644L115.554 87.8305L114.829 87.6472L114.866 87.6949L105.766 85.8327L105.803 85.809L105.151 85.7026L105.056 85.5815L104.93 85.662L104.25 85.5229L104.28 85.5602L94.4702 83.9555L93.7034 83.8303L94.1903 84.436L98.2146 89.4415L98.2388 89.4714L88.6919 88.2731L88.5787 88.134L88.4475 88.2427L87.8349 88.1653L88.1185 88.5126L82.9345 92.7663L82.9235 92.7535L82.7907 92.8752L82.2061 92.8218L82.4747 93.144L82.1445 93.4145L82.1982 93.4191L77.4272 97.7994L76.8569 97.7653L77.1231 98.0786L76.8168 98.3589L77.3903 98.3941L81.7782 103.565L81.4697 103.849L81.524 103.852L77.2289 108.301L77.2151 108.286L77.0824 108.442L76.5521 108.429L76.8135 108.732L76.5324 109.024L76.5853 109.025L72.4852 113.834L71.9666 113.835L72.2293 114.135L71.9766 114.43L72.4882 114.429L76.9424 119.498L73.0791 124.776L72.5908 124.795L72.8513 125.087L72.6302 125.389L73.1057 125.371L77.7022 130.514L77.9493 130.79L78.1661 130.491L82.0993 125.03L82.5803 125.012L82.3235 124.719L82.5409 124.418L82.0738 124.435L77.7162 119.477L81.99 114.407L82.5008 114.405L82.2534 114.119L86.8888 109.281L87.4218 109.295L87.1653 108.993L87.4459 108.7L86.9051 108.686L82.8651 103.924L91.9998 104.423L92.6822 104.46L92.2443 103.935L88.1648 99.0411Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.1543 60.9704L82.314 60.0032L81.6433 60.719L25.0589 121.096L25.4645 121.53L79.3788 77.3673L79.4655 77.2961L79.4832 77.1852L82.1543 60.9704Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.5096 102.985L71.8309 102.963L72.2746 103.477L76.9909 108.937L77.2045 109.184L77.4308 108.95L82.4036 103.796L82.87 103.314L82.1992 103.293L72.5096 102.985ZM94.6536 84.0633L94.476 83.8417L94.246 84.0093L88.3608 88.3132L87.7565 88.7561L88.4998 88.8489L98.8943 90.1536L99.6252 90.2458L99.1637 89.6723L94.6536 84.0633ZM111.459 82.3338L111.303 82.1322L111.08 82.2558L104.844 85.7176L104.14 86.1084L104.929 86.2692L115.534 88.4395L116.322 88.5999L115.829 87.9648L111.459 82.3338Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.1482 60.8422L81.5675 60.8735L78.8964 77.0884L78.7771 77.811L79.367 77.3759L85.3372 72.9712L85.5029 72.8489L85.4475 72.6516L82.1482 60.8422ZM36.6761 104.489L36.1838 104.153L30.2768 112.502L30.2713 112.511L30.2656 112.519L25.0217 121.145L25.5256 121.463L36.6761 104.489Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.7618 97.9113L77.5501 97.6618L77.3217 97.8969L72.2874 103.074L71.8169 103.558L72.4912 103.579L82.1809 103.887L82.8506 103.909L82.4169 103.397L77.7618 97.9113ZM122.791 85.1711L122.849 85.1442L122.037 84.9395L121.159 84.6704L121.203 84.729L111.297 82.2271L110.46 82.0155L110.99 82.6982L115.359 88.33L115.504 88.517L115.718 88.4175L121.865 85.5962L125.992 91.0109L126.125 91.1837L126.329 91.1107L132.802 88.8025L133.648 88.5005L132.789 88.2372L122.791 85.1711ZM73.6164 125.049L77.5638 119.656L77.9223 119.166L77.3157 119.183L68.4531 119.429L67.8117 119.447L68.2393 119.926L72.8641 125.1L69.3902 130.687L69.085 131.178L69.6622 131.141L77.9432 130.613L78.5546 130.574L78.1468 130.118L73.6164 125.049Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M82.0497 60.6918L81.3207 60.0946L81.5747 61.0019L84.874 72.8112L84.9837 73.2037L85.3244 72.9795L91.4865 68.9227L91.8247 68.7003L91.5121 68.4438L82.0497 60.6918Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M69.6243 130.547L69.0064 130.586L69.4229 131.044L74.189 136.291L74.4569 136.586L74.6639 136.246L78.1781 130.471L78.4761 129.983L77.9057 130.019L69.6243 130.547ZM100.454 79.9961L99.7741 79.8558L100.077 80.2396L94.3519 83.9408L83.9433 82.6005L83.1999 82.505L83.675 83.084L87.854 88.1891L78.2417 87.2713L77.5218 87.2027L77.985 87.7578L82.2222 92.841L72.8168 92.2244L72.1157 92.1777L72.5709 92.7138L77.3083 98.2966L77.5081 98.5329L77.7361 98.3231L83.0901 93.4083L88.7197 88.7892L94.4976 84.5639L104.827 86.2534L104.912 86.3611L105.042 86.2885L105.697 86.3958L105.44 86.0682L111.369 82.7762L112.072 82.3865L111.285 82.2246L100.681 80.0421L100.579 79.9153L100.454 79.9961ZM77.5468 119.285L73.0163 114.128L77.3537 109.041L77.8701 109.045L77.6086 108.742L77.8585 108.449L77.3537 108.446L72.725 103.088L72.5001 102.826L72.2748 103.087L67.7023 108.389L67.1769 108.385L67.4436 108.689L67.1913 108.981L67.7034 108.983L72.2403 114.147L68.222 119.549L67.8565 120.041L68.4693 120.025L77.3319 119.779L77.9659 119.761L77.5468 119.285Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.936 60.6345L81.6724 61.1522L91.1345 68.905L91.2942 69.0353L91.4725 68.9318L97.792 65.2683L98.4195 64.9039L97.7171 64.7226L81.936 60.6345Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M83.7228 82.6605L83.1298 83.0964L83.1838 83.1029L78.2531 87.1499L78.2429 87.1374L78.0184 87.3421L77.4669 87.7948L77.5184 87.8005L72.8244 92.0928L72.8134 92.0799L72.5904 92.307L72.0817 92.7724L72.1314 92.7756L67.5793 97.4214L67.0266 97.3997L67.296 97.7114L67.0173 97.9956L67.5595 98.0165L72.275 103.477L72.4872 103.722L72.7138 103.49L77.656 98.4072L78.2001 98.4278L77.9374 98.1175L78.2144 97.8327L77.6778 97.8119L73.4789 92.8641L82.8751 93.4811L83.5677 93.5259L83.1239 92.9937L78.9056 87.9324L88.5089 88.8499L89.2199 88.9181L88.7677 88.365L84.6095 83.2868L94.2656 84.5295L94.3586 84.6454L94.492 84.5592L95.1174 84.6392L94.8613 84.3202L100.573 80.6282L111.057 82.7853L111.136 82.8862L111.255 82.8306L121.783 85.4895L121.853 85.5822L121.966 85.5405L132.615 88.8068L133.489 89.0743L132.94 88.3435L128.921 82.9819L129.376 82.8145L128.624 82.5846L128.549 82.4849L128.435 82.5269L118.435 79.4646L118.49 79.4388L117.782 79.2602L117.7 79.1518L117.586 79.2054L116.811 78.9678L116.855 79.0253L106.951 76.5212L106.855 76.3991L106.736 76.4663L106.013 76.2835L106.331 76.6942L100.459 80.0012L89.9938 78.2664L89.886 78.1328L89.7579 78.2271L89.1118 78.1202L89.4056 78.4855L83.9568 82.4889L83.9466 82.4764L83.7228 82.6605Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.8733 60.6247L81.7868 61.2107L97.5687 65.2992L97.6763 65.3266L97.7764 65.2772L104.221 62.0556L104.099 61.4916L81.8733 60.6247Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.8444 119.71L72.7736 114.426L73.2703 114.418L73.0049 114.116L73.223 113.823L72.7554 113.831L68.233 108.686L72.6343 103.582L73.1583 103.589L72.8929 103.282L73.142 102.994L72.6378 102.987L68.1038 97.7365L73.0038 92.7357L78.32 87.8734L78.8897 87.9145L78.6341 87.6075L83.9982 83.2047L84.5909 83.2635L84.334 82.9498L89.9076 78.8563L100.352 80.5866L100.438 80.6965L100.568 80.6231L101.224 80.7314L100.963 80.4008L106.82 77.1025L117.528 79.8104L128.263 83.0968L132.463 88.7003L132.583 88.8605L132.777 88.8103L139.145 87.1607L140.051 86.9263L139.176 86.594L128.63 82.5932L124.347 76.8791L124.212 76.6992L124.003 76.7787L117.71 79.1663L113.418 73.5371L113.271 73.3449L113.053 73.4493L106.866 76.4129L102.487 70.7677L102.328 70.5633L102.104 70.692L96.034 74.1594L96.0246 74.1471L95.899 74.2287L95.2191 74.0876L95.2469 74.1233L85.3215 72.4567L85.2131 72.322L85.0848 72.4171L84.4384 72.3083L84.7341 72.676L79.2413 76.7289L79.2316 76.7174L79.101 76.8245L78.4832 76.7435L78.7612 77.0834L78.4192 77.3353L78.4705 77.3418L73.4874 81.4336L73.478 81.4213L73.3457 81.5414L72.7539 81.4828L73.0275 81.8113L72.7056 82.0763L72.7539 82.0809L67.869 86.5322L67.2901 86.4916L67.5619 86.8123L67.2613 87.0864L67.8286 87.1266L72.1278 92.1928L62.8265 91.7684L62.1357 91.737L62.5877 92.261L67.3054 97.7229L62.8343 102.846L62.3013 102.838L62.5721 103.146L62.3212 103.434L62.8316 103.441L67.4538 108.701L63.452 113.995L62.9469 114.004L63.217 114.306L62.9961 114.599L63.4711 114.591L68.0886 119.758L64.622 125.213L64.1488 125.238L64.4186 125.535L64.2305 125.83L64.6659 125.807L69.2842 130.892L66.4271 136.501L65.9889 136.54L66.2584 136.833L66.1086 137.127L66.4982 137.092L71.2475 142.24L71.5407 142.559L71.7331 142.171L74.6015 136.373L75.0324 136.335L74.7659 136.041L74.9116 135.748L74.5304 135.782L70.0144 130.81L73.4047 125.357L73.8706 125.333L73.6043 125.036L73.7878 124.742L73.3607 124.763L68.8444 119.71Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M73.6433 82.0764L68.1751 87.0584L68.1685 87.0643L68.162 87.0703L25.4647 121.529L25.4634 121.53L25.0957 121.078L25.0948 121.078L73.6433 82.0764ZM73.6433 82.0764L25.0948 121.078L73.6433 82.0764ZM73.6433 82.0764L79.3791 77.3664L79.0013 76.9065L79.0005 76.9063L25.0944 121.063L25.0895 121.067L73.6433 82.0764ZM25.0948 121.078L25.0881 121.069L25.0948 121.078ZM81.7172 60.6615L81.8459 60.9363L81.8495 61.2194L104.076 62.0863L104.141 62.0885L104.202 62.0639L110.738 59.3414L110.605 58.7696L83.7619 60.5009L153.631 33.2841L153.46 32.7165L118.467 40.3712L118.424 40.3805L118.387 40.4012L81.7172 60.6615Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.202 86.646L67.9917 86.3979L67.7635 86.6295L62.6017 91.8569L62.1312 92.3325L62.7995 92.3636L72.7837 92.8185L73.4663 92.8499L73.0244 92.3284L68.202 86.646ZM135.058 80.9311L134.937 80.7668L134.74 80.8205L128.365 82.5531L127.481 82.7931L128.338 83.1186L138.965 87.1504L139.91 87.5092L139.31 86.6955L135.058 80.9311ZM114 73.6563L114.053 73.6308L113.262 73.4305L112.385 73.1625L112.428 73.2197L102.324 70.6616L101.486 70.4495L102.016 71.1325L106.543 76.9673L106.69 77.1567L106.906 77.0526L113.092 74.0904L117.371 79.7036L117.506 79.8799L117.714 79.8018L124.214 77.335L125.028 77.0268L124.195 76.7723L114 73.6563ZM63.9872 114.275L68.0758 108.866L68.4394 108.385L67.8361 108.389L58.654 108.458L57.9953 108.463L58.4339 108.954L63.2289 114.319L59.6866 119.773L59.2026 119.792L59.4778 120.095L59.2898 120.385L59.7252 120.367L64.5711 125.702L64.8336 125.992L65.0424 125.662L68.6284 120.018L69.104 120L68.8326 119.696L69.0168 119.407L68.5899 119.424L63.9872 114.275Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.0898 121.067L25.4596 121.535L25.4634 121.531L62.9959 92.3008L63.0117 92.2889L63.0253 92.2754L68.1872 87.0481L67.7884 86.6073L25.0936 121.064L25.0898 121.067Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M91.2623 68.3573L90.5816 68.216L90.8878 68.6036L84.9965 72.4823L84.3637 72.8992L85.1105 73.0249L95.7965 74.8192L95.8846 74.9306L96.0142 74.8559L96.6709 74.9664L96.4073 74.6323L102.399 71.2082L103.081 70.8189L102.312 70.6584L91.4892 68.4045L91.387 68.2755L91.2623 68.3573ZM63.192 102.948L62.9524 102.677L62.7319 102.963L58.4196 108.574L58.0479 109.058L58.6577 109.053L67.8406 108.985L68.4914 108.979L68.0617 108.491L63.192 102.948ZM124.214 76.7787L123.256 76.4164L123.87 77.2358L128.205 83.0191L128.326 83.1807L128.521 83.1272L134.897 81.3949L135.782 81.1536L134.923 80.8295L124.214 76.7787Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M137.064 35.1545L136.898 34.5823L127.887 36.9988L127.876 37.0015L127.864 37.0057L118.431 40.3817L118.62 40.9462L137.064 35.1545Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M65.0113 125.302L64.7259 124.987L64.5281 125.364L61.4951 131.147L61.0449 131.181L61.3206 131.48L61.169 131.769L61.5605 131.74L66.3998 136.984L66.6891 137.299L66.8838 136.918L69.832 131.128L70.2745 131.095L70.0021 130.795L70.1486 130.508L69.7658 130.536L65.0113 125.302ZM57.6135 97.5019L57.3631 97.7858L57.8729 97.7975L62.7451 103.341L62.9693 103.596L63.1929 103.341L67.832 98.0246L68.3636 98.0365L68.0942 97.7248L68.3418 97.441L67.8385 97.4287L63.0383 91.8708L62.8158 91.6131L62.5905 91.8683L57.8794 97.2016L57.3389 97.1894L57.6135 97.5019Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.0932 121.064L25.451 121.54L58.1855 97.7436L58.2119 97.7244L58.233 97.6999L63.0363 92.2629L62.63 91.8314L25.0932 121.064Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.945 80.8384L134.579 81.2842L138.831 87.0486L138.938 87.1936L139.116 87.1661L145.144 86.2439L146.108 86.0963L145.226 85.6798L134.945 80.8384ZM108.913 67.6267L108.765 67.4324L108.545 67.54L102.12 70.683L101.391 71.0392L102.179 71.2383L113.102 74.0041L124.021 77.3412L124.894 77.6084L124.347 76.8781L119.933 70.9901L119.797 70.8089L119.586 70.8913L113.283 73.3586L108.913 67.6267ZM66.8368 136.581L66.5149 136.231L66.3412 136.674L64.0306 142.561L63.855 143.009L64.3334 142.966L71.4926 142.334L72.0962 142.281L71.6853 141.836L66.8368 136.581Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.8282 60.6266L81.8809 61.2191L110.643 59.3642L110.681 59.3617L110.717 59.3502L117.108 57.2571L116.982 56.6778L81.8282 60.6266Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M97.5987 64.6924L96.8778 64.5094L97.1993 64.9239L91.1733 68.417L90.5005 68.8077L91.2623 68.9665L102.083 71.2199L102.165 71.3246L102.291 71.2631L102.982 71.4072L102.711 71.0567L108.587 68.1826L112.945 73.8985L113.081 74.077L113.291 73.9951L119.803 71.4464L120.601 71.1343L119.782 70.8845L109.485 67.7433L109.536 67.7188L108.756 67.5203L107.879 67.2533L107.921 67.3095L97.8157 64.7471L97.7185 64.6225L97.5987 64.6924ZM64.2818 142.373L63.6728 142.427L64.0905 142.873L69.0239 148.145L69.3504 148.495L69.5197 148.046L71.7453 142.143L71.9118 141.7L71.4402 141.742L64.2818 142.373ZM130.728 75.062L130.606 74.8955L130.408 74.9522L124.027 76.7708L123.162 77.0174L124.003 77.3354L134.713 81.3862L135.657 81.7428L135.058 80.9313L130.728 75.062Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1016 121.059L25.437 121.55L53.78 103.371L53.8227 103.344L53.8536 103.303L58.2449 97.6866L57.8352 97.2621L25.1016 121.059Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.0425 108.741L63.1146 103.443L63.6272 103.444L63.3532 103.133L63.571 102.848L63.1029 102.847L58.2334 97.3066L57.9968 97.0369L57.7757 97.3199L53.4741 102.822L52.9525 102.821L53.2317 103.133L53.0106 103.416L53.486 103.417L58.2808 108.783L54.6595 114.235L54.1655 114.248L54.4461 114.556L54.257 114.841L54.6942 114.829L59.49 120.109L56.4723 125.697L56.01 125.724L56.2922 126.03L56.1393 126.313L56.5321 126.29L61.3342 131.495L59.0078 137.188L58.8265 137.632L59.3046 137.598L66.6393 137.08L67.2567 137.036L66.8366 136.581L62.0423 131.385L64.9764 125.789L65.4295 125.763L65.1511 125.456L65.2995 125.174L64.9156 125.197L60.2267 120.035L63.766 114.584L64.2519 114.57L63.9759 114.262L64.1604 113.977L63.7323 113.989L59.0425 108.741ZM119.8 70.8905L118.844 70.5301L119.457 71.3478L123.87 77.2358L123.993 77.3999L124.19 77.3434L130.371 75.5816L134.291 80.8939L133.829 80.9712L134.623 81.3449L134.687 81.4318L134.776 81.4166L144.972 86.2193L145.339 85.7749L141.369 80.3146L141.835 80.2362L141.041 79.8633L140.977 79.7756L140.887 79.7914L131.335 75.3067L131.436 75.2777L130.593 74.9599L119.8 70.8905ZM104.324 61.6088L104.175 61.4131L103.954 61.5232L97.5092 64.7445L96.7932 65.1023L97.5694 65.2987L108.603 68.0964L109.43 68.3055L108.913 67.6269L104.324 61.6088Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1156 121.049L25.411 121.564L49.8357 109.154L49.9037 109.12L49.9469 109.056L53.8652 103.287L53.4588 102.869L25.1156 121.049Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M115.44 64.9943L115.302 64.8117L115.089 64.8971L108.565 67.5313L107.784 67.8464L108.589 68.0916L119.609 71.4536L120.48 71.7194L119.933 70.9902L115.44 64.9943Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M81.8226 60.6268L81.8945 61.2185L81.8994 61.2174L117.048 57.2697L117.065 57.2682L117.082 57.2639L123.09 55.8846L122.985 55.2989L81.828 60.6268L81.8226 60.6268Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.2626 137.004L58.6396 137.048L59.0664 137.505L64.0905 142.873L64.4124 143.217L64.5849 142.778L66.8953 136.892L67.0678 136.453L66.5971 136.486L59.2626 137.004ZM126.32 69.0848L126.195 68.9167L125.995 68.9764L119.61 70.8831L118.765 71.1363L119.59 71.4472L130.384 75.5166L131.326 75.8719L130.728 75.0618L126.32 69.0848ZM104.174 61.5045L103.291 61.2353L103.851 61.9692L108.439 67.9881L108.577 68.168L108.787 68.0837L115.312 65.4487L116.094 65.1338L115.288 64.8885L104.174 61.5045Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M141.048 79.8732L136.753 73.9673L136.643 73.8159L136.46 73.8496L130.435 74.9452L129.519 75.112L130.362 75.5077L140.649 80.3367L144.858 86.1245L144.951 86.2531L145.109 86.2469L150.758 86.0489L151.806 86.012L150.896 85.493L141.048 79.8732ZM53.8419 102.921L53.5875 102.637L53.3735 102.953L49.5412 108.594L49.0374 108.602L49.3223 108.916L49.1332 109.195L49.5705 109.189L54.4588 114.57L51.3565 120.145L50.8826 120.167L51.171 120.479L51.0167 120.757L51.4116 120.739L56.4361 126.186L56.7191 126.492L56.917 126.125L60.0339 120.355L60.4986 120.334L60.2143 120.021L60.3635 119.744L59.978 119.761L55.1987 114.499L58.817 109.051L59.3126 109.043L59.0315 108.728L59.2167 108.45L58.7868 108.456L53.8419 102.921ZM64.5249 142.466L64.1585 142.074L64.0197 142.593L62.578 147.997L62.1498 148.019L62.4788 148.366L62.4162 148.602L62.6876 148.587L67.6114 153.783L67.9843 154.177L68.1158 153.651L69.4763 148.227L69.8954 148.205L69.5687 147.856L69.628 147.623L69.3631 147.637L64.5249 142.466ZM115.306 64.8946L114.351 64.5361L114.963 65.352L119.457 71.347L119.581 71.5133L119.78 71.4541L126.164 69.5471L127.012 69.2936L126.185 68.983L115.306 64.8946Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1418 121.034L25.3649 121.584L46.4098 115.068L46.5221 115.034L46.5802 114.932L49.9591 109.038L50.455 108.172L49.5656 108.624L25.1418 121.034Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M126.206 68.992L125.84 69.4386L130.249 75.4148L130.359 75.5646L130.542 75.5314L136.565 74.4355L137.484 74.2687L136.639 73.8731L126.206 68.992Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.7 33.2459L153.415 32.7295L81.7529 60.6446L81.8993 61.2172L123.062 55.8894L123.139 55.8792L123.201 55.8331L153.7 33.2459Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.8738 125.783L56.5619 125.444L56.381 125.867L53.8928 131.717L53.7056 132.156L54.182 132.13L61.6976 131.73L62.3299 131.696L61.9006 131.231L56.8738 125.783ZM110.862 58.8885L110.723 58.7035L110.51 58.7924L103.973 61.5139L103.208 61.8327L104.001 62.0744L115.114 65.4584L115.985 65.7232L115.439 64.995L110.862 58.8885Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.587 33.2981L153.49 32.7113L136.942 34.5747L136.913 34.5775L136.886 34.5855L118.442 40.3783L118.594 40.9528L153.587 33.2981Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.1508 131.536L53.5121 131.57L53.9492 132.037L58.9511 137.382L57.471 142.597L57.0264 142.612L57.3656 142.969L57.3006 143.198L57.5736 143.19L62.5934 148.488L62.9599 148.874L63.0973 148.36L64.5385 142.96L64.9731 142.945L64.6379 142.587L64.6981 142.359L64.4323 142.367L59.6312 137.237L61.9571 131.545L62.1348 131.11L61.6656 131.136L54.1508 131.536ZM147.045 80.0848L147.519 80.0608L146.688 79.5887L146.632 79.5096L146.556 79.5138L136.715 73.9154L132.341 67.8993L132.229 67.7462L132.042 67.7826L126.207 68.9333L122.077 63.3337L122.506 63.2001L121.777 62.9264L121.702 62.8269L121.598 62.8602L110.729 58.788L109.776 58.4311L110.386 59.2453L114.71 65.015L114.286 65.1476L115.015 65.421L115.089 65.5205L115.192 65.4877L125.964 69.5361L136.317 74.3801L140.281 79.83L139.813 79.8535L140.641 80.3267L140.699 80.4052L140.773 80.4013L150.601 86.0099L150.99 85.5781L147.045 80.0848ZM49.9211 108.689L49.6462 108.386L49.4424 108.741L46.1459 114.491L45.6606 114.506L45.9547 114.825L45.7988 115.097L46.1951 115.086L51.3172 120.638L51.5962 120.94L51.7962 120.58L54.9999 114.82L55.4771 114.806L55.1868 114.486L55.337 114.214L54.9505 114.226L49.9211 108.689Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.655 120.886L43.8369 120.879L43.9132 120.712L46.5925 114.909L46.8721 114.301L46.2341 114.499L25.1883 121.015L25.288 121.597L43.655 120.886Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M110.519 59.346L121.402 63.4222L125.84 69.4389L125.952 69.5902L126.137 69.5542L131.971 68.4038L135.955 73.8813L135.504 73.9114L136.309 74.3696L136.368 74.4502L136.443 74.4457L146.281 80.0422L150.506 85.9254L150.585 86.0344L150.719 86.0481L155.941 86.5754L156.139 86.0334L146.696 79.5994L142.696 74.0296L143.153 73.9998L142.345 73.5416L142.287 73.4604L142.211 73.4654L132.944 68.2113L133.052 68.1907L132.295 67.8379L127.845 61.7179L127.731 61.5615L127.542 61.6017L121.715 62.8439L117.255 56.7969L117.127 56.6239L116.922 56.6914L110.531 58.7846L109.725 59.0483L110.519 59.346ZM51.7551 120.234L51.4475 119.901L51.2634 120.315L48.757 125.972L48.2898 125.99L48.6018 126.323L48.4888 126.578L48.8295 126.565L53.8319 131.912L52.2687 137.097L51.807 137.104L52.1554 137.471L52.0885 137.695L52.3642 137.692L57.4823 143.093L57.842 143.472L57.9845 142.969L59.5091 137.596L59.9616 137.59L59.6157 137.221L59.6785 136.998L59.4109 137.002L54.5166 131.772L56.8553 126.275L57.3132 126.258L57.0043 125.924L57.1123 125.67L56.7812 125.682L51.7551 120.234ZM63.0252 148.078L62.5942 147.623L62.5143 148.245L61.8662 153.284L61.4311 153.285L61.8143 153.686L61.7891 153.88L62.0002 153.88L67.0139 159.116L67.4535 159.575L67.5248 158.943L68.0935 153.876L68.5185 153.876L68.1383 153.475L68.1606 153.281L67.9557 153.281L63.0252 148.078Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M152.305 80.6068L152.798 80.6488L151.902 80.0404L151.853 79.9712L151.793 79.9662L142.309 73.522L141.9 73.9423L145.866 79.4645L145.381 79.423L146.274 80.0314L146.323 80.1006L146.382 80.1053L155.803 86.5256L156.214 86.1067L152.305 80.6068ZM117.14 56.7036L116.776 57.1508L121.35 63.3514L121.464 63.5054L121.652 63.4657L127.667 62.1838L128.538 61.9984L127.729 61.6232L117.14 56.7036ZM46.5401 114.582L46.2382 114.254L46.0509 114.659L43.3724 120.463L43.1733 120.894L43.6486 120.885L51.5415 120.733L52.2037 120.72L51.7545 120.233L46.5401 114.582Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.556 33.3032L153.507 32.7101L145.501 33.1358L145.484 33.1365L145.468 33.1399L136.926 34.5765L137.009 35.1656L153.556 33.3032Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M138.266 67.8604L138.705 67.8237L137.921 67.3809L137.861 67.297L137.784 67.3036L127.807 61.6662L123.264 55.419L123.149 55.2601L122.957 55.3047L116.949 56.684L116.102 56.8781L116.89 57.2441L127.409 62.1311L131.548 67.8216L131.114 67.8583L131.897 68.3017L131.957 68.3857L132.033 68.3795L141.985 74.0211L151.479 80.4726L155.728 86.4524L155.791 86.5399L155.895 86.5671L160.633 87.8263L160.897 87.3067L151.91 80.0504L147.572 73.9471L147.491 73.8316L147.349 73.8227L142.302 73.4806L138.266 67.8604ZM56.937 147.856L56.2182 147.84L56.7155 148.359L61.8333 153.705L61.9737 158.528L61.9817 158.795L62.2476 158.815L67.2054 159.207L67.9837 159.269L67.4437 158.705L62.4411 153.479L63.1051 148.321L63.1468 147.992L62.8157 147.985L56.937 147.856ZM49.1716 126.06L48.8249 125.69L48.6703 126.172L46.9679 131.493L46.4886 131.489L46.8475 131.869L46.7771 132.087L47.0558 132.088L52.2743 137.597L52.6272 137.969L52.7759 137.478L54.3872 132.132L54.8564 132.135L54.5005 131.755L54.5663 131.538L54.2966 131.537L49.1716 126.06Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.5606 158.243L62.4349 153.913L62.9034 153.962L62.4217 153.458L62.4176 153.313L62.2671 153.297L57.2456 148.052L57.9535 143.197L58.4205 143.218L58.0136 142.788L58.0403 142.605L57.8314 142.596L53.2293 137.738L52.7069 137.187L52.2938 136.751L47.5217 131.714L49.1691 126.566L49.6562 126.578L49.2906 126.187L49.3588 125.975L49.0855 125.969L43.8602 120.384L43.5202 120.022L43.3605 120.492L41.5647 125.781L41.0678 125.768L41.4367 126.158L41.3644 126.371L41.6456 126.378L46.8642 131.886L45.9415 136.928L45.8816 137.257L46.215 137.278L52.1433 137.667L51.3825 142.312L50.905 142.29L51.3152 142.719L51.2851 142.903L51.5003 142.913L56.6338 148.274L56.688 152.718L56.2082 152.668L56.6931 153.17L56.6948 153.317L56.8507 153.333L61.5111 158.16L62.0567 158.726L62.5905 159.279L67.239 164.093L67.786 164.66L67.7504 163.873L67.5262 158.897L67.5138 158.634L67.2518 158.613L62.5606 158.243ZM137.513 67.8342L137.559 67.8976L141.9 73.9424L141.981 74.056L142.121 74.0654L147.169 74.4064L151.448 80.429L151.511 80.5187L151.555 80.529L160.523 87.7697L160.953 87.3666L156.648 81.258L156.585 81.1667L156.541 81.1562L148.252 74.4796L148.408 74.4907L147.541 73.9028L143.482 68.1923L143.935 68.2146L143.096 67.648L143.044 67.5742L142.982 67.5714L134.057 61.5432L134.173 61.5311L133.411 61.1022L133.349 61.0159L128.854 54.7567L128.751 54.6139L128.577 54.6347L122.989 55.299L122.083 55.4067L122.821 55.8222L127.058 61.6484L126.641 61.6909L127.402 62.1203L127.464 62.2067L127.54 62.199L137.513 67.8342Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.2542 136.684L45.49 136.634L46.0192 137.187L51.4182 142.826L51.8302 143.257L51.9274 142.669L52.7841 137.44L52.8377 137.116L52.5097 137.095L46.2542 136.684ZM156.611 81.2136L156.162 81.6006L160.466 87.7097L160.512 87.7732L160.582 87.807L164.77 89.8039L165.104 89.3191L156.611 81.2136Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.719 33.2306L153.346 32.7677L122.846 55.3549L121.946 56.0217L123.059 55.8902L128.648 55.2259L128.739 55.2153L128.809 55.1538L153.719 33.2306Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.4853 158.312L61.7577 157.559L57.286 152.926L57.2323 148.492L57.7273 148.555L57.2261 148.032L57.2242 147.891L57.0723 147.871L51.8479 142.415L51.3395 141.883L51.3355 142.619L51.3093 147.134L50.803 147.069L51.3073 147.591L51.306 147.734L51.4641 147.754L56.7152 153.193L57.5978 157.575L57.6364 157.763L57.8237 157.807L62.0201 158.767L62.8644 162.653L62.3683 162.553L62.9788 163.182L63.0021 163.289L63.1024 163.309L68.2972 168.657L69.0421 169.424L68.8011 168.382L67.8478 164.27L68.332 164.368L67.7243 163.739L67.6999 163.632L67.6031 163.613L63.1808 159.032L62.4853 158.312ZM160.73 83.0922L152.244 75.0071L152.196 74.9382L152.151 74.9276L143.103 67.658L138.943 61.8053L139.376 61.8182L138.565 61.2727L138.51 61.1961L138.448 61.1942L128.778 54.6832L128.371 55.1041L132.614 61.0131L132.188 60.9999L132.997 61.5463L133.052 61.622L133.113 61.6245L142.673 68.0812L147.087 74.2918L147.152 74.3836L147.195 74.3939L156.186 81.635L156.208 81.6661L156.235 81.679L164.692 89.7504L165.142 89.3636L160.805 83.171L160.758 83.1054L160.73 83.0922ZM41.9926 125.879L41.5989 125.463L41.4846 126.025L40.4916 130.913L39.9694 130.868L40.4076 131.326L40.3719 131.501L40.593 131.519L46.0193 137.187L46.4221 137.608L46.5268 137.035L47.4286 132.111L47.9391 132.156L47.5041 131.696L47.5357 131.523L47.3228 131.504L41.9926 125.879Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.744 33.2066L153.326 32.7838L128.416 54.7071L127.833 55.219L128.609 55.2284L133.73 55.2864L133.866 55.2879L133.955 55.1878L153.744 33.2066Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M156.307 76.7764L147.771 68.6582L147.721 68.5876L143.227 62.2102L143.158 62.1116L143.041 62.089L138.527 61.2187L133.978 54.816L133.89 54.6928L133.738 54.6912L128.617 54.6324L127.621 54.6215L128.446 55.1772L138.143 61.7054L142.642 68.0367L142.709 68.131L142.754 68.1408L151.799 75.4077L151.822 75.4409L151.85 75.453L160.335 83.5367L164.654 89.7054L164.681 89.7424L164.717 89.77L168.278 92.512L168.682 92.0792L160.796 83.1574L156.383 76.8566L156.336 76.7888L156.307 76.7764ZM57.957 157.225L56.9755 157.001L57.6767 157.723L63.0027 163.206L64.8137 167.189L64.863 167.297L64.9148 167.318L70.2026 172.74L70.6856 172.406L68.7815 168.324L68.7316 168.217L68.6812 168.197L63.5252 162.889L62.5623 158.455L62.5226 158.27L62.3383 158.229L57.957 157.225ZM46.4497 136.775L45.9553 136.258L45.9373 136.973L45.8277 141.44L45.2916 141.359L45.816 141.902L45.8125 142.041L45.9719 142.065L51.3299 147.614L52.1303 151.942L52.1645 152.129L52.3499 152.176L56.9171 153.336L57.9315 153.594L57.2047 152.841L51.9045 147.352L51.9295 142.966L52.4526 143.045L51.932 142.502L51.9333 142.364L51.779 142.341L46.4497 136.775Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.771 33.1719L153.302 32.8079L133.513 54.7891L133.152 55.1904L133.684 55.2821L138.282 56.0741L138.473 56.1071L138.58 55.946L153.771 33.1719Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M133.921 54.756L133.492 55.1615L138.111 61.6613L138.18 61.758L138.297 61.7806L142.927 62.674L143.17 62.1491L133.921 54.756ZM52.4957 151.599L51.4753 151.34L52.2088 152.094L57.6396 157.685L59.3752 161.61L59.4221 161.718L59.5298 161.763L63.135 163.31L63.466 162.828L58.1648 157.371L57.2819 152.99L57.2447 152.806L57.0628 152.76L52.4957 151.599ZM40.9456 131.026L40.4644 130.524L40.4332 131.219L40.2357 135.636L39.6701 135.537L40.2153 136.102L40.2089 136.236L40.3715 136.264L45.9022 141.992L46.3963 142.504L46.414 141.793L46.5236 137.334L47.0764 137.43L46.5351 136.865L46.5385 136.732L46.3818 136.705L40.9456 131.026ZM164.326 85.833L164.299 85.7938L164.285 85.7837L156.362 76.8305L155.896 77.1979L160.318 83.5128L160.345 83.5509L160.358 83.561L168.235 92.4733L168.702 92.1062L164.326 85.833Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M167.504 35.6659L167.628 35.0836L160.981 33.3477L160.956 33.3411L160.931 33.339L153.548 32.7102L153.474 33.3003L167.504 35.6659Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M151.874 70.418L151.825 70.3479L151.796 70.3353L143.21 62.1859L138.576 55.6093L138.505 55.5082L138.382 55.4875L133.785 54.6957L133.549 55.2218L142.765 62.5876L147.301 69.0255L147.35 69.0955L147.378 69.1071L155.935 77.243L156.383 76.8568L151.874 70.418ZM59.7641 161.217L59.4339 161.698L64.8707 167.274L65.3556 166.943L63.5234 162.912L63.4757 162.807L63.3695 162.762L59.7641 161.217ZM46.3306 141.579L45.6649 140.889L45.8223 141.835L46.5516 146.196L46.5834 146.383L46.7655 146.434L51.5261 147.758L52.5794 148.052L51.8197 147.265L46.3306 141.579Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5738 121.307L24.9936 121.206L14.3272 153.517L14.2935 153.619L14.3329 153.719L23.5796 177.154L24.1536 177.051L25.5738 121.307ZM153.794 33.1317L153.276 32.8417L138.085 55.6158L137.878 55.9254L138.226 56.0586L142.232 57.5978L142.492 57.698L142.609 57.4441L153.794 33.1317Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M159.859 79.4316L159.834 79.3951L159.82 79.3847L151.864 70.404L147.275 63.8501L147.224 63.7776L147.195 63.7658L138.537 55.5647L138.088 55.9525L142.74 62.5531L142.791 62.6245L142.82 62.6363L151.403 70.7841L155.896 77.1982L155.924 77.2385L155.937 77.2486L163.848 86.1876L168.214 92.4466L168.221 92.4562L168.228 92.4649L171.075 95.9577L171.369 95.7294L171.55 95.5996L167.124 89.2518L167.118 89.2426L167.115 89.2388L159.859 79.4316ZM62.0954 165.13L64.8727 167.278L67.5476 170.717L67.5715 170.747L67.5853 170.757L72.966 176.269L73.4132 175.877L70.6493 172.349L70.6262 172.319L70.6132 172.309L65.2973 166.858L59.8972 161.321L58.1624 157.396L58.1163 157.292L58.0643 157.269L52.6355 151.681L52.1485 152.005L53.8277 155.92L53.873 156.027L53.9274 156.05L59.4219 161.685L62.0419 165.077L62.0651 165.107L62.0954 165.13ZM40.7321 135.78L40.0868 135.112L40.2238 136.031L40.7931 139.87L40.1426 139.675L40.8761 140.429L40.8903 140.521L40.997 140.554L46.632 146.354L47.297 147.038L47.1396 146.098L46.4891 142.209L47.1231 142.4L46.395 141.645L46.3789 141.554L46.276 141.523L40.7321 135.78Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.808 33.0913L153.253 32.8828L142.068 57.1949L141.963 57.4223L142.17 57.5648L145.506 59.8673L145.845 60.1001L145.961 59.7061L153.808 33.0913Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M155.308 72.9084L155.28 72.867L155.265 72.8566L147.265 63.8354L142.583 57.1495L142.53 57.0746L142.445 57.0421L138.439 55.5029L138.128 55.9973L146.805 64.2159L151.387 70.7591L151.415 70.8005L151.429 70.8106L159.395 79.8031L159.862 79.4349L155.308 72.9084ZM62.4592 164.66L62.064 165.103L67.57 170.742L68.018 170.351L65.3189 166.883L65.2963 166.854L65.266 166.831L62.4592 164.66ZM47.0593 145.94L46.5704 146.261L48.1666 150.114L48.2106 150.219L48.2661 150.245L53.8877 156.01L54.3742 155.685L52.6958 151.77L52.6521 151.667L52.5991 151.642L47.0593 145.94Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M162.61 82.7749L162.603 82.7654L162.601 82.7619L155.303 72.9016L154.82 73.2488L159.374 79.7761L159.381 79.7856L159.383 79.7883L166.642 89.599L167.125 89.2519L162.61 82.7749ZM54.3141 155.595L53.8642 155.984L56.4224 159.322L56.4458 159.352L56.475 159.376L59.4632 161.725L59.8608 161.282L54.3141 155.595Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M177.969 42.6973L178.241 42.1714L167.718 35.1245L167.665 35.0891L167.602 35.0788L153.572 32.7131L153.416 33.2847L177.969 42.6973Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M150.662 66.2489L150.632 66.2061L150.617 66.1957L142.561 57.1221L142.094 57.4911L146.787 64.1915L146.817 64.2335L146.831 64.2436L154.841 73.2765L155.308 72.9084L150.662 66.2489ZM56.8432 158.908L56.4459 159.349L62.0641 165.103L62.5127 164.713L59.8827 161.309L59.8601 161.279L59.8314 161.257L56.8432 158.908ZM41.3698 140.083L40.8804 140.401L42.391 144.188L42.433 144.294L42.5348 144.344L46.7135 146.414L47.0594 145.94L41.3698 140.083Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.817 33.0589L153.237 32.923L145.39 59.5379L145.345 59.6912L145.447 59.8136L148.027 62.8936L148.437 63.3835L148.549 62.7553L153.817 33.0589Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.799 143.81L42.4539 144.285L48.2289 150.207L48.717 149.885L47.1207 146.033L47.0779 145.93L46.9777 145.88L42.799 143.81ZM158.003 76.166L157.996 76.1564L157.993 76.1526L150.656 66.2419L150.173 66.5891L154.82 73.2486L154.826 73.2589L154.83 73.2619L162.127 83.1227L162.611 82.775L158.003 76.166Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.6556 149.792L48.2052 150.179L50.6882 153.448L50.7109 153.478L50.7249 153.49L56.4459 159.349L56.8953 158.96L54.3369 155.622L54.3151 155.593L54.3016 155.582L48.6556 149.792ZM145.919 59.452L145.889 59.4081L145.845 59.3778L142.507 57.075L142.116 57.5177L150.195 66.6164L150.662 66.2482L145.919 59.452Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.819 33.038L153.23 32.9551L147.963 62.6507L147.947 62.7412L147.984 62.8245L149.712 66.6963L150.163 67.7086L150.28 66.6063L153.819 33.038Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.301 69.4196L153.294 69.4089L153.291 69.4062L145.915 59.4451L145.431 59.792L150.174 66.589L150.181 66.5996L150.183 66.6024L157.52 76.5131L158.003 76.1659L153.301 69.4196ZM42.8805 143.87L42.4292 144.257L44.8332 147.452L44.855 147.481L44.8842 147.504L48.2547 150.231L48.6555 149.792L42.8805 143.87Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.8863 153.501L14.315 153.652L16.1728 166.923L16.183 166.997L16.2269 167.057L23.6153 177.219L24.1333 176.935L14.8863 153.501ZM177.91 42.6662L178.271 42.1951L173.526 38.0533L173.497 38.029L173.464 38.0123L167.686 35.1063L167.387 35.6192L177.91 42.6662ZM153.82 33.0281L153.227 32.9755L149.687 66.5438L149.683 66.584L149.689 66.6233L150.461 71.2971L151.052 71.2699L153.82 33.0281Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M148.5 62.5327L148.493 62.5221L148.484 62.512L145.904 59.4312L145.436 59.7991L152.817 69.7673L153.301 69.4196L148.5 62.5327Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M153.82 33.0274L153.226 32.9853L153.226 32.9861L150.459 71.2263L150.458 71.2325L150.165 76.7038L150.165 76.7084L150.759 76.745L150.759 76.7404L153.82 33.0282L153.82 33.0274ZM45.3132 147.445L45.4395 147.268L45.3091 147.094L42.9055 143.898L42.6331 143.537L42.4089 143.93L23.5975 176.896L24.0987 177.217L45.3132 147.445Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M199.864 76.1632L199.331 76.419L201.724 83.4067L201.644 83.431L201.836 83.7327L201.917 83.9706L201.976 83.9525L206.211 90.6111L206.743 90.3529L204.265 83.264L204.343 83.2405L204.152 82.9383L204.069 82.7017L204.012 82.7188L199.864 76.1632ZM42.6161 194.208L42.2296 194.661L48.1642 200.22L48.1855 200.24L48.1977 200.246L60.4545 209.754L60.8407 209.303L54.9433 203.757L54.9219 203.738L54.9092 203.73L42.6161 194.208Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M195.505 69.2737L194.971 69.5276L197.373 76.6997L197.452 76.9349L197.698 76.8996L199.655 76.6169L200.11 76.551L199.865 76.1632L195.505 69.2737Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.438 72.7445L182.972 72.5232L178.37 42.3738L178.344 42.2027L178.182 42.1412L153.63 32.7289L152.68 32.365L153.283 33.1832L182.438 72.7445Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.9182 198.093L48.8955 198.071L48.8701 198.055L42.59 194.19L42.2519 194.679L54.5459 204.2L60.4339 209.736L60.4793 209.779L60.5383 209.8L68.5113 212.629L68.7647 212.093L54.9202 203.736L48.9182 198.093ZM197.612 76.3103L197.157 76.3759L197.404 76.7643L201.855 83.7625L202.388 83.5056L199.894 76.2256L199.815 75.992L199.57 76.0276L197.612 76.3103ZM191.052 62.2369L190.518 62.4888L192.824 69.5464L192.891 69.7533L193.109 69.7512L195.256 69.7304L195.791 69.7251L195.505 69.2736L191.052 62.2369Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.3637 182.733L29.9788 183.186L36.1694 188.984L36.1921 189.006L36.204 189.013L48.5318 198.544L48.918 198.093L42.76 192.303L42.7373 192.282L42.7254 192.274L30.3637 182.733ZM202.357 83.4429L201.81 83.6331L202.714 92.255L202.759 92.6873L203.145 92.4885L206.598 90.7159L206.889 90.5672L206.713 90.291L202.357 83.4429ZM193.104 69.1567L192.568 69.1616L192.855 69.6134L197.404 76.7652L197.938 76.5106L195.535 69.3384L195.467 69.1332L195.251 69.135L193.104 69.1567Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.716 72.8632L183.217 72.7986L182.917 72.3915L153.763 32.8303L153.282 32.1788L153.226 32.9862L150.165 76.7038L150.14 77.066L150.5 77.0196L182.716 72.8632Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.8667 198.054L48.51 198.526L54.5352 204.192L54.5819 204.236L54.6128 204.246L68.4573 212.603L68.8157 212.132L62.8515 206.474L62.8042 206.429L62.7734 206.418L48.8667 198.054ZM202.874 91.9588L202.582 92.1089L202.759 92.3848L207.219 99.317L207.765 99.1225L206.758 90.4175L206.709 89.9904L206.326 90.1867L202.874 91.9588ZM186.503 55.0489L185.967 55.2984L188.173 62.2342L188.232 62.419L188.425 62.4402L190.769 62.6928L191.382 62.7586L191.052 62.2371L186.503 55.0489ZM24.0379 176.808L23.6528 177.262L29.9781 183.186L30.0016 183.208L30.0294 183.225L36.1097 186.859L36.4443 186.367L24.0379 176.808Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.4665 186.386L36.4433 186.364L36.4154 186.347L30.3349 182.714L29.9998 183.204L42.3742 192.756L42.7599 192.303L36.4665 186.386ZM197.906 76.4454L197.359 76.633L198.154 85.1638L198.192 85.5682L198.565 85.4111L202.221 83.8768L202.546 83.7407L202.357 83.4428L197.906 76.4454Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M183.432 54.7942L183.987 54.582L181.888 47.7747L181.876 47.7352L181.853 47.7004L178.325 42.2571L177.805 42.5436L183.432 54.7942Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M188.681 61.8691L186.69 55.6043L186.88 55.6444L186.523 55.081L186.483 54.9521L186.436 54.9426L181.855 47.7028L181.319 47.9504L183.269 54.2746L183.071 54.2328L183.43 54.796L183.469 54.9254L183.518 54.9357L187.873 61.7817L188.165 62.2394L188.723 70.4977L188.748 70.853L189.093 70.7662L192.838 69.8278L193.473 77.6807L193.331 77.7284L193.498 77.9883L193.522 78.2918L193.663 78.2454L198.008 84.9992L198.2 85.2972L202.703 92.2986L201.804 102.015L201.662 102.095L201.779 102.274L201.737 102.737L201.989 102.595L206.417 109.355L206.894 110.082L206.963 109.215L207.753 99.3378L207.89 99.2601L207.774 99.0793L207.81 98.6214L207.567 98.759L203.298 92.1225L202.402 83.5713L202.36 83.1729L201.99 83.3283L198.709 84.7051L197.974 76.8128L198.111 76.7669L197.945 76.5057L197.917 76.2043L197.782 76.2492L193.579 69.6424L193.378 69.3259L191.084 62.3039L191.023 62.1212L190.832 62.1007L188.681 61.8691ZM48.558 198.571L48.588 198.582L62.4652 206.928L62.5119 206.972L68.4061 212.564L68.4747 212.629L68.568 212.643L78.0885 214.013L78.1616 213.763L78.3372 213.504L72.3316 207.719L72.2611 207.652L72.2074 207.645L56.7179 200.655L56.7063 200.644L50.5215 194.775L50.4721 194.729L50.4393 194.718L36.4434 186.364L30.0326 180.336L30.0078 180.313L29.9791 180.296L24.0067 176.788L23.6745 177.28L36.0696 186.83L42.3527 192.737L42.4013 192.783L48.5099 198.526L48.558 198.571Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.9803 180.298L29.6249 180.771L36.0592 186.82L36.1091 186.868L36.1413 186.877L50.1638 195.247L50.5214 194.775L44.1465 188.727L44.0952 188.678L44.063 188.669L29.9803 180.298ZM198.701 84.9761L198.244 84.2645L198.154 85.1051L197.104 95.0132L197.046 95.5554L197.534 95.3103L203.143 92.4896L203.439 92.3409L203.26 92.0626L198.701 84.9761ZM188.948 70.189L188.545 70.2896L188.77 70.6388L193.539 78.0509L194.085 77.8658L193.403 69.4297L193.375 69.0803L193.034 69.1658L188.948 70.189Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.384 72.6128L182.975 72.585L184 54.6867L184.004 54.6129L183.973 54.5454L178.346 42.2949L177.781 42.4639L182.384 72.6128Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.4386 194.72L50.1119 195.207L56.3456 201.121L56.4166 201.189L56.4697 201.196L71.956 208.185L77.9244 213.932L78.0145 214.019L78.1396 214.016L89.2105 213.704L89.2927 213.123L72.2823 207.672L66.1898 201.804L66.1175 201.734L66.0635 201.728L50.4386 194.72ZM197.266 94.779L196.968 94.9288L197.151 95.2074L201.764 102.25L198.909 113.07L198.735 113.728L199.335 113.409L206.806 109.455L207.094 109.302L206.915 109.028L202.391 102.122L203.306 92.2513L203.355 91.7169L202.876 91.9575L197.266 94.779ZM183.953 54.5105L183.345 53.5527L183.405 54.6861L183.831 62.6464L183.649 62.678L183.849 62.9888L183.863 63.2445L183.999 63.2208L188.77 70.6387L189.318 70.4574L188.772 62.3911L188.947 62.3608L188.749 62.0488L188.731 61.7946L188.602 61.8167L183.953 54.5105ZM194.039 77.7287L193.592 77.0331L193.493 77.8541L192.311 87.6901L192.248 88.2101L192.726 87.998L198.572 85.4087L198.889 85.2683L198.701 84.976L194.039 77.7287ZM37.6251 182.54L37.5724 182.489L37.503 182.469L29.9115 180.268L29.6762 180.809L43.7898 189.199L44.1465 188.727L37.6251 182.54Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.766 72.8519L182.64 72.2726L150.424 76.4291L150.219 76.4556L150.172 76.6562L148.702 82.9293L148.583 83.4353L149.08 83.2814L182.766 72.8519Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.0623 188.671L43.7365 189.158L50.1119 195.207L50.1853 195.277L50.2376 195.282L65.8138 202.268L71.9184 208.148L72.0099 208.237L72.0843 208.234L89.1112 213.69L89.4108 213.194L83.3922 207.272L83.3007 207.181L83.2244 207.184L66.1392 201.755L59.9053 195.751L59.8316 195.68L59.7773 195.675L44.0623 188.671ZM192.485 87.4532L192.165 87.5955L192.357 87.8892L197.151 95.2075L197.608 95.9056L197.696 95.0762L198.746 85.1682L198.801 84.6556L198.33 84.8648L192.485 87.4532ZM199.057 112.884L198.765 113.038L198.949 113.312L203.666 120.338L204.039 120.894L204.202 120.244L206.955 109.264L207.118 108.616L206.527 108.928L199.057 112.884ZM189.27 70.3163L188.834 69.6371L188.725 70.4377L187.405 80.1951L187.337 80.6941L187.807 80.5129L193.896 78.1676L194.237 78.036L194.039 77.7288L189.27 70.3163Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.9022 176.75L23.7401 177.319L37.3046 183.03L37.3625 183.054L37.4255 183.053L57.6641 182.662L57.7053 182.07L23.9022 176.75ZM148.903 82.7131L148.767 82.7555L148.714 82.889L146.052 89.7143L145.78 90.413L146.457 90.091L182.805 72.8373L182.589 72.2838L148.903 82.7131Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M197.65 94.8817L197.289 94.3316L197.114 94.9646L194.118 105.747L193.931 105.838L194.045 106.008L193.905 106.513L194.266 106.337L198.949 113.312L199.316 113.859L199.484 113.222L202.339 102.406L202.52 102.318L202.407 102.145L202.54 101.646L202.191 101.816L197.65 94.8817ZM37.5404 182.483L37.215 182.971L43.7365 189.159L43.8121 189.23L43.8658 189.234L59.5302 196.217L65.7765 202.232L65.8699 202.322L65.9449 202.318L83.0259 207.745L88.9933 213.619L89.1029 213.726L89.2531 213.7L101.439 211.563L101.448 210.978L83.3309 207.21L77.2348 201.211L77.1411 201.119L77.0635 201.123L59.8559 195.703L53.4759 189.558L53.4 189.485L53.3444 189.481L37.5404 182.483ZM187.593 79.958L187.249 80.0905L187.451 80.3982L192.358 87.889L192.805 88.5718L192.902 87.7612L194.084 77.9251L194.143 77.4339L193.682 77.6119L187.593 79.958Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M183.405 54.6526L182.39 72.3748L146.202 89.5532L146.113 89.5953L146.067 89.683L142.314 96.7464L141.807 97.7016L142.732 97.1408L182.832 72.8228L182.704 72.5847L182.972 72.613L184.436 62.9388L184.441 62.9088L184.44 62.8783L184 54.6542L183.405 54.6526ZM57.8739 152.145L57.7524 152.126L57.6538 152.198L23.6814 176.803L23.9127 177.337L109.712 160.698L109.701 160.111L57.8739 152.145Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M199.443 112.98L199.136 112.522L198.922 113.031L194.173 124.329L193.956 124.429L194.065 124.586L193.832 125.142L194.3 124.926L199.021 131.748L199.334 132.201L199.542 131.691L204.145 120.393L204.355 120.296L204.249 120.138L204.473 119.586L204.019 119.796L199.443 112.98ZM184.393 62.7328L183.966 62.0697L183.848 62.8498L182.411 72.3428L182.216 72.4064L182.366 72.6357L182.31 73.002L182.554 72.9225L187.451 80.3983L187.888 81.0659L187.995 80.2756L189.291 70.702L189.479 70.6398L189.331 70.4091L189.379 70.046L189.146 70.1226L184.393 62.7328ZM192.855 87.5623L192.502 87.0228L192.321 87.6419L189.18 98.3795L188.983 98.4674L189.103 98.6458L188.961 99.1289L189.319 98.9694L194.122 106.122L194.482 106.659L194.656 106.036L197.649 95.2596L197.841 95.1743L197.723 94.9945L197.856 94.516L197.51 94.6696L192.855 87.5623ZM95.6016 204.992L95.4901 204.88L95.3923 204.898L77.1723 201.15L70.931 195.007L70.8357 194.914L70.7578 194.92L53.425 189.51L46.8941 183.22L46.8163 183.144L46.7092 183.137L37.4416 182.458L37.2993 183.027L53.1004 190.023L59.4925 196.18L59.5876 196.272L59.6636 196.267L76.8679 201.686L82.9756 207.696L83.0863 207.805L83.1811 207.787L101.326 211.561L102.292 211.762L101.599 211.06L95.6016 204.992Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M142.423 96.632L142.366 96.6661L142.329 96.7216L137.569 103.897L137.988 104.305L182.848 72.8114L182.523 72.314L142.423 96.632ZM64.0146 152.076L63.9874 152.07L63.96 152.071L57.8249 152.142L57.7828 152.733L109.604 160.698L109.61 160.7L109.707 160.112L109.701 160.111L64.0146 152.076Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M95.4237 204.905L94.5837 204.809L95.1778 205.411L101.176 211.479L101.303 211.608L101.475 211.555L114.181 207.638L114.127 207.058L95.4237 204.905ZM194.616 105.79L194.313 105.338L194.096 105.837L189.151 117.237L188.852 117.926L189.541 117.629L199.314 113.419L199.643 113.278L199.443 112.98L194.616 105.79Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M137.646 103.818L137.611 103.842L137.584 103.877L131.919 111.026L132.333 111.448L182.859 72.8047L182.507 72.3246L137.646 103.818Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M187.949 80.0723L187.602 79.5423L187.416 80.1479L184.083 90.9715L183.898 91.5694L184.479 91.3343L192.718 88.0015L193.054 87.8654L192.855 87.5623L187.949 80.0723ZM189.306 117.082L188.972 117.226L189.179 117.525L194.158 124.721L194.467 125.166L194.677 124.667L199.471 113.261L199.757 112.581L199.078 112.872L189.306 117.082ZM89.4605 198.779L89.3477 198.665L89.2496 198.684L70.7819 194.929L69.7986 194.728L70.514 195.432L76.8172 201.635L76.9292 201.746L77.0254 201.727L95.3291 205.493L96.2915 205.691L95.6016 204.992L89.4605 198.779Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M131.97 110.975L131.951 110.991L131.933 111.009L125.481 117.994L125.887 118.428L182.859 72.8048L182.864 72.8002L182.497 72.3312L182.491 72.3357L131.97 110.975ZM71.0997 150.945L71.0427 150.931L70.9843 150.94L63.9163 152.075L63.912 152.662L109.604 160.698L109.725 160.116L71.0997 150.945Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M64.4777 188.656L64.3797 188.56L64.2445 188.572L53.2444 189.476L53.1805 190.057L70.6338 195.504L70.931 195.007L64.4777 188.656ZM184.256 90.7826L183.915 90.92L184.12 91.2242L189.18 98.7617L189.534 99.2888L189.713 98.6789L192.892 87.8093L193.064 87.2189L192.495 87.4498L184.256 90.7826ZM194.647 124.382L194.378 123.992L194.144 124.404L187.538 136.026L187.117 136.766L187.908 136.45L199.377 131.855L199.723 131.716L199.511 131.409L194.647 124.382Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M125.514 117.964L125.505 117.972L125.496 117.979L118.394 124.656L118.787 125.103L182.864 72.8004L182.866 72.7993L182.491 72.3359L182.489 72.3378L125.514 117.964Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M118.411 124.642L118.41 124.642L110.808 130.87L110.807 130.87L110.806 130.871L111.183 131.331L111.184 131.332L182.866 72.7984L182.678 72.5678L182.49 72.3371L182.489 72.3377L182.486 72.3404L118.411 124.642Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M108.363 200.932L108.234 200.798L108.118 200.834L89.2832 198.693L88.444 198.597L89.0375 199.198L95.1778 205.411L95.306 205.54L95.4197 205.505L114.06 207.65L114.879 207.744L114.309 207.148L108.363 200.932ZM189.675 98.4295L189.375 97.9844L189.156 98.4736L184.053 109.865L183.752 110.537L184.435 110.263L194.48 106.232L194.822 106.095L194.616 105.79L189.675 98.4295ZM182.927 72.4046L182.587 71.885L182.395 72.4761L178.901 83.2479L178.713 83.8281L179.287 83.6187L187.802 80.5156L188.155 80.387L187.95 80.0727L182.927 72.4046Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M184.214 109.711L183.867 109.85L184.08 110.156L189.179 117.525L189.483 117.966L189.697 117.474L194.642 106.074L194.93 105.41L194.258 105.68L184.214 109.711ZM108.158 200.839L107.429 200.816L107.932 201.343L113.878 207.56L114.021 207.709L114.212 207.627L127.157 202.023L127.048 201.453L108.158 200.839ZM83.1725 192.417L83.0586 192.301L82.9591 192.322L64.3274 188.576L63.3474 188.38L64.0601 189.081L70.5141 195.432L70.6275 195.544L70.724 195.524L89.1901 199.28L90.1487 199.475L89.4607 198.779L83.1725 192.417Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.8684 182.152L57.7691 182.055L57.6306 182.067L46.6584 183.138L46.5993 183.718L64.1808 189.153L64.478 188.656L57.8684 182.152ZM179.082 83.0591L178.725 83.1898L178.937 83.5055L184.12 91.2244L184.468 91.7431L184.652 91.1464L187.984 80.3228L188.161 79.7511L187.598 79.956L179.082 83.0591ZM189.668 117.186L189.402 116.801L189.167 117.206L182.455 128.764L182.191 128.864L182.304 129.022L181.98 129.58L182.55 129.364L187.431 136.174L179.334 147.501L179.038 147.603L179.153 147.755L178.737 148.336L179.412 148.104L184.457 154.872L184.703 155.202L184.939 154.866L193.002 143.423L193.289 143.324L193.179 143.171L193.588 142.591L192.927 142.819L188.15 136.155L194.609 124.791L194.864 124.695L194.754 124.536L195.07 123.98L194.515 124.19L189.668 117.186ZM102.273 194.565L102.143 194.429L101.965 194.488L89.1567 198.705L89.2157 199.285L108.114 201.433L108.931 201.526L108.363 200.932L102.273 194.565Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M139.909 194.301L121.3 195.084L121.249 195.029L121.117 195.087L102.079 194.473L83.0991 192.342L76.7315 185.898L76.6155 185.781L76.4543 185.818L64.2037 188.578L64.21 189.16L82.813 192.899L89.0375 199.198L89.167 199.329L89.3424 199.271L101.973 195.112L107.932 201.343L108.077 201.493L108.206 201.437L126.905 202.044L126.979 202.124L127.112 202.05L127.739 202.07L127.514 201.826L140.067 194.859L139.909 194.301ZM184.614 90.8927L184.319 90.4536L184.097 90.9336L178.881 102.201L178.637 102.292L178.759 102.467L178.527 102.969L178.987 102.797L184.08 110.156L184.38 110.591L184.596 110.109L189.648 98.8309L189.885 98.7426L189.767 98.5666L189.989 98.0685L189.543 98.2343L184.614 90.8927Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M103.332 136.667L103.227 136.289L102.891 136.493L94.7602 141.433L94.4734 141.608L94.6812 141.871L109.422 160.589L109.943 160.326L103.332 136.667ZM173.47 94.5908L174.015 94.8301L179.453 83.4676L179.462 83.4497L179.467 83.4308L182.961 72.6598L182.402 72.4565L173.47 94.5908Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.071 187.804L133.561 187.861L133.697 188.015L121.028 195.127L121.186 195.685L139.793 194.902L139.872 194.992L140.019 194.893L140.559 194.869L140.369 194.654L152.598 186.329L152.397 185.787L134.285 187.781L134.228 187.717L134.071 187.804ZM173.849 140.641L173.437 141.209L174.107 140.988L179.282 147.93L179.525 148.256L179.763 147.924L187.982 136.426L188.275 136.329L188.163 136.173L188.568 135.607L187.911 135.822L182.9 128.831L182.659 128.495L182.417 128.83L174.034 140.385L173.733 140.485L173.849 140.641ZM96.0345 188.042L95.9033 187.905L95.7231 187.966L82.8652 192.344L82.9274 192.922L102.024 195.067L102.841 195.158L102.273 194.565L96.0345 188.042Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M115.385 188.666L115.24 188.509L115.044 188.596L101.935 194.499L102.048 195.068L121.165 195.684L121.875 195.707L121.393 195.185L115.385 188.666ZM158.7 169.374L158.236 169.482L158.536 169.851L163.703 176.216L146.75 179.151L146.237 179.239L146.573 179.636L152.204 186.275L152.393 186.497L152.619 186.313L164.212 176.842L164.228 176.863L164.453 176.645L165.32 175.936L165.151 175.965L175.306 166.083L176.072 165.338L175.031 165.58L158.7 169.374ZM76.5523 185.812L75.7174 185.719L76.3074 186.317L82.7495 192.835L82.8804 192.967L83.0566 192.907L95.9153 188.53L95.8522 187.952L76.5523 185.812ZM179.431 83.1729L179.141 82.7398L178.916 83.21L173.478 94.5734L173.172 95.2135L173.843 94.983L184.464 91.3399L184.831 91.2143L184.614 90.8926L179.431 83.1729Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M110.807 130.87L110.703 130.955L110.698 131.087L109.359 160.391L109.318 161.277L109.885 160.595L182.906 72.7585L182.489 72.3377L110.807 130.87Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M179.637 147.41L174.588 140.636L182.841 129.259L183.14 129.166L183.025 129.006L183.427 128.452L182.774 128.656L177.749 121.645L184.524 110.234L184.794 110.142L184.678 109.974L184.989 109.449L184.442 109.633L179.441 102.409L184.637 91.1833L184.93 90.5504L184.27 90.7769L173.65 94.4208L173.278 94.5483L173.501 94.8716L178.745 102.449L171.8 113.885L171.522 113.978L171.642 114.146L171.321 114.674L171.884 114.485L177.026 121.658L168.603 133.094L168.295 133.189L168.414 133.349L168.005 133.904L168.672 133.695L173.836 140.624L164.135 151.715L163.792 151.81L163.914 151.966L163.421 152.531L164.187 152.318L169.242 158.821L153.079 162.448L152.611 162.552L152.914 162.925L158.214 169.455L140.981 172.347L140.465 172.435L140.803 172.833L146.21 179.208L128.385 181.115L128.327 181.05L128.171 181.139L127.659 181.193L127.798 181.35L115.225 188.493L109.228 181.986L109.081 181.828L108.884 181.917L95.8879 187.889L89.6408 181.358L89.5083 181.219L89.3911 181.26L70.0566 179.145L70.0126 179.101L69.9001 179.128L69.1181 179.042L69.3359 179.263L57.5898 182.075L57.601 182.656L76.3723 186.382L76.4403 186.451L76.5412 186.416L77.413 186.589L77.3221 186.497L95.6787 188.532L101.843 194.976L101.988 195.129L102.18 195.042L115.093 189.227L120.955 195.589L121.115 195.762L121.32 195.647L134.1 188.471L134.115 188.488L134.262 188.388L134.802 188.366L134.768 188.327L152.463 186.378L153.02 186.317L152.657 185.889L147.364 179.648L164.305 176.716L164.806 176.63L164.485 176.235L159.298 169.846L175.166 166.16L175.62 166.055L175.334 165.688L170.164 159.036L170.732 158.487L170.582 158.52L179.687 148.015L180.019 147.922L179.902 147.766L180.392 147.201L179.637 147.41Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.5729 182.079L57.7286 182.654L69.9886 179.72L70.0055 179.715L70.021 179.71L82.9743 174.992L82.7861 174.427L57.5729 182.079ZM157.241 118.068L157.747 118.381L173.992 94.8715L174.01 94.8441L174.022 94.8138L182.954 72.6795L182.417 72.4239L157.241 118.068Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M174.337 140.299L169.155 133.347L177.574 121.917L177.879 121.826L177.761 121.662L178.159 121.121L177.512 121.314L172.355 114.119L179.296 102.688L179.575 102.601L179.454 102.428L179.765 101.917L179.22 102.088L173.992 94.5332L173.733 94.1599L173.494 94.5445L166.273 106.166L165.985 106.256L166.109 106.427L165.79 106.941L166.35 106.765L171.629 114.129L163.036 125.618L162.721 125.711L162.843 125.875L162.437 126.416L163.1 126.22L168.401 133.332L158.534 144.506L158.188 144.598L158.312 144.758L157.823 145.311L158.584 145.109L163.775 151.786L147.317 155.347L146.844 155.449L147.149 155.825L152.589 162.527L135.068 165.374L134.548 165.458L134.889 165.86L140.438 172.403L122.338 174.284L122.279 174.217L122.123 174.306L121.61 174.36L121.752 174.519L109.067 181.812L102.916 175.138L102.769 174.978L102.571 175.07L89.4931 181.203L83.0875 174.506L82.9529 174.366L82.771 174.432L69.8176 179.15L69.8869 179.726L89.286 181.848L95.6038 188.454L95.7511 188.607L95.9435 188.518L108.937 182.549L114.948 189.07L115.108 189.244L115.313 189.127L128.201 181.806L128.216 181.823L128.362 181.722L128.903 181.701L128.869 181.663L146.833 179.74L147.391 179.68L147.027 179.251L141.596 172.848L158.817 169.958L159.32 169.873L158.999 169.477L153.678 162.924L169.83 159.299L170.288 159.196L170.001 158.826L164.699 152.006L165.26 151.466L165.117 151.497L174.383 140.903L174.72 140.813L174.601 140.653L175.085 140.1L174.337 140.299Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M182.449 72.3777L109.474 160.159L57.5433 182.09L57.7459 182.649L82.9591 174.997L82.9865 174.988L83.013 174.975L109.796 160.667L109.713 160.492L109.853 160.629L157.7 118.434L157.738 118.4L157.763 118.355L182.938 72.7122L182.449 72.3777Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M158.299 144.741L147.182 155.414L134.936 165.429L122.119 174.301L102.696 175.042L82.8811 174.414L82.1539 174.391L82.6569 174.917L89.2111 181.769L89.3587 181.924L89.5519 181.833L102.626 175.702L108.79 182.389L108.951 182.564L109.157 182.446L122.31 174.886L141.061 172.937L141.622 172.879L141.258 172.448L135.683 165.876L153.192 163.031L153.699 162.949L153.375 162.55L147.916 155.826L164.363 152.269L164.825 152.168L164.535 151.795L159.084 144.782L168.947 133.614L169.289 133.527L169.167 133.364L169.647 132.822L168.904 133.011L163.453 125.697L163.235 125.404L162.992 125.676L152.792 137.114L152.44 137.204L152.567 137.367L152.083 137.909L152.839 137.717L158.299 144.741Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M157.257 118.042L157.74 118.392L166.704 106.596L166.712 106.585L166.72 106.573L174 94.8594L173.502 94.5327L157.257 118.042Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M128.612 158.459L115.918 167.296L96.3512 168.015L96.2948 167.954L96.1528 168.022L95.5684 168.043L95.7332 168.223L82.7437 174.444L82.8635 175.009L102.563 175.633L102.64 175.717L102.773 175.64L103.397 175.66L103.354 175.612L122.087 174.899L122.169 174.991L122.315 174.89L122.856 174.869L122.662 174.65L135.064 166.066L135.078 166.083L135.235 165.954L135.709 165.907L135.538 165.706L147.338 156.057L147.353 156.076L147.519 155.917L147.938 155.851L147.798 155.68L148.418 155.173L148.27 155.196L158.842 145.044L159.226 144.965L159.098 144.799L159.651 144.268L158.819 144.441L153.189 137.198L152.987 136.937L152.748 137.165L141.325 148.082L140.93 148.165L141.052 148.314L128.924 158.234L128.48 158.304L128.612 158.459ZM157.13 118.209L156.727 118.739L157.386 118.553L162.976 126.053L163.214 126.372L163.453 126.053L172.176 114.392L172.487 114.304L172.367 114.136L172.762 113.608L172.118 113.789L166.709 106.242L166.474 105.915L166.23 106.235L157.325 117.952L157.005 118.042L157.13 118.209Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M146.663 129.77L135.263 140.612L134.864 140.691L135.001 140.861L134.455 141.381L135.288 141.214L140.91 148.14L122.949 150.861L122.881 150.781L122.716 150.896L122.257 150.966L122.391 151.124L109.549 160.116L109.047 160.165L109.194 160.332L96.0743 168.061L96.236 168.615L115.886 167.893L115.969 167.986L116.114 167.884L116.657 167.864L116.46 167.643L128.999 158.915L129.013 158.931L129.169 158.803L129.645 158.757L129.489 158.573L130.156 158.109L129.999 158.124L141.427 148.775L141.441 148.794L141.666 148.579L142.498 147.899L142.356 147.921L152.922 137.823L152.939 137.845L153.11 137.653L153.484 137.578L153.354 137.409L153.901 136.887L153.769 136.914L163.375 126.142L163.722 126.058L163.599 125.892L164.073 125.36L163.335 125.539L157.741 118.033L157.524 117.742L157.281 118.012L146.903 129.533L146.545 129.62L146.663 129.77Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M109.46 160.182L109.836 160.642L135.586 141.125L135.603 141.111L135.615 141.099L147.268 130.016L147.276 130.008L147.283 130L157.724 118.411L157.575 118.272L157.305 117.988L109.46 160.182Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default FootBottom;
