import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import { type IconProps } from '../../typescript';

const FeaturesTopRight: FC<IconProps> = (props) => {
  return (
    <motion.svg
initial={{ scale: 1.1, rotate: 12 }}
animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      {...props}
      viewBox="0 0 190 196"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.7418 29.7954L68.7308 29.8024L68.7208 29.8104L58.8788 37.2064L58.8728 37.2114L58.8658 37.2174L49.4498 45.0514L49.4428 45.0564L49.5878 45.2354L49.4298 45.0684L40.4898 53.3284L40.4828 53.3344L40.4758 53.3414L32.0918 62.0054L32.5828 62.4944L49.8878 45.5904L89.8698 17.0294L111.755 5.89539L111.448 5.27344L100.368 10.5444L100.36 10.5484L100.352 10.5524L89.5238 16.4274L89.6318 16.6314L89.5088 16.4354L78.9678 22.8594L78.9608 22.8644L78.9538 22.8684L68.7418 29.7954Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M96.9228 67.6052L96.1078 67.4622L96.5778 68.1442L100.716 74.1522L90.3008 72.6772L89.5088 72.5642L89.9678 73.2192L94.6198 79.8472L94.8288 80.1442L95.1158 79.9232L101.654 74.8882L108.498 70.2542L115.618 66.0472L116.383 65.5952L115.514 65.4102L103.856 62.9212L103.014 62.7412L103.496 63.4552L107.567 69.4772L96.9228 67.6052Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.539 -2.54645L134.279 -3.18945L111.477 5.26154L111.458 5.26855L111.44 5.27753L89.5322 16.4225L89.8292 17.0495L134.539 -2.54645Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M104.071 63.0655L103.877 62.7775L103.589 62.9725L96.9537 67.4655L96.9417 67.4485L96.6597 67.6645L95.9587 68.1395L96.0258 68.1515L90.0407 72.7444L89.3857 73.2465L90.2037 73.3625L101.402 74.9485L102.185 75.0594L101.736 74.4084L97.6187 68.4315L108.252 70.3015L109.058 70.4435L108.6 69.7654L104.071 63.0655ZM111.051 58.6615L110.179 58.4395L110.676 59.1895L115.152 65.9395L115.323 66.1974L115.599 66.0575L122.948 62.3215L123.77 61.9034L122.877 61.6765L111.051 58.6615Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.92 16.9922L89.488 16.4512L49.463 45.0422L49.433 45.0642L49.408 45.0922L17.334 80.5212L17.822 81.0132L89.92 16.9922Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M83.5385 78.3906L83.1776 78.7037L83.2415 78.7106L78.0466 83.8376L77.4515 83.7886L77.7205 84.1586L77.3945 84.4807L77.9905 84.5286L82.6965 90.9927L82.9346 91.3207L83.2216 91.0347L88.8585 85.4097L89.4435 85.4576L89.1915 85.1057L95.0175 80.0087L95.6285 80.0756L95.3665 79.7026L95.7255 79.3887L95.0975 79.3196L90.7266 73.0917L97.0656 68.2277L103.856 63.6296L115.369 66.0876L116.2 66.2646L115.73 65.5566L111.751 59.5557L122.574 62.3146L122.659 62.4457L122.819 62.3776L123.566 62.5676L123.307 62.1697L130.455 59.1196L131.335 58.7446L130.418 58.4686L118.582 54.9067L118.481 54.7507L118.327 54.8297L117.553 54.5967L117.86 55.0687L111.083 58.5477L111.072 58.5306L110.905 58.6296L99.2496 56.1036L98.4045 55.9207L98.8885 56.6367L103.036 62.7726L92.2576 60.8297L91.4376 60.6816L91.9105 61.3677L96.1255 67.4866L85.5695 65.9327L84.7715 65.8157L85.2346 66.4756L89.7885 72.9626L84.0645 77.9337L84.0496 77.9136L83.8776 78.0826L83.2706 78.0156L83.5385 78.3906Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.52 -2.53937L134.29 -3.19336L122.79 0.656677L122.78 0.660645L122.769 0.664673L111.467 5.26562L111.718 5.91266L134.52 -2.53937Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M99.4639 56.249L99.2698 55.9609L98.9819 56.1559L92.0018 60.884L91.2969 61.3619L92.1348 61.5129L103.723 63.601L104.532 63.7469L104.071 63.066L99.4639 56.249Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.9224 45.5572L49.4233 45.0762L32.0994 61.9992L32.0833 62.0142L32.0703 62.0312L17.3203 80.5382L17.8494 80.9872L49.9224 45.5572Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M92.9365 61.6351L92.6796 61.2621L99.2476 56.8141L110.754 59.3071L110.848 59.4481L111.013 59.3632L111.726 59.5181L111.469 59.1292L118.395 55.5751L130.096 59.0961L130.173 59.2181L130.326 59.1652L131.114 59.4031L130.848 58.9861L137.98 56.5372L138.802 56.8261L138.507 56.3561L139.037 56.1741L138.211 55.8831L133.915 49.0272L133.768 48.7921L133.506 48.8842L126.071 51.5031L126.06 51.4861L125.914 51.5491L125.093 51.2601L125.131 51.3192L114.77 48.1881L114.816 48.1641L114.07 47.9711L113.972 47.8201L113.818 47.9001L113.044 47.6661L113.074 47.7131L101.863 44.8051L100.987 44.5771L101.487 45.3322L105.623 51.5692L105.648 51.6071L94.6976 49.1981L94.5806 49.0251L94.4166 49.1362L93.7145 48.9811L94.0065 49.4141L87.5375 53.7971L87.5266 53.7811L87.3596 53.9081L86.6865 53.7841L86.9636 54.1862L86.5526 54.4651L86.6135 54.4761L80.6096 59.0501L79.9525 58.9492L80.2275 59.3411L79.8425 59.6342L80.5045 59.7352L85.0435 66.2031L84.6555 66.5001L84.7175 66.5092L79.2595 71.2151L79.2455 71.1951L79.0745 71.3621L78.4606 71.2902L78.7325 71.6691L78.3746 71.9781L78.4355 71.9852L73.1655 77.1281L72.5635 77.0742L72.8365 77.4492L72.5115 77.7661L73.1055 77.8192L77.7325 84.1751L72.6876 89.8881L72.1185 89.8571L72.3906 90.2241L72.1016 90.5511L72.6545 90.5811L77.4395 97.0402L77.6965 97.3862L77.9805 97.0612L83.1276 91.1451L83.6865 91.1751L83.4196 90.8082L83.7045 90.4811L83.1605 90.4521L78.6335 84.2341L84.1335 78.8062L84.7255 78.8591L84.4695 78.5012L90.3635 73.3821L90.9805 73.4542L90.7155 73.0771L91.0726 72.7672L90.4465 72.6931L86.2665 66.7371L96.8126 68.2902L97.6005 68.4061L97.1486 67.7501L92.9365 61.6351Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0253 16.8198L90.3134 15.7148L89.4594 16.4728L17.3613 80.4948L17.7863 81.0408L85.0554 35.5488L85.1633 35.4758L85.1964 35.3499L90.0253 16.8198Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M74.3514 64.5414L73.5664 64.4444L74.0264 65.0874L78.9164 71.9274L79.1375 72.2364L79.4254 71.9884L85.7454 66.5394L86.3374 66.0294L85.5614 65.9324L74.3514 64.5414ZM102.064 44.9494L101.881 44.6734L101.597 44.8434L94.3094 49.2094L93.5604 49.6584L94.4134 49.8454L106.335 52.4684L107.173 52.6534L106.699 51.9384L102.064 44.9494ZM121.749 44.7354L121.589 44.4844L121.317 44.6044L113.712 47.9554L112.854 48.3344L113.751 48.6054L125.826 52.2544L126.722 52.5254L126.218 51.7354L121.749 44.7354Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0305 16.6696L89.3535 16.6445L84.5245 35.1745L84.3095 36.0005L85.0395 35.5585L92.4375 31.0836L92.6425 30.9595L92.5995 30.7245L90.0305 16.6696ZM32.6115 62.4635L32.0765 62.0215L24.3315 71.0795L24.3245 71.0885L24.3165 71.0985L17.3125 80.5465L17.8625 80.9695L32.6115 62.4635Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M80.9875 59.2148L80.7685 58.9028L80.4785 59.1508L74.0835 64.6218L73.4865 65.1328L74.2665 65.2298L85.4775 66.6208L86.2525 66.7168L85.8035 66.0768L80.9875 59.2148ZM134.596 49.2358L134.666 49.2108L133.746 48.8868L132.755 48.4808L132.801 48.5538L121.574 44.5938L120.625 44.2588L121.166 45.1078L125.635 52.1078L125.784 52.3398L126.043 52.2488L133.476 49.6308L137.688 56.3528L137.823 56.5677L138.068 56.5048L145.825 54.5168L146.839 54.2568L145.871 53.8598L134.596 49.2358ZM73.2825 90.2618L78.4385 84.4258L78.9065 83.8948L78.2005 83.8498L67.8905 83.1898L67.1445 83.1418L67.5895 83.7428L72.4035 90.2408L67.7775 96.3528L67.3705 96.8898L68.0445 96.9088L77.7095 97.1797L78.4225 97.1998L77.9985 96.6268L73.2825 90.2618Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.9333 16.4858L89.1514 15.7148L89.3494 16.7949L91.9174 30.8488L92.0034 31.3168L92.4224 31.0928L100.005 27.0428L100.421 26.8209L100.085 26.4898L89.9333 16.4858Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.0629 96.2153L67.3428 96.1953L67.7769 96.7712L72.7468 103.368L73.0269 103.738L73.3029 103.366L77.9968 97.0403L78.3939 96.5052L77.7288 96.4863L68.0629 96.2153ZM109.23 40.8483L108.455 40.6133L108.766 41.0912L101.728 44.7743L89.7928 42.1093L88.9409 41.9193L89.4299 42.6412L93.7349 49.0113L82.6788 46.9203L81.8508 46.7643L82.3289 47.4583L86.7028 53.8083L75.8558 52.0892L75.0479 51.9603L75.5178 52.6302L80.4199 59.6143L80.6268 59.9092L80.9138 59.6913L87.6498 54.5593L94.6749 49.7993L101.83 45.5132L113.635 48.5753L113.723 48.7093L113.882 48.6393L114.631 48.8333L114.366 48.4263L121.598 45.2393L122.454 44.8613L121.559 44.5892L109.487 40.9263L109.383 40.7673L109.23 40.8483ZM78.4578 83.9922L73.7509 77.5262L79.3259 72.0862L79.9258 72.1453L79.6539 71.7662L79.9749 71.4532L79.3898 71.3953L74.5908 64.6843L74.3578 64.3572L74.0679 64.6363L68.1979 70.2993L67.5878 70.2393L67.8649 70.6202L67.5419 70.9323L68.1339 70.9902L72.8478 77.4653L67.6099 83.3052L67.1318 83.8373L67.8458 83.8823L78.1548 84.5433L78.8928 84.5903L78.4578 83.9922Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.8073 16.4062L89.4463 16.9803L99.5983 26.9843L99.7693 27.1533L99.9873 27.0522L107.712 23.4753L108.479 23.1202L107.683 22.8343L89.8073 16.4062Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.5297 42.1548L88.7957 42.5968L88.8577 42.6108L82.7047 46.7808L82.6937 46.7648L82.4117 46.9788L81.7238 47.4458L81.7827 47.4568L75.8787 51.9368L75.8667 51.9208L75.5847 52.1608L74.9437 52.6468L75.0017 52.6558L69.2247 57.5598L68.5847 57.4767L68.8637 57.8658L68.5107 58.1667L69.1367 58.2488L74.0267 65.0868L74.2467 65.3948L74.5347 65.1488L80.8107 59.7787L81.4397 59.8608L81.1677 59.4728L81.5208 59.1718L80.8997 59.0908L76.5557 52.9018L87.3927 54.6198L88.1917 54.7458L87.7327 54.0807L83.3787 47.7588L94.4237 49.8478L95.2417 50.0018L94.7758 49.3127L90.4927 42.9758L101.564 45.4488L101.66 45.5928L101.825 45.5068L102.541 45.6667L102.278 45.2698L109.3 41.5938L121.234 45.2158L121.315 45.3418L121.459 45.2898L133.392 49.4978L133.463 49.6128L133.598 49.5768L145.606 54.5018L146.591 54.9058L146.033 53.9998L141.942 47.3498L142.487 47.2038L141.639 46.8558L141.563 46.7328L141.426 46.7688L130.149 42.1498L130.216 42.1258L129.414 41.8428L129.33 41.7078L129.192 41.7578L128.318 41.3998L128.363 41.4718L117.138 37.5088L117.041 37.3578L116.894 37.4228L116.075 37.1338L116.401 37.6438L109.234 40.8548L97.2747 37.7248L97.1647 37.5588L97.0057 37.6548L96.2677 37.4618L96.5697 37.9167L89.8197 41.9798L89.8098 41.9648L89.5297 42.1548Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.7353 16.3887L89.5723 17.0587L107.448 23.4857L107.571 23.5297L107.692 23.4827L115.513 20.4327L115.432 19.7656L89.7353 16.3887Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.3152 83.5575L73.4382 77.8465L74.0152 77.8895L73.7392 77.5105L74.0232 77.1945L73.4792 77.1545L68.7812 70.7005L74.4332 65.2485L75.0402 65.3125L74.7652 64.9285L75.0852 64.6195L74.5002 64.5575L69.8002 57.9825L76.0192 52.7025L82.7062 47.6285L83.3632 47.7355L83.0992 47.3525L89.7922 42.8155L90.4742 42.9475L90.2092 42.5565L97.1132 38.4005L109.047 41.5235L109.136 41.6595L109.295 41.5885L110.044 41.7845L109.776 41.3725L116.925 38.1705L129.061 42.4545L141.166 47.4135L145.442 54.3635L145.564 54.5615L145.794 54.5245L153.36 53.2895L154.436 53.1135L153.456 52.6355L141.645 46.8675L137.285 39.7805L137.148 39.5575L136.897 39.6275L129.341 41.7265L124.961 34.7375L124.812 34.4985L124.547 34.5965L117.051 37.3745L112.572 30.3575L112.41 30.1035L112.136 30.2285L104.723 33.6045L104.713 33.5895L104.559 33.6705L103.785 33.4345L103.814 33.4795L92.4742 30.4855L92.3632 30.3185L92.2052 30.4145L91.4652 30.2195L91.7692 30.6775L84.9632 34.7945L84.9542 34.7805L84.7912 34.8905L84.0822 34.7305L84.3692 35.1545L83.9452 35.4105L84.0042 35.4235L77.7862 39.6395L77.7752 39.6245L77.6102 39.7505L76.9292 39.6185L77.2122 40.0295L76.8092 40.3015L76.8652 40.3125L70.7222 44.9575L70.0552 44.8475L70.3362 45.2485L69.9582 45.5345L70.6122 45.6425L75.0602 51.9795L64.3132 50.4945L63.5142 50.3845L63.9832 51.0405L68.8742 57.8815L63.1402 63.3485L62.5232 63.2825L62.8042 63.6685L62.4822 63.9755L63.0742 64.0385L67.8762 70.6355L62.6672 76.3515L62.0812 76.3085L62.3612 76.6875L62.0742 77.0035L62.6262 77.0445L67.4332 83.5325L62.8292 89.4935L62.2762 89.4715L62.5572 89.8445L62.3092 90.1665L62.8152 90.1875L67.6322 96.5795L63.7192 102.784L63.2062 102.781L63.4882 103.151L63.2822 103.476L63.7382 103.477L68.7002 109.958L69.0062 110.357L69.2712 109.927L73.2182 103.508L73.7222 103.508L73.4442 103.14L73.6442 102.815L73.1982 102.813L68.4882 96.5625L73.0042 90.5975L73.5472 90.6195L73.2702 90.2455L73.5142 89.9235L73.0162 89.9035L68.3152 83.5575Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.8984 40.4026L71.0225 45.5995L71.0145 45.6066L71.0054 45.6116L17.7864 81.0416L17.7845 81.0425L17.4055 80.4786L77.8984 40.4026ZM77.8984 40.4026L17.4055 80.4786L77.8984 40.4026ZM77.8984 40.4026L85.0555 35.5496H85.0565L84.6675 34.9745L84.6664 34.9756L17.4064 80.4615L17.4005 80.4656L77.8984 40.4026ZM17.4055 80.4786L17.3984 80.4666L17.4055 80.4786ZM89.5515 16.4156L89.6714 16.7486L89.6454 17.0765L115.342 20.4536L115.418 20.4636L115.491 20.4416L123.366 17.9806L123.272 17.3026L91.9404 16.4476L175.916 -7.67944L175.777 -8.35742L134.357 -3.20746L134.307 -3.20142L134.261 -3.18146L89.5515 16.4156Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.0958 45.1238L70.8788 44.8128L70.5888 45.0579L64.0418 50.5728L63.4458 51.0748L64.2178 51.1808L75.7538 52.7748L76.5428 52.8839L76.0858 52.2318L71.0958 45.1238ZM149.281 45.6238L149.158 45.4208L148.925 45.4619L141.343 46.7928L140.291 46.9768L141.25 47.4459L153.152 53.2578L154.21 53.7748L153.601 52.7668L149.281 45.6238ZM125.623 34.9368L125.687 34.9128L124.791 34.5969L123.801 34.1918L123.846 34.2628L112.395 30.2169L111.445 29.8809L111.987 30.7299L116.617 37.9828L116.768 38.2188L117.03 38.1218L124.523 35.3438L128.89 42.3138L129.028 42.5328L129.277 42.4638L137.082 40.2958L138.059 40.0239L137.121 39.6398L125.623 34.9368ZM63.2588 76.7318L68.5788 70.8928L69.0528 70.3729L68.3518 70.3138L57.6898 69.4138L56.9248 69.3499L57.3818 69.9658L62.3728 76.7028L57.6808 82.6538L57.1178 82.6248L57.4038 83.0048L57.1548 83.3209L57.6628 83.3478L62.7168 90.0548L62.9898 90.4178L63.2678 90.0588L68.0308 83.8918L68.5848 83.9208L68.3028 83.5408L68.5468 83.2249L68.0488 83.1989L63.2588 76.7318Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4004 80.4652L17.7794 81.0462L17.7844 81.0432L64.4534 51.1302L64.4724 51.1182L64.4894 51.1032L71.0364 45.5882L70.6204 45.0342L17.4054 80.4622L17.4004 80.4652Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M99.8049 26.363L99.0298 26.126L99.3448 26.6089L92.0948 30.481L91.3159 30.897L92.1699 31.1219L104.377 34.345L104.467 34.483L104.626 34.41L105.376 34.608L105.106 34.192L112.423 30.8589L113.256 30.48L112.381 30.212L100.064 26.442L99.9589 26.2809L99.8049 26.363ZM63.5438 63.5049L63.2949 63.1639L63.0089 63.4739L57.4069 69.524L56.9238 70.045L57.6318 70.105L68.2938 71.0049L69.0499 71.069L68.6028 70.455L63.5438 63.5049ZM137.142 39.649L136.069 39.127L136.694 40.1429L141.107 47.316L141.231 47.517L141.463 47.476L149.044 46.145L150.098 45.96L149.136 45.4919L137.142 39.649Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.2711 89.6375L62.972 89.2415L62.703 89.6575L58.567 96.0446L58.041 96.0355L58.329 96.4116L58.122 96.7305L58.579 96.7385L63.635 103.341L63.937 103.737L64.203 103.316L68.243 96.9116L68.759 96.9215L68.475 96.5446L68.675 96.2255L68.229 96.2175L63.2711 89.6375ZM57.652 56.5905L57.3311 56.8925L57.9221 56.9605L62.983 63.9135L63.216 64.2335L63.5031 63.9605L69.454 58.2866L70.0681 58.3575L69.789 57.9675L70.1071 57.6635L69.524 57.5966L64.548 50.6356L64.317 50.3125L64.028 50.5845L57.992 56.2705L57.367 56.1985L57.652 56.5905Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4053 80.4619L17.7693 81.0519L58.2903 56.9319L58.3233 56.9129L58.3503 56.8869L64.5033 51.0899L64.0782 50.5459L17.4053 80.4619Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M149.161 45.5052L148.688 45.9832L153.007 53.1262L153.115 53.3052L153.325 53.2932L160.418 52.8652L161.553 52.7972L160.573 52.2202L149.161 45.5052ZM120.363 27.3982L120.212 27.1572L119.946 27.2582L112.156 30.2202L111.272 30.5562L112.164 30.8712L124.544 35.2452L136.858 40.2822L137.842 40.6852L137.285 39.7802L132.792 32.4772L132.654 32.2522L132.4 32.3252L124.823 34.5152L120.363 27.3982ZM64.1851 102.92L63.8491 102.48L63.6 102.975L60.291 109.559L60.04 110.059L60.6 110.061L68.9741 110.092L69.6801 110.095L69.251 109.535L64.1851 102.92Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6827 16.3857L89.6797 17.0797L123.253 17.9947L123.297 17.9967L123.34 17.9867L130.979 16.2397L130.895 15.5547L89.6827 16.3857Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M107.548 22.7861L106.731 22.4971L107.06 23.0121L99.6957 26.4221L98.8727 26.8041L99.7397 27.0691L112.056 30.8381L112.139 30.9681L112.292 30.9101L113.079 31.1511L112.801 30.7171L119.927 28.0081L124.374 35.1051L124.513 35.3271L124.764 35.2541L132.593 32.9921L133.552 32.7151L132.628 32.3371L121.015 27.5941L121.077 27.5711L120.193 27.2581L119.204 26.8541L119.248 26.9241L107.794 22.8731L107.695 22.7181L107.548 22.7861ZM60.6017 109.368L59.8896 109.365L60.3267 109.927L65.4886 116.571L65.8307 117.011L66.0746 116.509L69.2867 109.897L69.5276 109.401L68.9767 109.399L60.6017 109.368ZM144.884 38.3521L144.759 38.1471L144.523 38.1911L136.926 39.6201L135.896 39.8141L136.838 40.2731L148.833 46.1161L149.89 46.6311L149.282 45.6241L144.884 38.3521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4141 80.4559L17.7511 81.062L52.5781 62.991L52.6311 62.9639L52.6711 62.921L58.3651 56.8719L57.9351 56.3359L17.4141 80.4559Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.1108 69.7836L63.4008 64.0707L63.9968 64.1266L63.7118 63.7357L63.9948 63.4296L63.4518 63.3787L58.3928 56.4296L58.1468 56.0917L57.8598 56.3956L52.2818 62.3217L51.6768 62.2646L51.9668 62.6567L51.6798 62.9617L52.2318 63.0137L57.2228 69.7507L52.4398 75.6907L51.8648 75.6536L52.1578 76.0416L51.9078 76.3516L52.4158 76.3846L57.4178 83.0226L53.3198 89.1846L52.7808 89.1666L53.0758 89.5516L52.8678 89.8636L53.3258 89.8787L58.3428 96.4307L55.0358 102.789L54.7788 103.284L55.3368 103.296L63.9028 103.477L64.6238 103.493L64.1848 102.92L59.1768 96.3787L63.1778 90.1996L63.7068 90.2167L63.4168 89.8317L63.6178 89.5197L63.1708 89.5046L58.2798 83.0146L62.9698 77.0676L63.5338 77.1036L63.2468 76.7156L63.4908 76.4056L62.9928 76.3737L58.1108 69.7836ZM132.648 32.3456L131.577 31.8256L132.201 32.8397L136.694 40.1426L136.819 40.3456L137.054 40.3016L144.415 38.9166L148.395 45.4996L147.852 45.5397L148.733 46.0577L148.798 46.1656L148.902 46.1577L160.221 52.8176L160.695 52.3417L156.67 45.5817L157.22 45.5406L156.337 45.0237L156.273 44.9156L156.167 44.9236L145.561 38.7006L145.683 38.6786L144.738 38.2197L132.648 32.3456ZM115.681 19.9246L115.529 19.6816L115.261 19.7856L107.44 22.8367L106.57 23.1757L107.45 23.4866L119.954 27.9086L120.891 28.2407L120.363 27.3976L115.681 19.9246Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4316 80.446L17.7197 81.0759L47.3837 69.282L47.4667 69.249L47.5237 69.1799L52.6857 62.9039L52.2587 62.376L17.4316 80.446Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M128.217 25.0396L128.078 24.8125L127.822 24.8895L119.97 27.2495L119.03 27.5316L119.938 27.9025L132.366 32.9785L133.349 33.3806L132.792 32.4755L128.217 25.0396Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M89.6768 16.3857L89.6968 17.0797L89.7028 17.0787L130.909 16.2477H130.928L130.948 16.2447L138.067 15.2857L138.008 14.5947L89.6828 16.3857H89.6768Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.3521 102.602L54.624 102.587L55.07 103.162L60.327 109.927L60.664 110.361L60.911 109.87L64.22 103.286L64.467 102.795L63.9171 102.784L55.3521 102.602ZM140.406 30.9468L140.279 30.7379L140.04 30.7858L132.428 32.3178L131.421 32.5208L132.345 32.9698L144.435 38.8438L145.491 39.3568L144.884 38.3518L140.406 30.9468ZM115.518 19.7878L114.522 19.3818L115.093 20.2938L119.776 27.7658L119.916 27.9908L120.169 27.9138L128.022 25.5538L128.962 25.2708L128.053 24.9008L115.518 19.7878Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.8939 80.9243L17.2969 80.5703L11.3428 90.1003L11.3339 90.1143L11.3268 90.1293L6.67285 99.4043L7.28485 99.7303L17.8939 80.9243Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M156.345 45.0366L151.992 37.7256L151.88 37.5386L151.663 37.5576L144.556 38.1866L143.476 38.2826L144.411 38.8315L155.833 45.5326L160.099 52.6966L160.193 52.8556L160.377 52.8655L166.954 53.2386L168.173 53.3076L167.172 52.6086L156.345 45.0366ZM52.6966 62.4775L52.4326 62.1205L52.1506 62.4636L47.1026 68.6006L46.5166 68.5555L46.8146 68.9506L46.5636 69.2546L47.0726 69.2936L52.1706 76.0596L47.9766 82.1976L47.4246 82.1716L47.7256 82.5656L47.5166 82.8705L47.9756 82.8926L53.2256 89.7486L53.5216 90.1346L53.7906 89.7306L58.0226 83.3666L58.5636 83.3915L58.2666 82.9976L58.4696 82.6926L58.0216 82.6716L53.0366 76.0566L57.8166 70.1205L58.3916 70.1646L58.0986 69.7686L58.3436 69.4645L57.8456 69.4266L52.6966 62.4775ZM60.8746 109.503L60.4906 109.009L60.2756 109.596L58.0256 115.712L57.5266 115.693L57.8716 116.131L57.7736 116.397L58.0906 116.409L63.2486 122.964L63.6396 123.46L63.8486 122.864L66.0056 116.715L66.4936 116.734L66.1526 116.295L66.2456 116.03L65.9376 116.018L60.8746 109.503ZM128.073 24.9106L127.004 24.3926L127.626 25.4045L132.201 32.8406L132.328 33.0466L132.565 32.9986L140.177 31.4666L141.187 31.2635L140.26 30.8146L128.073 24.9106Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4639 80.431L17.6638 81.093L42.7778 75.778L42.9119 75.75L42.9899 75.638L47.5399 69.159L48.2078 68.208L47.1279 68.637L17.4639 80.431Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M140.284 30.8271L139.812 31.3062L144.29 38.7111L144.402 38.8961L144.617 38.8771L151.724 38.2482L152.807 38.1522L151.869 37.6031L140.284 30.8271Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176 -7.71765L175.724 -8.34766L89.5938 16.3984L89.7027 17.0784L138.034 15.2874L138.124 15.2844L138.201 15.2374L176 -7.71765Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.7769 89.3264L53.4509 88.9003L53.1959 89.3724L49.6849 95.8943L49.4209 96.3834L49.9769 96.4053L58.7399 96.7413L59.4769 96.7703L59.0279 96.1843L53.7769 89.3264ZM123.558 17.4664L123.417 17.2363L123.159 17.3173L115.284 19.7783L114.363 20.0663L115.256 20.4304L127.791 25.5424L128.773 25.9434L128.217 25.0394L123.558 17.4664Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.0028 95.7123L49.2588 95.6843L49.7158 96.2723L54.9498 103.007L52.6758 108.902L52.1588 108.871L52.5138 109.322L52.4138 109.581L52.7328 109.6L57.9908 116.282L58.3748 116.77L58.5898 116.187L60.8378 110.076L61.3438 110.106L60.9928 109.654L61.0878 109.396L60.7778 109.377L55.7548 102.912L59.0608 96.5563L59.3128 96.0703L58.7658 96.0493L50.0028 95.7123ZM163.281 45.9223L163.833 45.9453L162.92 45.3083L162.863 45.2103L162.775 45.2073L151.953 37.6613L147.518 30.2143L147.405 30.0244L147.185 30.0463L140.292 30.7593L136.096 23.8214L136.608 23.7113L135.791 23.3173L135.717 23.1933L135.591 23.2203L123.413 17.3363L122.346 16.8203L122.967 17.8303L127.369 24.9863L126.863 25.0953L127.679 25.4903L127.755 25.6133L127.878 25.5863L139.945 31.4333L151.441 38.1573L155.459 44.9053L154.914 44.8823L155.825 45.5193L155.883 45.6163L155.969 45.6203L166.775 53.1763L167.272 52.7163L163.281 45.9223ZM47.5328 68.7513L47.2458 68.3703L46.9718 68.7603L42.5338 75.0803L41.9678 75.0463L42.2758 75.4483L42.0658 75.7473L42.5268 75.7753L47.8778 82.7643L48.1698 83.1453L48.4398 82.7493L52.7728 76.4073L53.3268 76.4413L53.0238 76.0393L53.2278 75.7403L52.7778 75.7133L47.5328 68.7513Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9606 82.2335L39.1725 82.2445L39.2786 82.0605L43.0065 75.6115L43.3956 74.9375L42.6345 75.0985L17.5195 80.4135L17.5726 81.0995L38.9606 82.2335Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M123.112 17.9608L135.304 23.8518L139.812 31.3058L139.926 31.4938L140.144 31.4719L147.036 30.7589L151.074 37.5399L150.548 37.5269L151.434 38.1448L151.493 38.2448L151.581 38.2468L162.399 45.7899L166.674 53.0678L166.754 53.2028L166.907 53.2328L172.911 54.4019L173.198 53.7938L162.928 45.3209L158.881 38.4318L159.414 38.4448L158.526 37.8278L158.467 37.7268L158.378 37.7249L148.186 30.6398L148.313 30.6268L147.473 30.1368L142.962 22.5618L142.846 22.3679L142.623 22.3948L135.729 23.2138L131.198 15.7219L131.069 15.5068L130.824 15.5628L123.185 17.3109L122.222 17.5308L123.112 17.9608ZM48.4289 82.3419L48.1079 81.9229L47.8509 82.3839L44.3389 88.6808L43.7949 88.6508L44.1209 89.0709L43.9629 89.3549L44.3589 89.3768L49.5939 96.1138L47.2259 101.964L46.6899 101.922L47.0559 102.386L46.9539 102.639L47.2739 102.664L52.6359 109.477L53.0129 109.956L53.2319 109.388L55.5749 103.315L56.0999 103.357L55.7379 102.891L55.8349 102.64L55.5239 102.616L50.4029 96.0248L53.7029 89.8958L54.2359 89.9258L53.9139 89.5048L54.0659 89.2219L53.6799 89.1998L48.4289 82.3419ZM58.5369 115.853L58.0849 115.279L57.9259 115.992L56.6359 121.771L56.1309 121.725L56.5329 122.23L56.4829 122.454L56.7289 122.476L61.9869 129.087L62.4479 129.667L62.5979 128.941L63.7989 123.122L64.2929 123.167L63.8939 122.661L63.9399 122.438L63.7019 122.417L58.5369 115.853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.0999 88.3882L36.4089 88.5102L36.5438 88.2072L39.2958 82.0292L39.5009 81.5682L38.9968 81.5412L17.6099 80.4072L17.4648 81.0772L36.0999 88.3882Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.329 47.0886L169.896 47.1896L168.922 46.3876L168.872 46.3026L168.803 46.2906L158.486 37.8006L157.966 38.2446L161.979 45.0756L161.42 44.9756L162.391 45.7776L162.441 45.8626L162.509 45.8746L172.756 54.3286L173.277 53.8876L169.329 47.0886ZM131.076 15.6016L130.605 16.0806L135.251 23.7636L135.366 23.9546L135.588 23.9286L142.704 23.0826L143.735 22.9606L142.838 22.4386L131.076 15.6016ZM42.9813 75.2276L42.6663 74.8146L42.4063 75.2646L38.6783 81.7136L38.4014 82.1936L38.9543 82.2336L48.1293 82.8986L48.8983 82.9546L48.4293 82.3416L42.9813 75.2276Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9105 80.892L17.2905 80.583L6.68152 99.39L6.66254 99.424L6.65051 99.461L1.43555 117.009L2.08655 117.246L17.9105 80.892Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.1805 109.047L52.7385 108.485L52.5706 109.18L51.1185 115.208L51.0266 115.589L51.4155 115.634L58.2245 116.411L59.0505 116.506L58.5366 115.852L53.1805 109.047ZM39.0035 81.5413L38.2266 81.4844L38.7045 82.0994L44.2615 89.2513L44.5825 89.6653L44.8386 89.2073L48.4565 82.7214L48.7216 82.2463L48.1785 82.2064L39.0035 81.5413Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M154.398 30.8004L154.912 30.8044L154.049 30.2064L153.988 30.1024L153.898 30.1014L142.923 22.4964L138.319 14.7634L138.202 14.5664L137.974 14.5974L130.855 15.5574L129.853 15.6924L130.727 16.2004L142.412 22.9934L146.607 30.0384L146.1 30.0344L146.961 30.6324L147.022 30.7364H147.111L158.056 38.3454L168.385 46.8434L172.677 54.2354L172.74 54.3434L172.858 54.3864L178.223 56.3534L178.584 55.7784L168.929 46.4004L164.547 38.8554L164.465 38.7124L164.302 38.6864L158.482 37.7524L154.398 30.8004ZM51.4952 114.945L50.6631 114.85L51.1852 115.505L56.5532 122.255L56.2012 127.865L56.1821 128.176L56.4891 128.229L62.2001 129.211L63.0961 129.366L62.5302 128.654L57.2831 122.057L58.6031 116.142L58.6871 115.766L58.3041 115.722L51.4952 114.945ZM44.8102 88.8264L44.4482 88.3594L44.2172 88.9034L41.6732 94.8944L41.1182 94.8404L41.4941 95.3184L41.3892 95.5644L41.7122 95.5954L47.1802 102.543L47.5502 103.013L47.7742 102.459L50.2151 96.4284L50.7592 96.4824L50.3871 96.0034L50.4861 95.7584L50.1732 95.7274L44.8102 88.8264Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2509 94.2017L34.6499 94.5137L34.7959 94.0276L36.5589 88.1647L36.6509 87.8586L36.3539 87.7427L17.7179 80.4307L17.3779 81.0267L34.2509 94.2017Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.9125 127.599L57.2285 122.561L57.7665 122.667L57.2615 122.032L57.2725 121.863L57.0995 121.828L51.8325 115.207L53.1715 109.649L53.7115 109.722L53.2845 109.18L53.3355 108.971L53.0935 108.938L48.2725 102.811L47.7245 102.115L47.2925 101.566L42.2915 95.212L44.7535 89.414L45.3175 89.48L44.9345 88.987L45.0355 88.749L44.7205 88.712L39.2525 81.675L38.8965 81.218L38.6615 81.746L36.0135 87.691L35.4375 87.624L35.8245 88.116L35.7175 88.355L36.0425 88.393L41.5115 95.341L39.9025 101.093L39.7975 101.468L40.1825 101.529L47.0205 102.612L45.6415 107.92L45.0895 107.845L45.5205 108.386L45.4655 108.596L45.7145 108.63L51.0985 115.399L50.6875 120.561L50.1365 120.452L50.6455 121.086L50.6315 121.257L50.8105 121.292L55.7035 127.391L56.2765 128.105L56.8365 128.804L61.7165 134.885L62.2905 135.601L62.3335 134.685L62.6045 128.887L62.6185 128.581L62.3165 128.529L56.9125 127.599ZM153.527 30.69L153.573 30.768L157.965 38.245L158.048 38.386L158.209 38.412L164.029 39.346L168.352 46.79L168.417 46.901L168.466 46.918L178.099 56.277L178.641 55.855L174.298 48.307L174.234 48.195L174.185 48.178L165.279 39.547L165.458 39.576L164.516 38.801L160.415 31.742L160.938 31.816L160.024 31.069L159.971 30.978L159.901 30.968L150.188 23.021L150.324 23.02L149.485 22.441L149.423 22.334L144.875 14.592L144.771 14.415L144.566 14.421L138.01 14.595L136.948 14.623L137.76 15.184L142.055 22.397L141.568 22.401L142.404 22.981L142.466 23.087H142.555L153.527 30.69Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.3147 99.6584L6.65771 99.4395L3.34674 108.405L3.33875 108.427L3.33374 108.45L1.42871 117.031L2.09973 117.205L7.3147 99.6584Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.2922 100.844L39.4102 100.704L39.9661 101.402L45.6292 108.521L46.0612 109.065L46.2361 108.393L47.7891 102.417L47.8851 102.047L47.5071 101.987L40.2922 100.844ZM174.26 48.252L173.698 48.653L178.041 56.2009L178.087 56.2789L178.165 56.3259L182.811 59.0889L183.25 58.5629L174.26 48.252Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.024 -7.73352L175.64 -8.31055L137.841 14.6445L136.724 15.3224L138.03 15.2875L144.585 15.1135L144.693 15.1105L144.781 15.0475L176.024 -7.73352Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.8182 127.671L56.0552 126.719L51.3591 120.867L51.7701 115.715L52.3381 115.842L51.8132 115.181L51.8262 115.017L51.6512 114.978L46.1722 108.09L45.6382 107.419L45.5552 108.272L45.0432 113.508L44.4622 113.378L44.9911 114.038L44.9752 114.203L45.1561 114.243L50.6691 121.115L51.2261 126.294L51.2502 126.517L51.4631 126.586L56.2302 128.149L56.7952 132.749L56.2291 132.579L56.8712 133.374L56.8871 133.5L57.0012 133.535L62.4581 140.294L63.2411 141.263L63.0721 140.029L62.4042 135.156L62.9562 135.321L62.3182 134.526L62.3011 134.4L62.1912 134.367L57.5482 128.581L56.8182 127.671ZM178.84 50.871L169.855 40.584L169.806 40.499L169.756 40.482L160.033 31.082L155.83 23.846L156.331 23.908L155.448 23.188L155.393 23.094L155.321 23.085L144.796 14.499L144.277 14.943L148.571 22.252L148.078 22.191L148.959 22.912L149.014 23.006L149.084 23.014L159.489 31.526L163.947 39.204L164.013 39.318L164.063 39.334L173.722 48.695L173.744 48.734L173.775 48.751L182.727 59.019L183.289 58.619L178.918 50.971L178.871 50.889L178.84 50.871ZM36.5002 87.851L36.0872 87.327L35.8951 87.966L34.2211 93.532L33.6191 93.424L34.0792 94.002L34.0201 94.201L34.2742 94.247L39.9661 101.402L40.3882 101.933L40.5712 101.28L42.1422 95.663L42.7302 95.768L42.2751 95.189L42.3301 94.991L42.0842 94.947L36.5002 87.851Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.055 -7.75891L175.615 -8.29395L144.372 14.4871L143.642 15.0201L144.541 15.1131L150.478 15.7271L150.634 15.7431L150.749 15.6361L176.055 -7.75891Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M174.381 43.0707L165.342 32.7407L165.292 32.6527L160.758 24.7737L160.688 24.6527L160.554 24.6137L155.41 23.1217L150.813 15.2077L150.725 15.0547L150.549 15.0367L144.612 14.4227L143.458 14.3027L144.357 15.0357L154.912 23.6457L159.457 31.4717L159.525 31.5887L159.575 31.6047L169.296 41.0017L169.319 41.0427L169.35 41.0597L178.333 51.3447L182.687 58.9627L182.714 59.0087L182.753 59.0447L186.591 62.6057L187.107 62.1467L178.908 50.9527L174.461 43.1717L174.413 43.0877L174.381 43.0707ZM51.6788 125.927L50.5638 125.562L51.3008 126.475L56.8958 133.404L58.5718 138.219L58.6178 138.35L58.6758 138.38L64.2328 145.236L64.8288 144.9L63.0548 139.959L63.0088 139.83L62.9518 139.801L57.5358 133.093L56.8918 127.845L56.8648 127.627L56.6558 127.558L51.6788 125.927ZM40.5088 100.97L39.9898 100.318L39.8928 101.146L39.2878 106.317L38.6758 106.166L39.2258 106.852L39.2068 107.012L39.3898 107.057L45.0148 114.067L45.4818 119.174L45.5018 119.395L45.7108 119.47L50.8868 121.304L52.0368 121.711L51.2728 120.76L45.7088 113.825L46.2058 108.738L46.8048 108.886L46.2588 108.199L46.2748 108.041L46.0978 107.997L40.5088 100.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.09 -7.79456L175.585 -8.26758L150.278 15.1274L149.816 15.5544L150.424 15.7175L155.674 17.1265L155.892 17.1844L156.034 17.0095L176.09 -7.79456Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M150.753 15.1318L150.212 15.5558L154.879 23.5908L154.949 23.7108L155.082 23.7498L160.359 25.2798L160.696 24.6968L150.753 15.1318ZM45.9424 118.816L44.7854 118.406L45.5564 119.361L51.2614 126.426L52.8564 131.166L52.8994 131.296L53.0194 131.361L57.0374 133.538L57.4724 133.016L51.9044 126.118L51.3474 120.94L51.3234 120.723L51.1184 120.65L45.9424 118.816ZM34.7344 93.7128L34.2304 93.0778L34.1194 93.8809L33.4194 98.9849L32.7734 98.8109L33.3454 99.5228L33.3244 99.6779L33.5094 99.7278L39.3164 106.965L39.8354 107.612L39.9314 106.788L40.5344 101.626L41.1654 101.797L40.5974 101.083L40.6154 100.929L40.4374 100.881L34.7344 93.7128ZM182.718 54.4348L182.691 54.3868L182.676 54.3738L174.438 43.1389L173.858 43.5158L178.315 51.3148L178.342 51.3618L178.357 51.3759L186.546 62.5568L187.128 62.1808L182.718 54.4348Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.916 35.2189L169.866 35.1329L169.834 35.1159L160.74 24.7439L156.065 16.6179L155.993 16.4929L155.854 16.4559L150.603 15.0469L150.273 15.6319L160.181 25.1619L164.758 33.1169L164.807 33.2019L164.839 33.2189L173.899 43.5729L174.461 43.1719L169.916 35.2189ZM53.3511 130.751L52.9161 131.274L58.6301 138.324L59.2272 137.991L57.5311 133.119L57.4872 132.992L57.3691 132.928L53.3511 130.751ZM39.8582 106.531L39.1592 105.66L39.2411 106.774L39.6232 111.912L39.6392 112.132L39.8452 112.211L45.2272 114.256L46.4182 114.708L45.6212 113.714L39.8582 106.531Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.7773 94.0786L34.8973 93.8265L34.6773 93.6556L17.8054 80.4806L17.3193 80.1016L17.2473 80.7136L9.65234 145.246L10.3104 145.436L34.7773 94.0786Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.0916 111.562L38.8926 111.107L39.6986 112.105L45.5566 119.361L46.2766 120.252L46.1716 119.111L45.6956 113.9L45.6756 113.684L45.4736 113.607L40.0916 111.562Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9364 80.7936L17.2744 80.6146L1.45038 116.969L1.40039 117.084L1.43542 117.205L9.66437 145.382L10.3414 145.326L17.9364 80.7936ZM176.121 -7.84039L175.55 -8.23242L155.494 16.5726L155.222 16.9096L155.61 17.1016L160.095 19.3156L160.386 19.4586L160.549 19.1776L176.121 -7.84039Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M178.22 46.5315L178.194 46.4865L178.179 46.4725L169.906 35.2025L165.279 27.1085L165.228 27.0195L165.196 27.0025L156.025 16.5625L155.463 16.9645L160.156 25.1205L160.207 25.2085L160.24 25.2255L169.331 35.5945L173.859 43.5165L173.887 43.5655L173.902 43.5795L182.126 54.7955L186.526 62.5245L186.532 62.5355L186.539 62.5465L189.47 66.9035L189.835 66.6695L190.059 66.5375L185.602 58.7005L185.596 58.6885L185.593 58.6845L178.22 46.5315ZM55.6379 135.542L58.6309 138.33L61.3688 142.604L61.3928 142.643L61.4078 142.656L67.0638 149.625L67.6238 149.219L64.7938 144.83L64.7698 144.792L64.7559 144.779L59.1688 137.888L53.4949 130.887L51.8998 126.146L51.8569 126.021L51.7988 125.989L46.0969 118.925L45.4968 119.25L47.0269 123.971L47.0688 124.099L47.1288 124.135L52.9039 131.26L55.5809 135.474L55.6049 135.51L55.6379 135.542ZM33.9808 99.2065L33.3038 98.3625L33.3638 99.4425L33.6149 103.958L32.8809 103.661L33.6519 104.615L33.6578 104.724L33.7789 104.773L39.6989 112.106L40.3969 112.969L40.3138 111.862L39.9749 107.279L40.6898 107.568L39.9249 106.615L39.9169 106.508L39.8008 106.461L33.9808 99.2065Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.142 -7.8855L175.519 -8.1875L159.948 18.8305L159.802 19.0836L160.026 19.2705L163.652 22.2975L164.02 22.6046L164.197 22.1595L176.142 -7.8855Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M173.634 38.4764L173.605 38.4254L173.59 38.4114L165.269 27.0914L160.549 18.8334L160.497 18.7415L160.402 18.6945L155.917 16.4805L155.503 17.0204L164.694 27.4835L169.313 35.5645L169.342 35.6144L169.357 35.6284L177.641 46.9125L178.222 46.5355L173.634 38.4764ZM56.1097 135.033L55.6047 135.506L61.3907 142.636L61.9527 142.23L59.1917 137.918L59.1677 137.881L59.1357 137.852L56.1097 135.033ZM40.2377 111.669L39.6367 111.99L41.0777 116.63L41.1177 116.758L41.1787 116.794L47.0867 124.083L47.6867 123.758L46.1567 119.036L46.1157 118.911L46.0567 118.876L40.2377 111.669Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.11395 117.091L1.42096 117.09L1.00195 125.19L1 125.225L1.00494 125.261L2.16498 132.759L2.85394 132.689L2.11395 117.091Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M181.055 50.7038L181.047 50.6918L181.044 50.6877L173.628 38.4668L173.03 38.8188L177.618 46.8777L177.625 46.8898L177.628 46.8938L185.003 59.0508L185.602 58.6998L181.055 50.7038ZM47.6255 123.646L47.0625 124.049L49.6745 128.196L49.6985 128.233L49.7295 128.263L52.9465 131.307L53.4545 130.837L47.6255 123.646Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M203.152 5.8374L203.524 5.25739L192.066 -4.0426L192.008 -4.0896L191.936 -4.10858L175.909 -8.34961L175.667 -7.70258L203.152 5.8374Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M168.953 30.2528L168.923 30.1998L168.908 30.1868L160.527 18.7988L159.947 19.1768L164.677 27.4518L164.707 27.5048L164.722 27.5178L173.053 38.8528L173.634 38.4758L168.953 30.2528ZM50.2072 127.759L49.6992 128.23L55.6052 135.506L56.1672 135.101L53.4781 130.87L53.4551 130.833L53.4241 130.804L50.2072 127.759ZM34.2621 104.267L33.6592 104.583L35.0082 109.138L35.0461 109.266L35.1592 109.335L39.7872 112.182L40.2382 111.669L34.2621 104.267Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.154 -7.92261L175.497 -8.14258L163.552 21.9034L163.483 22.0754L163.589 22.2284L166.255 26.0784L166.678 26.6904L166.875 25.9724L176.154 -7.92261Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.5223 108.744L35.0713 109.258L41.1403 116.746L41.7413 116.424L40.3003 111.784L40.2613 111.66L40.1503 111.591L35.5223 108.744ZM176.414 42.5432L176.407 42.5311L176.404 42.5272L168.948 30.2441L168.35 30.5961L173.032 38.8192L173.039 38.8311L173.041 38.8351L180.457 51.0551L181.055 50.7042L176.414 42.5432Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.0176 99.5823L34.0456 99.5293L34.0536 99.4703L34.8076 93.9763L34.1506 93.7793L9.68359 145.136L10.3046 145.445L34.0176 99.5823Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6792 116.309L41.1152 116.711L43.6482 120.768L43.6712 120.805L43.6862 120.82L49.7002 128.23L50.2622 127.826L47.6502 123.68L47.6282 123.644L47.6132 123.629L41.6792 116.309ZM164.176 21.8603L164.145 21.8063L164.097 21.7662L160.471 18.7383L159.969 19.2103L168.373 30.6302L168.953 30.2532L164.176 21.8603Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.16 -7.94647L175.485 -8.10547L166.205 25.7896L166.177 25.8925L166.212 25.9926L167.803 30.6705L168.219 31.8936L168.472 30.6265L176.16 -7.94647Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M171.678 34.2136L171.67 34.2006L171.667 34.1966L164.171 21.8516L163.573 22.2036L168.351 30.5956L168.358 30.6086L168.361 30.6126L175.816 42.8946L176.414 42.5426L171.678 34.2136ZM35.6099 108.821L35.0459 109.222L37.4939 113.186L37.5169 113.222L37.5469 113.252L41.1679 116.776L41.6789 116.309L35.6099 108.821Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2914 104.66L34.3445 104.57L34.3384 104.465L34.0565 99.4028L33.9865 98.1338L33.4025 99.2628L9.68945 145.126L10.2964 145.461L34.2914 104.66Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.0305 112.755L37.5195 113.222L43.6716 120.803L44.2355 120.401L41.7036 116.344L41.6805 116.308L41.6506 116.279L38.0305 112.755Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.10089 117.01L1.4209 117.124L2.16089 132.722L2.16589 132.809L2.2099 132.883L9.69891 145.463L10.3299 145.188L2.10089 117.01ZM203.086 5.79596L203.556 5.28796L198.491 -0.0240479L198.461 -0.0550537L198.424 -0.0790405L192.031 -4.06702L191.628 -3.50305L203.086 5.79596ZM176.162 -7.95703L175.48 -8.08105L167.792 30.491L167.782 30.537L167.786 30.584L168.183 36.0889L168.871 36.1199L176.162 -7.95703Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M166.841 25.71L166.834 25.697L166.825 25.684L164.16 21.834L163.578 22.212L171.079 34.5649L171.677 34.2139L166.841 25.71Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.6252 109.238L35.7202 109.101L35.6732 108.941L34.3242 104.386L34.0982 103.62L33.6932 104.309L9.69824 145.109L10.2812 145.484L35.6252 109.238Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.2264 174.285L35.7305 174.769L41.8934 181.706L41.9155 181.73L41.9415 181.75L49.0005 187.167L49.4485 186.638L36.2264 174.285Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M176.162 -7.95831L175.478 -8.07031L175.477 -8.06927L168.186 36.0067L168.185 36.0127L167.261 42.3307L167.26 42.3367L167.946 42.4427L167.947 42.4367L176.162 -7.95728V-7.95831ZM38.0519 113.23L38.2179 113.037L38.0839 112.821L35.6359 108.857L35.3589 108.408L35.0559 108.841L9.71191 145.086L10.2599 145.511L38.0519 113.23Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M224.988 47.0059L224.341 47.2448L226.374 55.6088L226.278 55.6278L226.469 55.9988L226.538 56.2828L226.607 56.2689L230.811 64.4468L231.456 64.2048L229.338 55.7148L229.43 55.6959L229.24 55.3248L229.169 55.0408L229.102 55.0548L224.988 47.0059ZM29.9335 167.203L29.4375 167.687L35.7305 174.769L35.7535 174.795L35.7665 174.804L48.9745 187.145L49.4715 186.661L43.2195 179.598L43.1965 179.572L43.1835 179.562L29.9335 167.203Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.80548 132.527L2.1875 132.84L5.03247 139.59L5.05145 139.634L5.08148 139.671L9.72449 145.5L10.2945 145.107L2.80548 132.527Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.6054 116.536L41.8824 116.28L41.6044 116.026L38.0234 112.748L37.7594 112.506L37.5264 112.777L9.73438 145.059L10.2324 145.54L41.6054 116.536Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.5058 159.97L23.0098 160.454L29.4368 167.687L29.4598 167.713L29.4878 167.734L36.3628 172.889L36.8078 172.358L23.5058 159.97Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.132 41.1803L205.775 40.9803L203.651 5.50635L203.639 5.3053L203.458 5.21631L175.973 -8.32471L174.91 -8.84766L175.523 -7.83368L205.132 41.1803Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.831 172.382L36.808 172.355L36.779 172.334L29.905 167.179L29.46 167.71L42.71 180.07L48.951 187.121L48.999 187.176L49.066 187.206L58.016 191.339L58.3669 190.745L43.194 179.57L36.831 172.382ZM222.359 46.9362L221.823 46.9642L222.068 47.4412L226.486 56.0362L227.132 55.7952L225.015 47.0822L224.948 46.8022L224.66 46.8172L222.359 46.9362ZM216.248 29.9072L215.601 30.1422L217.523 38.5772L217.58 38.8252L217.833 38.8462L220.326 39.0502L220.947 39.1012L220.664 38.5472L216.248 29.9072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.3177 119.05L46.7657 118.722L46.2737 118.464L41.5307 115.975L41.3137 115.861L41.1337 116.027L9.76074 145.031L10.2017 145.565L46.3177 119.05Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9413 152.581L16.4453 153.065L23.0114 160.455L23.0353 160.482L23.0483 160.492L36.3353 172.865L36.8314 172.383L30.3043 165.008L30.2803 164.981L30.2664 164.971L16.9413 152.581ZM227.103 55.7195L226.448 55.8825L226.577 65.9815L226.583 66.4885L227.053 66.2995L231.248 64.6105L231.601 64.4685L231.427 64.1305L227.103 55.7195ZM217.89 38.1545L217.268 38.1035L217.553 38.6595L222.068 47.4425L222.714 47.2035L220.692 38.6255L220.635 38.3805L220.383 38.3595L217.89 38.1545Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.441 41.347L206.03 41.326L205.725 40.821L176.117 -8.19299L175.629 -9L175.477 -8.06897L167.261 42.331L167.193 42.749L167.616 42.734L205.441 41.347Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7763 172.332L36.3113 172.841L42.6993 180.058L42.7483 180.114L42.7833 180.129L57.9553 191.304L58.4223 190.796L52.1053 183.593L52.0553 183.537L52.0203 183.521L36.7763 172.332ZM226.794 65.6551L226.439 65.7981L226.616 66.1371L231.051 74.6571L231.705 74.4891L231.465 64.2811L231.454 63.7801L230.989 63.9671L226.794 65.6551ZM211.736 21.0811L211.088 21.313L212.907 29.5961L212.956 29.8171L213.177 29.8621L215.87 30.4051L216.575 30.5471L216.248 29.9071L211.736 21.0811ZM10.2323 145.031L9.7373 145.516L16.4453 153.065L16.4703 153.093L16.5003 153.115L23.1683 157.98L23.6093 157.446L10.2323 145.031Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.218 120.658L52.926 120.238L52.131 120.024L46.203 118.436L46.0421 118.393L45.907 118.491L9.79102 145.006L10.173 145.584L52.218 120.658Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6328 157.47L23.6077 157.442L23.5767 157.42L16.9088 152.555L16.4688 153.089L29.8077 165.491L30.3037 165.007L23.6328 157.47ZM222.685 47.124L222.03 47.283L222.043 57.268L222.044 57.74L222.494 57.598L226.899 56.208L227.291 56.084L227.103 55.719L222.685 47.124Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M213.536 29.2265L211.894 21.7455L212.11 21.8115L211.757 21.1195L211.723 20.9666L211.67 20.9495L207.126 12.0605L206.478 12.2905L208.066 19.8365L207.841 19.7665L208.197 20.4586L208.23 20.6125L208.285 20.6306L212.608 29.0385L212.897 29.6015L212.665 39.2426L212.655 39.6575L213.065 39.5935L217.51 38.9045L217.41 48.0845L217.239 48.1245L217.406 48.4435L217.402 48.7985L217.57 48.7595L221.891 57.0605L222.082 57.4265L226.561 66.0305L224.48 77.2095L224.307 77.2866L224.424 77.5065L224.325 78.0396L224.633 77.9005L229.05 86.2175L229.525 87.1125L229.698 86.1135L231.669 74.7375L231.836 74.6616L231.72 74.4395L231.812 73.9125L231.515 74.0455L227.269 65.8895L227.141 55.8726L227.135 55.4055L226.69 55.5455L222.736 56.7935L222.724 47.5576L222.888 47.5195L222.723 47.1985V46.8455L222.561 46.8835L218.39 38.7686L218.19 38.3795L216.277 29.9875L216.227 29.7686L216.007 29.7245L213.536 29.2265ZM36.3618 172.899L36.3967 172.914L51.6077 184.079L51.6577 184.136L57.8997 191.253L57.9727 191.336L58.0797 191.362L68.9807 193.967L69.0917 193.684L69.3237 193.403L62.9717 186.049L62.8977 185.964L62.8358 185.95L45.6087 176.187L45.5967 176.173L39.0457 168.704L38.9927 168.644L38.9567 168.629L23.6077 157.442L16.8117 149.764L16.7857 149.735L16.7547 149.713L10.1987 145.004L9.76074 145.54L23.1237 157.943L29.7838 165.467L29.8367 165.526L36.3107 172.841L36.3618 172.899Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.16 144.979L9.79395 145.567L16.35 150.276L16.3849 150.301L16.426 150.317L25 153.682L25.29 153.053L10.16 144.979Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.756 149.714L16.293 150.224L23.113 157.93L23.167 157.991L23.2029 158.005L38.581 169.213L39.046 168.704L32.2939 161.006L32.24 160.944L32.2029 160.929L16.756 149.714ZM222.698 57.1067L222.243 56.2337L222.05 57.1987L219.774 68.5837L219.649 69.2067L220.241 68.9747L227.051 66.2997L227.41 66.1587L227.231 65.8167L222.698 57.1067ZM212.959 38.9087L212.481 38.9827L212.704 39.4117L217.446 48.5207L218.101 48.3647L218.209 38.5037L218.213 38.0947L217.809 38.1567L212.959 38.9087Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.9556 168.631L38.5246 169.161L45.1266 176.689L45.2026 176.776L45.2626 176.788L62.4866 186.55L68.7986 193.857L68.8936 193.967L69.0396 193.976L81.9186 194.795L82.0766 194.13L62.9196 185.99L56.4756 178.531L56.3996 178.443L56.3376 178.429L38.9556 168.631ZM219.987 68.3289L219.625 68.4708L219.808 68.8148L224.409 77.4778L219.941 89.7288L219.67 90.4728L220.401 90.1678L229.49 86.3748L229.842 86.2288L229.663 85.8928L225.151 77.3958L227.265 66.0408L227.379 65.4258L226.797 65.6538L219.987 68.3289ZM208.836 20.1838L208.231 19.0078L208.181 20.3289L207.825 29.6108L207.611 29.6288L207.81 30.0109L207.799 30.3088L207.959 30.2968L212.704 39.4119L213.358 39.2598L213.586 29.8428L213.793 29.8258L213.595 29.4418L213.602 29.1448L213.449 29.1578L208.836 20.1838ZM218.061 48.2008L217.617 47.3469L217.415 48.2888L214.993 59.5748L214.865 60.1718L215.443 59.9768L222.501 57.5958L222.885 57.4669L222.698 57.1068L218.061 48.2008ZM25.3866 153.131L25.3316 153.067L25.2526 153.036L16.6786 149.672L16.3486 150.275L31.8296 161.515L32.2936 161.006L25.3866 153.131Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.5 41.3403L205.416 40.6543L167.591 42.0403L167.351 42.0493L167.275 42.2773L164.9 49.3994L164.709 49.9733L165.301 49.8483L205.5 41.3403Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.2034 160.932L31.7734 161.463L38.5245 169.161L38.6025 169.25L38.6635 169.262L55.9915 179.03L62.4485 186.504L62.5444 186.616L62.6315 186.62L81.8055 194.768L82.2065 194.225L75.8555 186.711L75.7585 186.596L75.6685 186.591L56.4235 178.469L49.8315 170.838L49.7535 170.747L49.6904 170.735L32.2034 160.932ZM215.221 59.3188L214.834 59.4498L215.026 59.8109L219.808 68.8138L220.263 69.6729L220.454 68.7198L222.73 57.3348L222.848 56.7468L222.279 56.9388L215.221 59.3188ZM220.133 89.5269L219.778 89.6758L219.963 90.0139L224.686 98.6688L225.06 99.3538L225.318 98.6179L229.684 86.1699L229.942 85.4348L229.223 85.7349L220.133 89.5269ZM213.319 39.0908L212.885 38.2568L212.674 39.1738L210.1 50.3558L209.969 50.9268L210.533 50.7668L217.848 48.6948L218.258 48.5789L218.061 48.2008L213.319 39.0908Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.082 144.949L9.83301 145.591L24.963 153.665L25.028 153.7L25.101 153.705L48.627 155.41L48.7371 154.728L10.082 144.949ZM165.157 49.1691L164.994 49.2042L164.919 49.3531L161.102 56.9891L160.711 57.7701L161.531 57.4691L205.548 41.3261L205.357 40.6611L165.157 49.1691Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M220.42 68.4891L220.061 67.8131L219.789 68.5291L215.164 80.7202L214.937 80.8062L215.052 81.0151L214.835 81.5861L215.273 81.4211L219.962 90.0142L220.33 90.6871L220.593 89.9661L225.059 77.7202L225.279 77.6371L225.166 77.4261L225.373 76.8602L224.951 77.0192L220.42 68.4891ZM25.2952 153.056L24.8652 153.588L31.7722 161.463L31.8522 161.554L31.9142 161.565L49.3452 171.337L55.9512 178.984L56.0502 179.098L56.1372 179.102L75.3782 187.222L81.6762 194.673L81.7912 194.809L81.9692 194.794L96.3362 193.615L96.4092 192.938L75.7892 186.633L69.3562 179.021L69.2572 178.905L69.1672 178.901L49.7772 170.776L43.0302 162.966L42.9502 162.873L42.8862 162.862L25.2952 153.056ZM210.344 50.1002L209.93 50.2172L210.132 50.5961L215.026 59.8112L215.472 60.6501L215.671 59.7211L218.093 48.4341L218.214 47.8701L217.659 48.0272L210.344 50.1002Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M208.184 20.291L205.116 40.746L161.292 56.818L161.185 56.858L161.122 56.954L156.014 64.75L155.324 65.804L156.456 65.252L205.58 41.313L205.457 41.023L205.765 41.084L208.497 30.015L208.505 29.981L208.507 29.945L208.874 20.356L208.184 20.291ZM52.1254 120.023L51.9874 119.989L51.8643 120.061L9.82031 144.987L10.0313 145.631L111.363 135.477L111.412 134.795L52.1254 120.023Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M220.571 89.681L220.263 89.117L219.961 89.684L213.245 102.287L212.983 102.38L213.093 102.574L212.762 103.194L213.329 102.994L218.079 111.413L218.394 111.971L218.689 111.402L225.236 98.784L225.49 98.694L225.384 98.499L225.703 97.883L225.154 98.078L220.571 89.681ZM208.468 29.772L208.044 28.957L207.823 29.849L205.143 40.711L204.91 40.764L205.06 41.046L204.957 41.466L205.247 41.399L210.132 50.596L210.568 51.417L210.776 50.511L213.301 39.541L213.526 39.489L213.378 39.205L213.474 38.789L213.195 38.853L208.468 29.772ZM215.638 59.485L215.286 58.821L215.009 59.521L210.22 71.645L209.982 71.726L210.101 71.945L209.886 72.491L210.319 72.344L215.128 81.156L215.489 81.818L215.757 81.112L220.38 68.928L220.611 68.849L220.494 68.628L220.699 68.087L220.282 68.228L215.638 59.485ZM90.264 185.368L90.147 185.226L90.031 185.236L69.289 178.943L62.703 171.151L62.603 171.032L62.512 171.03L42.9771 162.904L36.069 154.908L35.9871 154.813L35.864 154.793L25.183 153.017L24.957 153.662L42.545 163.466L49.306 171.291L49.406 171.408L49.494 171.41L68.88 179.533L75.325 187.159L75.442 187.297L75.554 187.286L96.2061 193.601L97.3051 193.938L96.576 193.05L90.264 185.368Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M156.152 64.6284L156.083 64.6625L156.034 64.7234L149.745 72.5414L150.188 73.0594L205.601 41.3024L205.277 40.6895L156.152 64.6284ZM59.2581 120.597L59.2271 120.589L59.195 120.586L52.069 120.014L51.957 120.696L111.237 135.466L111.245 135.468L111.42 134.797L111.412 134.795L59.2581 120.597Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M90.0665 185.249L89.1016 185.047L89.7275 185.808L96.0396 193.49L96.1725 193.652L96.3785 193.609L111.54 190.42V189.741L90.0665 185.249ZM215.737 80.8226L215.433 80.2656L215.128 80.8226L208.174 93.5226L207.754 94.2897L208.586 94.0187L220.374 90.1766L220.771 90.0477L220.571 89.6807L215.737 80.8226Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M149.843 72.4572L149.8 72.4822L149.765 72.5192L142.428 80.2112L142.864 80.7442L205.613 41.2942L205.256 40.7002L149.843 72.4572Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M210.745 50.2711L210.398 49.6191L210.117 50.3011L205.096 62.5042L204.818 63.1791L205.516 62.9691L215.432 59.9802L215.836 59.8582L215.638 59.4852L210.745 50.2711ZM208.371 93.3602L207.968 93.4911L208.176 93.8602L213.186 102.74L213.497 103.291L213.794 102.732L220.573 90.0112L220.978 89.2512L220.16 89.5181L208.371 93.3602ZM83.8019 177.504L83.683 177.359L83.567 177.372L62.538 171.043L61.4189 170.706L62.174 171.599L68.826 179.469L68.944 179.61L69.0569 179.598L89.895 185.92L90.991 186.252L90.264 185.368L83.8019 177.504Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.1514 153.013L25.0693 153.701L35.7504 155.477L35.7794 155.482H35.8093L48.6544 155.411L48.6773 154.718L25.1514 153.013Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.939 154.814L35.5439 155.361L42.5049 163.419L42.608 163.538L42.764 163.539L55.624 163.663L55.7599 162.996L35.939 154.814Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M142.494 80.156L142.469 80.172L142.447 80.192L134.216 87.609L134.639 88.156L205.613 41.294L205.62 41.29L205.244 40.707L205.237 40.711L142.494 80.156ZM67.6002 120.041L67.5352 120.019L67.4661 120.024L59.1442 120.586L59.0762 121.267L111.237 135.466L111.439 134.803L67.6002 120.041Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.8918 163.093L55.7898 162.971L55.6308 162.97L42.7708 162.846L42.6338 163.513L62.3048 171.695L62.7028 171.151L55.8918 163.093ZM205.316 62.3044L204.907 62.4284L205.112 62.8034L210.179 72.0884L210.534 72.7384L210.806 72.0494L215.654 59.7754L215.918 59.1094L215.232 59.3164L205.316 62.3044ZM213.79 102.399L213.519 101.918L213.204 102.371L204.298 115.152L203.732 115.966L204.682 115.683L218.48 111.575L218.897 111.451L218.684 111.072L213.79 102.399Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M134.257 87.577L134.245 87.584L134.234 87.593L125.282 94.582L125.689 95.1429L205.62 41.29L205.622 41.2889L205.237 40.7109L205.235 40.713L134.257 87.577Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M125.301 94.5679H125.3L115.814 100.983L115.813 100.984L116.201 101.559L205.621 41.2879L205.428 41.0009L205.234 40.7129L205.229 40.7159L125.301 94.5679Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M105.504 182.018L105.37 181.848L105.231 181.879L83.6036 177.384L82.6406 177.184L83.2656 177.944L89.7276 185.808L89.8626 185.973L89.9976 185.943L111.398 190.421L112.338 190.617L111.74 189.865L105.504 182.018ZM210.788 71.7552L210.488 71.2062L210.181 71.7512L203.045 84.4252L202.624 85.1732L203.446 84.9272L215.532 81.3222L215.942 81.1992L215.737 80.8232L210.788 71.7552ZM205.735 40.8382L205.395 40.1992L205.11 40.8642L199.907 52.9892L199.627 53.6432L200.314 53.4622L210.527 50.7692L210.95 50.6572L210.745 50.2712L205.735 40.8382Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M76.834 118.309L76.731 118.26L76.6201 118.282L67.425 120.029L66.124 120.277L67.379 120.699L111.218 135.46L111.478 134.818L76.834 118.309Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M203.248 84.262L202.831 84.387L203.045 84.765L208.176 93.86L208.483 94.403L208.782 93.856L215.737 81.156L216.142 80.416L215.333 80.657L203.248 84.262ZM105.276 181.889L104.433 181.784L104.961 182.449L111.197 190.297L111.347 190.486L111.577 190.411L127.196 185.289L127.131 184.616L105.276 181.889ZM77.1843 169.45L77.0634 169.304L76.9464 169.317L55.7274 162.984L54.6113 162.651L55.3624 163.541L62.1733 171.599L62.2943 171.741L62.4073 171.728L83.4343 178.056L84.5253 178.385L83.8023 177.504L77.1843 169.45Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.9621 144.94L9.91211 145.622L48.5671 155.4L48.6631 155.424L48.7571 155.395L111.433 135.462L111.294 134.786L9.9621 144.94Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.9169 154.84L48.8118 154.716L48.6498 154.717L35.8049 154.788L35.6738 155.456L55.4949 163.637L55.8918 163.093L48.9169 154.84ZM200.137 52.7909L199.709 52.9039L199.921 53.2919L205.112 62.8029L205.46 63.4409L205.737 62.7689L210.759 50.5659L211.024 49.9199L210.35 50.0979L200.137 52.7909ZM208.78 93.5189L208.512 93.0429L208.195 93.4889L199.174 106.183L198.858 106.27L198.973 106.466L198.537 107.08L199.221 106.89L204.158 115.312L193.555 127.592L193.201 127.678L193.317 127.867L192.773 128.497L193.581 128.299L198.713 136.692L198.963 137.1L199.273 136.736L209.849 124.318L210.193 124.234L210.082 124.045L210.618 123.415L209.826 123.609L204.995 115.367L213.701 102.87L214.008 102.785L213.897 102.589L214.323 101.978L213.657 102.163L208.78 93.5189ZM99.1168 173.981L98.9808 173.809L98.7678 173.858L83.4559 177.386L83.4628 178.064L105.162 182.573L106.101 182.768L105.504 182.018L99.1168 173.981Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M86.5347 115.48L86.3937 115.371L86.2238 115.421L76.5847 118.29L75.7197 118.547L76.5347 118.935L111.179 135.445L111.541 134.857L86.5347 115.48Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M142.817 177.69L121.14 176.613L121.087 176.543L120.926 176.597L98.9023 173.854L77.1063 169.355L70.4053 161.2L70.2833 161.052L70.0923 161.077L55.5823 162.973L55.5283 163.649L76.7153 169.972L83.2664 177.944L83.4023 178.11L83.6113 178.062L98.7114 174.583L104.961 182.449L105.113 182.64L105.268 182.588L126.901 185.286L126.978 185.387L127.14 185.316L127.866 185.407L127.631 185.099L142.939 178.353L142.817 177.69ZM205.721 62.4697L205.425 61.9287L205.116 62.4617L197.862 74.9807L197.569 75.0597L197.691 75.2757L197.368 75.8327L197.921 75.6827L203.045 84.7647L203.347 85.3007L203.649 84.7647L210.715 72.2177L210.999 72.1407L210.88 71.9237L211.192 71.3697L210.657 71.5147L205.721 62.4697Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M96.5119 111.651L96.3569 111.413L96.0929 111.518L86.1949 115.431L85.6289 115.655L86.1099 116.028L111.116 135.406L111.62 134.943L96.5119 111.651Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.7507 154.731L47.6387 154.402L48.3867 155.288L55.3627 163.541L55.4847 163.685L55.6717 163.661L70.1816 161.764L70.2347 161.088L48.7507 154.731ZM180.779 139.871L180.139 140.485L181.059 140.309L186.389 148.641L186.618 148.999L186.922 148.703L199.176 136.832L199.566 136.757L199.45 136.566L200.085 135.951L199.18 136.124L194.041 127.721L193.816 127.353L193.505 127.652L181.058 139.602L180.657 139.679L180.779 139.871ZM203.649 84.4241L203.384 83.9541L203.066 84.3911L193.853 97.0951L193.528 97.1781L193.646 97.3801L193.214 97.9761L193.893 97.8011L199.086 106.659L199.358 107.122L199.668 106.685L208.688 93.9941L209.002 93.9121L208.889 93.7111L209.311 93.1171L208.65 93.2871L203.649 84.4241Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M106.524 106.913L106.442 106.463L106.03 106.664L96.0679 111.529L95.7158 111.7L95.9299 112.029L111.037 135.32L111.67 135.069L106.524 106.913ZM192.396 65.5718L193.002 65.9088L200.524 53.3038L200.536 53.2838L200.545 53.2628L205.747 41.1378L205.12 40.8418L192.396 65.5718Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M136.736 169.528L136.137 169.539L136.279 169.732L120.82 176.634L120.944 177.297L142.617 178.374L142.7 178.487L142.881 178.387L143.51 178.419L143.312 178.148L158.39 169.792L158.216 169.142L136.986 169.524L136.927 169.443L136.736 169.528ZM187.922 119.046L187.384 119.661L188.186 119.477L193.449 128.083L193.697 128.488L194.007 128.129L204.772 115.663L205.121 115.583L205.008 115.389L205.538 114.775L204.754 114.955L199.685 106.308L199.441 105.893L199.124 106.255L188.164 118.77L187.804 118.853L187.922 119.046ZM92.574 165.747L92.437 165.573L92.222 165.625L76.835 169.333L76.8459 170.01L98.776 174.536L99.713 174.729L99.118 173.98L92.574 165.747Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M116.352 101.319L116.449 100.615L115.834 100.972L106.008 106.676L105.798 106.798L105.841 107.038L110.987 135.194L111.672 135.179L116.352 101.319Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M114.961 168.535L114.809 168.337L114.573 168.418L98.7331 173.868L98.8031 174.54L120.919 177.295L121.74 177.397L121.237 176.74L114.961 168.535ZM167.279 150.771L166.73 150.846L167.038 151.307L172.355 159.243L152.37 160.84L151.765 160.888L152.113 161.385L157.938 169.688L158.134 169.967L158.416 169.776L172.878 160.024L172.895 160.05L173.179 159.821L174.261 159.091L174.062 159.107L186.899 148.724L187.867 147.941L186.634 148.111L167.279 150.771ZM70.2071 161.081L69.2471 160.884L69.8691 161.641L76.6481 169.89L76.7861 170.058L76.9971 170.007L92.3841 166.3L92.3731 165.623L70.2071 161.081ZM200.53 52.9598L200.239 52.4268L199.928 52.9478L192.406 65.5528L191.982 66.2628L192.786 66.0677L205.498 62.9728L205.937 62.8668L205.721 62.4698L200.53 52.9598Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M115.814 100.984L115.686 101.07L115.664 101.224L110.985 135.084L110.844 136.107L111.574 135.377L205.674 41.2459L205.235 40.7129L115.814 100.984Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M193.917 127.519L188.781 119.119L199.571 106.799L199.927 106.722L199.811 106.524L200.336 105.925L199.558 106.092L194.474 97.4205L203.552 84.9034L203.876 84.8264L203.759 84.6185L204.177 84.0424L203.521 84.1974L198.49 75.2805L205.716 62.8104L206.124 62.1074L205.334 62.2994L192.622 65.3945L192.177 65.5024L192.402 65.9014L197.678 75.2534L188.399 87.7825L188.066 87.8615L188.188 88.0684L187.759 88.6475L188.433 88.4874L193.634 97.3585L182.64 109.729L182.273 109.808L182.394 110.006L181.861 110.606L182.657 110.435L187.909 119.025L175.469 130.859L175.062 130.933L175.187 131.128L174.554 131.73L175.466 131.565L180.638 139.65L161.496 142.134L160.941 142.206L161.253 142.671L166.707 150.813L146.402 152.331L145.794 152.376L146.144 152.876L151.737 160.849L130.852 161.161L130.791 161.078L130.6 161.164L130 161.173L130.145 161.37L114.794 168.317L108.53 160.127L108.376 159.927L108.139 160.011L92.4207 165.553L85.8687 157.309L85.7297 157.133L85.5897 157.169L63.3807 152.652L63.3347 152.596L63.2007 152.616L62.3027 152.433L62.5317 152.712L48.6027 154.721L48.5537 155.397L69.9367 161.723L70.0087 161.811L70.1297 161.78L71.1227 162.074L71.0267 161.957L92.1097 166.277L98.5747 174.412L98.7267 174.604L98.9587 174.524L114.562 169.156L120.686 177.162L120.853 177.38L121.103 177.268L136.698 170.305L136.714 170.326L136.894 170.225L137.524 170.258L137.488 170.209L158.228 169.836L158.881 169.824L158.506 169.29L153.03 161.483L173 159.888L173.59 159.841L173.26 159.349L167.924 151.382L186.728 148.798L187.265 148.724L186.973 148.267L181.684 139.999L182.402 139.421L182.225 139.444L193.91 128.225L194.305 128.153L194.186 127.959L194.815 127.356L193.917 127.519Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.582 154.724L48.702 155.407L63.2401 153.311L63.26 153.308L63.2791 153.303L78.813 149.21L78.6541 148.535L48.582 154.724ZM171.06 91.0817L171.613 91.4987L192.97 65.9526L192.995 65.9236L193.013 65.8896L205.737 41.1586L205.141 40.8057L171.06 91.0817Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M188.525 118.701L183.254 110.083L194.242 97.7166L194.606 97.6446L194.487 97.4416L195.007 96.8566L194.234 97.0106L189.018 88.1116L198.292 75.5896L198.624 75.5176L198.503 75.3036L198.918 74.7436L198.267 74.8846L193.006 65.5606L192.747 65.0996L192.428 65.5216L182.809 78.2336L182.466 78.3086L182.591 78.5216L182.166 79.0826L182.836 78.9376L188.175 88.0456L176.977 100.461L176.603 100.536L176.727 100.738L176.199 101.324L176.988 101.166L182.381 109.985L169.74 121.897L169.327 121.968L169.455 122.166L168.829 122.756L169.733 122.602L175.044 130.905L155.568 133.279L155.007 133.348L155.322 133.817L160.919 142.173L140.285 143.607L139.672 143.65L140.025 144.153L145.767 152.337L124.563 152.588L124.502 152.504L124.312 152.591L123.71 152.598L123.858 152.799L108.361 159.907L101.936 151.508L101.782 151.306L101.543 151.392L85.7136 157.114L78.9955 148.659L78.8546 148.482L78.6356 148.54L63.1016 152.632L63.1215 153.308L85.4045 157.839L92.0305 166.178L92.1846 166.372L92.4175 166.29L108.132 160.749L114.41 168.957L114.578 169.176L114.829 169.062L130.564 161.942L130.579 161.963L130.76 161.862L131.39 161.895L131.355 161.847L152.402 161.533L153.057 161.523L152.681 160.987L147.063 152.977L167.352 151.46L167.946 151.416L167.615 150.921L162.141 142.75L181.269 140.268L181.811 140.197L181.517 139.737L176.093 131.258L176.801 130.69L176.632 130.711L188.514 119.407L188.915 119.339L188.794 119.141L189.415 118.55L188.525 118.701Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M205.183 40.7559L111.143 134.827L48.5469 154.733L48.7219 155.404L78.7938 149.215L78.8278 149.208L78.8588 149.194L111.463 135.451L111.385 135.238L111.533 135.412L171.552 91.5568L171.601 91.5208L171.634 91.4708L205.716 41.1948L205.183 40.7559Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M169.441 122.144L155.404 133.343L140.126 143.657L124.307 152.584L101.691 151.371L78.7668 148.53L77.9238 148.425L78.4518 149.09L85.3259 157.739L85.4808 157.934L85.7148 157.85L101.54 152.13L107.978 160.547L108.147 160.767L108.398 160.652L124.467 153.282L146.432 153.022L147.09 153.014L146.712 152.476L140.945 144.256L161.565 142.822L162.162 142.781L161.829 142.283L156.212 133.899L175.675 131.525L176.222 131.459L175.925 130.994L170.349 122.276L182.984 110.369L183.39 110.304L183.267 110.102L183.881 109.524L182.999 109.664L177.454 100.596L177.232 100.234L176.921 100.524L163.866 112.707L163.447 112.773L163.577 112.976L162.958 113.554L163.855 113.411L169.441 122.144Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M171.081 91.0538L171.603 91.5109L183.263 78.7798L183.274 78.7678L183.284 78.7548L192.981 65.9398L192.438 65.5078L171.081 91.0538Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M133.531 134.895L117.859 143.795L95.0775 142.542L95.0195 142.465L94.8466 142.529L94.1666 142.492L94.3386 142.717L78.6035 148.549L78.6805 149.219L101.474 152.044L101.554 152.15L101.717 152.075L102.439 152.164L102.393 152.105L124.207 153.275L124.292 153.39L124.472 153.289L125.102 153.323L124.9 153.047L140.206 144.41L140.221 144.431L140.416 144.299L140.972 144.294L140.796 144.043L155.515 134.106L155.531 134.13L155.741 133.963L156.233 133.931L156.09 133.718L156.864 133.196L156.688 133.207L170.04 122.555L170.494 122.503L170.362 122.298L171.061 121.74L170.077 121.852L164.317 112.847L164.11 112.523L163.809 112.762L149.39 124.211L148.923 124.264L149.047 124.45L133.918 134.668L133.395 134.702L133.531 134.895ZM170.915 91.2349L170.392 91.8058L171.176 91.6608L176.862 100.959L177.104 101.356L177.415 101.011L188.781 88.4099L189.152 88.3408L189.03 88.1339L189.545 87.5628L188.779 87.7048L183.307 78.3708L183.069 77.9648L182.752 78.3118L171.17 90.9558L170.788 91.0269L170.915 91.2349Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.5889 148.555L78.8448 149.201L95.0198 143.206L95.0339 143.201L95.0479 143.193L111.476 135.445L111.194 134.812L78.5889 148.555Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M157.536 103.532L143.153 114.896L142.681 114.946L142.822 115.157L142.133 115.701L143.117 115.597L148.902 124.233L127.77 125.474L127.7 125.374L127.496 125.49L126.956 125.522L127.095 125.719L111.236 134.784L110.647 134.787L110.8 134.996L94.752 142.565L94.881 143.225L117.759 144.483L117.844 144.6L118.024 144.498L118.655 144.533L118.452 144.255L133.931 135.465L133.946 135.485L134.141 135.354L134.699 135.35L134.537 135.12L135.36 134.653L135.177 134.654L149.433 125.026L149.449 125.049L149.733 124.823L150.77 124.123L150.603 124.133L163.94 113.543L163.957 113.57L164.177 113.365L164.62 113.319L164.486 113.11L165.176 112.562L165.02 112.579L177.315 101.105L177.727 101.044L177.601 100.838L178.208 100.271L177.334 100.401L171.643 91.0953L171.423 90.7344L171.112 91.0214L157.841 103.282L157.416 103.344L157.536 103.532Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M111.124 134.851L111.512 135.426L143.472 115.525L143.495 115.512L143.509 115.499L158.213 103.882L158.223 103.874L158.233 103.865L171.583 91.5311L171.426 91.3541L171.143 90.9961L111.124 134.851Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M143.576 115.038L143.383 114.749L143.094 114.944L127.402 125.545L126.355 126.253L127.617 126.179L149.547 124.891L150.152 124.855L149.815 124.351L143.576 115.038Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M111.145 134.838L111.501 135.434L127.77 126.135L127.781 126.128L127.792 126.12L143.483 115.52L143.106 114.938L111.145 134.838Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};

export default FeaturesTopRight;
