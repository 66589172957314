import React, { type FC } from 'react';

import Link from 'next/link';

import { type PropTypes, type Props } from '../../typescript';
interface Text {
  text: string;
  sameSite: boolean;
  link: string;
  className?: string;
}
const FootLink: FC<PropTypes<Props, Text>> = ({
  text,
  sameSite,
  link,
  ...props
}) => {
  return (
    <Link
      href={link}
      className="text-lg"
      target={!sameSite ? '_blank' : '_self'}
    >
      <span {...props}> {text}</span>
    </Link>
  );
};

export default FootLink;
