import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';
const ManThinking: FC<IconProps> = (props) => {
  return (
		<svg version="1.1" id="Capa_1" {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		viewBox="0 0 105.104 105.104">
		<g>
			<g>
				<g>
					<path d="M64.785,15.134c-0.22,0-0.41-0.127-0.422-0.283s-0.003-0.462,0.02-0.68c0,0,0.219-2.058,1.305-3.361
				c0.978-1.151,1.521-1.999,1.521-2.977c0-1.108-0.695-1.848-2.063-1.869c-0.782,0-1.651,0.261-2.195,0.674l-0.378-0.995
				c-0.078-0.206,0.022-0.447,0.223-0.536c0,0,1.59-0.707,2.741-0.707c2.499,0,3.629,1.543,3.629,3.193
				c0,1.478-0.825,2.543-1.868,3.781c-0.956,1.13-1.304,2.086-1.238,3.194l0.006,0.166c0.008,0.22-0.165,0.4-0.385,0.4H64.785z"/>
				</g>
				<g>
					<path d="M65.679,15.534h-0.894c-0.432,0-0.792-0.286-0.821-0.651c-0.014-0.183-0.004-0.514,0.021-0.754
				c0.01-0.089,0.246-2.194,1.396-3.574c0.905-1.066,1.428-1.852,1.428-2.721c0-1.192-0.908-1.457-1.669-1.469
				c-0.695,0-1.48,0.238-1.948,0.593l-0.426,0.323l-0.568-1.495c-0.152-0.401,0.043-0.87,0.435-1.044C62.701,4.71,64.314,4,65.537,4
				c2.783,0,4.028,1.805,4.028,3.593c0,1.583-0.85,2.719-1.962,4.039c-0.863,1.02-1.205,1.89-1.145,2.913l0.007,0.174
				c0.007,0.217-0.07,0.421-0.22,0.576C66.096,15.449,65.895,15.534,65.679,15.534z M64.758,14.719l0.907,0.015l-0.006-0.15
				c-0.072-1.238,0.326-2.278,1.333-3.468c1.038-1.233,1.773-2.208,1.773-3.523c0-1.351-0.848-2.793-3.229-2.793
				c-1.051,0-2.564,0.666-2.579,0.672l0.203,0.566c0.575-0.295,1.305-0.474,1.986-0.474c1.521,0.024,2.463,0.893,2.463,2.269
				c0,1.115-0.592,2.03-1.616,3.235c-0.994,1.193-1.21,3.125-1.212,3.145C64.765,14.368,64.755,14.572,64.758,14.719z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M63.907,18.175c0-0.804,0.543-1.368,1.304-1.368c0.76,0,1.282,0.564,1.282,1.368c0,0.761-0.5,1.348-1.305,1.348
				C64.428,19.522,63.907,18.936,63.907,18.175z"/>
				</g>
				<g>
					<path d="M65.188,19.922c-0.958,0-1.681-0.751-1.681-1.748c0-1.024,0.717-1.768,1.704-1.768c0.975,0,1.682,0.744,1.682,1.768
				C66.894,19.188,66.177,19.922,65.188,19.922z M65.211,17.207c-0.541,0-0.904,0.389-0.904,0.968c0,0.558,0.362,0.948,0.881,0.948
				c0.55,0,0.905-0.372,0.905-0.948C66.094,17.587,65.748,17.207,65.211,17.207z"/>
				</g>
			</g>
		</g>
		<g>
			<path d="M32.204,49.958l14.582-3.76c1.423-0.367,2.794-1.71,3.188-3.125l3.663-13.113c0.223-0.798,0.094-1.615-0.356-2.243
		c-0.449-0.629-1.182-1.016-2.011-1.063l-1.565-0.083c-1.512,0-2.981,1.079-3.418,2.509l-2.394,7.829
		c-0.219,0.715-1.08,1.526-1.808,1.703l-10.83,2.099c-1.507,0.366-2.721,1.866-2.764,3.414c0,0-0.137,4.736,0.329,5.216
		C29.266,49.799,29.884,50.292,32.204,49.958z"/>
			<path d="M41.908,34.769c0.443,0,0.878-0.035,1.308-0.087l1.829-5.982c0.597-1.955,2.601-3.429,4.661-3.429l1.634,0.084
		c0.444,0.025,0.86,0.139,1.256,0.292c0.09-0.56,0.149-1.13,0.149-1.715c0-5.975-4.861-10.836-10.836-10.836
		s-10.836,4.861-10.836,10.836S35.933,34.769,41.908,34.769z"/>
			<path d="M65.797,0c-5.973,0-10.833,5.366-10.833,11.961c0,2.964,0.987,5.675,2.611,7.767c-0.084,0.067-0.166,0.14-0.227,0.236
		l-2.228,3.499c-0.296,0.466-0.159,1.084,0.307,1.381C55.593,24.95,55.779,25,55.963,25c0.331,0,0.654-0.164,0.845-0.463
		l2.122-3.334c1.871,1.697,4.263,2.718,6.867,2.718c5.974,0,10.834-5.366,10.834-11.961S71.771,0,65.797,0z M65.797,21.922
		c-4.871,0-8.833-4.468-8.833-9.961S60.926,2,65.797,2c4.871,0,8.834,4.468,8.834,9.961S70.668,21.922,65.797,21.922z"/>
			<path d="M55.615,39.353c-0.603-0.84-1.662-1.502-2.743-1.82l-1.646,5.891c-0.518,1.855-2.248,3.552-4.115,4.034l-14.583,3.76
		c-0.693,0.107-1.193,0.15-1.671,0.15c-0.13,0-0.246-0.009-0.366-0.016v19.084v0.503v29.166c0,2.757,2.243,5,5,5h0.353
		c2.757,0,5-2.243,5-5V73.938h2.126v26.166c0,2.757,2.243,5,5,5h0.352c2.757,0,5-2.243,5-5V70.938v-0.503v-0.12h0.314
		c1.449,0,3.141-0.989,3.853-2.252l6.942-12.324c0.727-1.289,0.62-3.24-0.243-4.441L55.615,39.353z M56.669,55.539l-3.346,5.451
		V48.58l3.281,4.708C57.006,53.866,57.038,54.939,56.669,55.539z"/>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
		<g>
		</g>
	</svg>);
};
export default ManThinking;
