import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Interactions: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 116 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.8928 4.33781C33.8272 5.41283 30.4121 8.77515 29.1808 12.8694C28.6465 14.6764 28.693 18.1759 29.2738 20.0286C29.9475 22.2244 31.4808 24.4202 33.4323 25.9984C33.8505 26.3415 34.1757 26.6618 34.1293 26.6846C34.0828 26.7075 33.1535 27.165 32.0616 27.7139C28.1586 29.6581 25.1617 32.6545 23.2102 36.5886C22.0254 38.9674 21.1426 42.1696 21.1426 44.1595V45.1431H22.7456H24.3254L24.488 43.6563C25.3243 35.6051 32.0616 29.2921 40.3787 28.7432L42.5392 28.606L42.3999 27.6682C42.0514 25.4266 42.0978 25.4724 41.1686 25.4724C37.8696 25.4495 34.2687 23.1165 32.805 20.0286C32.2475 18.885 32.1778 18.4504 32.1778 16.3232C32.1778 14.196 32.2475 13.7843 32.805 12.5949C33.6879 10.7651 35.5232 8.93526 37.3817 8.02035C38.7989 7.33416 39.0777 7.28841 41.2383 7.28841C43.3988 7.28841 43.6776 7.33416 45.0715 8.02035C46.419 8.66079 49.021 10.8566 49.021 11.3369C49.021 11.4742 49.6715 11.1768 51.9947 9.98742C52.0411 9.96454 51.7623 9.48421 51.3674 8.93526C49.927 6.8767 47.1392 5.00112 44.4907 4.31493C42.7948 3.88035 39.5655 3.90322 37.8928 4.33781Z"
        fill="white"
      />
      <path
        d="M16.4401 0.337807C12.4498 1.41283 9.09791 4.77515 7.88941 8.8694C7.36497 10.6764 7.41057 14.1759 7.98062 16.0286C8.64187 18.2244 10.1468 20.4202 12.0621 21.9984C12.4726 22.3415 12.7918 22.6618 12.7462 22.6846C12.7006 22.7075 11.7885 23.165 10.7168 23.7139C6.88613 25.6581 3.94471 28.6545 2.02936 32.5886C0.866467 34.9674 0 38.1696 0 40.1595V41.1431H1.57332H3.12384L3.28345 39.6563C4.10432 31.6051 10.7168 25.2921 18.8799 24.7432L21.0004 24.606L20.8636 23.6682C20.5216 21.4266 20.5672 21.4724 19.6551 21.4724C16.4173 21.4495 12.883 19.1165 11.4465 16.0286C10.8992 14.885 10.8308 14.4504 10.8308 12.3232C10.8308 10.196 10.8992 9.78432 11.4465 8.59493C12.313 6.76509 14.1143 4.93526 15.9384 4.02035C17.3293 3.33416 17.603 3.28841 19.7235 3.28841C21.8441 3.28841 22.1177 3.33416 23.4858 4.02035C24.8083 4.66079 27.3621 6.85659 27.3621 7.33692C27.3621 7.47416 28.0006 7.17681 30.2808 5.98742C30.3264 5.96454 30.0527 5.48421 29.6651 4.93526C28.2514 2.8767 25.5152 1.00112 22.9158 0.314934C21.2512 -0.119648 18.0818 -0.0967789 16.4401 0.337807Z"
        fill="#FA9706"
      />
      <path
        d="M71.2931 4.33781C68.7939 5.00113 66.1811 6.80809 64.7498 8.84377L64 9.87305L65.4086 10.6507L66.8173 11.4284L68.0442 10.1018C68.8621 9.23261 69.7709 8.52355 70.7705 8.04322C72.1792 7.33416 72.4518 7.28842 74.5648 7.28842C76.5641 7.28842 76.9958 7.35703 78.1545 7.90598C81.8579 9.64433 84.1526 13.8072 83.5392 17.627C82.8803 21.6983 79.3587 25.0149 75.2918 25.4038L73.815 25.541L73.5651 26.9591C73.4288 27.7368 73.3152 28.423 73.3152 28.4916C73.3152 28.5602 74.2694 28.6746 75.4281 28.7432C83.5619 29.2921 90.2188 35.6737 90.9686 43.6563L91.1049 45.1431H92.6953H94.2857L94.1494 43.359C93.6041 36.5657 88.9465 29.9783 82.7667 27.2793C82.0851 26.982 81.4944 26.6846 81.4944 26.616C81.4944 26.5474 81.9261 26.2043 82.4259 25.8155C85.5158 23.5053 87.3107 18.7706 86.72 14.4934C86.0838 9.73582 82.3577 5.5272 77.7456 4.31494C76.087 3.88035 72.9289 3.90322 71.2931 4.33781Z"
        fill="white"
      />
      <path
        d="M92.5733 0.337811C90.0269 1.00113 87.3648 2.80809 85.9065 4.84377L85.1426 5.87305L86.5778 6.65073L88.013 7.42841L89.263 6.10178C90.0964 5.23261 91.0223 4.52355 92.0409 4.04322C93.4761 3.33416 93.7539 3.28842 95.9067 3.28842C97.9438 3.28842 98.3836 3.35703 99.5642 3.90598C103.337 5.64433 105.675 9.80719 105.05 13.627C104.379 17.6983 100.791 21.0149 96.6475 21.4038L95.1428 21.541L94.8882 22.9591C94.7493 23.7368 94.6335 24.423 94.6335 24.4916C94.6335 24.5602 95.6058 24.6746 96.7863 24.7432C105.074 25.2921 111.856 31.6737 112.62 39.6563L112.759 41.1431H114.379H116L115.861 39.359C115.305 32.5657 110.56 25.9783 104.263 23.2793C103.569 22.982 102.967 22.6846 102.967 22.616C102.967 22.5474 103.407 22.2043 103.916 21.8155C107.064 19.5053 108.893 14.7706 108.291 10.4934C107.643 5.73582 103.847 1.5272 99.1475 0.314938C97.4577 -0.119648 94.24 -0.0967751 92.5733 0.337811Z"
        fill="#FA9706"
      />
      <path
        d="M54.6272 12.912C52.6077 13.3897 50.6341 14.5268 48.9818 16.1871C46.5492 18.5752 45.31 21.5092 45.31 24.8298C45.31 28.2413 46.5722 31.1298 49.0736 33.5861L50.703 35.2009L49.4867 35.7468C43.0839 38.5443 38.563 44.8898 37.8745 52.0541L37.7139 53.7144H39.3203H40.9267L41.0644 52.6227C41.6611 47.71 43.6806 43.8663 47.1459 40.9778C50.6341 38.1121 55.4075 36.7248 59.676 37.3161C63.6462 37.8619 66.6984 39.3858 69.5211 42.206C72.2291 44.9125 73.6978 47.9147 74.2486 51.8039L74.524 53.7144H76.1304H77.7139L77.5532 52.0541C77.0483 47.1642 74.9829 42.8656 71.5636 39.5905C69.7276 37.8847 68.5113 37.0204 66.423 35.9742C65.5968 35.5648 64.9084 35.2009 64.8625 35.1782C64.8166 35.1554 65.4591 34.4959 66.2853 33.6998C70.2784 29.9016 71.2423 24.102 68.6949 19.0983C67.777 17.3016 65.4132 14.9362 63.5314 13.9583C60.9152 12.6164 57.5417 12.207 54.6272 12.912ZM59.8825 16.0507C62.6364 16.7785 64.8166 18.5752 66.0099 21.0998C68.9015 27.2406 63.9904 34.3139 57.1287 33.8818C50.68 33.4724 46.641 26.5583 49.5096 20.8496C50.6112 18.6207 52.4012 17.1196 54.9256 16.2554C56.6467 15.664 58.2302 15.5958 59.8825 16.0507Z"
        fill="#FA9706"
      />
    </svg>
  );
};
export default Interactions;
