import React, { type FC, useState } from 'react';

import Image from 'next/legacy/image';

import { close as closeIcon, success } from '../public';

import { Flutterwave } from './icons';
import FlutterWavePayment from './util/FlutterWavePayment';
interface IAppProps {
  close: () => void
  opened: boolean
  amount: number
  voteCount: boolean
  setSuccessToggle?: () => void
  email?: string
}

const SelectPaymentMethod: FC<IAppProps> = ({
  opened,
  close,
  amount,
  voteCount,
  setSuccessToggle,
  email
}) => {
  const [currency, setCurrency] = useState('');
  return (
    <div
      className={`${
        opened ? 'scale-100' : 'scale-0'
      } fixed inset-0 z-50 flex h-screen w-full origin-center flex-col items-center justify-center bg-black/70 transition-[transform] duration-500 ease-in`}
    >
      <div className=" z-40 flex h-auto w-10/12 flex-col items-center gap-8 rounded bg-white p-10 shadow-sm lg:w-2/5">
        <div className="float-right ml-auto cursor-pointer" onClick={close}>
          <Image src={closeIcon} width={400} height={400} alt="close" className="w-24 h-auto" />
        </div>
        <h1 className="text-3xl font-bold tracking-wider text-darkPurple">
          Choose Currency
        </h1>
        <span className="flex gap-2 font-bold">
          <input
            type="radio"
            className="accent-darkPurple"
            name="currency"
            value={'USD'}
            onChange={(e) => { setCurrency(e.target.value); }}
            id="USD"
          />{' '}
          <label htmlFor="USD">US Dollars</label>{' '}
        </span>
        <span className="flex gap-2 font-bold">
          <input
            type="radio"
            className="accent-darkPurple"
            name="currency"
            value={'MAD'}
            onChange={(e) => { setCurrency(e.target.value); }}
            id="MAD"
          />{' '}
          <label htmlFor="GHS">Ghana Cedis</label>{' '}
        </span>
        <span className="flex gap-2 font-bold">
          <input
            type="radio"
            className="accent-darkPurple"
            name="currency"
            value={'EGP'}
            onChange={(e) => { setCurrency(e.target.value); }}
            id="EGP"
          />{' '}
          <label htmlFor="NGN">Nigerian Naira</label>{' '}
          <label htmlFor="XOF">West African CFA franc</label>{' '}
        </span>

        {currency !== '' ? (
          <FlutterWavePayment
            {...{
              amount,
              currency,
              voteCount,
              text: 'Pay With FlutterWave',
              setSuccessToggle,
              email
            }}
          />
        ) : (
          <p className="mx-auto w-full cursor-pointer rounded bg-darkPurple/40 px-6 py-6 text-center font-bold text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple">
            {/* Celliot */}
            Pay
          </p>
        )}
        {/* <p className="hover:text-darkPurple w-full mx-auto bg-darkPurple px-6 rounded relative hover:bg-lightPurple text-lightPurple cursor-pointer transition-all duration-500 text-center py-6 ease-in font-bold group"><Flutterwave className="w-24 h-auto mx-auto" /></p> */}
      </div>
    </div>
  );
};

export default SelectPaymentMethod;
