import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const Group4: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="71"
      height="171"
      viewBox="0 0 71 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7642 46.1903L18.7581 46.1852L18.7517 46.1808L12.6963 41.8125L12.6923 41.81L12.6876 41.807L6.45516 37.7642L6.45101 37.7611L6.37343 37.8773L6.4417 37.7557L0.0482743 34.0654L0.0435462 34.0624L0.0382173 34.0596L-6.48907 30.764L-6.68385 31.1364L6.22187 38.115L29.9617 56.1281L40.3504 66.798L40.6553 66.5082L35.6077 61.0403L35.6039 61.0364L35.6002 61.0325L30.2481 55.8193L30.1494 55.9185L30.2407 55.812L24.6224 50.8639L24.6182 50.8607L24.6146 50.8574L18.7642 46.1903Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.94089 69.319L1.87697 68.8212L1.5666 69.2162L-1.16787 72.6945L-2.18883 66.3971L-2.266 65.9181L-2.56269 66.3018L-5.56371 70.1894L-5.69809 70.3639L-5.51842 70.4903L-1.4252 73.3703L2.49082 76.4996L6.20928 79.8657L6.60878 80.2273L6.55945 79.6907L5.90192 72.4909L5.85457 71.9708L5.52784 72.3785L2.77321 75.8205L1.94089 69.319Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.3419 78.4735L49.6675 78.2072L40.6676 66.523L40.6601 66.5133L40.6516 66.5044L30.2525 55.8232L29.9428 56.108L49.3419 78.4735Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.85673 72.6414L5.98861 72.4772L5.82382 72.3455L2.02707 69.3118L2.03474 69.3018L1.85887 69.1774L1.45757 68.857L1.46267 68.8979L-2.27494 66.2587L-2.68361 65.9697L-2.60353 66.4644L-1.50585 73.2352L-1.42913 73.7087L-1.13295 73.3314L1.58735 69.8709L2.41882 76.3657L2.48169 76.8581L2.79191 76.4707L5.85673 72.6414ZM9.66405 75.8909L9.63564 75.3459L9.29077 75.7688L6.18743 79.5764L6.0688 79.7219L6.19955 79.8566L9.68646 83.4401L10.0766 83.8408L10.0472 83.2828L9.66405 75.8909Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.9926 56.1501L30.2281 55.8025L6.46311 37.7699L6.44495 37.7565L6.42422 37.7471L-19.8694 25.5505L-20.0664 25.9217L29.9926 56.1501Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-6.7155 63.5096L-6.9618 63.357L-6.95431 63.3953L-10.859 61.3097L-10.9377 60.9563L-11.1036 61.1787L-11.3488 61.0479L-11.2692 61.4017L-14.1656 65.291L-14.3127 65.4879L-14.0954 65.6027L-9.8227 67.8545L-9.74515 68.202L-9.58667 67.9926L-5.58578 70.449L-5.51454 70.8149L-5.34571 70.596L-5.09924 70.7474L-5.17233 70.3713L-2.35241 66.7185L1.60592 69.514L5.4914 72.6185L6.14078 79.7288L6.18789 80.2419L6.51331 79.8423L9.27235 76.4573L9.62318 83.2222L9.5626 83.2951L9.63082 83.3755L9.65528 83.8423L9.83911 83.6206L12.8925 87.2112L13.2682 87.6534L13.2629 87.0726L13.1947 79.5765L13.2669 79.4899L13.1934 79.4149L13.189 78.9247L12.9709 79.1875L9.73585 75.889L9.74373 75.8795L9.65633 75.8007L9.02077 68.5959L8.97459 68.0735L8.64707 68.4828L5.84009 71.9893L5.02479 65.3974L4.96286 64.8958L4.6507 65.2933L1.86584 68.8361L0.865184 62.4428L0.789271 61.9596L0.490414 62.3466L-2.44658 66.152L-6.35614 63.732L-6.34724 63.7197L-6.47609 63.6505L-6.54657 63.2869L-6.7155 63.5096Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.3345 78.464L49.6718 78.213L45.3715 72.246L45.3674 72.2409L45.3631 72.2353L40.6635 66.5182L40.334 66.78L49.3345 78.464Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.97566 68.7461L9.10754 68.5819L8.94275 68.4502L4.94776 65.259L4.54401 64.9368L4.60744 65.4493L5.4846 72.5364L5.54571 73.0313L5.85709 72.6417L8.97566 68.7461Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.24758 38.1287L6.43631 37.7531L-6.48384 30.7669L-6.49542 30.7603L-6.50759 30.7559L-19.8817 25.5456L-20.0464 25.9329L6.24758 38.1287Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.68084 65.9355L4.85059 65.7195L8.60922 68.7225L9.23708 75.835L9.17235 75.9148L9.25127 75.9951L9.28989 76.4359L9.46889 76.2171L12.7742 79.5883L12.8418 86.9989L12.785 87.0655L12.8433 87.1445L12.8473 87.6437L13.0409 87.4146L15.7434 91.1042L15.724 91.6322L15.9431 91.3768L16.1439 91.651L16.1638 91.1202L19.3609 87.3977L19.4705 87.2703L19.3701 87.1351L16.5146 83.3007L16.5225 83.2913L16.4597 83.218L16.4792 82.6905L16.4519 82.7232L16.3997 76.1589L16.4219 76.1812L16.3994 75.7144L16.4692 75.6305L16.3951 75.5557L16.3913 75.0653L16.3694 75.0911L16.0351 68.0747L16.0094 67.5263L15.6621 67.9518L12.7947 71.4702L12.7772 71.4915L12.2008 64.7161L12.2799 64.6172L12.1861 64.5422L12.1495 64.1077L11.9513 64.3547L8.24812 61.3974L8.25541 61.3882L8.15178 61.3143L8.10243 60.9022L7.91947 61.135L7.68389 60.9472L7.6885 60.9845L3.95847 58.3309L3.89864 57.9322L3.72115 58.162L3.48212 57.9918L3.54281 58.3934L0.614152 62.1867L0.372286 62.0155L0.378221 62.053L-3.32997 59.7393L-3.32089 59.7276L-3.4484 59.6587L-3.51727 59.2901L-3.68777 59.5159L-3.9312 59.3642L-3.92428 59.4008L-7.85168 57.2746L-7.92883 56.9163L-8.09685 57.1419L-8.33893 57.0108L-8.2627 57.3643L-11.1106 61.1884L-15.3277 59.2953L-15.4122 58.9601L-15.5758 59.1838L-15.8172 59.0753L-15.735 59.4009L-18.6142 63.3351L-18.7683 63.5463L-18.5289 63.6522L-14.176 65.5679L-14.0927 65.897L-13.9282 65.6764L-13.6875 65.7825L-13.7687 65.4622L-10.9826 61.7209L-6.84872 63.9289L-6.77281 64.2813L-6.61158 64.0686L-2.58569 66.5604L-2.51631 66.9307L-2.34568 66.7093L-2.10186 66.8603L-2.1717 66.4844L0.525095 62.9912L1.52448 69.3785L1.59919 69.8557L1.89774 69.4759L4.68084 65.9355Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.1111 56.1802L30.8029 56.148L30.2102 55.7899L-19.8494 25.5617L-20.0891 25.9061L18.3694 56.674L18.4311 56.7234L18.51 56.7198L30.1111 56.1802Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-0.348178 55.6953L-0.433355 55.2232L-0.722923 55.6054L-3.80383 59.669L-3.943 59.8526L-3.74754 59.9748L0.546191 62.6539L0.948148 62.9049L0.864597 62.438L-0.348178 55.6953ZM15.9882 68.2177L16.1151 68.062L15.9656 67.9282L12.1248 64.4934L11.7299 64.1405L11.7752 64.6682L12.4026 72.0451L12.4464 72.5637L12.7751 72.1604L15.9882 68.2177ZM19.6565 79.5797L19.7731 79.4419L19.6546 79.3059L16.3446 75.5049L15.9706 75.0762L15.9751 75.6445L16.0359 83.2947L16.0403 83.8624L16.4071 83.4283L19.6565 79.5797Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.1989 56.1566L30.0915 55.76L18.4905 56.2997L17.9734 56.3239L18.3608 56.6671L22.2845 60.1459L22.3932 60.2423L22.5216 60.1751L30.1989 56.1566ZM-6.66091 31.1478L-6.50128 30.7583L-13.1417 27.9037L-13.1482 27.9013L-13.1554 27.8984L-19.8883 25.5432L-20.0342 25.9379L-6.66091 31.1478Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.93068 58.5791L4.07194 58.3961L3.87611 58.2728L-0.443846 55.5542L-0.84727 55.3005L-0.763029 55.7697L0.449933 62.513L0.533864 62.9791L0.823688 62.6038L3.93068 58.5791ZM19.3622 87.8294L19.3893 87.8654L19.4113 87.2743L19.468 86.6272L19.434 86.667L19.706 79.4519L19.7291 78.842L19.3348 79.3082L16.0856 83.1573L15.9779 83.2848L16.0772 83.4185L18.9318 87.2519L15.7971 90.9015L15.6969 91.0184L15.7775 91.149L18.3255 95.2835L18.6586 95.8239L18.7143 95.1919L19.3622 87.8294ZM-15.4374 59.7068L-11.1292 61.6422L-10.7374 61.8176L-10.8385 61.4006L-12.3125 55.3107L-12.4191 54.8701L-12.687 55.236L-15.5835 59.194L-19.9562 57.6153L-20.3405 57.4763L-20.2301 57.87L-18.6469 63.5163L-18.5301 63.9329L-18.2746 63.5841L-15.4374 59.7068Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.2884 56.0668L30.5941 55.4751L30.0043 55.7842L22.3273 59.8019L22.0717 59.936L22.2769 60.1383L25.9878 63.8008L26.1912 64.0017L26.3225 63.7475L30.2884 56.0668Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.8248 57.756L-19.9429 57.3354L-20.1983 57.6904L-23.1241 61.7566L-23.288 61.9854L-23.0229 62.0783L-18.514 63.6578L-18.1327 63.7914L-18.2414 63.4028L-19.8248 57.756ZM19.6531 71.6289L19.6496 71.1377L19.4288 71.4039L16.0286 67.991L15.4231 60.599L15.3797 60.0714L15.0496 60.4846L12.1356 64.1247L11.3559 57.3452L11.2972 56.8375L10.9813 57.2393L8.09126 60.9159L7.13383 54.3243L7.06305 53.8332L6.75965 54.226L3.59748 58.3225L3.46391 58.4954L3.64181 58.6224L7.82684 61.5995L11.8485 64.811L15.619 68.1831L15.9711 75.5713L15.9093 75.6464L15.9785 75.7259L16.001 76.1946L16.189 75.9678L19.3369 79.5824L19.7099 80.0101L19.7063 79.4428L19.6542 71.7918L19.7275 71.7029L19.6531 71.6289ZM-10.8743 61.5754L-7.97698 57.6852L-3.82262 59.9345L-3.74878 60.2926L-3.57824 60.0668L-3.33917 60.1964L-3.41093 59.8471L-0.388329 55.8594L-0.240879 55.6656L-0.454702 55.5479L-4.7913 53.168L-4.86639 52.8038L-5.03716 53.0329L-5.27601 52.902L-5.20301 53.2553L-8.10426 57.1512L-12.4296 55.1691L-12.8238 54.9881L-12.7213 55.4097L-11.2479 61.4992L-11.1423 61.935L-10.8743 61.5754Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.3116 55.9793L29.9141 55.8736L25.9482 63.5543L25.8811 63.6838L25.9789 63.7919L29.4414 67.6218L29.7851 68.002L29.8074 67.4896L30.3116 55.9793Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.3492 60.4551L14.9611 60.1096L14.9642 60.148L11.4413 57.3353L11.4485 57.3261L11.2738 57.2013L10.8795 56.8869L10.8837 56.923L7.22614 54.3104L7.23324 54.3005L7.04348 54.1804L6.64659 53.8967L6.65183 53.9319L2.77154 51.4692L2.70438 51.0835L2.5293 51.3152L2.29145 51.1649L2.35665 51.5422L-0.723128 55.6055L-0.86189 55.7883L-0.667576 55.9108L3.57249 58.5787L3.63822 58.9577L3.81396 58.7304L4.05187 58.8806L3.98696 58.5064L6.78914 54.8762L7.7454 61.4618L7.81627 61.9472L8.11879 61.5616L10.9962 57.9017L11.775 64.6746L11.8331 65.176L12.1483 64.7821L15.0472 61.1606L15.6083 68.0176L15.5422 68.0992L15.6217 68.1792L15.6579 68.6227L15.8405 68.3989L19.2338 71.8038L19.2848 79.3675L19.2264 79.4371L19.2825 79.5112L18.9939 87.1798L18.9401 87.2416L18.9853 87.3133L18.295 95.1545L18.2384 95.7977L18.6627 95.3114L21.7774 91.7448L21.9601 92.0342L22.009 91.4804L22.0665 91.4142L22.021 91.3413L22.6656 83.9786L22.6916 84.0131L22.7111 83.4977L22.7742 83.4247L22.7204 83.3538L22.7703 82.7832L22.7367 82.8222L23.0108 75.6077L23.0808 75.5243L23.0167 75.4509L23.0366 74.9245L22.7999 75.2052L19.6499 71.6326L19.3094 64.143L19.3857 64.0494L19.3015 63.9746L19.2804 63.5124L19.0713 63.7692L15.5028 60.5915L15.5097 60.583L15.3492 60.4551Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.3084 55.9347L29.891 55.961L29.3874 67.471L29.3841 67.5502L29.4331 67.6118L32.6077 71.5922L32.9794 71.4251L30.3084 55.9347Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-12.4487 55.6227L-8.2188 57.5614L-8.13984 57.9033L-7.97002 57.6752L-7.73589 57.7828L-7.81065 57.4605L-4.91871 53.5777L-0.74356 55.8694L-0.671315 56.2324L-0.498456 56.004L-0.26187 56.1337L-0.331279 55.7838L2.63039 51.8779L6.80801 54.529L10.9506 57.4882L11.0069 57.888L11.1812 57.6661L15.0139 60.7254L15.0603 61.1442L15.239 60.9203L18.889 64.1705L19.2291 71.6443L19.1663 71.7204L19.2361 71.7997L19.2574 72.2687L19.4478 72.0393L22.5894 75.6032L22.2934 83.403L21.6009 91.3065L18.3457 95.0343L18.253 95.1406L18.3159 95.2671L20.3934 99.4266L20.689 100.018L20.7894 99.3645L22.0034 91.4856L25.3228 87.6845L25.4273 87.565L25.3415 87.4322L22.7654 83.4341L26.0245 79.6391L26.136 79.5097L26.0316 79.3739L23.0731 75.5328L26.3305 71.6754L26.4484 71.5358L26.3267 71.3996L23.0368 67.7142L23.0437 67.7057L22.969 67.6312L22.9663 67.1404L22.9455 67.1653L22.6377 60.0587L22.7144 59.9643L22.6304 59.8901L22.6101 59.4264L22.3996 59.685L18.7898 56.4845L18.7963 56.4768L18.7032 56.4022L18.6682 55.9628L18.4744 56.2053L18.2497 56.0059L18.2528 56.0424L14.6916 53.2003L14.6983 53.1913L14.5956 53.1184L14.5494 52.7002L14.3624 52.9381L14.1323 52.7537L14.136 52.7881L10.3398 50.0668L10.2834 49.6607L10.1017 49.8956L9.86807 49.7281L9.92327 50.1264L7.05408 53.8434L5.9791 47.3519L5.89893 46.8694L5.60345 47.2591L2.52212 51.3235L-1.67649 48.9869L-1.74937 48.6177L-1.92231 48.85L-2.15809 48.7188L-2.08798 49.073L-5.04401 53.0418L-9.29231 51.0542L-9.37293 50.7071L-9.542 50.9375L-9.77667 50.8282L-9.70104 51.1553L-12.5931 55.1074L-16.8743 53.5143L-16.9611 53.1901L-17.1266 53.4199L-17.3577 53.3343L-17.2788 53.6311L-20.1134 57.5718L-24.411 56.4226L-24.5022 56.1251L-24.6651 56.3549L-24.8904 56.2941L-24.8094 56.5586L-27.6689 60.599L-27.8455 60.8483L-27.5487 60.9243L-23.12 62.0543L-23.0298 62.3464L-22.8661 62.1189L-22.6419 62.1762L-22.7216 61.9178L-19.9494 58.0645L-15.6817 59.6059L-15.5966 59.9243L-15.4299 59.6966L-15.1995 59.7799L-15.2776 59.4879L-12.4487 55.6227Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.2699 53.4027L10.022 50.3563L10.0165 50.3529L10.012 50.3486L-20.0894 25.9061L-20.0903 25.9051L-19.8319 25.584L14.2699 53.4027ZM14.2699 53.4027L-19.8319 25.584L14.2699 53.4027ZM14.2699 53.4027L18.3691 56.6739L18.3693 56.6745L18.6323 56.3457L18.6315 56.3453L-19.8219 25.5815L-19.8253 25.5788L14.2699 53.4027ZM-19.8319 25.584L-19.8262 25.5778L-19.8319 25.584ZM30.2601 55.8327L30.0888 55.9621L29.8942 56.0061L32.5652 71.4966L32.5731 71.5424L32.5989 71.5807L35.4421 75.6984L35.8179 75.5219L30.6717 57.222L59.7648 101.513L60.1324 101.31L49.6921 78.2489L49.6796 78.221L49.6598 78.198L30.2601 55.8327Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.3108 50.3134L10.4519 50.1318L10.2578 50.0079L5.88499 47.2092L5.48693 46.9544L5.56453 47.4206L6.71844 54.3887L6.79737 54.8653L7.09272 54.4832L10.3108 50.3134ZM24.0985 95.6842L24.194 95.5764L24.1283 95.4489L21.9923 91.2974L21.6963 90.7212L21.5973 91.3611L20.3743 99.3006L20.2654 100.006L20.7395 99.4722L24.0985 95.6842ZM26.0283 80.0585L26.0537 80.0912L26.0754 79.5154L26.1317 78.8691L26.0987 78.908L26.3801 71.5477L26.4037 70.937L26.0096 71.4038L22.6426 75.3911L22.5332 75.521L22.6365 75.6553L25.5945 79.4947L22.3441 83.2788L22.2421 83.3982L22.3269 83.5299L24.9878 87.6599L25.3212 88.1767L25.3748 87.5643L26.0283 80.0585ZM-9.40594 51.4657L-5.06643 53.4955L-4.67995 53.6764L-4.77198 53.2598L-6.17045 46.9228L-6.27113 46.4683L-6.54559 46.8438L-9.54868 50.9474L-13.84 49.3015L-13.9246 48.9702L-14.0931 49.2043L-14.321 49.1169L-14.2452 49.416L-17.2195 53.5506L-17.3806 53.7741L-17.1227 53.8704L-12.6935 55.5186L-12.6105 55.8446L-12.4412 55.6129L-12.2143 55.6973L-12.2889 55.4042L-9.40594 51.4657Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.825 25.5786L-20.0932 25.9027L-20.0906 25.9051L5.63627 47.5475L5.64663 47.5564L5.65838 47.5635L10.0312 50.3622L10.2771 50.0215L-19.8224 25.581L-19.825 25.5786Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3451 63.5625L26.3428 63.0709L26.1198 63.3403L22.572 59.8387L22.1908 59.4624L22.2143 59.9975L22.5455 67.6475L22.4818 67.7245L22.5527 67.8034L22.5731 68.2734L22.7654 68.0421L26.0132 71.6797L26.3826 72.0939L26.3803 71.5389L26.346 63.7267L26.4204 63.6369L26.3451 63.5625ZM-1.69444 49.2494L-1.54178 49.0438L-1.77281 48.934L-6.28533 46.7789L-6.67403 46.593L-6.5813 47.0138L-5.18281 53.3507L-5.08377 53.8001L-4.80865 53.4307L-1.69444 49.2494ZM25.3731 87.5783L25.4822 86.8629L25.0064 87.4078L21.6467 91.2551L21.5526 91.3631L21.6181 91.49L23.7539 95.6409L24.0509 96.218L24.1487 95.5766L25.3731 87.5783Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.0351 90.3361L56.4058 90.137L53.41 84.2636L53.4058 84.2567L53.4016 84.2491L49.6799 78.2248L49.3177 78.439L56.0351 90.3361Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-16.8784 53.7965L-16.7029 53.5521L-16.9922 53.4712L-21.436 52.2258L-21.5255 51.9195L-21.6914 52.154L-21.9134 52.0916L-21.8357 52.3577L-24.7489 56.4746L-24.9239 56.7208L-24.6322 56.799L-20.1959 57.9858L-20.1088 58.2864L-19.9416 58.054L-19.7208 58.1124L-19.7965 57.8527L-16.8784 53.7965ZM1.24904 44.5921L1.01634 44.4606L1.08337 44.8151L-2.03222 48.9981L-2.1756 49.1906L-1.9658 49.3077L2.39174 51.7326L2.46118 52.101L2.63682 51.8692L2.87015 51.9986L2.80396 51.6489L5.93947 47.5138L6.08498 47.3218L5.8754 47.2034L1.49558 44.7314L1.42472 44.3565L1.24904 44.5921Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.8223 25.581L-20.0984 25.898L1.1665 45.0229L1.18344 45.0386L1.20335 45.0495L5.66853 47.5693L5.90706 47.2252L-19.8223 25.581Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.1458 95.5934L23.7838 95.4056L20.4246 99.193L20.3404 99.2878L20.3852 99.4072L21.9102 103.438L22.1539 104.083L22.3116 103.412L24.1458 95.5934ZM29.4474 75.6549L29.5597 75.5241L29.4534 75.3882L26.3353 71.4099L25.9816 70.9585L25.9597 71.5318L25.6556 79.4892L24.9555 87.5277L24.8993 88.1702L25.3232 87.6847L28.7438 83.7676L28.8492 83.6472L28.7612 83.5132L26.1286 79.5193L29.4474 75.6549ZM-24.406 56.7173L-24.2117 56.4435L-24.5432 56.3884L-28.9521 55.6575L-29.2869 55.6021L-29.1872 55.9268L-27.6974 60.7822L-27.572 61.1916L-27.3249 60.8423L-24.406 56.7173Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.3007 55.9038L29.8983 56.027L35.4132 75.6355L35.42 75.6614L35.4335 75.6845L37.8207 79.794L38.2023 79.622L30.3007 55.9038Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.811 67.4026L29.8312 66.8774L29.5922 67.1607L26.2914 63.5098L25.922 63.102L25.9246 63.6518L25.9593 71.4632L25.899 71.5347L25.9601 71.6129L25.9623 72.112L26.1635 71.8729L29.0155 75.5121L25.706 79.3654L25.6024 79.4859L25.6899 79.6181L28.4096 83.745L28.7426 84.2505L28.7952 83.6474L29.4512 76.0677L29.4756 76.0995L29.4978 75.5312L29.5537 74.8856L29.521 74.9237L29.8048 67.5607L29.8768 67.4755L29.811 67.4026ZM-28.7856 55.8031L-28.912 55.3902L-29.1588 55.7445L-32.0773 59.9303L-32.2705 60.2076L-31.9359 60.2585L-27.5282 60.9283L-27.1975 60.9785L-27.2956 60.6591L-28.7856 55.8031ZM27.5183 91.8284L27.6145 91.7191L27.5465 91.5903L25.3511 87.4478L25.0533 86.8863L24.9571 87.5144L23.7328 95.5134L23.6249 96.2182L24.0986 95.6848L27.5183 91.8284Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.8173 25.585L-20.1076 25.8893L-3.3712 42.8056L-3.34599 42.8315L-3.31391 42.8469L1.21459 45.0554L1.44759 44.7099L-19.8173 25.585Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-6.3091 47.2335L-2.04797 49.2686L-1.97308 49.6238L-1.79795 49.3884L-1.56976 49.4972L-1.63801 49.1735L1.47563 44.9924L1.62707 44.7891L1.39936 44.6776L-3.03702 42.5141L-3.11294 42.1534L-3.28776 42.392L-3.51607 42.2807L-3.4468 42.6098L-6.44989 46.7133L-10.7512 45.0127L-10.8333 44.6731L-11.0052 44.9126L-11.2298 44.8236L-11.1575 45.1238L-14.1012 49.2159L-18.4078 47.952L-18.4944 47.6366L-18.6643 47.8768L-18.8824 47.8125L-18.8086 48.0804L-21.6999 52.1657L-25.9776 51.3952L-26.3105 51.3355L-26.217 51.6608L-24.7795 56.6544L-24.659 57.0748L-24.4062 56.7175L-21.5196 52.6393L-17.2207 53.844L-17.1353 54.1534L-16.9645 53.9162L-16.7476 53.9764L-16.8194 53.7148L-13.9414 49.7136L-9.65274 51.3591L-9.57204 51.6922L-9.39902 51.4561L-9.17555 51.5416L-9.24669 51.2475L-6.3091 47.2335ZM28.7935 83.6607L28.9019 82.9469L28.4269 83.4908L25.0064 87.4079L24.9113 87.5168L24.9791 87.645L27.1066 91.6587L24.0107 95.149L23.8897 94.8417L23.7484 95.4452L23.6975 95.5023L23.7209 95.5611L21.9018 103.316L22.2628 103.504L25.4531 99.9562L25.5759 100.267L25.7163 99.6629L25.7673 99.6064L25.7436 99.5465L27.4381 92.2835L27.4728 92.3502L27.5684 91.7203L28.7935 83.6607ZM32.9322 71.5979L33.0456 71.4662L32.9371 71.3297L29.7619 67.3495L29.4089 66.9066L29.3873 67.4723L29.0777 75.5102L29.0541 76.1126L29.4472 75.655L32.9322 71.5979Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.2272 79.779L32.3337 79.6576L32.243 79.5232L29.4624 75.4007L29.1299 74.907L29.0785 75.4997L28.3764 83.6113L28.3206 84.253L28.7444 83.7675L32.2272 79.779Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.2996 55.9001L29.9013 56.0367L29.903 56.0399L37.8035 79.7548L37.8069 79.7657L37.8122 79.7768L39.6494 83.7271L40.039 83.5685L30.3007 55.9036L30.2996 55.9001Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-25.812 51.5444L-25.9344 51.1201L-26.1871 51.4819L-29.1585 55.7445L-29.3492 56.0178L-29.0203 56.0725L-24.6114 56.8034L-24.2826 56.8581L-24.3753 56.5376L-25.812 51.5444ZM31.0009 87.9017L31.0991 87.7906L31.0283 87.6608L28.7705 83.5281L28.4716 82.9815L28.3779 83.5975L27.1529 91.657L27.0459 92.3609L27.5186 91.8284L31.0009 87.9017ZM32.9826 71.4787L33.0384 70.8288L32.613 71.3237L29.1288 75.3812L29.0237 75.5027L29.1138 75.6354L31.8946 79.7586L32.2277 80.252L32.2783 79.6589L32.9826 71.4787Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.7111 99.6694L29.1614 95.832L29.2496 95.7335L29.1995 95.6112L27.5556 91.6085L27.3055 91.0003L27.1559 91.6406L25.3316 99.4622L21.9507 103.223L21.8755 103.306L21.9029 103.414L22.871 107.29L23.0506 108.009L23.2751 107.303L25.7111 99.6694ZM-3.0518 42.7821L-2.89258 42.5649L-3.14201 42.4634L-7.60516 40.6448L-7.68459 40.2973L-7.85971 40.541L-8.08097 40.4504L-8.0119 40.7522L-11.0125 44.9229L-15.3225 43.5991L-15.4068 43.2748L-15.5808 43.52L-15.795 43.4539L-15.7252 43.7237L-18.7506 47.9986L-18.9208 48.2396L-18.6384 48.3226L-14.1907 49.6277L-14.1078 49.9455L-13.9331 49.7026L-13.7199 49.7652L-13.7884 49.502L-10.8549 45.4239L-6.55638 47.1234L-6.47833 47.4644L-6.30174 47.2234L-6.08156 47.3106L-6.14925 47.0153L-3.0518 42.7821ZM-28.8138 55.9853L-28.5969 55.6739L-28.9755 55.6551L-32.9227 55.4532L-33.0016 55.1608L-33.1931 55.4394L-33.3648 55.4306L-33.3147 55.6163L-36.1823 59.7838L-36.3992 60.0996L-36.0164 60.1133L-32.0668 60.2554L-31.99 60.5414L-31.7971 60.2649L-31.6269 60.2711L-31.6754 60.0905L-28.8138 55.9853ZM32.2766 79.672L32.3841 78.9596L31.91 79.5021L28.4272 83.4906L28.3307 83.6012L28.4012 83.7298L30.659 87.8624L30.9585 88.4108L31.0516 87.7931L32.2766 79.672Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.0482 87.8093L30.6858 87.6221L27.2035 91.5489L27.1166 91.647L27.1663 91.7681L28.8102 95.7709L29.0607 96.3808L29.2098 95.7387L31.0482 87.8093Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.8015 101.555L60.1167 101.281L30.2773 55.8547L29.9031 56.0403L39.6424 83.7086L39.6603 83.7602L39.7014 83.7964L59.8015 101.555Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-18.4076 48.2422L-18.2195 47.9767L-18.5388 47.914L-22.9482 47.0549L-23.2789 46.99L-23.1915 47.316L-21.8084 52.4516L-21.6925 52.8836L-21.4339 52.5181L-18.4076 48.2422ZM35.7742 75.7173L35.882 75.5942L35.7887 75.4594L32.9455 71.3417L32.6128 70.8601L32.5628 71.4429L31.8591 79.6229L31.8037 80.2638L32.2272 79.7791L35.7742 75.7173Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.7492 101.484L60.1404 101.331L56.418 90.1663L56.4113 90.1468L56.4015 90.1296L49.6841 78.2325L49.3094 78.4227L59.7492 101.484Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-22.7851 47.206L-22.9029 46.77L-23.1611 47.1406L-26.1193 51.3845L-29.9428 51.1289L-30.0179 50.8239L-30.2152 51.1107L-30.3832 51.0994L-30.3368 51.2876L-33.26 55.5359L-33.4734 55.8461L-33.0971 55.8657L-29.1531 56.0673L-29.0794 56.3658L-28.8808 56.0811L-28.7143 56.0896L-28.7591 55.9067L-25.9194 51.8336L-21.643 52.6039L-21.3162 52.6623L-21.4025 52.3417L-22.7851 47.206ZM26.4465 103.846L26.5326 104.17L26.7371 103.526L26.7836 103.476L26.7695 103.424L29.1913 95.798L32.7057 91.8886L32.7953 91.789L32.743 91.6656L31.089 87.8019L34.3516 84.1225L34.5075 84.3993L34.5886 83.8552L34.6471 83.79L34.6087 83.7219L35.8238 75.6096L35.9305 74.8987L35.4574 75.4402L32.1056 79.2781L31.9513 79.0047L31.8695 79.5484L31.8119 79.6145L31.8498 79.6809L30.6361 87.7222L28.8118 95.5908L25.6272 99.1328L25.5424 98.813L25.3374 99.4553L25.2917 99.5064L25.3049 99.5569L22.8745 107.176L23.2304 107.381L26.4465 103.846ZM-7.61543 40.9211L-7.44642 40.6863L-7.72163 40.5978L-12.1809 39.1655L-12.2631 38.8316L-12.4405 39.0823L-12.6515 39.0145L-12.5847 39.2866L-15.6689 43.644L-15.837 43.8817L-15.559 43.9667L-11.1059 45.3343L-11.0259 45.6613L-10.8476 45.4134L-10.6377 45.4777L-10.7031 45.2122L-7.61543 40.9211Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.4081 75.5479L34.1915 83.6695L30.6862 87.6224L30.5979 87.7223L30.6498 87.8446L32.3037 91.7077L29.1035 95.2672L29.0163 94.9603L28.818 95.5846L28.7707 95.6368L28.7854 95.6882L26.3646 103.312L22.9193 107.098L22.8555 107.168L22.8657 107.262L23.2697 110.95L23.6735 111.007L26.7315 103.533L29.9926 99.9492L30.081 100.26L30.2785 99.6349L30.3264 99.5825L30.3115 99.5306L32.5797 92.3523L32.6101 92.4235L32.7426 91.8488L36.3175 87.8724L36.4089 87.7702L36.3531 87.646L34.6375 83.8008L38.1607 79.8277L38.262 79.7143L38.1855 79.5825L35.7977 75.4731L35.4969 74.955L35.4081 75.5479ZM-15.3248 43.8872L-15.14 43.6258L-15.4532 43.56L-19.7325 42.6598L-19.813 42.3393L-19.9976 42.6038L-20.1905 42.5634L-20.1319 42.7967L-23.0911 47.0415L-26.9055 46.7234L-26.9777 46.4054L-27.1805 46.7009L-27.3454 46.6874L-27.3022 46.8773L-30.2825 51.2093L-30.4921 51.5139L-30.1237 51.5385L-26.1847 51.802L-26.1145 52.1136L-25.9097 51.82L-25.7469 51.831L-25.789 51.6466L-22.8939 47.494L-18.7501 48.3017L-18.6715 48.6158L-18.4857 48.3535L-18.2944 48.3906L-18.3512 48.1631L-15.3248 43.8872ZM-32.9127 55.7749L-32.6617 55.4098L-33.1032 55.4461L-36.6824 55.7395L-36.7467 55.4387L-36.9668 55.7625L-37.1055 55.7738L-37.074 55.9203L-39.956 60.1558L-40.2089 60.5272L-39.7614 60.4833L-36.1751 60.1311L-36.1122 60.4254L-35.891 60.1032L-35.7536 60.0896L-35.7843 59.948L-32.9127 55.7749Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.8597 107.559L26.9033 107.906L27.1924 107.197L27.2326 107.153L27.2271 107.111L30.2865 99.607L29.9357 99.3858L26.7021 102.94L26.6594 102.598L26.3697 103.305L26.3295 103.349L26.3348 103.391L23.2836 110.847L23.6329 111.07L26.8597 107.559ZM38.2078 79.7356L37.8456 79.549L34.2325 83.6231L34.1426 83.7241L34.1976 83.848L35.9689 87.8169L36.2251 88.392L36.3659 87.7785L38.2078 79.7356ZM-12.1861 39.4512L-12.0036 39.1944L-12.3111 39.1248L-16.7172 38.1269L-17.0451 38.053L-16.9687 38.3804L-15.7019 43.8138L-15.5958 44.2693L-15.3253 43.8873L-12.1861 39.4512Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.7416 101.463L60.1441 101.342L58.6718 95.8707L58.6688 95.8589L58.664 95.8477L56.4141 90.1553L56.0187 90.2992L59.7416 101.463Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.6045 95.9786L33.6948 96.277L33.8857 95.6695L33.9349 95.6155L33.9193 95.5632L36.3477 87.8377L39.9973 83.779L40.0903 83.6758L40.0313 83.5493L38.1935 79.5992L37.935 79.0432L37.7981 79.6408L35.9678 87.6313L32.6431 91.3293L32.5541 91.0349L32.3628 91.6412L32.3135 91.6953L32.3296 91.7468L29.8935 99.4556L26.8316 106.968L23.3233 110.784L23.2721 110.84L23.2685 110.916L23.0953 114.378L23.4933 114.483L27.1861 107.203L30.7668 103.307L30.8349 103.233L30.8206 103.134L30.3136 99.5956L33.6045 95.9786ZM-33.6558 51.5329L-33.7505 51.0339L-34.0359 51.4542L-36.9786 55.7784L-40.291 56.5846L-40.4746 56.6296L-40.45 56.8169L-39.9904 60.3013L-39.9189 60.8481L-39.6084 60.3921L-36.7325 56.1655L-33.0692 55.865L-32.8363 55.8459L-32.8798 55.6162L-33.6558 51.5329ZM-19.7326 42.9585L-19.5273 42.6648L-19.884 42.6289L-23.8117 42.2343L-23.8803 41.9031L-24.0895 42.2069L-24.2509 42.1904L-24.2107 42.3831L-27.2501 46.8009L-27.4557 47.0998L-27.0945 47.1298L-23.1621 47.4576L-23.0955 47.7823L-22.885 47.4807L-22.7253 47.4939L-22.7637 47.307L-19.7326 42.9585Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-40.0083 56.9494L-37.0337 56.2253L-36.9982 56.556L-36.7214 56.1492L-36.6215 56.1251L-36.6324 56.0186L-33.7462 51.7761L-30.2862 51.5509L-30.2313 51.8768L-29.9942 51.5319L-29.864 51.5238L-29.8884 51.3777L-27.2081 47.4824L-26.9036 47.0398L-26.6635 46.6907L-23.884 42.6504L-20.0828 43.0323L-20.0195 43.3708L-19.8029 43.0602L-19.6469 43.0759L-19.6822 42.8868L-16.5912 38.453L-16.3906 38.1645L-16.7388 38.1235L-20.6586 37.6603L-20.7235 37.3147L-20.9387 37.6274L-21.0964 37.6084L-21.0599 37.8035L-24.0991 42.2218L-27.7201 42.3256L-27.9562 42.3324L-27.9222 42.5663L-27.3182 46.7215L-30.6406 46.8786L-30.6966 46.5454L-30.9323 46.8924L-31.0638 46.8984L-31.0387 47.0487L-33.9895 51.3856L-37.0531 52.077L-37.0892 51.7383L-37.3647 52.1472L-37.4663 52.1699L-37.4543 52.2799L-40.1055 56.2118L-40.4159 56.6722L-40.7199 57.1224L-43.363 61.0435L-43.6744 61.5048L-43.1361 61.3648L-39.7294 60.4778L-39.5497 60.4308L-39.5739 60.2466L-40.0083 56.9494ZM33.512 95.4553L33.4751 95.496L29.9356 99.3858L29.8689 99.4593L29.8828 99.5572L30.3898 103.096L26.857 106.94L26.8044 106.998L26.8034 107.029L23.1175 114.293L23.4595 114.531L27.049 110.657L27.1023 110.6L27.1032 110.568L30.4983 103.856L30.5139 103.965L30.7929 103.279L34.1429 99.6339L34.1941 99.9495L34.4623 99.2863L34.5055 99.2392L34.4985 99.1963L37.3522 92.1401L37.3774 92.2193L37.5616 91.6291L37.6124 91.5739L41.2772 87.5455L41.3611 87.4539L41.3207 87.3363L40.0396 83.5707L39.8321 82.9607L39.6534 83.532L36.2493 87.3181L36.1592 87.0362L35.9738 87.6248L35.9237 87.6804L35.9398 87.7319L33.512 95.4553Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-27.5057 42.5059L-27.5835 41.9699L-27.8876 42.4176L-30.9909 46.9791L-31.2282 47.3272L-30.8075 47.3076L-27.0669 47.131L-26.8353 47.12L-26.8686 46.8903L-27.5057 42.5059ZM27.074 110.624L26.7405 110.371L23.1511 114.245L23.1142 114.286L23.101 114.339L22.3374 117.528L22.721 117.687L27.074 110.624Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.8153 101.566L60.0803 101.239L39.9802 83.4811L39.3865 82.9563L39.6419 83.7064L40.9229 87.4714L40.9441 87.5334L40.9964 87.573L59.8153 101.566Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-40.067 56.9072L-39.653 56.2939L-37.1093 52.5205L-34.0516 51.8309L-34.0229 52.1827L-33.7345 51.7597L-33.6372 51.7377L-33.6461 51.6293L-30.6435 47.216L-30.351 46.7859L-30.86 46.8914L-33.9846 47.5376L-34.0139 47.1777L-34.3009 47.6029L-34.3993 47.6234L-34.3899 47.7354L-37.3772 52.1655L-40.2763 53.4206L-40.4011 53.4747L-40.4027 53.6104L-40.4497 56.6527L-43.012 57.8081L-43.0155 57.4497L-43.3603 57.9647L-43.4304 57.9966L-43.4301 58.069L-46.3621 62.4463L-46.7823 63.0743L-46.098 62.7542L-43.3961 61.49L-43.3923 61.8394L-43.0467 61.3267L-42.9768 61.2942L-42.9775 61.2245L-40.4625 57.4938L-40.067 56.9072ZM26.3818 113.748L30.7218 106.692L30.7622 106.649L30.7631 106.617L34.4564 99.293L37.8904 95.556L37.9447 95.8573L38.2027 95.2163L38.2472 95.1675L38.2395 95.1242L41.317 87.4827L40.9664 87.2621L37.5065 91.065L37.4531 90.7684L37.1941 91.4085L37.1496 91.4573L37.1576 91.4993L34.1013 99.0579L30.4573 103.022L30.4032 103.081L30.4029 103.113L26.7206 110.392L26.702 110.412L26.6977 110.433L22.3628 117.466L22.6957 117.72L26.338 113.811L26.377 113.769L26.3818 113.748ZM-20.6635 37.9704L-20.4344 37.6368L-20.8391 37.6407L-24.364 37.6734L-24.4099 37.3053L-24.6618 37.6757L-24.7876 37.6774L-24.7686 37.8328L-27.8875 42.4176L-28.1191 42.7576L-27.7079 42.746L-24.172 42.6445L-24.1269 43.0039L-23.8735 42.6362L-23.749 42.6324L-23.7678 42.482L-20.6635 37.9704Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.8358 101.579L60.0665 101.228L41.2475 87.2352L40.8074 86.9084L40.9154 87.4458L41.6288 90.9948L41.6476 91.088L41.7303 91.1353L59.8358 101.579Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.0966 109.761L34.4518 102.667L34.4937 102.622L38.2405 98.5772L38.298 98.5149L38.2964 98.4303L38.2343 95.1825L41.99 91.0952L42.0628 91.0167L42.0415 90.9115L41.3281 87.3626L41.1898 86.6726L40.9272 87.3253L37.8412 94.9884L34.1271 99.0297L34.0716 99.0902L34.0713 99.122L30.3793 106.444L30.3597 106.465L30.3555 106.486L26.0163 113.54L22.3883 117.433L22.3665 117.457L22.3527 117.486L20.9814 120.35L21.3401 120.566L26.3469 113.802L30.0526 109.826L30.0926 109.783L30.0966 109.761ZM-39.9819 53.617L-39.9713 52.9055L-40.3674 53.4967L-43.3729 57.9846L-45.8597 59.8222L-45.9273 59.8725L-45.9343 59.9115L-48.9044 64.3642L-48.6025 64.6489L-46.0603 62.7319L-45.9939 62.682L-45.9873 62.6437L-43.0776 58.2993L-40.1542 56.9814L-40.0327 56.9265L-40.0304 56.7931L-39.9819 53.617ZM-27.5393 42.6544L-27.2551 42.2364L-27.7521 42.3293L-30.8559 42.9099L-30.8786 42.5283L-31.1768 42.9703L-31.2729 42.9882L-31.2661 43.1022L-34.3131 47.6221L-37.1867 48.8121L-37.3111 48.8635L-37.3169 48.998L-37.4471 52.3259L-37.4758 53.0652L-37.0626 52.4515L-34.048 47.9805L-31.0124 47.3524L-30.9903 47.726L-30.6907 47.286L-30.5963 47.2669L-30.6027 47.1564L-27.5393 42.6544Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.863 101.593L60.046 101.215L41.9402 90.7709L41.6098 90.5802L41.6248 90.9617L41.7541 94.2559L41.7597 94.3926L41.8866 94.4433L59.863 101.593Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.0229 91.0472L41.68 90.8102L37.8668 94.9598L37.8099 95.0219L37.8113 95.106L37.8753 98.4377L38.2736 98.5278L42.0229 91.0472ZM-36.8968 49.0145L-36.8677 48.2705L-37.282 48.889L-40.3465 53.4651L-42.8044 55.2423L-42.872 55.2906L-42.888 55.3718L-43.4254 58.0908L-43.0447 58.2488L-40.0523 53.7821L-37.1538 52.5272L-37.0324 52.4742L-37.0271 52.3423L-36.8968 49.0145ZM-24.3765 38.0035L-24.0995 37.5973L-24.5845 37.6776L-27.6665 38.1912L-27.6821 37.7857L-27.9914 38.2452L-28.085 38.2609L-28.0806 38.3771L-31.2264 43.0432L-31.5076 43.4603L-31.0131 43.3675L-27.915 42.7873L-27.9004 43.1836L-27.5892 42.7261L-27.4967 42.7088L-27.501 42.597L-24.3765 38.0035ZM25.016 116.636L25.0389 116.612L25.0438 116.601L30.0672 109.807L29.7445 109.539L26.0302 113.524L26.0078 113.549L26.0024 113.56L21.0014 120.316L21.3239 120.585L25.016 116.636Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.1599 111.451L60.5805 111.452L60.8027 106.602L60.8035 106.584L60.8011 106.566L60.1494 101.371L59.7304 101.406L60.1599 111.451Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.84 105.762L33.8808 105.717L33.8848 105.696L38.2544 98.5613L42.1188 94.3907L42.1782 94.3265L42.1747 94.2393L42.0452 90.9445L41.647 90.8587L37.9116 98.3128L34.1286 102.396L34.0882 102.44L34.0842 102.461L29.7189 109.572L30.0523 109.826L33.84 105.762ZM-42.475 55.4538L-42.8562 55.296L-45.9104 59.8746L-45.61 60.1604L-43.0937 58.3009L-43.0281 58.2526L-43.0123 58.1727L-42.475 55.4538ZM-30.8774 43.2785L-30.4988 42.7168L-31.1292 42.9649L-34.0361 44.1112L-34.1606 44.1601L-34.1693 44.2936L-34.3846 47.7788L-34.4319 48.55L-33.9998 47.9094L-30.8774 43.2785Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.919 25.9486L-19.9345 25.5329L-43.838 22.9141L-43.9136 22.9058L-43.9774 22.9479L-58.8144 32.787L-58.6601 33.169L-19.919 25.9486ZM59.8946 101.603L60.0189 101.201L42.042 94.052L41.7978 93.9552L41.7565 94.2145L41.2818 97.2106L41.2513 97.4049L41.4434 97.4487L59.8946 101.603Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.7835 112.608L28.8049 112.584L28.8103 112.573L33.8477 105.753L37.7023 101.616L37.7446 101.57L37.7487 101.549L42.1438 94.3575L41.8098 94.1044L37.9312 98.2908L37.8894 98.3362L37.8855 98.3584L33.5172 105.49L29.7444 109.539L29.7211 109.564L29.7157 109.575L24.7007 116.358L21.0167 120.298L21.0114 120.303L21.0063 120.309L19.0107 122.791L19.2119 122.96L19.3286 123.066L23.065 119.074L23.0709 119.068L23.0726 119.066L28.7835 112.608ZM-44.8375 57.6408L-45.9133 59.8762L-47.8962 62.2316L-47.9139 62.2523L-47.9193 62.2635L-50.9364 66.7938L-50.6005 67.045L-48.5682 64.6158L-48.551 64.5952L-48.546 64.5848L-45.5605 60.1082L-42.5275 55.5616L-40.0695 53.7844L-40.0048 53.737L-39.9962 53.6974L-36.9323 49.1234L-37.2285 48.8344L-39.6872 50.5706L-39.7538 50.6179L-39.7633 50.659L-42.8499 55.2864L-44.8084 57.5956L-44.8255 57.6161L-44.8375 57.6408ZM-27.6935 38.556L-27.3266 38.012L-27.9413 38.2412L-30.5116 39.1997L-30.4717 38.7211L-30.8854 39.3394L-30.9469 39.3623L-30.9535 39.4412L-34.1345 44.1901L-34.5092 44.7499L-33.8824 44.5023L-31.2898 43.481L-31.3284 43.9471L-30.9142 43.3325L-30.8531 43.3084L-30.8468 43.2328L-27.6935 38.556Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9245 101.606L59.9872 101.191L41.5362 97.0378L41.3634 96.9988L41.2954 97.1622L40.1952 99.8072L40.0836 100.076L40.3732 100.098L59.9245 101.606Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.6231 108.501L32.6474 108.475L32.6528 108.464L37.7106 101.607L41.6433 97.3864L41.6872 97.3398L41.6974 97.2763L42.1721 94.2802L41.7848 94.1376L37.38 101.344L33.5316 105.475L33.5078 105.5L33.5024 105.512L28.4588 112.341L28.7818 112.61L32.6231 108.501ZM-44.4581 57.8227L-44.823 57.6154L-47.9105 62.2501L-47.5742 62.5025L-45.574 60.1271L-45.5569 60.1065L-45.5459 60.0828L-44.4581 57.8227ZM-33.7846 44.4237L-34.0787 44.1334L-36.5065 45.8034L-36.5735 45.8497L-36.5833 45.8914L-39.741 50.6255L-39.4448 50.9144L-36.9855 49.1781L-36.9205 49.1319L-36.9109 49.0914L-33.7846 44.4237Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.8774 115.001L26.8831 114.995L26.8849 114.992L32.6273 108.497L32.3158 108.214L28.4745 112.322L28.4688 112.328L28.4671 112.331L22.7542 118.791L23.0652 119.074L26.8774 115.001ZM-39.3909 50.8589L-39.7257 50.6054L-41.6572 52.8648L-41.6743 52.8854L-41.686 52.9087L-42.8697 55.3199L-42.5061 55.5295L-39.3909 50.8589Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.84 119.72L57.2429 119.831L60.5659 111.521L60.5827 111.479L60.5807 111.434L60.1512 101.388L59.7329 101.365L56.84 119.72Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.5426 104.31L36.5678 104.283L36.5727 104.272L41.659 97.3675L41.3357 97.0996L37.3948 101.329L37.3696 101.356L37.3647 101.367L32.3001 108.233L32.623 108.501L36.5426 104.31ZM-41.3083 53.0942L-41.6725 52.8848L-44.823 57.6153L-44.4873 57.8679L-42.521 55.5487L-42.5038 55.5287L-42.4926 55.5056L-41.3083 53.0942ZM-30.5738 39.6299L-30.8654 39.3376L-33.2605 40.939L-33.3278 40.984L-33.3474 41.0619L-34.163 44.2548L-33.7847 44.4236L-30.5738 39.6299Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9483 101.607L59.9574 101.187L40.4057 99.6785L40.2936 99.6695L40.2241 99.7585L38.4744 101.996L38.1961 102.351L38.6474 102.336L59.9483 101.607Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-32.9396 41.1657L-33.3185 40.9971L-36.5624 45.8601L-36.2677 46.1502L-33.8399 44.4802L-33.7751 44.4353L-33.7551 44.3586L-32.9396 41.1657ZM30.7684 110.844L30.7741 110.837L30.7759 110.835L36.5471 104.305L36.2356 104.022L32.3162 108.214L32.3105 108.221L32.3086 108.222L26.5666 114.718L26.8776 115.001L30.7684 110.844Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9628 101.606L59.9334 101.186L38.6322 101.914L38.5676 101.917L38.5159 101.955L36.0931 103.719L35.4597 104.18L36.2391 104.098L59.9628 101.606Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.7389 106.601L34.745 106.594L34.7468 106.592L40.5467 100.028L40.2352 99.7448L36.2352 104.023L36.229 104.029L36.2272 104.032L30.4564 110.561L30.7679 110.844L34.7389 106.601ZM-32.9689 41.2306L-33.3026 40.9762L-35.1576 43.1077L-35.1743 43.1275L-35.1863 43.1503L-36.5752 45.8818L-36.2128 46.0937L-32.9689 41.2306Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-43.7446 23.2984L-43.9331 22.9251L-52.8334 26.1621L-52.8829 26.1807L-52.9178 26.2195L-58.8551 32.8218L-58.5822 33.1377L-43.7446 23.2984ZM56.8524 119.674L57.2312 119.855L59.3957 115.965L59.4083 115.942L59.4155 115.917L60.574 111.496L60.1749 111.364L56.8524 119.674ZM59.9697 101.605L59.9187 101.188L36.1956 103.68L36.1671 103.683L36.1406 103.694L33.0239 104.915L33.1298 105.319L59.9697 101.605Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.7931 102.269L38.7993 102.262L38.8052 102.255L40.5551 100.018L40.2314 99.749L34.4278 106.317L34.7387 106.6L38.7931 102.269Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9707 101.605L59.9124 101.189L59.9116 101.188L33.0723 104.902L33.0687 104.902L29.2432 105.505L29.2396 105.505L29.3017 105.921L29.3054 105.921L59.9701 101.605L59.9707 101.605ZM-35.0821 43.4388L-34.9404 43.5002L-34.8394 43.3837L-32.9844 41.2522L-32.7743 41.011L-33.0796 40.9134L-58.6341 32.7615L-58.7816 33.1553L-35.0821 43.4388Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.9292 139.778L36.6745 139.447L32.1964 142.13L32.1681 142.078L31.9876 142.255L31.8355 142.346L31.8561 142.384L27.8767 146.291L28.133 146.621L32.6686 143.866L32.6962 143.916L32.8769 143.739L33.0287 143.647L33.0084 143.61L36.9292 139.778ZM-67.8024 48.4548L-68.172 48.2546L-71.1405 53.1744L-71.1514 53.1924L-71.1543 53.2015L-75.9234 63.0729L-75.5536 63.2736L-72.5888 58.3811L-72.5779 58.3631L-72.5744 58.3537L-67.8024 48.4548Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.05 135.751L40.7962 135.419L36.1923 138.135L36.0411 138.224L36.1018 138.389L36.585 139.7L36.6976 140.005L36.9299 139.778L41.05 135.751Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7283 127.23L36.9598 127.566L57.1221 119.949L57.2364 119.906L57.2553 119.785L60.1489 101.429L60.2604 100.719L59.7835 101.257L36.7283 127.23Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-69.5598 53.3819L-69.5483 53.3637L-69.5414 53.3432L-67.7936 48.4339L-68.1812 48.2718L-72.9538 58.1709L-75.9137 63.055L-75.9369 63.0926L-75.9422 63.1369L-76.7244 69.0644L-76.3172 69.1607L-72.5771 58.3612L-69.5598 53.3819ZM36.4966 138.243L36.3839 137.938L36.1517 138.166L31.9694 142.272L32.2253 142.603L36.8902 139.808L37.0403 139.719L36.9798 139.555L36.4966 138.243ZM45.2586 131.638L45.006 131.306L40.4669 133.938L40.3336 134.015L40.367 134.166L40.6977 135.646L40.78 136.015L41.0499 135.751L45.2586 131.638Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-61.6738 38.2976L-62.0435 38.0975L-65.1406 43.2306L-65.1519 43.2493L-65.1553 43.2587L-69.9294 53.1818L-69.5603 53.3821L-66.4643 48.2741L-66.453 48.2553L-66.4497 48.2454L-61.6738 38.2976ZM32.2636 142.573L32.0512 142.223L26.2256 144.116L25.9331 144.21L26.1272 144.449L27.8606 146.574L28.0064 146.753L28.1709 146.591L32.2636 142.573ZM40.7775 134.074L40.695 133.705L40.4244 133.97L36.1506 138.166L36.4054 138.497L41.0092 135.782L41.1408 135.705L41.1076 135.555L40.7775 134.074Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-69.5409 53.3412L-69.9194 53.1636L-72.949 58.1626L-72.9726 58.2011L-72.975 58.224L-76.7152 69.0229L-76.3369 69.202L-73.3027 64.2466L-73.2793 64.2075L-73.2763 64.1844L-69.5409 53.3412ZM26.4538 144.183L26.3071 144.003L26.1427 144.166L22.0068 148.269L22.2219 148.617L28.0906 146.64L28.3788 146.544L28.1868 146.308L26.4538 144.183ZM49.5576 127.436L49.3066 127.103L44.837 129.647L44.7178 129.716L44.7316 129.852L44.902 131.509L44.9467 131.943L45.2584 131.638L49.5576 127.436ZM-58.5089 33.0527L-58.8789 32.8534L-62.0431 38.0975L-62.0548 38.117L-62.0621 38.1384L-63.6791 42.8761L-63.2905 43.0353L-58.5089 33.0527Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-63.3001 43.0533L-63.2884 43.0338L-63.2812 43.0119L-61.6642 38.2742L-62.0527 38.1155L-66.8336 48.0737L-66.464 48.2738L-63.3001 43.0533ZM36.4462 138.466L36.2362 138.115L30.4557 139.921L30.1825 140.006L30.3458 140.241L31.944 142.542L32.0863 142.747L32.2639 142.572L36.4462 138.466Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.2814 125.276L49.5098 125.628L53.9063 123.176L53.9317 123.161L53.9527 123.141L57.1958 119.901L56.9218 119.584L49.2814 125.276Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M45.1642 129.945L49.2011 127.647L49.2018 127.784L49.539 127.455L49.6215 127.408L49.6218 127.374L53.9517 123.142L53.7018 122.808L49.6175 125.087L49.6175 124.944L49.2808 125.274L49.1976 125.321L49.1971 125.356L45.106 129.374L44.8319 129.643L39.2066 131.244L38.9644 131.313L39.0753 131.539L40.2747 133.989L34.9401 135.584L34.8861 135.492L34.7314 135.646L34.5251 135.708L34.578 135.798L30.5485 139.795L30.3709 139.972L26.1943 144.115L19.3453 144.923L19.2696 144.836L19.1633 144.944L18.8367 144.982L18.9727 145.136L14.9512 149.191L14.5184 149.628L15.1281 149.548L22.0714 148.641L22.1455 148.724L22.2532 148.617L22.5749 148.575L22.4444 148.427L26.4035 144.5L32.1817 142.622L32.4511 142.535L32.2899 142.302L30.8552 140.237L36.2021 138.567L36.2536 138.655L36.4098 138.502L36.6143 138.438L36.5631 138.351L40.5119 134.474L40.7012 134.288L45.2169 131.67L45.3348 131.601L45.3206 131.466L45.1642 129.945ZM-69.9437 53.2034L-69.946 53.2263L-73.6735 64.046L-73.6975 64.0852L-76.6954 68.9817L-76.7303 69.0389L-76.7261 69.1056L-76.2721 75.8879L-76.0882 75.9013L-75.8837 75.985L-72.7689 70.9824L-72.7324 70.924L-72.7355 70.8856L-70.183 59.1507L-70.177 59.1413L-67.0309 54.0025L-67.0057 53.961L-67.0035 53.9374L-63.2881 43.0338L-60.0651 37.7155L-60.053 37.6952L-60.0458 37.6733L-58.499 33.0286L-58.8883 32.8714L-63.6654 42.8437L-66.8238 48.0556L-66.8484 48.0969L-69.9192 53.1634L-69.9437 53.2034Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-60.0462 37.6739L-60.4249 37.4975L-63.6599 42.8348L-63.6855 42.8771L-63.6871 42.9004L-67.4094 53.8246L-67.0309 54.0023L-63.7884 48.7058L-63.7621 48.6634L-63.7601 48.6392L-60.0462 37.6739ZM30.6671 140.271L31.0908 139.85L30.4971 139.912L23.4937 140.644L23.1104 140.684L23.3514 140.985L26.1268 144.447L26.273 144.63L26.4389 144.464L30.6671 140.271ZM39.4529 131.354L39.324 131.09L39.1157 131.297L34.694 135.683L34.9023 136.035L40.6327 134.322L40.8703 134.25L40.7617 134.027L39.4529 131.354Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.8114 127.173L36.9177 127.578L49.4395 125.653L49.4916 125.645L49.5333 125.613L57.1732 119.922L56.9732 119.556L36.8114 127.173Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-67.0052 53.9374L-67.3897 53.7832L-70.5609 58.9621L-70.5976 59.0218L-70.5937 59.0588L-73.1461 70.7917L-76.2415 75.7629L-76.2881 75.8377L-76.267 75.9239L-74.4225 83.5302L-74.0089 83.502L-72.7439 70.9416L-69.5843 65.8666L-69.547 65.8067L-69.55 65.7683L-67.0052 53.9374ZM23.6794 140.722L23.532 140.538L23.3657 140.706L19.177 144.93L11.2774 144.548L10.7977 144.525L11.1059 144.894L14.9391 149.475L15.0871 149.652L15.2494 149.488L19.3581 145.345L26.3149 144.525L26.6916 144.48L26.4548 144.184L23.6794 140.722ZM49.5548 125.595L50.1269 125.033L49.3528 125.242L43.9131 126.707L43.8641 126.586L43.6787 126.77L43.5042 126.818L43.54 126.908L39.1153 131.297L39.3211 131.649L44.816 130.085L44.8632 130.202L45.0499 130.018L45.2231 129.969L45.1881 129.882L49.5548 125.595ZM34.9898 135.982L35.4044 135.571L34.8225 135.624L27.8501 136.253L27.4813 136.287L27.6983 136.586L30.3481 140.245L30.4919 140.444L30.6668 140.271L34.9898 135.982ZM-60.4715 43.2881L-60.4443 43.2447L-60.4406 43.1934L-60.0361 37.6221L-60.4447 37.5396L-64.167 48.5291L-63.7887 48.7062L-60.4715 43.2881Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-63.7619 48.6403L-64.1468 48.4868L-67.3896 53.7827L-67.4271 53.8439L-67.423 53.8814L-69.9603 65.6756L-73.1262 70.7609L-73.1738 70.8366L-73.1604 70.8878L-74.427 83.4594L-74.0404 83.5939L-70.8321 78.5628L-70.783 78.4859L-70.7963 78.4329L-69.5575 65.8248L-66.325 60.6331L-66.2863 60.5716L-66.2907 60.5329L-63.7619 48.6403ZM28.0397 136.339L27.8942 136.139L27.7197 136.315L23.3666 140.706L22.9511 141.124L23.5374 141.063L30.5408 140.331L30.9026 140.293L30.6889 139.998L28.0397 136.339ZM11.4292 144.623L11.279 144.444L11.1166 144.612L6.95445 148.906L6.62508 149.246L7.09776 149.263L15.0931 149.55L15.5653 149.567L15.262 149.205L11.4292 144.623ZM39.4123 131.596L39.8172 131.194L39.2481 131.237L32.3086 131.76L31.9544 131.787L32.1485 132.085L34.6656 135.948L34.8066 136.165L34.9901 135.982L39.4123 131.596Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-58.4886 32.9512L-58.9052 32.9225L-60.8571 43.1388L-60.8656 43.1827L-60.8554 43.2259L-57.607 57.158L-57.1922 57.0989L-58.4886 32.9512ZM24.9037 105.517L24.8541 105.429L24.7543 105.413L19.6447 104.577L19.122 104.491L19.4439 104.912L36.7185 127.498L37.0692 127.267L24.9037 105.517Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6648 141.001L23.9917 140.671L23.528 140.643L15.6348 140.159L15.5442 140.043L15.4438 140.148L15.0741 140.125L15.2485 140.349L11.1161 144.611L10.7926 144.946L11.2576 144.968L19.1539 145.35L19.2416 145.462L19.3434 145.359L19.7085 145.377L19.5404 145.161L23.6648 141.001ZM-60.4446 43.2211L-60.8301 43.0679L-64.1473 48.4859L-64.1856 48.5486L-64.1808 48.5865L-66.7018 60.4411L-69.9411 65.6438L-69.9893 65.7217L-69.9759 65.7728L-71.2143 78.3782L-74.3955 83.3673L-74.4536 83.4583L-74.4128 83.5587L-71.1432 91.6671L-70.738 91.5875L-70.7991 78.5102L-67.5489 73.414L-67.4996 73.3358L-67.5134 73.2829L-66.2991 60.5903L-62.9907 55.2766L-62.9513 55.2135L-62.9564 55.1745L-60.4446 43.2211ZM32.5005 131.855L32.3582 131.636L32.1751 131.821L27.7194 136.315L27.3138 136.724L27.8877 136.672L34.8606 136.042L35.2091 136.011L35.0182 135.718L32.5005 131.855Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.3755 125.237L36.9766 127.143L19.778 104.656L19.7355 104.601L19.6685 104.582L14.2338 103.027L13.4992 102.818L14.0227 103.374L36.7318 127.514L36.8775 127.39L36.8977 127.58L43.8002 127.169L43.8213 127.167L43.8425 127.162L49.462 125.648L49.3755 125.237ZM-36.4828 52.8123L-36.4879 52.7262L-36.5518 52.6679L-58.5577 32.8061L-58.8927 33.0443L-34.7671 89.9024L-34.3633 89.808L-36.4828 52.8123Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.4186 144.904L11.6898 144.624L11.3071 144.552L2.79882 142.931L2.69781 142.796L2.60524 142.895L2.18658 142.815L2.4045 143.107L-1.61611 147.374L-1.88254 147.657L-1.49992 147.725L6.9866 149.245L7.08444 149.376L7.17829 149.28L7.59249 149.353L7.38072 149.071L11.4186 144.904ZM43.9357 127.108L44.3313 126.716L43.775 126.749L37.0017 127.152L36.9291 127.027L36.7928 127.164L36.531 127.18L36.622 127.336L32.1751 131.821L31.7782 132.222L32.3403 132.179L39.1482 131.666L39.2188 131.787L39.3566 131.65L39.6149 131.631L39.5275 131.481L43.9357 127.108ZM28.0184 136.611L28.3396 136.287L27.8843 136.253L20.0005 135.662L19.9107 135.539L19.8053 135.647L19.4504 135.621L19.6135 135.845L15.3759 140.217L15.0575 140.545L15.5146 140.574L23.4033 141.057L23.4907 141.177L23.5976 141.069L23.9478 141.091L23.7911 140.874L28.0184 136.611ZM-67.4603 86.6658L-67.3991 86.5725L-67.4258 86.5071L-67.5158 73.3613L-64.1889 68.1441L-64.138 68.0648L-64.1532 68.0117L-62.9643 55.235L-59.5773 49.7945L-59.537 49.7299L-59.5476 49.6551L-60.4421 43.1494L-60.8564 43.1347L-63.3676 55.086L-66.6821 60.4105L-66.7319 60.4894L-66.7172 60.5408L-67.9312 73.2307L-71.1873 78.3364L-71.2462 78.429L-71.2196 78.4919L-71.1585 91.5895L-71.1558 92.2867L-70.7728 91.7046L-67.4603 86.6658Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.3297 103.086L14.2975 103.052L14.2534 103.034L8.59328 100.8L8.37303 101.15L36.7422 127.524L37.0389 127.226L14.3297 103.086ZM-35.5306 57.0468L-35.5315 57.0274L-35.5355 57.0083L-36.4873 52.7783L-36.9025 52.8363L-34.7831 89.8276L-34.7828 89.8325L-34.3627 89.8131L-34.363 89.8081L-35.5306 57.0468Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-67.4267 86.53L-67.4835 85.9348L-67.8115 86.4343L-71.124 91.4731L-71.1939 91.5793L-71.1319 91.6908L-66.5553 99.8971L-66.162 99.7749L-67.4267 86.53ZM15.6785 140.51L15.9464 140.233L15.5688 140.157L6.96157 138.416L6.44173 138.311L6.74848 138.744L11.096 144.879L11.2422 145.086L11.4187 144.904L15.6785 140.51Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.6591 100.841L8.63691 100.821L8.60915 100.807L2.83333 97.9429L2.60313 98.2913L36.7486 127.529L37.0283 127.216L8.6591 100.841Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.4741 132.118L32.7892 131.799L32.3436 131.759L24.3722 131.049L23.9312 131.009L24.1785 131.376L27.695 136.58L27.8384 136.792L28.0187 136.61L32.4741 132.118ZM7.09165 138.501L6.94323 138.291L6.76697 138.478L2.52622 142.978L2.26308 143.258L2.64031 143.329L11.2282 144.965L11.7413 145.062L11.4394 144.637L7.09165 138.501ZM-64.069 81.5072L-64.0064 81.4122L-64.0349 81.3474L-64.1557 68.0289L-64.162 67.3202L-64.5433 67.9182L-67.9035 73.1877L-67.9639 73.2814L-67.9366 73.3447L-67.8461 86.5513L-67.841 87.2458L-67.46 86.6656L-64.069 81.5072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.87764 97.9711L2.86388 97.9595L2.84832 97.9504L-2.92923 94.5188L-3.16985 94.8623L36.7492 127.529L36.7527 127.533L37.0227 127.21L37.0191 127.207L2.87764 97.9711ZM-33.7065 61.7777L-33.7054 61.7361L-33.7208 61.697L-35.5446 56.9786L-35.9513 57.0618L-34.7829 89.8273L-34.3626 89.8249L-33.7065 61.7777Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-60.7485 62.7487L-60.6962 62.6677L-60.7243 62.5754L-62.9679 55.1052L-63.3789 55.146L-64.5757 68.0116L-64.189 68.1441L-60.7485 62.7487ZM24.5272 131.14L24.3818 130.926L24.2015 131.112L19.7364 135.718L19.4239 136.041L19.8719 136.074L27.8533 136.672L28.2865 136.705L28.0431 136.345L24.5272 131.14ZM2.83201 143.267L3.06179 143.024L2.74272 142.923L-6.26292 140.066L-6.83623 139.885L-6.50131 140.384L-1.6378 147.635L-1.49089 147.855L-1.30977 147.663L2.83201 143.267Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.90364 94.537L-2.90986 94.5313L-2.91704 94.5266L-8.57653 90.6004L-8.82814 90.9371L36.7524 127.533L36.7534 127.534L37.0188 127.207L37.0173 127.206L-2.90364 94.537Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-8.56448 90.6091L-8.56466 90.6085L-13.9879 86.2697L-13.9886 86.2693L-14.2518 86.5976L36.7542 127.533L36.8857 127.37L37.0176 127.206L37.015 127.203L-8.56448 90.6091Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-62.7761 94.8885L-62.7017 94.7803L-62.7447 94.7054L-64.0354 81.3708L-64.093 80.7771L-64.4206 81.2759L-67.8115 86.4343L-67.8828 86.5422L-67.8411 86.6149L-66.5814 99.815L-66.5257 100.395L-66.1978 99.913L-62.7761 94.8885ZM20.0391 136.011L20.3031 135.738L19.9321 135.658L11.3072 133.808L10.7981 133.698L11.0886 134.13L15.3525 140.481L15.4976 140.696L15.6785 140.51L20.0391 136.011ZM37.0348 127.518L37.3437 127.206L36.9073 127.16L28.9483 126.33L28.5191 126.286L28.7476 126.651L32.1461 132.081L32.2871 132.306L32.4738 132.118L37.0348 127.518Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.4382 133.896L11.2907 133.677L11.1103 133.869L6.76678 138.478L6.5076 138.754L6.87823 138.829L15.4857 140.57L15.9872 140.671L15.7019 140.246L11.4382 133.896ZM-62.7423 94.7338L-62.8333 94.2266L-63.1234 94.6522L-66.5457 99.6767L-66.6282 99.7977L-66.5433 99.9174L-60.7647 108.041L-60.3867 107.882L-62.7423 94.7338ZM-60.5963 76.2246L-60.5335 76.1283L-60.5621 76.0629L-60.7149 62.6337L-60.723 61.9274L-61.1032 62.5226L-64.5436 67.918L-64.6041 68.0136L-64.5762 68.0767L-64.4552 81.3938L-64.4492 82.0849L-64.0692 81.5076L-60.5963 76.2246Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-57.2242 57.223L-57.1713 57.1398L-57.2011 57.0462L-59.5549 49.6198L-59.9654 49.6642L-61.1346 62.6166L-60.7481 62.7485L-57.2242 57.223ZM29.1047 126.428L28.9622 126.2L28.7756 126.393L24.202 131.112L23.8951 131.428L24.3342 131.468L32.3058 132.179L32.7276 132.216L32.5032 131.858L29.1047 126.428ZM7.07327 138.767L7.3007 138.526L6.98533 138.422L-1.99064 135.483L-2.09791 135.316L-2.19071 135.418L-2.62481 135.276L-2.39162 135.638L-6.3803 140.014L-15.4013 136.084L-15.5149 135.894L-15.6034 135.996L-16.0663 135.794L-15.8061 136.226L-19.7429 140.71L-19.9342 140.928L-19.6675 141.042L-10.5714 144.931L-10.4608 145.115L-10.3714 145.017L-9.91002 145.214L-10.165 144.79L-6.26143 140.508L2.54372 143.3L2.64819 143.463L2.74175 143.363L3.17229 143.5L2.94523 143.147L7.07327 138.767ZM-59.2711 89.7426L-59.196 89.6329L-59.2627 89.5183L-64.0629 81.2857L-64.4543 81.4118L-63.1587 94.7906L-63.1026 95.3695L-62.7757 94.8887L-59.2711 89.7426Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-53.5511 115.719L-56.8303 102.971L-56.7993 102.928L-56.8596 102.845L-59.2364 89.5955L-60.5552 76.1623L-57.0388 70.8131L-56.975 70.7158L-57.0239 70.6097L-60.7345 62.5476L-61.1358 62.638L-60.983 76.0469L-64.4204 81.2763L-64.4921 81.385L-64.4266 81.4974L-59.693 89.6161L-63.1233 94.6521L-63.2065 94.7745L-63.1485 94.8549L-60.816 107.869L-60.8606 107.932L-60.7903 108.013L-60.7123 108.45L-60.5762 108.259L-53.9131 115.909L-53.5511 115.719ZM24.5044 131.405L24.7644 131.136L24.4001 131.053L15.8436 129.106L15.7451 128.95L15.642 129.06L15.2612 128.973L15.4477 129.266L11.1104 133.869L10.8544 134.141L11.2192 134.219L19.758 136.052L19.8537 136.203L19.958 136.095L20.335 136.176L20.1547 135.892L24.5044 131.405Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-57.1914 57.1072L-57.2011 56.404L-57.5795 56.9967L-61.1032 62.5228L-61.1646 62.6194L-61.1171 62.7234L-57.4059 70.7852L-57.0048 70.6942L-57.1914 57.1072ZM-24.8135 130.896L-25.2843 130.635L-25.0167 131.137L-28.8825 135.724L-29.0486 135.921L-28.8225 136.043L-19.7411 141.003L-19.6274 141.215L-19.5377 141.114L-19.0671 141.371L-19.3303 140.878L-15.389 136.389L-15.2164 136.192L-15.4456 136.066L-24.6074 131.009L-24.7242 130.79L-24.8135 130.896ZM11.4163 134.157L11.6408 133.919L11.3305 133.814L2.31416 130.765L2.20757 130.592L2.11185 130.697L1.68889 130.554L1.91248 130.916L-2.28258 135.518L-2.50177 135.759L-2.19286 135.86L6.78117 138.799L6.88517 138.966L6.98127 138.864L7.40126 139.002L7.18377 138.649L11.4163 134.157Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-19.0954 81.9569L-18.8496 81.8284L-19.0401 81.6259L-23.6514 76.7324L-23.8138 76.5593L-23.9658 76.7425L-34.7347 89.6853L-34.4754 90.0067L-19.0954 81.9569ZM20.3085 124.246L20.2225 124.658L28.877 126.744L28.8907 126.748L28.9045 126.749L36.8633 127.579L36.9218 127.162L20.3085 124.246Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-49.9191 110.728L-50.0333 110.383L-50.1195 110.5L-56.9002 102.79L-57.2619 102.981L-53.9834 115.727L-54.0339 115.795L-53.9434 115.882L-53.8487 116.252L-53.7274 116.089L-46.1732 123.316L-45.8281 123.099L-49.8718 110.872L-49.8355 110.823L-49.9191 110.728ZM-11.4664 131.283L-11.9195 131.082L-11.6685 131.513L-15.705 136.111L-15.895 136.328L-15.6312 136.442L-6.47331 140.433L-6.36413 140.62L-6.27214 140.52L-5.82111 140.716L-6.06651 140.295L-1.9713 135.802L-1.77487 135.586L-2.0416 135.468L-11.263 131.373L-11.3759 131.18L-11.4664 131.283ZM-55.6808 84.4709L-55.6047 84.3602L-55.6735 84.245L-60.5914 76.0013L-60.9815 76.1296L-59.6542 89.6452L-59.5973 90.2226L-59.2707 89.7432L-55.6808 84.4709Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-53.2647 97.9382L-53.1774 97.8145L-53.2668 97.6925L-59.2751 89.5L-59.6517 89.6615L-57.2653 102.966L-57.1765 103.46L-56.8865 103.05L-53.2647 97.9382ZM-33.5567 125.04L-33.699 124.735L-33.9105 124.997L-37.5494 129.505L-42.0726 118.218L-42.2093 117.876L-42.4345 118.167L-46.1943 123.036L-46.3207 123.2L-46.1592 123.329L-37.9075 129.948L-37.9195 129.963L-37.7358 130.086L-37.1181 130.581L-37.1632 130.469L-28.8386 136.034L-28.2109 136.454L-28.5313 135.77L-33.5567 125.04ZM-57.0057 70.6769L-57.0645 70.0854L-57.3909 70.582L-60.9477 75.9932L-61.0202 76.1034L-60.9526 76.2164L-56.0353 84.4604L-55.6452 84.3321L-57.0057 70.6769ZM29.0772 126.686L29.3335 126.421L28.9758 126.335L20.3213 124.248L19.8338 124.131L20.0915 124.561L24.1727 131.366L24.3132 131.601L24.5042 131.405L29.0772 126.686Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-15.2939 136.28L-11.3538 131.794L-2.27591 135.824L-2.16725 136.017L-2.07345 135.914L-1.63198 136.11L-1.86881 135.689L2.23819 131.184L11.1219 134.187L11.2249 134.361L11.3242 134.256L11.7331 134.394L11.5252 134.042L15.7836 129.523L24.3067 131.463L24.7873 131.573L24.5338 131.15L20.4525 124.345L20.3099 124.106L20.1193 124.309L15.6531 129.048L6.72621 125.93L6.62051 125.751L6.52261 125.859L6.11002 125.715L6.32406 126.077L2.12285 130.686L-7.02126 126.546L-7.13312 126.348L-7.226 126.454L-7.66944 126.253L-7.42707 126.683L-11.4563 131.272L-20.5498 126.198L-20.666 125.975L-20.7564 126.083L-21.219 125.825L-20.9592 126.323L-24.7104 130.774L-29.5956 120.135L-29.7372 119.827L-29.9503 120.091L-33.6837 124.716L-38.2188 113.23L-38.3544 112.886L-38.5809 113.178L-42.1914 117.853L-46.1325 105.814L-46.0954 105.763L-46.1796 105.668L-46.2929 105.322L-46.3809 105.442L-53.1682 97.8022L-49.5528 92.6998L-49.4647 92.5746L-49.556 92.4525L-55.5957 84.3473L-52.0009 79.0683L-51.924 78.9561L-51.97 78.8815L-53.3528 65.2059L-53.3286 65.1692L-53.3643 65.0952L-53.4201 64.5422L-53.5404 64.7251L-57.2118 57.0198L-57.6122 57.1131L-57.4258 70.6361L-57.4638 70.6936L-57.4241 70.7581L-57.4156 71.3862L-57.3651 71.3095L-56.071 84.2975L-59.6184 89.5064L-59.7021 89.629L-59.6141 89.749L-53.6958 97.8189L-57.2299 102.807L-57.3261 102.943L-57.2162 103.068L-50.3757 110.846L-50.3849 110.859L-50.2941 110.945L-50.1997 111.316L-50.1779 111.286L-46.2275 123.23L-46.103 123.606L-45.8613 123.293L-42.3258 118.716L-37.8065 129.994L-37.673 130.328L-37.4475 130.048L-33.7942 125.523L-28.912 135.948L-28.7724 136.246L-28.5603 135.995L-24.7242 131.443L-24.2602 131.755L-24.3054 131.656L-15.704 136.404L-15.5912 136.619L-15.5003 136.515L-15.0378 136.771L-15.2939 136.28Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-57.2176 57.0081L-57.5916 57.2006L-53.7601 65.2429L-53.7548 65.2539L-53.7485 65.264L-48.5811 73.5236L-48.2188 73.31L-57.2176 57.0081ZM1.69284 116.482L1.5509 116.878L20.1912 124.647L20.2125 124.656L20.2354 124.661L36.8493 127.577L36.9464 127.168L1.69284 116.482Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-11.1579 131.57L-7.11584 126.966L2.02429 131.103L2.13154 131.301L2.22769 131.195L2.66013 131.391L2.43172 130.971L6.64645 126.348L15.5683 129.465L15.6698 129.644L15.772 129.535L16.171 129.675L15.9721 129.323L20.4249 124.597L20.6452 124.364L20.3434 124.256L11.2493 120.974L11.1441 120.789L11.0433 120.899L10.6419 120.754L10.8465 121.116L6.53289 125.848L-2.67344 121.598L-2.78423 121.395L-2.8789 121.503L-3.31335 121.303L-3.07978 121.731L-7.21629 126.443L-16.3911 121.266L-16.5066 121.04L-16.5982 121.15L-17.0526 120.893L-16.8007 121.389L-20.6531 125.96L-25.5347 115.108L-25.6756 114.796L-25.8905 115.062L-29.7222 119.808L-34.2678 108.116L-34.4031 107.769L-34.6309 108.064L-38.3368 112.863L-42.2999 100.628L-42.2623 100.578L-42.3469 100.483L-42.4593 100.136L-42.5491 100.258L-49.4559 92.5625L-45.7485 87.3293L-45.6592 87.2037L-45.7521 87.0808L-51.916 78.9437L-48.2288 73.5306L-48.1517 73.4171L-48.2248 73.3007L-53.3915 65.041L-53.7795 65.1743L-52.3915 78.8953L-56.028 84.2341L-56.1126 84.3583L-56.0232 84.4784L-49.9848 92.5815L-53.6081 97.6953L-53.7047 97.832L-53.5934 97.9568L-46.6368 105.788L-46.6463 105.8L-46.5552 105.887L-46.4609 106.258L-46.4394 106.229L-42.468 118.362L-42.3443 118.739L-42.1015 118.425L-38.4741 113.729L-33.9425 125.206L-33.81 125.542L-33.5829 125.261L-29.8363 120.62L-24.9548 131.251L-24.8161 131.552L-24.6027 131.299L-20.6687 126.631L-20.2122 126.939L-20.2548 126.845L-11.5688 131.691L-11.4572 131.911L-11.3643 131.805L-10.9102 132.058L-11.1579 131.57Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.9831 127.183L-34.43 89.6577L-57.229 56.9895L-57.5861 57.2116L-48.5872 73.5134L-48.5771 73.5318L-48.5634 73.5473L-34.7337 89.9554L-34.6244 89.8719L-34.6986 89.9889L1.50651 116.853L1.53619 116.875L1.57108 116.885L36.8249 127.571L36.9831 127.183Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.71311 116.489L1.54241 116.874L11.015 121.334L11.0239 121.339L11.0332 121.342L20.201 124.651L20.3534 124.259L1.71311 116.489Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-30.4386 102.636L-38.4148 95.1622L-41.7908 81.743L-41.7567 81.6956L-41.8249 81.6069L-41.9259 81.2064L-42.0252 81.3466L-48.2359 73.2837L-48.6101 73.4489L-46.1423 87.1581L-46.1893 87.2235L-46.1165 87.3044L-46.038 87.7386L-46.0122 87.7013L-42.7621 100.546L-42.8134 100.615L-42.7225 100.702L-42.6287 101.073L-42.5053 100.906L-34.7473 108.215L-34.7568 108.228L-34.6452 108.317L-34.5422 108.638L-34.4285 108.491L-26.0234 115.226L-26.0344 115.24L-25.8999 115.331L-25.7927 115.61L-25.6951 115.489L-25.2535 115.844L-25.2915 115.744L-16.7184 121.558L-16.6065 121.812L-16.5116 121.699L-16.0625 122.003L-16.3046 121.453L-12.1265 116.496L-11.9761 116.318L-12.1687 116.187L-21.3955 109.897L-21.5103 109.636L-21.5957 109.742L-30.2374 102.819L-30.3513 102.523L-30.4386 102.636ZM1.57809 116.426L1.15324 116.226L1.37838 116.654L-2.98278 121.621L-3.16913 121.833L-2.91334 121.951L6.43098 126.264L6.53776 126.467L6.63566 126.359L7.05911 126.554L6.83894 126.136L11.2595 121.287L11.4517 121.076L11.1937 120.955L1.78562 116.523L1.67569 116.315L1.57809 116.426Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-7.95269 110.892L-17.1238 104.608L-17.2378 104.344L-17.3346 104.463L-17.7737 104.162L-17.5363 104.713L-21.4962 109.619L-26.0198 97.6036L-25.9745 97.5451L-26.0784 97.4478L-26.1941 97.1409L-26.2832 97.2568L-34.3886 89.7043L-34.4964 89.3637L-34.5899 89.4899L-41.8629 81.5585L-42.2219 81.7521L-38.8313 95.2284L-38.8837 95.2987L-38.7923 95.3845L-38.6989 95.7563L-38.5745 95.5887L-30.6967 102.971L-30.7056 102.983L-30.5947 103.072L-30.4919 103.395L-30.3878 103.26L-29.9692 103.652L-30.0027 103.546L-21.8599 110.069L-21.8703 110.082L-21.6883 110.206L-21.0962 110.681L-21.132 110.586L-12.5975 116.403L-12.6101 116.418L-12.4517 116.508L-12.3453 116.757L-12.2484 116.641L-11.8068 116.942L-11.8447 116.855L-2.98587 121.91L-2.87637 122.138L-2.77972 122.027L-2.34208 122.277L-2.57473 121.794L1.79017 116.823L1.95961 116.63L1.73741 116.502L-7.75299 111.023L-7.86543 110.789L-7.95269 110.892Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M-34.4473 89.6513L-34.7104 89.9795L-17.4303 104.906L-17.4187 104.917L-17.4086 104.923L-8.03318 111.347L-8.02675 111.351L-8.01972 111.355L1.52717 116.866L1.60142 116.743L1.75778 116.515L-34.4473 89.6513Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default Group4;
