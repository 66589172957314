import React, { type FC, useEffect, useState } from 'react';

import Image from 'next/legacy/image';
import Link from 'next/link';

import {
  type Search,
  useGetCategoryQuery,
} from '../../utils/services/ApiConnection';

import { parser } from './CategoriesCard';
import Loading from './Loading';

const NomineeCard: FC<Search> = ({
  name,
  votes,
  user_image,
  category_id,
  id,
}) => {
  const { data, isLoading } = useGetCategoryQuery(category_id || 0);
  const [page, setPage] = useState('');
  useEffect(() => {
    if (data != null) {
      setPage(data[0]?.category);
    }
  }, [data]);
  return (
    <>
      {isLoading ? (
        <span className="group flex cursor-pointer select-none flex-col items-center gap-6 p-6 shadow-xl transition-all duration-500 ease-in hover:shadow-2xl">
          <Loading className="mt-4" />
        </span>
      ) : (
        <Link
          href={{
            pathname: `/attest/${parser(page)}`,
            query: { id: category_id },
          }}
        >
          <span className="group flex cursor-pointer select-none flex-col items-center gap-6 p-6 shadow-xl transition-all duration-500 ease-in hover:shadow-2xl">
            <Image
              height={96}
              alt={user_image}
              width={96}
              src={`https://hammerhead-app-iepws.ondigitalocean.app/images/${user_image}`}
              className="block rounded-full shadow-md shadow-[#1F0231]/30  transition-[box-shadow] duration-500 ease-in hover:shadow-[#1F0231]/40"
            />
            <span className="text-center">{name}</span>
            <span>{votes} attestation units</span>
          </span>
        </Link>
      )}
    </>
  );
};

export default NomineeCard;
