import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Xcom: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M8.92737 6.85175L14.5105 0.5H13.1874L8.33842 6.01475L4.46684 0.5H0L5.85632 8.84L0 15.5H1.32316L6.44368 9.67625L10.5332 15.5H15L8.92737 6.85175ZM7.11474 8.91275L6.52105 8.0825L1.8 1.475H3.83289L7.64289 6.8075L8.23579 7.63775L13.1882 14.5693H11.1561L7.11474 8.91275Z"
        fill="white"
      />
    </svg>
  );
};
export default Xcom;
