/* eslint-disable no-console */
import React, { type FC, memo } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';

import { type PropTypes, type Props } from '../../typescript';
import useToggle, { type UseToggle } from '@util/hooks/useToggle';
import { LinkedIn } from '../icons';
interface Vote {
  votes?: string
  name?: string
  user_image?: string
  id: number
  category_id?: number
  sub_category?: string
  query?: boolean
  linkedin?: string
  job_category?: string
  category_attribute?: string
  slug?: string
  vote_possibility?: string
}
const VoteCard: FC<PropTypes<Props, Vote>> = ({
  votes,
  name,
  user_image,
  id,
  category_id,
  sub_category,
  linkedin,
  job_category,
  category_attribute,
  slug,
  vote_possibility
}) => {
  const [{ toggle: copiedToggle, setToggle }, closeToggle]: UseToggle = useToggle(false);
  return (
    <div className="group w-full bg-white flex cursor-pointer select-none flex-col items-center justify-between gap-6 p-6 shadow-[0px_2px_15px_rgba(230,230,230,0.35)] transition-all duration-500 ease-in hover:shadow-[0px_2px_15px_rgba(230,230,230,0.65)]">
      {/* <span className="w-24 h-24 rounded-full bg-[#1F0231] shadow-xl shadow-[#1F0231]/30 hover:shadow-2xl hover:shadow-[#1F0231]/30 transition-[box-shadow] duration-500 ease-in"></span> */}
      {!!parseInt(vote_possibility || "") ? <Image
        src={`https://awardtest.techtrend.africa/public/Participant/${user_image ?? ""}`}
        width={210}
        height={210}
        alt={name as string}
        className="block rounded-full text-center shadow-md duration-500 ease-in h-36 w-36 relative"
      /> : <div className="rounded-full p-6 bg-golden relative">
        <span className="absolute text-golden bg-white px-2 rounded-full -right-10 top-2">Global Hero</span>
        <Image
          src={`https://awardtest.techtrend.africa/public/Participant/${user_image ?? ""}`}
          width={210}
          height={210}
          alt={name as string}
          className="block rounded-full text-center shadow-md duration-500 ease-in h-36 w-36 relative"
        />
      </div>}
      <div className="flex flex-col gap-4 items-center text-center relative">
        <div className="flex gap-2 w-fit mt-2 items-center text-center">
          <h3 className="text-golden lg:text-sm text-base font-bold">{name}</h3>
          <Link
            href={
              (linkedin?.includes('https://www.') ?? false) ||
                (linkedin?.includes('www.') ?? false) ||
                (linkedin?.includes('https') ?? false)
                ? linkedin ?? ''
                : `https://www.${linkedin ?? ''}`
            }
            target="_blank"
          >
            <LinkedIn />
          </Link>
        </div>
        <h5 className="text-[#6B6B6B] text-sm font-light">{job_category}</h5>
        <p className="text-[#6B6B6B] text-sm font-light">
          {category_attribute}
        </p>
        <span className="text-[#1F0231">{votes} Attestation units</span>
        {sub_category && <span>{sub_category}</span>}

        <Link
          href={{ pathname: `/attest/participant/${id}`, query: { slug, category_id } }}
          className="h-max cursor-pointer rounded-md bg-darkPurple py-2 px-10 font-light text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple"
        >
          View
        </Link>
        <AnimatePresence>
          {copiedToggle && (
            <motion.div
              initial={{ opacity: 0, y: 0 }}
              animate={{ opacity: 1, y: -40 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ duration: 0.75 }}
              onAnimationComplete={() => {
                setTimeout(() => {
                  closeToggle();
                }, 2000);
              }}
              className="bg-green-700 text-green-200 rounded-lg bottom-0 absolute flex items-center justify-center text-sm py-3 px-10 h-max mx-auto animate-move"
            >
              Copied!
            </motion.div>
          )}
        </AnimatePresence>
        <div
          onClick={() => {
            navigator.clipboard.writeText(`https://theconnectedawards.com/attest/participant/${id}`)
              .then(() => {
                setToggle();
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          className="text-sm font-light text-darkPurple"
        >
          Copy profile
        </div>
      </div>
    </div>
  );
};

export default memo(VoteCard);
