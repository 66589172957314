/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { type FC } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { InstagramStroked, LinkedInStroked, TwitterStroked, GitHubStroked, BehanceStroked } from './icons';

export interface ITheTeamProps {
  category: string
  name: string
  linkedin: string
  img: string
  twitter?: string
  instagram?: string
  github?: string
  behance?: string
  hidden?: boolean
}

const TheTeam: FC<ITheTeamProps> = ({
  category,
  name,
  linkedin,
  img,
  twitter,
  behance,
  github,
  instagram,
  hidden
}) => {
  return (
  <div className="lg:basis-[25%]">
    <div
      className={`flex flex-col gap-2 h-fit text-center items-center relative group w-fit border-4 rounded-xl overflow-hidden border-[#1F0231] ${
        hidden ? 'opacity-0' : 'opacity-100'
      }`}
    >
      {!hidden && (
        <>
          <div className="w-full h-full absolute inset-0 bg-[#1F0231B2] group-hover:opacity-100 z-10 opacity-0 transition-opacity duration-500 ease-in" />
          <Image
            src={'/about/' + img}
            alt={name}
            width={299}
            height={347}
            quality={100}
            className="lg:h-96 w-auto"
            priority
          />
          <div className="flex absolute flex-col inset-0 m-auto space-y-1 w-fit h-fit z-10 items-center text-white group-hover:opacity-100 opacity-0 transition-opacity duration-500 ease-in">
            <div className="flex justify-between w-10/12 gap-3">
              <Link href={linkedin} target="_blank" className="mb-2">
                <LinkedInStroked className="w-8 h-8" />
              </Link>
              {(instagram ?? false) && (
                <Link href={instagram ?? ''} target="_blank" className="mb-2">
                  <InstagramStroked className="w-8 h-8" />
                </Link>
              )}
              {(github ?? false) && (
                <Link href={github ?? ''} target="_blank" className="mb-2">
                  <GitHubStroked className="w-8 h-8" />
                </Link>
              )}
              {(behance ?? false) && (
                <Link href={behance ?? ''} target="_blank" className="mb-2">
                  <BehanceStroked className="w-8 h-8" />
                </Link>
              )}
              {(twitter ?? false) && (
                <Link href={twitter ?? ''} target="_blank" className="mb-2">
                  <TwitterStroked className="w-8 h-8" />
                </Link>
              )}
            </div>
            <h3 className="text-base font-medium">{name}</h3>
            <h5 className="text-xs font-light">{category}</h5>
          </div>
          <div className="absolute w-9/12 bg-[#1F0231] text-white bottom-8 mx-auto py-3 px-1.5 group-hover:opacity-0 opacity-100 transition-opacity duration-500 ease-in">
            <h3 className="text-sm font-medium">{name}</h3>
            <h5 className="text-xs font-light">{category}</h5>
          </div>
        </>)
      }
    </div>
  </div>
  );
};
export default TheTeam;
