import React, { type FC } from 'react';

import Image, { type StaticImageData } from 'next/legacy/image';

interface SelectedCardProps {
  image: StaticImageData
  name: string
}

const SelectedCard: FC<SelectedCardProps> = ({ image, name }) => {
  return (
    <span className="flex w-full flex-col items-center justify-center gap-4 p-8">
      <div className="lg:basis-[30%] basis-full h-auto mt-4 block ">
        <Image
          alt={name}
          // height={256}
          // width={256}
          src={image}
        />
      </div>
      {/* <span className="text-darkPurple font-semibold text-base">{role}</span> */}
      <span className="text-lg font-semibold text-center">{name}</span>
    </span>
  );
};

export default SelectedCard;
