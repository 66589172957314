import rocket from './rocket.svg';
import thumbs from './thumbs-up.svg';
import close from './close.svg';
import close2 from './close2.svg';
import instagram from './instagram.svg';
import linkedln from './linkedln.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import logo from './logo.png';
import success from './success.svg';
import oops from './oops.svg';
import theconnectedLogo from './theconnectedLogo.png';
import image1 from './awards/image1.jpg';
import image2 from './awards/image2.jpg';
import image3 from './awards/image3.jpg';
import image4 from './awards/image4.jpg';
import image5 from './awards/image5.jpg';
import image6 from './awards/image6.jpg';
import image7 from './awards/image7.jpg';
import image8 from './awards/image8.jpg';
import image9 from './awards/image9.jpg';
import image10 from './awards/image10.jpg';
import image11 from './awards/image11.jpg';
import image12 from './awards/image12.jpg';
import image13 from './awards/image13.jpg';
import image14 from './awards/image14.jpg';
import image15 from './awards/image15.jpg';
import image16 from './awards/image16.jpg';
export {
  rocket,
  thumbs,
  close,
  instagram,
  linkedln,
  facebook,
  twitter,
  logo,
  success,
  oops,
  theconnectedLogo,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  close2,
};
