/* eslint-disable multiline-ternary */
import React, { useState, type FC, useId, useEffect } from 'react';

import { Inter } from 'next/font/google';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type DefaultSession } from 'next-auth';
import { useSession, signOut } from 'next-auth/react';

import { Logo } from './icons';
import { AnimatePresence, motion } from 'framer-motion';
import { staggerChildren, wordsAnimation } from '@util/constants/animations';

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

interface Links {
  text: string;
  link: string;
}
const links: Links[] = [
  { text: 'Categories', link: 'attest' },
  { text: 'Winners', link: 'winners' },
  { text: 'Process', link: 'process' },
  { text: 'About us', link: 'about-us' },
  { text: 'FAQs', link: 'information' },
];
const Navbar: FC = () => {
  const { data: session, status } = useSession() as {
    data: null | (DefaultSession & { user: { token: string } });
    status: string;
  };
  const [changeMessage, setChangeMessage] = useState<boolean>(false);
  const [drop, setDrop] = useState<boolean>(false);
  const [log, setLog] = useState<boolean>(false);
  const route = useRouter();
  const Id = useId();
  useEffect(() => {
    const interval = setInterval(() => {
      setChangeMessage((prev) => !prev);
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <nav
      className={`sticky top-0 z-20 flex w-full gap-14 border-b-[1px] bg-darkerPurple p-4 ${inter.className}  px-8 font-medium lg:px-16 items-center`}
    >
      <Logo drop={drop} className="z-20 h-auto w-36 cursor-pointer" />
      <div
        className={`${!drop
          ? 'inset-0 top-24 h-0 w-0 p-12 opacity-0 ease-out'
          : 'inset-0 flex h-screen w-full items-center gap-16 overflow-y-scroll p-12 pt-36 text-white opacity-100'
          } lg:text absolute z-10 flex-col overflow-y-hidden  bg-[#1F0231] transition-[height,overflow,opacity] duration-1000 ease-in lg:relative lg:top-0 lg:my-auto lg:ml-auto lg:flex lg:h-fit  lg:w-full lg:flex-row lg:overflow-y-visible lg:bg-transparent lg:p-0 lg:align-middle lg:opacity-100`}
      >
        <div className="z-10 my-auto flex flex-col items-center  justify-between  gap-16 text-white lg:mx-auto lg:basis-7/12 lg:flex-row lg:justify-between lg:gap-0">
          {links.map(({ link, text }: Links, i) => (
            <Link key={`${Id}-${i}`} href={`/${link}`}>
              <span
                className={`relative w-fit cursor-pointer text-xl transition-all duration-500 before:absolute before:-bottom-2 before:h-1 before:w-full before:origin-right before:rounded-full before:bg-white before:transition-[transform] before:duration-300 hover:before:origin-left lg:text-sm ${route.asPath.includes(link)
                  ? 'before:scale-x-100'
                  : 'before:scale-x-0 hover:before:scale-x-100'
                  }`}
              >
                {text}
              </span>
            </Link>
          ))}
        </div>
        <div>
          {status === 'loading' ? (
            <span className="z-10 my-auto flex w-fit basis-[28.6%] text-white animate-pulse flex-col items-center gap-16 align-middle text-xl lg:ml-auto lg:flex-row lg:gap-6 lg:text-sm">
              Loading
            </span>
          ) : session == null ? true ? (
            <Link
              className="mx-auto block w-fit cursor-pointer rounded bg-golden px-6 py-5 font-bold capitalize text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple disabled:cursor-not-allowed disabled:first-letter:opacity-70"
              href="https://forms.gle/2D3aqWXbKUycofVC6"
              target="_blank"

            >
              Participate
            </Link>
            ) :(
            <>
              <span className="z-10 my-auto flex w-fit basis-[28.6%] flex-col items-center gap-16 align-middle text-xl lg:ml-auto lg:flex-row lg:gap-6 lg:text-sm">
                <Link href={`/login?redirect=${route.asPath}`}>
                  <span className="w-full cursor-pointer rounded px-6 py-3 text-white transition-all duration-500 ease-in hover:bg-darkPurple hover:text-lightPurple lg:w-fit">
                    Login
                  </span>
                </Link>
                <Link href={`/sign-up?redirect=${route.asPath}`}>
                  <span
                    className={
                      'w-full lg:w-fit cursor-pointer rounded bg-white px-6 py-4 transition-all duration-500 ease-in hover:bg-lightPurple text-darkPurple'
                    }
                  >
                    Create Account
                  </span>
                </Link>
              </span>
            </>
          ) : (
            <span className="z-10 my-auto flex w-fit flex-col gap-6 text-white align-middle lg:ml-auto lg:flex-row">
              <span className="h-10 w-px bg-[#DBDBDB]"></span>
              <span className="my-auto flex items-center space-x-2">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 0.000366211C4.02996 0.000366211 0 4.02953 0 8.99997C0 13.9704 4.02956 17.9996 9 17.9996C13.9708 17.9996 18 13.9704 18 8.99997C18 4.02953 13.9708 0.000366211 9 0.000366211ZM9 2.69135C10.6446 2.69135 11.9772 4.02439 11.9772 5.66816C11.9772 7.31232 10.6446 8.64497 9 8.64497C7.35623 8.64497 6.02359 7.31232 6.02359 5.66816C6.02359 4.02439 7.35623 2.69135 9 2.69135ZM8.99802 15.6466C7.35781 15.6466 5.85557 15.0493 4.69687 14.0605C4.41461 13.8198 4.25173 13.4668 4.25173 13.0963C4.25173 11.4293 5.60098 10.095 7.26847 10.095H10.7323C12.4002 10.095 13.7443 11.4293 13.7443 13.0963C13.7443 13.4672 13.5822 13.8194 13.2996 14.0602C12.1413 15.0493 10.6386 15.6466 8.99802 15.6466Z"
                    fill="#fff"
                  />
                </svg>
                <span
                  className="flex cursor-pointer"
                  onClick={() => {
                    setLog(!log);
                  }}
                >
                  {session.user?.name}
                  <svg
                    className={`h-5 w-5 ${log ? 'rotate-180' : ''}`}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.3333 6.66666L7.99996 10L4.66663 6.66666"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    className={`${!log
                      ? 'top-[-500%] hidden ease-out lg:flex'
                      : 'top-16 flex h-fit w-max  py-12'
                      } z-50 bg-darkPurple text-white transition-all duration-1000 ease-in lg:absolute`}
                  >
                    <span
                      className="group flex w-fit border-y px-12 py-3 hover:bg-white hover:px-10 hover:text-darkPurple"
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={async () => {
                        await signOut({
                          redirect: true,
                          callbackUrl: route?.asPath,
                        });
                      }}
                    >
                      <svg
                        className="my-auto h-4 w-4 align-middle transition-all duration-1000 ease-in group-hover:mr-4"
                        viewBox="0 0 18 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.38574 15.5H3.38574C2.55731 15.5 1.88574 14.8285 1.88574 14V5C1.88574 4.17157 2.55731 3.5 3.38574 3.5H6.38574V5H3.38574V14H6.38574V15.5Z"
                          className="fill-white group-hover:fill-darkPurple"
                        />
                        <path
                          d="M10.3813 13.5388L11.4465 12.4826L8.50709 9.51817H15.3643C15.7785 9.51817 16.1143 9.18239 16.1143 8.76817C16.1143 8.35395 15.7785 8.01817 15.3643 8.01817H8.49269L11.478 5.05807L10.4218 3.99292L5.62866 8.74559L10.3813 13.5388Z"
                          className="fill-white group-hover:fill-darkPurple"
                        />
                      </svg>
                      Logout
                    </span>
                  </span>
                </span>
              </span>
            </span>
          )}
        </div>
      </div>

      <div className="z-20 float-right my-auto ml-auto cursor-pointer lg:hidden">
        <div
          className={`
    h-1 w-8 cursor-pointer rounded-full
    transition-all duration-500 ease-in before:absolute before:mt-2.5 before:h-1 before:w-8 before:cursor-pointer
    before:rounded-full before:transition-all before:duration-500 before:ease-in before:content-[''] after:absolute after:-mt-2.5
    after:h-1 after:w-8 after:cursor-pointer
    after:rounded-full after:transition-all
    after:duration-500 after:ease-in  after:content-['']
    ${drop
              ? 'bg-transparent before:mt-0  before:rotate-[135deg] before:bg-white  after:mt-0 after:rotate-45 after:bg-white'
              : 'bg-white before:bg-white after:bg-white'
            }
    `}
          onClick={() => {
            setDrop(!drop);
          }}
        ></div>
      </div>
    </nav>
  );
};

export default Navbar;
