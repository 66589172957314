import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const InterSwitch: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 120 184"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_11_139"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="54"
        >
          <path
            d="M0 65.0328C0 61.5404 43.9896 54.0039 73.4919 54.0039C86.2859 54.0039 96.3543 55.4213 98.9301 59.1995C112.214 76.7414 18.6973 186.795 10.9049 183.457C4.6558 181.788 60.7628 111.738 51.4101 83.3753C46.7413 68.3772 0 70.0455 0 65.0328Z"
            fill="#FF0C00"
          />
        </mask>
        <g mask="url(#mask0_11_139)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-25.6627 209.195H125.883V28.3404H-25.6627L-25.6627 209.195Z"
            fill="#FF0C00"
          />
        </g>
        <mask
          id="mask1_11_139"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="79"
          y="0"
        >
          <path
            d="M79.4551 21.6739C79.4551 9.69448 88.5269 -1.90735e-05 99.7078 -1.90735e-05C110.9 -1.90735e-05 119.959 9.69448 119.959 21.6739C119.959 33.6594 110.9 43.3539 99.7078 43.3539C88.5269 43.3539 79.4551 33.6594 79.4551 21.6739Z"
            fill="#FF0C00"
          />
        </mask>
        <g mask="url(#mask1_11_139)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.7923 69.0184H145.623V-25.6646H53.7923L53.7923 69.0184Z"
            fill="#FF0C00"
          />
        </g>
      </svg>
  );
};
export default InterSwitch;
