import React, { type FC } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { type EmblaOptionsType } from 'embla-carousel-react';
import Image, { type StaticImageData } from 'next/image';
export interface IAboutCarouselProps {
  slides: string[] | StaticImageData[]
  options: EmblaOptionsType
}
const autoplayOptions = {
  delay: 4000,
  rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement
};
const AboutCarousel: FC<IAboutCarouselProps> = ({ slides, options }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay(autoplayOptions)]);

  return (
    <div
    //   onMouseOver={() => {
    //     emblaApi?.plugins()?.autoplay?.stop();
    //   }}
    // onMouseLeave={() => {
    //   emblaApi?.plugins()?.autoplay?.play();
    // }}
    className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((val, index) => (
            <div className="embla__slide  shadow-xl shadow-black/15" key={index}>
              <Image
                src={val}
                width={527}
                height={315}
                alt="image"
                className="embla__slide__img"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default AboutCarousel;
