/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { type FC, useState, useEffect } from "react";

import { FlutterWaveButton, closePaymentModal } from "flutterwave-react-v3";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

// import { payForVotes } from '../../utils/services/fetch';
import { useAppDispatch } from "../../utils/hooks/useRedux";
import { updateSlice } from "../../utils/services/AmountSlice";
import { useInitializePaymentMutation } from "../../utils/services/ApiConnection";
import { type PaymentProp } from "../ChooseMethod";
import { Flutterwave } from "../icons";
const FlutterWavePayment: FC<
  PaymentProp & {
    currency?: string;
    text: string;
    metadata?: string;
    email?: string;
    nominee_id?: number;
    name?: string;
  }
> = ({
  amount,
  voteCount,
  currency,
  setSuccessToggle,
  text,
  metadata,
  email,
  nominee_id,
  name,
}) => {
  const { data: session } = useSession();
  const dispatch = useAppDispatch();
  const [data] = useInitializePaymentMutation();
  const { push } = useRouter();
  const [clicked, setClicked] = useState(false);
  if (currency) {
    return (
      <button
        className="group mx-auto flex w-full cursor-pointer items-center justify-center rounded bg-darkPurple px-6 py-6 text-center text-sm font-bold text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple lg:text-base"
        onClick={() => {
          setClicked(true);
          if (!clicked) {
            void data({
              tx_ref: String(Date.now()),
              amount,
              nominee_id,
              currency,
              redirect_url: `https://theconnectedawards.com/attest/nominee/${nominee_id}?payment_successful=true&amount_paid=${amount}&vote_count=${voteCount}`,
              meta: {
                nominee_id,
                vote_amount: voteCount,
              },
              customizations: {
                title: voteCount ? `${voteCount} Units` : "Nomination",
                description: `Payment for ${
                  voteCount ? `${voteCount} Units For ${name}` : "Nomination"
                }`,
                logo: "https://raw.githubusercontent.com/Onyelaudochukwuka/theconnectedawards/main/public/logo.png?token=GHSAT0AAAAAACDRFTRFFJJIZPLXAXSKKB3UZELFDQQ",
              },
              customer: {
                email:
                  email ??
                  (session?.user?.email !== null
                    ? String(session?.user?.email)
                    : ""),
                name:
                  session?.user?.name !== null
                    ? String(session?.user?.name)
                    : "",
              },
            })
              .unwrap()
              .then((res) => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (res) {
                  void push(res.data.link);
                }
              });
          }
        }}
      >
        <span className="flex w-fit">
          <Flutterwave className="h-auto w-24" />
          <span className="-ml-12">{text}</span>
        </span>
      </button>
    );
  } else {
    const public_key =
      // process.env.FLUTTERWAVE_SECRET_KEY;
      "FLWPUBK_TEST-1a3da5429cbe7502d46737a90a331ab3-X";
    const config = {
      public_key: String(public_key),
      tx_ref: String(Date.now()),
      amount,
      currency,
      payment_options: "card,mobilemoney,ussd",
      customer: {
        email:
          email ??
          (session?.user?.email != null ? String(session?.user?.email) : ""),
        name: session?.user?.name != null ? String(session?.user?.name) : "",
        phone_number: `${voteCount}-${nominee_id}`,
        phonenumber: `${voteCount}-${nominee_id}`,
      },
      meta: {
        payment_type: "customPrice",
        vote_count: voteCount,
        nominee_id,
      },
      customizations: {
        title: voteCount ? `${voteCount} attestation` : "Nomination",
        description: `Payment for ${
          voteCount ? `${voteCount} votes For ${name}` : "Nomination"
        }`,
        logo: "https://raw.githubusercontent.com/Onyelaudochukwuka/theconnectedawards/main/public/logo.svg",
      },
    };

    const fwConfig = {
      ...config,
      callback: (ref: any) => {
        closePaymentModal(); // this will close the modal programmatically
        if (ref) {
          if (setSuccessToggle != null) {
            setSuccessToggle();
          }
          if (typeof voteCount === "number") {
            dispatch(updateSlice({ voteCount, amount, metadata: "" }));
          }
        }
      },
      onClose: () => {},
    };
    return (
      <FlutterWaveButton
        {...fwConfig}
        className="group mx-auto flex w-full cursor-pointer rounded-md bg-[#D8CDE0]/10 border-[1px] border-[#D8CDE0] items-center justify-center px-6 py-6 text-center text-sm font-bold  transition-all duration-500 ease-in hover:bg-darkPurple hover:text-[#D8CDE0] lg:text-base"
      >
        <span className="flex  w-fit basis-full  self-center lg:basis-2/3 items-center">
          <Flutterwave className="h-auto w-24" />
          <span className="-ml-12">{text}</span>
        </span>
      </FlutterWaveButton>
    );
  }
};

export default FlutterWavePayment;
