/* eslint-disable @typescript-eslint/no-empty-interface */
import { NextFont } from 'next/dist/compiled/@next/font';
import React, { type FC } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { FeaturesTopLeft } from './icons';
import Link from 'next/link';

export interface INominationHeroProps {
  font: NextFont;
}

const NominationHero: FC<INominationHeroProps> = ({ font }) => {
  return (
    <section
      className={`relative flex h-full w-full flex-col items-center justify-between space-y-8 overflow-hidden bg-darkerPurple px-8 py-16 text-white lg:flex-row lg:space-y-0 lg:px-12 lg:py-12 ${font.className}`}
    >
      <div className="z-10 flex lg:w-11/12 w-full m-auto flex-col items-center bg-golden relative">
        <iframe
          className="grid h-[480px] w-full place-content-center bg-black text-white"
          src="https://www.youtube.com/embed/do16sAt6Uqw?si=0DTUkPxYO1r7xWHW0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        {/* <div className="absolute bottom-0 left-0 right-0 bg-black/[0.35] p-6 backdrop-blur-md">
          <p className="text-left text-xs text-white lg:text-2xl">
            Hear directly from Jared Mwanduka, a Kenyan winner of The Connected
            Awards, as he shares insights into the entire journey and displays
            all the awarded gift items.
          </p>
        </div> */}
      </div>
      <span className="absolute -top-20 left-0 z-0 hidden transition-transform duration-300 ease-in hover:rotate-12 hover:scale-110 lg:block">
        <FeaturesTopLeft className="h-auto w-56" />
      </span>
      <span className="absolute inset-0 z-0 m-auto hidden translate-x-[25%] translate-y-[30%] transition-transform duration-300 ease-in hover:scale-110 lg:block">
        <FeaturesTopLeft className="h-auto w-56" />
      </span>
      <span className="absolute -bottom-24 right-[40%] z-0 m-auto hidden transition-transform duration-300 ease-in hover:scale-110 lg:block">
        <FeaturesTopLeft className="h-auto w-56" />
      </span>
    </section>
  );
};
export default NominationHero;
