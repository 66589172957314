import React, { type FC } from 'react';

import type { Props } from '../typescript';

const Loader: FC<Props> = () => {
  return (
    <div className="flex h-screen w-full flex-1 items-center justify-center bg-darkPurple">
      <div className="flex gap-8 ">
        <span className="animate-bouncing h-8 w-8 rounded-full bg-golden"></span>
        <span className="animate-bouncing delays-300 h-8 w-8 rounded-full bg-golden"></span>
        <span className="animate-bouncing delays-500 h-8 w-8 rounded-full bg-golden"></span>
      </div>
    </div>
  );
};

export default Loader;
