import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const TopLeftGroup: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="174"
      height="251"
      viewBox="0 0 174 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.8591 64.3073L87.8501 64.2978L87.8409 64.2893L78.8477 55.8812L78.8419 55.8756L78.8351 55.8698L69.4843 47.9579L69.4785 47.9523L69.327 48.1258L69.4639 47.9404L59.7778 40.5715L59.77 40.5654L59.762 40.5603L49.7739 33.8077L49.3774 34.3767L69.0299 48.4825L104.101 82.8971L118.865 102.515L119.425 102.106L112.31 92.1096L112.305 92.102L112.299 92.0945L104.634 82.452L104.452 82.593L104.622 82.4386L96.466 73.1736L96.4604 73.167L96.4536 73.1612L87.8591 64.3073Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.5191 98.6265L55.5183 97.7987L54.9293 98.3795L49.7305 103.499L49.3751 92.9857L49.3476 92.1863L48.7829 92.7525L43.0636 98.4843L42.8069 98.7408L43.0745 98.9858L49.1688 104.549L54.9208 110.485L60.3 116.767L60.8779 117.442L60.9098 116.553L61.3361 104.64L61.3671 103.78L60.7478 104.379L55.524 109.434L55.5191 98.6265Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M131.137 123.488L131.725 123.12L119.442 102.132L119.432 102.115L119.42 102.098L104.639 82.4596L104.073 82.8606L131.137 123.488Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.2301 104.878L61.48 104.636L61.238 104.387L55.6605 98.6319L55.6753 98.6181L55.4137 98.3776L54.8251 97.7702L54.8247 97.8382L49.2624 92.7413L48.6533 92.1833L48.6815 93.0087L49.0634 104.313L49.0901 105.103L49.6541 104.548L54.8258 99.4544L54.8299 110.252L54.8302 111.069L55.4183 110.501L61.2301 104.878ZM66.7792 110.986L66.8475 110.088L66.195 110.709L60.3237 116.289L60.1005 116.503L60.2864 116.749L65.2416 123.338L65.7958 124.075L65.8641 123.156L66.7792 110.986Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.147 82.9396L104.605 82.4201L69.4969 47.9693L69.4704 47.9432L69.4384 47.9228L28.9769 22.4899L28.5771 23.056L104.147 82.9396Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5716 87.3187L42.2013 87.0182L42.206 87.0823L36.2545 82.8561L36.1986 82.2619L35.8816 82.591L35.5086 82.3259L35.5643 82.9211L30.015 88.6777L29.7341 88.9694L30.0655 89.2025L36.5835 93.777L36.639 94.3608L36.9417 94.0518L42.9731 98.9039L43.0128 99.5178L43.3345 99.195L43.7062 99.4938L43.6655 98.8632L49.039 93.4771L54.9301 98.8754L60.6372 104.764L60.2169 116.529L60.1866 117.377L60.8025 116.792L66.0206 111.831L65.1834 122.969L65.0692 123.076L65.1648 123.221L65.1062 123.99L65.454 123.665L69.6985 130.174L70.2212 130.976L70.3334 130.026L71.7859 117.75L71.9216 117.623L71.8168 117.486L71.9118 116.683L71.5004 117.068L66.8982 110.998L66.9121 110.983L66.7856 110.838L67.249 98.9207L67.2831 98.0563L66.6612 98.6577L61.3394 103.807L61.381 92.8551L61.3846 92.0223L60.7905 92.6069L55.4962 97.82L55.1941 87.1552L55.1712 86.3498L54.6014 86.9199L49.0039 92.5305L43.1137 87.7569L43.1314 87.7397L42.9344 87.5998L42.895 86.9892L42.5716 87.3187Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M131.126 123.471L131.731 123.13L125.942 112.473L125.937 112.463L125.931 112.455L119.436 102.122L118.844 102.482L131.126 123.471Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M67.1439 99.1563L67.3935 98.9155L67.1518 98.6656L61.2828 92.6121L60.6899 92.0016L60.6868 92.8531L60.6424 104.627L60.6393 105.45L61.2303 104.878L67.1439 99.1563Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M69.0694 48.5108L69.4561 47.9352L49.7818 33.8136L49.7643 33.801L49.7454 33.7901L28.9582 22.4788L28.6079 23.0779L69.0694 48.5108Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.7054 93.6645L61.029 93.3464L66.5502 99.0421L66.093 110.806L65.9701 110.923L66.0827 111.07L66.0543 111.8L66.3922 111.478L71.0952 117.682L69.6603 129.816L69.5534 129.914L69.6316 130.056L69.5349 130.873L69.8997 130.539L73.55 137.137L73.4077 137.997L73.8201 137.624L74.0906 138.114L74.2338 137.25L80.2393 131.83L80.4456 131.644L80.3089 131.402L76.4381 124.535L76.4539 124.521L76.3655 124.388L76.5085 123.529L76.4566 123.576L77.7413 112.83L77.7724 112.87L77.833 112.102L77.9644 111.98L77.8588 111.842L77.9548 111.039L77.9143 111.077L78.8315 99.5315L78.903 98.6289L78.2466 99.2527L72.8223 104.409L72.7898 104.44L73.2605 93.2379L73.411 93.093L73.2722 92.9512L73.303 92.2323L72.9271 92.5952L67.4874 86.9857L67.5013 86.9717L67.3471 86.8292L67.3527 86.1452L67.0045 86.4881L66.6586 86.1308L66.6584 86.1937L61.1117 81.0759L61.0962 80.4104L60.7581 80.7497L60.403 80.4211L60.4175 81.0905L54.8366 86.6846L54.4769 86.3537L54.4787 86.417L48.8976 81.8586L48.9143 81.8411L48.7202 81.702L48.6844 81.0851L48.3572 81.4178L47.9915 81.1195L47.9947 81.1811L42.0146 76.8839L41.9637 76.2817L41.6414 76.6156L41.2735 76.3508L41.3243 76.9447L35.8679 82.6059L29.3658 78.629L29.2975 78.0637L28.9831 78.3944L28.6112 78.1667L28.678 78.7172L23.1486 84.5509L22.852 84.8645L23.221 85.0874L29.9412 89.128L30.0085 89.6848L30.3238 89.3584L30.6948 89.5817L30.6297 89.0409L35.9669 83.5025L42.2668 87.9763L42.3179 88.5693L42.6262 88.2545L48.6911 93.1706L48.7272 93.7908L49.0529 93.4638L49.4198 93.7614L49.384 93.1321L54.5236 87.9814L54.826 98.637L54.8481 99.433L55.4162 98.8738L60.7054 93.6645Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.335 83.0131L105.473 83.1053L104.577 82.3955L29.0077 22.5118L28.5429 23.0246L85.0263 81.3717L85.1173 81.4655L85.2478 81.4754L104.335 83.0131Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.6156 75.8661L54.5756 75.0759L54.0215 75.6407L48.1355 81.6441L47.8698 81.9159L48.1635 82.1561L54.6279 87.4336L55.233 87.9286L55.1928 87.1477L54.6156 75.8661ZM78.7239 99.7553L78.964 99.5276L78.7473 99.2767L73.1817 92.8578L72.6092 92.1987L72.5731 93.0709L72.0606 105.267L72.0241 106.125L72.6456 105.534L78.7239 99.7553ZM82.3532 119.104L82.5724 118.903L82.4073 118.656L77.7858 111.749L77.2641 110.968L77.1534 111.9L75.6562 124.425L75.546 125.355L76.2358 124.721L82.3532 119.104Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.483 82.9934L104.39 82.3222L85.3035 80.7844L84.4529 80.7156L85.015 81.3587L90.7058 87.8665L90.8638 88.0471L91.0876 87.9638L104.483 82.9934ZM49.4129 34.4L49.7553 33.7959L39.4898 27.7419L39.4799 27.7363L39.469 27.7305L28.9472 22.4731L28.6268 23.0889L49.4129 34.4Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.0139 81.4752L61.2832 81.2054L60.9882 80.9628L54.4905 75.6162L53.8833 75.1165L53.9236 75.9016L54.5008 87.1832L54.5403 87.963L55.0925 87.4101L61.0139 81.4752ZM80.1518 132.536L80.1884 132.6L80.3479 131.638L80.5756 130.592L80.5119 130.649L82.4617 118.905L82.6267 117.912L81.885 118.593L75.7666 124.21L75.5635 124.396L75.6997 124.636L79.5688 131.501L73.6791 136.816L73.4913 136.987L73.5957 137.217L76.9004 144.511L77.3328 145.464L77.5557 144.442L80.1518 132.536ZM29.1004 79.2792L35.7437 83.3429L36.3474 83.7119L36.2691 83.0091L35.1289 72.7413L35.0462 71.9982L34.5317 72.5409L28.9686 78.4105L22.1465 74.9154L21.5469 74.6083L21.6456 75.275L23.0567 84.8404L23.1609 85.5466L23.6517 85.0289L29.1004 79.2792Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.648 82.8646L105.271 81.9605L104.242 82.3429L90.8468 87.3133L90.4017 87.4785L90.6949 87.8525L96.0002 94.6168L96.291 94.9881L96.5587 94.6004L104.648 82.8646Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.3316 75.1738L22.2261 74.4601L21.7349 74.9879L16.1013 81.0286L15.7848 81.3681L16.1999 81.5757L23.2447 85.0995L23.8401 85.3984L23.7436 84.7395L22.3316 75.1738ZM84.0067 106.098L84.1039 105.295L83.6875 105.684L78.8372 99.3927L79.3897 87.1765L79.4289 86.3041L78.8023 86.9116L73.2772 92.2569L73.4159 81.0062L73.426 80.1637L72.8261 80.7551L67.3321 86.1658L67.1408 75.1844L67.1274 74.3659L66.5489 74.9462L60.5229 80.9858L60.2677 81.2407L60.5334 81.4852L66.757 87.2283L72.6638 93.32L78.1277 99.6219L77.1622 111.779L77.0453 111.888L77.1422 112.033L77.0808 112.804L77.4364 112.473L81.8311 119.041L82.3514 119.819L82.4635 118.89L83.9751 106.366L84.1125 106.236L84.0067 106.098ZM36.1741 83.2873L41.7248 77.5289L48.05 82.075L48.0962 82.675L48.4225 82.3421L48.7869 82.604L48.7414 82.0176L54.5172 76.1257L54.7988 75.8394L54.4744 75.6029L47.8772 70.8045L47.8305 70.1941L47.5038 70.5331L47.14 70.2682L47.1865 70.8632L41.6275 76.6297L34.9674 72.4845L34.3606 72.1075L34.4391 72.8176L35.5792 83.0854L35.661 83.82L36.1741 83.2873Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.704 82.7269L104.077 82.4708L95.9869 94.2063L95.851 94.4043L95.9884 94.6014L100.852 101.586L101.336 102.28L101.479 101.447L104.704 82.7269Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M79.2982 86.9263L78.7349 86.2798L78.7325 86.3431L73.5574 81.0078L73.571 80.9947L73.3116 80.7538L72.7327 80.1571L72.7311 80.2175L67.294 75.1806L67.3078 75.1666L67.0227 74.9296L66.4332 74.3838L66.4342 74.4417L60.6002 69.6038L60.5722 68.9599L60.2362 69.3028L59.8793 69.0067L59.9073 69.6382L54.0215 75.6407L53.7573 75.9108L54.0488 76.1515L60.4275 81.4004L60.4561 82.0341L60.791 81.6992L61.1485 81.9934L61.1209 81.3682L66.4614 76.015L66.6513 86.9858L66.6661 87.7944L67.2413 87.2277L72.7118 81.841L72.5726 93.0812L72.562 93.9133L73.1607 93.3349L78.6565 88.0164L78.1449 99.3492L78.0193 99.4684L78.1322 99.6151L78.0997 100.349L78.4454 100.021L83.2837 106.298L81.79 118.68L81.6805 118.781L81.7565 118.914L79.6845 131.396L79.5836 131.487L79.6429 131.613L76.8771 144.294L76.6508 145.335L77.4465 144.627L83.2848 139.443L83.5231 139.955L83.7175 139.06L83.8261 138.963L83.7663 138.835L86.3578 126.927L86.393 126.988L86.5322 126.149L86.6502 126.043L86.5769 125.916L86.778 124.993L86.7155 125.05L88.668 113.307L88.8006 113.185L88.711 113.052L88.8535 112.195L88.4073 112.604L84.0008 106.104L85.0057 93.7846L85.1504 93.6465L85.0286 93.5069L85.0904 92.7463L84.6946 93.1227L79.5201 87.1815L79.5337 87.1684L79.2982 86.9263Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.709 82.653L104.02 82.6091L100.794 101.33L100.773 101.457L100.84 101.569L105.203 108.741L105.846 108.546L104.709 82.653Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M34.8407 73.223L41.3547 77.2761L41.4121 77.8521L41.7374 77.5147L42.0986 77.7398L42.0442 77.1975L47.5831 71.4497L53.9345 76.0682L53.9764 76.6775L54.3079 76.3396L54.6663 76.6011L54.6259 76.0149L60.2851 70.2435L66.5649 75.4512L72.7239 81.1554L72.7311 81.821L73.063 81.4945L78.6931 87.2989L78.6819 87.9927L79.0215 87.6641L84.3125 93.7415L83.3107 106.036L83.1914 106.147L83.2893 106.292L83.2267 107.064L83.5854 106.728L87.9806 113.212L85.8689 125.907L83.0881 138.69L76.9863 144.108L76.8118 144.262L76.8888 144.483L79.4186 151.719L79.7782 152.748L80.0798 151.7L83.7083 139.067L89.9306 133.543L90.1264 133.369L90.014 133.134L86.6344 126.057L92.7573 120.53L92.9662 120.341L92.8239 120.097L88.7858 113.198L94.9187 107.569L95.1407 107.364L94.9696 107.117L90.3578 100.403L90.3711 100.391L90.2643 100.253L90.3625 99.4495L90.3237 99.4852L91.3017 87.7987L91.4476 87.6598L91.3248 87.52L91.3888 86.7589L90.9903 87.1377L85.7541 81.1503L85.7665 81.1379L85.6296 80.9966L85.6641 80.2714L85.2961 80.627L84.9707 80.2542L84.968 80.3143L79.7359 74.9225L79.7492 74.9104L79.5971 74.7684L79.608 74.0755L79.2529 74.4249L78.9141 74.0766L78.9134 74.1331L73.2732 68.8901L73.2645 68.2139L72.9184 68.5605L72.5711 68.2379L72.5791 68.9005L67.1108 74.3815L66.7064 63.5397L66.6763 62.7345L66.1113 63.3099L60.2239 69.3151L53.8438 64.6172L53.8014 63.9976L53.4703 64.3417L53.1115 64.0781L53.1521 64.6715L47.4898 70.5463L40.9564 66.41L40.8973 65.8243L40.5728 66.167L40.2112 65.9388L40.2667 66.4895L34.7121 72.3499L28.0425 68.8506L27.9686 68.3035L27.6494 68.6455L27.2887 68.4555L27.3569 68.959L21.8979 74.8123L15.1089 72.0356L15.0215 71.5305L14.7076 71.8717L14.3513 71.7261L14.4289 72.1753L8.90986 78.1868L8.56789 78.5581L9.03683 78.744L16.0455 81.5157L16.1319 82.0124L16.447 81.6746L16.8014 81.8155L16.7255 81.376L22.0636 75.6523L28.7222 79.0634L28.7951 79.602L29.1143 79.2642L29.4743 79.4488L29.4078 78.955L34.8407 73.223Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M79.0046 75.1659L72.6919 69.2966L72.6844 69.2895L72.6768 69.2825L28.5438 23.025L28.5421 23.0235L29.0315 22.5529L29.0317 22.552L79.0046 75.1659ZM79.0046 75.1659L29.0317 22.552L79.0046 75.1659ZM79.0046 75.1659L85.0272 81.3721L85.5248 80.8891L85.525 80.8882L29.0487 22.55L29.0438 22.5447L79.0046 75.1659ZM29.0317 22.552L29.0421 22.5432L29.0317 22.552ZM104.651 82.4758L104.344 82.6519L104.016 82.6834L105.153 108.576L105.157 108.653L105.191 108.721L108.983 116.049L109.634 115.839L105.035 84.8347L143.379 163.344L144.022 163.09L131.758 123.193L131.743 123.145L131.715 123.103L104.651 82.4758Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M73.1731 69.2877L73.441 69.0196L73.15 68.777L66.582 63.2871L65.9842 62.7867L66.0127 63.5657L66.4474 75.2035L66.4768 75.9992L67.0398 75.4357L73.1731 69.2877ZM86.2584 146.371L86.4375 146.215L86.3561 145.992L83.7293 138.756L83.3653 137.753L83.0701 138.78L79.4132 151.509L79.0878 152.641L79.9747 151.865L86.2584 146.371ZM92.6749 121.216L92.7096 121.276L92.8659 120.339L93.0925 119.293L93.0302 119.349L95.0261 107.37L95.1916 106.375L94.4497 107.057L88.1107 112.877L87.9049 113.065L88.0467 113.307L92.0831 120.204L85.9778 125.715L85.786 125.889L85.8968 126.122L89.3883 133.432L89.8247 134.348L90.0399 133.357L92.6749 121.216ZM40.6836 67.0583L47.3585 71.2845L47.9522 71.6603L47.8887 70.9601L46.9237 60.3042L46.8545 59.5398L46.3262 60.0965L40.5588 66.1821L33.8833 62.5948L33.8148 62.0346L33.4898 62.3833L33.1353 62.1929L33.1973 62.6969L27.4694 68.8387L27.1587 69.1715L27.5617 69.3822L34.462 73.0019L34.5289 73.5524L34.8548 73.208L35.2083 73.3941L35.1481 72.8998L40.6836 67.0583Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.0435 22.5446L28.5367 23.0191L28.5408 23.0232L66.1046 63.7874L66.1199 63.8047L66.1369 63.8192L72.7049 69.309L73.1783 68.8035L29.0477 22.5487L29.0435 22.5446Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.635 94.302L96.7334 93.4979L96.3128 93.8909L91.2406 87.4238L90.6954 86.7293L90.6215 87.6085L89.5687 100.191L89.4477 100.304L89.547 100.447L89.482 101.22L89.8446 100.882L94.3987 107.509L94.9165 108.264L95.0287 107.355L96.6022 94.57L96.742 94.4389L96.635 94.302ZM53.7595 65.0425L54.0518 64.7382L53.6975 64.5097L46.766 60.0433L46.1685 59.6583L46.2332 60.366L47.1979 71.0229L47.2673 71.7781L47.793 71.232L53.7595 65.0425ZM90.0343 133.379L90.3627 132.231L89.4704 133.024L83.1728 138.616L82.9968 138.772L83.0777 138.993L85.7043 146.229L86.0699 147.234L86.3634 146.206L90.0343 133.379Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M139.61 144.286L140.258 144.037L136.583 133.806L136.579 133.794L136.572 133.781L131.742 123.152L131.105 123.426L139.61 144.286Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.9763 69.3113L28.3149 68.9495L27.8581 68.7562L20.85 65.7919L20.7672 65.2725L20.4473 65.6214L20.0967 65.4733L20.1686 65.9251L14.5448 72.0506L14.2072 72.4168L14.6691 72.6062L21.6762 75.4712L21.757 75.982L22.0791 75.6366L22.427 75.779L22.3578 75.3372L27.9763 69.3113ZM59.5456 58.0391L59.1919 57.7757L59.2274 58.3689L53.2602 64.5614L52.9851 64.8463L53.3038 65.0813L59.9243 69.9556L59.9618 70.574L60.2978 70.2311L60.6513 70.4914L60.6169 69.9057L66.6079 63.7954L66.8857 63.5122L66.5676 63.2752L59.92 58.319L59.882 57.6901L59.5456 58.0391Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.0478 22.5487L28.5298 23.0101L59.3204 58.727L59.3452 58.7559L59.3757 58.7779L66.1526 63.8313L66.6145 63.3178L29.0478 22.5487Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.3554 146.231L85.8024 145.849L79.5187 151.344L79.3607 151.481L79.4098 151.685L81.0621 158.596L81.3265 159.702L81.725 158.637L86.3554 146.231ZM99.1862 114.728L99.3975 114.537L99.2514 114.292L94.9819 107.135L94.498 106.323L94.3427 107.256L92.1846 120.208L89.3627 133.209L89.1367 134.248L89.932 133.543L96.3435 127.85L96.5408 127.674L96.4248 127.437L92.9516 120.356L99.1862 114.728ZM15.0558 72.519L15.4305 72.1116L14.9002 71.9526L7.84128 69.8372L7.3042 69.6765L7.39964 70.228L8.82364 78.4806L8.94392 79.1763L9.42089 78.6562L15.0558 72.519Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.702 82.6009L104.019 82.7192L108.947 115.94L108.954 115.984L108.971 116.024L112.018 123.244L112.678 123.041L104.702 82.6009Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M101.502 101.306L101.645 100.451L101.195 100.865L96.5573 94.2042L96.0387 93.4606L95.9278 94.3605L94.3548 107.144L94.2411 107.248L94.3246 107.389L94.224 108.206L94.6048 107.856L98.5091 114.403L92.292 120.015L92.0978 120.19L92.2132 120.426L95.801 127.742L96.2403 128.638L96.4514 127.663L99.1059 115.403L99.1392 115.46L99.2945 114.535L99.5199 113.491L99.4588 113.545L101.46 101.564L101.595 101.439L101.502 101.306ZM8.08286 70.1108L7.96212 69.4087L7.48418 69.9368L1.83768 76.1742L1.46371 76.5869L2.00056 76.7404L9.07008 78.7553L9.60037 78.9061L9.50713 78.3624L8.08286 70.1108ZM92.6563 140.778L92.8379 140.62L92.7529 140.395L90.0256 133.161L89.6559 132.181L89.3676 133.188L85.6968 146.015L85.3738 147.145L86.2583 146.371L92.6563 140.778Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.055 22.5578L28.5174 22.9947L52.3611 54.1538L52.3968 54.2009L52.4465 54.2329L59.3924 58.7904L59.8458 58.2737L29.055 22.5578Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.6322 60.7829L53.1774 65.0004L53.2258 65.5958L53.561 65.2476L53.9118 65.4731L53.8681 64.9294L59.8323 58.7403L60.1227 58.4397L59.7728 58.2102L52.9687 53.7457L52.9198 53.1399L52.5841 53.4942L52.2342 53.2648L52.2789 53.817L46.5106 59.9023L39.8302 56.2231L39.7668 55.6508L39.4359 56.0063L39.087 55.8142L39.1431 56.3208L33.4745 62.3991L26.6944 59.4339L26.6184 58.8997L26.2902 59.2569L25.9474 59.1065L26.0115 59.5605L20.4304 65.6389L13.595 63.4866L13.0628 63.3188L13.1478 63.8708L14.4575 72.3381L14.5673 73.0508L15.0555 72.5191L20.6269 66.4506L27.4076 69.318L27.4822 69.8416L27.8113 69.4887L28.1534 69.6338L28.0898 69.1902L33.6319 63.2474L40.3031 66.8315L40.3658 67.3944L40.6973 67.0442L41.0455 67.231L40.9908 66.735L46.6322 60.7829ZM96.4461 127.685L96.7726 126.541L95.882 127.331L89.4704 133.024L89.2918 133.182L89.3763 133.405L92.0185 140.414L86.2277 145.477L86.0934 144.949L85.7361 145.907L85.6413 145.99L85.6675 146.091L81.074 158.394L81.6255 158.778L87.5832 153.641L87.7191 154.174L88.0755 153.216L88.1712 153.134L88.1443 153.031L92.4306 141.506L92.474 141.621L92.7618 140.611L96.4461 127.685ZM105.732 108.819L105.944 108.626L105.795 108.381L101.433 101.208L100.948 100.411L100.795 101.331L98.6108 114.413L98.4477 115.394L99.1858 114.728L105.732 108.819Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.0674 22.5732L28.4975 22.966L45.2641 50.1316L45.3112 50.2072L45.3889 50.2513L52.466 54.2449L52.9122 53.7326L29.0674 22.5732Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M102.873 122.053L103.071 121.876L102.952 121.637L99.2635 114.314L98.8223 113.438L98.6153 114.397L95.7741 127.517L95.5495 128.554L96.3435 127.85L102.873 122.053Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.702 82.5945L104.021 82.7352L104.023 82.7408L111.998 123.176L112.001 123.195L112.008 123.214L114.189 130.058L114.859 129.88L104.702 82.6009L104.702 82.5945Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M13.8342 63.7647L13.7229 63.0454L13.2338 63.5851L7.48453 69.937L7.11614 70.344L7.64256 70.502L14.7005 72.6171L15.2269 72.7751L15.1429 72.2317L13.8342 63.7647ZM99.1721 135.082L99.3553 134.921L99.2667 134.694L96.4366 127.464L96.061 126.507L95.7796 127.495L92.0952 140.421L91.7737 141.55L92.6567 140.778L99.1721 135.082ZM105.839 108.635L106.066 107.583L105.268 108.304L98.7208 114.213L98.5252 114.39L98.6435 114.626L102.333 121.949L102.774 122.826L102.981 121.867L105.839 108.635Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M28.6772 23.1106L28.9225 22.4616L18.503 18.2532L18.488 18.2474L18.4727 18.2426L8.52949 15.27L8.31454 15.9296L28.6772 23.1106Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M88.065 153.226L94.5087 147.668L94.674 147.526L94.6171 147.316L92.7637 140.426L92.4817 139.379L92.1035 140.396L87.4882 152.807L81.1733 158.253L81.0329 158.374L81.0553 158.556L81.8302 165.097L81.9741 166.31L82.489 165.204L88.065 153.226ZM52.8888 54.1822L53.194 53.8594L52.8072 53.6414L45.8866 49.7353L45.8292 49.1511L45.4918 49.5122L45.1492 49.3196L45.1991 49.8267L39.4218 56.0222L32.6479 52.9576L32.5775 52.4093L32.2433 52.7743L31.9051 52.6219L31.9643 53.0788L26.1234 59.4388L25.795 59.797L26.2402 59.9915L33.2422 63.0542L33.3112 63.5919L33.6474 63.2315L33.9829 63.378L33.9261 62.932L39.5751 56.8746L46.2512 60.5507L46.3078 61.1255L46.6462 60.7687L46.9878 60.957L46.9389 60.4583L52.8888 54.1822ZM7.99909 70.4022L8.41863 69.9389L7.80322 69.8277L1.38861 68.6748L1.32136 68.1798L0.949808 68.5953L0.670624 68.5453L0.714018 68.8591L-4.84545 75.078L-5.26692 75.549L-4.64304 75.6509L1.78632 76.7076L1.85307 77.1921L2.22609 76.779L2.50309 76.8255L2.46091 76.5191L7.99909 70.4022ZM102.975 121.888L103.3 120.744L102.411 121.534L95.8831 127.331L95.702 127.491L95.79 127.717L98.6203 134.946L98.9963 135.906L99.2773 134.915L102.975 121.888Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M29.087 22.6018L28.4703 22.9139L38.0668 46.7234L38.1175 46.8505L38.2413 46.9081L45.4116 50.2632L46.4648 50.7556L45.8539 49.7665L29.087 22.6018Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M99.2688 134.941L98.7148 134.559L92.2004 140.255L92.0371 140.398L92.0932 140.606L93.9469 147.495L94.2291 148.546L94.607 147.526L99.2688 134.941Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.43 163.42L144.002 163.039L104.674 82.5156L104.023 82.7408L114.181 130.026L114.2 130.114L114.259 130.182L143.43 163.42Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M26.6339 59.9083L26.9972 59.5135L26.488 59.3443L19.4556 57.0191L18.9272 56.8441L19.0033 57.3949L20.1928 66.0836L20.293 66.8146L20.7922 66.2712L26.6339 59.9083ZM109.521 116.149L109.722 115.969L109.599 115.73L105.808 108.402L105.364 107.545L105.16 108.488L102.302 121.72L102.079 122.757L102.871 122.053L109.521 116.149Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.359 163.293L144.031 163.124L140.271 144.088L140.265 144.055L140.253 144.024L131.747 123.165L131.094 123.397L143.359 163.293Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M19.6901 57.3012L19.5887 56.5628L19.0892 57.1154L13.3644 63.4393L7.1654 62.2237L7.10515 61.7088L6.72319 62.1372L6.45102 62.0837L6.48761 62.4L0.819589 68.7402L0.406365 69.203L1.01692 69.3129L7.42642 70.4656L7.48546 70.9689L7.86936 70.5451L8.13983 70.593L8.10421 70.2852L13.5988 64.2151L20.4322 66.367L20.9549 66.5313L20.8799 65.989L19.6901 57.3012ZM88.3972 160.21L88.4708 160.758L88.9389 159.748L89.0252 159.675L89.0131 159.587L94.5649 147.62L101.129 141.959L101.296 141.815L101.236 141.602L99.3367 134.936L105.441 129.6L105.637 130.085L105.885 129.212L105.993 129.117L105.944 128.998L109.625 115.984L109.947 114.843L109.061 115.63L102.778 121.208L102.583 120.729L102.336 121.601L102.227 121.696L102.275 121.813L98.6133 134.713L93.9869 147.201L88.0406 152.33L87.9682 151.79L87.4986 152.797L87.413 152.872L87.4247 152.957L81.8598 164.911L82.3988 165.32L88.3972 160.21ZM45.8121 50.1858L46.1381 49.8373L45.7062 49.6348L38.7113 46.3615L38.6474 45.7994L38.3052 46.1717L37.9738 46.0169L38.0252 46.476L32.0721 52.9595L31.7481 53.3126L32.1852 53.5102L39.1825 56.6748L39.2457 57.2275L39.5889 56.8596L39.9188 57.0078L39.8676 56.5601L45.8121 50.1858Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M31.0454 44.0855L31.0713 44.296L31.2717 44.3687L38.2693 46.9202L39.0017 47.1862L38.7106 46.4644L29.1144 22.6539L28.448 22.8258L31.0454 44.0855Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M108.957 115.795L105.273 128.826L98.7143 134.56L98.5495 134.704L98.6091 134.916L100.508 141.579L94.5313 146.733L94.4529 146.213L93.9985 147.192L93.9106 147.268L93.923 147.355L88.3731 159.318L81.949 164.793L81.83 164.894L81.827 165.051L81.7184 171.166L82.3669 171.344L88.9273 159.757L95.0094 154.576L95.0881 155.104L95.5422 154.121L95.6309 154.046L95.6178 153.959L100.825 142.691L100.859 142.814L101.196 141.901L107.874 136.142L108.044 135.995L107.979 135.78L105.975 129.133L112.566 123.37L112.755 123.205L112.657 122.974L109.61 115.755L109.226 114.844L108.957 115.795ZM32.5837 53.4292L32.9405 53.04L32.4417 52.8661L25.6305 50.5013L25.5654 49.9605L25.2084 50.3548L24.901 50.2483L24.949 50.6427L19.223 56.9674L13.0508 55.6523L12.9988 55.1168L12.6057 55.5568L12.3394 55.5007L12.3699 55.8205L6.59163 62.2848L6.18569 62.7391L6.78313 62.8561L13.1699 64.1082L13.2205 64.633L13.616 64.1957L13.8804 64.2472L13.8506 63.9368L19.4518 57.7489L26.0604 59.9344L26.124 60.4646L26.4822 60.0736L26.7879 60.1745L26.7423 59.7911L32.5837 53.4292ZM1.33728 69.2022L1.82443 68.6579L1.09364 68.6251L-4.82054 68.3586L-4.86258 67.8534L-5.29129 68.337L-5.51953 68.3265L-5.49925 68.5717L-11.096 74.8988L-11.5868 75.4535L-10.846 75.4753L-4.90718 75.6471L-4.86635 76.1406L-4.43641 75.6603L-4.20938 75.6675L-4.22942 75.4295L1.33728 69.2022Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M24.4887 42.3375L24.4232 42.6623L24.7444 42.7423L31.3063 44.3793L31.7963 44.5014L31.7346 44.0006L29.1369 22.7419L28.453 22.7157L24.4887 42.3375Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M88.2986 166.369L88.2973 166.945L88.9175 165.846L88.9931 165.782L88.9931 165.712L95.5615 154.078L95.034 153.643L89.004 158.781L89.0054 158.213L88.3849 159.309L88.3093 159.373L88.3096 159.442L81.7629 171.001L82.289 171.438L88.2986 166.369ZM112.664 123.228L112.109 122.848L105.35 128.757L105.182 128.905L105.247 129.119L107.315 135.98L107.615 136.974L107.972 135.999L112.664 123.228ZM38.6437 46.8281L38.9956 46.4459L38.5072 46.2674L31.5094 43.7169L30.9888 43.5273L31.0451 44.0783L31.9835 53.2291L32.0621 53.9969L32.5841 53.4283L38.6437 46.8281Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M28.7115 23.1213L28.908 22.4571L8.54457 15.2749L8.50843 15.2628L8.46938 15.2582L-9.71733 13.1688L-9.8379 13.8509L28.7115 23.1213Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M7.10953 62.7468L7.58577 62.2131L6.8722 62.1692L0.683141 61.7857L0.29242 61.7614L0.316425 62.1529L0.732274 68.9926L0.783024 69.8225L1.33754 69.2022L7.10953 62.7468ZM31.735 44.0071L31.6559 43.233L31.1326 43.8106L25.055 50.5248L24.7035 50.9133L25.1987 51.0852L32.2146 53.5217L32.7287 53.7003L32.673 53.1588L31.735 44.0071Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.35 163.258L144.034 163.144L142.767 153.888L142.765 153.869L142.759 153.85L140.267 144.07L139.591 144.223L143.35 163.258Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M101.746 148.836L101.831 149.343L102.27 148.389L102.361 148.311L102.346 148.223L107.93 136.093L114.746 130.216L114.92 130.066L114.85 129.848L112.668 123.004L112.361 122.04L112.012 122.99L107.352 135.676L101.143 141.031L101.059 140.531L100.619 141.483L100.528 141.561L100.542 141.648L94.9509 153.749L88.3751 165.396L81.841 170.907L81.7455 170.989L81.7239 171.112L80.7187 176.736L81.3475 176.992L88.9068 165.856L95.5758 160.23L95.7021 160.124L95.6988 159.959L95.6091 154.065L101.746 148.836ZM1.00807 62.1109L0.956816 61.2747L0.402563 61.9022L-5.31145 68.3608L-10.8984 68.989L-11.208 69.0241L-11.2061 69.3348L-11.1822 75.1297L-11.1792 76.0395L-10.5765 75.358L-4.99039 69.0448L1.06324 69.3183L1.44863 69.335L1.4251 68.9499L1.00807 62.1109ZM25.5694 50.991L25.9652 50.5527L25.3903 50.4197L19.048 48.956L19.0054 48.3992L18.5997 48.8525L18.3397 48.7918L18.3651 49.1156L12.4717 55.7067L12.073 56.1525L12.6581 56.2778L19.0212 57.6333L19.0625 58.1784L19.4694 57.7285L19.7288 57.7839L19.7038 57.4706L25.5694 50.991Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.4428 41.525L18.2044 41.9726L18.7079 42.0322L24.7878 42.7502L25.1051 42.7881L25.1687 42.4752L29.1331 22.8524L28.4869 22.6212L18.4428 41.525Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-10.511 69.6432L-5.4957 69.0796L-5.50673 69.6282L-4.9687 69.0203L-4.80016 69.0015L-4.79639 68.8252L0.810008 62.4882L6.5159 62.842L6.53688 63.3862L6.99687 62.8722L7.21201 62.8856L7.20264 62.6421L12.399 56.8295L12.9892 56.1697L13.4552 55.6479L18.8443 49.6205L24.9809 51.037L25.0138 51.6038L25.433 51.1415L25.6853 51.2003L25.6667 50.8823L31.6474 44.2758L32.0359 43.8461L31.4741 43.706L25.1596 42.1307L25.1265 41.5526L24.7094 42.0185L24.4552 41.9551L24.4736 42.2823L18.5811 48.8737L12.6372 48.2885L12.2498 48.2506L12.2569 48.6399L12.3771 55.5616L6.91004 55.1261L6.88906 54.5695L6.43125 55.0872L6.21393 55.0701L6.22356 55.3209L0.493288 61.7991L-4.66172 62.2907L-4.65045 61.7288L-5.18627 62.3403L-5.35727 62.3564L-5.36053 62.5391L-10.5164 68.4164L-11.1208 69.1046L-11.7112 69.7778L-16.853 75.6401L-17.4589 76.3301L-16.5485 76.2128L-10.7914 75.4724L-10.4878 75.4327L-10.4887 75.1264L-10.511 69.6432ZM101.704 147.96L101.635 148.019L95.0341 153.643L94.91 153.748L94.9125 153.912L95.0035 159.806L88.4225 165.356L88.3246 165.438L88.3168 165.49L80.7738 176.602L81.2834 177.063L87.9616 171.475L88.0615 171.393L88.0694 171.34L95.023 161.071L95.025 161.253L95.6241 160.189L101.864 154.925L101.882 155.453L102.459 154.424L102.539 154.357L102.537 154.285L108.676 143.339L108.701 143.473L109.126 142.547L109.22 142.467L116.055 136.643L116.211 136.51L116.17 136.31L114.859 129.884L114.647 128.843L114.235 129.74L107.879 135.222L107.789 134.742L107.364 135.667L107.269 135.747L107.286 135.835L101.704 147.96Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M8.39035 15.9475L8.49129 15.2616L-0.912961 13.5581L-0.936719 13.5543L-0.960013 13.5526L-9.74174 13.1679L-9.79636 13.8581L8.39035 15.9475Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M12.9501 48.6282L12.9345 47.7359L12.3434 48.4037L6.31621 55.2171L5.85572 55.7372L6.54774 55.7932L12.7029 56.2837L13.0837 56.3148L13.0771 55.9318L12.9501 48.6282ZM88.0094 171.427L87.5167 170.943L80.8378 176.531L80.7685 176.59L80.736 176.674L78.8211 181.73L79.4163 182.071L88.0094 171.427Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.45 163.441L143.952 162.963L114.781 129.724L113.919 128.743L114.18 130.023L115.49 136.449L115.511 136.554L115.589 136.629L143.45 163.441Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-10.5991 69.5624L-9.79421 68.6448L-4.84605 63.0053L0.298279 62.5151L0.27197 63.096L0.831586 62.4636L0.995284 62.4477L1.00365 62.2695L6.8357 55.6764L7.40378 55.0348L6.54938 55.1011L1.30359 55.5062L1.33015 54.9119L0.772708 55.548L0.606831 55.5602L0.598693 55.7458L-5.21076 62.3681L-10.2145 63.8157L-10.4292 63.8787L-10.4618 64.1004L-11.172 69.066L-15.6035 70.4213L-15.5348 69.8356L-16.2058 70.6051L-16.3285 70.6425L-16.3415 70.7609L-22.0511 77.3092L-22.8696 78.2481L-21.6833 77.8678L-16.9995 76.3641L-17.0668 76.9358L-16.3955 76.1695L-16.2735 76.1308L-16.2605 76.0166L-11.3681 70.4394L-10.5991 69.5624ZM86.2256 176.392L94.7956 165.757L94.871 165.694L94.879 165.642L102.448 154.434L108.843 149.039L108.87 149.543L109.425 148.548L109.509 148.477L109.505 148.405L116.133 136.548L115.605 136.115L109.152 141.613L109.127 141.117L108.57 142.109L108.488 142.18L108.49 142.251L101.915 153.975L95.1288 159.7L95.0284 159.784L95.0207 159.836L87.4793 170.974L87.4454 171.003L87.433 171.036L78.876 181.635L79.3685 182.119L86.1412 176.486L86.213 176.426L86.2256 176.392ZM25.0869 42.6371L25.5314 42.1399L24.8688 42.0613L19.0962 41.3796L19.098 40.7689L18.6087 41.322L18.4025 41.2974L18.4019 41.5559L12.3435 48.4038L11.8942 48.9122L12.5692 48.9784L18.3742 49.5507L18.3717 50.1478L18.8632 49.5984L19.0674 49.6183L19.0687 49.3693L25.0869 42.6371Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.48 163.467L143.931 162.941L116.07 136.13L115.419 135.503L115.483 136.404L115.91 142.357L115.921 142.514L116.046 142.608L143.48 163.467Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M93.1326 170.646L101.736 159.951L101.813 159.885L108.786 154.052L108.893 153.962L108.908 153.824L109.484 148.499L116.48 142.598L116.614 142.484L116.602 142.308L116.176 136.355L116.093 135.197L115.527 136.209L108.881 148.099L101.963 153.935L101.86 154.022L101.853 154.074L94.2872 165.279L94.2508 165.309L94.2394 165.342L85.6708 175.975L78.9243 181.586L78.8838 181.62L78.8552 181.665L76.0146 186.064L76.5562 186.492L86.1558 176.473L93.0468 170.742L93.121 170.68L93.1326 170.646ZM-9.77411 64.1987L-9.60824 63.0374L-10.3789 63.9212L-16.2317 70.6348L-20.6826 73.122L-20.8036 73.1897L-20.8232 73.2518L-26.6089 79.9152L-26.1749 80.4439L-21.6174 77.8389L-21.4983 77.7707L-21.4802 77.7103L-15.8137 71.211L-10.7576 69.6651L-10.5469 69.6012L-10.5163 69.3831L-9.77411 64.1987ZM12.8638 48.8634L13.4161 48.2394L12.5838 48.2875L7.38596 48.5912L7.42873 47.9608L6.84851 48.6221L6.68749 48.6314L6.67475 48.8189L0.748215 55.576L-4.20055 56.9229L-4.41492 56.9807L-4.45115 57.2005L-5.35808 62.6159L-5.56005 63.8188L-4.75544 62.9022L1.10759 56.218L6.20324 55.8239L6.16121 56.439L6.74237 55.7821L6.90196 55.7703L6.91423 55.5889L12.8638 48.8634Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.523 163.495L143.901 162.915L116.467 142.056L115.966 141.676L115.911 142.302L115.434 147.718L115.415 147.943L115.612 148.052L143.523 163.495Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M116.544 142.526L116.032 142.068L108.93 148.058L108.824 148.148L108.809 148.286L108.218 153.748L108.851 153.979L116.544 142.526ZM-3.76731 57.3142L-3.56462 56.1043L-4.37018 57.0289L-10.3384 63.8745L-14.7289 66.2686L-14.8491 66.3334L-14.8917 66.4629L-16.3395 70.7985L-15.7487 71.1364L-9.92327 64.4541L-4.92047 63.0063L-4.71045 62.9453L-4.67424 62.7297L-3.76731 57.3142ZM19.0086 41.9174L19.5454 41.3102L18.7349 41.3411L13.5874 41.5378L13.6474 40.8715L13.0441 41.559L12.8882 41.5644L12.8714 41.756L6.75239 48.731L6.20539 49.3542L7.03362 49.3061L12.222 49.0032L12.1637 49.6544L12.7679 48.9713L12.9227 48.9625L12.9388 48.7779L19.0086 41.9174ZM83.3894 180.832L83.4323 180.796L83.4427 180.779L93.0752 170.715L92.6037 170.209L85.6971 175.953L85.6554 175.988L85.645 176.005L76.0556 186.011L76.527 186.518L83.3894 180.832Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M141.952 179.683L142.64 179.771L144.014 171.884L144.02 171.854L144.02 171.825L144.038 163.191L143.345 163.162L141.952 179.683Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.089 164.885L100.165 164.821L100.177 164.786L108.812 154.03L116.002 148.014L116.113 147.922L116.125 147.778L116.601 142.363L115.967 142.139L108.303 153.552L101.265 159.44L101.188 159.504L101.177 159.538L92.555 170.259L93.0467 170.742L100.089 164.885ZM-14.2345 66.6825L-14.8256 66.3455L-20.7756 73.1967L-20.3442 73.7278L-15.8408 71.2114L-15.7234 71.1459L-15.6813 71.0183L-14.2345 66.6825ZM7.27394 49.1888L8.01094 48.3492L6.92745 48.6232L1.9335 49.8908L1.71986 49.9458L1.67831 50.1622L0.59909 55.8181L0.3603 57.0695L1.20101 56.1114L7.27394 49.1888Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.6557 42.0221L18.9238 42.0962L19.0551 41.8506L29.0992 22.9469L29.3884 22.4027L28.7726 22.4378L-36.0977 26.1654L-36.1695 26.8463L18.6557 42.0221Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M2.36003 50.293L2.59981 49.0335L1.75713 49.9994L-4.37028 57.0289L-5.12351 57.8924L-4.01799 57.5919L1.03144 56.2175L1.24047 56.1604L1.28105 55.9479L2.36003 50.293Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M28.8122 23.13L28.8734 22.4465L-9.67626 13.1771L-9.79779 13.1478L-9.91051 13.2032L-36.2314 26.2012L-36.0571 26.8579L28.8122 23.13ZM143.572 163.517L143.859 162.888L115.947 147.445L115.568 147.235L115.447 147.651L114.046 152.452L113.955 152.764L114.261 152.875L143.572 163.517Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.3922 175.027L90.4322 174.995L90.4428 174.977L100.104 164.872L107.272 158.911L107.351 158.845L107.362 158.81L116.05 147.965L115.557 147.481L108.341 153.52L108.263 153.585L108.252 153.621L99.6183 164.374L92.6034 170.209L92.5592 170.245L92.5488 170.262L82.9316 180.31L76.0847 185.985L76.0742 185.993L76.0648 186.002L72.2834 189.645L72.5768 189.964L72.745 190.162L79.6894 184.411L79.6996 184.403L79.7037 184.399L90.3922 175.027ZM-18.5541 69.7672L-20.7805 73.1996L-24.515 76.6373L-24.5484 76.6681L-24.5589 76.6851L-30.4395 83.4649L-29.9423 83.9465L-26.1118 80.3967L-26.0786 80.3668L-26.0684 80.3508L-20.2512 73.652L-14.3427 66.8472L-9.95241 64.4541L-9.83556 64.3905L-9.81377 64.328L-3.84824 57.4848L-4.27159 56.9505L-8.65584 59.2781L-8.77559 59.341L-8.79812 59.4065L-14.8116 66.3304L-18.4971 69.7L-18.5303 69.7298L-18.5541 69.7672ZM13.4677 42.1284L14.1807 41.3147L13.1275 41.5623L8.72423 42.5938L8.88842 41.8195L8.08282 42.7441L7.97761 42.7694L7.95004 42.8965L1.7579 49.9996L1.02744 50.8378L2.10461 50.5642L6.5573 49.4337L6.397 50.1884L7.20307 49.2701L7.30783 49.2426L7.33321 49.1201L13.4677 42.1284Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.62 163.531L143.809 162.865L114.498 152.222L114.224 152.123L114.078 152.376L111.726 156.473L111.488 156.889L111.957 156.985L143.62 163.531Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M97.5279 169.113L97.5733 169.076L97.5839 169.058L107.288 158.897L114.6 152.815L114.682 152.747L114.712 152.645L116.113 147.845L115.508 147.531L106.802 158.4L99.6456 164.352L99.6002 164.389L99.5898 164.406L89.9159 174.524L90.3884 175.03L97.5279 169.113ZM-17.9728 70.144L-18.5256 69.7279L-24.5425 76.6655L-24.0455 77.148L-20.2785 73.6792L-20.2465 73.6501L-20.2227 73.6127L-17.9728 70.144ZM2.28028 50.4554L1.85962 49.9187L-2.45995 52.1439L-2.57827 52.2051L-2.60321 52.2721L-8.75558 59.3562L-8.33102 59.8898L-3.94701 57.5631L-3.83088 57.5024L-3.80667 57.4384L2.28028 50.4554Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.7753 178.544L86.7857 178.535L86.7895 178.532L97.5357 169.106L97.0859 168.579L89.9454 174.496L89.935 174.505L89.9321 174.508L79.2398 183.883L79.6895 184.411L86.7753 178.544ZM-8.23213 59.8113L-8.72648 59.3263L-12.3568 62.6189L-12.389 62.649L-12.4135 62.6852L-14.8532 66.3818L-14.3011 66.8008L-8.23213 59.8113Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M134.796 192.514L135.433 192.779L142.601 179.88L142.637 179.814L142.643 179.741L144.037 163.22L143.358 163.094L134.796 192.514Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M104.813 163.075L104.86 163.036L104.87 163.018L114.63 152.788L114.156 152.281L106.828 158.377L106.782 158.416L106.772 158.433L97.0555 168.606L97.528 169.113L104.813 163.075ZM-11.8347 63.0676L-12.3857 62.6488L-18.5255 69.7279L-18.0297 70.2112L-14.3296 66.8287L-14.2976 66.7996L-14.2743 66.7642L-11.8347 63.0676ZM8.53189 43.2837L8.11559 42.746L3.86362 44.8654L3.74481 44.9244L3.69663 45.0474L1.69612 50.0995L2.28039 50.4554L8.53189 43.2837Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.659 163.537L143.761 162.851L112.098 156.306L111.915 156.269L111.783 156.398L108.455 159.692L107.926 160.216L108.666 160.285L143.659 163.537Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M4.34179 45.3031L3.75727 44.9482L-2.56277 52.2255L-2.14187 52.7612L2.17767 50.536L2.29358 50.4763L2.34129 50.3552L4.34179 45.3031ZM94.0051 172.557L94.0156 172.548L94.0197 172.544L104.821 163.068L104.371 162.541L97.0859 168.579L97.0745 168.587L97.0713 168.592L86.3245 178.016L86.775 178.545L94.0051 172.557Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M13.1036 42.2303L13.1608 42.2487L13.2202 42.246L18.762 42.0342L18.8411 41.3531L-35.9841 26.1773L-36.1808 26.8425L13.1036 42.2303Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.03917 52.6806L-2.5323 52.1949L-6.08868 55.3936L-6.12069 55.4228L-6.13329 55.4402L-12.3857 62.6489L-11.8902 63.1331L-8.26084 59.8403L-8.22909 59.8121L-8.21769 59.7954L-2.03917 52.6806ZM112.249 156.912L112.297 156.873L112.328 156.819L114.68 152.721L114.128 152.309L104.341 162.568L104.814 163.075L112.249 156.912Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.683 163.538L143.723 162.846L108.731 159.594L108.625 159.584L108.532 159.635L104.202 162.015L103.07 162.637L104.361 162.666L143.683 163.538Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M101.386 166.446L101.397 166.436L101.4 166.433L112.256 156.907L111.806 156.379L104.371 162.541L104.359 162.55L104.356 162.554L93.5554 172.029L94.0051 172.557L101.386 166.446ZM4.28074 45.4035L3.78782 44.9167L0.309713 48.0165L0.277971 48.0446L0.253484 48.0808L-2.58727 52.2576L-2.03934 52.6808L4.28074 45.4035Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M8.14991 43.3812L8.24792 43.418L8.34979 43.3939L13.2861 42.2377L14.5231 41.9472L13.3097 41.5685L-35.9738 26.1809L-36.1982 26.8371L8.14991 43.3812Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M0.827581 48.4701L0.278464 48.0477L-6.11891 55.4243L-5.62456 55.9092L-2.06818 52.7105L-2.03738 52.6821L-2.01341 52.6479L0.827581 48.4701Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-9.60376 13.8248L-9.83363 13.1759L-25.0655 16.6135L-25.1506 16.6324L-25.2164 16.6892L-36.3041 26.249L-35.9237 26.8231L-9.60376 13.8248ZM134.826 192.442L135.408 192.816L139.76 186.906L139.785 186.87L139.802 186.83L142.619 179.841L141.994 179.543L134.826 192.442ZM143.695 163.538L143.699 162.844L104.377 161.972L104.33 161.971L104.284 161.982L98.9316 163.329L99.0201 164.012L143.695 163.538Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M108.92 160.206L108.932 160.196L108.943 160.186L112.271 156.892L111.799 156.385L100.936 165.917L101.386 166.446L108.92 160.206Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M3.87255 45.4902L4.02418 45.56L4.17352 45.4859L8.42548 43.3665L9.13975 43.0107L8.39207 42.7316L-35.9567 26.1863L-36.2243 26.8255L3.87255 45.4902Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M143.695 163.538L143.687 162.844L143.686 162.844L99.0135 163.319L99.0062 163.319L92.6245 163.506L92.6192 163.507L92.6339 164.2L92.6393 164.199L143.695 163.538L143.695 163.538ZM0.363674 48.5729L0.581781 48.7026L0.771096 48.5344L4.25018 45.4349L4.64383 45.0837L4.16539 44.8614L-35.9305 26.1969L-36.2544 26.8098L0.363674 48.5729Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M98.0463 221.167L97.6979 220.572L89.8143 224.026L89.7784 223.934L89.4465 224.186L89.1781 224.304L89.2048 224.37L81.8812 229.931L82.2323 230.524L90.2252 226.962L90.2599 227.05L90.5925 226.799L90.8592 226.68L90.8339 226.616L98.0463 221.167ZM-54.1995 49.9522L-54.7622 49.5471L-60.6447 56.9747L-60.6657 57.0014L-60.6725 57.0162L-70.5311 72.1663L-69.9694 72.5712L-64.099 65.188L-64.078 65.1613L-64.07 65.1458L-54.1995 49.9522Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M105.626 215.439L105.28 214.844L97.1831 218.326L96.9176 218.44L96.9823 218.722L97.4994 220.968L97.6198 221.489L98.0465 221.167L105.626 215.439Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-2.27636 52.6474L-1.97545 52.875L-1.77364 52.5574L0.832654 48.4612L1.02526 48.1598L0.717371 47.9769L-35.9007 26.2137L-36.2871 26.7883L-2.27636 52.6474Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.335 200.601L100.644 201.199L135.21 192.948L135.406 192.901L135.462 192.707L144.024 163.288L144.355 162.15L143.463 162.93L100.335 200.601Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-58.1018 57.6447L-58.0793 57.6163L-58.0632 57.5853L-54.1807 49.9198L-54.781 49.5743L-64.6516 64.7689L-70.5118 72.1402L-70.5569 72.197L-70.5755 72.2676L-73.0918 81.799L-72.4453 82.0415L-64.0758 65.1587L-58.1018 57.6447ZM97.6584 218.566L97.5382 218.043L97.1111 218.368L89.414 224.211L89.7636 224.806L97.9769 221.207L98.2405 221.093L98.1755 220.812L97.6584 218.566ZM113.368 209.591L113.024 208.995L105.051 212.353L104.817 212.451L104.841 212.704L105.072 215.195L105.13 215.816L105.626 215.44L113.368 209.591Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-3.93269 57.7244L-3.53175 58.1088L-3.36258 57.579L-1.73586 52.476L-1.66129 52.2431L-1.85615 52.0945L-35.8679 26.2352L-36.3167 26.7622L-3.93269 57.7244Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-42.0563 34.6171L-42.6195 34.2139L-48.7549 41.9619L-48.7775 41.9903L-48.7852 42.0048L-58.6639 57.2396L-58.1021 57.6445L-51.9732 49.9352L-51.9507 49.9068L-51.9429 49.8922L-42.0563 34.6171ZM89.8333 224.764L89.5596 224.147L79.6348 226.028L79.1372 226.122L79.4052 226.552L81.7967 230.39L81.9975 230.713L82.3012 230.483L89.8333 224.764ZM105.53 212.64L105.473 212.019L104.976 212.396L97.1098 218.367L97.457 218.963L105.554 215.481L105.786 215.382L105.763 215.131L105.53 212.64Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.224 200.935L100.348 201.511L100.792 201.124L143.919 163.453L144.629 162.832L143.686 162.844L92.6248 163.506L92.2018 163.512L92.2903 163.926L100.224 200.935Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-58.0626 57.5813L-58.6447 57.2125L-64.6425 64.7556L-64.6891 64.8141L-64.6983 64.851L-73.0678 81.7338L-72.4862 82.1045L-66.4907 74.6329L-66.443 74.5737L-66.4338 74.5368L-58.0626 57.5813ZM79.9943 226.185L79.7918 225.861L79.4886 226.093L71.8688 231.941L72.1474 232.555L82.1586 230.546L82.65 230.448L82.3851 230.023L79.9943 226.185ZM121.276 203.615L120.935 203.017L113.093 206.247L112.884 206.333L112.878 206.559L112.811 209.305L112.794 210.024L113.368 209.591L121.276 203.615ZM-35.7859 26.6998L-36.35 26.2964L-42.6192 34.213L-42.642 34.2424L-42.6587 34.2763L-46.2924 41.6874L-45.689 42.0285L-35.7859 26.6998Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-4.49136 63.8142L-3.95447 64.4386L-3.88297 63.6185L-3.34806 57.5043L-3.33338 57.338L-3.45423 57.2233L-35.8383 26.2611L-36.3405 26.7378L-4.49136 63.8142Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-45.7092 42.0565L-45.6855 42.0273L-45.6688 41.9934L-42.0361 34.582L-42.6383 34.2402L-52.5361 49.531L-51.9731 49.9352L-45.7092 42.0565ZM97.5296 218.92L97.259 218.303L87.4295 220.05L86.9636 220.132L87.1819 220.551L89.3162 224.648L89.5057 225.012L89.8334 224.764L97.5296 218.92Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M121.276 200.024L121.576 200.648L129.278 197.554L129.323 197.536L129.361 197.506L135.342 192.885L134.959 192.308L121.276 200.024Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M113.566 206.802L120.649 203.885L120.621 204.11L121.241 203.641L121.386 203.582L121.393 203.527L129.358 197.509L129.019 196.91L121.863 199.784L121.893 199.55L121.273 200.022L121.127 200.08L121.12 200.138L113.59 205.855L113.086 206.238L103.551 207.683L103.14 207.746L103.274 208.138L104.725 212.396L95.6677 213.892L95.5985 213.731L95.3129 213.951L94.9627 214.008L95.0302 214.167L87.6066 219.864L87.2791 220.115L79.5832 226.02L68.2135 225.912L68.1072 225.756L67.9103 225.909L67.3682 225.905L67.5584 226.184L60.1355 231.978L59.3367 232.602L60.3508 232.598L71.8962 232.563L71.9996 232.714L72.1984 232.562L72.7336 232.56L72.5504 232.291L79.8451 226.693L89.6886 224.828L90.1472 224.741L89.931 224.327L88.0157 220.649L97.1095 219.034L97.1761 219.189L97.4634 218.971L97.8106 218.909L97.7455 218.757L105.012 213.239L105.36 212.975L113.293 209.634L113.499 209.546L113.505 209.322L113.566 206.802ZM-58.6927 57.2727L-58.7016 57.3086L-67.0556 74.2283L-67.1021 74.2868L-73.0273 81.6708L-73.0961 81.7567L-73.1028 81.8663L-73.775 93.054L-73.4779 93.1146L-73.1608 93.2936L-67.0225 85.7618L-66.951 85.6735L-66.9477 85.6104L-60.3249 66.9492L-60.313 66.9347L-54.0951 59.1867L-54.0457 59.1248L-54.037 59.0857L-45.6856 42.0273L-39.3046 34.0015L-39.2804 33.9705L-39.2634 33.9355L-35.765 26.6618L-36.3692 26.3236L-46.2626 41.6382L-52.516 49.5041L-52.5652 49.565L-58.6447 57.2125L-58.6927 57.2727Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-39.2647 33.9371L-39.8482 33.5701L-46.252 41.6252L-46.3026 41.6878L-46.3101 41.7262L-54.6768 58.8168L-54.095 59.1866L-47.6868 51.2005L-47.6347 51.1362L-47.6272 51.0979L-39.2647 33.9371ZM87.7004 220.666L88.4827 220.067L87.498 220.044L75.891 219.78L75.2558 219.765L75.5874 220.308L79.404 226.549L79.6052 226.879L79.9112 226.644L87.7004 220.666ZM103.931 207.915L103.775 207.457L103.392 207.752L95.2442 214.003L95.5119 214.62L105.242 213.015L105.645 212.948L105.513 212.561L103.931 207.915Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.483 200.525L100.572 201.209L121.455 200.673L121.542 200.671L121.617 200.629L135.3 192.913L135.049 192.273L100.483 200.525Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-54.0388 59.0852L-54.6362 58.7529L-60.9023 66.562L-60.975 66.6511L-60.9771 66.7134L-67.599 85.3707L-73.6977 92.8557L-73.7898 92.9688L-73.7744 93.1138L-72.3445 105.94L-71.6617 105.979L-66.972 85.7001L-60.7458 78.0593L-60.6717 77.9686L-60.6693 77.9053L-54.0388 59.0852ZM76.1784 219.946L75.9757 219.614L75.6697 219.853L67.9368 225.89L55.0962 223.617L54.3164 223.48L54.7439 224.146L60.0566 232.439L60.2622 232.759L60.5628 232.525L68.1459 226.606L79.6962 226.716L80.3215 226.721L79.9959 226.188L76.1784 219.946ZM121.655 200.602L122.709 199.803L121.398 199.983L112.196 201.245L112.142 201.037L111.8 201.299L111.504 201.34L111.545 201.495L103.391 207.752L103.655 208.37L112.969 206.958L113.021 207.158L113.364 206.898L113.658 206.853L113.619 206.705L121.655 200.602ZM95.6665 214.554L96.4311 213.968L95.4682 213.932L83.9322 213.507L83.3224 213.485L83.6148 214.02L87.1854 220.558L87.3791 220.913L87.7003 220.666L95.6665 214.554ZM-41.1309 43.0309L-41.0774 42.9648L-41.061 42.8824L-39.237 33.8545L-39.8878 33.6335L-48.269 50.8318L-47.6869 51.2006L-41.1309 43.0309Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.242 200.992L100.903 200.79L92.9687 163.78L92.9182 163.545L92.6807 163.51L85.2545 162.409L84.6554 162.319L84.882 162.882L100.242 200.992Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-47.629 51.0975L-48.2266 50.7661L-54.636 58.7529L-54.7094 58.8448L-54.7113 58.9062L-61.3209 77.6675L-67.5602 85.3236L-67.6538 85.4384L-67.6433 85.5245L-72.3368 105.823L-71.7325 106.123L-65.4356 98.5628L-65.339 98.4477L-65.3499 98.3594L-60.6938 77.996L-54.3234 70.1778L-54.2478 70.0853L-54.2463 70.0218L-47.629 51.0975ZM84.2245 213.687L84.0283 213.329L83.7059 213.581L75.67 219.853L74.9035 220.452L75.8751 220.473L87.4818 220.738L88.0823 220.751L87.7941 220.225L84.2245 213.687ZM55.3279 223.772L55.1203 223.447L54.8202 223.688L47.1165 229.843L46.5066 230.33L47.2767 230.457L60.2933 232.594L61.0615 232.721L60.6417 232.064L55.3279 223.772ZM103.814 208.301L104.561 207.729L103.621 207.68L92.1627 207.088L91.5768 207.057L91.8325 207.586L95.1432 214.43L95.329 214.813L95.6667 214.554L103.814 208.301Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-35.732 26.537L-36.4077 26.4035L-41.7314 42.7059L-41.7539 42.7755L-41.7463 42.8485L-39.3398 66.3125L-38.649 66.3034L-35.732 26.537ZM85.5246 162.621L85.4621 162.467L85.302 162.418L77.1195 159.986L76.2818 159.737L76.7211 160.492L100.263 201.036L100.885 200.733L85.5246 162.621Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M76.096 220.401L76.7001 219.929L75.9481 219.787L63.1387 217.348L63.0144 217.139L62.8277 217.289L62.2286 217.174L62.4673 217.577L54.819 223.687L54.2203 224.167L54.9754 224.3L67.8111 226.572L67.9308 226.774L68.1202 226.626L68.7132 226.732L68.4823 226.343L76.096 220.401ZM-41.0731 42.9268L-41.6719 42.5961L-48.2278 50.7659L-48.3029 50.8605L-48.3032 50.9233L-54.9001 69.7868L-61.2826 77.6205L-61.3788 77.7377L-61.3664 77.8243L-66.0214 98.1829L-72.2661 105.679L-72.3798 105.816L-72.335 105.988L-68.6786 119.933L-67.9983 119.887L-65.3702 98.4854L-58.9924 90.8277L-58.894 90.71L-58.9067 90.6203L-54.272 70.1155L-47.7518 62.1141L-47.6745 62.019L-47.6748 61.9539L-41.0731 42.9268ZM92.4559 207.283L92.2689 206.897L91.9309 207.161L83.7057 213.581L82.9559 214.166L83.9067 214.201L95.4427 214.625L96.0187 214.646L95.7676 214.128L92.4559 207.283Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M121.438 199.979L100.76 200.51L77.3215 160.144L77.264 160.045L77.1577 159.999L68.5936 156.324L67.4353 155.827L68.1757 156.846L100.283 201.066L100.547 200.895L100.54 201.208L111.915 201.976L111.95 201.978L111.985 201.974L121.493 200.67L121.438 199.979ZM-3.88215 63.6125L-3.87233 63.4696L-3.96505 63.362L-35.8142 26.2856L-36.4113 26.6057L-8.81361 124.634L-8.13358 124.564L-3.88215 63.6125Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M55.2529 224.229L55.7549 223.828L55.1438 223.629L41.5659 219.204L41.4285 218.962L41.2571 219.103L40.5888 218.886L40.8851 219.41L33.418 225.55L32.9228 225.957L33.5346 226.149L47.098 230.404L47.2311 230.639L47.4049 230.5L48.0664 230.707L47.7794 230.201L55.2529 224.229ZM112.15 201.906L112.879 201.346L111.961 201.285L100.799 200.531L100.707 200.311L100.455 200.507L100.024 200.478L100.14 200.753L91.931 207.161L91.1979 207.732L92.1259 207.78L103.368 208.362L103.458 208.574L103.712 208.38L104.138 208.402L104.027 208.138L112.15 201.906ZM84.1332 214.127L84.7257 213.665L83.9892 213.514L71.2175 210.903L71.0964 210.682L70.9007 210.839L70.3261 210.721L70.5458 211.121L62.703 217.388L62.1146 217.859L62.8544 218L75.6573 220.436L75.7749 220.651L75.9722 220.496L76.5408 220.605L76.329 220.218L84.1332 214.127ZM-61.6106 112.521L-61.4911 112.38L-61.522 112.269L-58.9264 90.7493L-52.396 82.9092L-52.2971 82.7895L-52.3108 82.6996L-47.7009 62.0504L-41.0263 53.8597L-40.9463 53.7622L-40.9481 53.637L-41.0542 42.8098L-41.7285 42.6995L-48.3283 61.723L-54.8615 69.7396L-54.9582 69.8588L-54.9451 69.9466L-59.5788 90.4475L-65.9685 98.1197L-66.0846 98.2588L-66.0549 98.3672L-68.6872 119.802L-68.8273 120.943L-68.0789 120.07L-61.6106 112.521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M68.7368 156.439L68.6917 156.376L68.6237 156.338L59.8322 151.503L59.3992 152.029L100.297 201.084L100.844 200.659L68.7368 156.439ZM-3.20972 70.7367L-3.20585 70.7046L-3.20926 70.6729L-3.88358 63.5554L-4.57407 63.5636L-8.82475 124.508L-8.82549 124.515L-8.13348 124.571L-8.13374 124.564L-3.20972 70.7367Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-61.5269 112.305L-61.4958 111.321L-62.1378 112.069L-68.6063 119.619L-68.743 119.779L-68.6648 119.974L-62.8913 134.351L-62.2227 134.232L-61.5269 112.305ZM63.1369 217.93L63.6323 217.534L63.0317 217.331L49.3167 212.688L48.488 212.407L48.8993 213.179L54.7305 224.122L54.9263 224.49L55.253 224.229L63.1369 217.93Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9317 151.585L59.8999 151.547L59.8575 151.519L51.0083 145.629L50.5587 146.151L100.306 201.096L100.83 200.641L59.9317 151.585Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M92.3576 207.707L92.9396 207.254L92.2185 207.095L79.3282 204.269L78.6161 204.112L78.9451 204.764L83.6101 214.01L83.8005 214.388L84.1333 214.127L92.3576 207.707ZM49.5112 212.854L49.3119 212.479L48.9851 212.748L41.1096 219.224L40.6219 219.626L41.2223 219.822L54.929 224.289L55.7467 224.555L55.3426 223.795L49.5112 212.854ZM-54.9878 104.79L-54.8669 104.648L-54.8987 104.536L-52.3189 82.7285L-52.1809 81.5674L-52.9295 82.466L-59.5247 90.3837L-59.6422 90.5245L-59.6109 90.6343L-62.2187 112.253L-62.3558 113.389L-61.6105 112.521L-54.9878 104.79Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M51.0725 145.684L51.0521 145.663L51.0291 145.644L42.296 138.826L41.8311 139.339L100.306 201.096L100.312 201.101L100.821 200.629L100.815 200.624L51.0725 145.684ZM-1.21331 78.8548L-1.20266 78.7874L-1.21897 78.7205L-3.2184 70.6223L-3.90015 70.6739L-8.82489 124.508L-8.13678 124.592L-1.21331 78.8548Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-45.6434 74.8029L-45.5416 74.6798L-45.5676 74.5239L-47.6785 61.8375L-48.3593 61.8193L-53.0009 82.612L-52.3958 82.9092L-45.6434 74.8029ZM79.5642 204.452L79.3719 204.07L79.0383 204.337L70.7736 210.94L70.1957 211.402L70.9213 211.551L83.8499 214.194L84.5521 214.337L84.2291 213.698L79.5642 204.452ZM41.5502 219.76L41.9766 219.41L41.4759 219.178L27.3427 212.627L26.4428 212.21L26.8863 213.098L33.3282 225.972L33.523 226.361L33.8587 226.085L41.5502 219.76Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M42.3346 138.861L42.3246 138.851L42.3147 138.842L33.8772 131.239L33.3951 131.738L100.312 201.101L100.313 201.104L100.815 200.624L100.813 200.621L42.3346 138.861Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.5066 212.787L27.3085 212.391L26.972 212.678L19.3274 219.179L18.9112 219.534L19.4095 219.759L33.4958 226.134L34.386 226.536L33.9483 225.662L27.5066 212.787Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M33.8943 131.257L33.8935 131.255L25.9291 123.028L25.9284 123.027L25.9274 123.027L25.4291 123.509L25.4288 123.51L100.314 201.104L100.564 200.863L100.813 200.622L100.812 200.621L100.809 200.618L33.8943 131.257Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-55.6648 126.947L-55.5213 126.785L-55.5752 126.654L-54.9055 104.576L-54.8746 103.592L-55.5154 104.339L-62.1379 112.069L-62.276 112.23L-62.2236 112.359L-62.9162 134.211L-62.9464 135.171L-62.3094 134.452L-55.6648 126.947ZM71.2073 211.482L71.6953 211.092L71.106 210.884L57.3853 206.056L56.576 205.772L56.9599 206.539L62.6102 217.815L62.802 218.198L63.1368 217.93L71.2073 211.482ZM100.777 201.136L101.348 200.691L100.643 200.525L87.7975 197.507L87.1054 197.344L87.4039 197.99L91.8288 207.58L92.0121 207.977L92.3573 207.708L100.777 201.136Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M2.09613 87.6474L2.12637 87.5384L2.08701 87.4318L-1.23153 78.6801L-1.70117 77.4427L-1.89871 78.7518L-8.82193 124.488L-8.14466 124.633L2.09613 87.6474Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M57.5802 206.229L57.3854 205.84L57.0497 206.116L48.9849 212.748L48.5027 213.144L49.0937 213.345L62.809 217.988L63.6083 218.258L63.2295 217.504L57.5802 206.229ZM-55.5776 126.7L-55.6205 125.851L-56.184 126.487L-62.8297 133.991L-62.9892 134.172L-62.8753 134.385L-55.1196 148.877L-54.4673 148.696L-55.5776 126.7ZM-48.2068 96.8743L-48.0835 96.7308L-48.117 96.6173L-45.5652 74.6203L-45.4313 73.4634L-46.1771 74.3586L-52.9297 82.4659L-53.0487 82.6083L-53.0164 82.7184L-55.5958 104.524L-55.7306 105.656L-54.988 104.79L-48.2068 96.8743Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-35.7433 26.4177L-36.4231 26.4863L-39.3401 66.2527L-39.3471 66.3509L-39.301 66.4387L-8.78641 124.701L-8.14555 124.446L-35.7433 26.4177Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-38.7277 66.5002L-38.6245 66.3755L-38.6527 66.2159L-40.9539 53.5779L-41.633 53.5653L-46.2486 74.5057L-45.6435 74.8029L-38.7277 66.5002ZM88.0341 197.699L87.8478 197.297L87.5016 197.574L79.0382 204.337L78.4695 204.791L79.18 204.947L92.0702 207.773L92.7511 207.923L92.4589 207.288L88.0341 197.699ZM49.4256 213.284L49.8469 212.937L49.3539 212.703L35.2859 206.024L35.1443 205.728L34.9717 205.874L34.2917 205.551L34.5977 206.192L27.1608 212.517L13.2261 204.207L13.0794 203.874L12.9138 204.021L12.1988 203.595L12.5335 204.356L5.16054 210.867L4.80205 211.185L5.21474 211.427L19.2807 219.686L19.4225 220.01L19.5901 219.867L20.3029 220.286L19.9745 219.54L27.2521 213.35L41.0711 219.755L41.2079 220.041L41.3816 219.898L42.0578 220.212L41.7601 219.588L49.4256 213.284ZM-48.8584 119.262L-48.7135 119.098L-48.7993 118.896L-54.932 104.43L-55.5989 104.554L-56.2705 126.707L-56.3002 127.665L-55.6648 126.947L-48.8584 119.262Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M6.56801 96.7102L6.65158 96.5528L6.57169 96.3937L2.07255 87.4003L1.66892 86.5927L1.42843 87.4624L-8.81334 124.447L-8.17192 124.702L6.56801 96.7102Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-44.9225 162.941L-47.6267 141.406L-47.5673 141.342L-47.6479 141.194L-48.7713 119.028L-48.127 96.7819L-41.259 88.7662L-41.1339 88.6201L-41.1923 88.4366L-45.5787 74.4769L-46.2541 74.5404L-48.8016 96.5035L-55.5154 104.339L-55.655 104.502L-55.5715 104.7L-49.5237 118.966L-56.1839 126.487L-56.3448 126.67L-56.2664 126.814L-55.1675 148.586L-55.2537 148.68L-55.1554 148.827L-55.1181 149.558L-54.8563 149.273L-45.5544 163.177L-44.9225 162.941ZM79.471 204.879L79.9524 204.494L79.3735 204.282L65.7861 199.312L65.6567 199.038L65.4659 199.195L64.8604 198.974L65.1042 199.493L57.0498 206.116L56.5742 206.507L57.155 206.711L70.7384 211.49L70.8638 211.756L71.0567 211.602L71.6567 211.813L71.4211 211.311L79.471 204.879Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M12.0701 105.87L12.2781 105.677L12.1292 105.435L6.55615 96.3672L6.23709 95.8486L5.95345 96.387L-8.78648 124.379L-8.24266 124.794L12.0701 105.87Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M18.4754 114.907L18.9046 114.748L18.6352 114.378L12.1143 105.412L11.8839 105.096L11.5979 105.363L-8.71588 124.286L-8.35947 124.866L18.4754 114.907ZM74.1016 192.294L73.8762 192.95L87.5953 198.169L87.6168 198.177L87.6394 198.182L100.484 201.2L100.666 200.531L74.1016 192.294Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-37.9409 155.535L-38.0562 154.947L-38.222 155.12L-47.7029 141.096L-48.3351 141.333L-45.6312 162.864L-45.7284 162.965L-45.5985 163.126L-45.5193 163.751L-45.2878 163.508L-34.4406 176.906L-33.8306 176.621L-37.8929 155.781L-37.8243 155.708L-37.9409 155.535ZM20.6634 197.177L19.9642 196.753L20.2856 197.51L12.7236 204.188L12.368 204.503L12.7761 204.747L26.9217 213.182L27.0618 213.513L27.232 213.368L27.9291 213.784L27.6152 213.042L35.2509 206.548L35.6171 206.236L35.2051 205.987L20.9779 197.366L20.8338 197.027L20.6634 197.177ZM-41.8861 111.388L-41.7397 111.223L-41.8281 111.019L-48.1523 96.5107L-48.817 96.6387L-49.4656 119.022L-49.4929 119.978L-48.8586 119.262L-41.8861 111.388Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6918 123.614L26.4021 123.588L25.9441 123.044L18.6206 114.359L18.4631 114.172L18.2347 114.258L-8.59996 124.215L-8.46637 124.886L25.6918 123.614Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-40.7441 133.919L-40.5757 133.735L-40.6963 133.516L-48.8149 118.863L-49.4652 119.049L-48.3369 141.307L-48.2946 142.134L-47.7346 141.525L-40.7441 133.919ZM-14.1639 182.357L-14.334 181.828L-14.7339 182.213L-21.6263 188.826L-26.6695 169.423L-26.8219 168.835L-27.2505 169.264L-34.4158 176.443L-34.6563 176.684L-34.4204 176.929L-22.304 189.477L-22.3268 189.498L-22.0516 189.738L-21.1449 190.677L-21.1954 190.483L-8.74068 201.323L-7.80152 202.141L-8.18309 200.955L-14.1639 182.357ZM-41.1763 88.5497L-41.1488 87.5713L-41.7864 88.3142L-48.7333 96.4235L-48.8744 96.5883L-48.7879 96.7871L-42.464 111.297L-41.799 111.168L-41.1763 88.5497ZM87.9353 198.116L88.4102 197.736L87.8425 197.52L74.1225 192.302L73.3496 192.008L73.6821 192.766L78.937 204.747L79.1182 205.161L79.4709 204.879L87.9353 198.116Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M25.9285 123.027L25.8202 122.916L25.6662 122.922L-8.49192 124.194L-9.52484 124.232L-8.67801 124.824L100.364 201.146L100.813 200.621L25.9285 123.027Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3608 204.551L20.7406 198.034L34.7475 206.521L34.885 206.858L35.06 206.71L35.7413 207.123L35.442 206.385L43.0987 199.873L57.0029 206.639L57.135 206.944L57.319 206.793L57.9588 207.104L57.6923 206.486L65.5993 199.983L79.1358 204.934L79.899 205.212L79.5723 204.468L74.3165 192.487L74.1326 192.067L73.7787 192.358L65.4855 199.179L51.5354 192.216L51.4003 191.902L51.2173 192.058L50.5729 191.736L50.8472 192.372L43.014 199.035L28.9216 190.357L28.7807 190.008L28.6064 190.162L27.9229 189.741L28.2295 190.496L20.6823 197.16L6.86729 186.964L6.72393 186.576L6.55404 186.733L5.85057 186.214L6.17178 187.083L-0.89167 193.581L-6.66255 175.162L-6.82927 174.628L-7.23308 175.015L-14.3044 181.8L-19.3252 162.068L-19.4767 161.477L-19.9069 161.908L-26.7877 168.802L-30.722 148.287L-30.6522 148.213L-30.77 148.041L-30.8826 147.451L-31.0513 147.627L-40.5586 133.717L-33.581 126.125L-33.4111 125.94L-33.5345 125.719L-41.7223 111.203L-34.7408 103.319L-34.5929 103.152L-34.6517 103.02L-34.0607 80.3639L-34.0139 80.3085L-34.0562 80.1805L-34.0319 79.2642L-34.2672 79.5384L-38.6649 66.1696L-39.3386 66.2387L-41.8552 88.396L-41.9291 88.4816L-41.8781 88.5953L-41.9955 89.6245L-41.8969 89.5099L-42.4893 111.023L-49.3778 118.802L-49.5409 118.985L-49.4224 119.2L-41.4243 133.634L-48.2461 141.055L-48.4315 141.257L-48.2778 141.484L-38.7124 155.633L-38.7306 155.652L-38.6005 155.812L-38.5224 156.438L-38.4808 156.395L-34.5109 176.755L-34.3861 177.395L-33.9246 176.933L-27.188 170.184L-22.1487 189.574L-21.9999 190.147L-21.573 189.737L-14.6545 183.097L-8.84308 201.168L-8.67711 201.684L-8.27837 201.317L-1.05346 194.672L-0.360623 195.279L-0.413864 195.108L12.6641 204.667L12.8031 205.044L12.9737 204.893L13.6772 205.407L13.3608 204.551Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-38.6716 66.1504L-39.3241 66.3866L-34.7341 80.3403L-34.7276 80.3595L-34.7196 80.3769L-27.9913 94.9637L-27.3543 94.6907L-38.6716 66.1504ZM45.2743 175.714L44.9596 176.331L73.8262 192.928L73.8597 192.946L73.8961 192.957L100.461 201.194L100.706 200.546L45.2743 175.714Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.1078 197.71L28.6805 191.022L42.7656 199.696L42.9012 200.041L43.0794 199.889L43.7459 200.299L43.4597 199.566L51.3176 192.883L65.2607 199.841L65.3892 200.156L65.5787 199.999L66.202 200.31L65.9506 199.694L74.219 192.895L74.6275 192.558L74.1578 192.318L59.9666 185.053L59.8342 184.728L59.6468 184.889L59.0199 184.568L59.2784 185.201L51.2358 192.042L37.0659 183.17L36.9269 182.814L36.7485 182.972L36.081 182.553L36.373 183.304L28.6249 190.146L14.6982 179.766L14.5573 179.372L14.3835 179.532L13.6943 179.018L14.0025 179.882L6.74919 186.553L1.02708 167.786L0.862799 167.246L0.45533 167.636L-6.80185 174.6L-11.7979 154.529L-11.9463 153.933L-12.3801 154.367L-19.4425 161.443L-23.3726 140.605L-23.3004 140.531L-23.4191 140.358L-23.5306 139.767L-23.7024 139.948L-33.3941 125.921L-26.2381 118.135L-26.0658 117.948L-26.1921 117.727L-34.5757 103.132L-27.4165 95.0481L-27.267 94.8783L-27.3612 94.6734L-34.0895 80.0866L-34.7511 80.2226L-35.3444 102.954L-42.4059 110.928L-42.5697 111.114L-42.4481 111.329L-34.2625 125.841L-41.2558 133.45L-41.4425 133.653L-41.2868 133.88L-31.5419 148.14L-31.5598 148.158L-31.4287 148.319L-31.3519 148.945L-31.3112 148.901L-27.3455 169.576L-27.2224 170.219L-26.7594 169.755L-19.8471 162.83L-14.8299 182.548L-14.683 183.125L-14.2538 182.714L-7.15753 175.903L-1.3908 194.31L-1.22722 194.832L-0.824865 194.462L6.58354 187.648L7.26497 188.246L7.21488 188.083L20.4111 197.822L20.5477 198.204L20.7219 198.051L21.4113 198.56L21.1078 197.71Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.763 200.579L-8.21127 124.305L-38.6867 66.1167L-39.3164 66.406L-27.9991 94.9462L-27.9863 94.9772L-27.9678 95.0066L-8.77028 124.728L-8.57485 124.614L-8.71979 124.79L44.8928 176.281L44.936 176.323L44.9908 176.347L100.421 201.179L100.763 200.579Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M14.4022 179.515L0.935832 167.635L-11.8744 154.381L-23.4137 140.353L-26.1473 117.87L-27.3298 94.7998L-27.3733 93.9531L-27.9355 94.588L-35.26 102.86L-35.426 103.046L-35.3015 103.262L-26.92 117.853L-34.0916 125.655L-34.2794 125.859L-34.1218 126.087L-24.0737 140.632L-20.0024 162.217L-19.8812 162.864L-19.4159 162.399L-12.322 155.292L-7.32956 175.349L-7.18487 175.93L-6.7527 175.515L0.528967 168.527L6.24594 187.282L6.40709 187.81L6.81237 187.437L14.4304 180.431L28.351 190.806L28.4849 191.195L28.6621 191.038L29.3381 191.543L29.0468 190.698L37.0143 183.663L37.3328 183.381L36.9936 183.126L22.7281 172.385L22.5899 171.985L22.4122 172.147L21.7364 171.638L22.0323 172.497L14.4022 179.515Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.3054 175.73L44.9461 176.323L59.5087 185.595L59.522 185.604L59.5366 185.612L73.8408 192.935L74.1722 192.325L45.3054 175.73Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-4.39108 146.364L-15.8777 132.476L-18.599 109.824L-18.5338 109.753L-18.6273 109.594L-18.7081 108.918L-18.9002 109.126L-27.3774 94.6426L-28.0224 94.8363L-26.8466 117.772L-26.937 117.87L-26.8345 118.017L-26.7972 118.744L-26.7463 118.688L-24.1105 140.373L-24.2096 140.477L-24.0783 140.637L-24.0017 141.264L-23.7659 141.016L-12.6018 154.59L-12.6207 154.608L-12.4559 154.778L-12.3552 155.325L-12.138 155.107L0.204296 167.878L0.183955 167.897L0.384899 168.075L0.50136 168.555L0.687045 168.376L1.33504 169.047L1.29395 168.877L14.1024 180.175L14.2322 180.613L14.4116 180.448L15.0823 181.039L14.8006 180.09L22.6688 172.853L22.9522 172.593L22.6643 172.338L8.88514 160.126L8.75123 159.675L8.58978 159.831L-4.10006 146.706L-4.22358 146.197L-4.39108 146.364ZM45.0991 175.598L44.4454 175.181L44.7241 175.928L36.5552 183.143L36.2066 183.45L36.6006 183.697L50.9836 192.701L51.115 193.055L51.2992 192.898L51.9497 193.307L51.6774 192.576L59.9197 185.567L60.277 185.262L59.8813 185.01L45.4167 175.8L45.2816 175.436L45.0991 175.598Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M30.6642 164.558L16.9747 152.367L16.8442 151.911L16.6602 152.086L16.004 151.502L16.278 152.454L8.7784 159.65L3.88603 139.055L3.97255 138.969L3.82279 138.788L3.6973 138.262L3.52692 138.433L-8.15457 124.388L-8.25972 123.809L-8.43932 123.996L-18.6795 109.507L-19.3075 109.748L-16.5732 132.497L-16.6736 132.602L-16.541 132.761L-16.4657 133.389L-16.2284 133.14L-4.88282 146.858L-4.90075 146.876L-4.73668 147.045L-4.63736 147.593L-4.43838 147.394L-3.83594 148.124L-3.8683 147.943L8.09004 160.311L8.06991 160.33L8.34075 160.571L9.21082 161.471L9.17215 161.308L21.9173 172.603L21.8933 172.625L22.1328 172.805L22.2561 173.233L22.4391 173.065L23.0982 173.65L23.0544 173.499L36.4893 183.615L36.6207 184.01L36.8016 183.851L37.4655 184.35L37.1863 183.511L45.3628 176.29L45.6798 176.011L45.3427 175.754L30.9631 164.814L30.8275 164.407L30.6642 164.558Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-8.23887 124.291L-8.7372 124.773L16.411 152.791L16.428 152.81L16.4433 152.823L30.4373 165.285L30.4475 165.294L30.4579 165.301L44.9222 176.307L45.0696 176.121L45.373 175.781L-8.23887 124.291Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9095 152.809L17.1603 152.568L16.9186 152.318L3.75238 138.706L2.87402 137.797L3.16614 139.027L8.24382 160.4L8.38319 160.991L8.82069 160.571L16.9095 152.809Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M-8.22151 124.308L-8.7458 124.762L3.23716 139.169L3.24491 139.179L3.25391 139.188L16.4202 152.8L16.9277 152.327L-8.22151 124.308Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default TopLeftGroup;
