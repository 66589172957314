import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import { type IconProps } from '../../typescript';

const FeaturesBottomLeft: FC<IconProps> = (props) => {
  return (
    <motion.svg
initial={{ scale: 1.1, rotate: 12 }}
animate={{ scale: 1, rotate: 0 }}
transition={{ delay: 0, duration: 0.3 }}
       whileHover={{
         scale: 1.2,
         transition: { duration: 1 }
       }}
  whileTap={{ scale: 0.9 }}
      {...props}
      width="66"
      height="58"
      viewBox="0 0 66 58"
      fill="none"
      xmlns="http://www.w3.org/2000/motion.svg"
    >
      <path
        d="M19.7814 11.7563L19.7784 11.7582L19.7756 11.7604L17.0469 13.811L17.0452 13.8124L17.0433 13.8141L14.4327 15.9861L14.4307 15.9874L14.4709 16.0371L14.4271 15.9908L11.9485 18.2809L11.9465 18.2825L11.9446 18.2845L9.62012 20.6866L9.75624 20.8222L14.5541 16.1355L25.6392 8.21687L31.7069 5.12993L31.6218 4.95749L28.5499 6.41889L28.5476 6.41999L28.5454 6.42111L25.5433 8.04997L25.5733 8.10652L25.5392 8.05218L22.6166 9.83326L22.6147 9.83463L22.6128 9.83575L19.7814 11.7563Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.5951 22.239L27.3691 22.1993L27.4994 22.3884L28.6467 24.0542L25.7591 23.6452L25.5396 23.6139L25.6668 23.7955L26.9566 25.6331L27.0145 25.7155L27.0941 25.6542L28.9068 24.2582L30.8043 22.9734L32.7784 21.807L32.9905 21.6817L32.7495 21.6304L29.5173 20.9403L29.2839 20.8904L29.4175 21.0884L30.5462 22.758L27.5951 22.239Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.0242 2.78941L37.9521 2.61113L31.6302 4.95419L31.6249 4.95614L31.6199 4.95863L25.5459 8.04862L25.6282 8.22244L38.0242 2.78941Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.5768 20.9805L29.523 20.9006L29.4432 20.9547L27.6036 22.2004L27.6003 22.1957L27.5221 22.2556L27.3278 22.3873L27.3463 22.3906L25.687 23.664L25.5054 23.8032L25.7322 23.8354L28.8368 24.2751L29.0539 24.3059L28.9294 24.1254L27.788 22.4682L30.736 22.9867L30.9595 23.0261L30.8325 22.8381L29.5768 20.9805ZM31.5121 19.7595L31.2703 19.6979L31.4081 19.9059L32.6491 21.7773L32.6965 21.8488L32.773 21.81L34.8105 20.7742L35.0384 20.6583L34.7908 20.5954L31.5121 19.7595Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6535 8.20638L25.5337 8.05638L14.4367 15.9833L14.4284 15.9894L14.4214 15.9972L5.52881 25.82L5.6641 25.9564L25.6535 8.20638Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.8841 25.2294L23.784 25.3162L23.8018 25.3181L22.3614 26.7396L22.1965 26.726L22.271 26.8286L22.1807 26.9178L22.3459 26.9311L23.6507 28.7233L23.7167 28.8143L23.7962 28.735L25.3591 27.1754L25.5213 27.1887L25.4514 27.0911L27.0667 25.678L27.2361 25.6965L27.1635 25.5931L27.263 25.5061L27.0889 25.4869L25.877 23.7602L27.6345 22.4117L29.5171 21.1368L32.7091 21.8183L32.9395 21.8674L32.8092 21.6711L31.706 20.0073L34.7067 20.7723L34.7303 20.8086L34.7746 20.7897L34.9817 20.8424L34.9099 20.7321L36.8917 19.8864L37.1357 19.7825L36.8815 19.7059L33.5999 18.7184L33.5719 18.6751L33.5292 18.697L33.3146 18.6324L33.3997 18.7633L31.5208 19.7278L31.5177 19.7231L31.4714 19.7506L28.24 19.0502L28.0058 18.9995L28.1399 19.198L29.2897 20.8992L26.3015 20.3605L26.0741 20.3195L26.2053 20.5097L27.3739 22.2062L24.4472 21.7754L24.226 21.7429L24.3543 21.9259L25.6169 23.7244L24.0299 25.1027L24.0258 25.0971L23.9781 25.144L23.8098 25.1254L23.8841 25.2294Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.019 2.79128L37.9552 2.60996L34.7668 3.6774L34.764 3.6785L34.761 3.67961L31.6274 4.95524L31.697 5.13463L38.019 2.79128Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.2995 19.0905L28.2457 19.0106L28.1658 19.0647L26.2306 20.3755L26.0352 20.5081L26.2675 20.5499L29.4803 21.1288L29.7046 21.1693L29.5768 20.9805L28.2995 19.0905Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5639 16.1261L14.4256 15.9927L9.62244 20.6847L9.61799 20.6888L9.61439 20.6935L5.5249 25.8247L5.67158 25.9491L14.5639 16.1261Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.4899 20.5837L26.4187 20.4803L28.2397 19.2471L31.4298 19.9383L31.4558 19.9774L31.5016 19.9538L31.6992 19.9968L31.628 19.889L33.5482 18.9036L36.7924 19.8798L36.8137 19.9136L36.8562 19.8989L37.0746 19.9649L37.0009 19.8493L38.9783 19.1703L39.2061 19.2504L39.1244 19.1201L39.2713 19.0697L39.0423 18.989L37.8512 17.0881L37.8105 17.023L37.7378 17.0485L35.6764 17.7746L35.6734 17.7699L35.6329 17.7874L35.4053 17.7072L35.4158 17.7236L32.5432 16.8555L32.556 16.8489L32.3491 16.7954L32.322 16.7535L32.2793 16.7757L32.0647 16.7108L32.073 16.7238L28.9647 15.9176L28.7218 15.8544L28.8605 16.0637L30.0072 17.7929L30.0141 17.8034L26.9782 17.1355L26.9458 17.0876L26.9003 17.1184L26.7056 17.0754L26.7866 17.1954L24.9931 18.4106L24.99 18.4062L24.9437 18.4414L24.7571 18.407L24.8339 18.5185L24.72 18.5958L24.7369 18.5989L23.0722 19.867L22.8901 19.839L22.9663 19.9477L22.8596 20.029L23.0431 20.057L24.3016 21.8502L24.194 21.9326L24.2112 21.9351L22.6979 23.2398L22.6941 23.2343L22.6467 23.2806L22.4764 23.2606L22.5518 23.3657L22.4526 23.4514L22.4695 23.4533L21.0084 24.8792L20.8415 24.8643L20.9172 24.9682L20.827 25.0561L20.9917 25.0708L22.2746 26.833L20.8758 28.417L20.7181 28.4084L20.7935 28.5101L20.7134 28.6008L20.8667 28.6091L22.1933 30.3999L22.2646 30.4958L22.3433 30.4057L23.7704 28.7655L23.9253 28.7738L23.8513 28.6721L23.9303 28.5814L23.7795 28.5733L22.5244 26.8494L24.0493 25.3445L24.2134 25.3591L24.1424 25.2599L25.7766 23.8406L25.9476 23.8606L25.8742 23.7561L25.9731 23.6701L25.7996 23.6496L24.6407 21.9983L27.5646 22.4289L27.783 22.461L27.6577 22.2791L26.4899 20.5837Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6825 8.15864L25.7623 7.85228L25.5255 8.06244L5.53613 25.8127L5.65396 25.9641L24.3045 13.3513L24.3344 13.3311L24.3436 13.2961L25.6825 8.15864Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.3373 21.3895L21.1196 21.3626L21.2472 21.5408L22.6029 23.4372L22.6642 23.5229L22.7441 23.4542L24.4963 21.9434L24.6604 21.802L24.4453 21.7751L21.3373 21.3895ZM29.0208 15.9575L28.9701 15.881L28.8913 15.9281L26.8707 17.1386L26.663 17.2631L26.8995 17.3149L30.2049 18.0422L30.4373 18.0935L30.3059 17.8952L29.0208 15.9575ZM34.4785 15.8982L34.4342 15.8286L34.3588 15.8619L32.2502 16.7909L32.0124 16.896L32.261 16.9712L35.6089 17.9828L35.8573 18.058L35.7176 17.839L34.4785 15.8982Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6842 8.11709L25.4965 8.11015L24.1577 13.2476L24.0981 13.4767L24.3005 13.3541L26.3516 12.1134L26.4084 12.079L26.3965 12.0139L25.6842 8.11709ZM9.76465 20.8136L9.61631 20.6911L7.46898 23.2024L7.46704 23.2049L7.46482 23.2077L5.52295 25.8272L5.67544 25.9445L9.76465 20.8136Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.1769 19.9128L23.1162 19.8263L23.0358 19.8951L21.2627 21.4119L21.0972 21.5536L21.3135 21.5805L24.4218 21.9661L24.6366 21.9928L24.5121 21.8153L23.1769 19.9128ZM38.0399 17.1461L38.0593 17.1392L37.8042 17.0493L37.5294 16.9368L37.5422 16.957L34.4295 15.8591L34.1664 15.7662L34.3164 16.0016L35.5557 17.9424L35.5967 18.0067L35.6685 17.9815L37.7293 17.2556L38.8971 19.1193L38.9346 19.1789L39.0025 19.1614L41.1531 18.6103L41.4343 18.5382L41.1659 18.4281L38.0399 17.1461ZM21.0406 28.5207L22.4702 26.9026L22.5999 26.7554L22.4042 26.7429L19.5457 26.5599L19.3389 26.5466L19.4622 26.7133L20.7969 28.5149L19.5144 30.2094L19.4015 30.3583L19.5884 30.3636L22.268 30.4387L22.4657 30.4443L22.3482 30.2854L21.0406 28.5207Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6572 8.06604L25.4404 7.85228L25.4953 8.15172L26.2073 12.0482L26.2312 12.178L26.3473 12.1159L28.4497 10.993L28.5651 10.9315L28.4719 10.8397L25.6572 8.06604Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.5936 30.1712L19.3939 30.1656L19.5143 30.3253L20.8922 32.1544L20.9699 32.2569L21.0464 32.1538L22.3478 30.3999L22.4579 30.2516L22.2735 30.2463L19.5936 30.1712ZM31.0073 14.8205L30.7924 14.7553L30.8786 14.8879L28.9273 15.909L25.6183 15.1701L25.3821 15.1174L25.5176 15.3176L26.7112 17.0837L23.6459 16.504L23.4163 16.4607L23.5489 16.6531L24.7616 18.4137L21.7542 17.9371L21.5302 17.9013L21.6605 18.0871L23.0196 20.0234L23.077 20.1052L23.1565 20.0448L25.0241 18.6219L26.9718 17.3022L28.9556 16.1139L32.2285 16.9628L32.253 17L32.297 16.9806L32.5047 17.0344L32.4312 16.9215L34.4363 16.0379L34.6737 15.9331L34.4255 15.8577L31.0785 14.8421L31.0497 14.798L31.0073 14.8205ZM22.4756 26.7823L21.1706 24.9896L22.7163 23.4813L22.8826 23.4977L22.8072 23.3926L22.8962 23.3058L22.734 23.2897L21.4035 21.4291L21.3389 21.3384L21.2585 21.4158L19.631 22.9859L19.4619 22.9692L19.5387 23.0749L19.4491 23.1614L19.6133 23.1774L20.9202 24.9727L19.468 26.5918L19.3354 26.7393L19.5334 26.7518L22.3916 26.9351L22.5962 26.9481L22.4756 26.7823Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6221 8.04388L25.522 8.20303L28.3366 10.9767L28.3841 11.0235L28.4445 10.9955L30.5863 10.0038L30.7989 9.90535L30.5782 9.82607L25.6221 8.04388Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5454 15.1829L25.3418 15.3055L25.359 15.3093L23.6531 16.4655L23.65 16.461L23.5719 16.5204L23.3811 16.6499L23.3975 16.6529L21.7606 17.895L21.7572 17.8906L21.6791 17.9571L21.5013 18.0918L21.5174 18.0943L19.9157 19.454L19.7383 19.431L19.8156 19.5388L19.7178 19.6223L19.8913 19.645L21.2471 21.5409L21.3081 21.6263L21.3879 21.5581L23.128 20.0692L23.3024 20.0919L23.227 19.9844L23.3248 19.9009L23.1527 19.8785L21.9483 18.1625L24.9529 18.6389L25.1744 18.6738L25.0471 18.4894L23.84 16.7366L26.9022 17.3158L27.129 17.3585L26.9998 17.1675L25.8124 15.4105L28.8818 16.0962L28.9084 16.1361L28.9542 16.1123L29.1527 16.1566L29.0798 16.0465L31.0267 15.0274L34.3354 16.0316L34.3578 16.0665L34.3978 16.0521L37.7062 17.2188L37.7259 17.2507L37.7633 17.2407L41.0926 18.6061L41.3657 18.7182L41.211 18.467L40.0767 16.6232L40.2279 16.5828L39.9927 16.4863L39.9717 16.4522L39.9337 16.4622L36.8071 15.1815L36.8257 15.1749L36.6033 15.0964L36.58 15.059L36.5418 15.0728L36.2995 14.9736L36.3119 14.9935L33.1998 13.8948L33.1729 13.8529L33.1321 13.8709L32.905 13.7908L32.9954 13.9322L31.0083 14.8225L27.6927 13.9547L27.6622 13.9086L27.6181 13.9353L27.4135 13.8818L27.4972 14.0079L25.6257 15.1344L25.623 15.1302L25.5454 15.1829Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6023 8.03913L25.5571 8.22489L30.5133 10.0068L30.5474 10.019L30.581 10.006L32.7494 9.16034L32.7269 8.9754L25.6023 8.03913Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.6634 26.6617L21.0838 25.0783L21.2438 25.0902L21.1673 24.9851L21.246 24.8975L21.0952 24.8864L19.7926 23.097L21.3597 21.5855L21.528 21.6032L21.4517 21.4968L21.5404 21.4111L21.3783 21.3939L20.0752 19.5709L21.7994 18.1071L23.6534 16.7003L23.8355 16.7299L23.7624 16.6237L25.618 15.3658L25.8071 15.4024L25.7336 15.294L27.6478 14.1418L30.9565 15.0076L30.9812 15.0453L31.0253 15.0257L31.2329 15.08L31.1586 14.9658L33.1407 14.078L36.5055 15.2658L39.8616 16.6407L41.0471 18.5676L41.081 18.6225L41.1447 18.6122L43.2424 18.2698L43.5408 18.221L43.2691 18.0885L39.9944 16.4893L38.7856 14.5244L38.7476 14.4626L38.678 14.482L36.5831 15.0639L35.3687 13.1262L35.3274 13.0599L35.2539 13.0871L33.1756 13.8573L31.9338 11.9118L31.8889 11.8414L31.813 11.8761L29.7577 12.8121L29.7549 12.8079L29.7122 12.8304L29.4976 12.7649L29.5056 12.7774L26.3616 11.9473L26.3308 11.901L26.287 11.9276L26.0818 11.8736L26.1661 12.0005L24.2791 13.142L24.2767 13.1381L24.2315 13.1686L24.0349 13.1243L24.1145 13.2418L23.9969 13.3128L24.0133 13.3164L22.2893 14.4853L22.2863 14.4811L22.2405 14.5161L22.0517 14.4795L22.1302 14.5934L22.0184 14.6688L22.034 14.6719L20.3308 15.9597L20.1459 15.9292L20.2238 16.0404L20.119 16.1197L20.3003 16.1496L21.5335 17.9066L18.5539 17.4949L18.3324 17.4644L18.4624 17.6463L19.8184 19.5429L18.2287 21.0587L18.0576 21.0404L18.1355 21.1474L18.0462 21.2325L18.2104 21.25L19.5417 23.079L18.0975 24.6638L17.9351 24.6519L18.0127 24.757L17.9331 24.8446L18.0861 24.8559L19.4189 26.6548L18.1424 28.3075L17.9891 28.3014L18.067 28.4048L17.9983 28.4941L18.1385 28.4999L19.4741 30.2721L18.3892 31.9922L18.247 31.9916L18.3251 32.0939L18.268 32.184L18.3945 32.1846L19.7702 33.9812L19.855 34.0921L19.9285 33.9729L21.0228 32.1929L21.1626 32.1932L21.0855 32.0909L21.1409 32.0008L21.0173 32.0005L19.7114 30.2674L20.9635 28.6136L21.114 28.6197L21.0372 28.516L21.1049 28.4267L20.9668 28.4211L19.6634 26.6617Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.3202 14.6969L20.4138 16.1377L20.4116 16.1397L20.4091 16.1411L5.65396 25.9641L5.65342 25.9644L5.54833 25.808L22.3202 14.6969ZM22.3202 14.6969L5.54833 25.808L22.3202 14.6969ZM22.3202 14.6969L24.3045 13.3513H24.3048L24.1969 13.1919L24.1966 13.1922L5.5486 25.8033L5.54695 25.8044L22.3202 14.6969ZM5.54833 25.808L5.54639 25.8047L5.54833 25.808ZM25.551 8.04639L25.5843 8.13871L25.5771 8.22964L32.7017 9.16593L32.7227 9.1687L32.743 9.16261L34.9263 8.48029L34.9003 8.2923L26.2134 8.05525L49.496 1.36597L49.4575 1.17799L37.9736 2.60584L37.9598 2.60751L37.947 2.61305L25.551 8.04639Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.4343 16.006L20.3742 15.9197L20.2938 15.9877L18.4786 17.5167L18.3133 17.6559L18.5274 17.6853L21.7258 18.1272L21.9445 18.1575L21.8178 17.9767L20.4343 16.006ZM42.1113 16.1446L42.0772 16.0883L42.0127 16.0997L39.9105 16.4687L39.6188 16.5197L39.8847 16.6498L43.1846 18.2611L43.4779 18.4045L43.3091 18.125L42.1113 16.1446ZM35.5521 13.1816L35.5698 13.1749L35.3214 13.0873L35.0469 12.975L35.0594 12.9947L31.8846 11.873L31.6212 11.7798L31.7715 12.0152L33.0552 14.0261L33.097 14.0915L33.1697 14.0646L35.2471 13.2944L36.4579 15.2269L36.4961 15.2876L36.5652 15.2685L38.7291 14.6674L39 14.592L38.74 14.4855L35.5521 13.1816ZM18.2615 24.7694L19.7365 23.1505L19.8679 23.0064L19.6735 22.99L16.7175 22.7405L16.5054 22.7227L16.6321 22.8935L18.0158 24.7614L16.715 26.4113L16.5589 26.4032L16.6382 26.5086L16.5691 26.5962L16.71 26.6037L18.1112 28.4632L18.1869 28.5639L18.264 28.4643L19.5845 26.7545L19.7381 26.7626L19.66 26.6572L19.7276 26.5696L19.5895 26.5624L18.2615 24.7694Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.54688 25.8044L5.65196 25.9655L5.65335 25.9647L18.5925 17.6712L18.5977 17.6679L18.6024 17.6637L20.4176 16.1347L20.3023 15.9811L5.54826 25.8036L5.54688 25.8044Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.3938 10.8045L28.1789 10.7388L28.2662 10.8727L26.2562 11.9462L26.0402 12.0616L26.277 12.1239L29.6614 13.0175L29.6863 13.0558L29.7304 13.0356L29.9384 13.0905L29.8635 12.9751L31.8922 12.051L32.1231 11.946L31.8805 11.8717L28.4656 10.8264L28.4365 10.7818L28.3938 10.8045ZM18.3403 21.1022L18.2713 21.0077L18.192 21.0936L16.6388 22.771L16.5049 22.9155L16.7012 22.9321L19.6572 23.1816L19.8669 23.1994L19.7429 23.0291L18.3403 21.1022ZM38.7456 14.4881L38.4481 14.3434L38.6214 14.625L39.8449 16.6138L39.8793 16.6695L39.9436 16.6581L42.0454 16.2891L42.3377 16.2378L42.071 16.1081L38.7456 14.4881Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.1109 1.47742L44.0743 1.2886L41.1038 1.7993L41.1002 1.80015L41.0963 1.80097L37.9614 2.60804L38.0058 2.7952L44.1109 1.47742Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.265 28.3477L18.1821 28.2379L18.1076 28.3533L16.9608 30.1241L16.815 30.1216L16.8949 30.2258L16.8375 30.3143L16.9642 30.3165L18.366 32.1469L18.4497 32.2567L18.5234 32.14L19.6435 30.3645L19.7866 30.3672L19.7079 30.2627L19.7633 30.1743L19.6397 30.172L18.265 28.3477ZM16.7072 19.1853L16.6182 19.2691L16.782 19.2879L18.1852 21.2156L18.2498 21.3044L18.3294 21.2287L19.9793 19.6556L20.1495 19.6752L20.0722 19.5671L20.1604 19.4828L19.9987 19.4643L18.6191 17.5343L18.555 17.4447L18.4749 17.5201L16.8014 19.0966L16.6281 19.0767L16.7072 19.1853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.54834 25.8036L5.64926 25.9672L16.8838 19.2798L16.893 19.2746L16.9005 19.2674L18.6064 17.6601L18.4886 17.5093L5.54834 25.8036Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.0782 16.1116L41.9471 16.2442L43.1445 18.2246L43.1745 18.2742L43.2327 18.2709L45.1993 18.1522L45.5139 18.1334L45.2422 17.9734L42.0782 16.1116ZM34.0939 11.0914L34.052 11.0246L33.9783 11.0526L31.8185 11.8738L31.5734 11.967L31.8207 12.0543L35.2531 13.267L38.6672 14.6636L38.94 14.7753L38.7856 14.5244L37.5399 12.4996L37.5016 12.4372L37.4312 12.4574L35.3304 13.0646L34.0939 11.0914ZM18.5184 32.0301L18.4252 31.9081L18.3562 32.0454L17.4387 33.8708L17.3691 34.0094L17.5244 34.01L19.8461 34.0186L20.0419 34.0194L19.9229 33.8642L18.5184 32.0301Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5877 8.03836L25.5869 8.23076L34.8951 8.48444L34.9073 8.485L34.9192 8.48223L37.0372 7.99786L37.0139 7.80795L25.5877 8.03836Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.5405 9.81281L30.314 9.73268L30.4052 9.87547L28.3635 10.8209L28.1353 10.9268L28.3757 11.0003L31.7903 12.0453L31.8134 12.0813L31.8558 12.0652L32.074 12.132L31.9969 12.0117L33.9726 11.2606L35.2055 13.2283L35.2441 13.2898L35.3137 13.2696L37.4843 12.6425L37.7502 12.5657L37.494 12.4609L34.2742 11.1458L34.2914 11.1395L34.0464 11.0527L33.7721 10.9407L33.7843 10.9601L30.6087 9.83694L30.5812 9.79396L30.5405 9.81281ZM17.5246 33.8179L17.3271 33.8171L17.4483 33.9729L18.8795 35.815L18.9743 35.937L19.042 35.7978L19.9325 33.9646L19.9993 33.8271L19.8466 33.8265L17.5246 33.8179ZM40.892 14.1285L40.8573 14.0717L40.7919 14.0839L38.6856 14.4801L38.4001 14.5339L38.6612 14.6611L41.9869 16.2811L42.2799 16.4239L42.1114 16.1447L40.892 14.1285Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.55078 25.8019L5.64423 25.9699L15.3001 20.9597L15.3148 20.9522L15.3259 20.9403L16.9046 19.2632L16.7854 19.1146L5.55078 25.8019Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.8341 22.8431L18.3008 21.2591L18.4661 21.2747L18.387 21.1663L18.4655 21.0814L18.315 21.0673L16.9123 19.1406L16.8441 19.0469L16.7646 19.1312L15.218 20.7742L15.0503 20.7584L15.1307 20.8671L15.0511 20.9517L15.2042 20.9661L16.5879 22.8339L15.2618 24.4808L15.1024 24.4706L15.1837 24.5781L15.1143 24.6641L15.2552 24.6732L16.642 26.5136L15.5058 28.2221L15.3564 28.2171L15.4382 28.3238L15.3805 28.4103L15.5075 28.4145L16.8985 30.2311L15.9816 31.9938L15.9103 32.1311L16.065 32.1344L18.44 32.1846L18.6399 32.189L18.5182 32.0302L17.1297 30.2166L18.239 28.5035L18.3857 28.5082L18.3053 28.4015L18.361 28.315L18.237 28.3108L16.881 26.5114L18.1813 24.8626L18.3377 24.8726L18.2581 24.765L18.3258 24.6791L18.1877 24.6702L16.8341 22.8431ZM37.4998 12.4633L37.2028 12.3191L37.3758 12.6003L38.6215 14.625L38.6562 14.6813L38.7213 14.6691L40.7622 14.2851L41.8657 16.1103L41.7151 16.1214L41.9594 16.265L41.9774 16.2949L42.0062 16.2927L45.1445 18.1392L45.2759 18.0072L44.1599 16.133L44.3124 16.1216L44.0676 15.9783L44.0499 15.9484L44.0205 15.9506L41.0799 14.2252L41.1138 14.2191L40.8518 14.0919L37.4998 12.4633ZM32.7956 9.01952L32.7535 8.95215L32.6792 8.98098L30.5108 9.82689L30.2696 9.92088L30.5135 10.0071L33.9803 11.2331L34.2401 11.3252L34.0937 11.0914L32.7956 9.01952Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.55566 25.7992L5.63552 25.9738L13.86 22.7039L13.883 22.6947L13.8988 22.6756L15.33 20.9356L15.2116 20.7892L5.55566 25.7992Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.2712 10.4377L36.2327 10.3747L36.1617 10.3961L33.9847 11.0504L33.7241 11.1286L33.9759 11.2315L37.4216 12.6388L37.6941 12.7503L37.5397 12.4993L36.2712 10.4377Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5859 8.03836L25.5915 8.23076L25.5931 8.23049L37.0177 8.0001H37.0229L37.0285 7.99925L39.0022 7.73337L38.9859 7.54179L25.5876 8.03836H25.5859Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.0695 31.9419L15.8677 31.9378L15.9913 32.0972L17.4489 33.9728L17.5423 34.0932L17.6108 33.957L18.5282 32.1316L18.5967 31.9955L18.4442 31.9924L16.0695 31.9419ZM39.651 12.0754L39.6158 12.0174L39.5495 12.0307L37.4391 12.4555L37.1599 12.5118L37.4161 12.6363L40.7681 14.2648L41.0608 14.4071L40.8925 14.1284L39.651 12.0754ZM32.7507 8.98151L32.4746 8.86894L32.6329 9.12179L33.9313 11.1934L33.9701 11.2558L34.0402 11.2345L36.2175 10.5801L36.4781 10.5017L36.2261 10.3991L32.7507 8.98151Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.68381 25.9317L5.5183 25.8335L3.86752 28.4758L3.86503 28.4796L3.86309 28.4838L2.57275 31.0553L2.74243 31.1457L5.68381 25.9317Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.0698 15.9817L42.8629 13.9547L42.8318 13.9028L42.7717 13.9081L40.8012 14.0825L40.5018 14.1091L40.761 14.2613L43.9278 16.1192L45.1106 18.1054L45.1367 18.1495L45.1877 18.1523L47.0112 18.2557L47.3491 18.2748L47.0716 18.081L44.0698 15.9817ZM15.3331 20.8172L15.2599 20.7183L15.1817 20.8134L13.7821 22.5149L13.6196 22.5024L13.7022 22.6119L13.6327 22.6962L13.7738 22.707L15.1872 24.5829L14.0244 26.2847L13.8714 26.2775L13.9548 26.3867L13.8969 26.4713L14.0241 26.4774L15.4797 28.3782L15.5618 28.4852L15.6364 28.3732L16.8097 26.6088L16.9597 26.6157L16.8774 26.5065L16.9336 26.4219L16.8094 26.4161L15.4273 24.5821L16.7526 22.9363L16.912 22.9485L16.8308 22.8387L16.8987 22.7544L16.7606 22.7439L15.3331 20.8172ZM17.6004 33.8551L17.494 33.7181L17.4344 33.8809L16.8105 35.5765L16.6722 35.5713L16.7678 35.6927L16.7407 35.7665L16.8285 35.7698L18.2586 37.5872L18.367 37.7247L18.425 37.5595L19.023 35.8546L19.1583 35.8599L19.0638 35.7382L19.0896 35.6647L19.0042 35.6614L17.6004 33.8551ZM36.2313 10.4017L35.9349 10.2581L36.1074 10.5386L37.3758 12.6003L37.411 12.6574L37.4767 12.6441L39.5871 12.2194L39.8672 12.1631L39.6102 12.0386L36.2313 10.4017Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.56494 25.7951L5.62038 25.9786L12.5833 24.505L12.6205 24.4972L12.6421 24.4662L13.9036 22.6699L14.0888 22.4062L13.7894 22.5251L5.56494 25.7951Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.6167 12.0421L39.4858 12.1749L40.7274 14.228L40.7584 14.2793L40.818 14.274L42.7885 14.0996L43.0887 14.073L42.8287 13.9208L39.6167 12.0421Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5193 1.35566L49.4428 1.18099L25.563 8.04191L25.5932 8.23044L38.9931 7.73387L39.0181 7.73304L39.0394 7.72001L49.5193 1.35566Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.6325 28.2614L15.5421 28.1433L15.4714 28.2742L14.498 30.0824L14.4248 30.218L14.579 30.2241L17.0085 30.3172L17.2129 30.3253L17.0884 30.1628L15.6325 28.2614ZM34.9795 8.338L34.9404 8.27422L34.8689 8.29667L32.6855 8.97899L32.4302 9.05885L32.6778 9.15977L36.1531 10.5771L36.4254 10.6883L36.2712 10.4376L34.9795 8.338Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.4813 1.36898L49.4675 1.17741L44.0887 1.2872L44.0793 1.28749L44.0705 1.28941L37.9653 2.6072L37.9975 2.79657L49.4813 1.36898Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5861 30.0317L14.3799 30.024L14.5066 30.187L15.9577 32.0543L15.3272 33.6887L15.1839 33.6801L15.2823 33.8051L15.2546 33.8769L15.343 33.8822L16.8008 35.7348L16.9073 35.8701L16.9669 35.7085L17.5902 34.0142L17.7305 34.0225L17.6331 33.8972L17.6595 33.8256L17.5735 33.8204L16.1809 32.0279L17.0975 30.2657L17.1674 30.131L17.0157 30.1251L14.5861 30.0317ZM45.9928 16.2273L46.1458 16.2337L45.8927 16.0571L45.8769 16.0299L45.8525 16.0291L42.8521 13.9369L41.6225 11.8722L41.5911 11.8195L41.5302 11.8256L39.619 12.0233L38.4557 10.0997L38.5976 10.0692L38.3711 9.95998L38.3506 9.92559L38.3157 9.93309L34.9393 8.30172L34.6434 8.15866L34.8156 8.43869L36.0361 10.4227L35.8958 10.4529L36.122 10.5624L36.1431 10.5966L36.1772 10.5891L39.5228 12.2102L42.7101 14.0744L43.8241 15.9453L43.673 15.9389L43.9256 16.1156L43.9417 16.1424L43.9655 16.1435L46.9615 18.2385L47.0993 18.1109L45.9928 16.2273ZM13.9013 22.5567L13.8217 22.4511L13.7458 22.5592L12.5153 24.3114L12.3584 24.302L12.4438 24.4135L12.3856 24.4964L12.5134 24.5041L13.997 26.4418L14.0779 26.5475L14.1528 26.4377L15.3541 24.6794L15.5077 24.6888L15.4237 24.5773L15.4803 24.4944L15.3555 24.4869L13.9013 22.5567Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.5247 26.2949L11.5834 26.298L11.6128 26.247L12.6464 24.4589L12.7543 24.2721L12.5433 24.3167L5.58008 25.7903L5.59478 25.9805L11.5247 26.2949Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.8556 8.47497L38.2359 10.1083L39.4858 12.1749L39.5174 12.227L39.5778 12.2209L41.4886 12.0233L42.6082 13.9033L42.4623 13.8997L42.708 14.0711L42.7244 14.0988L42.7488 14.0993L45.7481 16.1907L46.9333 18.2085L46.9555 18.2459L46.9979 18.2542L48.6626 18.5784L48.7421 18.4098L45.8947 16.0606L44.7727 14.1506L44.9205 14.1542L44.6743 13.9832L44.6579 13.9552L44.6332 13.9546L41.8075 11.9903L41.8427 11.9867L41.6098 11.8508L40.3591 9.75061L40.3269 9.69683L40.2651 9.70431L38.3537 9.93138L37.0975 7.85421L37.0617 7.79459L36.9938 7.81011L34.8759 8.29476L34.6089 8.35575L34.8556 8.47497ZM14.1495 26.3248L14.0605 26.2086L13.9893 26.3365L13.0156 28.0823L12.8647 28.074L12.9551 28.1904L12.9113 28.2692L13.0211 28.2753L14.4725 30.1431L13.816 31.7651L13.6674 31.7534L13.7689 31.8821L13.7406 31.9522L13.8293 31.9591L15.3159 33.8481L15.4205 33.9809L15.4812 33.8234L16.1308 32.1396L16.2763 32.1513L16.176 32.0221L16.2029 31.9525L16.1166 31.9458L14.6968 30.1185L15.6118 28.4192L15.7595 28.4275L15.6703 28.3108L15.7124 28.2323L15.6054 28.2262L14.1495 26.3248ZM16.952 35.6158L16.8267 35.4567L16.7826 35.6544L16.425 37.2566L16.2849 37.2439L16.3964 37.3839L16.3825 37.446L16.4507 37.4521L17.9085 39.285L18.0364 39.4458L18.0779 39.2445L18.4109 37.6312L18.5479 37.6437L18.4373 37.5034L18.45 37.4415L18.384 37.4357L16.952 35.6158Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.7316 28.0011L10.8172 28.0349L10.8546 27.9509L11.6177 26.238L11.6745 26.1102L11.5347 26.1027L5.60515 25.7883L5.56494 25.9741L10.7316 28.0011Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.6698 16.5508L47.827 16.5788L47.557 16.3565L47.5431 16.3329L47.524 16.3296L44.6636 13.9757L44.5194 14.0988L45.632 15.9927L45.477 15.965L45.7462 16.1874L45.7601 16.2109L45.7789 16.2142L48.62 18.5582L48.7644 18.4359L47.6698 16.5508ZM37.0641 7.82096L36.9335 7.95377L38.2216 10.0839L38.2535 10.1369L38.315 10.1297L40.288 9.8951L40.5738 9.86127L40.3251 9.71655L37.0641 7.82096ZM12.6394 24.3525L12.5521 24.238L12.48 24.3627L11.4464 26.1507L11.3696 26.2838L11.5229 26.2949L14.0667 26.4793L14.2799 26.4948L14.1499 26.3248L12.6394 24.3525Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.68834 25.9229L5.51645 25.8372L2.57507 31.0515L2.5698 31.0609L2.56647 31.0712L1.12061 35.9364L1.3011 36.0021L5.68834 25.9229Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.4673 33.7292L15.3448 33.5733L15.2982 33.766L14.8956 35.4373L14.8701 35.543L14.978 35.5554L16.8658 35.7709L17.0948 35.7972L16.9523 35.6159L15.4673 33.7292ZM11.5367 26.103L11.3213 26.0872L11.4538 26.2578L12.9945 28.2407L13.0835 28.3554L13.1545 28.2285L14.1576 26.4302L14.2311 26.2985L14.0805 26.2874L11.5367 26.103Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.4714 1.36951L49.473 1.17737L46.8849 1.07757L46.8793 1.07728L46.8738 1.07784L44.0835 1.28745L44.0926 1.47959L49.4714 1.36951Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.5298 12.0349L43.6723 12.036L43.433 11.8702L43.4161 11.8414L43.3912 11.8411L40.3483 9.7326L39.0718 7.58861L39.0394 7.53398L38.9762 7.54258L37.0024 7.80875L36.7246 7.84616L36.9669 7.98701L40.2066 9.87039L41.3697 11.8236L41.2291 11.8225L41.4679 11.9883L41.4848 12.0172H41.5094L44.544 14.1268L47.4077 16.4829L48.5977 18.5323L48.6152 18.5623L48.6479 18.5742L50.1353 19.1196L50.2354 18.9601L47.5586 16.3601L46.3436 14.2682L46.3209 14.2285L46.2757 14.2213L44.6621 13.9624L43.5298 12.0349ZM14.9996 35.3644L14.7689 35.338L14.9137 35.5196L16.402 37.3911L16.3044 38.9465L16.2991 39.0327L16.3842 39.0474L17.9676 39.3197L18.216 39.3626L18.0591 39.1652L16.6043 37.3362L16.9703 35.6962L16.9936 35.592L16.8874 35.5798L14.9996 35.3644ZM13.1462 28.1228L13.0458 27.9933L12.9818 28.1441L12.2764 29.8052L12.1226 29.7902L12.2268 29.9227L12.1977 29.9909L12.2872 29.9995L13.8033 31.9259L13.9059 32.0562L13.968 31.9026L14.6447 30.2305L14.7956 30.2455L14.6924 30.1126L14.7199 30.0447L14.6331 30.0361L13.1462 28.1228Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.2191 29.613L10.3297 29.6995L10.3702 29.5648L10.859 27.9393L10.8845 27.8544L10.8022 27.8223L5.63527 25.795L5.54102 25.9602L10.2191 29.613Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.5019 38.8725L16.5895 37.4757L16.7386 37.5051L16.5986 37.329L16.6017 37.2822L16.5537 37.2724L15.0934 35.4368L15.4647 33.8958L15.6144 33.916L15.496 33.7657L15.5101 33.7078L15.443 33.6986L14.1064 31.9999L13.9545 31.8069L13.8347 31.6547L12.4481 29.8931L13.1307 28.2856L13.2871 28.3039L13.1809 28.1672L13.2089 28.1012L13.1216 28.0909L11.6056 26.1399L11.5069 26.0132L11.4417 26.1596L10.7075 27.8079L10.5479 27.7893L10.6551 27.9257L10.6255 27.992L10.7156 28.0025L12.2319 29.9288L11.7858 31.5236L11.7567 31.6276L11.8634 31.6445L13.7593 31.9447L13.3769 33.4164L13.2239 33.3956L13.3434 33.5456L13.3281 33.6038L13.3972 33.6133L14.8899 35.49L14.776 36.9212L14.6232 36.8909L14.7643 37.0667L14.7604 37.1141L14.8101 37.1238L16.1667 38.8148L16.3255 39.0128L16.4808 39.2066L17.8338 40.8925L17.9929 41.091L18.0049 40.8371L18.08 39.2296L18.0839 39.1447L18.0001 39.1303L16.5019 38.8725ZM43.2887 12.0041L43.3014 12.0258L44.5191 14.0988L44.5421 14.1379L44.5868 14.1451L46.2004 14.404L47.3989 16.4679L47.417 16.4987L47.4306 16.5034L50.1013 19.0982L50.2516 18.9812L49.0475 16.8885L49.0298 16.8575L49.0159 16.8528L46.5467 14.4598L46.5966 14.4678L46.3351 14.253L45.1984 12.2958L45.3431 12.3163L45.09 12.1092L45.0753 12.084L45.0556 12.0812L42.3626 9.87789L42.4006 9.87762L42.168 9.71709L42.1508 9.68743L40.8896 7.54093L40.861 7.49186L40.8042 7.49352L38.9865 7.54176L38.6921 7.54953L38.9172 7.70506L40.108 9.70489L39.9727 9.70599L40.2045 9.8668L40.222 9.8962H40.2466L43.2887 12.0041Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.75105 31.126L2.5689 31.0653L1.65092 33.5512L1.6487 33.5573L1.64732 33.5636L1.11914 35.9427L1.30518 35.991L2.75105 31.126Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.894 31.4546L11.6494 31.4158L11.8036 31.6093L13.3736 33.5831L13.4934 33.7339L13.5419 33.5476L13.9725 31.8907L13.9991 31.7881L13.8943 31.7715L11.894 31.4546ZM49.037 16.8733L48.8812 16.9845L50.0853 19.0772L50.098 19.0988L50.1197 19.1118L51.4078 19.8779L51.5295 19.7321L49.037 16.8733Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5259 1.35126L49.4195 1.19128L38.9396 7.55563L38.6299 7.7436L38.992 7.7339L40.8094 7.68566L40.8393 7.68483L40.8637 7.66736L49.5259 1.35126Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.4759 38.8925L16.2644 38.6285L14.9624 37.006L15.0764 35.5776L15.2338 35.6128L15.0883 35.4296L15.0919 35.3841L15.0434 35.3733L13.5243 33.4636L13.3763 33.2775L13.3532 33.514L13.2113 34.9657L13.0502 34.9297L13.1969 35.1127L13.1924 35.1584L13.2426 35.1695L14.7711 37.0748L14.9255 38.5107L14.9322 38.5725L14.9912 38.5916L16.3129 39.025L16.4696 40.3003L16.3126 40.2532L16.4906 40.4736L16.4951 40.5086L16.5267 40.5183L18.0396 42.3922L18.2567 42.6609L18.2099 42.3187L18.0247 40.9677L18.1777 41.0134L18.0008 40.793L17.9961 40.7581L17.9656 40.7489L16.6783 39.1447L16.4759 38.8925ZM50.3069 17.5994L47.8158 14.7473L47.8022 14.7237L47.7884 14.719L45.0926 12.1129L43.9273 10.1066L44.0662 10.1238L43.8214 9.92421L43.8062 9.89815L43.7862 9.89566L40.8681 7.51517L40.7242 7.63826L41.9147 9.66471L41.7781 9.6478L42.0223 9.8477L42.0376 9.87376L42.057 9.87598L44.9418 12.2359L46.1778 14.3647L46.1961 14.3963L46.21 14.4008L48.8879 16.9961L48.894 17.0069L48.9026 17.0116L51.3846 19.8585L51.5404 19.7476L50.3285 17.6271L50.3155 17.6044L50.3069 17.5994ZM10.8427 27.8522L10.7282 27.707L10.675 27.8841L10.2108 29.4273L10.0439 29.3974L10.1715 29.5576L10.1551 29.6128L10.2256 29.6255L11.8037 31.6093L11.9207 31.7565L11.9714 31.5755L12.407 30.0181L12.57 30.0472L12.4438 29.8867L12.4591 29.8318L12.3909 29.8196L10.8427 27.8522Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5345 1.34411L49.4125 1.19577L40.7502 7.51187L40.5479 7.65966L40.7971 7.68545L42.4432 7.85567L42.4864 7.86012L42.5183 7.83045L49.5345 1.34411Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.0704 15.4368L46.5644 12.5728L46.5505 12.5484L45.2934 10.3639L45.274 10.3304L45.2369 10.3196L43.8107 9.90589L42.5361 7.71171L42.5117 7.66929L42.4629 7.66431L40.8169 7.49408L40.4969 7.46081L40.7462 7.66403L43.6726 10.0512L44.9327 12.221L44.9516 12.2534L44.9654 12.2578L47.6606 14.8632L47.667 14.8746L47.6756 14.8793L50.1661 17.7308L51.3733 19.8429L51.3808 19.8557L51.3916 19.8657L52.4557 20.853L52.5988 20.7257L50.3256 17.6221L49.0926 15.4648L49.0793 15.4415L49.0704 15.4368ZM15.0509 38.4089L14.7418 38.3077L14.9461 38.5608L16.4974 40.4819L16.962 41.8169L16.9748 41.8532L16.9909 41.8615L18.5316 43.7624L18.6968 43.6692L18.205 42.2993L18.1922 42.2636L18.1764 42.2555L16.6748 40.3957L16.4962 38.9407L16.4888 38.8802L16.4308 38.8611L15.0509 38.4089ZM11.954 31.4895L11.8101 31.3087L11.7832 31.5383L11.6155 32.972L11.4458 32.9301L11.5983 33.1203L11.593 33.1647L11.6438 33.1771L13.2033 35.1207L13.3328 36.5366L13.3383 36.5979L13.3963 36.6187L14.8313 37.1272L15.1502 37.24L14.9384 36.9763L13.3957 35.0536L13.5335 33.6432L13.6996 33.6842L13.5482 33.4938L13.5526 33.45L13.5036 33.4378L11.954 31.4895Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5443 1.33407L49.4043 1.20293L42.3878 7.68927L42.2598 7.80766L42.4283 7.85286L43.8839 8.2435L43.9444 8.25958L43.9837 8.21106L49.5443 1.33407Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.5199 7.69063L42.3699 7.80819L43.6638 10.0359L43.6832 10.0692L43.7201 10.08L45.1832 10.5042L45.2766 10.3426L42.5199 7.69063ZM13.4607 36.4373L13.1399 36.3237L13.3537 36.5884L14.9354 38.5472L15.3776 39.8614L15.3896 39.8975L15.4228 39.9155L16.5368 40.5191L16.6574 40.3743L15.1137 38.4618L14.9593 37.0262L14.9526 36.9661L14.8958 36.9458L13.4607 36.4373ZM10.3533 29.4775L10.2135 29.3014L10.1828 29.524L9.98867 30.9391L9.80957 30.8909L9.96815 31.0883L9.96233 31.1313L10.0136 31.1451L11.6236 33.1516L11.7675 33.331L11.7941 33.1025L11.9613 31.6714L12.1363 31.7188L11.9788 31.5208L11.9838 31.4781L11.9344 31.4648L10.3533 29.4775ZM51.3823 18.5875L51.3748 18.5742L51.3706 18.5706L49.0866 15.4557L48.9258 15.5602L50.1615 17.7225L50.169 17.7355L50.1732 17.7394L52.4436 20.8394L52.605 20.7351L51.3823 18.5875Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.8884 2.54236L53.9458 2.35882L51.8586 1.60359L51.8508 1.60081L51.8428 1.59944L49.4864 1.17884L49.4448 1.36654L53.8884 2.54236Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.8324 13.2598L47.8185 13.236L47.8096 13.2313L45.2883 10.3556L43.9921 8.10264L43.9722 8.06798L43.9336 8.05771L42.4778 7.66706L42.3863 7.82926L45.1333 10.4715L46.4023 12.677L46.4159 12.7006L46.4248 12.7053L48.9367 15.576L49.0925 15.4648L47.8324 13.2598ZM15.5144 39.7464L15.3937 39.8914L16.978 41.846L17.1435 41.7537L16.6733 40.4029L16.6611 40.3677L16.6284 40.3499L15.5144 39.7464ZM11.7734 33.0313L11.5796 32.7898L11.6023 33.0987L11.7082 34.5232L11.7127 34.5842L11.7698 34.6061L13.262 35.1731L13.5922 35.2984L13.3712 35.0228L11.7734 33.0313Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.3649 29.5787L10.3982 29.5089L10.3372 29.4615L5.65937 25.8087L5.52462 25.7036L5.50465 25.8733L3.39893 43.765L3.58136 43.8176L10.3649 29.5787Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.8383 34.4262L11.5059 34.3L11.7293 34.5767L13.3535 36.5885L13.5531 36.8355L13.524 36.5191L13.392 35.0744L13.3865 35.0145L13.3305 34.9931L11.8383 34.4262Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.6955 25.8957L5.51196 25.8461L1.1247 35.9253L1.11084 35.9572L1.12055 35.9908L3.40205 43.8029L3.58975 43.7874L5.6955 25.8957ZM49.5528 1.32165L49.3945 1.21296L43.8339 8.09022L43.7585 8.18367L43.8661 8.23689L45.1095 8.85074L45.1902 8.89039L45.2354 8.81248L49.5528 1.32165Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.135 16.3962L50.1278 16.3837L50.1237 16.3798L47.83 13.2552L46.5471 11.0111L46.533 10.9864L46.5241 10.9817L43.9814 8.08717L43.8256 8.19862L45.1267 10.4599L45.1409 10.4843L45.15 10.489L47.6705 13.3639L48.9259 15.5602L48.9337 15.5738L48.9379 15.5777L51.218 18.6874L52.4379 20.8303L52.4396 20.8333L52.4415 20.8364L53.2541 22.0444L53.3553 21.9795L53.4174 21.9429L52.1817 19.7701L52.1801 19.7667L52.1792 19.7656L50.135 16.3962ZM16.1488 41.0745L16.9786 41.8475L17.7377 43.0327L17.7444 43.0433L17.7485 43.0471L19.3167 44.979L19.472 44.8665L18.6873 43.6496L18.6807 43.6393L18.6768 43.6357L17.1278 41.7249L15.5547 39.7839L15.1124 38.4697L15.1005 38.435L15.0844 38.4259L13.5035 36.4676L13.3372 36.5577L13.7614 37.8667L13.773 37.9021L13.7897 37.9119L15.3908 39.8873L16.133 41.0556L16.1397 41.0659L16.1488 41.0745ZM10.1443 31.0005L9.95663 30.7665L9.97326 31.0659L10.0429 32.318L9.83936 32.2356L10.0531 32.5001L10.0548 32.5301L10.0883 32.5437L11.7297 34.5768L11.9232 34.8163L11.9002 34.5091L11.8062 33.2387L12.0044 33.3189L11.7923 33.0546L11.7901 33.0247L11.7579 33.0117L10.1443 31.0005Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5586 1.30912L49.3859 1.22539L45.0688 8.71621L45.0283 8.78637L45.0904 8.83821L46.0958 9.67746L46.1978 9.76258L46.2469 9.6392L49.5586 1.30912Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.8635 14.1628L48.8555 14.1487L48.8513 14.1448L46.5443 11.0063L45.2356 8.71675L45.2212 8.69125L45.1949 8.67822L43.9514 8.06438L43.8366 8.2141L46.3848 11.115L47.6655 13.3555L47.6735 13.3693L47.6777 13.3732L49.9744 16.5018L50.1355 16.3972L48.8635 14.1628ZM16.2796 40.9336L16.1396 41.0647L17.7438 43.0415L17.8996 42.929L17.1341 41.7334L17.1274 41.7232L17.1186 41.7151L16.2796 40.9336ZM11.879 34.4558L11.7124 34.5448L12.1119 35.8313L12.123 35.8668L12.1399 35.8767L13.7779 37.8976L13.9443 37.8075L13.5201 36.4983L13.5087 36.4637L13.4924 36.454L11.879 34.4558Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.30885 35.9591L1.11671 35.9588L1.00054 38.2045L1 38.2142L1.00137 38.2242L1.32299 40.3031L1.51401 40.2837L1.30885 35.9591Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.9207 17.5532L50.9188 17.5498L50.9179 17.5487L48.8618 14.1604L48.696 14.258L49.968 16.4924L49.97 16.4957L49.9708 16.4968L52.0156 19.8674L52.1814 19.7701L50.9207 17.5532ZM13.9271 37.7765L13.771 37.8883L14.4952 39.0381L14.5018 39.0483L14.5104 39.0566L15.4023 39.9006L15.5432 39.7703L13.9271 37.7765Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.0475 5.11386L57.1507 4.95305L53.9739 2.3746L53.9578 2.36157L53.9379 2.3563L49.4943 1.18047L49.4272 1.35986L57.0475 5.11386Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.5654 11.8829L47.557 11.8683L47.5529 11.8646L45.2292 8.70729L45.0684 8.81209L46.3798 11.1064L46.3881 11.1211L46.3923 11.1247L48.7021 14.2673L48.8632 14.1628L47.5654 11.8829ZM14.6427 38.9168L14.5018 39.0474L16.1393 41.0647L16.2951 40.9524L15.5496 39.7793L15.5432 39.7691L15.5346 39.761L14.6427 38.9168ZM10.2219 32.4036L10.0547 32.4912L10.4287 33.7541L10.4392 33.7895L10.4706 33.8087L11.7537 34.598L11.8787 34.4558L10.2219 32.4036Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5619 1.29888L49.3797 1.23789L46.068 9.56823L46.0488 9.61592L46.0782 9.65834L46.8174 10.7258L46.9347 10.8955L46.9893 10.6964L49.5619 1.29888Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.5713 33.6448L10.4463 33.7873L12.1289 35.8634L12.2956 35.7741L11.896 34.4877L11.8852 34.4533L11.8545 34.4342L10.5713 33.6448ZM49.6341 15.2904L49.6321 15.2871L49.6313 15.286L47.5641 11.8805L47.3983 11.9781L48.6964 14.2579L48.6983 14.2612L48.6989 14.2624L50.755 17.6504L50.9208 17.5531L49.6341 15.2904Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.1544 31.1047L10.1622 31.09L10.1644 31.0736L10.3734 29.5504L10.1913 29.4958L3.40771 43.7347L3.5799 43.8203L10.1544 31.1047Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.2784 35.7423L12.1221 35.8537L12.8243 36.9786L12.8307 36.9888L12.8349 36.993L14.5023 39.0474L14.6581 38.9354L13.9339 37.7859L13.9278 37.7759L13.9237 37.7718L12.2784 35.7423ZM46.2411 9.55602L46.2325 9.54104L46.2192 9.52994L45.2139 8.69043L45.0747 8.82129L47.4047 11.9875L47.5655 11.883L46.2411 9.55602Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5637 1.29226L49.3766 1.24818L46.8037 10.6457L46.7959 10.6742L46.8056 10.702L47.2467 11.9989L47.3621 12.338L47.4322 11.9867L49.5637 1.29226Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.3211 12.9812L48.3189 12.9776L48.3181 12.9765L46.2398 9.55384L46.074 9.65145L47.3987 11.9781L47.4006 11.9818L47.4015 11.9829L49.4684 15.3881L49.6342 15.2905L48.3211 12.9812ZM10.5958 33.6663L10.4395 33.7775L11.1182 34.8765L11.1245 34.8865L11.1329 34.8948L12.1368 35.8718L12.2785 35.7423L10.5958 33.6663Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.2302 32.5126L10.2449 32.4876L10.2432 32.4585L10.165 31.0551L10.1456 30.7033L9.98369 31.0163L3.40918 43.7319L3.57747 43.8248L10.2302 32.5126Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.2672 34.7569L11.1255 34.8864L12.8312 36.9882L12.9875 36.8768L12.2855 35.752L12.2791 35.742L12.2708 35.7339L11.2672 34.7569Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.30523 35.9366L1.1167 35.9682L1.32186 40.2928L1.32325 40.3169L1.33545 40.3375L3.4118 43.8253L3.58675 43.7491L1.30523 35.9366ZM57.029 5.1022L57.1593 4.96136L55.755 3.48858L55.7467 3.47999L55.7364 3.47334L53.9639 2.36766L53.8522 2.52402L57.029 5.1022ZM49.5642 1.28914L49.3751 1.25475L47.2436 11.949L47.2408 11.9617L47.2419 11.9748L47.352 13.501L47.5427 13.5096L49.5642 1.28914Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.9799 10.6235L46.9779 10.6199L46.9754 10.6162L46.2366 9.54883L46.0752 9.65363L48.1549 13.0785L48.3207 12.9812L46.9799 10.6235Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.6 33.7818L10.6263 33.7438L10.6133 33.6995L10.2393 32.4366L10.1766 32.2242L10.0643 32.4153L3.41162 43.7272L3.57324 43.8311L10.6 33.7818Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.7664 51.8163L10.6289 51.9505L12.3376 53.8738L12.3437 53.8805L12.3509 53.886L14.3081 55.3879L14.4323 55.2412L10.7664 51.8163Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.5643 1.28886L49.3747 1.25781L49.3744 1.2581L47.3529 13.4783L47.3527 13.48L47.0965 15.2316L47.0962 15.2333L47.2864 15.2627L47.2867 15.261L49.5643 1.28915V1.28886ZM11.2729 34.8886L11.3189 34.8351L11.2818 34.7752L10.603 33.6762L10.5262 33.5517L10.4422 33.6718L3.41553 43.7208L3.56745 43.8386L11.2729 34.8886Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.1011 16.5278L62.922 16.5941L63.4854 18.913L63.4588 18.9183L63.5117 19.0211L63.5309 19.0999L63.55 19.096L64.7156 21.3634L64.8944 21.2963L64.3072 18.9424L64.3327 18.9371L64.28 18.8343L64.2606 18.7555L64.2417 18.7594L63.1011 16.5278ZM9.0218 49.8528L8.88428 49.987L10.629 51.9505L10.6354 51.9577L10.639 51.9602L14.301 55.3818L14.4388 55.2476L12.7054 53.2893L12.699 53.2821L12.6954 53.2794L9.0218 49.8528Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.50093 40.2391L1.32959 40.3258L2.11837 42.1973L2.12363 42.2095L2.13196 42.2198L3.41924 43.8359L3.57728 43.7269L1.50093 40.2391Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.9025 14.1826L61.7231 14.2483L62.2837 16.6265L62.3023 16.7047L62.3824 16.7006L63.0204 16.6676L63.1687 16.6598L63.1011 16.5276L61.9025 14.1826Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.2577 35.8052L12.3345 35.7342L12.2574 35.6638L11.2646 34.755L11.1914 34.6879L11.1268 34.763L3.42139 43.7133L3.55947 43.8467L12.2577 35.8052Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.24007 47.8475L7.10254 47.9817L8.88445 49.987L8.89082 49.9942L8.89859 50L10.8047 51.4293L10.9281 51.2821L7.24007 47.8475Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.5964 14.9125L57.7747 14.8571L57.1858 5.02181L57.1825 4.96607L57.1323 4.9414L49.512 1.18711L49.2173 1.04212L49.3872 1.32325L57.5964 14.9125Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.9343 51.2887L10.9279 51.2813L10.9198 51.2754L9.014 49.8462L8.89062 49.9934L12.5642 53.4203L14.2946 55.3752L14.3079 55.3904L14.3265 55.3987L16.8079 56.5446L16.9052 56.3799L12.6984 53.2816L10.9343 51.2887ZM62.3725 16.5085L62.2239 16.5162L62.2918 16.6485L63.5167 19.0314L63.6958 18.9646L63.1089 16.5489L63.0903 16.4713L63.0104 16.4755L62.3725 16.5085ZM60.6782 11.7871L60.4988 11.8523L61.0317 14.1909L61.0475 14.2596L61.1176 14.2655L61.8088 14.322L61.981 14.3362L61.9025 14.1826L60.6782 11.7871Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.5647 36.5022L13.689 36.4113L13.5525 36.3397L12.2375 35.6496L12.1774 35.618L12.1275 35.6641L3.4292 43.7055L3.55146 43.8536L13.5647 36.5022Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.41975 45.7988L5.28223 45.933L7.10268 47.9816L7.10933 47.9891L7.11294 47.9919L10.7968 51.4226L10.9343 51.2887L9.12468 49.244L9.11803 49.2365L9.11415 49.2337L5.41975 45.7988ZM63.6878 18.9435L63.5062 18.9887L63.542 21.7887L63.5436 21.9293L63.674 21.8769L64.837 21.4086L64.9349 21.3692L64.8867 21.2755L63.6878 18.9435ZM61.1335 14.0736L60.961 14.0594L61.0401 14.2136L62.2919 16.6487L62.471 16.5824L61.9103 14.2042L61.8945 14.1362L61.8247 14.1304L61.1335 14.0736Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.682 14.9589L57.8453 14.953L57.7607 14.813L49.5518 1.22375L49.4165 1L49.3744 1.25813L47.0965 15.2317L47.0776 15.3476L47.1949 15.3434L57.682 14.9589Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.919 51.2749L10.7901 51.416L12.5612 53.4169L12.5748 53.4324L12.5845 53.4366L16.7909 56.5349L16.9204 56.3941L15.169 54.397L15.1552 54.3815L15.1454 54.377L10.919 51.2749ZM63.6021 21.6983L63.5037 21.738L63.5527 21.832L64.7824 24.1942L64.9637 24.1476L64.8971 21.3174L64.8941 21.1785L64.7652 21.2303L63.6021 21.6983ZM59.4272 9.34004L59.2475 9.40436L59.7519 11.7009L59.7655 11.7621L59.8267 11.7746L60.5734 11.9252L60.7688 11.9645L60.6782 11.7871L59.4272 9.34004ZM3.5596 43.7056L3.42236 43.84L5.28216 45.933L5.2891 45.9408L5.29743 45.9469L7.14614 47.2957L7.2684 47.1477L3.5596 43.7056Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.2005 36.9481L15.3968 36.8317L15.1764 36.7723L13.5328 36.332L13.4882 36.3201L13.4508 36.3473L3.4375 43.6987L3.54342 43.8589L15.2005 36.9481Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.27482 47.1543L7.26788 47.1466L7.25928 47.1405L5.41057 45.7916L5.28857 45.9397L8.98685 49.3782L9.12436 49.244L7.27482 47.1543ZM62.4626 16.5605L62.281 16.6046L62.2846 19.373L62.2849 19.5039L62.4096 19.4645L63.6309 19.0791L63.7396 19.0447L63.6875 18.9435L62.4626 16.5605Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.4468 9.16731L58.6318 9.11546L58.1577 6.86334L58.1549 6.85031L58.1488 6.83839L57.1748 4.98301L56.999 5.05953L58.4468 9.16731Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9264 11.5986L59.4712 9.52445L59.5311 9.54274L59.4332 9.35088L59.4238 9.30847L59.4091 9.30375L58.1493 6.83926L57.9696 6.90302L58.4099 8.99518L58.3475 8.97577L58.4462 9.16763L58.4553 9.21033L58.4706 9.21532L59.6692 11.5465L59.7493 11.7025L59.685 14.3756L59.6822 14.4906L59.7959 14.4729L61.0282 14.2818L61.0005 16.827L60.9531 16.8381L60.9994 16.9266L60.9983 17.025L61.0449 17.0142L62.2429 19.3156L62.2959 19.4171L63.5377 21.8026L62.9607 24.902L62.9127 24.9234L62.9452 24.9844L62.9177 25.1321L63.0031 25.0936L64.2278 27.3995L64.3594 27.6477L64.4074 27.3707L64.9539 24.2167L65.0002 24.1956L64.968 24.134L64.9935 23.9879L64.9112 24.0248L63.734 21.7635L63.6985 18.9863L63.6968 18.8568L63.5734 18.8956L62.4772 19.2416L62.4739 16.6809L62.5193 16.6704L62.4736 16.5814V16.4835L62.4287 16.494L61.2722 14.2441L61.2168 14.1363L60.6864 11.8096L60.6725 11.7489L60.6115 11.7367L59.9264 11.5986ZM10.8044 51.4321L10.8141 51.4362L15.0314 54.5318L15.0453 54.5476L16.7759 56.5208L16.7961 56.5438L16.8258 56.551L19.8481 57.2733L19.8789 57.1948L19.9432 57.1169L18.1821 55.0782L18.1616 55.0544L18.1444 55.0505L13.3682 52.3437L13.3648 52.3398L11.5485 50.269L11.5339 50.2524L11.5239 50.2482L7.26832 47.1466L5.3841 45.0178L5.37689 45.0098L5.3683 45.0037L3.55063 43.6981L3.4292 43.8467L7.13413 47.2855L8.98064 49.3716L8.99533 49.3879L10.7903 51.416L10.8044 51.4321Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.53947 43.6911L3.43799 43.8542L5.25567 45.1598L5.26536 45.1667L5.27674 45.1711L7.65391 46.1041L7.73431 45.9297L3.53947 43.6911Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.36861 45.0039L5.24023 45.1453L7.1311 47.2818L7.14608 47.2988L7.15605 47.3026L11.4197 50.4101L11.5486 50.269L9.67656 48.1347L9.6616 48.1175L9.65132 48.1133L5.36861 45.0039ZM62.4667 19.3284L62.3405 19.0863L62.287 19.3539L61.656 22.5104L61.6213 22.6831L61.7855 22.6188L63.6735 21.8771L63.7731 21.838L63.7234 21.7432L62.4667 19.3284ZM59.7665 14.2829L59.634 14.3034L59.6958 14.4224L61.0105 16.9479L61.1921 16.9046L61.2221 14.1706L61.2232 14.0572L61.1112 14.0744L59.7665 14.2829Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.5823 14.8685L57.7736 14.8771L58.6325 9.14964L58.6361 9.1258L58.628 9.10332L57.1802 4.99583L56.9937 5.03354L57.5823 14.8685Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.5232 50.2487L11.4037 50.3956L13.2341 52.4828L13.2552 52.5069L13.2719 52.5102L18.0473 55.2168L19.7973 57.2427L19.8236 57.2732L19.8641 57.2757L23.4348 57.5027L23.4787 57.3184L18.1673 55.0615L16.3807 52.9935L16.3596 52.9691L16.3424 52.9652L11.5232 50.2487ZM61.7146 22.4396L61.6143 22.479L61.665 22.5744L62.9407 24.9762L61.7019 28.3729L61.6267 28.5791L61.8294 28.4946L64.3494 27.4429L64.447 27.4025L64.3973 27.3093L63.1464 24.9535L63.7325 21.8053L63.7641 21.6348L63.6027 21.698L61.7146 22.4396ZM58.623 9.09129L58.4552 8.76524L58.4414 9.1315L58.3427 11.705L58.2833 11.71L58.3385 11.8159L58.3355 11.8985L58.3798 11.8951L59.6954 14.4223L59.8767 14.3802L59.9399 11.7693L59.9973 11.7646L59.9424 11.6581L59.9444 11.5758L59.902 11.5794L58.623 9.09129ZM61.1806 16.8591L61.0575 16.6223L61.0015 16.8835L60.33 20.0126L60.2945 20.1781L60.4548 20.124L62.4117 19.4639L62.5181 19.4281L62.4663 19.3283L61.1806 16.8591ZM7.76117 45.9513L7.74593 45.9335L7.72403 45.9249L5.34685 44.9922L5.25537 45.1594L9.54751 48.2758L9.67616 48.1346L7.76117 45.9513Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.6986 14.9569L57.6753 14.7667L47.1882 15.1509L47.1217 15.1534L47.1006 15.2167L46.4421 17.1913L46.3892 17.3504L46.5533 17.3157L57.6986 14.9569Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.65096 48.1141L9.53174 48.2613L11.4035 50.3956L11.4251 50.4203L11.442 50.4236L16.2463 53.1318L18.0365 55.204L18.0631 55.2351L18.0872 55.2362L23.4033 57.4952L23.5145 57.3447L21.7536 55.2614L21.7267 55.2295L21.7018 55.2281L16.366 52.9763L14.5384 50.8606L14.5168 50.8353L14.4993 50.832L9.65096 48.1141ZM60.3933 19.9416L60.286 19.9779L60.3392 20.078L61.665 22.5741L61.7912 22.8123L61.8441 22.548L62.4752 19.3915L62.5079 19.2285L62.3501 19.2817L60.3933 19.9416ZM61.7551 28.3169L61.6567 28.3582L61.708 28.4519L63.0175 30.8515L63.1212 31.0414L63.1927 30.8374L64.4032 27.3861L64.4747 27.1823L64.2754 27.2655L61.7551 28.3169ZM59.8659 14.3333L59.7456 14.1021L59.6871 14.3563L58.9735 17.4566L58.9371 17.6149L59.0935 17.5705L61.1216 16.996L61.2353 16.9639L61.1807 16.8591L59.8659 14.3333Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.51777 43.6828L3.44873 43.8608L7.64357 46.0993L7.66159 46.109L7.68183 46.1104L14.2045 46.5831L14.235 46.3941L3.51777 43.6828ZM46.5128 17.1275L46.4676 17.1372L46.4468 17.1785L45.3885 19.2956L45.2801 19.5121L45.5075 19.4287L57.7113 14.953L57.6584 14.7686L46.5128 17.1275Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.8349 22.484L61.7354 22.2966L61.66 22.4951L60.3777 25.8751L60.3147 25.8989L60.3466 25.9569L60.2865 26.1152L60.4079 26.0694L61.7079 28.4519L61.81 28.6385L61.8829 28.4386L63.1211 25.0433L63.1821 25.0203L63.1508 24.9618L63.2082 24.8049L63.0912 24.849L61.8349 22.484ZM7.73592 45.9305L7.6167 46.078L9.53168 48.2613L9.55387 48.2866L9.57104 48.2896L14.4039 50.9989L16.2354 53.1191L16.2628 53.1507L16.287 53.1518L21.6216 55.4031L23.3677 57.4689L23.3996 57.5066L23.449 57.5025L27.4323 57.1756L27.4525 56.9879L21.7355 55.2398L19.952 53.1293L19.9245 53.0972L19.8996 53.0961L14.5236 50.8434L12.653 48.678L12.6308 48.6523L12.6131 48.6492L7.73592 45.9305ZM59.0413 17.3856L58.9265 17.418L58.9825 17.5231L60.3394 20.078L60.4631 20.3106L60.5182 20.053L61.1898 16.9237L61.2233 16.7673L61.0694 16.8109L59.0413 17.3856Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.4424 9.12103L57.5917 14.7922L45.4414 19.2483L45.4117 19.2594L45.3943 19.286L43.9781 21.4474L43.7868 21.7397L44.1006 21.5866L57.7204 14.9494L57.6863 14.869L57.7717 14.886L58.5291 11.817L58.5313 11.8076L58.5319 11.7976L58.6337 9.13905L58.4424 9.12103ZM15.1745 36.772L15.1363 36.7626L15.1021 36.7826L3.44531 43.6934L3.50381 43.8719L31.5984 41.0567L31.612 40.8676L15.1745 36.772Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.8767 28.3596L61.7913 28.2032L61.7076 28.3604L59.8455 31.8547L59.7729 31.8804L59.8034 31.9342L59.7116 32.1061L59.8688 32.0507L61.1858 34.3849L61.2731 34.5396L61.3549 34.3818L63.1701 30.8834L63.2405 30.8585L63.2111 30.8044L63.2995 30.6336L63.1473 30.6877L61.8767 28.3596ZM58.5211 11.7497L58.4035 11.5237L58.3423 11.771L57.5992 14.7825L57.5346 14.7972L57.5762 14.8754L57.5477 14.9918L57.628 14.9733L58.9824 17.5232L59.1033 17.7508L59.161 17.4996L59.861 14.4581L59.9234 14.4437L59.8824 14.365L59.909 14.2496L59.8317 14.2674L58.5211 11.7497ZM60.509 19.9877L60.4114 19.8036L60.3346 19.9977L59.0068 23.3591L58.9409 23.3815L58.9738 23.4422L58.9142 23.5936L59.0343 23.5529L60.3676 25.996L60.4677 26.1796L60.542 25.9838L61.8237 22.6058L61.8878 22.5839L61.8553 22.5226L61.9122 22.3726L61.7966 22.4117L60.509 19.9877ZM25.7487 54.8891L25.7162 54.8498L25.6841 54.8525L19.9333 53.1078L18.1073 50.9474L18.0796 50.9144L18.0543 50.9139L12.6382 48.6609L10.7229 46.444L10.7002 46.4176L10.6661 46.4121L7.70475 45.9197L7.64209 46.0985L12.5184 48.8167L14.3929 50.9862L14.4206 51.0187L14.445 51.0192L19.8199 53.2714L21.6068 55.3857L21.6392 55.4239L21.6703 55.4209L27.3961 57.1717L27.7008 57.2652L27.4987 57.019L25.7487 54.8891Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.0163 21.4136L43.9972 21.423L43.9836 21.4399L42.24 23.6075L42.3628 23.7511L57.7262 14.9464L57.6364 14.7764L44.0163 21.4136ZM17.1522 36.9312L17.1436 36.9289L17.1347 36.9281L15.159 36.7695L15.1279 36.9586L31.5635 41.0536L31.5657 41.0542L31.6142 40.8682L31.612 40.8676L17.1522 36.9312Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.6943 54.8561L25.4268 54.8001L25.6003 55.0111L27.3503 57.1409L27.3872 57.1859L27.4443 57.1739L31.6478 56.2898V56.1015L25.6943 54.8561ZM60.5367 25.9037L60.4524 25.7492L60.3679 25.9037L58.4398 29.4248L58.3234 29.6374L58.5541 29.5623L61.8223 28.4971L61.9324 28.4613L61.8769 28.3596L60.5367 25.9037Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.2673 23.5842L42.2553 23.5911L42.2456 23.6014L40.2114 25.734L40.3323 25.8818L57.7297 14.9442L57.6307 14.7795L42.2673 23.5842Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.1526 17.433L59.0564 17.2523L58.9785 17.4414L57.5864 20.8247L57.5093 21.0118L57.7028 20.9536L60.452 20.1249L60.5641 20.0911L60.5092 19.9877L59.1526 17.433ZM58.4944 29.3796L58.3826 29.4159L58.4403 29.5182L59.8293 31.9802L59.9156 32.133L59.9979 31.978L61.8774 28.4511L61.9897 28.2404L61.7629 28.3144L58.4944 29.3796ZM23.9572 52.7088L23.9242 52.6686L23.8921 52.6722L18.0617 50.9175L17.7515 50.824L17.9608 51.0716L19.8051 53.2536L19.8378 53.2927L19.8691 53.2894L25.6465 55.0422L25.9504 55.1342L25.7488 54.8891L23.9572 52.7088Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.69608 45.9186L7.67334 46.1094L10.6347 46.6018L10.6427 46.6032H10.651L14.2124 46.5835L14.2187 46.3913L7.69608 45.9186Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.6872 46.4179L10.5776 46.5696L12.5076 48.8037L12.5362 48.8367L12.5794 48.8369L16.1449 48.8713L16.1826 48.6864L10.6872 46.4179Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.2295 25.7188L40.2225 25.7232L40.2164 25.7288L37.9343 27.7852L38.0516 27.9368L57.7294 14.9442L57.7313 14.9431L57.6271 14.7814L57.6252 14.7825L40.2295 25.7188ZM19.4649 36.777L19.4468 36.771L19.4277 36.7723L17.1204 36.9281L17.1016 37.117L31.5634 41.0537L31.6194 40.8699L19.4649 36.777Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.2193 48.7133L16.191 48.6795L16.1469 48.6792L12.5814 48.6448L12.5435 48.8297L17.9973 51.0982L18.1076 50.9474L16.2193 48.7133ZM57.6476 20.7692L57.5342 20.8036L57.591 20.9076L58.9958 23.4819L59.0943 23.6621L59.1697 23.4711L60.5138 20.0681L60.587 19.8834L60.3968 19.9408L57.6476 20.7692ZM59.997 31.8857L59.9219 31.7523L59.8345 31.8779L57.3653 35.4215L57.2084 35.6472L57.4718 35.5687L61.2973 34.4298L61.4129 34.3954L61.3539 34.2903L59.997 31.8857Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.9459 27.7762L37.9425 27.7782L37.9395 27.7807L35.4575 29.7184L35.5704 29.8739L57.7315 14.943L57.732 14.9427L57.6253 14.7825L57.6247 14.783L37.9459 27.7762Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.4164 35.3843L57.2988 35.4193L57.3609 35.5252L58.768 37.9254L58.8448 38.0562L58.9302 37.9309L61.3489 34.3918L61.502 34.168L61.2422 34.2454L57.4164 35.3843Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.4631 29.7145H35.4628L32.8328 31.4931L32.8325 31.4934L32.9401 31.6528L57.7321 14.9425L57.6786 14.8629L57.6248 14.7831L57.6234 14.7839L35.4631 29.7145Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.9741 53.9603L29.9369 53.9132L29.8984 53.9218L23.9022 52.6755L23.6353 52.6201L23.8085 52.8308L25.6001 55.0111L25.6376 55.0569L25.675 55.0485L31.6082 56.2901L31.8688 56.3444L31.703 56.1359L29.9741 53.9603ZM59.1644 23.3896L59.0812 23.2373L58.9961 23.3885L57.0176 26.9023L56.9009 27.1097L57.1288 27.0415L60.4797 26.042L60.5934 26.0079L60.5365 25.9037L59.1644 23.3896ZM57.7635 14.8177L57.6692 14.6406L57.5902 14.8249L56.1476 18.1866L56.07 18.3679L56.2605 18.3178L59.092 17.5711L59.2093 17.5401L59.1525 17.433L57.7635 14.8177Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.0251 36.2968L21.9965 36.2832L21.9657 36.2893L19.4164 36.7737L19.0557 36.8424L19.4036 36.9594L31.5581 41.052L31.6302 40.874L22.0251 36.2968Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.0738 26.8572L56.9582 26.8919L57.0176 26.9967L58.4401 29.5183L58.5253 29.6688L58.6082 29.5172L60.5365 25.9961L60.6487 25.7909L60.4244 25.8577L57.0738 26.8572ZM29.9108 53.9246L29.6771 53.8955L29.8235 54.0799L31.5524 56.2557L31.594 56.3081L31.6578 56.2873L35.9882 54.8672L35.9702 54.6807L29.9108 53.9246ZM22.1222 50.4758L22.0887 50.4354L22.0562 50.439L16.1732 48.6831L15.8638 48.5908L16.072 48.8376L17.9604 51.0717L17.9939 51.111L18.0252 51.1074L23.855 52.8619L24.1575 52.9531L23.9571 52.7088L22.1222 50.4758Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.48456 43.6803L3.4707 43.8694L14.1879 46.5804L14.2145 46.5871L14.2406 46.579L31.6177 41.0525L31.5792 40.8651L3.48456 43.6803Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.2854 46.4251L14.2563 46.3907L14.2114 46.391L10.6501 46.4107L10.6138 46.5959L16.1092 48.8641L16.2193 48.7133L14.2854 46.4251ZM56.2117 18.1317L56.093 18.163L56.1518 18.2706L57.591 20.9076L57.6875 21.0845L57.7643 20.8982L59.1566 17.5148L59.2301 17.3357L59.0432 17.3851L56.2117 18.1317ZM58.608 29.4237L58.5336 29.2917L58.4458 29.4154L55.9447 32.9348L55.857 32.9589L55.8889 33.0133L55.7681 33.1835L55.9577 33.1308L57.3265 35.4659L54.3868 38.8705L54.2886 38.8944L54.3208 38.9468L54.17 39.1214L54.394 39.0666L55.8168 41.3935L55.8862 41.5067L55.9721 41.4057L58.9043 37.9628L58.9997 37.9395L58.9689 37.8871L59.1175 37.7125L58.898 37.7662L57.5586 35.4811L59.9723 32.0163L60.0574 31.9927L60.0267 31.9384L60.1448 31.769L59.9601 31.8203L58.608 29.4237ZM28.2035 51.732L28.1658 51.6843L28.1068 51.6979L23.8615 52.6761L23.8634 52.8641L29.8795 54.1142L30.1399 54.1683L29.9744 53.9603L28.2035 51.732Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7148 35.5125L24.6757 35.4823L24.6286 35.4962L21.9561 36.2916L21.7163 36.3628L21.9423 36.4704L31.5474 41.0479L31.6478 40.8848L24.7148 35.5125Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.3193 52.7604L34.3093 52.4618L34.2946 52.4424L34.25 52.4574L28.1437 51.6968L22.1007 50.4495L20.2429 48.1885L20.209 48.1475L20.1561 48.1544L16.1331 48.6801L16.1182 48.8675L21.9923 50.6206L23.8086 52.8308L23.8463 52.8769L23.9043 52.8635L28.0908 51.899L29.8236 54.0798L29.8658 54.1328L29.9087 54.1184L35.9066 54.8664L35.9279 54.8944L35.9728 54.8747L36.1741 54.9L36.109 54.8146L40.3531 52.9442L40.3193 52.7604ZM57.7596 20.8153L57.6776 20.6653L57.5919 20.8131L55.5807 24.284L55.4995 24.3059L55.5333 24.3658L55.4438 24.5202L55.5971 24.4786L57.0177 26.9967L57.1015 27.1453L57.1852 26.9967L59.1442 23.518L59.223 23.4966L59.19 23.4364L59.2765 23.2828L59.1282 23.323L57.7596 20.8153Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.4812 34.4509L27.4382 34.3849L27.365 34.414L24.6208 35.4989L24.4639 35.561L24.5972 35.6644L31.5302 41.037L31.67 40.9087L27.4812 34.4509Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.239 46.395L13.9307 46.3037L14.138 46.5494L16.0722 48.8375L16.106 48.8775L16.1578 48.8708L20.1808 48.3449L20.1955 48.1574L14.239 46.395ZM50.8441 42.275L50.6667 42.4452L50.9218 42.3964L52.3995 44.7065L52.463 44.8057L52.5473 44.7237L55.9448 41.4324L56.0529 41.4116L56.0207 41.3587L56.1968 41.1881L55.9459 41.2361L54.5211 38.9063L54.4587 38.8043L54.3725 38.8872L50.9215 42.2004L50.8103 42.2217L50.8441 42.275ZM57.1849 26.9021L57.1114 26.7718L57.0233 26.893L54.4689 30.4152L54.3788 30.4382L54.4116 30.4942L54.2918 30.6594L54.48 30.6109L55.9198 33.0668L55.9952 33.1952L56.0812 33.074L58.582 29.5554L58.669 29.5327L58.6377 29.477L58.7547 29.3123L58.5715 29.3594L57.1849 26.9021Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.2568 33.1373L30.2341 33.0125L30.1198 33.0682L27.3579 34.4171L27.2603 34.4645L27.3196 34.5557L31.5081 41.0132L31.6836 40.9436L30.2568 33.1373ZM54.0651 21.6753L54.2331 21.7688L56.3186 18.274L56.3219 18.2684L56.3244 18.2626L57.7667 14.9009L57.5929 14.8189L54.0651 21.6753Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.6331 50.4975L38.4671 50.5005L38.5064 50.5541L34.2204 52.4677L34.2548 52.6515L40.2637 52.9501L40.2867 52.9814L40.3369 52.9537L40.5112 52.9626L40.4563 52.8874L44.6368 50.5707L44.5885 50.3905L38.7024 50.4964L38.6861 50.4739L38.6331 50.4975ZM52.8246 36.5012L52.6754 36.6717L52.8978 36.6207L54.357 39.0067L54.4257 39.119L54.5117 39.0195L57.4963 35.5633L57.5931 35.5411L57.5618 35.4873L57.7087 35.3171L57.4913 35.367L56.0859 32.9696L56.0183 32.8545L55.9304 32.9549L52.8917 36.4247L52.7919 36.4477L52.8246 36.5012ZM26.3891 49.4492L26.3511 49.401L26.2915 49.4154L22.0254 50.4434L22.0284 50.6311L28.1086 51.886L28.3684 51.9395L28.2034 51.7318L26.3891 49.4492Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.9818 31.5863L33.0087 31.3911L32.8382 31.4901L30.1139 33.0715L30.0557 33.1054L30.0676 33.1719L31.4943 40.9782L31.6843 40.9741L32.9818 31.5863Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5957 50.2222L32.5536 50.1673L32.4882 50.1897L28.0965 51.7008L28.1159 51.8871L34.2476 52.6509L34.4752 52.6792L34.3358 52.497L32.5957 50.2222ZM47.1011 45.297L46.9489 45.3178L47.0343 45.4457L48.5084 47.6459L42.9675 48.0887L42.7998 48.102L42.8963 48.2398L44.5113 50.5418L44.5656 50.6192L44.6438 50.5662L48.6534 47.8625L48.6581 47.8697L48.7369 47.8062L49.0369 47.6038L48.9817 47.6082L52.5408 44.7295L52.8092 44.5124L52.4673 44.5596L47.1011 45.297ZM20.1876 48.1555L19.9214 48.1009L20.0938 48.3108L21.9733 50.5978L22.0116 50.6444L22.0701 50.6303L26.3362 49.6025L26.3332 49.4148L20.1876 48.1555ZM56.32 18.1786L56.2393 18.0309L56.1531 18.1753L54.0676 21.6701L53.9501 21.8669L54.173 21.8129L57.6974 20.9548L57.8191 20.9254L57.7592 20.8153L56.32 18.1786Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.8325 31.4934L32.797 31.5172L32.7909 31.5599L31.4937 40.9477L31.4546 41.2314L31.657 41.029L57.7465 14.9309L57.6248 14.7831L32.8325 31.4934Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.4869 38.8503L53.0629 36.5214L56.0545 33.1056L56.1532 33.0843L56.1211 33.0294L56.2666 32.8633L56.0509 32.9096L54.6414 30.5053L57.1582 27.0349L57.2481 27.0136L57.2156 26.9559L57.3315 26.7962L57.1497 26.8392L55.7548 24.3669L57.7582 20.9096L57.8713 20.7146L57.6523 20.7679L54.1279 21.626L54.0045 21.6559L54.0669 21.7665L55.5297 24.3594L52.957 27.8331L52.8647 27.855L52.8985 27.9124L52.7796 28.073L52.9665 28.0286L54.4085 30.4881L51.3603 33.918L51.2586 33.9399L51.2921 33.9948L51.1444 34.1612L51.3651 34.1137L52.8212 36.4953L49.3722 39.7764L49.2593 39.7969L49.294 39.8509L49.1185 40.0179L49.3713 39.9721L50.8053 42.2137L45.4981 42.9024L45.3442 42.9223L45.4307 43.0513L46.9429 45.3087L41.3132 45.7295L41.1447 45.742L41.2417 45.8806L42.7924 48.0912L37.002 48.1777L36.985 48.1547L36.9321 48.1785L36.7657 48.181L36.8059 48.2356L32.5498 50.1617L30.8131 47.891L30.7704 47.8355L30.7047 47.8589L26.3468 49.3954L24.5303 47.1097L24.4917 47.0609L24.4529 47.0709L18.2954 45.8185L18.2827 45.803L18.2455 45.8086L17.9965 45.7578L18.06 45.8352L14.1982 46.3922L14.1846 46.5796L20.1131 48.3335L20.133 48.3579L20.1666 48.3493L20.4419 48.4308L20.4153 48.3984L26.2606 49.5961L28.053 51.8516L28.0952 51.9048L28.1595 51.8826L32.4855 50.3943L34.1834 52.614L34.2297 52.6744L34.299 52.6434L38.6228 50.7129L38.6272 50.7187L38.6771 50.6907L38.8518 50.6998L38.8418 50.6863L44.592 50.5828L44.7731 50.5795L44.6691 50.4315L43.1509 48.267L48.6876 47.8247L48.8512 47.8117L48.7597 47.6753L47.2803 45.4664L52.4937 44.75L52.6426 44.7295L52.5617 44.6028L51.0953 42.3105L51.2944 42.1502L51.2453 42.1566L54.485 39.0461L54.5945 39.0261L54.5615 38.9723L54.7359 38.8051L54.4869 38.8503Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.1924 46.393L14.2257 46.5823L18.2564 46.0012L18.2619 46.0004L18.2672 45.999L22.574 44.8642L22.5299 44.6771L14.1924 46.393ZM48.1498 28.748L48.3031 28.8636L54.2244 21.7809L54.2313 21.7729L54.2363 21.7635L57.7641 14.9067L57.5989 14.8089L48.1498 28.748Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.9917 36.4055L51.5303 34.0161L54.5767 30.5876L54.6777 30.5677L54.6447 30.5114L54.7888 30.3492L54.5745 30.3919L53.1284 27.9246L55.6996 24.4528L55.7917 24.4329L55.7581 24.3735L55.8732 24.2183L55.6927 24.2574L54.2341 21.6723L54.1622 21.5445L54.0738 21.6615L51.4069 25.1859L51.3118 25.2067L51.3465 25.2658L51.2286 25.4213L51.4144 25.3811L52.8947 27.9063L49.79 31.3484L49.6863 31.3692L49.7207 31.4252L49.5743 31.5877L49.793 31.5439L51.2882 33.989L47.7835 37.2916L47.669 37.3113L47.7045 37.3662L47.5309 37.5298L47.7815 37.4871L49.254 39.7891L43.8543 40.4473L43.6987 40.4664L43.786 40.5965L45.3378 42.9132L39.617 43.3108L39.447 43.3227L39.5449 43.4621L41.1369 45.7312L35.258 45.8008L35.2411 45.7775L35.1884 45.8016L35.0215 45.8036L35.0626 45.8593L30.766 47.83L28.9846 45.5013L28.9419 45.4453L28.8757 45.4692L24.487 47.0556L22.6244 44.7114L22.5853 44.6624L22.5246 44.6785L18.2178 45.813L18.2233 46.0004L24.4013 47.2566L26.2384 49.5687L26.2811 49.6224L26.3457 49.5997L30.7025 48.0634L32.4431 50.3391L32.4896 50.3999L32.5592 50.3682L36.9218 48.3942L36.926 48.4L36.9762 48.372L37.1508 48.3812L37.1411 48.3679L42.9765 48.2808L43.1581 48.278L43.0538 48.1294L41.4962 45.9086L47.1214 45.488L47.2861 45.4758L47.1943 45.3386L45.6766 43.0732L50.9799 42.385L51.1302 42.3653L51.0487 42.2378L49.5449 39.887L49.7412 39.7295L49.6943 39.7353L52.9886 36.6012L53.0998 36.5824L53.0663 36.5275L53.2384 36.3636L52.9917 36.4055Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.6104 14.795L31.5375 40.8765L14.1826 46.3955L14.2311 46.5815L22.5687 44.8656L22.5781 44.8636L22.5867 44.8598L31.6263 41.0495L31.6046 40.9904L31.6457 41.0387L48.2861 28.8797L48.2997 28.8697L48.3089 28.8559L57.7582 14.9167L57.6104 14.795Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.701 37.3603L43.8092 40.4652L39.5733 43.3248L35.1875 45.7999L28.9171 45.4636L22.5614 44.6759L22.3276 44.6468L22.474 44.8311L24.3799 47.2291L24.4228 47.2832L24.4877 47.2599L28.8753 45.674L30.6602 48.0076L30.7071 48.0686L30.7766 48.0367L35.2318 45.9934L41.3217 45.9213L41.5041 45.9191L41.3993 45.7699L39.8004 43.4909L45.5174 43.0933L45.6829 43.082L45.5906 42.9439L44.0332 40.6194L49.4294 39.9612L49.5811 39.9429L49.4987 39.814L47.9528 37.3969L51.4558 34.0956L51.5684 34.0776L51.5343 34.0216L51.7046 33.8614L51.46 33.9002L49.9226 31.386L49.8611 31.2857L49.7749 31.3661L46.1553 34.7438L46.0392 34.7622L46.0752 34.8184L45.9036 34.9787L46.1523 34.939L47.701 37.3603Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.1553 28.7403L48.3 28.867L51.5328 25.3373L51.5358 25.3339L51.5386 25.3303L54.2271 21.7773L54.0766 21.6576L48.1553 28.7403Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.7448 40.8953L33.3997 43.3628L27.0836 43.0154L27.0675 42.9941L27.0195 43.0118L26.831 43.0016L26.8787 43.064L22.5161 44.6809L22.5375 44.8666L28.8569 45.6499L28.8791 45.6793L28.9243 45.6585L29.1244 45.6832L29.1117 45.6668L35.1597 45.9912L35.1833 46.0231L35.2332 45.9951L35.4078 46.0045L35.3518 45.928L39.5955 43.5333L39.5996 43.5392L39.6537 43.5026L39.8078 43.5012L39.759 43.4316L43.8399 40.6765L43.8444 40.6832L43.9026 40.6369L44.039 40.628L43.9994 40.569L44.2139 40.4242L44.1652 40.4273L47.867 37.474L47.9929 37.4596L47.9563 37.4027L48.1501 37.248L47.8773 37.2791L46.2803 34.7824L46.2229 34.6926L46.1395 34.7588L42.1418 37.9331L42.0123 37.9478L42.0467 37.9994L37.8521 40.8323L37.7071 40.8418L37.7448 40.8953ZM48.1096 28.7904L47.9646 28.9487L48.182 28.9085L49.7585 31.4864L49.8255 31.5965L49.9118 31.5008L53.063 28.0072L53.1659 27.988L53.1321 27.9307L53.2749 27.7723L53.0625 27.8117L51.5453 25.2238L51.4794 25.1113L51.3915 25.2075L48.1803 28.7131L48.0744 28.7328L48.1096 28.7904Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5117 44.6826L22.5827 44.8614L27.0673 43.1993L27.0711 43.1979L27.075 43.196L31.6297 41.0478L31.5515 40.8723L22.5117 44.6826Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.4002 32.2001L40.4125 35.3508L40.2816 35.3647L40.3207 35.4232L40.1297 35.574L40.4025 35.5452L42.0064 37.9395L36.1475 38.2836L36.1281 38.2559L36.0715 38.288L35.9218 38.2969L35.9604 38.3515L31.5634 40.8648L31.4001 40.8657L31.4425 40.9236L26.9932 43.0221L27.0289 43.2051L33.3719 43.5539L33.3955 43.5863L33.4454 43.5581L33.6203 43.5678L33.5641 43.4907L37.8557 41.0536L37.8598 41.0592L37.9139 41.0229L38.0686 41.0217L38.0237 40.958L38.2519 40.8285L38.2011 40.8288L42.1536 38.1594L42.1581 38.1657L42.2368 38.1031L42.5243 37.909L42.478 37.9118L46.1757 34.9757L46.1805 34.9832L46.2415 34.9263L46.3643 34.9136L46.3271 34.8556L46.5184 34.7037L46.4752 34.7084L49.884 31.5272L49.9982 31.5103L49.9633 31.4532L50.1316 31.296L49.8893 31.332L48.3114 28.7519L48.2504 28.6518L48.1642 28.7314L44.4848 32.1308L44.367 32.148L44.4002 32.2001Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.5322 40.8832L31.6398 41.0426L40.5008 35.525L40.5072 35.5214L40.5111 35.5178L44.5878 32.2969L44.5906 32.2947L44.5933 32.2922L48.2947 28.8725L48.2511 28.8235L48.1727 28.7242L31.5322 40.8832Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.5299 35.39L40.4762 35.3098L40.3963 35.3639L36.0457 38.3031L35.7554 38.4993L36.1053 38.4788L42.1854 38.1217L42.3532 38.1118L42.2597 37.972L40.5299 35.39Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.5381 40.8793L31.6368 41.0445L36.1474 38.4663L36.1505 38.4644L36.1535 38.4624L40.5039 35.5233L40.3994 35.3619L31.5381 40.8793Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};

export default FeaturesBottomLeft;
