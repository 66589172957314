import React, { ComponentProps, type FC } from 'react';

import type { IconProps } from '../../typescript';

const TopRainBow: FC<IconProps> = (props) => {
  return (
      <svg
          {...props}
      width="181"
      height="176"
      viewBox="0 0 181 176"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.182 4.9789L134.49 3.71455C133.975 20.4023 129.993 36.8306 122.771 52.0613C115.549 67.292 105.229 81.0269 92.4 92.4816C79.5711 103.936 64.4845 112.887 48.0016 118.822C31.5188 124.756 13.9626 127.559 -3.66448 127.07L-5.00024 170.335C94.3675 173.085 177.277 99.0511 180.182 4.9789Z"
        fill="#3E0563"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.213 2.7664L63.944 1.76279C63.4506 17.7428 56.2721 32.8827 43.9876 43.852C31.7032 54.8212 15.319 60.7213 -1.5605 60.2542L-2.62061 94.5903C23.875 95.3131 49.5897 86.0473 68.8723 68.8291C88.1549 51.611 99.4276 27.8494 100.213 2.7664Z"
        fill="#FA9706"
      />
    </svg>
  );
};
export default TopRainBow;
