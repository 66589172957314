import React, { type FC } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import { logo } from '../../public';
import type { IconProps, PropTypes } from '../../typescript';

const Logo: FC<PropTypes<IconProps, { drop: boolean }>> = ({ drop, ...props }) => {
  return (
    <Link href="/" className="z-20">
      <span className="z-20">
        <Image
          src={'/logo-2.png'}
          width={96}
          height={59.52}
          alt="logo"
          priority
          className="w-24 h-auto z-20"
        />
      </span>
    </Link>
  );
};

export default Logo;
