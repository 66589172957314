import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const GitHubStroked: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 48 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6922 22.5C46.6922 34.6326 36.473 44.5 23.8268 44.5C11.1807 44.5 0.961426 34.6326 0.961426 22.5C0.961426 10.3674 11.1807 0.5 23.8268 0.5C36.473 0.5 46.6922 10.3674 46.6922 22.5Z"
          stroke="white"
        />
        <path
          d="M24 11C22.4241 11 20.8637 11.3181 19.4078 11.9361C17.9519 12.5542 16.629 13.46 15.5147 14.602C13.2643 16.9083 12 20.0363 12 23.2979C12 28.7336 15.444 33.3453 20.208 34.981C20.808 35.0793 21 34.6981 21 34.3661V32.2877C17.676 33.0256 16.968 30.6398 16.968 30.6398C16.416 29.2132 15.636 28.832 15.636 28.832C14.544 28.0695 15.72 28.0941 15.72 28.0941C16.92 28.1802 17.556 29.3608 17.556 29.3608C18.6 31.2301 20.364 30.6767 21.048 30.3815C21.156 29.5822 21.468 29.0411 21.804 28.7336C19.14 28.4262 16.344 27.3685 16.344 22.683C16.344 21.318 16.8 20.2234 17.58 19.3503C17.46 19.0428 17.04 17.7639 17.7 16.1036C17.7 16.1036 18.708 15.7716 21 17.358C21.948 17.0875 22.98 16.9522 24 16.9522C25.02 16.9522 26.052 17.0875 27 17.358C29.292 15.7716 30.3 16.1036 30.3 16.1036C30.96 17.7639 30.54 19.0428 30.42 19.3503C31.2 20.2234 31.656 21.318 31.656 22.683C31.656 27.3808 28.848 28.4139 26.172 28.7213C26.604 29.1025 27 29.8527 27 30.9964V34.3661C27 34.6981 27.192 35.0916 27.804 34.981C32.568 33.333 36 28.7336 36 23.2979C36 21.6829 35.6896 20.0838 35.0866 18.5917C34.4835 17.0997 33.5996 15.7439 32.4853 14.602C31.371 13.46 30.0481 12.5542 28.5922 11.9361C27.1363 11.3181 25.5759 11 24 11Z"
          fill="white"
        />
      </svg>
  );
};
export default GitHubStroked;
