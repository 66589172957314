import React, { type FC, ReactNode, useId } from 'react';

import { type Content, type Props } from '../typescript';

import { Medal, Star, Stars } from './icons';
const content: Content[] = [
  {
    icon: <Medal />,
    header: 'Promising Talent',
    text: 'Promising Talent is an individual we consider at their early stage of professional career. We divided the category based on the years of experience and the total number of nominees. The total number of accepted nomination play a role in this process. Our team makes a final decision on the mode of separation and splitting.'
  },
  {
    icon: <Star />,
    header: 'Intermediate Talent',
    text: 'Intermediate Talent is an individual we consider at their mid-stage of professional career. We divided the category based on the years of experience and the total number of nominees. The total number of accepted nomination play a role in this process. Our team makes a final decision on the mode of separation and splitting.'
  },
  {
    icon: <Stars />,
    header: 'Exceptional Talent',
    text: 'Exceptional Talent is an individual we consider at their later stage of professional career. We divided the category based on the years of experience and the total number of nominees. The total number of accepted nomination play a role in this process. Our team makes a final decision on the mode of separation and splitting.'
  }
];
const CTA: FC<Props> = () => {
  const Id = useId();
  return (
    <div className="grid grid-cols-1 gap-8 p-6 text-[#0c0114] md:grid-cols-2 lg:grid-cols-3">
      {content.map((item, i) => (
        <div
          key={Id + i}
          className="mx-auto flex flex-col items-center gap-8 rounded-lg border-2 border-gray-200 p-6 text-center"
          data-aos={`zoom-${i % 2 != 0 ? 'in' : 'out'}`}
        >
          {item.icon}
          <h1>{item.header}</h1>
          <p className="w-10/12">{item.text}</p>
        </div>
      ))}
    </div>
  );
};

export default CTA;
