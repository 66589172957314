import React, { type FC } from 'react';

interface IUploadImageIcon {
  className?: string;
}

const UploadImageIcon: FC<IUploadImageIcon> = (props) => {
  return (
    <svg {...props} viewBox="0 0 46 46" fill="none">
      <path d="M40.8889 5.11111V40.8889H5.11111V5.11111H40.8889ZM40.8889 0H5.11111C2.3 0 0 2.3 0 5.11111V40.8889C0 43.7 2.3 46 5.11111 46H40.8889C43.7 46 46 43.7 46 40.8889V5.11111C46 2.3 43.7 0 40.8889 0ZM28.4689 22.6422L20.8022 32.5322L15.3333 25.9133L7.66667 35.7778H38.3333L28.4689 22.6422Z" fill="#7C7C7C" />
    </svg>
  );
};
export default UploadImageIcon;