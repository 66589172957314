import React, { type FC } from 'react';

import { type Url } from 'next/dist/shared/lib/router/router';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';
interface IPopUp {
  closeIcon: StaticImageData;
  icon?: StaticImageData;
  details: string;
  detailscontd: string;
  link: Url;
  close: () => void;
  opened: boolean;
  linkText?: string;
  header?: string;
}
const PopUp: FC<IPopUp> = ({
  close,
  closeIcon,
  icon,
  details,
  detailscontd,
  link,
  opened,
  linkText,
  header,
}) => {
  return (
    <div
      className={`${
        opened ? 'scale-100' : 'scale-0'
      } fixed inset-0 z-50 flex h-screen w-full origin-center flex-col items-center justify-center bg-black/70 transition-[transform] duration-500 ease-in`}
    >
      <div
        onBlur={close}
        tabIndex={0}
        className=" z-40 flex h-auto w-4/5 flex-col items-center gap-8 rounded bg-white p-10 shadow-sm lg:w-2/5"
      >
        <div className="float-right ml-auto cursor-pointer" onClick={close}>
          <Image
            src={closeIcon}
            width={96}
            height={96}
            alt="close"
            className="h-auto w-6"
          />{' '}
        </div>
        {icon != null ? (
          <Image
            src={icon}
            width={96}
            height={96}
            alt="icon"
            className="h-auto w-16"
          />
        ) : (
          <span className="facecontainer relative">
            <div className="face2">
              <div className="eye"></div>
              <div className="eye right"></div>
              <div className="mouth sad"></div>
            </div>
            <div className="move shadow"></div>
          </span>
        )}
        <h1 className="text-xl font-bold tracking-wider text-darkPurple lg:text-3xl">
          {header ?? 'Awesome!'}
        </h1>
        <span className="text-center">
          <p>{details}</p>
          <p>{detailscontd}</p>
        </span>
        {linkText && (
          <span className="rounded bg-darkPurple p-2 px-4 text-sm font-bold tracking-wider text-white lg:p-3 lg:px-8">
            <Link href={link}>{linkText}</Link>
          </span>
        )}
      </div>
    </div>
  );
};

export default PopUp;
