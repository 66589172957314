import React, { Dispatch, type FC, SetStateAction } from 'react';

import type { Props, PropTypes } from '../../typescript';
import useToggle from '../../utils/hooks/useToggle';
interface Prop {
  selected: boolean
  change: () => void
  toggleSelect: {
    setTrue: () => void
    setFalse: () => void
  }
}
const Selector: FC<PropTypes<Props, Prop>> = ({
  selected,
  change,
  toggleSelect: { setFalse, setTrue }
}) => {
  const [{ toggle, setToggle }, closeToggle] = useToggle(false);
  return (
    <div
      className="relative flex h-max w-full flex-col items-center  justify-around gap-8 rounded-sm p-3 font-bold text-white lg:flex-row"
      onClick={() => { setToggle(); }}
    >
      <span
        className={`relative w-full cursor-pointer rounded-lg px-12  py-4 text-center after:absolute after:-top-2 after:-right-2 after:h-4 after:w-4 after:rounded-full lg:w-1/3 ${
          selected
            ? 'bg-darkPurple after:bg-red-600'
            : 'bg-white text-darkPurple ring-4 ring-darkPurple after:bg-transparent'
        } transition-colors duration-500 ease-in after:transition-colors after:duration-500 after:ease-in`}
        onClick={setTrue}
      >
        Basic
      </span>
      <span
        className={`relative w-full cursor-pointer rounded-lg px-12 py-4 text-center after:absolute after:-top-2 after:-right-2 after:h-4 after:w-4 after:rounded-full lg:w-1/3 ${
          !selected
            ? 'bg-darkPurple after:bg-red-600'
            : 'bg-white text-darkPurple ring-4 ring-darkPurple after:bg-transparent'
        } transition-colors duration-500 ease-in after:transition-colors after:duration-500 after:ease-in`}
        onClick={setFalse}
      >
        Pro
      </span>
    </div>
  );
};

export default Selector;
