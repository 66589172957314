import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import type { IconProps, Props, PropTypes } from '../../typescript';

const FeaturesBottomLeftMobile: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <motion.svg
      initial={{ scale: 1.1, rotate: 12 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 1 }
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
      width="34"
      height="30"
      viewBox="0 0 34 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3912 6.37791L10.3897 6.37889L10.3883 6.37999L9.02393 7.40528L9.02311 7.40597L9.02213 7.4068L7.71682 8.4928L7.71586 8.49349L7.73595 8.51831L7.71406 8.49516L6.47473 9.64022L6.47376 9.64104L6.47279 9.64202L5.31055 10.8431L5.37861 10.9109L7.77755 8.56752L13.3201 4.60821L16.3539 3.06473L16.3114 2.97852L14.7754 3.70922L14.7743 3.70977L14.7732 3.71032L13.2721 4.52476L13.2871 4.55303L13.2701 4.52586L11.8088 5.4164L11.8078 5.41709L11.8069 5.41765L10.3912 6.37791Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.2973 11.6196L14.1843 11.5998L14.2495 11.6943L14.8231 12.5272L13.3793 12.3227L13.2695 12.307L13.3332 12.3978L13.9781 13.3167L14.007 13.3578L14.0468 13.3272L14.9532 12.6292L15.9019 11.9868L16.8889 11.4036L16.995 11.341L16.8745 11.3153L15.2584 10.9703L15.1417 10.9453L15.2085 11.0443L15.7728 11.8791L14.2973 11.6196Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.5116 1.89383L19.4756 1.80469L16.3146 2.97622L16.312 2.97719L16.3095 2.97844L13.2725 4.52343L13.3136 4.61034L19.5116 1.89383Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.2887 10.9909L15.2618 10.951L15.2218 10.978L14.3021 11.6009L14.3004 11.5985L14.2613 11.6284L14.1641 11.6943L14.1734 11.696L13.3437 12.3327L13.2529 12.4023L13.3663 12.4183L14.9187 12.6382L15.0272 12.6536L14.965 12.5633L14.3942 11.7348L15.8683 11.994L15.98 12.0137L15.9165 11.9197L15.2887 10.9909ZM16.2563 10.3804L16.1354 10.3496L16.2043 10.4536L16.8248 11.3893L16.8485 11.4251L16.8867 11.4057L17.9055 10.8878L18.0195 10.8298L17.8957 10.7983L16.2563 10.3804Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.327 4.60234L13.2671 4.52734L7.71858 8.49082L7.71442 8.49387L7.71095 8.49775L3.26465 13.4091L3.33229 13.4774L13.327 4.60234Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.4425 13.1149L12.3925 13.1583L12.4014 13.1592L11.6812 13.87L11.5987 13.8632L11.636 13.9145L11.5908 13.9591L11.6734 13.9658L12.3258 14.8619L12.3588 14.9073L12.3986 14.8677L13.18 14.0879L13.2611 14.0946L13.2262 14.0458L14.0338 13.3392L14.1185 13.3485L14.0822 13.2968L14.132 13.2532L14.0449 13.2437L13.439 12.3803L14.3177 11.706L15.259 11.0686L16.855 11.4094L16.9702 11.4339L16.9051 11.3357L16.3535 10.5039L17.8538 10.8863L17.8656 10.9045L17.8878 10.8951L17.9914 10.9214L17.9554 10.8662L18.9463 10.4434L19.0683 10.3914L18.9412 10.3532L17.3004 9.85938L17.2864 9.83775L17.2651 9.8487L17.1578 9.81641L17.2003 9.88184L16.2609 10.3641L16.2593 10.3618L16.2362 10.3755L14.6205 10.0253L14.5034 9.99994L14.5705 10.0992L15.1453 10.9498L13.6512 10.6805L13.5376 10.6599L13.6031 10.755L14.1874 11.6033L12.7241 11.3879L12.6135 11.3717L12.6777 11.4631L13.309 12.3624L12.5155 13.0515L12.5134 13.0488L12.4895 13.0722L12.4054 13.0629L12.4425 13.1149Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.5092 1.89535L19.4774 1.80469L17.8831 2.3384L17.8818 2.33895L17.8802 2.33951L16.3135 2.97733L16.3483 3.06702L19.5092 1.89535Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.6497 10.0458L14.6228 10.0059L14.5829 10.0329L13.6153 10.6883L13.5176 10.7546L13.6337 10.7755L15.2401 11.065L15.3523 11.0852L15.2884 10.9908L14.6497 10.0458Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.78221 8.56278L7.71303 8.49609L5.31146 10.8421L5.30924 10.8442L5.30744 10.8465L3.2627 13.4121L3.33604 13.4743L7.78221 8.56278Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.7447 10.7924L13.7091 10.7407L14.6196 10.1241L16.2146 10.4697L16.2277 10.4892L16.2505 10.4775L16.3494 10.499L16.3138 10.445L17.2739 9.95235L18.8959 10.4405L18.9066 10.4574L18.9278 10.45L19.0371 10.483L19.0002 10.4252L19.9889 10.0857L20.1028 10.1258L20.0619 10.0606L20.1354 10.0354L20.0209 9.99504L19.4254 9.04462L19.405 9.01204L19.3687 9.0248L18.338 9.38786L18.3365 9.3855L18.3162 9.39424L18.2024 9.35417L18.2077 9.36236L16.7714 8.92831L16.7777 8.92498L16.6743 8.89823L16.6607 8.8773L16.6394 8.88839L16.5321 8.85595L16.5363 8.86247L14.9821 8.45934L14.8607 8.42773L14.93 8.5324L15.5033 9.39701L15.5068 9.40227L13.9889 9.06832L13.9726 9.04434L13.9499 9.05974L13.8526 9.03824L13.8931 9.09827L12.9963 9.70587L12.9948 9.70365L12.9716 9.72126L12.8783 9.70406L12.9167 9.7598L12.8597 9.79847L12.8682 9.8L12.0359 10.4341L11.9448 10.4201L11.9829 10.4744L11.9295 10.515L12.0213 10.529L12.6505 11.4257L12.5968 11.4668L12.6054 11.4681L11.8487 12.1205L11.8468 12.1177L11.8231 12.1408L11.738 12.1309L11.7757 12.1834L11.7261 12.2262L11.7345 12.2272L11.0039 12.9402L10.9205 12.9327L10.9583 12.9847L10.9133 13.0286L10.9956 13.036L11.637 13.9171L10.9377 14.709L10.8588 14.7047L10.8965 14.7556L10.8564 14.801L10.9331 14.8051L11.5964 15.7005L11.6321 15.7485L11.6714 15.7034L12.3849 14.8833L12.4624 14.8874L12.4254 14.8366L12.4649 14.7912L12.3895 14.7872L11.762 13.9252L12.5244 13.1728L12.6065 13.1801L12.571 13.1305L13.388 12.4209L13.4736 12.4309L13.4368 12.3786L13.4863 12.3356L13.3995 12.3254L12.8201 11.4997L14.282 11.715L14.3913 11.7311L14.3286 11.6401L13.7447 10.7924Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3407 4.57896L13.3807 4.42578L13.2623 4.53086L3.26758 13.406L3.32649 13.4817L12.6518 7.1753L12.6667 7.16518L12.6713 7.14772L13.3407 4.57896Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.1684 11.1945L11.0596 11.181L11.1233 11.2702L11.8012 12.2184L11.8319 12.2612L11.8718 12.2268L12.7479 11.4715L12.83 11.4008L12.7224 11.3873L11.1684 11.1945ZM15.0101 8.47853L14.9848 8.44027L14.9454 8.46383L13.9351 9.06908L13.8313 9.13132L13.9495 9.15724L15.6022 9.52086L15.7184 9.5465L15.6527 9.44738L15.0101 8.47853ZM17.739 8.44886L17.7168 8.41406L17.6791 8.4307L16.6249 8.89524L16.5059 8.94778L16.6303 8.98535L18.3042 9.49119L18.4284 9.52876L18.3585 9.41925L17.739 8.44886Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3424 4.55816L13.2485 4.55469L12.5791 7.12343L12.5493 7.23795L12.6505 7.17667L13.676 6.55632L13.7045 6.53913L13.6985 6.50655L13.3424 4.55816ZM5.38257 10.9064L5.3084 10.8451L4.23474 12.1008L4.23376 12.1021L4.23265 12.1035L3.26172 13.4132L3.33796 13.4718L5.38257 10.9064Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.088 10.4561L12.0576 10.4129L12.0174 10.4472L11.1309 11.2057L11.0481 11.2765L11.1562 11.29L12.7104 11.4828L12.8178 11.4961L12.7556 11.4074L12.088 10.4561ZM19.5194 9.07276L19.5291 9.06929L19.4016 9.02438L19.2642 8.96809L19.2706 8.97821L17.7143 8.42925L17.5827 8.38281L17.6577 8.50051L18.2773 9.47089L18.2979 9.50305L18.3338 9.49044L19.3642 9.12752L19.9481 10.0594L19.9668 10.0892L20.0008 10.0804L21.0761 9.80484L21.2166 9.7688L21.0825 9.71377L19.5194 9.07276ZM11.0198 14.7601L11.7346 13.951L11.7995 13.8774L11.7016 13.8712L10.2724 13.7797L10.1689 13.773L10.2306 13.8563L10.898 14.7571L10.2567 15.6044L10.2003 15.6789L10.2937 15.6815L11.6335 15.7191L11.7324 15.7218L11.6736 15.6424L11.0198 14.7601Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3281 4.53266L13.2197 4.42578L13.2472 4.5755L13.6032 6.52375L13.6151 6.58863L13.6732 6.55758L14.7244 5.99614L14.782 5.96537L14.7355 5.91948L13.3281 4.53266Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.297 15.5849L10.1972 15.5821L10.2574 15.6619L10.9464 16.5765L10.9852 16.6278L11.0234 16.5762L11.6741 15.6992L11.7292 15.6251L11.637 15.6224L10.297 15.5849ZM16.0039 7.90953L15.8964 7.87695L15.9396 7.94321L14.9639 8.45378L13.3094 8.08433L13.1913 8.058L13.2591 8.15808L13.8559 9.04114L12.3232 8.75127L12.2084 8.72965L12.2747 8.82585L12.881 9.70613L11.3773 9.46783L11.2653 9.44995L11.3305 9.54282L12.01 10.511L12.0387 10.5519L12.0785 10.5217L13.0123 9.81024L13.9862 9.15037L14.978 8.55622L16.6145 8.98069L16.6267 8.99928L16.6488 8.98957L16.7526 9.01646L16.7159 8.96004L17.7184 8.51824L17.8371 8.46584L17.713 8.42813L16.0395 7.92034L16.0251 7.8983L16.0039 7.90953ZM11.7381 13.8904L11.0855 12.9941L11.8584 12.2399L11.9416 12.2481L11.9039 12.1956L11.9484 12.1522L11.8673 12.1442L11.202 11.2138L11.1697 11.1685L11.1295 11.2072L10.3158 11.9922L10.2312 11.9839L10.2696 12.0367L10.2248 12.08L10.3069 12.088L10.9604 12.9856L10.2342 13.7952L10.168 13.8689L10.2669 13.8752L11.6961 13.9668L11.7984 13.9733L11.7381 13.8904Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3108 4.52148L13.2607 4.60106L14.6681 5.98788L14.6918 6.0113L14.722 5.9973L15.7929 5.50144L15.8992 5.45222L15.7889 5.41258L13.3108 4.52148Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.2722 8.09057L13.1704 8.15185L13.179 8.15379L12.3261 8.73186L12.3245 8.72964L12.2854 8.75931L12.1901 8.82405L12.1982 8.82557L11.3798 9.44662L11.3781 9.4444L11.339 9.47767L11.2502 9.54504L11.2582 9.54629L10.4574 10.2261L10.3687 10.2146L10.4073 10.2685L10.3584 10.3103L10.4452 10.3216L11.1231 11.2696L11.1536 11.3123L11.1935 11.2782L12.0635 10.5337L12.1507 10.5451L12.113 10.4913L12.1619 10.4496L12.0758 10.4384L11.4736 9.5804L12.9759 9.81855L13.0867 9.83602L13.0231 9.74383L12.4195 8.86744L13.9506 9.15703L14.064 9.17838L13.9994 9.08286L13.4057 8.20438L14.9404 8.54721L14.9537 8.56717L14.9766 8.55525L15.0759 8.57743L15.0394 8.52239L16.0128 8.0128L17.6672 8.51491L17.6784 8.53238L17.6984 8.52517L19.3526 9.10851L19.3625 9.12445L19.3812 9.11946L21.0458 9.80219L21.1824 9.8582L21.105 9.7326L20.5379 8.81074L20.6134 8.7905L20.4959 8.74226L20.4853 8.72521L20.4663 8.7302L18.9031 8.08988L18.9123 8.08656L18.8012 8.04732L18.7895 8.02861L18.7704 8.03554L18.6492 7.98591L18.6555 7.99589L17.0994 7.44652L17.0859 7.42558L17.0656 7.43459L16.952 7.39453L16.9972 7.46523L16.0037 7.91036L14.3458 7.47646L14.3306 7.45345L14.3086 7.46676L14.2062 7.44L14.2481 7.50307L13.3124 8.06632L13.311 8.06424L13.2722 8.09057Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3009 4.51953L13.2783 4.61241L15.7564 5.50336L15.7735 5.50946L15.7902 5.50295L16.8744 5.08014L16.8632 4.98767L13.3009 4.51953Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.332 13.8301L11.0422 13.0384L11.1221 13.0443L11.0839 12.9918L11.1232 12.948L11.0478 12.9424L10.3966 12.0477L11.1801 11.292L11.2642 11.3008L11.2261 11.2476L11.2705 11.2048L11.1894 11.1962L10.5378 10.2847L11.3999 9.55274L12.3269 8.84935L12.418 8.86419L12.3814 8.81109L13.3093 8.18214L13.4038 8.20044L13.3671 8.14624L14.3241 7.57011L15.9785 8.00303L15.9908 8.02189L16.0129 8.01205L16.1167 8.03921L16.0796 7.9821L17.0706 7.53822L18.753 8.1321L20.431 8.81955L21.0238 9.783L21.0407 9.81045L21.0726 9.80532L22.1215 9.63411L22.2706 9.60972L22.1348 9.54345L20.4974 8.74385L19.893 7.76141L19.874 7.73049L19.8393 7.7402L18.7918 8.03118L18.1846 7.06231L18.1639 7.02919L18.1272 7.04277L17.0881 7.42788L16.4672 6.45513L16.4447 6.41992L16.4067 6.43725L15.3791 6.90525L15.3777 6.90317L15.3563 6.91441L15.249 6.88169L15.2531 6.88792L13.681 6.47288L13.6657 6.44972L13.6438 6.46303L13.5412 6.436L13.5833 6.49949L12.6398 7.07022L12.6386 7.06828L12.616 7.08353L12.5177 7.06135L12.5575 7.12012L12.4987 7.15561L12.5069 7.15742L11.6449 7.74187L11.6434 7.73978L11.6205 7.75725L11.5261 7.73896L11.5653 7.79592L11.5095 7.83364L11.5172 7.83516L10.6656 8.47908L10.5732 8.46383L10.6121 8.51942L10.5597 8.55906L10.6504 8.57404L11.267 9.45251L9.77718 9.24666L9.66642 9.23141L9.73143 9.32235L10.4095 10.2707L9.61457 11.0286L9.52904 11.0194L9.56799 11.0729L9.52336 11.1155L9.60542 11.1242L10.2711 12.0387L9.549 12.8311L9.46777 12.8252L9.50658 12.8777L9.4668 12.9215L9.54332 12.9272L10.2097 13.8266L9.57146 14.653L9.4948 14.6499L9.53375 14.7016L9.49937 14.7462L9.56951 14.7492L10.2373 15.6353L9.69484 16.4953L9.62372 16.495L9.66282 16.5462L9.63426 16.5912L9.69747 16.5915L10.3853 17.4898L10.4278 17.5453L10.4645 17.4856L11.0116 16.5957L11.0815 16.5958L11.043 16.5447L11.0707 16.4996L11.0089 16.4995L10.3559 15.6329L10.982 14.806L11.0573 14.809L11.0189 14.7572L11.0527 14.7126L10.9837 14.7098L10.332 13.8301Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.6603 7.84928L10.7071 8.56971L10.706 8.57069L10.7048 8.57138L3.32722 13.4829L3.32695 13.4831L3.27441 13.4049L11.6603 7.84928ZM11.6603 7.84928L3.27441 13.4049L11.6603 7.84928ZM11.6603 7.84928L12.6525 7.17652H12.6526L12.5987 7.09681L12.5986 7.09695L3.27455 13.4025L3.27372 13.4031L11.6603 7.84928ZM3.27441 13.4049L3.27344 13.4032L3.27441 13.4049ZM13.2758 4.52404L13.2924 4.5702L13.2888 4.61567L16.8511 5.08381L16.8616 5.0852L16.8717 5.08215L17.9634 4.74099L17.9504 4.647L13.6069 4.52847L25.2482 1.18383L25.229 1.08984L19.4871 1.80377L19.4801 1.8046L19.4738 1.80737L13.2758 4.52404Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.7174 8.50371L10.6873 8.46059L10.6471 8.49456L9.73954 9.25908L9.65691 9.32867L9.76393 9.34337L11.3631 9.56434L11.4725 9.57945L11.4092 9.48906L10.7174 8.50371ZM21.5559 8.57302L21.5389 8.54488L21.5066 8.55056L20.4555 8.73508L20.3097 8.76058L20.4426 8.8256L22.0925 9.63129L22.2392 9.70297L22.1548 9.56323L21.5559 8.57302ZM18.2763 7.09152L18.2852 7.08819L18.161 7.04439L18.0237 6.98824L18.03 6.99808L16.4425 6.4372L16.3108 6.39062L16.386 6.50832L17.0278 7.51378L17.0488 7.54649L17.0851 7.53304L18.1238 7.14794L18.7292 8.11417L18.7483 8.14453L18.7828 8.13496L19.8648 7.83442L20.0003 7.79671L19.8702 7.74348L18.2763 7.09152ZM9.63099 12.8854L10.3685 12.076L10.4342 12.0039L10.337 11.9957L8.85897 11.8709L8.75293 11.8621L8.81628 11.9475L9.50817 12.8814L8.85773 13.7064L8.77968 13.7023L8.81933 13.755L8.78481 13.7988L8.85523 13.8026L9.55585 14.7323L9.5937 14.7827L9.63223 14.7329L10.2925 13.878L10.3693 13.882L10.3302 13.8293L10.364 13.7855L10.295 13.7819L9.63099 12.8854Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.27344 13.4019L3.32598 13.4825L3.32667 13.482L9.79623 9.3353L9.79887 9.33364L9.80122 9.33156L10.7088 8.56704L10.6511 8.49023L3.27413 13.4015L3.27344 13.4019Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.6974 5.902L14.5899 5.86914L14.6336 5.93609L13.6286 6.47286L13.5206 6.53053L13.639 6.56172L15.3312 7.00851L15.3437 7.02764L15.3657 7.01752L15.4697 7.04497L15.4322 6.9873L16.4466 6.52526L16.562 6.47272L16.4407 6.43557L14.7333 5.91294L14.7187 5.89062L14.6974 5.902ZM9.67064 11.0509L9.63612 11.0036L9.59648 11.0466L8.81989 11.8853L8.75293 11.9575L8.85108 11.9658L10.3291 12.0906L10.4339 12.0994L10.372 12.0143L9.67064 11.0509ZM19.8733 7.74379L19.7245 7.67142L19.8112 7.81226L20.4229 8.80664L20.4401 8.8345L20.4723 8.82881L21.5232 8.6443L21.6693 8.61866L21.536 8.55378L19.8733 7.74379Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5552 1.23894L22.5369 1.14453L21.0517 1.39988L21.0499 1.4003L21.0479 1.40072L19.4805 1.80425L19.5026 1.89783L22.5552 1.23894Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.63204 14.6741L9.59058 14.6193L9.55329 14.6769L8.97993 15.5623L8.90701 15.5611L8.94694 15.6132L8.91824 15.6574L8.9816 15.6585L9.68249 16.5737L9.72435 16.6286L9.76123 16.5703L10.3213 15.6825L10.3928 15.6839L10.3534 15.6316L10.3812 15.5874L10.3193 15.5863L9.63204 14.6741ZM8.85309 10.093L8.80859 10.1348L8.89052 10.1442L9.59211 11.1081L9.6244 11.1525L9.6642 11.1146L10.4892 10.3281L10.5743 10.3379L10.5356 10.2838L10.5797 10.2417L10.4989 10.2324L9.80906 9.26744L9.77703 9.22266L9.73697 9.26037L8.90022 10.0486L8.81358 10.0386L8.85309 10.093Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.27441 13.4011L3.32488 13.4828L8.94216 10.1392L8.94674 10.1365L8.95048 10.1329L9.80345 9.32932L9.74453 9.25391L3.27441 13.4011Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.5391 8.55523L21.4735 8.6215L22.0723 9.61171L22.0872 9.63652L22.1163 9.63486L23.0996 9.57552L23.257 9.5661L23.1211 9.48611L21.5391 8.55523ZM17.5469 6.04512L17.526 6.01172L17.4891 6.02571L16.4092 6.43633L16.2867 6.48291L16.4103 6.52658L18.1265 7.13292L19.8336 7.83119L19.97 7.88705L19.8928 7.7616L19.2699 6.7492L19.2508 6.71802L19.2156 6.72814L18.1652 7.03173L17.5469 6.04512ZM9.75918 16.5145L9.7126 16.4535L9.67808 16.5221L9.21936 17.4348L9.18457 17.5041L9.2622 17.5044L10.4231 17.5087L10.5209 17.5091L10.4615 17.4315L9.75918 16.5145Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.2934 4.5195L13.293 4.61571L17.9471 4.74255L17.9532 4.74282L17.9591 4.74144L19.0181 4.49926L19.0065 4.4043L13.2934 4.5195Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.7707 5.40725L15.6575 5.36719L15.7031 5.43858L14.6822 5.9113L14.5681 5.96425L14.6883 6.00099L16.3957 6.52347L16.4072 6.54149L16.4284 6.53346L16.5375 6.56686L16.4989 6.5067L17.4868 6.13116L18.1033 7.115L18.1225 7.14577L18.1573 7.13565L19.2426 6.82207L19.3756 6.78368L19.2475 6.73128L17.6376 6.07377L17.6462 6.07058L17.5237 6.02719L17.3866 5.97118L17.3927 5.98089L15.8048 5.41932L15.7911 5.39783L15.7707 5.40725ZM9.26277 17.4098L9.16406 17.4094L9.22464 17.4873L9.94023 18.4083L9.98765 18.4693L10.0215 18.3998L10.4667 17.4832L10.5001 17.4144L10.4238 17.4141L9.26277 17.4098ZM20.9465 7.56511L20.9292 7.5367L20.8964 7.5428L19.8433 7.74089L19.7005 7.76779L19.8311 7.83141L21.4939 8.64141L21.6405 8.71281L21.5562 8.57321L20.9465 7.56511Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.27539 13.4003L3.32211 13.4843L8.15006 10.9792L8.15741 10.9755L8.16295 10.9695L8.95229 10.1309L8.89269 10.0566L3.27539 13.4003Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.91732 11.922L9.65066 11.1301L9.73327 11.1378L9.69377 11.0836L9.733 11.0412L9.65772 11.0341L8.95641 10.0708L8.92231 10.024L8.88252 10.0661L8.10927 10.8876L8.02539 10.8797L8.0656 10.934L8.02581 10.9763L8.10234 10.9835L8.79422 11.9175L8.13116 12.7409L8.05146 12.7358L8.09207 12.7896L8.05742 12.8325L8.12784 12.8371L8.82125 13.7573L8.25315 14.6115L8.17843 14.609L8.21934 14.6624L8.1905 14.7057L8.25399 14.7077L8.94948 15.616L8.49104 16.4974L8.45542 16.566L8.53277 16.5677L9.72024 16.5928L9.82019 16.595L9.75934 16.5156L9.06509 15.6088L9.61974 14.7522L9.69307 14.7546L9.65287 14.7012L9.68074 14.658L9.61877 14.6559L8.94075 13.7562L9.5909 12.9318L9.66909 12.9368L9.6293 12.883L9.66313 12.84L9.59409 12.8356L8.91732 11.922ZM19.2501 6.73213L19.1017 6.66004L19.1882 6.80061L19.811 7.813L19.8283 7.84114L19.8609 7.83504L20.8813 7.64304L21.4331 8.55562L21.3578 8.56117L21.4799 8.63298L21.4889 8.64795L21.5034 8.64684L23.0725 9.57009L23.1382 9.50411L22.5802 8.56699L22.6565 8.56131L22.5341 8.48964L22.5252 8.47467L22.5105 8.47577L21.0402 7.6131L21.0571 7.61005L20.9261 7.54643L19.2501 6.73213ZM16.8981 5.01025L16.877 4.97656L16.8398 4.99098L15.7556 5.41393L15.635 5.46093L15.757 5.50404L17.4904 6.11704L17.6203 6.16307L17.5471 6.04621L16.8981 5.01025Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.27832 13.3995L3.31825 13.4868L7.43047 11.8519L7.44197 11.8473L7.44988 11.8377L8.16546 10.9677L8.10627 10.8945L3.27832 13.3995Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.6359 5.71898L18.6166 5.6875L18.5811 5.69818L17.4926 6.02533L17.3623 6.06443L17.4882 6.11586L19.211 6.81953L19.3473 6.87526L19.2701 6.7498L18.6359 5.71898Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.293 4.51977L13.2957 4.61597L13.2966 4.61583L19.0088 4.50064H19.0115L19.0142 4.50021L20.0011 4.36727L19.9929 4.27148L13.2938 4.51977H13.293Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.53452 16.4701L8.43359 16.468L8.49542 16.5477L9.22418 17.4855L9.2709 17.5457L9.30514 17.4776L9.76386 16.5649L9.7981 16.4969L9.72185 16.4953L8.53452 16.4701ZM20.3253 6.53681L20.3077 6.50784L20.2745 6.51449L19.2193 6.72686L19.0797 6.75501L19.2078 6.81725L20.8838 7.63154L21.0302 7.70266L20.946 7.56334L20.3253 6.53681ZM16.8751 4.98988L16.737 4.93359L16.8162 5.06002L17.4654 6.09584L17.4848 6.12703L17.5199 6.11635L18.6085 5.7892L18.7388 5.74996L18.6128 5.69867L16.8751 4.98988Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.34166 13.4651L3.2589 13.416L2.43352 14.7371L2.43227 14.7391L2.4313 14.7412L1.78613 16.0269L1.87097 16.0721L3.34166 13.4651Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5346 8.49071L21.9312 7.47722L21.9157 7.45129L21.8856 7.45392L20.9004 7.54112L20.7507 7.55443L20.8803 7.63053L22.4637 8.55947L23.0551 9.55259L23.0681 9.57464L23.0936 9.57601L24.0053 9.62773L24.1743 9.63729L24.0356 9.54039L22.5346 8.49071ZM8.16628 10.9085L8.12969 10.859L8.0906 10.9066L7.39081 11.7573L7.30957 11.7511L7.35088 11.8058L7.31609 11.848L7.38665 11.8534L8.09336 12.7913L7.51197 13.6422L7.43545 13.6386L7.47717 13.6932L7.4482 13.7355L7.51182 13.7386L8.23961 14.689L8.28065 14.7425L8.31794 14.6865L8.9046 13.8043L8.9796 13.8077L8.93843 13.7531L8.96657 13.7108L8.90447 13.7079L8.21342 12.7909L8.87605 11.968L8.95576 11.9741L8.91515 11.9192L8.94911 11.8771L8.88007 11.8718L8.16628 10.9085ZM9.29997 17.4274L9.24674 17.3589L9.21694 17.4403L8.90503 18.2881L8.83585 18.2855L8.88367 18.3462L8.87009 18.3831L8.91403 18.3848L9.62907 19.2935L9.68327 19.3622L9.71225 19.2796L10.0113 18.4272L10.0789 18.4298L10.0316 18.369L10.0445 18.3322L10.0018 18.3306L9.29997 17.4274ZM18.6154 5.70072L18.4672 5.62891L18.5534 5.76919L19.1877 6.80002L19.2053 6.82858L19.2381 6.82193L20.2933 6.60955L20.4333 6.58141L20.3048 6.51917L18.6154 5.70072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.28223 13.3976L3.30995 13.4893L6.79141 12.7525L6.80999 12.7486L6.82081 12.7331L7.45156 11.835L7.54416 11.7031L7.39444 11.7626L3.28223 13.3976Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.3086 6.52148L20.2432 6.58789L20.8639 7.61441L20.8795 7.64006L20.9093 7.63743L21.8945 7.55024L22.0446 7.53693L21.9146 7.46082L20.3086 6.52148Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2594 1.17718L25.2212 1.08984L13.2812 4.5203L13.2964 4.61457L19.9963 4.36628L20.0088 4.36587L20.0195 4.35935L25.2594 1.17718Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.31675 14.6303L8.27156 14.5713L8.23621 14.6367L7.74949 15.5408L7.71289 15.6086L7.78997 15.6116L9.00476 15.6582L9.10692 15.6622L9.04468 15.581L8.31675 14.6303ZM17.9903 4.66861L17.9707 4.63672L17.9349 4.64795L16.8433 4.98911L16.7156 5.02903L16.8394 5.0795L18.5771 5.78816L18.7132 5.84374L18.6361 5.71843L17.9903 4.66861Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2404 1.18368L25.2335 1.08789L22.5441 1.14279L22.5394 1.14293L22.535 1.14389L19.4824 1.80279L19.4985 1.89747L25.2404 1.18368Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.79356 15.5166L7.69042 15.5127L7.75376 15.5942L8.47934 16.5279L8.1641 17.3451L8.09244 17.3408L8.14165 17.4033L8.12778 17.4392L8.172 17.4419L8.9009 18.3682L8.95414 18.4358L8.98394 18.355L9.29557 17.5078L9.36572 17.512L9.31706 17.4493L9.33023 17.4136L9.28725 17.4109L8.59093 16.5147L9.04923 15.6336L9.08417 15.5662L9.00834 15.5633L7.79356 15.5166ZM23.4969 8.61439L23.5734 8.61758L23.4469 8.52928L23.4389 8.51569L23.4267 8.51527L21.9265 7.4692L21.3117 6.43684L21.2961 6.41051L21.2656 6.41356L20.31 6.5124L19.7283 5.55061L19.7993 5.53535L19.686 5.48074L19.6758 5.46354L19.6583 5.46729L17.9701 4.65161L17.8222 4.58008L17.9083 4.72009L18.5185 5.71211L18.4484 5.72722L18.5615 5.78197L18.572 5.79903L18.5891 5.79528L20.2619 6.60583L21.8556 7.53796L22.4126 8.47341L22.337 8.47022L22.4633 8.55853L22.4713 8.57197L22.4833 8.57252L23.9813 9.61999L24.0502 9.55622L23.4969 8.61439ZM7.45114 11.7791L7.41136 11.7263L7.37338 11.7803L6.75816 12.6565L6.67969 12.6518L6.72239 12.7075L6.69328 12.7489L6.75718 12.7528L7.49898 13.7217L7.53945 13.7745L7.57688 13.7196L8.17755 12.8404L8.25435 12.8451L8.21235 12.7894L8.24062 12.748L8.17824 12.7442L7.45114 11.7791Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.26234 13.6481L6.29172 13.6497L6.30642 13.6242L6.82321 12.7302L6.87715 12.6367L6.77164 12.659L3.29004 13.3958L3.29739 13.4909L6.26234 13.6481Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9281 4.73667L19.6182 5.55332L20.2431 6.58665L20.2589 6.61271L20.2891 6.60966L21.2446 6.51082L21.8043 7.45085L21.7314 7.44904L21.8542 7.53471L21.8624 7.54857L21.8746 7.54885L23.3743 8.59452L23.9669 9.60343L23.978 9.62215L23.9992 9.62631L24.8315 9.78837L24.8713 9.70408L23.4476 8.5295L22.8866 7.5745L22.9605 7.5763L22.8374 7.49077L22.8292 7.47676L22.8169 7.47649L21.404 6.49432L21.4216 6.49252L21.3051 6.42459L20.6798 5.37449L20.6637 5.3476L20.6328 5.35134L19.6771 5.46488L19.049 4.42629L19.0311 4.39648L18.9971 4.40424L17.9382 4.64657L17.8047 4.67706L17.9281 4.73667ZM7.57501 13.6616L7.53051 13.6035L7.49488 13.6674L7.00803 14.5403L6.93262 14.5362L6.97781 14.5944L6.9559 14.6338L7.0108 14.6368L7.73651 15.5708L7.40824 16.3817L7.33394 16.3759L7.38468 16.4402L7.37054 16.4753L7.4149 16.4788L8.15821 17.4232L8.21048 17.4896L8.24084 17.4109L8.56563 16.569L8.63842 16.5748L8.58823 16.5102L8.60168 16.4754L8.55857 16.4721L7.84866 15.5584L8.30612 14.7088L8.38001 14.7129L8.33537 14.6546L8.35645 14.6153L8.30294 14.6123L7.57501 13.6616ZM8.97625 18.3071L8.91359 18.2275L8.89155 18.3264L8.71272 19.1275L8.64271 19.1211L8.69844 19.1911L8.69151 19.2222L8.72561 19.2252L9.45451 20.1417L9.51842 20.2221L9.53921 20.1215L9.7057 19.3148L9.77418 19.321L9.71887 19.2509L9.72524 19.22L9.69225 19.217L8.97625 18.3071Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.86553 14.5009L5.90837 14.5178L5.92708 14.4758L6.30858 13.6194L6.337 13.5555L6.26713 13.5517L3.30233 13.3945L3.28223 13.4874L5.86553 14.5009Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.3347 8.77509L24.4133 8.78909L24.2782 8.67792L24.2713 8.66614L24.2617 8.66447L22.8315 7.48753L22.7594 7.54908L23.3158 8.49604L23.2383 8.48218L23.3729 8.59336L23.3798 8.60514L23.3892 8.6068L24.8097 9.77875L24.882 9.71761L24.3347 8.77509ZM19.0318 4.41016L18.9665 4.47656L19.6105 5.54163L19.6265 5.56811L19.6573 5.5645L20.6437 5.44722L20.7867 5.43031L20.6623 5.35795L19.0318 4.41016ZM6.81947 12.6759L6.77581 12.6187L6.73976 12.681L6.22297 13.575L6.18457 13.6416L6.26123 13.6471L7.53312 13.7393L7.63973 13.7471L7.57471 13.6621L6.81947 12.6759Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.34442 13.4608L3.25847 13.418L1.78778 16.0251L1.78515 16.0298L1.78348 16.035L1.06055 18.4676L1.15079 18.5004L3.34442 13.4608Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.23415 17.3639L8.17288 17.286L8.14959 17.3824L7.9483 18.218L7.93555 18.2708L7.98947 18.2771L8.93338 18.3848L9.04789 18.3979L8.97664 18.3073L8.23415 17.3639ZM6.26884 13.5509L6.16113 13.543L6.22739 13.6282L6.99774 14.6197L7.04224 14.6771L7.07773 14.6136L7.57928 13.7144L7.61602 13.6486L7.54074 13.6431L6.26884 13.5509Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2359 1.18518L25.2367 1.08911L23.9427 1.03921L23.9399 1.03906L23.9371 1.03934L22.542 1.14415L22.5466 1.24022L25.2359 1.18518Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.2651 6.51804L22.3364 6.51859L22.2168 6.4357L22.2083 6.42128L22.1958 6.42114L20.6744 5.36689L20.0362 4.29489L20.0199 4.26758L19.9883 4.27188L19.0015 4.40496L18.8625 4.42367L18.9837 4.49409L20.6036 5.43578L21.1851 6.41241L21.1148 6.41186L21.2342 6.49475L21.2426 6.50917H21.255L22.7722 7.56398L24.2041 8.74202L24.7991 9.76676L24.8078 9.78172L24.8242 9.78769L25.5679 10.0604L25.618 9.98065L24.2795 8.68061L23.6721 7.63468L23.6607 7.61485L23.6381 7.61125L22.8313 7.48177L22.2651 6.51804ZM8.00005 18.1828L7.88471 18.1696L7.95708 18.2604L8.70122 19.1961L8.65243 19.9738L8.64979 20.0169L8.69235 20.0243L9.48404 20.1604L9.60825 20.1819L9.52979 20.0832L8.80242 19.1687L8.98541 18.3487L8.99705 18.2966L8.94396 18.2905L8.00005 18.1828ZM7.07333 14.562L7.02315 14.4973L6.99113 14.5727L6.63846 15.4032L6.56152 15.3957L6.61364 15.462L6.59909 15.496L6.64387 15.5003L7.40188 16.4635L7.45317 16.5287L7.48422 16.4519L7.82261 15.6158L7.89802 15.6233L7.84645 15.5569L7.86017 15.5229L7.81679 15.5187L7.07333 14.562Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.60955 15.3075L5.66486 15.3507L5.6851 15.2833L5.9295 14.4706L5.94226 14.4282L5.90108 14.4121L3.31764 13.3984L3.27051 13.4811L5.60955 15.3075Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.75142 19.9364L8.79522 19.238L8.8698 19.2527L8.7998 19.1647L8.80132 19.1412L8.77734 19.1364L8.04719 18.2185L8.23282 17.4481L8.30767 17.4582L8.24848 17.383L8.25555 17.3541L8.22201 17.3495L7.55368 16.5001L7.47772 16.4036L7.41783 16.3275L6.72456 15.4467L7.06586 14.6429L7.14405 14.6521L7.09095 14.5837L7.10495 14.5508L7.06128 14.5456L6.30327 13.5701L6.25392 13.5068L6.22135 13.58L5.85426 14.4041L5.77441 14.3948L5.82806 14.463L5.81323 14.4961L5.85828 14.5014L6.61643 15.4646L6.39338 16.262L6.37882 16.3139L6.4322 16.3224L7.38013 16.4725L7.18896 17.2084L7.11243 17.198L7.17219 17.273L7.16455 17.3021L7.19908 17.3068L7.94545 18.2452L7.88847 18.9607L7.81208 18.9456L7.88265 19.0335L7.8807 19.0572L7.90552 19.0621L8.58382 19.9076L8.66325 20.0065L8.74088 20.1034L9.41738 20.9464L9.49695 21.0457L9.50291 20.9187L9.54048 20.115L9.54242 20.0725L9.50055 20.0653L8.75142 19.9364ZM22.1448 6.50224L22.1512 6.51305L22.76 7.54956L22.7716 7.56911L22.7939 7.57272L23.6007 7.70219L24.2 8.73413L24.209 8.74951L24.2158 8.75187L25.5512 10.0493L25.6263 9.99078L25.0242 8.94442L25.0154 8.92889L25.0084 8.92654L23.7738 7.73005L23.7988 7.73407L23.6681 7.62664L23.0997 6.64807L23.172 6.65833L23.0455 6.55478L23.0381 6.54216L23.0283 6.54078L21.6818 5.43911L21.7008 5.43897L21.5845 5.35871L21.5759 5.34388L20.9453 4.27063L20.931 4.24609L20.9026 4.24692L19.9938 4.27105L19.8465 4.27493L19.9591 4.3527L20.5545 5.35261L20.4869 5.35316L20.6027 5.43357L20.6115 5.44826H20.6238L22.1448 6.50224Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.87552 16.0636L1.78445 16.0332L1.32546 17.2761L1.32435 17.2792L1.32366 17.2824L1.05957 18.4719L1.15259 18.496L1.87552 16.0636Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.44649 16.2281L6.32422 16.2087L6.40129 16.3055L7.18634 17.2924L7.24622 17.3678L7.27048 17.2746L7.48577 16.4462L7.49908 16.3949L7.44668 16.3866L6.44649 16.2281ZM25.018 8.9375L24.9401 8.99309L25.5422 10.0394L25.5485 10.0503L25.5593 10.0568L26.2034 10.4398L26.2643 10.3669L25.018 8.9375Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2625 1.17569L25.2092 1.0957L19.9693 4.27788L19.8145 4.37186L19.9955 4.36702L20.9042 4.34289L20.9192 4.34248L20.9314 4.33375L25.2625 1.17569Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.73749 19.9465L8.63171 19.8145L7.98072 19.0032L8.03769 18.289L8.11643 18.3066L8.04366 18.215L8.04546 18.1923L8.0212 18.1869L7.26167 17.232L7.18764 17.139L7.17614 17.2572L7.10516 17.9831L7.02461 17.9651L7.09795 18.0566L7.09573 18.0794L7.12082 18.085L7.88507 19.0376L7.96228 19.7556L7.96561 19.7865L7.99514 19.796L8.65597 20.0127L8.7343 20.6504L8.65583 20.6268L8.74483 20.737L8.74705 20.7545L8.76285 20.7594L9.51933 21.6963L9.62788 21.8307L9.60445 21.6596L9.51185 20.9841L9.58838 21.0069L9.49993 20.8967L9.49757 20.8793L9.48232 20.8747L8.83868 20.0726L8.73749 19.9465ZM25.653 9.29994L24.4074 7.87389L24.4006 7.8621L24.3937 7.85974L23.0458 6.55666L22.4632 5.55355L22.5326 5.56215L22.4102 5.46233L22.4026 5.4493L22.3926 5.44806L20.9336 4.25781L20.8616 4.31936L21.4569 5.33258L21.3885 5.32413L21.5107 5.42408L21.5183 5.43711L21.528 5.43822L22.9704 6.6182L23.5884 7.68258L23.5976 7.69838L23.6045 7.70061L24.9435 8.99828L24.9465 9.00369L24.9508 9.00605L26.1918 10.4295L26.2697 10.374L25.6638 9.3138L25.6573 9.30243L25.653 9.29994ZM5.92087 14.4263L5.86362 14.3537L5.837 14.4423L5.60494 15.2139L5.52148 15.1989L5.58526 15.279L5.57707 15.3066L5.61229 15.313L6.40134 16.3049L6.45985 16.3785L6.48522 16.288L6.703 15.5093L6.78451 15.5238L6.72144 15.4436L6.72906 15.4161L6.69496 15.41L5.92087 14.4263Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2677 1.17183L25.2067 1.09766L20.8756 4.25571L20.7744 4.3296L20.899 4.34249L21.7221 4.4276L21.7437 4.42983L21.7596 4.415L25.2677 1.17183Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.035 8.21848L23.7819 6.78646L23.775 6.77426L23.1465 5.68203L23.1368 5.66525L23.1182 5.65984L22.4051 5.45301L21.7678 4.35592L21.7556 4.33471L21.7312 4.33222L20.9082 4.2471L20.7482 4.23047L20.8729 4.33208L22.3361 5.52565L22.9661 6.61055L22.9755 6.62676L22.9825 6.62899L24.3301 7.93166L24.3332 7.93734L24.3375 7.93969L25.5828 9.36547L26.1864 10.4215L26.1901 10.4279L26.1956 10.4329L26.7276 10.9265L26.7991 10.8629L25.6625 9.31113L25.0461 8.23247L25.0394 8.22083L25.035 8.21848ZM8.02522 19.7045L7.87065 19.6539L7.97282 19.7805L8.74844 20.741L8.98077 21.4085L8.98715 21.4267L8.99519 21.4308L9.76554 22.3813L9.84816 22.3347L9.60224 21.6497L9.59586 21.6318L9.58795 21.6278L8.83715 20.6979L8.74788 19.9704L8.74414 19.9402L8.71516 19.9306L8.02522 19.7045ZM6.47676 16.2448L6.40482 16.1544L6.39136 16.2692L6.3075 16.986L6.22266 16.9651L6.2989 17.0602L6.29627 17.0824L6.32163 17.0886L7.10141 18.0604L7.16615 18.7684L7.16892 18.799L7.1979 18.8094L7.91542 19.0636L8.07485 19.1201L7.96893 18.9882L7.19762 18.0269L7.26652 17.3217L7.34956 17.3422L7.27386 17.2469L7.27608 17.225L7.25154 17.2189L6.47676 16.2448Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2722 1.16714L25.2021 1.10156L21.6939 4.34473L21.6299 4.40393L21.7142 4.42653L22.442 4.62185L22.4722 4.62989L22.4919 4.60563L25.2722 1.16714Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.7595 4.3457L21.6845 4.40448L22.3314 5.51834L22.3411 5.53498L22.3596 5.54038L23.0911 5.75249L23.1378 5.67167L21.7595 4.3457ZM7.22987 18.7191L7.06947 18.6622L7.17635 18.7946L7.96722 19.774L8.18834 20.4311L8.19429 20.4491L8.21093 20.4581L8.76793 20.7599L8.82823 20.6876L8.05636 19.7313L7.97914 19.0135L7.97582 18.9834L7.9474 18.9733L7.22987 18.7191ZM5.67614 15.2391L5.60627 15.1511L5.59089 15.2624L5.49385 15.97L5.4043 15.9458L5.48359 16.0445L5.48068 16.066L5.50632 16.073L6.31133 17.0762L6.38328 17.1659L6.39658 17.0517L6.48018 16.3361L6.56765 16.3598L6.48891 16.2608L6.4914 16.2394L6.46673 16.2328L5.67614 15.2391ZM26.1907 9.79414L26.1869 9.78749L26.1848 9.78569L25.0428 8.22822L24.9624 8.28048L25.5803 9.36163L25.584 9.36814L25.5861 9.37009L26.7213 10.9201L26.802 10.8679L26.1907 9.79414Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.4444 1.7716L27.4731 1.67984L26.4295 1.30222L26.4257 1.30083L26.4216 1.30014L25.2435 1.08984L25.2227 1.18369L27.4444 1.7716Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.4164 7.13036L24.4095 7.11844L24.4051 7.11609L23.1444 5.67825L22.4963 4.55177L22.4863 4.53444L22.4671 4.52931L21.7391 4.33398L21.6934 4.41508L23.0669 5.7362L23.7014 6.83897L23.7082 6.85075L23.7126 6.85311L24.9686 8.28845L25.0465 8.23286L24.4164 7.13036ZM8.25742 20.3736L8.19712 20.4461L8.98923 21.4235L9.072 21.3773L8.83688 20.7019L8.83079 20.6843L8.81442 20.6754L8.25742 20.3736ZM6.38694 17.0161L6.29004 16.8954L6.3014 17.0498L6.35436 17.7621L6.35658 17.7925L6.38513 17.8035L7.13122 18.087L7.29633 18.1496L7.18584 18.0119L6.38694 17.0161Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.68221 15.2891L5.69885 15.2542L5.66834 15.2305L3.32944 13.4041L3.26207 13.3516L3.25208 13.4364L2.19922 22.3822L2.29044 22.4086L5.68221 15.2891Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.41915 17.7135L6.25293 17.6504L6.36467 17.7887L7.17674 18.7946L7.27655 18.9181L7.262 18.76L7.19601 18.0376L7.19324 18.0076L7.16524 17.997L6.41915 17.7135Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.34799 13.4488L3.25622 13.424L1.06259 18.4636L1.05566 18.4796L1.06052 18.4963L2.20127 22.4024L2.29512 22.3946L3.34799 13.4488ZM25.2766 1.16177L25.1975 1.10742L22.4172 4.54605L22.3795 4.59277L22.4333 4.61939L23.055 4.92631L23.0953 4.94614L23.1179 4.90718L25.2766 1.16177Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5678 8.69747L25.5642 8.69123L25.5621 8.68929L24.4152 7.12697L23.7738 6.00493L23.7667 5.99259L23.7623 5.99023L22.4909 4.54297L22.413 4.59869L23.0636 5.72933L23.0707 5.74153L23.0753 5.74389L24.3355 7.18131L24.9632 8.27951L24.9671 8.2863L24.9692 8.28825L26.1092 9.84308L26.7192 10.9145L26.72 10.916L26.721 10.9176L27.1273 11.5216L27.1779 11.4891L27.209 11.4708L26.5911 10.3844L26.5903 10.3827L26.5899 10.3822L25.5678 8.69747ZM8.57465 21.0366L8.98956 21.4231L9.36911 22.0157L9.37244 22.021L9.37452 22.0229L10.1586 22.9889L10.2362 22.9326L9.84391 22.3242L9.84058 22.319L9.83864 22.3172L9.06413 21.3618L8.27757 20.3913L8.05646 19.7342L8.0505 19.7169L8.04245 19.7123L7.25201 18.7332L7.16883 18.7782L7.38093 19.4327L7.38675 19.4504L7.39507 19.4553L8.19564 20.443L8.56675 21.0272L8.57007 21.0323L8.57465 21.0366ZM5.57241 15.9996L5.47856 15.8826L5.48687 16.0323L5.52167 16.6584L5.41992 16.6172L5.5268 16.7494L5.52763 16.7644L5.54441 16.7712L6.36508 17.7878L6.46184 17.9075L6.45033 17.7539L6.40334 17.1187L6.50245 17.1588L6.39641 17.0267L6.3953 17.0117L6.37922 17.0052L5.57241 15.9996Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2798 1.15515L25.1934 1.11328L23.0349 4.85869L23.0146 4.89377L23.0457 4.91969L23.5484 5.33932L23.5994 5.38188L23.6239 5.32019L25.2798 1.15515Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.932 7.58048L24.928 7.5734L24.9259 7.57146L23.7724 6.00221L23.1181 4.85743L23.1108 4.84468L23.0977 4.83817L22.4759 4.53125L22.4186 4.60611L23.6927 6.05656L24.333 7.1768L24.337 7.18373L24.3391 7.18566L25.4875 8.74993L25.568 8.69767L24.932 7.58048ZM8.64004 20.9658L8.57004 21.0314L9.37213 22.0198L9.45003 21.9635L9.06729 21.3658L9.06396 21.3607L9.05953 21.3566L8.64004 20.9658ZM6.43976 17.727L6.35645 17.7715L6.5562 18.4147L6.56175 18.4324L6.57021 18.4374L7.38921 19.4479L7.47239 19.4028L7.26029 18.7482L7.25461 18.7309L7.24643 18.7261L6.43976 17.727Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.15442 18.4787L1.05836 18.4785L1.00027 19.6014L1 19.6062L1.00069 19.6112L1.1615 20.6507L1.25701 20.6409L1.15442 18.4787Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.9606 9.27645L25.9596 9.27478L25.9592 9.27423L24.9312 7.58008L24.8483 7.62887L25.4843 8.74606L25.4852 8.74773L25.4857 8.74828L26.508 10.4336L26.5909 10.3849L25.9606 9.27645ZM7.46379 19.3881L7.38574 19.444L7.74783 20.0189L7.75116 20.024L7.75546 20.0282L8.20142 20.4502L8.27184 20.385L7.46379 19.3881Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.024 3.05654L29.0756 2.97613L27.4872 1.68691L27.4792 1.68039L27.4692 1.67776L25.2474 1.08984L25.2139 1.17954L29.024 3.05654Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.2827 6.44134L24.2785 6.434L24.2764 6.4322L23.1146 4.85352L23.0342 4.90592L23.6899 6.05305L23.6941 6.0604L23.6962 6.0622L24.8511 7.63354L24.9316 7.58127L24.2827 6.44134ZM7.82133 19.9583L7.75091 20.0236L8.56964 21.0322L8.64755 20.9761L8.27478 20.3895L8.27159 20.3844L8.26729 20.3804L7.82133 19.9583ZM5.61093 16.7017L5.52734 16.7455L5.71435 17.3769L5.71961 17.3946L5.73528 17.4042L6.37685 17.7989L6.43937 17.7278L5.61093 16.7017Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.281 1.14963L25.1899 1.11914L23.534 5.28431L23.5244 5.30815L23.5391 5.32936L23.9087 5.86308L23.9673 5.94792L23.9946 5.84838L25.281 1.14963Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.78615 17.3216L5.72363 17.3929L6.56495 18.4309L6.64827 18.3863L6.44851 17.7431L6.4431 17.7259L6.42771 17.7163L5.78615 17.3216ZM25.3175 8.14442L25.3166 8.14276L25.3161 8.14221L24.2825 6.43945L24.1996 6.48825L24.8487 7.62818L24.8497 7.62984L24.8499 7.63039L25.878 9.32441L25.9609 9.27575L25.3175 8.14442Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.57744 16.0525L5.58132 16.0451L5.58243 16.037L5.68696 15.2754L5.59588 15.248L2.2041 22.3675L2.29019 22.4103L5.57744 16.0525Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.63873 18.3716L6.56055 18.4274L6.91168 18.9898L6.91487 18.9949L6.91695 18.997L7.75065 20.0242L7.82856 19.9682L7.46647 19.3934L7.46342 19.3885L7.46134 19.3864L6.63873 18.3716ZM23.6201 5.2785L23.6158 5.27101L23.6091 5.26546L23.1065 4.8457L23.0369 4.91113L24.2019 6.49425L24.2823 6.44198L23.6201 5.2785Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2814 1.14704L25.1878 1.125L23.9013 5.82375L23.8975 5.83802L23.9023 5.85189L24.1229 6.50038L24.1805 6.66993L24.2156 6.49428L25.2814 1.14704Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.6606 6.99105L24.6594 6.98924L24.659 6.98869L23.6199 5.27734L23.537 5.32615L24.1993 6.4895L24.2003 6.4913L24.2007 6.49186L25.2342 8.19447L25.3171 8.14567L24.6606 6.99105ZM5.79792 17.3336L5.71973 17.3892L6.05908 17.9387L6.06227 17.9437L6.06644 17.9478L6.5684 18.4363L6.63924 18.3716L5.79792 17.3336Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.61459 16.7562L5.62194 16.7438L5.62111 16.7292L5.58202 16.0275L5.57231 15.8516L5.49135 16.0081L2.2041 22.3659L2.28825 22.4123L5.61459 16.7562Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.13334 17.8789L6.0625 17.9437L6.91534 18.9946L6.99352 18.9388L6.64252 18.3764L6.63933 18.3715L6.63517 18.3674L6.13334 17.8789Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.15286 18.4679L1.05859 18.4837L1.16118 20.646L1.16187 20.658L1.16797 20.6683L2.20615 22.4122L2.29362 22.3741L1.15286 18.4679ZM29.0147 3.05068L29.0799 2.98025L28.3777 2.24387L28.3736 2.23957L28.3684 2.23624L27.4822 1.68341L27.4263 1.76159L29.0147 3.05068ZM25.2823 1.14415L25.1878 1.12695L24.122 6.47406L24.1206 6.48044L24.1212 6.48695L24.1762 7.25009L24.2716 7.25438L25.2823 1.14415Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.9904 5.81075L23.9895 5.80895L23.9882 5.80715L23.6188 5.27344L23.5381 5.32584L24.5779 7.03829L24.6608 6.98963L23.9904 5.81075Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.80023 17.3901L5.81339 17.3711L5.80688 17.349L5.61987 16.7175L5.58854 16.6113L5.5324 16.7068L2.20605 22.3628L2.28687 22.4148L5.80023 17.3901Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.88321 26.4082L5.81445 26.4753L6.6688 27.4369L6.67186 27.4403L6.67546 27.443L7.65403 28.194L7.71613 28.1207L5.88321 26.4082Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.2824 1.14443L25.1876 1.12891L25.1874 1.12905L24.1767 7.23915L24.1766 7.23998L24.0485 8.11582L24.0483 8.11666L24.1434 8.13135L24.1436 8.13052L25.2824 1.14458V1.14443ZM6.13668 17.9443L6.15969 17.9176L6.14112 17.8876L5.80176 17.3381L5.76336 17.2759L5.72136 17.3359L2.20801 22.3604L2.28397 22.4193L6.13668 17.9443Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.0508 8.76367L31.9613 8.7968L32.2429 9.95627L32.2296 9.9589L32.2561 10.0103L32.2657 10.0497L32.2752 10.0478L32.858 11.1815L32.9474 11.1479L32.6538 9.97097L32.6666 9.96834L32.6402 9.9169L32.6305 9.87753L32.6211 9.87947L32.0508 8.76367ZM5.01115 25.4262L4.94238 25.4933L5.81476 26.475L5.81795 26.4786L5.81976 26.4799L7.65074 28.1907L7.71963 28.1236L6.85294 27.1444L6.84975 27.1408L6.84795 27.1395L5.01115 25.4262Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.25071 20.6191L1.16504 20.6625L1.55943 21.5983L1.56206 21.6044L1.56622 21.6095L2.20987 22.4175L2.28888 22.3631L1.25071 20.6191Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.451 7.5918L31.3613 7.62465L31.6416 8.81379L31.6509 8.85289L31.691 8.85081L32.01 8.83431L32.0841 8.83042L32.0503 8.7643L31.451 7.5918Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.62911 18.4024L6.66751 18.3669L6.62897 18.3317L6.13254 17.8773L6.09595 17.8438L6.06365 17.8813L2.21094 22.3565L2.27998 22.4231L6.62911 18.4024Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.11954 24.4238L4.05078 24.4909L4.94173 25.4936L4.94492 25.4972L4.94881 25.5001L5.90187 26.2147L5.96356 26.1411L4.11954 24.4238Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.298 7.95669L29.3871 7.92897L29.0927 3.01133L29.091 2.98346L29.0659 2.97112L25.2558 1.09398L25.1084 1.02148L25.1934 1.16205L29.298 7.95669Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.96713 26.1453L5.96394 26.1416L5.95992 26.1387L5.007 25.4241L4.94531 25.4977L6.78211 27.2111L7.64729 28.1886L7.65394 28.1962L7.66323 28.2003L8.90394 28.7733L8.95259 28.6909L6.84921 27.1418L5.96713 26.1453ZM31.6862 8.7552L31.6119 8.75908L31.6459 8.82521L32.2583 10.0167L32.3479 9.9833L32.0544 8.77544L32.0451 8.73662L32.0052 8.7387L31.6862 8.7552ZM30.8391 6.39453L30.7494 6.42711L31.0158 7.59642L31.0237 7.6308L31.0588 7.63371L31.4044 7.66199L31.4905 7.66906L31.4513 7.59226L30.8391 6.39453Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.28262 18.7507L7.34472 18.7052L7.27652 18.6694L6.619 18.3244L6.58892 18.3086L6.56397 18.3316L2.21484 22.3523L2.27598 22.4264L7.28262 18.7507Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.20939 23.399L3.14062 23.4661L4.05085 24.4904L4.05418 24.4941L4.05598 24.4955L5.89791 26.2109L5.96667 26.1439L5.06185 25.1216L5.05853 25.1178L5.05659 25.1164L3.20939 23.399ZM32.3434 9.97134L32.2526 9.99394L32.2705 11.3939L32.2713 11.4642L32.3365 11.438L32.918 11.2039L32.967 11.1842L32.9428 11.1373L32.3434 9.97134ZM31.0663 7.53636L30.98 7.5293L31.0195 7.60637L31.6454 8.82393L31.735 8.79079L31.4547 7.60166L31.4468 7.56769L31.4119 7.56478L31.0663 7.53636Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.3412 7.97944L29.4229 7.97652L29.3806 7.90652L25.2762 1.11187L25.2085 1L25.1874 1.12907L24.0485 8.11584L24.0391 8.17379L24.0977 8.17171L29.3412 7.97944Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.95926 26.1373L5.8948 26.2079L6.78034 27.2084L6.78714 27.2161L6.79198 27.2182L8.89523 28.7674L8.95997 28.6969L8.08426 27.6984L8.07733 27.6906L8.07248 27.6884L5.95926 26.1373ZM32.3008 11.3491L32.2516 11.3689L32.2761 11.4159L32.8909 12.597L32.9816 12.5737L32.9483 11.1586L32.9468 11.0891L32.8823 11.1151L32.3008 11.3491ZM30.2134 5.16992L30.1235 5.20208L30.3757 6.35033L30.3825 6.38097L30.4131 6.3872L30.7864 6.46248L30.8842 6.48216L30.8388 6.39345L30.2134 5.16992ZM2.27956 22.3527L2.21094 22.4199L3.14084 23.4664L3.14431 23.4703L3.14847 23.4733L4.07283 24.1478L4.13396 24.0737L2.27956 22.3527Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.10026 18.9741L8.19841 18.9159L8.0882 18.8863L7.26642 18.6661L7.24411 18.6602L7.22539 18.6737L2.21875 22.3494L2.27171 22.4296L8.10026 18.9741Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.13765 24.0781L4.13418 24.0743L4.12988 24.0712L3.20553 23.3968L3.14453 23.4708L4.99367 25.1901L5.06242 25.123L4.13765 24.0781ZM31.7315 8.78125L31.6407 8.80329L31.6425 10.1875L31.6427 10.2529L31.7051 10.2332L32.3157 10.0405L32.3701 10.0233L32.344 9.97274L31.7315 8.78125Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.7229 5.08434L29.8154 5.05841L29.5783 3.93235L29.577 3.92584L29.5739 3.91988L29.0869 2.99219L28.999 3.03045L29.7229 5.08434Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.4635 6.29958L30.2358 5.26252L30.2658 5.27167L30.2169 5.17573L30.2121 5.15453L30.2048 5.15217L29.5749 3.91992L29.485 3.9518L29.7052 4.99788L29.674 4.98818L29.7233 5.08411L29.7279 5.10546L29.7355 5.10795L30.3348 6.27352L30.3749 6.35156L30.3427 7.68807L30.3413 7.7456L30.3982 7.73672L31.0144 7.64121L31.0005 8.9138L30.9768 8.91935L31 8.96357L30.9994 9.01278L31.0227 9.00738L31.6217 10.1581L31.6482 10.2089L32.2691 11.4016L31.9806 12.9513L31.9566 12.962L31.9728 12.9925L31.9591 13.0664L32.0018 13.0471L32.6141 14.2001L32.68 14.3241L32.704 14.1856L32.9772 12.6086L33.0003 12.5981L32.9843 12.5673L32.997 12.4943L32.9558 12.5127L32.3672 11.382L32.3495 9.99343L32.3487 9.92869L32.287 9.9481L31.7388 10.1211L31.7372 8.84075L31.7599 8.83548L31.737 8.79098V8.74204L31.7146 8.74731L31.1364 7.62236L31.1086 7.56843L30.8434 6.40508L30.8365 6.37472L30.806 6.36862L30.4635 6.29958ZM5.90245 26.2163L5.9073 26.2184L8.01595 27.7662L8.02288 27.7741L8.88819 28.7607L8.8983 28.7722L8.91314 28.7758L10.4243 29.1369L10.4397 29.0977L10.4719 29.0587L9.5913 28.0394L9.58104 28.0275L9.57245 28.0256L7.18432 26.6721L7.18267 26.6702L6.27452 25.6348L6.26718 25.6265L6.26218 25.6244L4.1344 24.0736L3.19229 23.0092L3.18869 23.0052L3.18439 23.0021L2.27556 22.3494L2.21484 22.4237L4.06731 24.143L4.99057 25.1861L4.99791 25.1942L5.89538 26.2083L5.90245 26.2163Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.26949 22.3457L2.21875 22.4272L3.12759 23.08L3.13244 23.0835L3.13812 23.0857L4.32671 23.5522L4.36691 23.465L2.26949 22.3457Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.1843 23.0026L3.12012 23.0733L4.06555 24.1416L4.07304 24.1501L4.07802 24.152L6.20983 25.7057L6.27429 25.6352L5.33828 24.568L5.3308 24.5594L5.32566 24.5574L3.1843 23.0026ZM31.7333 10.1649L31.6703 10.0438L31.6435 10.1776L31.328 11.7559L31.3107 11.8422L31.3927 11.8101L32.3368 11.4393L32.3865 11.4197L32.3617 11.3723L31.7333 10.1649ZM30.3833 7.64213L30.317 7.6524L30.3479 7.71187L31.0053 8.97461L31.0961 8.95299L31.111 7.58599L31.1116 7.5293L31.0556 7.53789L30.3833 7.64213Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.2914 7.93439L29.387 7.93869L29.8165 5.07495L29.8183 5.06303L29.8143 5.05179L29.0904 2.99805L28.9971 3.0169L29.2914 7.93439Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.26186 25.6245L6.20211 25.698L7.11732 26.7416L7.12785 26.7536L7.13618 26.7553L9.52388 28.1086L10.3989 29.1215L10.4121 29.1368L10.4323 29.138L12.2177 29.2516L12.2396 29.1594L9.5839 28.0309L8.69059 26.9969L8.68006 26.9847L8.67146 26.9828L6.26186 25.6245ZM31.3576 11.72L31.3074 11.7397L31.3327 11.7874L31.9706 12.9883L31.3512 14.6866L31.3136 14.7898L31.415 14.7475L32.6749 14.2217L32.7237 14.2014L32.6989 14.1548L32.0734 12.9769L32.3665 11.4028L32.3823 11.3176L32.3016 11.3492L31.3576 11.72ZM29.8117 5.04584L29.7279 4.88281L29.7209 5.06594L29.6716 6.35267L29.6419 6.35517L29.6695 6.40813L29.668 6.44943L29.6902 6.44777L30.3479 7.71136L30.4386 7.69028L30.4702 6.38483L30.4989 6.38247L30.4715 6.32924L30.4724 6.28807L30.4512 6.28988L29.8117 5.04584ZM31.0906 8.92974L31.029 8.81136L31.001 8.94194L30.6653 10.5065L30.6475 10.5892L30.7276 10.5622L31.7061 10.2321L31.7593 10.2143L31.7334 10.1643L31.0906 8.92974ZM4.38083 23.4758L4.37321 23.4669L4.36226 23.4627L3.17367 22.9963L3.12793 23.0799L5.274 24.6381L5.33832 24.5675L4.38083 23.4758Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.3491 7.97792L29.3374 7.88281L24.0939 8.07496L24.0606 8.0762L24.05 8.10781L23.7208 9.09511L23.6943 9.17468L23.7764 9.15735L29.3491 7.97792Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.32523 24.5568L5.26562 24.6304L6.2015 25.6976L6.21231 25.7099L6.22076 25.7116L8.62289 27.0657L9.518 28.1018L9.5313 28.1173L9.54337 28.1178L12.2014 29.2474L12.257 29.1721L11.3766 28.1304L11.3631 28.1145L11.3506 28.1138L8.68277 26.9879L7.76895 25.93L7.75814 25.9174L7.7494 25.9158L5.32523 24.5568ZM30.6964 10.4705L30.6427 10.4887L30.6694 10.5387L31.3323 11.7868L31.3953 11.9059L31.4218 11.7738L31.7373 10.1955L31.7537 10.114L31.6748 10.1406L30.6964 10.4705ZM31.3773 14.6582L31.3281 14.6788L31.3538 14.7257L32.0085 15.9255L32.0603 16.0204L32.0961 15.9184L32.7013 14.1928L32.7371 14.0909L32.6374 14.1325L31.3773 14.6582ZM30.4327 7.66639L30.3726 7.55078L30.3433 7.6779L29.9865 9.22802L29.9683 9.30718L30.0465 9.285L31.0606 8.99776L31.1174 8.98169L31.0901 8.92928L30.4327 7.66639Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.25913 22.3419L2.22461 22.4309L4.32203 23.5501L4.33104 23.555L4.34116 23.5557L7.60249 23.792L7.61774 23.6975L2.25913 22.3419ZM23.7566 9.0642L23.734 9.06906L23.7236 9.08971L23.1945 10.1483L23.1403 10.2565L23.254 10.2148L29.3559 7.97695L29.3294 7.88477L23.7566 9.0642Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.4177 11.7412L31.3679 11.6474L31.3302 11.7467L30.6891 13.4367L30.6576 13.4486L30.6736 13.4776L30.6435 13.5567L30.7042 13.5339L31.3542 14.7251L31.4052 14.8184L31.4417 14.7184L32.0608 13.0208L32.0913 13.0093L32.0756 12.9801L32.1043 12.9016L32.0458 12.9236L31.4177 11.7412ZM4.3682 23.4644L4.30859 23.5381L5.26608 24.6298L5.27718 24.6424L5.28577 24.644L7.70217 25.9986L8.61794 27.0587L8.63166 27.0745L8.64372 27.0751L11.311 28.2007L12.1841 29.2336L12.2 29.2525L12.2247 29.2504L14.2164 29.0869L14.2265 28.9931L11.368 28.119L10.4762 27.0638L10.4625 27.0477L10.45 27.0472L7.76206 25.9208L6.82674 24.8382L6.81565 24.8253L6.80678 24.8238L4.3682 23.4644ZM30.0209 9.19196L29.9635 9.20818L29.9915 9.26071L30.67 10.5382L30.7318 10.6545L30.7594 10.5257L31.0951 8.961L31.1119 8.88281L31.035 8.90458L30.0209 9.19196Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.7212 5.06055L29.2959 7.89616L23.2207 10.1242L23.2059 10.1297L23.1971 10.143L22.489 11.2237L22.3934 11.3699L22.5503 11.2933L29.3602 7.97475L29.3431 7.93455L29.3858 7.94301L29.7646 6.40855L29.7657 6.40384L29.766 6.39885L29.8168 5.06956L29.7212 5.06055ZM8.08726 18.886L8.06813 18.8813L8.05107 18.8913L2.22266 22.3467L2.25191 22.436L16.2992 21.0284L16.306 20.9338L8.08726 18.886Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.4386 14.6797L31.3959 14.6015L31.354 14.6801L30.423 16.4272L30.3867 16.4401L30.4019 16.467L30.356 16.5529L30.4347 16.5252L31.0931 17.6923L31.1368 17.7697L31.1777 17.6908L32.0853 15.9416L32.1205 15.9291L32.1058 15.9021L32.15 15.8167L32.0739 15.8437L31.4386 14.6797ZM29.7608 6.3747L29.702 6.26172L29.6714 6.38537L29.2998 7.89113L29.2676 7.89848L29.2883 7.93757L29.2741 7.99579L29.3143 7.98651L29.9915 9.26146L30.0519 9.37527L30.0807 9.24968L30.4308 7.72894L30.462 7.72173L30.4414 7.68236L30.4548 7.62469L30.4161 7.63357L29.7608 6.3747ZM30.7547 10.4937L30.7059 10.4017L30.6675 10.4987L30.0037 12.1794L29.9707 12.1906L29.9872 12.221L29.9574 12.2967L30.0174 12.2763L30.684 13.4979L30.7341 13.5897L30.7712 13.4918L31.4121 11.8028L31.4441 11.7918L31.4279 11.7612L31.4563 11.6862L31.3985 11.7057L30.7547 10.4937ZM13.3746 27.9444L13.3584 27.9247L13.3423 27.9261L10.4669 27.0538L9.55388 25.9736L9.54002 25.9571L9.52741 25.9568L6.81934 24.8303L5.86171 23.7219L5.85034 23.7087L5.83329 23.7059L4.35262 23.4597L4.32129 23.5491L6.75945 24.9082L7.69671 25.993L7.71057 26.0092L7.72277 26.0095L10.4102 27.1355L11.3036 28.1927L11.3198 28.2118L11.3354 28.2103L14.1983 29.0857L14.3506 29.1325L14.2496 29.0094L13.3746 27.9444Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5086 11.2072L22.4991 11.212L22.4923 11.2204L21.6205 12.3042L21.6819 12.376L29.3636 7.97365L29.3187 7.88867L22.5086 11.2072ZM9.07657 18.966L9.07227 18.9649L9.06783 18.9645L8.07998 18.8852L8.06445 18.9798L16.2822 21.0273L16.2833 21.0276L16.3076 20.9345L16.3065 20.9343L9.07657 18.966Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3467 27.9284L13.2129 27.9004L13.2997 28.0059L14.1747 29.0709L14.1931 29.0933L14.2217 29.0874L16.3234 28.6453V28.5511L13.3467 27.9284ZM30.7679 13.4522L30.7257 13.375L30.6834 13.4522L29.7194 15.2128L29.6612 15.3191L29.7765 15.2815L31.4107 14.7489L31.4657 14.7311L31.438 14.6802L30.7679 13.4522Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.6334 12.293L21.6274 12.2965L21.6226 12.3016L20.6055 13.3679L20.6659 13.4418L29.3646 7.97297L29.3151 7.89062L21.6334 12.293Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.0765 9.21733L30.0284 9.12695L29.9895 9.2215L29.2934 10.9132L29.2549 11.0067L29.3517 10.9776L30.7263 10.5633L30.7823 10.5464L30.7548 10.4946L30.0765 9.21733ZM29.7474 15.1906L29.6916 15.2088L29.7204 15.2599L30.4149 16.4909L30.458 16.5673L30.4992 16.4898L31.439 14.7264L31.4951 14.621L31.3817 14.658L29.7474 15.1906ZM12.4789 26.8552L12.4624 26.8351L12.4463 26.8369L9.5311 25.9595L9.37598 25.9128L9.48064 26.0366L10.4028 27.1276L10.4191 27.1472L10.4348 27.1455L13.3235 28.0219L13.4754 28.0679L13.3747 27.9454L12.4789 26.8552Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.34829 23.459L4.33691 23.5544L5.81759 23.8006L5.82161 23.8013H5.82576L7.60642 23.7914L7.60961 23.6953L4.34829 23.459Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.84382 23.709L5.78906 23.7848L6.75404 24.9019L6.76832 24.9184L6.78995 24.9185L8.57269 24.9357L8.59154 24.8432L5.84382 23.709Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.6147 13.3593L20.6113 13.3615L20.6082 13.3643L19.4672 14.3925L19.5258 14.4683L29.3647 7.972L29.3657 7.97145L29.3135 7.89062L29.3126 7.89117L20.6147 13.3593ZM10.2324 18.8884L10.2234 18.8854L10.2139 18.8861L9.06021 18.964L9.05078 19.0584L16.2817 21.0267L16.3097 20.9348L10.2324 18.8884Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.60939 24.8564L8.59526 24.8394L8.57322 24.8393L6.79048 24.8221L6.77148 24.9146L9.49841 26.0488L9.55358 25.9734L8.60939 24.8564ZM29.3235 10.8843L29.2668 10.9015L29.2953 10.9535L29.9977 12.2406L30.0469 12.3308L30.0846 12.2352L30.7567 10.5337L30.7933 10.4414L30.6982 10.4701L29.3235 10.8843ZM30.4983 16.4426L30.4607 16.3759L30.417 16.4387L29.1824 18.2105L29.104 18.3233L29.2356 18.2841L31.1484 17.7146L31.2062 17.6974L31.1767 17.6449L30.4983 16.4426Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.4727 14.3875L19.471 14.3885L19.4695 14.3897L18.2285 15.3586L18.2849 15.4363L29.3655 7.9709L29.3658 7.97075L29.3124 7.89062L29.3121 7.8909L19.4727 14.3875Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.2082 18.1921L29.1494 18.2096L29.1805 18.2626L29.884 19.4627L29.9224 19.5281L29.9651 19.4654L31.1745 17.6958L31.251 17.584L31.1211 17.6227L29.2082 18.1921Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.2313 15.3564H18.2312L16.9162 16.2456L16.916 16.2458L16.9698 16.3255L29.3658 7.97034L29.339 7.93055L29.3121 7.89062L29.3115 7.89104L18.2313 15.3564Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.4868 27.4802L15.4682 27.4566L15.449 27.4609L12.4509 26.8378L12.3174 26.8101L12.404 26.9154L13.2998 28.0056L13.3185 28.0285L13.3373 28.0243L16.3039 28.6451L16.4342 28.6722L16.3513 28.568L15.4868 27.4802ZM30.082 12.1948L30.0404 12.1187L29.9978 12.1943L29.0086 13.9512L28.9502 14.0549L29.0642 14.0208L30.7396 13.5211L30.7964 13.504L30.768 13.4519L30.082 12.1948ZM29.3815 7.90889L29.3343 7.82031L29.2948 7.9125L28.5736 9.59334L28.5347 9.684L28.63 9.65892L30.0458 9.2856L30.1044 9.27007L30.076 9.21655L29.3815 7.90889Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.513 18.6474L11.4987 18.6406L11.4833 18.6437L10.2087 18.8859L10.0283 18.9202L10.2023 18.9787L16.2795 21.025L16.3156 20.936L11.513 18.6474Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.0367 13.9277L28.9789 13.945L29.0085 13.9974L29.7198 15.2582L29.7624 15.3335L29.8038 15.2577L30.768 13.4971L30.8241 13.3945L30.712 13.4279L29.0367 13.9277ZM15.4552 27.4614L15.3383 27.4468L15.4115 27.539L16.276 28.627L16.2968 28.6532L16.3286 28.6428L18.4939 27.9327L18.4848 27.8394L15.4552 27.4614ZM11.5609 25.737L11.5441 25.7168L11.5279 25.7186L8.58635 24.8406L8.43164 24.7945L8.53575 24.9179L9.47994 26.0349L9.49671 26.0546L9.51238 26.0528L12.4273 26.93L12.5785 26.9756L12.4783 26.8535L11.5609 25.737Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.24228 22.3393L2.23535 22.4338L7.59396 23.7893L7.60727 23.7926L7.6203 23.7886L16.3089 21.0254L16.2896 20.9316L2.24228 22.3393Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.64248 23.7127L7.62792 23.6955L7.60546 23.6956L5.82481 23.7055L5.80664 23.7981L8.55436 24.9322L8.6094 24.8567L7.64248 23.7127ZM28.6056 9.56596L28.5463 9.58162L28.5756 9.63541L29.2953 10.9539L29.3435 11.0423L29.3819 10.9492L30.0781 9.25752L30.1148 9.16797L30.0214 9.19264L28.6056 9.56596ZM29.8037 15.2119L29.7666 15.146L29.7226 15.2078L28.4721 16.9675L28.4283 16.9796L28.4442 17.0067L28.3838 17.0919L28.4786 17.0655L29.163 18.233L27.6931 19.9354L27.6441 19.9473L27.6602 19.9735L27.5847 20.0608L27.6968 20.0334L28.4082 21.1969L28.4428 21.2534L28.4858 21.203L29.9519 19.4815L29.9996 19.4699L29.9842 19.4437L30.0585 19.3563L29.9487 19.3832L29.279 18.2407L30.4859 16.5082L30.5285 16.4965L30.5131 16.4693L30.5721 16.3846L30.4798 16.4102L29.8037 15.2119ZM14.6015 26.3661L14.5827 26.3423L14.5531 26.3491L12.4305 26.8381L12.4315 26.9321L15.4395 27.5572L15.5697 27.5842L15.4869 27.4803L14.6015 26.3661Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.8576 18.2553L12.8381 18.2402L12.8145 18.2472L11.4783 18.6449L11.3584 18.6805L11.4714 18.7343L16.274 21.023L16.3241 20.9415L12.8576 18.2553Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.6592 26.8796L17.6542 26.7303L17.6468 26.7206L17.6245 26.7281L14.5714 26.3478L11.5499 25.7241L10.6209 24.5936L10.604 24.5731L10.5776 24.5766L8.56608 24.8394L8.55859 24.9331L11.4957 25.8097L12.4038 26.9148L12.4227 26.9378L12.4516 26.9311L14.5449 26.4489L15.4113 27.5393L15.4324 27.5658L15.4539 27.5586L18.4528 27.9326L18.4635 27.9466L18.4859 27.9367L18.5866 27.9494L18.554 27.9067L20.6761 26.9715L20.6592 26.8796ZM29.3793 10.907L29.3383 10.832L29.2955 10.9059L28.2899 12.6414L28.2493 12.6523L28.2662 12.6823L28.2214 12.7595L28.298 12.7387L29.0084 13.9977L29.0502 14.072L29.0921 13.9977L30.0716 12.2584L30.111 12.2477L30.0945 12.2176L30.1378 12.1408L30.0636 12.1609L29.3793 10.907Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.2401 17.7263L14.2186 17.6934L14.182 17.7079L12.8099 18.2504L12.7314 18.2814L12.7981 18.3331L16.2646 21.0194L16.3345 20.9552L14.2401 17.7263Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.61997 23.6983L7.46582 23.6527L7.56951 23.7755L8.53657 24.9196L8.55349 24.9395L8.57941 24.9362L10.5909 24.6732L10.5982 24.5795L7.61997 23.6983ZM25.9226 21.6383L25.8338 21.7234L25.9614 21.699L26.7003 22.854L26.732 22.9037L26.7741 22.8626L28.4729 21.217L28.5269 21.2066L28.5109 21.1801L28.5989 21.0949L28.4734 21.1189L27.761 19.954L27.7298 19.903L27.6867 19.9444L25.9612 21.601L25.9056 21.6117L25.9226 21.6383ZM29.0929 13.9519L29.0562 13.8867L29.0121 13.9473L27.735 15.7084L27.6899 15.7199L27.7063 15.7479L27.6464 15.8305L27.7405 15.8063L28.4604 17.0342L28.4981 17.0984L28.5411 17.0378L29.7915 15.2785L29.835 15.2672L29.8194 15.2393L29.8779 15.1569L29.7862 15.1805L29.0929 13.9519Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.6282 17.0694L15.6168 17.007L15.5597 17.0348L14.1787 17.7092L14.1299 17.733L14.1596 17.7786L16.2538 21.0073L16.3415 20.9725L15.6282 17.0694ZM27.5323 11.3384L27.6163 11.3851L28.6591 9.63772L28.6607 9.63494L28.662 9.63203L29.3831 7.95118L29.2962 7.91016L27.5323 11.3384Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.8166 25.7492L19.7335 25.7508L19.7532 25.7775L17.6102 26.7343L17.6274 26.8262L20.6318 26.9755L20.6433 26.9912L20.6684 26.9773L20.7556 26.9818L20.7282 26.9442L22.8184 25.7858L22.7943 25.6957L19.8512 25.7487L19.843 25.7375L19.8166 25.7492ZM26.9123 18.7511L26.8377 18.8363L26.9489 18.8108L27.6785 20.0039L27.7129 20.06L27.7558 20.0102L29.2482 18.2821L29.2965 18.271L29.2809 18.2441L29.3543 18.159L29.2457 18.184L28.543 16.9853L28.5091 16.9277L28.4652 16.9779L26.9458 18.7128L26.8959 18.7243L26.9123 18.7511ZM13.6945 25.2251L13.6755 25.201L13.6457 25.2082L11.5127 25.7222L11.5142 25.8161L14.5543 26.4435L14.6842 26.4702L14.6017 26.3664L13.6945 25.2251Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9904 16.2929L17.0039 16.1953L16.9186 16.2448L15.5565 17.0355L15.5273 17.0524L15.5333 17.0857L16.2467 20.9889L16.3416 20.9868L16.9904 16.2929Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.7981 25.6113L16.777 25.5838L16.7443 25.5951L14.5485 26.3506L14.5582 26.4437L17.624 26.8256L17.7379 26.8398L17.6681 26.7487L16.7981 25.6113ZM24.0508 23.1487L23.9747 23.1591L24.0174 23.223L24.7544 24.3232L21.984 24.5445L21.9001 24.5512L21.9484 24.6201L22.7559 25.7711L22.783 25.8098L22.8221 25.7833L24.827 24.4314L24.8293 24.435L24.8687 24.4033L25.0187 24.3021L24.9911 24.3043L26.7706 22.8649L26.9048 22.7564L26.7339 22.78L24.0508 23.1487ZM10.594 24.578L10.4609 24.5506L10.5472 24.6556L11.4869 25.7991L11.506 25.8224L11.5353 25.8153L13.6683 25.3014L13.6668 25.2076L10.594 24.578ZM28.6603 9.58952L28.6199 9.51562L28.5768 9.58785L27.534 11.3352L27.4753 11.4337L27.5867 11.4066L29.3489 10.9776L29.4098 10.9629L29.3799 10.9079L28.6603 9.58952Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.9165 16.2458L16.8988 16.2577L16.8957 16.2791L16.2471 20.973L16.2275 21.1148L16.3287 21.0136L29.3735 7.96452L29.3126 7.89062L16.9165 16.2458Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.743 19.9253L27.031 18.7608L28.5268 17.0529L28.5761 17.0423L28.56 17.0148L28.6328 16.9318L28.525 16.9549L27.8202 15.7527L29.0786 14.0176L29.1236 14.0069L29.1073 13.9781L29.1653 13.8982L29.0743 13.9197L28.3769 12.6836L29.3786 10.9549L29.4352 10.8574L29.3257 10.884L27.5634 11.3131L27.5018 11.3281L27.533 11.3834L28.2643 12.6798L26.978 14.4167L26.9319 14.4276L26.9488 14.4563L26.8893 14.5366L26.9827 14.5144L27.7037 15.7442L26.1797 17.4591L26.1288 17.4701L26.1456 17.4975L26.0717 17.5807L26.182 17.557L26.9101 18.7478L25.1856 20.3883L25.1292 20.3985L25.1465 20.4256L25.0587 20.509L25.1852 20.4861L25.9022 21.6069L23.2486 21.9513L23.1716 21.9613L23.2149 22.0257L23.9709 23.1544L21.1561 23.3649L21.0718 23.3711L21.1204 23.4404L21.8957 24.5457L19.0005 24.5889L18.992 24.5774L18.9656 24.5894L18.8824 24.5906L18.9025 24.6179L16.7744 25.581L15.9061 24.4456L15.8847 24.4179L15.8519 24.4295L13.6729 25.1978L12.7646 24.055L12.7454 24.0306L12.726 24.0355L9.64722 23.4094L9.64084 23.4016L9.62226 23.4044L9.49778 23.379L9.52952 23.4177L7.59859 23.6962L7.5918 23.7899L10.556 24.6669L10.566 24.6791L10.5828 24.6748L10.7205 24.7155L10.7072 24.6993L13.6298 25.2982L14.526 26.4259L14.5471 26.4525L14.5793 26.4414L16.7423 25.6973L17.5912 26.8071L17.6144 26.8373L17.649 26.8218L19.8109 25.8565L19.8131 25.8595L19.8381 25.8455L19.9254 25.85L19.9204 25.8432L22.7955 25.7915L22.8861 25.7899L22.8341 25.7158L22.0749 24.6336L24.8433 24.4125L24.9251 24.406L24.8794 24.3377L24.1396 23.2333L26.7464 22.8751L26.8208 22.8648L26.7803 22.8015L26.0472 21.6553L26.1467 21.5752L26.1222 21.5784L27.742 20.0231L27.7968 20.0132L27.7803 19.9863L27.8675 19.9027L27.743 19.9253Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.5957 23.6964L7.61234 23.791L9.62769 23.5005L9.63046 23.5001L9.6331 23.4994L11.7865 22.932L11.7645 22.8384L7.5957 23.6964ZM24.5744 14.8739L24.6511 14.9317L27.6117 11.3903L27.6152 11.3863L27.6177 11.3816L29.3816 7.95323L29.2989 7.9043L24.5744 14.8739Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.9963 18.702L26.2656 17.5073L27.7889 15.7931L27.8393 15.7831L27.8228 15.7549L27.8949 15.6738L27.7877 15.6952L27.0647 14.4616L28.3503 12.7257L28.3963 12.7157L28.3796 12.686L28.4371 12.6084L28.3468 12.6279L27.6175 11.3354L27.5816 11.2715L27.5374 11.33L26.2039 13.0922L26.1564 13.1026L26.1737 13.1321L26.1148 13.2099L26.2077 13.1898L26.9478 14.4524L25.3955 16.1735L25.3436 16.1839L25.3608 16.2119L25.2876 16.2931L25.397 16.2712L26.1446 17.4937L24.3922 19.1451L24.335 19.1549L24.3527 19.1823L24.2659 19.2641L24.3913 19.2428L25.1275 20.3938L22.4276 20.7229L22.3498 20.7325L22.3935 20.7975L23.1694 21.9558L20.309 22.1546L20.224 22.1606L20.2729 22.2303L21.0689 23.3648L18.1295 23.3996L18.121 23.388L18.0947 23.4001L18.0113 23.401L18.0318 23.4289L15.8835 24.4143L14.9928 23.2499L14.9714 23.2219L14.9383 23.2338L12.744 24.0271L11.8127 22.855L11.7932 22.8304L11.7628 22.8385L9.60938 23.4057L9.61214 23.4995L12.7012 24.1276L13.6197 25.2836L13.6411 25.3105L13.6733 25.2991L15.8517 24.531L16.722 25.6688L16.7453 25.6992L16.7801 25.6834L18.9614 24.6964L18.9635 24.6993L18.9886 24.6853L19.0759 24.6898L19.0711 24.6832L21.9887 24.6397L22.0795 24.6383L22.0274 24.564L21.2486 23.4536L24.0612 23.2433L24.1435 23.2372L24.0977 23.1686L23.3388 22.0358L25.9905 21.6918L26.0656 21.6819L26.0248 21.6181L25.2729 20.4427L25.3711 20.364L25.3476 20.3669L26.9948 18.7999L27.0504 18.7904L27.0336 18.763L27.1197 18.6811L26.9963 18.702Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.3047 7.89844L16.2683 20.9392L7.59082 23.6987L7.61508 23.7917L11.7839 22.9337L11.7886 22.9328L11.7929 22.9308L16.3126 21.0257L16.3018 20.9961L16.3223 21.0203L24.6426 14.9408L24.6494 14.9358L24.6539 14.9289L29.3786 7.95929L29.3047 7.89844Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.3508 19.1799L22.4049 20.7324L20.2869 22.1622L18.094 23.3997L14.9588 23.2315L11.7809 22.8377L11.6641 22.8231L11.7373 22.9153L12.6902 24.1143L12.7117 24.1413L12.7441 24.1297L14.9379 23.3367L15.8303 24.5036L15.8538 24.5341L15.8886 24.5181L18.1162 23.4964L21.1611 23.4604L21.2523 23.4593L21.1999 23.3847L20.4004 22.2452L23.2589 22.0464L23.3417 22.0407L23.2955 21.9717L22.5169 20.8094L25.215 20.4803L25.2908 20.4712L25.2496 20.4067L24.4766 19.1982L26.2282 17.5476L26.2845 17.5385L26.2674 17.5105L26.3525 17.4304L26.2303 17.4498L25.4616 16.1928L25.4308 16.1426L25.3877 16.1828L23.5779 17.8717L23.5198 17.8808L23.5378 17.909L23.452 17.9891L23.5764 17.9693L24.3508 19.1799Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.5781 14.8695L24.6505 14.9328L26.2669 13.168L26.2684 13.1663L26.2698 13.1645L27.614 11.388L27.5388 11.3281L24.5781 14.8695Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.3722 20.9467L17.1996 22.1805L14.0415 22.0068L14.0335 21.9961L14.0095 22.005L13.9153 21.9998L13.9391 22.031L11.7578 22.8395L11.7685 22.9324L14.9282 23.324L14.9393 23.3387L14.9619 23.3283L15.062 23.3406L15.0556 23.3325L18.0796 23.4947L18.0914 23.5106L18.1163 23.4966L18.2037 23.5013L18.1757 23.463L20.2975 22.2657L20.2996 22.2686L20.3266 22.2503L20.4037 22.2496L20.3793 22.2149L22.4197 20.8373L22.4219 20.8406L22.4511 20.8175L22.5193 20.8131L22.4994 20.7835L22.6067 20.7112L22.5823 20.7127L24.4333 19.236L24.4962 19.2288L24.4779 19.2004L24.5748 19.1231L24.4384 19.1386L23.6399 17.8903L23.6112 17.8453L23.5695 17.8785L21.5706 19.4656L21.5059 19.473L21.5231 19.4987L19.4258 20.9152L19.3533 20.9199L19.3722 20.9467ZM24.5546 14.8943L24.4821 14.9734L24.5908 14.9533L25.379 16.2423L25.4125 16.2973L25.4556 16.2495L27.0313 14.5026L27.0827 14.4931L27.0658 14.4644L27.1372 14.3852L27.031 14.4049L26.2724 13.111L26.2394 13.0547L26.1955 13.1028L24.5899 14.8556L24.537 14.8654L24.5546 14.8943Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.7559 22.8407L11.7913 22.9301L14.0336 22.099L14.0356 22.0983L14.0375 22.0974L16.3149 21.0233L16.2758 20.9355L11.7559 22.8407Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.6996 16.6003L20.7058 18.1757L20.6403 18.1826L20.6599 18.2118L20.5644 18.2873L20.7008 18.2728L21.5027 19.47L18.5733 19.6421L18.5636 19.6282L18.5353 19.6443L18.4604 19.6487L18.4797 19.676L16.2812 20.9327L16.1996 20.9331L16.2208 20.9621L13.9961 22.0113L14.014 22.1028L17.1855 22.2772L17.1973 22.2934L17.2222 22.2793L17.3097 22.2841L17.2815 22.2456L19.4273 21.0271L19.4294 21.0298L19.4565 21.0117L19.5338 21.0111L19.5114 20.9792L19.6254 20.9145L19.6001 20.9146L21.5763 19.58L21.5786 19.5831L21.6179 19.5518L21.7617 19.4548L21.7385 19.4562L23.5874 17.9881L23.5897 17.9918L23.6202 17.9634L23.6817 17.957L23.6631 17.9281L23.7587 17.8521L23.7371 17.8545L25.4415 16.2639L25.4986 16.2554L25.4812 16.2268L25.5653 16.1483L25.4442 16.1663L24.6552 14.8762L24.6247 14.8262L24.5816 14.866L22.7419 16.5657L22.683 16.5743L22.6996 16.6003Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.2656 20.9408L16.3194 21.0205L20.7499 18.2617L20.7531 18.2599L20.755 18.2581L22.7934 16.6477L22.7948 16.6466L22.7962 16.6453L24.6468 14.9355L24.6251 14.911L24.5859 14.8613L16.2656 20.9408Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.7652 18.1944L20.7383 18.1543L20.6984 18.1813L18.5231 19.6509L18.3779 19.7491L18.5529 19.7388L21.593 19.5602L21.6768 19.5553L21.6301 19.4854L20.7652 18.1944Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.2686 20.9403L16.3179 21.0229L18.5732 19.7338L18.5747 19.7329L18.5763 19.7319L20.7515 18.2623L20.6992 18.1816L16.2686 20.9403Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};
export default FeaturesBottomLeftMobile;
