import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const TwitterStroked: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 47 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.2308 22.5C46.2308 34.6326 36.0115 44.5 23.3654 44.5C10.7192 44.5 0.5 34.6326 0.5 22.5C0.5 10.3674 10.7192 0.5 23.3654 0.5C36.0115 0.5 46.2308 10.3674 46.2308 22.5Z"
          stroke="white"
        />
        <path
          d="M34.92 16C34.15 16.35 33.32 16.58 32.46 16.69C33.34 16.16 34.02 15.32 34.34 14.31C33.51 14.81 32.59 15.16 31.62 15.36C30.83 14.5 29.72 14 28.46 14C26.11 14 24.19 15.92 24.19 18.29C24.19 18.63 24.23 18.96 24.3 19.27C20.74 19.09 17.57 17.38 15.46 14.79C15.09 15.42 14.88 16.16 14.88 16.94C14.88 18.43 15.63 19.75 16.79 20.5C16.08 20.5 15.42 20.3 14.84 20V20.03C14.84 22.11 16.32 23.85 18.28 24.24C17.6507 24.4122 16.9901 24.4362 16.35 24.31C16.6216 25.1625 17.1535 25.9084 17.871 26.4429C18.5885 26.9775 19.4554 27.2737 20.35 27.29C18.8336 28.4904 16.954 29.1393 15.02 29.13C14.68 29.13 14.34 29.11 14 29.07C15.9 30.29 18.16 31 20.58 31C28.46 31 32.79 24.46 32.79 18.79C32.79 18.6 32.79 18.42 32.78 18.23C33.62 17.63 34.34 16.87 34.92 16Z"
          fill="white"
        />
      </svg>
  );
};
export default TwitterStroked;
