import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const LinkedInStroked: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.188 12.376C12.3964 12.376 13.376 11.3964 13.376 10.188C13.376 8.9796 12.3964 8 11.188 8C9.9796 8 9 8.9796 9 10.188C9 11.3964 9.9796 12.376 11.188 12.376Z"
          fill="white"
        />
        <path
          d="M15.4428 14.0344V26.1734H19.2118V20.1704C19.2118 18.5864 19.5098 17.0524 21.4738 17.0524C23.4108 17.0524 23.4348 18.8634 23.4348 20.2704V26.1744H27.2058V19.5174C27.2058 16.2474 26.5018 13.7344 22.6798 13.7344C20.8448 13.7344 19.6148 14.7414 19.1118 15.6944H19.0608V14.0344H15.4428ZM9.30078 14.0344H13.0758V26.1734H9.30078V14.0344Z"
          fill="white"
        />
        <circle cx="18" cy="18" r="17.25" stroke="white" strokeWidth="1.5" />
      </svg>
  );
};
export default LinkedInStroked;
