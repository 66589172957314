/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { InputHTMLAttributes, type FC, useRef, useEffect } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { cn } from '@util/functions/utils';
export interface IAnimatedInputsProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const AnimatedInputs: FC<IAnimatedInputsProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isInVIew = useInView(ref);
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInVIew) {
      void mainControls.start("visible");
    } else {
      void mainControls.start("hidden");
    }
  }, [isInVIew, mainControls]);
  return (
    <motion.input
      ref={ref}
      variants={{
        hidden: {
          opacity: 0,
          scale: 0.2,
        },
        visible: {
          opacity: 1,
          scale: 1,
        },
      }}
      initial="hidden"
      animate={mainControls}
      className={cn(`peer border outline-none ${
        props.error
          ? 'border-red-200 focus:border-red-500'
          : 'border-pink-main focus:border-gray-300'
      } rounded-[4px] px-3 py-2.5 text-sm bg-transparent text-dark placeholder:text-[#7C7C7C]`, props.className)}
      transition={{
        ease: [0.6, 0.01, 0.05, 0.95],
        duration: 0.5,
      }}
      {...(props as any)}
    />
  );
}
export default AnimatedInputs;