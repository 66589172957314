import React, { type FC } from 'react';

import Image from 'next/legacy/image';

import { close as closeIcon } from '../public';
import { type PropTypes } from '../typescript';

// import { Payment } from './util';
import FlutterWavePayment from './util/FlutterWavePayment';
interface IChooseMethodProps {
  close: () => void
  opened: boolean
}
export interface PaymentProp {
  amount: number
  voteCount: number | boolean
  setSuccessToggle?: () => void
  metadata?: string
  nominee_id?: number
  name?: string
}
const ChooseMethod: FC<PropTypes<IChooseMethodProps, PaymentProp>> = ({
  opened,
  close,
  amount,
  voteCount,
  setSuccessToggle,
  metadata,
  nominee_id,
  name
}) => {
  return (
    <>
      <div
        className={`${
          opened ? 'scale-100' : 'scale-0'
        } fixed inset-0 z-40 flex h-screen w-full origin-center flex-col items-center justify-center bg-black/70 transition-[transform] duration-500 ease-in`}
      >
        <div
          onBlur={close}
          tabIndex={0}
          className=" z-40 flex h-auto w-4/5 flex-col items-center gap-8 overflow-y-scroll rounded bg-white p-10 shadow-sm lg:w-2/5"
        >
          <div className="float-right ml-auto cursor-pointer" onClick={close}>
            <Image src={closeIcon} width={24} height={24} alt="close" />{' '}
          </div>
          <h1 className="text-lg font-bold tracking-wider text-back lg:text-2xl">
            Payment Method
          </h1>
          {/* {metadata !== 'XOF' && metadata !== 'EUR'
            ? (
            <> */}
          {/* <Payment
                {...{
                  amount,
                  voteCount,
                  setSuccessToggle,
                  text: `Pay with ${metadata ?? ''}`,
                  metadata,
                  nominee_id
                }} */}
          {/* /> */}
          <FlutterWavePayment
            {...{
              amount,
              currency: metadata,
              voteCount,
              setSuccessToggle,
              text: `Pay with ${metadata ?? ''}`,
              metadata,
              nominee_id,
              name
            }}
          />
          {/* : (
            <FlutterWavePayment
              {...{
                amount,
                currency: metadata,
                voteCount,
                setSuccessToggle,
                text: `Pay with ${metadata}`,
                metadata,
                nominee_id
              }}
            /> */}
        </div>
      </div>
    </>
  );
};

export default ChooseMethod;
