const COMPANY_ICONS = [
  '/Microsoft-Logo.svg',
  // '/templars.png',
  '/amazon-logo-inverse.svg',
  '/paystack-logo.svg',
  '/UBA-Logo.svg',
  '/flutterwave-logo.svg',
  // '/meta-map.png',
  '/cowrywise-logo.svg',
  '/leatherback-logo.svg',
  '/data-culture.svg',
  // '/absa-logo-passion.jpeg',
  // '/deloitte.svg',
  // '/logo-light.svg',
  '/thoughtworks-logo.svg',
  // '/TigerLogic-Logo-white.png',
  '/sage-logo.svg',
  '/africhange.svg',
  // '/fair-money.jpeg',
  // '/barclays-logo.jpeg',
  '/sunfi-logo.svg',
  // '/smarterise.png',
  // '/explore-ai.png',
  '/vibra.svg',
  '/bots-craft.svg',
  '/monie-point.svg',
  // '/flip-side-logo.png',
  '/deimos-logo.svg',
  '/the_tyms_color_logo_main.svg',
  '/symphony-solutions-logo.svg',
  '/res-group-logo.svg',
  // '/brooklyn-data-logo.png',
  // '/celllant-logo.png',
  'engie-logo.svg',
  // '/sterling-logo.png',
  // '/Mukuru-Logo-final.webp',
  // '/logo-cinetpay.webp',
  '/logo-eni.svg',
  '/thrive-agric-logo-light-transparent.svg',
];

export default COMPANY_ICONS;
