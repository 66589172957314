import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Back: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 12 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20L0 10L10 0L11.775 1.775L3.55 10L11.775 18.225L10 20Z"
          fill="black"
        />
      </svg>
  );
};
export default Back;
