import React, { type FC, type ReactNode } from 'react';

import Footer from './Footer';
import Layout from './Layout';
import Navbar from './Navbar';
const PageContainer: FC<{ children: ReactNode, title?: string, image?: string }> = ({ children, title, image }) => {
  return (
    <Layout title={title} image={image}>
      <Navbar />
      <main aria-label="Main Content" className="w-screen overflow-x-hidden">
        {children}
      </main>
      <Footer />
    </Layout>
  );
};

export default PageContainer;
