import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const ScrollDown: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 34 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.0003 12L0.774414 2.99947L6.1856 0L17.0003 6.00106L27.815 0L33.2262 2.99947L17.0003 12Z"
          fill="#0C0114"
        />
      </svg>
  );
};
export default ScrollDown;
