import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Target: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 69 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.3685 42.7406C63.9623 28.8185 58.9371 13.6914 47.1445 8.9532C35.3519 4.21498 21.2574 11.6599 15.6636 25.582C10.0698 39.504 15.095 54.6311 26.8876 59.3693C38.6802 64.1075 52.7747 56.6626 58.3685 42.7406Z"
        fill="#FA9706"
      />
      <g opacity="0.1">
        <path
          opacity="0.1"
          d="M58.3714 42.7396C63.9652 28.8176 58.9401 13.6904 47.1474 8.95222C35.3548 4.21401 21.2603 11.659 15.6666 25.581C10.0728 39.503 15.0979 54.6301 26.8905 59.3684C38.6831 64.1066 52.7776 56.6616 58.3714 42.7396Z"
          fill="#0C0114"
        />
        <path
          opacity="0.1"
          d="M35.9683 7.2041L41.4385 7.60911L32.4653 60.7223L26.9951 60.3173L35.9683 7.2041Z"
          fill="#0C0114"
        />
      </g>
      <path
        d="M53.3333 42.3665C58.9271 28.4445 53.902 13.3174 42.1094 8.57917C30.3167 3.84096 16.2223 11.2859 10.6285 25.2079C5.03467 39.13 10.0598 54.2571 21.8524 58.9953C33.645 63.7335 47.7395 56.2886 53.3333 42.3665Z"
        fill="#FA9706"
      />
      <path
        d="M48.6364 40.4807C52.9998 29.6207 49.0794 17.8205 39.8798 14.1241C30.6802 10.4278 19.6852 16.235 15.3217 27.095C10.9582 37.955 14.8786 49.7553 24.0782 53.4516C33.2778 57.148 44.2729 51.3407 48.6364 40.4807Z"
        fill="white"
      />
      <path
        d="M43.9971 38.6136C47.1432 30.7833 44.3162 22.2749 37.6827 19.6096C31.0491 16.9443 23.1211 21.1313 19.975 28.9616C16.8288 36.7919 19.6558 45.3003 26.2894 47.9656C32.9229 50.6309 40.8509 46.4439 43.9971 38.6136Z"
        fill="#3E0563"
      />
      <path
        d="M39.2972 36.7248C41.213 31.9565 39.4929 26.7759 35.4551 25.1535C31.4173 23.5312 26.5909 26.0814 24.675 30.8497C22.7592 35.618 24.4793 40.7986 28.5171 42.4209C32.5549 44.0433 37.3813 41.4931 39.2972 36.7248Z"
        fill="white"
      />
      <path
        d="M35.5956 33.3538C35.5956 35.5839 33.9651 37.5879 31.9716 37.8298C29.9782 38.0718 28.3792 36.4675 28.3897 34.2427C28.4002 32.0178 30.0255 30.0348 32.0137 29.7613C34.0019 29.4878 35.6061 31.1236 35.5956 33.3538Z"
        fill="#FA9706"
      />
    </svg>
  );
};
export default Target;
