import React, { FC } from 'react';
import type { IconProps, Props, PropTypes } from "../../typescript";



const ArrowRight: FC<PropTypes<Props, IconProps>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 18L21 12L15 6" stroke="#0C0114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 12H20" stroke="#0C0114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 12H20" stroke="#0C0114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    );
}
export default ArrowRight;