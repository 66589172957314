import React, { FC, useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import { useSession } from 'next-auth/react';
import { useAppDispatch } from '../../utils/hooks/useRedux';
import { addVotes } from '../../utils/services/AppSlice';
import { updateSlice } from '../../utils/services/AmountSlice';
import { Paystack } from '../icons';
import { ifCondition } from "../../utils/functions";
// interface PaymentProp  {
//     amount: number;
//     voteCount: number;
//     setSuccessToggle: () => void;
// }

const Payment = ({ amount, voteCount, setSuccessToggle, text, metadata, nominee_id }) => {
  let currency = metadata;
  let factor =
    currency === 'GHS'
      ? 12.36
      : ifCondition(
          currency === 'NGN',
          480,
          ifCondition(currency === 'XOF', 605.77, 1)
        );
  const { data: session } = useSession();
  const dispatch = useAppDispatch();
  const publicKey = process.env.PAYSTACK_SECRET_KEY;
  const componentProps = {
    email: session?.user?.email ? String(session?.user?.email) : '',
    amount: Math.round((amount / factor) * 0.0195 > 5 ? (amount + ((amount / factor) * 5) ) * 100 :  amount * 100 * 1.0195),
    metadata: {
      payment_type: 'customPrice',
      vote_count: voteCount,
      nominee_id,
      custom_fields: [
        {
          display_name: 'The Connected Awards',
          variable_name: 'The Connected Awards',
          value: 300,
        },
      ],
    },
    publicKey:
      String(publicKey),
    //  "pk_test_1666366ba963b5201f5a2841f91c2d18070525d8",
    onSuccess: (ref) => {
      setSuccessToggle();
      payForVotes(ref.reference);
      dispatch(updateSlice({ voteCount, amount, metadata: '' }));
      close();
    },
  };
  return (
    <PaystackButton
      {...componentProps}
      currency={metadata}
      className="group mx-auto flex w-full cursor-pointer rounded-md bg-[#D8CDE0]/10 border-[1px] border-[#D8CDE0] items-center justify-center px-6 py-6 text-center text-sm font-bold  transition-all duration-500 ease-in hover:bg-darkPurple lg:text-base hover:text-[#D8CDE0]"
    >
      <span className="flex  w-fit basis-full self-center lg:basis-2/3 items-center">
        <Paystack className="h-auto w-24" />
        <span className="-ml-12">{text}</span>
      </span>
    </PaystackButton>
  );
};

export default Payment;
