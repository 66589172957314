import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const BottomLeftGroup: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="161"
      height="160"
      viewBox="0 0 161 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.9936 39.2686L83.9869 39.2674L83.9803 39.267L77.6082 38.6704L77.6041 38.6702L77.5994 38.6701L71.238 38.3909L71.2335 38.3904L71.2267 38.5099L71.2243 38.3905L64.8971 38.4416L64.8923 38.4414L64.8871 38.4417L58.6325 38.8427L58.6503 39.2024L71.218 38.7514L96.5848 41.7461L108.897 45.1161L108.996 44.7695L102.891 42.9226L102.886 42.9213L102.882 42.9201L96.6623 41.3936L96.6325 41.5098L96.6537 41.3914L90.3526 40.1764L90.3482 40.1759L90.3441 40.175L83.9936 39.2686Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M81.6124 63.667L81.349 63.327L81.2912 63.7536L80.7821 67.5117L77.2916 63.3023L77.0265 62.9819L76.974 63.3944L76.4457 67.5707L76.4223 67.758L76.61 67.7733L80.8856 68.1228L85.1386 68.7335L89.3485 69.605L89.8008 69.6986L89.5313 69.3236L85.9172 64.2895L85.6563 63.9259L85.592 64.3691L85.0522 68.109L81.6124 63.667Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M120.613 49.8362L120.737 49.4979L109.011 44.7752L109.001 44.7712L108.991 44.7684L96.6669 41.3946L96.5619 41.7396L120.613 49.8362Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M85.9496 64.4203L85.9757 64.2417L85.7967 64.216L81.6733 63.6241L81.6746 63.6134L81.4906 63.5979L81.0549 63.5355L81.0765 63.5635L77.1682 63.2374L76.7407 63.2015L77.0148 63.5321L80.7676 68.058L81.03 68.3745L81.0849 67.967L81.5913 64.2282L85.0275 68.6658L85.2879 69.0022L85.3488 68.5813L85.9496 64.4203ZM90.1745 65.1671L89.9167 64.7767L89.8456 65.239L89.207 69.4007L89.1826 69.5597L89.3377 69.6024L93.471 70.7351L93.9334 70.8617L93.6692 70.4622L90.1745 65.1671Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.6172 41.749L96.6402 41.3898L71.2462 38.3916L71.227 38.3896L71.2076 38.3916L46.481 40.804L46.4967 41.1639L96.6172 41.749Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M72.6924 63.1354L72.4441 63.1296L72.4663 63.1546L68.6751 63.3102L68.4634 63.0833L68.4375 63.3197L68.1995 63.3296L68.412 63.5564L67.9618 67.6885L67.9387 67.8979L68.1491 67.8882L72.2844 67.6957L72.4926 67.9187L72.5188 67.6952L76.5422 67.7718L76.7538 68.0112L76.7834 67.7761L77.0313 67.7809L76.8139 67.5348L77.3103 63.6106L81.4496 63.9563L85.6693 64.5619L89.2384 69.5333L89.4961 69.892L89.5629 69.4553L90.1308 65.7556L93.3292 70.6015L93.3161 70.6816L93.4014 70.7114L93.6223 71.0457L93.6619 70.802L97.478 72.1283L97.9476 72.2918L97.6914 71.865L94.3841 66.356L94.3998 66.2607L94.3129 66.2372L94.0967 65.8769L94.0497 66.1658L90.2263 65.1343L90.228 65.1239L90.1292 65.1035L86.5293 60.0563L86.2682 59.6905L86.204 60.1351L85.6535 63.9455L82.1871 59.4293L81.9234 59.0856L81.8655 59.5149L81.3471 63.3425L77.83 59.0535L77.564 58.7295L77.5113 59.1452L76.9946 63.233L73.0545 63.1436L73.0558 63.1306L72.9305 63.1355L72.7205 62.8975L72.6924 63.1354Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M120.603 49.8321L120.743 49.5001L114.973 46.9597L114.968 46.9577L114.962 46.9555L109.006 44.7731L108.876 45.1098L120.603 49.8321Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M86.5608 60.187L86.5869 60.0084L86.408 59.9827L82.0697 59.3606L81.6314 59.2979L81.9009 59.649L85.6282 64.5043L85.8884 64.8434L85.9492 64.4203L86.5608 60.187Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M71.2432 38.7502L71.2194 38.3906L58.6378 38.8424L58.6264 38.8425L58.6155 38.8445L46.4701 40.8056L46.5166 41.1633L71.2432 38.7502Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M82.1667 59.9764L82.1983 59.7431L86.2801 60.3288L89.8343 65.3112L89.8212 65.3983L89.9144 65.4234L90.1344 65.7323L90.1716 65.4928L94.0785 66.5476L97.3482 71.9937L97.3352 72.0675L97.4126 72.1006L97.6324 72.4677L97.6759 72.2143L101.276 73.7662L101.491 74.1648L101.542 73.8809L101.809 73.9962L101.593 73.5954L102.338 69.456L102.364 69.3141L102.231 69.2579L98.4551 67.6654L98.4568 67.6551L98.3786 67.6282L98.1639 67.23L98.1579 67.266L95.2672 62.4385L95.2933 62.4454L95.0738 62.1103L95.089 62.0179L95.0018 61.9948L94.7858 61.6342L94.781 61.6627L91.4854 56.6238L91.2282 56.2298L91.1565 56.6951L90.5665 60.5405L90.5628 60.5638L87.1931 55.8082L87.2086 55.7007L87.1067 55.6861L86.8909 55.381L86.8518 55.6496L82.8307 55.0735L82.8321 55.0635L82.7235 55.054L82.5079 54.7709L82.4739 55.0224L82.2183 54.986L82.2379 55.0116L78.3289 54.6715L78.1115 54.4029L78.0802 54.6499L77.8297 54.628L78.049 54.8983L77.5332 58.9735L77.2801 58.9521L77.3008 58.9772L73.5557 58.8789L73.5573 58.8663L73.4332 58.8707L73.2222 58.6283L73.1943 58.8692L72.9485 58.8629L72.9695 58.8869L69.1439 59.0224L68.9312 58.7911L68.9051 59.0308L68.6693 59.0391L68.8792 59.2672L68.4365 63.3301L64.4981 63.7635L64.2901 63.5526L64.2664 63.7889L64.0408 63.8137L64.2431 64.0186L63.825 68.1763L63.8029 68.3993L64.0258 68.3736L68.0744 67.8978L68.2789 68.1048L68.3046 67.8703L68.5285 67.8441L68.3294 67.6427L68.7624 63.6679L72.7761 63.5032L72.9853 63.7306L73.012 63.5034L77.0692 63.5953L77.2814 63.8388L77.3113 63.6011L77.557 63.6067L77.3421 63.3593L77.8169 59.6066L81.3305 63.8917L81.5931 64.2119L81.6486 63.8015L82.1667 59.9764Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.718 41.7194L97.2152 41.3951L96.6216 41.388L46.5008 40.8034L46.4734 41.162L88.257 47.1859L88.3241 47.1955L88.3808 47.1586L96.718 41.7194Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M74.0017 54.5954L73.7337 54.2836L73.6858 54.6918L73.175 59.0328L73.1519 59.229L73.3494 59.2343L77.6859 59.3483L78.092 59.3591L77.8274 59.0504L74.0017 54.5954ZM91.5129 56.7499L91.539 56.5797L91.3703 56.5458L87.0402 55.6768L86.5951 55.5876L86.8578 55.9578L90.5265 61.1357L90.7842 61.4999L90.8519 61.0591L91.5129 56.7499ZM99.1598 63.5511L99.186 63.3986L99.0394 63.3497L94.9423 61.9793L94.4797 61.8251L94.73 62.243L98.0988 67.869L98.3487 68.2866L98.4311 67.8065L99.1598 63.5511Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.7727 41.6639L96.521 41.4176L88.1838 46.8567L87.8123 47.0993L88.2477 47.1845L92.6582 48.0501L92.7804 48.0741L92.8461 47.9687L96.7727 41.6639ZM58.6724 39.2007L58.6211 38.8436L52.4745 39.6195L52.4686 39.6205L52.4621 39.6216L46.4641 40.8067L46.5277 41.1617L58.6724 39.2007Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M78.4163 54.8669L78.4412 54.6703L78.2429 54.6642L73.8698 54.5325L73.4615 54.5203L73.7276 54.8304L77.5537 59.2858L77.8182 59.5937L77.8693 59.1905L78.4163 54.8669ZM102.527 69.7742L102.562 69.789L102.322 69.3427L102.083 68.84L102.075 68.8841L99.1409 63.435L98.893 62.9743L98.8042 63.49L98.0759 67.7457L98.0517 67.8867L98.1832 67.9423L101.958 69.5345L101.227 73.593L101.204 73.7229L101.32 73.7844L104.999 75.7322L105.48 75.9867L105.247 75.4955L102.527 69.7742ZM64.5959 64.1152L68.6199 63.6732L68.9856 63.6326L68.7298 63.3684L64.9947 59.5093L64.7245 59.23L64.6856 59.6168L64.2651 63.7997L60.3484 64.5331L60.004 64.5974L60.2566 64.8403L63.8797 68.3243L64.1469 68.5813L64.1842 68.2126L64.5959 64.1152Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.7993 41.559L96.7681 40.989L96.4666 41.4737L92.5399 47.7777L92.4094 47.9878L92.6489 48.0481L96.9819 49.1419L97.2196 49.202L97.2061 48.9571L96.7993 41.559Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.5068 64.58L60.2369 64.3205L60.2024 64.6939L59.8073 68.9694L59.7856 69.2097L60.0219 69.1631L64.0395 68.3711L64.3794 68.3042L64.1302 68.0643L60.5068 64.58ZM95.7031 57.6779L95.4872 57.3165L95.4397 57.6091L91.4445 56.5647L87.7855 51.3661L87.5242 50.9951L87.4599 51.4438L86.8883 55.3995L83.3667 50.7291L83.1027 50.3795L83.0439 50.8136L82.5059 54.7858L78.9346 50.3315L78.6689 49.9993L78.6154 50.4214L78.0589 54.822L78.0353 55.0079L78.2219 55.0244L82.6076 55.4058L86.9744 56.0313L91.2254 56.8846L94.6956 62.1905L94.6825 62.2728L94.7682 62.3015L94.9885 62.638L95.0289 62.3888L98.9252 63.6918L99.3866 63.8457L99.1375 63.4281L95.7747 57.7977L95.7903 57.7002L95.7031 57.6779ZM68.7796 63.5132L69.23 59.3801L73.2768 59.237L73.4869 59.4695L73.5149 59.2286L73.7478 59.2204L73.543 58.9936L74.0438 54.734L74.0685 54.5267L73.8594 54.5326L69.6212 54.6583L69.4075 54.4219L69.3808 54.6654L69.1475 54.6724L69.3549 54.9017L68.904 59.0408L64.847 59.4556L64.4771 59.4931L64.736 59.7601L68.4703 63.6193L68.7378 63.8954L68.7796 63.5132Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.779 41.4842L96.4394 41.5788L96.8463 48.9769L96.853 49.1017L96.9721 49.1391L101.194 50.4644L101.614 50.5961L101.407 50.2077L96.779 41.4842Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M87.6678 51.2918L87.2309 51.2052L87.2499 51.2323L83.4249 50.6847L83.4262 50.6747L83.2429 50.6584L82.815 50.5975L82.8338 50.6223L78.9962 50.2811L78.9972 50.2707L78.8048 50.2644L78.3883 50.2272L78.4074 50.251L74.4704 50.1172L74.2532 49.8615L74.2245 50.1087L73.9835 50.1011L74.1956 50.3515L73.6855 54.6918L73.6624 54.8872L73.8592 54.8933L78.1511 55.0222L78.3644 55.2737L78.3955 55.0294L78.6365 55.037L78.426 54.7887L78.9192 50.889L82.487 55.3394L82.7503 55.6673L82.8063 55.2509L83.3421 51.2966L86.8602 55.9624L87.121 56.3077L87.1828 55.8797L87.7512 51.9444L91.145 56.767L91.1316 56.856L91.2251 56.8806L91.4446 57.1925L91.4822 56.9478L95.4688 57.9893L98.7928 63.5557L98.7799 63.6325L98.8534 63.6628L101.972 69.4543L101.959 69.5233L102.024 69.5567L104.921 75.6501L105.158 76.15L105.26 75.6063L106.012 71.6178L106.273 71.7522L106.068 71.3218L106.082 71.2479L106.017 71.2139L103.294 65.4937L103.328 65.5079L103.119 65.1186L103.134 65.0373L103.063 65.0083L102.852 64.565L102.844 64.6084L99.9122 59.1588L99.9277 59.0668L99.8484 59.0404L99.6344 58.6428L99.5815 58.953L95.7018 57.682L92.1961 52.2961L92.2119 52.1938L92.1171 52.1751L91.9007 51.8428L91.8578 52.1234L87.8406 51.3259L87.842 51.3167L87.6678 51.2918Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.757 41.4525L96.4601 41.6532L101.089 50.3764L101.121 50.4363L101.184 50.4605L105.259 52.0222L105.461 51.7373L96.757 41.4525Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.03 59.799L68.9977 59.3937L69.2045 59.612L69.2309 59.3697L69.4507 59.3475L69.2554 59.1418L69.7051 55.0164L73.7857 54.8957L73.9968 55.1325L74.0253 54.8887L74.2564 54.8817L74.0531 54.6533L74.5444 50.4806L78.7829 50.6243L83.1295 51.0108L83.3447 51.2817L83.3771 51.0421L87.5382 51.6373L87.7544 51.9266L87.7892 51.6835L91.8981 52.4991L95.3967 57.8735L95.3833 57.9569L95.4693 57.9852L95.6889 58.3225L95.7299 58.0703L99.5995 59.3385L102.77 65.2302L105.692 71.3706L104.906 75.5393L104.884 75.6581L104.986 75.7243L108.328 77.895L108.803 78.2035L108.593 77.677L106.067 71.3281L106.868 67.0774L106.894 66.9437L106.772 66.8829L103.132 65.048L103.891 60.828L103.917 60.684L103.781 60.629L99.9263 59.0764L100.657 54.811L100.684 54.6566L100.535 54.6089L96.5025 53.3153L96.5039 53.306L96.4163 53.2834L96.2011 52.9219L96.1966 52.9494L92.8814 47.8323L92.8972 47.7292L92.8028 47.7109L92.5863 47.3771L92.5431 47.6596L88.4855 46.8633L88.4869 46.8548L88.3857 46.8401L88.1689 46.5306L88.1311 46.7941L87.8785 46.7443L87.8966 46.7699L84.0305 46.2173L84.0315 46.2077L83.924 46.1985L83.7082 45.9096L83.6734 46.1666L83.4232 46.1303L83.4409 46.1541L79.4536 45.7928L79.2355 45.5173L79.2034 45.7698L78.958 45.7476L79.1718 46.0178L78.6668 50.0108L75.0522 45.6816L74.7833 45.3598L74.7343 45.7762L74.2235 50.118L70.1061 50.2158L69.8918 49.9747L69.865 50.2214L69.6338 50.2269L69.8394 50.4582L69.3797 54.6749L65.3772 55.0522L65.1669 54.8307L65.142 55.0744L64.9211 55.0956L65.1191 55.3044L64.6994 59.481L60.844 60.1641L60.639 59.9622L60.6166 60.2039L60.4086 60.2411L60.5959 60.4261L60.2137 64.5693L56.5391 65.5874L56.3424 65.4073L56.3219 65.6478L56.129 65.7008L56.3037 65.861L55.9464 70.0888L55.9243 70.3496L56.1766 70.2769L59.9398 69.1876L60.1333 69.3642L60.1554 69.1251L60.346 69.07L60.1748 68.9136L60.5489 64.8621L64.3719 64.1468L64.5731 64.3451L64.5973 64.1044L64.8037 64.0658L64.6192 63.884L65.03 59.799Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.8069 46.5499L79.3447 46.1446L79.3392 46.1445L79.334 46.1433L46.4733 41.162L46.4722 41.1617L46.5237 40.8122L83.8069 46.5499ZM83.8069 46.5499L46.5237 40.8122L83.8069 46.5499ZM83.8069 46.5499L88.257 47.1858L88.2574 47.1862L88.3088 46.829L88.3081 46.829L46.53 40.806L46.5263 40.8055L83.8069 46.5499ZM46.5237 40.8122L46.5252 40.8052L46.5237 40.8122ZM96.6773 41.3979L96.607 41.568L96.4823 41.685L105.186 51.9699L105.212 52.0003L105.248 52.0173L109.137 53.8245L109.338 53.5308L97.585 42.2456L138.324 62.3299L138.508 62.0205L120.774 49.5176L120.753 49.5024L120.728 49.494L96.6773 41.3979Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M79.5388 45.9877L79.5641 45.7922L79.3668 45.7851L74.92 45.6171L74.5152 45.6018L74.775 45.9125L78.6551 50.5596L78.9205 50.8774L78.9727 50.4668L79.5388 45.9877ZM109.439 73.5201L109.462 73.399L109.358 73.3333L105.977 71.194L105.508 70.8969L105.712 71.4126L108.258 77.8102L108.484 78.3791L108.603 77.7784L109.439 73.5201ZM104.075 61.1364L104.108 61.1495L103.874 60.7147L103.635 60.2127L103.628 60.2557L100.638 54.6952L100.39 54.2337L100.301 54.7498L99.5461 59.1588L99.5216 59.3024L99.6563 59.3567L103.51 60.9083L102.753 65.1164L102.729 65.2489L102.849 65.3095L106.609 67.2048L107.08 67.4418L106.854 66.9661L104.075 61.1364ZM65.4714 55.4057L69.5596 55.0199L69.9238 54.9857L69.6748 54.7178L65.8882 50.6433L65.6163 50.3512L65.5767 50.7479L65.1407 55.0847L61.2549 55.7331L61.0485 55.5251L61.0256 55.7713L60.8193 55.8057L61.0053 55.9938L60.6041 60.341L60.5822 60.5761L60.8146 60.5352L64.8033 59.8285L65.0062 60.0334L65.0306 59.7886L65.235 59.7524L65.0525 59.5683L65.4714 55.4057Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5264 40.8056L46.469 41.1616L46.472 41.1622L74.8838 45.9752L74.8953 45.9773L74.9071 45.9774L79.354 46.1454L79.3877 45.7868L46.5293 40.8062L46.5264 40.8056Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M97.1428 48.8102L96.9275 48.4481L96.8798 48.744L92.7371 47.6982L92.2919 47.5858L92.5418 47.971L96.1103 53.4793L96.0967 53.5639L96.1833 53.5914L96.4026 53.9299L96.4442 53.6754L100.424 54.952L100.877 55.0975L100.634 54.6884L97.2148 48.9312L97.2307 48.8325L97.1428 48.8102ZM70.2066 50.4175L70.2301 50.1992L70.0117 50.2185L65.7412 50.5868L65.3732 50.6183L65.6246 50.8889L69.4111 54.9634L69.6796 55.2524L69.7223 54.8599L70.2066 50.4175ZM106.859 66.977L106.629 66.401L106.514 67.0103L105.703 71.3127L105.681 71.4334L105.784 71.4987L109.166 73.6377L109.636 73.9351L109.429 73.4187L106.859 66.977Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M130.712 55.6926L130.899 55.3844L126.134 52.3465L126.128 52.3431L126.121 52.3394L120.754 49.5052L120.579 49.8208L130.712 55.6926Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.9633 60.3744L60.9867 60.1175L60.7378 60.1835L56.9134 61.194L56.7142 61.0066L56.6935 61.252L56.5023 61.3023L56.6753 61.4652L56.3116 65.7727L56.2892 66.0306L56.5388 65.9617L60.3322 64.9111L60.5272 65.0953L60.5498 64.851L60.7383 64.7983L60.5695 64.6392L60.9633 60.3744ZM70.3578 45.6976L70.1288 45.7015L70.3323 45.9343L69.8477 50.3786L69.8255 50.5832L70.0313 50.5786L74.3045 50.477L74.5159 50.719L74.5449 50.4714L74.7736 50.4657L74.5728 50.236L75.0928 45.8184L75.1169 45.6133L74.9106 45.6169L70.6005 45.6934L70.3853 45.4472L70.3578 45.6976Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5291 40.8062L46.4628 41.1604L70.4841 46.0519L70.5034 46.0561L70.5229 46.0556L74.9168 45.9773L74.9436 45.6194L46.5291 40.8062Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M109.435 73.4325L109.086 73.451L108.249 77.709L108.228 77.8156L108.313 77.8844L111.191 80.2L111.652 80.5708L111.476 80.0062L109.435 73.4325ZM104.689 56.3973L104.715 56.2518L104.578 56.1976L100.545 54.6129L100.088 54.4331L100.321 54.8662L103.553 60.8777L106.529 67.1213L106.766 67.6204L106.869 67.0775L107.694 62.6972L107.72 62.5624L107.596 62.5017L103.916 60.6945L104.689 56.3973ZM56.6707 65.8032L56.6953 65.5165L56.4264 65.6198L52.8513 66.9953L52.5799 67.0999L52.7946 67.2965L56.0049 70.2367L56.2754 70.4847L56.3062 70.1193L56.6707 65.8032Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.7379 41.4331L96.4941 41.699L109.088 53.7908L109.105 53.807L109.125 53.8182L112.674 55.8174L112.881 55.5245L96.7379 41.4331Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M101.372 50.142L101.159 49.7451L101.105 50.0583L97.08 48.7949L96.6298 48.6541L96.8707 49.0592L100.29 54.8157L100.277 54.8947L100.356 54.9259L100.574 55.2938L100.619 55.0297L104.307 56.4794L103.536 60.7644L103.512 60.8984L103.634 60.9581L107.437 62.8257L107.903 63.0545L107.679 62.586L104.871 56.7006L104.903 56.7135L104.672 56.284L104.433 55.7827L104.425 55.825L101.436 50.2615L101.452 50.1672L101.372 50.142ZM53.0371 67.0306L52.7643 66.7804L52.7359 67.1494L52.3982 71.5102L52.3759 71.7991L52.6453 71.6913L56.1929 70.2711L56.4591 70.1645L56.2478 69.9711L53.0371 67.0306ZM110.29 69.1854L110.314 69.0629L110.208 68.9973L106.786 66.8904L106.322 66.6049L106.524 67.1108L109.094 73.5529L109.321 74.1206L109.439 73.5206L110.29 69.1854Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5344 40.8069L46.4521 41.1579L66.1679 46.3851L66.1977 46.3932L66.2281 46.3906L70.5336 46.0549L70.5557 45.6984L46.5344 40.8069Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M65.9209 50.933L69.9535 50.5853L70.1632 50.8152L70.1903 50.5651L70.4062 50.5464L70.2151 50.3369L70.699 45.8948L70.7226 45.6788L70.5059 45.6954L66.288 46.0243L66.0752 45.7908L66.0497 46.0431L65.8327 46.06L66.0268 46.2731L65.5908 50.6098L61.6739 51.2221L61.4657 51.0068L61.4427 51.2585L61.2381 51.2903L61.422 51.4807L61.0249 55.7832L57.2938 56.7205L57.0927 56.5251L57.0716 56.7763L56.8825 56.8236L57.0534 56.9895L56.6921 61.2642L53.1967 62.5535L52.9248 62.654L53.1352 62.8538L56.367 65.9188L56.6387 66.1771L56.6702 65.8032L57.0311 61.5358L60.7308 60.5581L60.9284 60.7496L60.9515 60.5001L61.1379 60.4504L60.9712 60.2883L61.3592 56.0815L65.2429 55.434L65.4472 55.6451L65.4725 55.3954L65.6748 55.3615L65.4944 55.1751L65.9209 50.933ZM107.684 62.5964L107.454 62.0218L107.339 62.6301L106.514 67.0104L106.491 67.1322L106.597 67.1974L109.912 69.2387L109.141 73.1627L108.919 72.9882L109.076 73.4955L109.064 73.5598L109.106 73.5931L111.131 80.113L111.48 80.0957L112.296 76.0879L112.522 76.2644L112.363 75.7568L112.376 75.6928L112.332 75.6589L110.429 69.5564L110.483 69.5906L110.28 69.0836L107.684 62.5964ZM105.501 51.8854L105.527 51.7388L105.388 51.6851L101.312 50.1238L100.859 49.95L101.089 50.3773L104.353 56.4507L104.597 56.9061L104.689 56.3972L105.501 51.8854Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.545 40.8095L46.4355 41.1525L61.9703 46.9888L62.0137 47.0051L62.0598 46.9985L66.24 46.3895L66.2604 46.0371L46.545 40.8095Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M108.535 58.2367L108.56 58.1007L108.435 58.0408L104.589 56.2029L104.129 55.9827L104.349 56.4429L107.354 62.7413L107.592 63.2397L107.694 62.6968L108.535 58.2367Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M96.7355 41.4309L96.5006 41.7049L96.5032 41.7066L112.644 55.796L112.651 55.8027L112.66 55.8085L115.734 57.929L115.953 57.6426L96.7379 41.4331L96.7355 41.4309Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.384 62.592L53.1092 62.3317L53.0796 62.7088L52.7362 67.1493L52.7141 67.4341L52.9808 67.3316L56.556 65.9561L56.8227 65.8536L56.615 65.657L53.384 62.592ZM111.158 64.771L111.182 64.6462L111.073 64.5811L107.609 62.5086L107.151 62.2346L107.349 62.7304L109.946 69.2176L110.173 69.7842L110.291 69.1853L111.158 64.771ZM105.486 51.7756L105.245 51.2712L105.146 51.8217L104.334 56.3334L104.31 56.4689L104.434 56.5278L108.28 58.3661L108.74 58.5859L108.52 58.1257L105.486 51.7756Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5558 41.155L46.4499 40.81L40.8272 42.3939L40.8188 42.3961L40.8109 42.3994L35.7773 44.3397L35.8987 44.6791L46.5558 41.155Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M112.362 75.7641L113.244 71.4297L113.266 71.3186L113.176 71.25L110.222 69.0068L109.773 68.666L109.941 69.2041L111.991 75.7759L111.127 80.0234L111.108 80.1174L111.175 80.1855L113.575 82.6289L114.019 83.0817L113.879 82.4628L112.362 75.7641ZM66.3935 46.229L66.4168 45.9993L66.1884 46.0327L62.1006 46.6282L61.8909 46.4059L61.8674 46.6621L61.6646 46.6912L61.8467 46.8842L61.4418 51.2695L57.6821 52.164L57.4789 51.961L57.4569 52.2178L57.2699 52.262L57.4387 52.431L57.0607 56.9041L57.0396 57.1561L57.2844 57.0947L61.1377 56.1266L61.337 56.3254L61.3606 56.07L61.5453 56.0237L61.3803 55.8589L61.7759 51.5712L65.6903 50.9593L65.8961 51.1773L65.9219 50.9226L66.1225 50.8913L65.9442 50.7025L66.3935 46.229ZM53.0954 67.1775L53.1204 66.8531L52.8325 67.0038L49.8283 68.5696L49.643 68.3878L49.6225 68.6769L49.4919 68.7449L49.6096 68.8604L49.3015 73.1855L49.2784 73.5131L49.5673 73.3569L52.5472 71.7458L52.7282 71.9238L52.7506 71.6357L52.879 71.5663L52.7648 71.454L53.0954 67.1775ZM108.524 58.1362L108.294 57.5631L108.18 58.1698L107.339 62.63L107.316 62.7536L107.424 62.8181L110.888 64.8905L111.347 65.1656L111.148 64.6688L108.524 58.1362Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.5627 40.8151L46.4082 41.1396L57.9271 47.8771L57.9885 47.9132L58.0577 47.8974L62.0735 46.9962L62.6629 46.864L62.0975 46.6514L46.5627 40.8151Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M111.152 64.6822L110.803 64.7014L109.936 69.1157L109.915 69.226L110.004 69.2939L112.958 71.5372L113.408 71.8789L113.239 71.3397L111.152 64.6822Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.369 62.3453L138.483 62.0064L96.6993 41.407L96.5034 41.7067L115.721 57.9184L115.756 57.9487L115.803 57.9575L138.369 62.3453Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M57.42 56.9349L57.4436 56.657L57.1804 56.7494L53.5493 58.0304L53.2767 58.1262L53.4829 58.3291L56.7362 61.5226L57.0096 61.7915L57.0417 61.4091L57.42 56.9349ZM109.39 53.6943L109.417 53.5565L109.289 53.4975L105.399 51.6904L104.944 51.4791L105.16 51.9314L108.195 58.2811L108.432 58.7787L108.534 58.2365L109.39 53.6943Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.3 62.3156L138.522 62.0324L130.921 55.4008L130.908 55.3893L130.893 55.3808L120.761 49.5091L120.566 49.8124L138.3 62.3156Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.7355 58.0713L53.4591 57.8004L53.4292 58.1864L53.0874 62.6073L50.1513 64.0797L49.9633 63.887L49.9421 64.1846L49.8131 64.2493L49.9292 64.3681L49.6151 68.7771L49.5922 69.0991L49.8788 68.95L52.8804 67.3854L53.0646 67.5739L53.0876 67.2773L53.2144 67.2113L53.1018 67.0956L53.4303 62.8523L56.9246 61.5634L57.1915 61.4646L56.9884 61.2652L53.7355 58.0713ZM114.72 78.5304L114.924 78.7323L114.796 78.168L114.808 78.1104L114.775 78.0785L113.251 71.3915L114.149 66.9761L114.172 66.8635L114.08 66.795L111.179 64.6589L111.991 60.5229L112.227 60.6596L112.05 60.2223L112.065 60.1488L112.007 60.1151L109.38 53.5933L109.15 53.0217L109.036 53.6274L108.227 57.9193L107.994 57.7843L108.17 58.2216L108.156 58.2955L108.213 58.3281L110.81 64.7969L112.881 71.4033L112.067 75.404L111.865 75.2046L111.993 75.7682L111.981 75.8258L112.013 75.8574L113.527 82.5426L113.879 82.5394L114.72 78.5304ZM62.2131 46.8367L62.236 46.5898L61.9942 46.644L58.0771 47.5232L57.8712 47.3122L57.8492 47.5745L57.6638 47.6161L57.8314 47.7881L57.4458 52.3476L57.4248 52.5963L57.6672 52.5384L61.5516 51.614L61.7528 51.8208L61.7769 51.5602L61.9599 51.5166L61.7962 51.3488L62.2131 46.8367Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M54.2011 49.0255L54.2788 49.1039L54.3839 49.0702L58.0731 47.8931L58.4584 47.7699L58.1094 47.5657L46.59 40.8279L46.3705 41.1103L54.2011 49.0255Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M109.046 53.7283L111.676 60.2577L110.803 64.7013L110.782 64.8135L110.873 64.8813L113.774 67.017L112.956 71.0375L112.758 70.8485L112.883 71.3961L112.87 71.4552L112.903 71.4867L114.427 78.1711L113.526 82.4655L113.51 82.5455L113.558 82.6105L115.459 85.1597L115.782 85.0262L114.795 78.1757L115.647 74.1106L115.847 74.3019L115.721 73.7541L115.734 73.6946L115.701 73.6627L114.258 67.3734L114.311 67.4128L114.159 66.9306L115.073 62.4394L115.096 62.3242L115.001 62.2566L112.063 60.1609L112.939 55.6946L112.965 55.5668L112.851 55.5026L109.302 53.5038L108.854 53.2517L109.046 53.7283ZM57.8054 52.3778L57.8285 52.1044L57.5684 52.1919L54.0155 53.386L53.8168 53.1842L53.7953 53.4598L53.6352 53.5138L53.7799 53.6607L53.4378 58.0828L50.4812 59.505L50.2897 59.3014L50.2682 59.6079L50.1405 59.6695L50.2549 59.791L49.9351 64.2867L49.9126 64.6028L50.1955 64.4609L53.2203 62.9442L53.4076 63.1439L53.4313 62.8381L53.5564 62.7754L53.4452 62.6575L53.78 58.3314L57.1927 57.1278L57.3872 57.3257L57.4105 57.0512L57.568 56.9955L57.4272 56.852L57.8054 52.3778ZM49.9754 68.8028L50.0022 68.424L49.6917 68.6426L47.1747 70.4145L46.9965 70.2202L46.9746 70.5551L46.877 70.6237L46.9639 70.7182L46.6748 75.0999L46.6493 75.4842L46.9608 75.2574L49.4576 73.439L49.6319 73.629L49.6554 73.2949L49.751 73.2252L49.6668 73.1339L49.9754 68.8028Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.9487 50.3924L51.0249 50.5474L51.1827 50.4781L54.4017 49.0639L54.6417 48.9583L54.4573 48.7717L46.6271 40.8568L46.337 41.0638L50.9487 50.3924Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M116.638 81.0941L116.821 81.3313L116.727 80.6822L116.737 80.632L116.715 80.6033L115.715 73.7298L115.36 73.7187L114.515 77.7496L114.335 77.5157L114.428 78.1638L114.417 78.2139L114.439 78.2423L115.425 85.0775L115.779 85.09L116.638 81.0941ZM112.934 55.6058L112.586 55.6254L111.686 60.2054L111.663 60.3191L111.758 60.3867L114.791 62.5496L115.23 62.8632L115.068 62.3488L112.934 55.6058ZM58.1971 47.7363L58.2204 47.4673L57.963 47.5495L54.2739 48.7266L53.9995 48.8144L54.1982 49.0231L57.4949 52.4873L57.7712 52.7777L57.8051 52.3779L58.1971 47.7363Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.573 41.1482L46.4426 40.8125L35.7852 44.3371L35.766 44.3435L35.7484 44.3535L27.6062 49.28L27.7737 49.5988L46.573 41.1482Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M50.2946 64.3126L50.3211 63.9418L50.0134 64.1504L47.347 65.9614L47.1784 66.0758L47.3123 66.2291L49.6597 68.909L49.9442 69.2344L49.9752 68.8031L50.2946 64.3126ZM54.4596 48.7746L54.1807 48.481L54.1495 48.8847L53.7861 53.5789L53.7648 53.8504L54.0235 53.7638L57.6832 52.534L57.9512 52.444L57.7563 52.2387L54.4596 48.7746Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.285 62.3038L138.53 62.0393L135.065 58.6366L135.058 58.6292L135.049 58.6229L130.914 55.3946L130.684 55.6727L138.285 62.3038Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M116.591 69.6075L116.787 69.7888L116.664 69.257L116.677 69.1956L116.643 69.1638L115.08 62.4006L116.014 57.8161L116.037 57.6994L115.939 57.6316L112.865 55.5115L112.432 55.213L112.591 55.7141L114.71 62.4131L113.86 66.59L113.667 66.4112L113.789 66.9423L113.776 67.0036L113.81 67.0347L115.359 73.7889L116.361 80.6696L115.427 85.0139L115.414 85.0775L115.444 85.1353L116.82 87.768L117.16 87.673L116.725 80.6895L117.678 76.2549L117.696 76.1712L117.643 76.1042L115.731 73.7098L116.591 69.6075ZM47.5836 65.9914L47.2968 65.6639L47.2685 66.0984L46.973 70.572L44.8759 72.6068L44.7599 72.7198L44.8594 72.8476L46.7128 75.2223L47.0033 75.5953L47.0345 75.1235L47.3231 70.7511L49.8992 68.9374L50.063 68.8221L49.9311 68.6713L47.5836 65.9914ZM54.1455 53.6068L54.1696 53.3006L53.8905 53.429L50.817 54.8439L50.6224 54.6291L50.5998 54.9444L50.4734 55.0024L50.5868 55.1272L50.2606 59.7119L50.2385 60.0221L50.5184 59.8873L53.5663 58.421L53.7567 58.632L53.7811 58.3177L53.9049 58.2581L53.7953 58.1367L54.1455 53.6068Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.2047 51.9822L48.2491 52.2417L48.4752 52.1058L51.2035 50.4671L51.3459 50.3815L51.2722 50.2331L46.6606 40.9037L46.3214 41.0141L48.2047 51.9822Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.2431 72.7534L47.1263 70.926L47.2962 71.1549L47.324 70.734L47.3873 70.6729L47.333 70.5989L47.6222 66.2103L50.0809 64.5406L50.263 64.7575L50.2883 64.3996L50.381 64.3371L50.2995 64.2398L50.5875 60.1971L50.6201 59.7378L50.6459 59.3755L50.9441 55.1826L53.9186 53.8133L54.1125 54.0359L54.1376 53.7123L54.2596 53.6561L54.1514 53.5317L54.5088 48.9128L54.5317 48.6125L54.2566 48.7334L51.1592 50.0943L50.961 49.8671L50.9379 50.1917L50.8131 50.2462L50.9249 50.3744L50.599 54.9595L47.9687 56.6094L47.7972 56.717L47.9239 56.875L50.1755 59.6828L47.7889 61.2424L47.6028 61.0205L47.5795 61.3793L47.4849 61.4408L47.5688 61.541L47.2728 66.0275L45.3096 67.8694L45.1358 67.6348L45.1099 68.0566L45.0447 68.1175L45.1013 68.1936L44.8508 72.2506L44.8215 72.7257L44.7925 73.1903L44.5432 77.2359L44.5136 77.7121L44.8505 77.3747L46.9822 75.2393L47.0946 75.1264L46.9966 75.0008L45.2431 72.7534ZM116.295 69.2608L116.285 69.3069L115.36 73.7189L115.343 73.8021L115.395 73.8684L117.308 76.2629L116.367 80.638L116.354 80.7034L116.366 80.7271L116.799 87.6956L117.155 87.7228L118.124 83.3007L118.139 83.2353L118.125 83.2113L117.718 76.7769L117.777 76.851L117.685 76.2232L118.576 72.0741L118.751 72.2852L118.661 71.6785L118.673 71.625L118.649 71.5963L117.692 65.1429L117.745 65.1905L117.624 64.6744L117.638 64.6115L118.595 60.0428L118.618 59.9387L118.537 59.8693L115.954 57.6437L115.536 57.2831L115.652 57.7828L114.782 62.0593L114.593 61.8902L114.711 62.4056L114.699 62.4684L114.733 62.4996L116.295 69.2608Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M35.9351 44.6622L35.759 44.3482L31.3501 46.6393L31.3393 46.645L31.3294 46.6521L27.5959 49.2867L27.7932 49.5883L35.9351 44.6622Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.2051 56.6496L47.9147 56.2874L47.8845 56.7503L47.5735 61.4689L47.5494 61.8293L47.8517 61.632L50.5388 59.8763L50.7051 59.7675L50.5807 59.6122L48.2051 56.6496ZM118.128 83.2659L117.772 83.2236L116.803 87.6457L116.793 87.6918L116.807 87.7372L117.628 90.4246L117.981 90.3758L118.128 83.2659Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.384 62.3473L138.438 61.9911L115.871 57.6033L115.205 57.4735L115.719 57.9167L118.302 60.142L118.344 60.1786L118.4 60.1851L138.384 62.3473Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.1814 72.7479L45.2208 72.1149L45.4608 68.2217L47.4205 66.3836L47.5945 66.6311L47.6237 66.1933L47.6861 66.1347L47.6324 66.0586L47.9335 61.4931L47.9627 61.0482L47.6325 61.3473L45.6046 63.1824L45.4266 62.9292L45.3993 63.3681L45.3354 63.4259L45.391 63.5046L45.1087 68.0758L43.5119 70.2627L43.4432 70.3569L43.501 70.4579L44.7881 72.7261L43.3969 74.6931L43.2386 74.4298L43.2076 74.9601L43.1697 75.0142L43.2013 75.0675L42.9369 79.5757L42.8992 80.2222L43.2657 79.6884L44.7128 77.5804L44.8674 77.8369L44.9 77.308L44.9375 77.2536L44.9067 77.2024L45.144 73.3532L45.1814 72.7479ZM118.974 85.8749L119.115 78.776L119.126 78.7262L119.113 78.7021L118.66 71.6863L119.573 67.4332L119.745 67.6322L119.657 67.0465L119.668 66.9911L119.644 66.9625L118.598 59.9793L118.243 59.9686L117.339 64.2817L117.17 64.0858L117.257 64.6713L117.245 64.7266L117.269 64.7542L118.295 71.6669L117.325 76.1792L117.311 76.2462L117.325 76.2698L117.767 83.2482L117.761 83.2709L117.767 83.2883L117.62 90.3684L117.976 90.411L118.969 85.9406L118.98 85.8927L118.974 85.8749ZM51.2903 50.3258L51.3146 49.9798L51.0173 50.1584L48.427 51.7141L48.2333 51.462L48.2081 51.8452L48.1158 51.9011L48.1974 52.0077L47.8849 56.7503L47.8616 57.1022L48.1603 56.9149L50.7288 55.3036L50.9183 55.5496L50.9457 55.1678L51.0361 55.1109L50.9569 55.008L51.2903 50.3258Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.405 62.3483L138.423 61.9887L118.439 59.8265L117.971 59.7762L118.285 60.1264L120.354 62.4386L120.408 62.4994L120.49 62.4984L138.405 62.3483Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M119.987 81.3154L120.122 74.1811L120.134 74.1297L121.145 69.5135L121.16 69.4425L121.122 69.3806L119.665 67.0079L120.665 62.3562L120.684 62.2666L120.623 62.1981L118.554 59.8859L118.152 59.4362L118.241 60.0325L119.291 67.0353L118.302 71.635L118.288 71.7038L118.301 71.7274L118.755 78.7414L118.749 78.7652L118.755 78.7826L118.614 85.88L117.625 90.3329L117.619 90.3601L117.622 90.3876L117.853 93.0994L118.212 93.1033L118.972 85.9303L119.983 81.3822L119.993 81.3331L119.987 81.3154ZM43.8149 70.2801L43.5136 69.7498L43.4778 70.3587L43.2071 74.9805L42.168 77.4187L42.1399 77.4852L42.1518 77.517L41.8919 82.0975L42.2386 82.1766L43.284 79.6557L43.3114 79.59L43.2997 79.5589L43.562 75.0847L45.1494 72.8408L45.2153 72.7475L45.159 72.6478L43.8149 70.2801ZM48.2453 56.7741L48.2736 56.3418L47.9468 56.6264L45.9058 58.4039L45.7232 58.1318L45.6949 58.588L45.6316 58.6429L45.6863 58.7242L45.3987 63.3877L43.7926 65.5155L43.7229 65.6075L43.7771 65.7094L45.1268 68.2248L45.4269 68.7835L45.4655 68.1506L45.7503 63.5373L47.7203 61.7543L47.899 62.0207L47.9292 61.5655L47.9906 61.5103L47.9379 61.4315L48.2453 56.7741Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.431 62.3466L138.402 61.9881L120.487 62.1378L120.16 62.1405L120.336 62.4158L121.863 64.7937L121.927 64.8922L122.043 64.8746L138.431 62.3466Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M120.669 62.3061L120.312 62.28L119.298 67.0027L119.283 67.0733L119.32 67.1348L120.815 69.5687L121.149 69.4623L120.669 62.3061ZM44.0952 65.5387L43.7935 64.9764L43.7561 65.6133L43.4801 70.3259L42.4361 72.707L42.4072 72.772L42.4306 72.839L43.2149 75.0814L43.5648 75.0327L43.8351 70.4323L45.4315 68.2457L45.4982 68.1539L45.4448 68.0541L44.0952 65.5387ZM48.562 51.9632L48.5901 51.5427L48.2666 51.8128L46.2126 53.5314L46.025 53.2385L45.996 53.7124L45.9337 53.7647L45.9874 53.8486L45.6905 58.663L45.6639 59.0934L45.9889 58.81L48.026 57.0352L48.2089 57.3217L48.2401 56.8484L48.3008 56.7954L48.2492 56.7148L48.562 51.9632ZM119.221 88.6021L119.227 88.5742L119.226 88.5639L119.986 81.3616L119.631 81.3037L118.618 85.8623L118.612 85.8898L118.613 85.9004L117.853 93.0649L118.208 93.1238L119.221 88.6021Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M142.934 69.5017L143.245 69.3195L141.302 65.6392L141.295 65.6256L141.285 65.6135L138.546 62.058L138.252 62.266L142.934 69.5017Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M121.015 76.7334L121.026 76.6828L121.019 76.6651L121.148 69.4954L122.191 64.7348L122.207 64.6616L122.167 64.5987L120.64 62.2205L120.308 62.3302L120.787 69.4607L119.766 74.1212L119.755 74.171L119.761 74.1887L119.626 81.3396L119.982 81.3819L121.015 76.7334ZM42.7708 72.7201L42.4206 72.7691L42.1533 77.4791L42.4994 77.5598L43.5506 75.0926L43.5781 75.0284L43.5551 74.9625L42.7708 72.7201ZM46.0499 58.6853L46.0856 58.1058L45.7276 58.563L44.0778 60.673L44.0071 60.7632L44.0587 60.8656L45.4138 63.5342L45.7139 64.1246L45.7549 63.4635L46.0499 58.6853Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M48.45 52.1185L48.5842 52.0635L48.5594 51.9208L46.6765 40.9531L46.6221 40.6373L46.3803 40.8475L20.8931 63.0147L21.0797 63.3179L48.45 52.1185Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M44.3802 60.7023L44.0781 60.1079L44.0392 60.7737L43.7552 65.6132L43.7206 66.2078L44.0792 65.7322L45.7183 63.5612L45.7862 63.4711L45.7354 63.3709L44.3802 60.7023Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.617 41.1197L46.425 40.8193L27.6257 49.2699L27.5662 49.2967L27.5374 49.3555L20.8497 63.0716L21.1297 63.2868L46.617 41.1197ZM138.459 62.3402L138.376 61.9898L121.987 64.518L121.765 64.5526L121.847 64.7621L122.798 67.1821L122.86 67.3389L123.021 67.2877L138.459 62.3402Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M120.253 83.9888L120.259 83.9624L120.258 83.9517L121.017 76.7236L122.067 71.9922L122.079 71.9401L122.073 71.9224L122.195 64.6996L121.839 64.6577L120.792 69.4361L120.781 69.4877L120.788 69.5058L120.659 76.6734L119.63 81.304L119.624 81.3327L119.625 81.3433L118.866 88.5335L117.856 93.0452L117.854 93.0515L117.853 93.0581L117.457 95.759L117.679 95.7966L117.812 95.8242L118.838 91.2511L118.84 91.2443L118.84 91.2418L120.253 83.9888ZM41.9754 75.3626L42.1518 77.4817L41.7101 80.0835L41.706 80.1065L41.7069 80.1172L41.446 84.7753L41.8033 84.815L42.2495 82.1371L42.2532 82.1144L42.2524 82.1045L42.5133 77.4998L42.7788 72.8227L43.8228 70.4416L43.85 70.3785L43.8392 70.3455L44.1157 65.6347L43.7712 65.5499L42.7089 67.901L42.6803 67.9649L42.6911 67.9993L42.4211 72.7595L41.9773 75.3165L41.9736 75.3392L41.9754 75.3626ZM46.3505 53.8128L46.3852 53.2514L46.0307 53.6878L44.5481 55.5128L44.3696 55.1418L44.3325 55.7784L44.2971 55.8221L44.3265 55.8832L44.0394 60.7741L44.0058 61.3505L44.3613 60.8953L45.8332 59.0142L46.0072 59.3754L46.0462 58.7413L46.0808 58.697L46.0527 58.6383L46.3505 53.8128Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.483 62.3303L138.349 61.9963L122.912 66.9442L122.767 66.9904L122.788 67.1407L123.124 69.573L123.158 69.8198L123.382 69.7105L138.483 62.3303Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M121.306 79.2865L121.313 79.2567L121.312 79.2461L122.07 71.982L123.142 67.1549L123.154 67.1013L123.134 67.0501L122.183 64.6301L121.835 64.693L121.711 71.9317L120.662 76.6553L120.656 76.6847L120.657 76.6953L119.897 83.9327L120.253 83.9908L121.306 79.2865ZM42.3346 75.3321L41.9749 75.3374L41.7074 80.1033L42.0655 80.1437L42.5113 77.5195L42.515 77.4969L42.5129 77.4746L42.3346 75.3321ZM44.3993 60.7946L44.0558 60.7079L42.9876 62.9967L42.9582 63.0599L42.9691 63.0951L42.6928 67.9648L43.0373 68.0496L44.1 65.6982L44.1279 65.6358L44.1174 65.6017L44.3993 60.7946Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.8388 49.5488L27.5726 49.3061L24.579 52.2753L24.566 52.2881L24.5553 52.3037L22.3796 55.5936L22.6691 55.8075L27.8388 49.5488Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M119.885 86.5853L119.886 86.5782L119.886 86.5757L121.307 79.2815L120.954 79.2077L119.901 83.9121L119.899 83.9191L119.899 83.9217L118.485 91.177L118.838 91.2511L119.885 86.5853ZM43.0529 67.9854L42.6955 67.9436L42.25 70.4521L42.2463 70.4747L42.2478 70.4971L42.4208 72.793L42.7805 72.7898L43.0529 67.9854Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M144.074 77.0539L144.42 76.9609L143.264 69.3769L143.259 69.3385L143.237 69.306L138.555 62.0703L138.236 62.2346L144.074 77.0539Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M122.381 74.4864L122.388 74.4556L122.387 74.4453L123.145 67.1341L122.79 67.0767L121.716 71.9138L121.709 71.9446L121.71 71.9549L120.951 79.2283L121.306 79.2864L122.381 74.4864ZM42.6076 70.4698L42.2475 70.4733L41.975 75.3374L42.3328 75.3781L42.778 72.8103L42.782 72.788L42.7803 72.766L42.6076 70.4698ZM44.6889 55.8576L44.3465 55.7683L43.2726 57.9922L43.2424 58.0547L43.2618 58.1207L44.0465 60.8342L44.3993 60.7946L44.6889 55.8576Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.5 62.3202L138.324 62.0058L123.223 69.3863L123.136 69.4284L123.124 69.5243L122.803 71.9375L122.752 72.3209L123.078 72.1136L138.5 62.3202Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.6081 58.0204L43.2549 58.0604L42.9709 63.063L43.3147 63.1492L44.383 60.8605L44.4113 60.7992L44.3928 60.7338L43.6081 58.0204ZM120.953 81.823L120.955 81.8159L120.955 81.8133L122.382 74.4813L122.029 74.4076L120.955 79.2079L120.953 79.2149L120.952 79.2172L119.532 86.511L119.885 86.5851L120.953 81.823Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.2335 53.9702L46.2628 53.9596L46.2865 53.9397L48.4977 52.0901L48.3139 51.7845L20.9436 62.9839L21.0745 63.3199L46.2335 53.9702Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.3314 63.0833L42.9739 63.0408L42.5295 65.4871L42.5254 65.5093L42.526 65.5204L42.2481 70.4737L42.6056 70.5148L43.0507 68.0066L43.0548 67.9851L43.0543 67.9741L43.3314 63.0833ZM123.479 69.5876L123.486 69.556L123.481 69.5238L123.145 67.0911L122.787 67.0971L122.026 74.4287L122.381 74.4865L123.479 69.5876Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.511 62.313L138.307 62.0158L122.885 71.8089L122.838 71.8387L122.817 71.8894L121.793 74.2452L121.525 74.8611L122.065 74.4622L138.511 62.313Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M122.044 76.963L122.045 76.9552L122.046 76.9526L123.479 69.5827L123.126 69.5091L122.029 74.4079L122.027 74.4154L122.027 74.418L120.6 81.7493L120.953 81.823L122.044 76.963ZM43.6149 58.0808L43.2577 58.0378L42.8132 60.4186L42.8095 60.4405L42.8105 60.4625L42.9711 63.0843L43.331 63.0833L43.6149 58.0808Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M44.5628 56.019L44.6146 56.003L44.649 55.9605L46.3107 53.9147L46.7275 53.4021L46.1081 53.6321L20.949 62.9819L21.0654 63.323L44.5628 56.019Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.1707 60.4406L42.8108 60.4416L42.5263 65.509L42.8838 65.5516L43.3285 63.1056L43.3323 63.0837L43.3309 63.0621L43.1707 60.4406Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.8621 49.5132L27.5606 49.3192L22.3909 55.578L22.3624 55.6132L22.3535 55.657L20.8352 63.1148L21.1741 63.2297L27.8621 49.5132ZM144.063 77.0145L144.422 76.9834L144.331 73.169L144.33 73.1466L144.324 73.1244L143.26 69.3548L142.907 69.4309L144.063 77.0145ZM138.516 62.3094L138.297 62.0232L121.851 74.172L121.831 74.1862L121.817 74.2057L120.044 76.4621L120.298 76.7146L138.516 62.3094Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M123.158 72.0005L123.159 71.993L123.161 71.9849L123.482 69.572L123.126 69.5139L121.691 76.8886L122.044 76.9627L123.158 72.0005Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.4749 58.2464L43.5594 58.2269L43.5972 58.1489L44.6711 55.9251L44.8521 55.5514L44.4553 55.6748L20.9583 62.9784L21.0514 63.3265L43.4749 58.2464Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M20.9603 83.4778L20.6003 83.4906L20.5452 88.3141L20.5453 88.331L20.5483 88.3478L21.3694 92.8999L21.7267 92.8531L20.9603 83.4778Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.516 62.3091L138.292 62.0268L138.291 62.0269L120.074 76.4317L120.071 76.4337L117.507 78.5409L117.504 78.5428L117.731 78.8235L117.733 78.8215L138.515 62.3095L138.516 62.3091ZM43.0125 60.6307L43.1439 60.6146L43.1679 60.4846L43.6124 58.1038L43.6628 57.8342L43.3948 57.8948L20.9717 62.9745L21.0338 63.3296L43.0125 60.6307Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M138.077 100.525L137.745 100.391L135.602 104.319L135.558 104.293L135.502 104.502L135.429 104.635L135.461 104.654L134.218 109.27L134.551 109.402L136.705 105.396L136.747 105.421L136.804 105.212L136.876 105.078L136.845 105.06L138.077 100.525ZM21.0163 78.553L20.6563 78.5657L20.6005 83.4904L20.6003 83.5085L20.6021 83.5165L21.3673 92.8822L21.7277 92.8698L21.7926 87.9668L21.7929 87.9487L21.7914 87.9403L21.0163 78.553Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.707 55.729L22.3598 55.6333L21.0938 59.2246L21.0857 59.2482L21.0843 59.2729L20.832 63.1387L21.1887 63.1868L22.707 55.729Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M139.372 95.7593L139.04 95.6243L136.818 99.631L136.746 99.7629L136.862 99.8582L137.789 100.617L138.004 100.793L138.077 100.525L139.372 95.7593Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M43.2234 63.1448L43.4195 63.1431L43.4014 62.9482L43.1701 60.4351L43.1532 60.2497L42.9688 60.2725L20.9898 62.9717L21.0132 63.3309L43.2234 63.1448Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.0731 73.5233L20.7131 73.5361L20.656 78.5657L20.6558 78.5838L20.6592 78.6017L21.5012 82.9887L21.858 82.94L21.0731 73.5233Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M132.477 91.3408L132.794 91.4888L144.382 77.1005L144.448 77.019L144.409 76.9215L138.572 62.1017L138.346 61.5288L138.227 62.1332L132.477 91.3408Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.8584 82.9574L21.859 82.939L21.8552 82.9208L21.0137 78.5341L20.6568 78.5828L21.4315 87.9705L21.3665 92.8651L21.3658 92.9031L21.3811 92.938L23.3786 97.6575L23.7213 97.5517L21.7925 87.9474L21.8584 82.9574ZM137.09 99.5792L136.874 99.4025L136.802 99.6717L135.496 104.523L135.829 104.656L138.061 100.564L138.133 100.433L138.017 100.338L137.09 99.5792ZM140.695 90.8919L140.363 90.756L138.153 94.6727L138.088 94.7881L138.178 94.8846L139.066 95.8348L139.287 96.0716L139.372 95.7595L140.695 90.8919Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M44.1569 65.7599L44.4439 65.7904L44.3449 65.5191L43.3908 62.9031L43.3472 62.7834L43.2199 62.7843L21.0097 62.9704L20.9926 63.33L44.1569 65.7599Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.1319 68.3855L20.7718 68.3982L20.7137 73.5365L20.7135 73.5553L20.715 73.5637L21.499 82.9697L21.8587 82.9574L21.927 77.8381L21.9272 77.8194L21.9253 77.8106L21.1319 68.3855ZM135.844 104.617L135.535 104.45L132.054 108.38L131.879 108.577L132.125 108.669L134.33 109.486L134.515 109.555L134.566 109.364L135.844 104.617ZM138.443 94.6383L138.221 94.4012L138.136 94.7147L136.802 99.6721L137.134 99.806L139.356 95.7993L139.419 95.6851L139.33 95.589L138.443 94.6383Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M132.537 91.5135L132.771 91.7113L132.83 91.4105L138.581 62.2032L138.675 61.7221L138.291 62.0271L117.507 78.5411L117.334 78.6781L117.502 78.8202L132.537 91.5135Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.8546 82.9189L21.4978 82.9521L21.4313 87.962L21.4306 88.0006L21.4388 88.0187L23.3672 97.6227L23.7245 97.5906L23.8133 92.6109L23.8137 92.5719L23.8058 92.5534L21.8546 82.9189ZM132.251 108.33L132.064 108.261L132.014 108.454L130.74 113.282L131.051 113.446L134.528 109.435L134.699 109.239L134.454 109.148L132.251 108.33ZM142.045 85.919L141.715 85.7816L139.518 89.6039L139.46 89.706L139.529 89.8006L140.375 90.9512L140.596 91.2523L140.694 90.8918L142.045 85.919ZM21.1909 63.1352L20.8309 63.1487L20.7714 68.3982L20.7712 68.4177L20.7751 68.4367L21.6388 72.6397L21.9951 72.5885L21.1909 63.1352Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M45.8644 68.4416L46.2837 68.5276L46.0527 68.1673L44.3277 65.4835L44.2808 65.4107L44.1946 65.4012L21.0303 62.9713L20.9751 63.3272L45.8644 68.4416Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.9962 72.6061L21.9964 72.5865L21.9921 72.5672L21.1284 68.3642L20.7724 68.4157L21.5667 77.8507L21.9267 77.838L21.9962 72.6061ZM137.15 99.7654L136.843 99.5975L133.356 103.443L133.191 103.625L133.414 103.728L135.595 104.733L135.789 104.823L135.844 104.616L137.15 99.7654Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M140.903 84.4426L141.225 84.6039L143.407 80.8814L143.42 80.8598L143.427 80.8356L144.415 77.033L144.075 76.9174L140.903 84.4426Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M139.89 89.682L141.874 86.2302L141.934 86.3311L142.04 85.9414L142.081 85.8707L142.066 85.8456L143.427 80.8374L143.097 80.6993L141.069 84.1573L141.007 84.0516L140.902 84.4423L140.861 84.5131L140.876 84.5393L139.598 89.2852L139.513 89.6028L136.052 93.2302L135.903 93.3863L136.083 93.5051L138.034 94.7944L134.786 98.2906L134.706 98.2462L134.658 98.4273L134.533 98.5625L134.611 98.6062L133.37 103.31L133.316 103.518L132.03 108.394L127.321 111.966L127.227 111.935L127.195 112.061L126.971 112.231L127.138 112.285L125.929 117.029L125.798 117.54L126.214 117.216L130.95 113.529L131.041 113.558L131.074 113.432L131.294 113.262L131.133 113.209L132.352 108.587L135.805 104.689L135.966 104.507L135.746 104.406L133.789 103.503L137.014 99.9462L137.09 99.9889L137.139 99.8077L137.262 99.6719L137.187 99.6299L138.42 95.0498L138.479 94.8302L140.678 90.9332L140.735 90.8315L140.666 90.7376L139.89 89.682ZM21.4978 82.9924L21.506 83.0103L23.4529 92.6241L23.4522 92.6635L23.3646 97.5839L23.3636 97.6413L23.3957 97.6888L26.6779 102.503L26.8196 102.433L27.0071 102.406L27.135 97.3561L27.1366 97.2971L27.1176 97.2701L23.905 87.4906L23.9053 87.4811L23.9971 82.3172L23.9977 82.2756L23.9891 82.2573L21.9969 72.5867L22.0676 67.2569L22.0677 67.2366L22.0635 67.2173L21.1883 63.1134L20.8324 63.1664L21.6355 72.6102L21.5663 77.8333L21.5661 77.8745L21.4985 82.9522L21.4978 82.9924Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.1818 63.0901L20.8354 63.1883L21.7106 67.2922L21.7153 67.314L21.7255 67.3345L23.8469 71.6271L24.1784 71.4866L21.1818 63.0901Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M22.0628 67.2179L21.7064 67.2522L21.6351 72.6012L21.6345 72.6436L21.6435 72.6615L23.6396 82.3503L23.9964 82.3171L24.091 76.9949L24.092 76.9522L24.083 76.9334L22.0628 67.2179ZM133.664 103.61L133.794 103.115L133.383 103.419L128.526 107.002L128.26 107.198L128.569 107.316L132.124 108.668L132.311 108.74L132.362 108.546L133.664 103.61ZM136.281 93.2044L136.072 93.0657L136.007 93.3087L134.646 98.4708L134.953 98.6398L138.442 94.8844L138.587 94.7285L138.41 94.6111L136.281 93.2044Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M132.513 91.2625L132.768 91.5157L141.183 84.6527L141.218 84.6242L141.235 84.5828L144.407 77.0581L144.101 76.875L132.513 91.2625Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M23.9876 82.2574L23.6365 82.3105L23.5436 87.5149L23.5424 87.5749L23.5613 87.6005L26.7732 97.3785L26.646 102.396L26.6441 102.472L26.6971 102.527L31.3647 107.345L31.6581 107.145L27.1355 97.3145L27.265 92.192L27.2666 92.1315L27.2476 92.1045L23.9876 82.2574ZM128.697 106.979L128.508 106.907L128.459 107.103L127.199 112.044L121.196 115.194L120.832 115.386L121.22 115.524L126.042 117.243L126.229 117.31L126.278 117.118L127.513 112.272L132.297 108.643L132.556 108.447L132.252 108.331L128.697 106.979ZM141.244 84.5597L141.422 83.8957L140.941 84.3864L137.558 87.8324L137.47 87.7644L137.413 87.981L137.304 88.0917L137.37 88.1431L136.008 93.3086L136.312 93.4788L139.693 89.9361L139.779 90.002L139.837 89.785L139.943 89.6732L139.88 89.6247L141.244 84.5597ZM134.995 98.5626L135.123 98.0788L134.716 98.3705L129.838 101.865L129.58 102.05L129.87 102.177L133.418 103.729L133.61 103.814L133.664 103.61L134.995 98.5626ZM24.1882 71.5502L24.1895 71.5064L24.1699 71.4668L22.0482 67.1746L21.7104 67.2912L23.7349 77.0282L24.0913 76.9947L24.1882 71.5502Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M132.563 91.5314L132.77 91.2381L117.736 78.5445L117.64 78.464L117.531 78.5251L114.127 80.4342L113.853 80.5882L114.125 80.7471L132.563 91.5314Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M24.0824 76.9345L23.7313 76.9884L23.6363 82.3102L23.6352 82.3717L23.6545 82.3977L26.9042 92.2144L26.7744 97.3473L26.7722 97.4239L26.8043 97.4559L31.3306 107.295L31.6747 107.226L31.8592 102.115L31.8621 102.037L31.8292 102.004L27.2666 92.1497L27.3993 86.9092L27.4011 86.8469L27.381 86.8203L24.0824 76.9345ZM130.015 101.846L129.82 101.761L129.768 101.967L128.459 107.103L128.334 107.592L128.74 107.292L133.597 103.709L133.848 103.524L133.562 103.399L130.015 101.846ZM121.341 115.184L121.152 115.117L121.105 115.312L119.896 120.292L119.8 120.687L120.157 120.494L126.189 117.232L126.545 117.04L126.164 116.904L121.341 115.184ZM136.357 93.4002L136.481 92.9276L136.08 93.2066L131.179 96.6078L130.929 96.7816L131.202 96.9173L134.74 98.6782L134.939 98.777L134.995 98.5626L136.357 93.4002Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.1622 63.0512L20.8419 63.2111L23.8385 71.6076L23.8512 71.6437L23.8776 71.6712L32.3309 80.5537L32.6117 80.3299L21.1622 63.0512ZM114.306 80.4354L114.231 80.3919L114.15 80.423L110.012 82.0256L109.589 82.1892L110.009 82.3602L132.586 91.5426L132.745 91.22L114.306 80.4354Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M128.808 107.192L128.906 106.806L128.551 106.986L122.509 110.058L122.392 110.012L122.363 110.133L122.08 110.277L122.306 110.366L121.105 115.311L121.011 115.699L121.364 115.514L127.364 112.365L127.478 112.41L127.508 112.289L127.786 112.144L127.568 112.057L128.808 107.192ZM24.179 71.4893L23.8275 71.5436L23.7306 76.9881L23.7296 77.0511L23.7496 77.077L27.0375 86.9312L26.9046 92.1828L26.9028 92.2612L26.9349 92.2932L31.4967 102.145L31.3138 107.213L31.3105 107.306L31.3842 107.362L37.323 111.933L37.5878 111.698L31.8608 102.062L32.048 96.8845L32.0505 96.8053L32.0173 96.7723L27.3999 86.8665L27.5356 81.503L27.5373 81.4392L27.5166 81.4126L24.179 71.4893ZM131.362 96.5947L131.162 96.4949L131.107 96.7113L129.768 101.967L129.646 102.446L130.047 102.158L134.926 98.6635L135.169 98.4889L134.901 98.3552L131.362 96.5947Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M140.955 84.373L132.622 91.1685L110.145 82.0262L110.089 82.0042L110.032 82.0191L105.341 83.2317L104.707 83.3959L105.335 83.5794L132.602 91.5488L132.656 91.3942L132.753 91.5254L137.675 88.2229L137.69 88.2126L137.703 88.1995L141.198 84.6393L140.955 84.373ZM46.0504 68.1649L46.0092 68.1035L45.9366 68.0882L21.0477 62.9742L20.9037 63.2957L63.433 94.824L63.6903 94.5788L46.0504 68.1649Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M121.455 115.396L121.534 115.072L121.22 115.184L114.229 117.684L114.096 117.628L114.07 117.741L113.726 117.864L114.014 117.985L112.898 122.884L112.824 123.209L113.136 123.094L120.067 120.529L120.196 120.583L120.223 120.471L120.561 120.346L120.282 120.229L121.455 115.396ZM137.749 88.1195L137.871 87.6577L137.474 87.9235L132.645 91.1646L132.537 91.1035L132.496 91.2644L132.309 91.3899L132.444 91.4656L131.107 96.7115L130.988 97.1798L131.385 96.904L136.192 93.5672L136.297 93.6259L136.339 93.4649L136.522 93.3385L136.392 93.2655L137.749 88.1195ZM130.117 102.056L130.214 101.678L129.862 101.85L123.781 104.839L123.661 104.787L123.63 104.913L123.356 105.048L123.574 105.143L122.343 110.214L122.25 110.595L122.6 110.417L128.639 107.347L128.755 107.397L128.788 107.271L129.056 107.135L128.846 107.043L130.117 102.056ZM37.8711 106.637L37.8758 106.542L37.8276 106.505L32.0495 96.8312L32.2409 91.5309L32.244 91.4502L32.2097 91.4176L27.5369 81.4608L27.6757 75.9694L27.6774 75.9042L27.6371 75.8535L24.1495 71.4354L23.837 71.6045L27.1742 81.5259L27.0386 86.9001L27.0362 86.9801L27.0693 87.0116L31.6859 96.9152L31.4984 102.102L31.4951 102.196L31.542 102.231L37.2778 111.882L37.5827 112.396L37.6128 111.8L37.8711 106.637Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M105.436 83.2331L105.398 83.2221L105.358 83.2284L100.205 84.0365L100.194 84.3907L132.614 91.552L132.704 91.2029L105.436 83.2331ZM48.5934 70.88L48.5843 70.8661L48.5731 70.8537L46.032 68.1419L45.7504 68.3651L63.3884 94.7758L63.3907 94.7793L63.6927 94.5824L63.6903 94.5789L48.5934 70.88Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M37.8371 106.522L37.5366 106.107L37.5112 106.619L37.253 111.781L37.2475 111.89L37.3417 111.945L44.2887 116.02L44.5262 115.759L37.8371 106.522ZM122.694 110.299L122.772 109.978L122.459 110.086L115.343 112.539L114.914 112.687L115.329 112.874L121.206 115.518L121.404 115.607L121.456 115.396L122.694 110.299Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M100.272 84.0384L100.247 84.033L100.22 84.035L94.7081 84.4282L94.6894 84.7857L132.622 91.553L132.692 91.1997L100.272 84.0384Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M131.457 96.8006L131.552 96.4285L131.205 96.5925L125.007 99.5308L124.664 99.6933L125.006 99.8566L129.865 102.174L130.063 102.269L130.117 102.056L131.457 96.8006ZM115.476 112.545L115.276 112.455L115.227 112.669L114.049 117.837L113.976 118.158L114.285 118.047L121.341 115.524L121.763 115.373L121.355 115.19L115.476 112.545ZM38.1357 101.352L38.1407 101.255L38.0915 101.219L32.2156 91.4315L31.903 90.9105L31.8811 91.518L31.6879 96.8714L31.684 96.9669L31.7316 97.0018L37.5365 106.72L37.842 107.231L37.8715 106.637L38.1357 101.352Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M24.1385 71.4225L23.8664 71.6585L27.3539 76.0766L27.3633 76.0887L27.3748 76.0992L32.3407 80.5636L32.5918 80.305L24.1385 71.4225Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M27.6588 75.8876L27.3156 75.9599L27.1754 81.4936L27.1735 81.5754L27.2331 81.6304L32.1365 86.175L32.4221 85.966L27.6588 75.8876Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M94.7525 84.43L94.7373 84.4274L94.7219 84.4274L88.9621 84.4022L88.9336 84.7605L132.621 91.5529L132.626 91.5539L132.685 91.1981L132.681 91.1972L94.7525 84.43ZM51.9965 73.5831L51.9792 73.5519L51.9509 73.5297L48.5533 70.8358L48.289 71.074L63.3882 94.7756L63.6977 94.5912L51.9965 73.5831Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.439 86.0495L32.4425 85.9669L32.3816 85.9109L27.4783 81.3663L27.1923 81.575L31.8979 91.6009L32.2412 91.5308L32.439 86.0495ZM125.161 99.5309L124.96 99.4356L124.908 99.6516L123.61 104.995L123.52 105.369L123.865 105.199L130.022 102.173L130.356 102.009L130.02 101.849L125.161 99.5309ZM114.4 117.917L114.464 117.637L114.184 117.701L106.289 119.504L105.787 119.619L106.251 119.842L112.995 123.087L113.199 123.185L113.25 122.965L114.4 117.917Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M88.9893 84.4043L88.9823 84.4028L88.9749 84.4024L83.0875 83.9605L83.0479 84.3186L132.626 91.554L132.627 91.5542L132.681 91.1973L132.679 91.1974L88.9893 84.4043Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M106.407 119.517L106.2 119.417L106.153 119.642L105.078 124.747L105.019 125.025L105.296 124.959L113.115 123.1L113.61 122.982L113.152 122.762L106.407 119.517Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M83.1002 83.9618L83.0998 83.9615L77.2077 83.112L77.2069 83.112L77.1551 83.4689L132.627 91.5536L132.653 91.3757L132.68 91.1971L132.677 91.1965L83.1002 83.9618Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M44.9044 110.678L44.9123 110.565L44.848 110.529L38.1007 101.237L37.8003 100.823L37.7749 101.334L37.5107 106.619L37.5049 106.729L37.5673 106.765L44.2332 115.971L44.5263 116.375L44.5592 115.877L44.9044 110.678ZM123.96 105.08L124.037 104.764L123.728 104.866L116.551 107.246L116.128 107.386L116.53 107.579L122.44 110.419L122.64 110.515L122.693 110.298L123.96 105.08ZM132.828 91.4201L132.92 91.0554L132.578 91.2115L126.337 94.0561L126.001 94.2098L126.328 94.3804L131.198 96.9158L131.4 97.0207L131.456 96.8005L132.828 91.4201Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M56.1548 76.1459L56.1324 76.091L56.082 76.0607L51.9341 73.5173L51.3469 73.1578L51.6821 73.759L63.3836 94.7668L63.7082 94.6107L56.1548 76.1459Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M116.687 107.254L116.483 107.156L116.433 107.377L115.226 112.669L115.155 112.986L115.461 112.88L122.577 110.427L122.992 110.284L122.596 110.093L116.687 107.254ZM44.8624 110.548L44.5749 110.213L44.5454 110.654L44.1998 115.853L44.1915 115.978L44.3062 116.03L52.1053 119.521L52.3156 119.24L44.8624 110.548ZM38.4062 95.9402L38.4107 95.8417L38.3611 95.8058L32.4136 85.9498L32.1007 85.4315L32.0784 86.0365L31.8806 91.5177L31.8774 91.6147L31.9255 91.6492L37.8009 101.436L38.1056 101.944L38.1355 101.352L38.4062 95.9402Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M21.1189 63.0056L20.8612 63.2505L32.3111 80.5287L32.3397 80.5715L32.3859 80.5933L63.4653 94.8426L63.6482 94.5339L21.1189 63.0056Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.6416 80.436L32.6446 80.3516L32.5819 80.2953L27.6161 75.8309L27.3321 76.0421L32.0958 86.1201L32.4387 86.0496L32.6416 80.436ZM126.495 94.0608L126.291 93.9546L126.237 94.1779L124.908 99.6517L124.819 100.019L125.16 99.8572L131.359 96.9192L131.687 96.7634L131.365 96.5962L126.495 94.0608ZM115.578 112.75L115.641 112.473L115.363 112.533L107.454 114.262L107.302 114.185L107.278 114.3L106.896 114.384L107.225 114.55L106.179 119.516L97.8066 120.532L97.6403 120.442L97.6189 120.555L97.1893 120.607L97.5694 120.813L96.6085 125.836L96.562 126.081L96.8085 126.049L105.219 124.971L105.381 125.059L105.404 124.947L105.831 124.892L105.458 124.69L106.482 119.83L114.201 118.067L114.349 118.142L114.375 118.028L114.752 117.942L114.431 117.78L115.578 112.75ZM45.2584 105.353L45.2662 105.239L45.1671 105.184L38.0435 101.186L37.8091 101.45L44.5792 110.772L44.8722 111.175L44.9048 110.678L45.2584 105.353Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.8754 78.4501L60.8593 78.3589L60.7764 78.3187L56.0654 76.0517L55.6428 75.848L55.8207 76.2823L63.3736 94.7475L63.7185 94.6479L60.8754 78.4501Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M60.7714 122.061L52.8096 114.066L52.8137 114.021L52.7329 113.985L45.2203 105.229L38.4094 95.8765L38.6835 90.3964L38.6883 90.2968L38.6061 90.2396L32.3617 85.895L32.1045 86.1362L38.0433 95.9771L37.7755 101.334L37.7698 101.446L37.867 101.5L44.8919 105.442L44.5455 110.654L44.5371 110.78L44.6149 110.815L51.9929 119.417L51.9872 119.483L52.0743 119.512L52.3218 119.801L52.3391 119.6L60.5865 122.358L60.7714 122.061ZM125.259 99.7365L125.334 99.4249L125.029 99.5218L117.861 101.801L117.72 101.729L117.692 101.854L117.373 101.956L117.638 102.091L116.433 107.377L116.362 107.689L116.665 107.588L123.771 105.232L123.907 105.302L123.937 105.177L124.251 105.073L123.995 104.942L125.259 99.7365Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M66.0518 80.4658L66.0754 80.3201L65.9371 80.2681L60.7614 78.3122L60.4654 78.2004L60.52 78.5121L63.3627 94.7102L63.7185 94.7084L66.0518 80.4658Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.6162 80.3358L32.3035 79.8204L32.2814 80.4227L32.0789 86.0367L32.0754 86.1348L32.1558 86.1909L38.4005 90.5351L38.6573 90.2937L32.6162 80.3358ZM88.5987 120.788L88.1378 120.8L88.5532 121.054L87.6899 126.123L87.6528 126.341L87.8732 126.333L96.738 126.052L96.9142 126.159L96.9364 126.045L97.3957 126.031L96.9871 125.781L97.9487 120.752L97.9908 120.531L97.7666 120.538L88.8 120.782L88.6189 120.671L88.5987 120.788ZM116.785 107.457L116.847 107.183L116.572 107.24L108.586 108.905L108.432 108.823L108.407 108.942L108.032 109.021L108.354 109.191L107.254 114.414L107.197 114.687L107.469 114.627L115.377 112.9L115.526 112.978L115.553 112.861L115.923 112.78L115.609 112.615L116.785 107.457Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M71.5599 82.1445L71.6857 81.9427L71.4569 81.876L65.9237 80.2638L65.7285 80.2065L65.6958 80.4079L63.3624 94.6497L63.6936 94.7745L71.5599 82.1445ZM119.048 96.2702L119.163 96.6117L126.464 94.3934L126.476 94.3899L126.487 94.385L132.728 91.54L132.59 91.2069L119.048 96.2702Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M61.2861 116.795L61.0519 116.589L61.039 116.713L52.679 113.963L52.4948 114.261L60.455 122.254L60.4474 122.326L60.552 122.351L60.7828 122.583L60.8014 122.41L69.5233 124.468L69.6836 124.157L61.3837 116.881L61.3893 116.829L61.2861 116.795ZM98.6282 115.275L98.2062 115.324L98.579 115.533L97.5941 120.684L97.5479 120.926L97.7926 120.897L106.293 119.866L106.455 119.957L106.479 119.843L106.898 119.792L106.533 119.587L107.607 114.489L107.658 114.244L107.41 114.272L98.8177 115.254L98.6502 115.16L98.6282 115.275ZM45.6208 99.8975L45.629 99.7827L45.5281 99.7275L38.3127 95.7733L38.0801 96.0375L44.9331 105.447L45.2261 105.849L45.2591 105.353L45.6208 99.8975Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M77.2969 83.4293L77.5804 83.1924L77.219 83.1147L71.4447 81.8728L71.3213 81.8463L71.2539 81.9536L63.3877 94.5837L63.6564 94.8174L77.2969 83.4293Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M53.2573 108.798L53.2681 108.669L53.149 108.618L45.1501 105.175L44.9421 105.458L52.4859 114.251L52.766 114.578L52.8023 114.149L53.2573 108.798ZM79.5939 120.26L79.3564 120.097L79.3137 120.382L78.5839 125.294L70.338 118.92L70.0884 118.726L70.0485 119.039L69.3858 124.27L69.3636 124.446L69.5388 124.471L78.5119 125.777L78.5094 125.793L78.6987 125.804L79.3702 125.902L79.288 125.838L87.8567 126.333L88.5028 126.371L87.9691 126.005L79.5939 120.26ZM38.6487 90.2812L38.3483 89.8698L38.3228 90.3784L38.0459 95.922L38.0402 96.0349L38.1392 96.0891L45.3543 100.044L45.5868 99.7795L38.6487 90.2812ZM126.587 94.263L126.662 93.9562L126.36 94.0479L119.059 96.2662L118.647 96.3911L119.025 96.5972L124.997 99.8519L125.203 99.9646L125.258 99.7363L126.587 94.263Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M77.2071 83.1123L77.1278 83.1006L77.0654 83.1521L63.4253 94.5406L63.0134 94.8848L63.5494 94.8593L132.662 91.5559L132.68 91.1974L77.2071 83.1123Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M97.9718 120.631L98.9335 115.603L107.392 114.638L107.556 114.732L107.581 114.616L107.992 114.569L107.634 114.361L108.711 109.247L116.58 107.607L116.732 107.691L116.759 107.57L117.122 107.494L116.815 107.325L117.998 102.135L125.138 99.8654L125.541 99.7377L125.17 99.5353L119.198 96.2807L118.989 96.1666L118.936 96.3987L117.695 101.841L109.745 103.416L109.589 103.33L109.563 103.452L109.196 103.525L109.511 103.699L108.41 108.93L99.8549 109.844L99.686 109.746L99.6634 109.865L99.2485 109.909L99.6145 110.122L98.631 115.262L89.7076 115.464L89.5252 115.351L89.5051 115.469L89.0511 115.48L89.4596 115.735L88.6219 120.654L80.3901 114.915L80.1514 114.749L80.1088 115.037L79.3596 120.076L71.0183 113.56L70.7686 113.364L70.7284 113.679L70.0918 118.702L61.9491 111.519L61.9546 111.465L61.8511 111.432L61.6171 111.225L61.604 111.352L53.2698 108.656L53.7242 103.315L53.7349 103.184L53.6143 103.134L45.6303 99.7694L45.9928 94.3071L46.0009 94.1908L45.9344 94.1557L38.971 84.6521L38.9729 84.6144L38.9143 84.5753L38.6329 84.1909L38.6234 84.3782L32.5629 80.2803L32.3077 80.5232L38.3208 90.4338L38.3177 90.4929L38.3751 90.5232L38.6543 90.9836L38.6583 90.905L45.2575 99.9391L44.8996 105.329L44.891 105.456L45.0082 105.506L52.8872 108.898L52.4433 114.119L52.4313 114.261L52.5666 114.305L61.0003 117.08L60.9991 117.094L61.1036 117.118L61.3345 117.351L61.3377 117.32L69.4461 124.429L69.7014 124.652L69.7439 124.316L70.3675 119.398L78.607 125.767L78.8503 125.956L78.8954 125.651L79.6288 120.72L87.7656 126.302L87.998 126.461L88.0455 126.183L88.9022 121.154L89.3805 121.182L89.3044 121.129L97.7223 120.9L97.8994 121.01L97.9214 120.894L98.3742 120.882L97.9718 120.631Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5534 80.2741L32.3607 80.5788L38.6859 84.8562L38.6946 84.862L38.7037 84.8668L46.1103 88.7243L46.2852 88.4091L32.5534 80.2741ZM101.921 98.622L101.988 98.9757L119.136 96.6173L119.156 96.6149L119.174 96.6082L132.717 91.5444L132.611 91.2003L101.921 98.622Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M98.9806 115.353L99.9666 110.195L108.517 109.28L108.683 109.38L108.708 109.26L109.112 109.217L108.761 109.006L109.867 103.759L117.813 102.185L117.966 102.274L117.994 102.149L118.349 102.079L118.05 101.905L119.286 96.4791L119.348 96.2112L119.078 96.2619L110.933 97.7881L110.774 97.6971L110.748 97.8227L110.388 97.8899L110.697 98.0684L109.566 103.439L100.917 104.299L100.747 104.197L100.724 104.318L100.316 104.359L100.674 104.574L99.6651 109.852L90.6371 110.014L90.4534 109.897L90.4334 110.017L89.9863 110.026L90.3879 110.282L89.5274 115.333L81.2056 109.436L80.9657 109.267L80.9228 109.557L80.1538 114.728L71.7153 108.065L71.4645 107.867L71.4243 108.184L70.7713 113.34L62.5271 106.022L62.533 105.968L62.4295 105.935L62.1955 105.727L62.1821 105.856L53.7355 103.171L54.201 97.6939L54.2124 97.5624L54.0904 97.5119L46.0007 94.1778L46.3731 88.5763L46.3808 88.4589L46.2763 88.4046L38.87 84.5467L38.6413 84.8137L45.6283 94.3486L45.2611 99.8733L45.2525 100.002L45.3708 100.052L53.353 103.415L52.8977 108.768L52.8857 108.911L53.0221 108.955L61.5645 111.718L61.563 111.732L61.6679 111.756L61.8987 111.989L61.902 111.958L70.1078 119.197L70.3632 119.422L70.4061 119.085L71.046 114.039L79.3809 120.55L79.6248 120.741L79.6705 120.435L80.4222 115.378L88.6479 121.112L88.8812 121.274L88.929 120.994L89.8075 115.836L90.2785 115.865L90.2061 115.814L98.7295 115.621L98.9075 115.735L98.9302 115.616L99.3758 115.606L98.9806 115.353Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M132.645 91.1956L63.5763 94.4969L32.5372 80.2652L32.3699 80.5845L46.1016 88.7195L46.1171 88.7287L46.1339 88.7341L63.4812 94.8488L63.5256 94.7396L63.5217 94.8583L101.944 98.9766L101.976 98.9799L102.006 98.9722L132.697 91.5508L132.645 91.1956Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M90.4356 110.005L81.12 109.404L71.6365 108.029L62.4299 105.931L54.1546 97.5561L46.33 88.4471L46.0424 88.112L46.013 88.5524L45.6328 94.2831L45.6242 94.4123L45.7436 94.4618L53.8311 97.7953L53.3642 103.284L53.3523 103.428L53.4891 103.471L62.2474 106.256L70.7874 113.836L71.0433 114.063L71.086 113.724L71.7421 108.546L80.1753 115.205L80.4192 115.398L80.4653 115.09L81.2364 109.9L89.5532 115.793L89.7867 115.959L89.8351 115.676L90.7387 110.373L99.7626 110.211L99.9415 110.328L99.9648 110.207L100.403 110.2L100.015 109.945L101.053 104.518L101.094 104.301L100.873 104.304L91.5911 104.425L91.4068 104.304L91.3859 104.428L90.9456 104.433L91.3407 104.692L90.4356 110.005Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M101.939 98.6185L101.98 98.9768L110.917 98.157L110.925 98.1562L110.933 98.1547L119.145 96.6161L119.087 96.2601L101.939 98.6185Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M72.1637 102.352L63.0231 100.295L54.6983 91.8469L54.7029 91.7971L54.614 91.7612L54.3654 91.5092L54.3528 91.6559L46.2608 88.3969L46.0562 88.6816L53.8359 97.7386L53.8296 97.8073L53.9185 97.8355L54.1651 98.1222L54.1681 98.0834L62.15 106.161L62.1425 106.235L62.247 106.26L62.4775 106.493L62.4963 106.316L71.4041 108.346L71.4025 108.359L71.5237 108.377L71.7393 108.569L71.7594 108.411L80.896 109.736L80.8938 109.751L81.033 109.76L81.2334 109.92L81.2529 109.788L81.7332 109.857L81.6616 109.8L90.5225 110.372L90.7153 110.51L90.7362 110.385L91.2002 110.415L90.7825 110.114L91.7158 104.636L91.7494 104.439L91.5501 104.426L82.0001 103.788L81.8019 103.645L81.7846 103.76L72.392 102.4L72.1789 102.23L72.1637 102.352ZM101.811 98.6301L101.41 98.6668L101.763 98.8852L100.698 104.45L100.653 104.687L100.893 104.663L109.671 103.791L109.838 103.894L109.864 103.772L110.261 103.732L109.917 103.519L111.076 98.015L111.127 97.7757L110.883 97.7983L102.007 98.6119L101.835 98.5056L101.811 98.6301Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M46.2529 88.3942L46.1258 88.7318L54.4441 92.0819L54.4512 92.0849L54.459 92.0871L63.4876 94.8513L63.6005 94.5092L46.2529 88.3942Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M92.3648 98.6824L82.8584 98.0242L82.6594 97.8784L82.6399 98.0088L82.1846 97.9772L82.5995 98.2812L81.8049 103.626L73.2422 96.7139L73.2503 96.651L73.1312 96.6243L72.9123 96.4478L72.8969 96.5722L63.6267 94.5135L63.3991 94.3087L63.3848 94.4426L54.565 91.7424L54.3839 92.0413L62.7443 100.525L62.736 100.6L62.8409 100.624L63.0714 100.858L63.0905 100.68L72.1185 102.711L72.1172 102.724L72.238 102.742L72.4541 102.936L72.4722 102.791L72.9521 102.899L72.8813 102.835L81.7318 104.117L81.73 104.131L81.9182 104.144L82.5619 104.237L82.4941 104.182L91.3275 104.773L91.3246 104.789L91.4809 104.787L91.6675 104.924L91.689 104.797L92.146 104.827L92.0801 104.779L100.822 104.665L101.002 104.786L101.025 104.662L101.457 104.657L101.075 104.401L102.14 98.8313L102.182 98.6155L101.962 98.6172L92.5696 98.6929L92.3844 98.5681L92.3648 98.6824Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M63.5601 94.4996L63.5083 94.8564L82.7612 98.3772L82.7746 98.3802L82.7845 98.3801L92.5028 99.0532L92.5095 99.0536L92.5165 99.0537L101.965 98.9775L101.966 98.8545L101.983 98.6179L63.5601 94.4996Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M82.9719 98.2265L82.9984 98.0476L82.8194 98.0218L73.0763 96.6124L72.426 96.5186L72.9373 96.9315L81.8233 104.105L82.0686 104.302L82.1153 103.991L82.9719 98.2265Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
      <path
        d="M63.5735 94.5016L63.502 94.8554L73.0118 96.9674L73.0185 96.9685L73.0252 96.9697L82.7679 98.3788L82.8264 98.0231L63.5735 94.5016Z"
        fill="#D8CDE0"
        stroke="#D8CDE0"
        strokeWidth="0.693723"
      />
    </svg>
  );
};
export default BottomLeftGroup;
