export { default as Medal } from './Medal';
export { default as Star } from './Star';
export { default as BottomRainbow } from './BottomRainbow';
export { default as Stars } from './Stars';
export { default as Africa } from './Africa';
export { default as Google } from './Google';
export { default as Linked } from './Linked';
export { default as Facebook } from './Facebook';
export { default as Github } from './Github';
export { default as Twitter } from './Twitter';
export { default as Logo } from './Logo';
export { default as Arrow } from './Arrow';
export { default as MenuIcon } from './MenuIcon';
export { default as Search } from './Search';
export { default as Lighting } from './Lighting';
export { default as Clear } from './Clear';
export { default as Group4 } from './Group4';
export { default as Group17 } from './Group17';
export { default as FeaturesTopLeft } from './FeaturesTopLeft';
export { default as FeaturesBottomLeft } from './FeaturesBottomLeft';
export { default as FeaturesTopRight } from './FeaturesTopRight';
export { default as FeaturesRightGroup } from './FeaturesRightGroup';
export { default as FeaturesBottomLeftMobile } from './FeaturesBottomLeftMobile';
export { default as FeaturesTopRightMobile } from './FeaturesTopRightMobile';
export { default as FeaturesRightGroupMobile } from './FeaturesRightGroupMobile';
export { default as FeaturesTopLeftMobile } from './FeaturesTopLeftMobile';
export { default as ImageIcon } from './ImageIcon';
export { default as FooTopLeft } from './FooTopLeft';
export { default as FooCenter } from './FooCenter';
export { default as FootBottom } from './FootBottom';
export { default as TopLeftGroup } from './TopLeftGroup';
export { default as TopRightGroup } from './TopRightGroup';
export { default as RightCenterGroup } from './RightCenterGroup';
export { default as LeftCenterGroup } from './LeftCenterGroup';
export { default as BottomRightGroup } from './BottomRightGroup';
export { default as BottomLeftGroup } from './BottomLeftGroup';
export { default as BackArrow } from './BackArrow';
export { default as Flutterwave } from './Flutterwave';
export { default as Paystack } from './Paystack';
export { default as ManThinking } from './ManThinking';
export { default as LeftRainBow } from './TopRainBow';
export { default as TopRainBow } from './TopRainBow';
export { default as DownArrow } from './DownArrow';
export { default as Winners } from './Winners';
export { default as Globe } from './Globe';
export { default as Interactions } from './Interactions';
export { default as LinkedIn } from './LinkedIn';
export { default as InterSwitch } from './InterSwitch';
export { default as Target } from './Target';
export { default as Trophy } from './Trophy';
export { default as People } from './People';
export { default as Back } from './Back';
export { default as LinkedInStroked } from './LinkedInStroked';
export { default as InstagramStroked } from './InstagramStroked';
export { default as TwitterStroked } from './TwitterStroked';
export { default as BehanceStroked } from './BehanceStroked';
export { default as GitHubStroked } from './GitHubStroked';
export { default as ScrollDown } from './ScrollDown';
export { default as WesternEurope } from './WesternEurope';
export { default as UnitedKingdom } from './UnitedKingdom';
export { default as FaqIllustration } from './FaqIllustration';
export { default as Nigeria } from './Nigeria';
export { default as ArrowRight } from './ArrowRight';
export { default as Illustartion404 } from './Illustartion404';
export { default as WatermarkTop404 } from './WatermarkTop404';
export { default as WatermarkBottom404 } from './WatermarkBottom404';
export { default as UploadImageIcon } from './UploadImageIcon';
export { default as Important } from './Important';
export { default as Xcom } from './Xcom';
export { default as LinkedInIcon } from './LinkedIcon';
export { default as WhatsappIcon } from './Whatsapp';
export { default as MailIcon } from './MailIcon';
