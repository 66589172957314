import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const DownArrow: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8L0 1.99965L2.00094 0L6 4.00071L9.99906 0L12 1.99965L6 8Z"
        fill="black"
      />
    </svg>
  );
};
export default DownArrow;
