import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const WatermarkBottom404: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
        width="654"
        height="658"
        viewBox="0 0 654 658"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M521.607 576.571C394.461 304.633 66.4428 401.773 -77.2643 198.747C-155.932 87.6091 -282.482 -159.995 -645.274 153.577C-878.762 356.87 -751.154 623.607 -438.769 742.803C-356.584 786.271 -319.894 844.9 -296.911 923.084C-273.929 1001.27 -325.358 1171.64 -231.284 1328.08C51.8153 1798.86 997.769 1594.97 521.607 576.571Z"
          fill="#1F0231"
        />
      </svg>
  );
};
export default WatermarkBottom404;
