import React, { type ChangeEvent, type FC } from 'react';

import { TypeAnimation } from 'react-type-animation';

import { DM_Sans } from 'next/font/google';
import type { PropTypes } from '../../typescript';
import { Clear, Search } from '../icons';
import Link from 'next/link';
import { cn } from '@util/functions/utils';
interface Heading {
  heading?: Array<string | number>;
  text?: string;
  typing?: boolean;
  search: string;
  changeSearch: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  clearSearch: () => void;
  className: string;
  isHome?: boolean;
}
const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin', 'latin-ext'],
});
const SearchBar: FC<PropTypes<{ className: string }, Heading>> = ({
  typing,
  heading,
  text,
  search,
  changeSearch,
  clearSearch,
  className,
  isHome,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cn('m-auto flex flex-col items-center space-y-6', className)}
    >
      {typing ? (
        <h1 className="z-10 mx-auto w-4/5 text-center text-3xl font-medium lg:text-7xl">
          Biggest Tech Professionals Award in <br />
          <TypeAnimation
            sequence={heading != null ? heading : []}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            style={{ color: '#FA9706' }}
            speed={1}
          />
        </h1>
      ) : (
        <h1 className="mx-auto w-4/5 text-center text-3xl font-medium capitalize lg:text-7xl">
          {text}
        </h1>
      )}
      {isHome && (
          <Link
            className="mx-auto block w-fit cursor-pointer rounded bg-golden px-6 py-5 font-bold capitalize text-lightPurple transition-all duration-500 ease-in hover:bg-lightPurple hover:text-darkPurple disabled:cursor-not-allowed disabled:first-letter:opacity-70"
            href="https://forms.gle/2D3aqWXbKUycofVC6"
            target="_blank"
          >
            Participate
          </Link>
      )}
      {!isHome && (
        <div className="mx-auto mt-8 flex w-4/5 flex-col items-center gap-6 lg:w-3/5 lg:flex-row">
          <label className="relative mx-auto block w-full">
            <span className="absolute inset-y-0 left-2 flex items-center pl-2">
              <Search className="h-5 w-5" />
            </span>
            <input
              type="text"
              className="my-auto block h-[auto] w-full rounded-md border border-[#DBDBDB] bg-transparent py-4 pl-12 pr-8 align-middle shadow-sm outline-none placeholder:text-[#BABABA]"
              placeholder="Participant's Name, Participant's Category"
              value={search}
              onChange={changeSearch}
            />
            <span
              className={`absolute inset-y-0 right-2 flex items-center pr-2 ${
                search.length > 0 ? 'scale-100' : 'scale-0'
              } origin-center cursor-pointer transition-transform duration-300 ease-in-out`}
              onClick={clearSearch}
            >
              <Clear className="h-5 w-5" />
            </span>
          </label>
          <span className="relative flex h-full w-full cursor-pointer items-center justify-center rounded bg-lightPurple px-8 py-4 text-center font-semibold text-darkPurple transition-all duration-500 ease-in hover:bg-darkPurple hover:text-lightPurple lg:w-fit">
            Search
          </span>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
