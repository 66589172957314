import React, { useCallback, useEffect, useState, type FC } from 'react';

import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel, { type EmblaOptionsType } from 'embla-carousel-react';
import Image from 'next/image';
export interface IEditionCarouselProps {
  slides: Array<{
    src: string
    alt: string
    href: string
  }>
  options: EmblaOptionsType
}
const autoplayOptions = {
  delay: 4000,
  rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement
};

const EditionCarousel: FC<IEditionCarouselProps> = ({ slides, options }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay(autoplayOptions)
  ]);
  const [scrollProgress, setScrollProgress] = useState(0);

  const onScroll = useCallback(() => {
    if (emblaApi == null) return;
    const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
    setScrollProgress(progress * 100);
  }, [emblaApi, setScrollProgress]);

  useEffect(() => {
    if (emblaApi == null) return;
    onScroll();
    emblaApi.on('scroll', onScroll);
    emblaApi.on('reInit', onScroll);
  }, [emblaApi, onScroll]);

  return (
    <section className="lg:pl-16 flex flex-col gap-4 items-center">
      <div
        onMouseOver={() => {
          if (emblaApi != null) {
            (emblaApi as any)?.plugins().autoplay.stop();
          }
        }}
        onMouseLeave={() => {
          if (emblaApi != null) {
            (emblaApi as any).plugins().autoplay.play();
          }
        }}
        className="embla"
      >
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((val, index) => (
              <div
                className="embla__slide2  shadow-xl shadow-black/15"
                key={index}
              >
                <Image
                  src={`/edition/${val.src}`}
                  width={364}
                  height={413}
                  alt={val.alt}
                  className="embla__slide__img"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <span
          className={`w-6 h-3 rounded-full border-2 border-golden cursor-pointer transition-colors duration-300 ease-in hover:bg-golden ${
            scrollProgress <= 25 && scrollProgress > 0
              ? 'bg-golden'
              : 'bg-transparent'
          } `}
        />
        <span
          className={`w-6 h-3 rounded-full border-2 border-golden cursor-pointer transition-colors duration-300 ease-in hover:bg-golden ${
            scrollProgress <= 50 && scrollProgress > 25
              ? 'bg-golden'
              : 'bg-transparent'
          } `}
        />
        <span
          className={`w-6 h-3 rounded-full border-2 border-golden cursor-pointer transition-colors duration-300 ease-in hover:bg-golden ${
            scrollProgress <= 75 && scrollProgress > 0
               ? 'bg-golden'
              : 'bg-transparent'
          } `}
        />
        <span
          className={`w-6 h-3 rounded-full border-2 border-golden cursor-pointer transition-colors duration-300 ease-in hover:bg-golden ${
            scrollProgress <= 100 && scrollProgress > 75 ? 'bg-golden' : 'bg-transparent'
          } `}
        />
      </div>
    </section>
  );
};
export default EditionCarousel;
