import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const Trophy: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 58 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_549_1164)">
          <path
            d="M31.6719 40.0781H26.3281V53.4375H31.6719V40.0781Z"
            fill="#3E0563"
          />
          <path
            d="M36.1211 49.875H21.8711C20.3978 49.875 19.1992 51.0736 19.1992 52.5469V56.1094C19.1992 56.6017 19.5976 57 20.0898 57H37.9023C38.3946 57 38.793 56.6017 38.793 56.1094V52.5469C38.793 51.0734 37.5944 49.875 36.1211 49.875Z"
            fill="#FA9706"
          />
          <path
            d="M36.1215 49.875H29V57H37.9028C38.3951 57 38.7934 56.6017 38.7934 56.1094V52.5469C38.7934 51.0734 37.5949 49.875 36.1215 49.875Z"
            fill="#FA9706"
          />
          <path
            d="M15.6393 33.8446C15.4053 33.8446 15.167 33.7977 14.9382 33.6994C6.35818 30.023 0.556979 20.4287 0.502984 9.82727C0.497752 8.86884 0.867361 7.96686 1.54413 7.28676C2.219 6.60833 3.11753 6.23438 4.0725 6.23438H14.7495C15.7332 6.23438 16.5308 7.03193 16.5308 8.01562C16.5308 8.99932 15.7332 9.79688 14.7495 9.79688H4.0725C4.11247 19.0136 9.04575 27.2989 16.3421 30.4256C17.2457 30.8127 17.665 31.8599 17.277 32.7644C16.9882 33.4393 16.3307 33.8446 15.6393 33.8446Z"
            fill="#FA9706"
          />
          <path
            d="M42.3595 33.8446C41.6681 33.8446 41.0106 33.4393 40.7218 32.7644C40.3339 31.8599 40.7531 30.8127 41.6567 30.4256C48.9531 27.2989 53.8863 19.0136 53.9332 9.8099L43.2493 9.79688C42.2656 9.79688 41.468 8.99932 41.468 8.01562C41.468 7.03193 42.2656 6.23438 43.2493 6.23438H53.9263C54.8813 6.23438 55.7797 6.60833 56.4547 7.28676C57.1313 7.96686 57.5009 8.86884 57.4958 9.82816C57.4419 20.4287 51.6406 30.0229 43.0606 33.6994C42.8318 33.7977 42.5934 33.8446 42.3595 33.8446Z"
            fill="#FA9706"
          />
          <path
            d="M44.1367 0.890625H13.8555C13.3632 0.890625 12.9648 1.28896 12.9648 1.78125V25.8281C12.9648 34.6674 20.1569 41.8594 28.9961 41.8594C37.8353 41.8594 45.0273 34.6674 45.0273 25.8281V1.78125C45.0273 1.28896 44.6289 0.890625 44.1367 0.890625Z"
            fill="#FA9706"
          />
          <path
            d="M28.5249 0.890625H13.3809C12.8886 0.890625 12.4902 1.28896 12.4902 1.78125V25.8281C12.4902 34.6674 19.6823 41.8594 28.5215 41.8594C28.5227 41.8594 28.5238 41.8593 28.5249 41.8593V0.890625Z"
            fill="#FA9706"
          />
          <path
            d="M30.2 12.4529L31.929 17.7743H37.5242C38.7507 17.7743 39.2606 19.3438 38.2685 20.0646L33.7419 23.3534L35.4709 28.6748C35.8499 29.8412 34.5148 30.8111 33.5227 30.0903L28.9961 26.8015L24.4695 30.0903C23.4772 30.8113 22.1421 29.8412 22.5211 28.6748L24.2501 23.3534L19.7235 20.0646C18.7313 19.3437 19.2413 17.7743 20.4678 17.7743H26.063L27.7921 12.4529C28.1707 11.2865 29.8209 11.2865 30.2 12.4529Z"
            fill="white"
          />
          <path
            d="M37.5252 17.7739H31.9299L30.2009 12.4525C30.0119 11.8705 29.5062 11.5793 29 11.5781V26.8036L33.5234 30.09C34.5156 30.811 35.8507 29.841 35.4716 28.6745L33.7426 23.3531L38.2692 20.0643C39.2616 19.3434 38.7516 17.7739 37.5252 17.7739Z"
            fill="white"
          />
          <path
            d="M12.9688 3.5625C11.9891 3.5625 11.1875 2.76094 11.1875 1.78125C11.1875 0.801562 11.9891 0 12.9688 0H45.0312C46.0109 0 46.8125 0.801562 46.8125 1.78125C46.8125 2.76094 46.0109 3.5625 45.0312 3.5625H12.9688Z"
            fill="#3E0563"
          />
        </g>
        <defs>
          <clipPath id="clip0_549_1164">
            <rect
              width="57"
              height="57"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
  );
};
export default Trophy;
