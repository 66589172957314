import React, { type FC, useEffect, useState } from 'react';

import Image from 'next/legacy/image';
import Link from 'next/link';

import { close } from '../public';

export interface IPromptProps {}

const Prompt: FC<IPromptProps> = (props) => {
  const [displayIncrease, setDisplayIncrease] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDisplayIncrease(true);
    }, 5000);
  }, []);
  return (
    <div
      className={`fixed top-24 right-6 z-20 flex flex-col items-center gap-3 bg-white p-6 shadow-xl transition-all duration-500 ease-in hover:shadow-2xl ${
        displayIncrease ? 'scale-100' : 'scale-0'
      }`}
    >
      <div
        className="absolute top-2 right-2 cursor-pointer"
        onClick={() => { setDisplayIncrease(false); }}
      >
        <Image src={close} width={24} height={24} alt="close" />{' '}
      </div>
      <p className="mt-4">Want More Voting Power?</p>
      <Link href="/increase">
        <span className="rounded-md bg-darkPurple py-2 px-3 text-white transition-all duration-500 ease-in hover:bg-lightPurple ">
          Increase Here
        </span>
      </Link>
    </div>
  );
};
export default Prompt;
