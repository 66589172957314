import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const InstagramStroked: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
      <svg
        {...props}
        viewBox="0 0 48 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.6922 22.5C46.6922 34.6326 36.473 44.5 23.8268 44.5C11.1807 44.5 0.961426 34.6326 0.961426 22.5C0.961426 10.3674 11.1807 0.5 23.8268 0.5C36.473 0.5 46.6922 10.3674 46.6922 22.5Z"
          stroke="white"
        />
        <path
          d="M19.2614 14H27.6614C30.8614 14 33.4614 16.6 33.4614 19.8V28.2C33.4614 29.7383 32.8504 31.2135 31.7626 32.3012C30.6749 33.3889 29.1997 34 27.6614 34H19.2614C16.0614 34 13.4614 31.4 13.4614 28.2V19.8C13.4614 18.2617 14.0725 16.7865 15.1602 15.6988C16.2479 14.6111 17.7232 14 19.2614 14ZM19.0614 16C18.1066 16 17.191 16.3793 16.5158 17.0544C15.8407 17.7295 15.4614 18.6452 15.4614 19.6V28.4C15.4614 30.39 17.0714 32 19.0614 32H27.8614C28.8162 32 29.7319 31.6207 30.407 30.9456C31.0821 30.2705 31.4614 29.3548 31.4614 28.4V19.6C31.4614 17.61 29.8514 16 27.8614 16H19.0614ZM28.7114 17.5C29.0429 17.5 29.3609 17.6317 29.5953 17.8661C29.8297 18.1005 29.9614 18.4185 29.9614 18.75C29.9614 19.0815 29.8297 19.3995 29.5953 19.6339C29.3609 19.8683 29.0429 20 28.7114 20C28.3799 20 28.062 19.8683 27.8275 19.6339C27.5931 19.3995 27.4614 19.0815 27.4614 18.75C27.4614 18.4185 27.5931 18.1005 27.8275 17.8661C28.062 17.6317 28.3799 17.5 28.7114 17.5ZM23.4614 19C24.7875 19 26.0593 19.5268 26.997 20.4645C27.9346 21.4021 28.4614 22.6739 28.4614 24C28.4614 25.3261 27.9346 26.5979 26.997 27.5355C26.0593 28.4732 24.7875 29 23.4614 29C22.1353 29 20.8636 28.4732 19.9259 27.5355C18.9882 26.5979 18.4614 25.3261 18.4614 24C18.4614 22.6739 18.9882 21.4021 19.9259 20.4645C20.8636 19.5268 22.1353 19 23.4614 19ZM23.4614 21C22.6658 21 21.9027 21.3161 21.3401 21.8787C20.7775 22.4413 20.4614 23.2044 20.4614 24C20.4614 24.7956 20.7775 25.5587 21.3401 26.1213C21.9027 26.6839 22.6658 27 23.4614 27C24.2571 27 25.0201 26.6839 25.5827 26.1213C26.1454 25.5587 26.4614 24.7956 26.4614 24C26.4614 23.2044 26.1454 22.4413 25.5827 21.8787C25.0201 21.3161 24.2571 21 23.4614 21Z"
          fill="white"
        />
      </svg>
  );
};
export default InstagramStroked;
