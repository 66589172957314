import React, { type FC } from 'react';

import type { IconProps } from '../../typescript';

const Stars: FC<IconProps> = (props) => {
  return (
        <svg {...props} width="176" height="80" viewBox="0 0 176 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_11209)">
                <path d="M21.9316 42.0743L26.443 51.2157C26.7568 51.8515 27.3634 52.2921 28.0651 52.3939L38.1535 53.8599C39.9207 54.1168 40.6258 56.2879 39.3475 57.5336L32.0475 64.6492C31.5402 65.144 31.3083 65.8574 31.4284 66.5557L33.1514 76.6033C33.4535 78.363 31.6062 79.7047 30.0259 78.8745L21.0029 74.131C20.3755 73.8014 19.6253 73.8014 18.9979 74.131L9.97495 78.8745C8.39464 79.7055 6.54733 78.363 6.84938 76.6033L8.57236 66.5557C8.69251 65.8574 8.46056 65.144 7.95326 64.6492L0.653297 57.5336C-0.624968 56.2871 0.0800799 54.116 1.84729 53.8599L11.9357 52.3939C12.6374 52.2921 13.244 51.8515 13.5578 51.2157L18.0692 42.0743C18.8586 40.4731 21.1414 40.4731 21.9316 42.0743Z" fill="#FA9706" />
            </g>
            <g clipPath="url(#clip1_1_11209)">
                <path d="M91.8631 4.14858L100.886 22.4314C101.514 23.703 102.727 24.5841 104.13 24.7877L124.307 27.7197C127.841 28.2337 129.252 32.5758 126.695 35.0672L112.095 49.2984C111.08 50.2879 110.617 51.7147 110.857 53.1115L114.303 73.2066C114.907 76.726 111.212 79.4093 108.052 77.7489L90.0058 68.2621C88.7509 67.6029 87.2507 67.6029 85.9958 68.2621L67.9499 77.7489C64.7893 79.411 61.0947 76.726 61.6988 73.2066L65.1447 53.1115C65.385 51.7147 64.9211 50.2879 63.9065 49.2984L49.3066 35.0672C46.7501 32.5741 48.1602 28.232 51.6946 27.7197L71.8715 24.7877C73.2749 24.5841 74.4881 23.703 75.1155 22.4314L84.1385 4.14858C85.7171 0.946242 90.2828 0.946242 91.8631 4.14858Z" fill="#FA9706" />
            </g>
            <g clipPath="url(#clip2_1_11209)">
                <path d="M157.932 42.0743L162.443 51.2157C162.757 51.8515 163.363 52.2921 164.065 52.3939L174.154 53.8599C175.921 54.1168 176.626 56.2879 175.348 57.5336L168.048 64.6492C167.54 65.144 167.308 65.8574 167.428 66.5557L169.151 76.6033C169.453 78.363 167.606 79.7047 166.026 78.8745L157.003 74.131C156.375 73.8014 155.625 73.8014 154.998 74.131L145.975 78.8745C144.395 79.7055 142.547 78.363 142.849 76.6033L144.572 66.5557C144.693 65.8574 144.461 65.144 143.953 64.6492L136.653 57.5336C135.375 56.2871 136.08 54.116 137.847 53.8599L147.936 52.3939C148.637 52.2921 149.244 51.8515 149.558 51.2157L154.069 42.0743C154.859 40.4731 157.141 40.4731 157.932 42.0743Z" fill="#FA9706" />
            </g>
            <defs>
                <clipPath id="clip0_1_11209">
                    <rect width="40" height="40" fill="white" transform="translate(0 40)" />
                </clipPath>
                <clipPath id="clip1_1_11209">
                    <rect width="80" height="80" fill="white" transform="translate(48)" />
                </clipPath>
                <clipPath id="clip2_1_11209">
                    <rect width="40" height="40" fill="white" transform="translate(136 40)" />
                </clipPath>
            </defs>
        </svg>
  );
};

export default Stars;
