import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const DropDown: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="22"
      height="13"
      viewBox="0 0 22 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1585 0.00933972L21.1355 9.58634L17.4707 12.3841L10.821 5.99878L3.49616 11.5966L0.168804 8.40489L11.1585 0.00933972Z"
        fill="#0C0114"
      />
    </svg>
  );
};

export default DropDown;
