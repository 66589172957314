import React, { type FC } from 'react';

import { ManThinking } from './icons';

export interface IPaymentPopupProps {
  toggle: boolean
  close: () => void
  setCustomPriceToggle: () => void
}

const PaymentPopup: FC<IPaymentPopupProps> = ({
  toggle,
  close,
  setCustomPriceToggle
}) => {
  return (
    <section
      className={`inset-0 flex ${
        toggle ? 'translate-x-[-100%]' : 'translate-0'
      } fixed z-50 items-center justify-center bg-dark/50 transition-transform duration-700`}
    >
      <div className="flex h-3/4 w-3/4 flex-col items-center justify-center bg-white p-6">
        <div className="flex h-3/5 w-3/4 flex-col items-center justify-between">
          <ManThinking className="h-24 w-24" />
          <h2 className="text-lg font-bold lg:text-xl">
            Do you want to Purchase as much Votes as your budget can get you?
          </h2>
          <div className="flex  w-1/3 justify-around ">
            <button
              className="rounded px-4 text-red-600 transition-colors duration-500 ease-in hover:text-red-400"
              onClick={() => { close(); }}
            >
              No
            </button>
            <button
              className="rounded px-4 text-green-600 transition-colors duration-500 ease-in hover:text-green-400"
              onClick={() => {
                close();
                setCustomPriceToggle();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PaymentPopup;
