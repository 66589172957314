import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const MailIcon: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 17 14"
      fill="none"
    >
      <path
        d="M14.3798 0H3.17979C2.54327 0 1.93282 0.276562 1.48273 0.768845C1.03264 1.26113 0.779785 1.92881 0.779785 2.625V11.375C0.779785 12.0712 1.03264 12.7389 1.48273 13.2312C1.93282 13.7234 2.54327 14 3.17979 14H14.3798C15.0163 14 15.6268 13.7234 16.0768 13.2312C16.5269 12.7389 16.7798 12.0712 16.7798 11.375V2.625C16.7798 1.92881 16.5269 1.26113 16.0768 0.768845C15.6268 0.276562 15.0163 0 14.3798 0ZM13.8438 1.75L8.77979 5.90625L3.71579 1.75H13.8438ZM14.3798 12.25H3.17979C2.96761 12.25 2.76413 12.1578 2.6141 11.9937C2.46407 11.8296 2.37979 11.6071 2.37979 11.375V2.84375L8.29979 7.7C8.43826 7.81359 8.60669 7.875 8.77979 7.875C8.95288 7.875 9.12131 7.81359 9.25979 7.7L15.1798 2.84375V11.375C15.1798 11.6071 15.0955 11.8296 14.9455 11.9937C14.7954 12.1578 14.592 12.25 14.3798 12.25Z"
        fill="white"
      />
    </svg>
  );
};
export default MailIcon;
