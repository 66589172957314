import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const LinkedInIcon: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.7058 0.000111863H1.17423C1.02212 -0.0020038 0.871084 0.0259095 0.72975 0.0822577C0.588415 0.138606 0.459548 0.222285 0.350508 0.328517C0.241468 0.434748 0.154392 0.561451 0.0942511 0.70139C0.0341104 0.841328 0.00208357 0.99176 0 1.1441V14.8559C0.00208357 15.0082 0.0341104 15.1587 0.0942511 15.2986C0.154392 15.4385 0.241468 15.5653 0.350508 15.6715C0.459548 15.7777 0.588415 15.8614 0.72975 15.9177C0.871084 15.9741 1.02212 16.002 1.17423 15.9999H14.7058C14.8579 16.002 15.0089 15.9741 15.1502 15.9177C15.2916 15.8614 15.4205 15.7777 15.5295 15.6715C15.6385 15.5653 15.7256 15.4385 15.7857 15.2986C15.8459 15.1587 15.8779 15.0082 15.88 14.8559V1.1441C15.8779 0.99176 15.8459 0.841328 15.7857 0.70139C15.7256 0.561451 15.6385 0.434748 15.5295 0.328517C15.4205 0.222285 15.2916 0.138606 15.1502 0.0822577C15.0089 0.0259095 14.8579 -0.0020038 14.7058 0.000111863ZM4.81672 13.3919H2.42034V6.19203H4.81672V13.3919ZM3.61853 5.18404C3.28804 5.18404 2.97109 5.05256 2.73739 4.81851C2.5037 4.58447 2.37241 4.26704 2.37241 3.93606C2.37241 3.60507 2.5037 3.28764 2.73739 3.0536C2.97109 2.81956 3.28804 2.68807 3.61853 2.68807C3.79402 2.66814 3.97174 2.68556 4.14004 2.73918C4.30835 2.7928 4.46345 2.88142 4.59518 2.99924C4.72692 3.11706 4.83232 3.26141 4.90448 3.42285C4.97665 3.58429 5.01396 3.75918 5.01396 3.93606C5.01396 4.11294 4.97665 4.28782 4.90448 4.44926C4.83232 4.6107 4.72692 4.75506 4.59518 4.87287C4.46345 4.99069 4.30835 5.07931 4.14004 5.13293C3.97174 5.18656 3.79402 5.20397 3.61853 5.18404ZM13.4597 13.3919H11.0633V9.52798C11.0633 8.55999 10.7198 7.928 9.84911 7.928C9.57965 7.92998 9.31727 8.01462 9.09732 8.17054C8.87737 8.32645 8.71042 8.54613 8.61897 8.79999C8.55646 8.98802 8.52938 9.18604 8.53909 9.38398V13.3839H6.14272V6.18402H8.53909V7.20001C8.75679 6.8217 9.07341 6.51004 9.45486 6.29859C9.83631 6.08713 10.2681 5.98391 10.7038 6.00003C12.3014 6.00003 13.4597 7.03201 13.4597 9.24798V13.3919Z"
        fill="white"
      />
    </svg>
  );
};
export default LinkedInIcon;
