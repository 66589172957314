import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import type { IconProps, Props, PropTypes } from '../../typescript';

const FeaturesRightGroupMobilen: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <motion.svg
      initial={{ scale: 1.1, rotate: 12 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 1 }
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
      width="82"
      height="73"
      viewBox="0 0 82 73"
      fill="none"
    >
      <path
        d="M24.477 14.4458L24.4732 14.4482L24.4698 14.451L21.0589 17.0142L21.0568 17.0159L21.0544 17.018L17.7911 19.733L17.7887 19.7347L17.8389 19.7967L17.7842 19.7389L14.6859 22.6015L14.6834 22.6036L14.681 22.606L11.7754 25.6087L11.9455 25.7781L17.9429 19.9198L31.7993 10.0215L39.3839 6.16281L39.2775 5.94727L35.4376 7.77402L35.4348 7.77539L35.432 7.77679L31.6794 9.81286L31.7168 9.88356L31.6742 9.81564L28.021 12.042L28.0186 12.0437L28.0162 12.0451L24.477 14.4458Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.2433 27.549L33.9608 27.4994L34.1237 27.7358L35.5578 29.818L31.9483 29.3068L31.6738 29.2676L31.8329 29.4946L33.4451 31.7917L33.5176 31.8946L33.617 31.818L35.8829 30.073L38.2548 28.467L40.7223 27.009L40.9875 26.8524L40.6863 26.7883L36.646 25.9257L36.3542 25.8633L36.5213 26.1107L37.9321 28.1977L34.2433 27.549Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.2805 3.23652L47.1904 3.01367L39.288 5.9425L39.2814 5.94493L39.2752 5.94804L31.6826 9.81053L31.7855 10.0278L47.2805 3.23652Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7212 25.9763L36.6539 25.8765L36.5541 25.944L34.2546 27.5012L34.2505 27.4953L34.1528 27.5701L33.9098 27.7347L33.933 27.7389L31.8588 29.3307L31.6318 29.5047L31.9153 29.5449L35.7962 30.0945L36.0675 30.133L35.9119 29.9074L34.4851 27.8359L38.1701 28.484L38.4495 28.5332L38.2908 28.2983L36.7212 25.9763ZM39.1402 24.45L38.838 24.373L39.0102 24.633L40.5615 26.9723L40.6207 27.0617L40.7164 27.0132L43.2633 25.7184L43.5482 25.5735L43.2387 25.4949L39.1402 24.45Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.817 10.0078L31.6673 9.82031L17.796 19.729L17.7856 19.7366L17.7769 19.7463L6.66113 32.0248L6.83025 32.1953L31.817 10.0078Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.6049 31.2872L29.4798 31.3957L29.502 31.3981L27.7016 33.175L27.4953 33.158L27.5886 33.2862L27.4756 33.3978L27.6821 33.4144L29.3131 35.6546L29.3956 35.7683L29.495 35.6692L31.4486 33.7198L31.6514 33.7364L31.564 33.6144L33.5831 31.848L33.7949 31.8712L33.7041 31.7419L33.8285 31.6331L33.6109 31.6092L32.096 29.4508L34.2929 27.7651L36.6461 26.1715L40.6361 27.0234L40.9241 27.0847L40.7612 26.8394L39.3822 24.7596L43.1331 25.7158L43.1626 25.7612L43.218 25.7376L43.4769 25.8035L43.3871 25.6656L45.8644 24.6085L46.1694 24.4786L45.8516 24.3829L41.7496 23.1485L41.7146 23.0944L41.6612 23.1218L41.393 23.041L41.4994 23.2046L39.1507 24.4103L39.1469 24.4044L39.089 24.4387L35.0498 23.5633L34.7569 23.4999L34.9247 23.748L36.3619 25.8745L32.6266 25.2012L32.3424 25.1499L32.5063 25.3876L33.9671 27.5082L30.3088 26.9697L30.0322 26.9291L30.1927 27.1579L31.7709 29.406L29.7872 31.1288L29.782 31.1219L29.7224 31.1805L29.512 31.1572L29.6049 31.2872Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.2736 3.23837L47.1939 3.01172L43.2084 4.34601L43.2049 4.34739L43.2011 4.34878L39.2842 5.94332L39.3712 6.16756L47.2736 3.23837Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.1244 23.6135L35.0571 23.5137L34.9573 23.5813L32.5383 25.2198L32.2939 25.3855L32.5844 25.4378L36.6004 26.1614L36.8807 26.212L36.721 25.976L35.1244 23.6135Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.955 19.9069L17.7821 19.7402L11.7782 25.6052L11.7726 25.6104L11.7681 25.6163L6.65625 32.0302L6.8396 32.1858L17.955 19.9069Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.8623 25.4801L32.7732 25.3508L35.0495 23.8093L39.0371 24.6733L39.0697 24.7221L39.1269 24.6927L39.3739 24.7464L39.2849 24.6116L41.6852 23.3799L45.7404 24.6002L45.7671 24.6424L45.8201 24.6241L46.0932 24.7066L46.001 24.562L48.4727 23.7133L48.7576 23.8134L48.6553 23.6506L48.839 23.5875L48.5528 23.4866L47.0639 21.1106L47.013 21.0291L46.9222 21.061L44.3454 21.9687L44.3416 21.9628L44.291 21.9846L44.0065 21.8845L44.0197 21.9049L40.4289 20.8198L40.4448 20.8115L40.1863 20.7446L40.1523 20.6923L40.099 20.72L39.8307 20.6389L39.8411 20.6552L35.9558 19.6474L35.6522 19.5684L35.8255 19.83L37.2589 21.9916L37.2675 22.0047L33.4726 21.1698L33.4321 21.1099L33.3752 21.1484L33.1319 21.0946L33.2331 21.2447L30.9912 22.7637L30.9874 22.7582L30.9295 22.8022L30.6963 22.7592L30.7923 22.8985L30.6498 22.9952L30.671 22.999L28.5902 24.5842L28.3625 24.5492L28.4578 24.6851L28.3244 24.7866L28.5538 24.8216L30.1268 27.0632L29.9924 27.1661L30.0139 27.1693L28.1223 28.8002L28.1175 28.7933L28.0582 28.8511L27.8454 28.8262L27.9397 28.9575L27.8156 29.0646L27.8367 29.0671L26.0103 30.8494L25.8017 30.8307L25.8963 30.9607L25.7837 31.0705L25.9895 31.0889L27.5931 33.2917L25.8447 35.2716L25.6475 35.2609L25.7418 35.3881L25.6416 35.5014L25.8332 35.5118L27.4916 37.7503L27.5806 37.8702L27.6791 37.7576L29.4628 35.7073L29.6566 35.7177L29.564 35.5905L29.6628 35.4771L29.4743 35.4671L27.9054 33.3121L29.8115 31.431L30.0166 31.4493L29.9279 31.3253L31.9706 29.5512L32.1844 29.5762L32.0926 29.4455L32.2163 29.3381L31.9994 29.3124L30.5507 27.2483L34.2056 27.7865L34.4787 27.8267L34.3221 27.5993L32.8623 25.4801Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.8528 9.9474L31.9526 9.56445L31.6567 9.82715L6.66992 32.015L6.81721 32.2042L30.1304 16.4382L30.1678 16.4129L30.1793 16.3693L31.8528 9.9474Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.4215 26.4862L26.1494 26.4526L26.3088 26.6755L28.0036 29.046L28.0802 29.1531L28.1799 29.0671L30.3703 27.1787L30.5754 27.0019L30.3065 26.9683L26.4215 26.4862ZM36.0259 19.6963L35.9624 19.6007L35.864 19.6596L33.3383 21.1727L33.0787 21.3283L33.3743 21.3931L37.5061 22.3021L37.7965 22.3663L37.6322 22.1185L36.0259 19.6963ZM42.848 19.6222L42.7926 19.5352L42.6983 19.5767L40.0627 20.7381L39.7653 20.8695L40.0762 20.9634L44.261 22.228L44.5715 22.3219L44.3968 22.0481L42.848 19.6222Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.8549 9.89734L31.6203 9.88867L29.9467 16.3105L29.8722 16.5968L30.1252 16.4436L32.6891 14.8928L32.7602 14.8498L32.7453 14.7683L31.8549 9.89734ZM11.9554 25.768L11.77 25.6148L9.08586 28.754L9.08343 28.7571L9.08066 28.7606L6.65332 32.035L6.84393 32.1816L11.9554 25.768Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.7204 24.6403L28.6445 24.5321L28.544 24.6181L26.3277 26.5142L26.1208 26.6912L26.3911 26.7249L30.2765 27.2069L30.545 27.2402L30.3894 27.0184L28.7204 24.6403ZM47.2991 21.1819L47.3234 21.1732L47.0045 21.0609L46.6611 20.9202L46.677 20.9455L42.7861 19.5731L42.4572 19.457L42.6447 19.7513L44.1939 22.1772L44.2452 22.2576L44.3349 22.2261L46.9109 21.3188L48.3707 23.6484L48.4175 23.7229L48.5024 23.7011L51.1907 23.0121L51.5421 22.922L51.2066 22.7844L47.2991 21.1819ZM26.0501 35.4001L27.837 33.3776L27.9992 33.1935L27.7545 33.1779L24.1814 32.9492L23.9229 32.9326L24.0771 33.1409L25.7454 35.3928L24.1422 37.5111L24.0012 37.6972L24.2347 37.7037L27.5843 37.7977L27.8314 37.8046L27.6845 37.606L26.0501 35.4001Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.8208 9.83165L31.5498 9.56445L31.6184 9.93875L32.5084 14.8094L32.5382 14.9716L32.6834 14.8939L35.3114 13.4904L35.4556 13.4134L35.3391 13.2987L31.8208 9.83165Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.2416 37.4631L23.9921 37.4562L24.1425 37.6558L25.8649 39.9421L25.962 40.0704L26.0576 39.9414L27.6844 37.7491L27.822 37.5636L27.5915 37.5571L24.2416 37.4631ZM38.5087 18.2748L38.2401 18.1934L38.3479 18.359L35.9088 19.6354L31.7725 18.7118L31.4772 18.646L31.6467 18.8962L33.1387 21.1038L29.307 20.3792L29.0201 20.3251L29.1857 20.5656L30.7016 22.7663L26.9424 22.1705L26.6624 22.1258L26.8252 22.358L28.5241 24.7785L28.5959 24.8807L28.6953 24.8052L31.0298 23.0266L33.4644 21.3769L35.9441 19.8915L40.0353 20.9527L40.0658 20.9992L40.1209 20.9749L40.3805 21.0421L40.2887 20.9011L42.795 19.7966L43.0917 19.6656L42.7815 19.5713L38.5978 18.3018L38.5617 18.2467L38.5087 18.2748ZM27.8442 33.227L26.2129 30.9862L28.145 29.1008L28.3529 29.1213L28.2587 28.9899L28.3699 28.8815L28.1672 28.8614L26.504 26.5356L26.4232 26.4222L26.3227 26.5189L24.2884 28.4815L24.077 28.4607L24.173 28.5928L24.061 28.7009L24.2662 28.721L25.8999 30.965L24.0846 32.989L23.9189 33.1733L24.1664 33.1889L27.7392 33.418L27.9949 33.4343L27.8442 33.227Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.7774 9.80469L31.6523 10.0036L35.1707 13.4707L35.2299 13.5292L35.3055 13.4942L37.9827 12.2546L38.2485 12.1315L37.9727 12.0324L31.7774 9.80469Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.6819 18.7284L31.4276 18.8816L31.449 18.8864L29.3166 20.3316L29.3128 20.3261L29.2151 20.4002L28.9766 20.5621L28.9971 20.5659L26.951 22.1185L26.9468 22.113L26.8491 22.1961L26.6269 22.3646L26.647 22.3677L24.6449 24.0672L24.4231 24.0385L24.5198 24.1733L24.3975 24.2776L24.6144 24.306L26.3091 26.6759L26.3854 26.7826L26.4852 26.6973L28.6602 24.8363L28.8782 24.8647L28.7839 24.7302L28.9063 24.6259L28.6911 24.5978L27.1856 22.4529L30.9413 23.0483L31.2182 23.092L31.0592 22.8615L29.5502 20.6705L33.378 21.3945L33.6615 21.4479L33.5 21.2091L32.0157 19.0129L35.8525 19.87L35.8858 19.9199L35.943 19.8901L36.1911 19.9455L36.1 19.8079L38.5336 18.534L42.6695 19.7892L42.6975 19.8329L42.7475 19.8149L46.883 21.2732L46.9076 21.3131L46.9544 21.3006L51.116 23.0074L51.4573 23.1474L51.264 22.8335L49.8462 20.5288L50.0351 20.4782L49.7412 20.3576L49.7148 20.315L49.6673 20.3275L45.7591 18.7267L45.7823 18.7183L45.5044 18.6203L45.4753 18.5735L45.4274 18.5908L45.1246 18.4667L45.1401 18.4917L41.2499 17.1182L41.2163 17.0659L41.1654 17.0884L40.8815 16.9883L40.9945 17.165L38.5107 18.2779L34.3661 17.1931L34.328 17.1356L34.2729 17.1688L34.0171 17.102L34.1218 17.2596L31.7824 18.6677L31.779 18.6625L31.6819 18.7284Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.7528 9.79883L31.6963 10.031L37.8915 12.2584L37.9341 12.2737L37.9761 12.2574L40.6866 11.2003L40.6585 10.9692L31.7528 9.79883Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.3289 33.0781L26.1044 31.0988L26.3044 31.1138L26.2087 30.9824L26.3071 30.8729L26.1186 30.859L24.4904 28.6223L26.4492 26.7328L26.6596 26.755L26.5643 26.6219L26.6752 26.5148L26.4725 26.4933L24.8436 24.2146L26.9989 22.3848L29.3164 20.6263L29.5441 20.6634L29.4526 20.5306L31.7721 18.9583L32.0085 19.004L31.9167 18.8685L34.3094 17.4282L38.4453 18.5105L38.4761 18.5576L38.5312 18.533L38.7908 18.601L38.6979 18.4582L41.1755 17.3485L45.3815 18.8332L49.5766 20.5518L51.0586 22.9604L51.1008 23.0291L51.1805 23.0162L53.8027 22.5882L54.1756 22.5272L53.836 22.3616L49.7426 20.3626L48.2316 17.9065L48.1841 17.8292L48.0972 17.8534L45.4785 18.5809L43.9605 16.1587L43.9089 16.0759L43.8171 16.1099L41.2192 17.0726L39.6669 14.6408L39.6108 14.5527L39.5158 14.5961L36.9467 15.7661L36.9433 15.7609L36.8899 15.7889L36.6216 15.7071L36.6317 15.7227L32.7016 14.6851L32.6632 14.6272L32.6084 14.6605L32.3519 14.5929L32.4573 14.7517L30.0986 16.1785L30.0955 16.1736L30.039 16.2117L29.7932 16.1563L29.8927 16.3032L29.7458 16.392L29.7662 16.3965L27.6113 17.8576L27.6075 17.8524L27.5503 17.8961L27.3143 17.8503L27.4123 17.9927L27.2727 18.087L27.2921 18.0908L25.1631 19.7006L24.932 19.6625L25.0294 19.8015L24.8983 19.9006L25.125 19.938L26.6665 22.1342L22.942 21.6196L22.6651 21.5814L22.8276 21.8088L24.5227 24.1797L22.5354 26.0743L22.3216 26.0515L22.419 26.1852L22.3074 26.2916L22.5126 26.3135L24.1768 28.5998L22.3715 30.5807L22.1684 30.5658L22.2655 30.6972L22.166 30.8067L22.3573 30.8209L24.0233 33.0694L22.4277 35.1353L22.236 35.1277L22.3334 35.2569L22.2475 35.3685L22.4228 35.3758L24.0922 37.5911L22.7361 39.7412L22.5583 39.7405L22.6561 39.8684L22.5847 39.981L22.7427 39.9817L24.4624 42.2274L24.5684 42.3661L24.6603 42.217L26.0281 39.9921L26.2028 39.9924L26.1065 39.8646L26.1758 39.7519L26.0212 39.7516L24.3889 37.5852L25.954 35.5179L26.1422 35.5255L26.0462 35.3959L26.1307 35.2843L25.9582 35.2774L24.3289 33.0781Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.6499 18.1212L25.2669 19.9223L25.2641 19.9248L25.261 19.9265L6.81709 32.2053L6.81641 32.2057L6.68505 32.0102L27.6499 18.1212ZM27.6499 18.1212L6.68505 32.0102L27.6499 18.1212ZM27.6499 18.1212L30.1303 16.4393H30.1306L29.9958 16.2401L29.9954 16.2404L6.68539 32.0043L6.68332 32.0057L27.6499 18.1212ZM6.68505 32.0102L6.68262 32.0061L6.68505 32.0102ZM31.6884 9.80815L31.73 9.92356L31.721 10.0372L40.6267 11.2076L40.6531 11.211L40.6784 11.2034L43.4076 10.3505L43.375 10.1155L32.5164 9.81923L61.6196 1.45762L61.5715 1.22266L47.2167 3.00746L47.1993 3.00955L47.1834 3.01647L31.6884 9.80815Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.293 19.7573L25.2178 19.6495L25.1173 19.7344L22.8484 21.6457L22.6418 21.8197L22.9093 21.8565L26.9073 22.4089L27.1808 22.4467L27.0224 22.2207L25.293 19.7573ZM52.3893 19.9306L52.3467 19.8602L52.2659 19.8745L49.6383 20.3357L49.2737 20.3995L49.6061 20.562L53.7309 22.5763L54.0975 22.7555L53.8865 22.4061L52.3893 19.9306ZM44.1903 16.2268L44.2124 16.2185L43.9019 16.109L43.5588 15.9686L43.5744 15.9932L39.6059 14.5911L39.2766 14.4746L39.4645 14.7688L41.0691 17.2825L41.1214 17.3643L41.2122 17.3307L43.809 16.3679L45.3225 18.7835L45.3703 18.8594L45.4566 18.8354L48.1616 18.0841L48.5001 17.9898L48.1751 17.8567L44.1903 16.2268ZM22.577 30.7116L24.4207 28.688L24.585 28.5078L24.3421 28.4873L20.6469 28.1754L20.3818 28.1532L20.5402 28.3667L22.2699 30.7015L20.6438 32.7639L20.4487 32.7539L20.5478 32.8856L20.4615 32.9951L20.6376 33.0045L22.3891 35.3289L22.4838 35.4547L22.5801 35.3303L24.2308 33.193L24.4228 33.203L24.3251 33.0714L24.4096 32.9618L24.237 32.9528L22.577 30.7116Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.68359 32.0058L6.81495 32.2071L6.81669 32.2061L22.9906 21.8392L22.9972 21.8351L23.0031 21.8299L25.272 19.9186L25.1279 19.7266L6.68533 32.0047L6.68359 32.0058Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.243 13.256L34.9744 13.1738L35.0835 13.3412L32.5709 14.6831L32.301 14.8273L32.5969 14.9053L36.8275 16.0223L36.8587 16.0701L36.9138 16.0448L37.1737 16.1134L37.0801 15.9692L39.6159 14.8141L39.9046 14.6828L39.6014 14.5899L35.3327 13.2833L35.2963 13.2275L35.243 13.256ZM22.6761 26.1281L22.5898 26.0099L22.4907 26.1174L20.5492 28.2141L20.3818 28.3947L20.6272 28.4155L24.3223 28.7274L24.5843 28.7496L24.4294 28.5368L22.6761 26.1281ZM48.1827 17.8604L47.8108 17.6795L48.0274 18.0316L49.5569 20.5176L49.5998 20.5872L49.6802 20.573L52.3075 20.1117L52.6728 20.0476L52.3394 19.8854L48.1827 17.8604Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.889 1.59735L54.8432 1.36133L51.1301 1.9997L51.1256 2.00075L51.1208 2.00179L47.2021 3.01063L47.2576 3.24458L54.889 1.59735Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5811 35.1854L22.4774 35.0481L22.3842 35.1923L20.9508 37.4058L20.7685 37.4027L20.8683 37.533L20.7966 37.6436L20.955 37.6463L22.7072 39.9344L22.8119 40.0716L22.904 39.9257L24.3042 37.7063L24.483 37.7098L24.3846 37.5791L24.4539 37.4686L24.2993 37.4658L22.5811 35.1854ZM20.6337 23.7324L20.5225 23.8371L20.7273 23.8606L22.4812 26.2703L22.562 26.3812L22.6615 26.2866L24.7239 24.3202L24.9367 24.3448L24.84 24.2096L24.9502 24.1043L24.7481 24.0811L23.0236 21.6686L22.9436 21.5566L22.8434 21.6509L20.7515 23.6215L20.5349 23.5965L20.6337 23.7324Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.68555 32.0046L6.8117 32.2091L20.8549 23.8499L20.8664 23.8433L20.8757 23.8343L23.0081 21.8253L22.8608 21.6367L6.68555 32.0046Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.3473 19.89L52.1834 20.0557L53.6802 22.5312L53.7176 22.5932L53.7904 22.5891L56.2486 22.4408L56.6419 22.4172L56.3023 22.2172L52.3473 19.89ZM42.3669 13.6148L42.3145 13.5312L42.2223 13.5662L39.5226 14.5928L39.2162 14.7092L39.5254 14.8184L43.8158 16.3343L48.0835 18.0799L48.4245 18.2196L48.2315 17.906L46.6743 15.375L46.6265 15.297L46.5385 15.3223L43.9126 16.0813L42.3669 13.6148ZM22.8975 39.7882L22.781 39.6357L22.6947 39.8072L21.5479 42.089L21.4609 42.2623L21.655 42.263L24.5572 42.2737L24.8018 42.2748L24.6532 42.0807L22.8975 39.7882Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.7344 9.79778L31.7334 10.0383L43.3687 10.3554L43.3839 10.3561L43.3988 10.3526L46.0462 9.74716L46.0171 9.50977L31.7344 9.79778Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.9258 12.0162L37.6427 11.916L37.7567 12.0945L35.2046 13.2763L34.9194 13.4087L35.2199 13.5005L39.4882 14.8067L39.5169 14.8518L39.57 14.8317L39.8427 14.9152L39.7464 14.7648L42.216 13.8259L43.7572 16.2855L43.8054 16.3625L43.8923 16.3372L46.6056 15.5532L46.938 15.4572L46.6177 15.3262L42.5931 13.6825L42.6145 13.6745L42.3082 13.566L41.9654 13.426L41.9807 13.4503L38.0111 12.0463L37.9768 11.9926L37.9258 12.0162ZM21.6559 42.0226L21.4092 42.0215L21.5606 42.2163L23.3496 44.5189L23.4681 44.6714L23.5527 44.4974L24.6659 42.2059L24.7494 42.034L24.5584 42.0333L21.6559 42.0226ZM50.8653 17.4108L50.8219 17.3398L50.7401 17.355L48.1073 17.8503L47.7503 17.9175L48.0768 18.0766L52.2338 20.1016L52.6002 20.2801L52.3895 19.9311L50.8653 17.4108Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.68848 32.0018L6.80528 32.2118L18.8751 25.949L18.8935 25.9396L18.9074 25.9247L20.8807 23.8283L20.7317 23.6426L6.68848 32.0018Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.7923 28.3031L22.6257 26.3232L22.8322 26.3426L22.7334 26.2071L22.8315 26.101L22.6433 26.0834L20.89 23.6751L20.8048 23.5579L20.7053 23.6633L18.7722 25.717L18.5625 25.6973L18.663 25.8332L18.5636 25.9389L18.7549 25.9569L20.4846 28.2917L18.8269 30.3503L18.6277 30.3375L18.7292 30.4719L18.6426 30.5794L18.8186 30.5908L20.5521 32.8913L19.1319 35.0268L18.9451 35.0206L19.0474 35.154L18.9753 35.2622L19.134 35.2674L20.8727 37.5381L19.7266 39.7415L19.6376 39.9131L19.8309 39.9173L22.7996 39.98L23.0495 39.9855L22.8974 39.787L21.1617 37.5201L22.5484 35.3786L22.7317 35.3845L22.6312 35.2511L22.7009 35.143L22.5459 35.1378L20.8509 32.8885L22.4763 30.8275L22.6718 30.84L22.5723 30.7055L22.6568 30.5981L22.4843 30.587L20.7923 28.3031ZM46.6243 15.3284L46.2532 15.1481L46.4694 15.4996L48.0265 18.0305L48.0699 18.1009L48.1513 18.0856L50.7024 17.6057L52.0817 19.8871L51.8935 19.901L52.1989 20.0805L52.2214 20.1179L52.2574 20.1151L56.1802 22.4233L56.3445 22.2583L54.9496 19.9155L55.1402 19.9013L54.8342 19.7222L54.812 19.6847L54.7752 19.6875L51.0996 17.5308L51.1418 17.5232L50.8143 17.3641L46.6243 15.3284ZM40.7441 11.0237L40.6915 10.9395L40.5986 10.9755L37.8881 12.0329L37.5866 12.1504L37.8916 12.2581L42.225 13.7907L42.5498 13.9057L42.3668 13.6136L40.7441 11.0237Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.69434 31.9988L6.79416 32.2171L17.0747 28.1297L17.1035 28.1183L17.1232 28.0944L18.9122 25.9193L18.7642 25.7363L6.69434 31.9988Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M45.0892 12.7974L45.041 12.7188L44.9523 12.7454L42.231 13.5633L41.9053 13.6611L42.22 13.7896L46.5271 15.5488L46.8678 15.6881L46.6747 15.3745L45.0892 12.7974Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.7324 9.79844L31.7394 10.0389L31.7414 10.0386L46.0221 9.75061H46.0286L46.0356 9.74956L48.5028 9.4172L48.4823 9.17773L31.7345 9.79844H31.7324Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.8363 39.6772L19.584 39.672L19.7385 39.8713L21.5605 42.2158L21.6772 42.3662L21.7628 42.196L22.9096 39.9142L22.9952 39.7441L22.8046 39.7403L19.8363 39.6772ZM49.3131 14.844L49.2691 14.7716L49.1863 14.7882L46.5482 15.3191L46.1992 15.3895L46.5195 15.5451L50.7095 17.5808L51.0754 17.7586L50.8651 17.4103L49.3131 14.844ZM40.6878 10.9766L40.3426 10.8359L40.5405 11.152L42.1635 13.7415L42.212 13.8195L42.2997 13.7928L45.0213 12.9749L45.347 12.8769L45.032 12.7486L40.6878 10.9766Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.85464 32.1637L6.64775 32.041L4.58428 35.3438L4.58117 35.3486L4.57874 35.3538L2.96582 38.5682L3.17792 38.6812L6.85464 32.1637Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.8371 19.7268L53.3285 17.193L53.2897 17.1282L53.2145 17.1348L50.7514 17.3528L50.3771 17.3861L50.7012 17.5763L54.6597 19.8987L56.1381 22.3815L56.1707 22.4366L56.2345 22.44L58.5138 22.5693L58.9363 22.5932L58.5894 22.351L54.8371 19.7268ZM18.9162 25.7712L18.8247 25.6475L18.727 25.7664L16.9775 27.8933L16.7744 27.8777L16.8777 28.0146L16.7907 28.1199L16.9671 28.1334L18.7339 30.4783L17.2804 32.6055L17.0891 32.5965L17.1934 32.7331L17.121 32.8388L17.28 32.8464L19.0995 35.2225L19.2021 35.3562L19.2953 35.2162L20.762 33.0107L20.9495 33.0193L20.8466 32.8828L20.9169 32.7771L20.7617 32.7698L19.034 30.4773L20.6906 28.42L20.8899 28.4353L20.7884 28.2981L20.8733 28.1927L20.7007 28.1795L18.9162 25.7712ZM21.7504 42.0685L21.6173 41.8973L21.5428 42.1008L20.7631 44.2204L20.5901 44.2138L20.7097 44.3656L20.6757 44.4578L20.7856 44.4619L22.5732 46.7337L22.7087 46.9056L22.7811 46.699L23.5287 44.568L23.6978 44.5746L23.5796 44.4224L23.6118 44.3306L23.5051 44.3264L21.7504 42.0685ZM45.039 12.7518L44.6685 12.5723L44.8841 12.923L46.4696 15.5001L46.5136 15.5714L46.5958 15.5548L49.2338 15.0239L49.5839 14.9535L49.2626 14.7979L45.039 12.7518Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.70605 31.9939L6.77535 32.2233L15.479 30.3813L15.5255 30.3716L15.5525 30.3328L17.1294 28.0874L17.3609 27.7578L16.9866 27.9065L6.70605 31.9939Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.271 14.8027L49.1074 14.9687L50.6594 17.5351L50.6982 17.5992L50.7727 17.5926L53.2357 17.3746L53.6111 17.3413L53.286 17.1511L49.271 14.8027Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6495 1.4449L61.5539 1.22656L31.7041 9.80271L31.7419 10.0384L48.4918 9.41766L48.523 9.41663L48.5497 9.40034L61.6495 1.4449Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.2909 35.0777L19.1779 34.9301L19.0895 35.0937L17.8728 37.354L17.7812 37.5235L17.974 37.5311L21.0109 37.6475L21.2663 37.6576L21.1107 37.4545L19.2909 35.0777ZM43.4747 10.1735L43.4258 10.0938L43.3364 10.1218L40.6072 10.9747L40.288 11.0745L40.5975 11.2007L44.9417 12.9723L45.282 13.1113L45.0893 12.798L43.4747 10.1735Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.602 1.46213L61.5847 1.22266L54.8613 1.3599L54.8495 1.36025L54.8384 1.36267L47.207 3.00989L47.2472 3.24661L61.602 1.46213Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.9829 37.2905L17.7251 37.2808L17.8834 37.4846L19.6974 39.8187L18.9093 41.8618L18.7301 41.851L18.8531 42.0073L18.8185 42.0971L18.929 42.1037L20.7513 44.4194L20.8844 44.5885L20.9589 44.3865L21.7379 42.2686L21.9133 42.279L21.7917 42.1224L21.8246 42.0329L21.7172 42.0264L19.9763 39.7858L21.1221 37.583L21.2094 37.4146L21.0199 37.4073L17.9829 37.2905ZM57.2412 20.035L57.4326 20.043L57.1161 19.8222L57.0964 19.7882L57.0659 19.7872L53.3153 17.172L51.7783 14.5911L51.7392 14.5253L51.6629 14.5329L49.274 14.78L47.8198 12.3755L47.9973 12.3374L47.7141 12.2009L47.6885 12.1579L47.6448 12.1673L43.4243 10.128L43.0546 9.94922L43.2698 10.2993L44.7954 12.7793L44.62 12.8171L44.9028 12.9539L44.9291 12.9966L44.9718 12.9872L49.1538 15.0136L53.1379 17.3439L54.5304 19.6825L54.3415 19.6746L54.6572 19.8953L54.6774 19.929L54.7072 19.9303L58.4522 22.549L58.6244 22.3896L57.2412 20.035ZM17.1269 27.9468L17.0274 27.8147L16.9325 27.9499L15.3944 30.1402L15.1982 30.1284L15.305 30.2677L15.2322 30.3713L15.392 30.381L17.2465 32.8032L17.3477 32.9352L17.4412 32.798L18.9429 30.6001L19.1349 30.6119L19.0299 30.4725L19.1006 30.3689L18.9446 30.3596L17.1269 27.9468Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.1563 32.6184L14.2298 32.6222L14.2665 32.5585L15.5585 30.3234L15.6934 30.0898L15.4296 30.1456L6.72559 31.9876L6.74397 32.2254L14.1563 32.6184Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.3197 10.3446L47.545 12.3862L49.1073 14.9695L49.1468 15.0347L49.2224 15.0271L51.6109 14.78L53.0104 17.13L52.8281 17.1255L53.1351 17.3397L53.1556 17.3744L53.1861 17.3751L56.9352 19.9892L58.4168 22.5115L58.4445 22.5583L58.4975 22.5687L60.5783 22.9738L60.6778 22.7631L57.1186 19.8267L55.716 17.4392L55.9007 17.4437L55.593 17.2298L55.5725 17.1948L55.5417 17.1942L52.0095 14.7387L52.0535 14.7342L51.7624 14.5644L50.199 11.9392L50.1588 11.8719L50.0815 11.8813L47.6923 12.1651L46.122 9.56866L46.0773 9.49414L45.9924 9.51354L43.345 10.1194L43.0112 10.1956L43.3197 10.3446ZM17.437 32.6569L17.3258 32.5117L17.2367 32.6715L16.0196 34.8538L15.8311 34.8434L15.944 34.989L15.8893 35.0874L16.0265 35.095L17.8408 37.4298L17.0201 39.4572L16.8344 39.4427L16.9612 39.6035L16.9259 39.6912L17.0368 39.6998L18.895 42.061L19.0257 42.227L19.1016 42.0301L19.9136 39.9254L20.0956 39.94L19.9701 39.7785L20.0037 39.6915L19.8959 39.6832L18.1212 37.399L19.2648 35.2749L19.4495 35.2853L19.3379 35.1394L19.3906 35.0413L19.2569 35.0337L17.437 32.6569ZM20.9401 44.2707L20.7835 44.0718L20.7284 44.3189L20.2813 46.3217L20.1063 46.3057L20.2456 46.4808L20.2283 46.5584L20.3135 46.566L22.1358 48.8572L22.2956 49.0582L22.3475 48.8066L22.7638 46.7899L22.935 46.8055L22.7967 46.6301L22.8126 46.5528L22.7301 46.5455L20.9401 44.2707Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.1643 34.7523L13.2714 34.7945L13.3182 34.6895L14.2719 32.5484L14.343 32.3887L14.1683 32.3793L6.75631 31.9863L6.70605 32.2185L13.1643 34.7523Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.3372 20.4377L59.5337 20.4727L59.1961 20.1948L59.1788 20.1653L59.1549 20.1612L55.5793 17.2188L55.3991 17.3727L56.7899 19.7401L56.5962 19.7054L56.9327 19.9834L56.95 20.0129L56.9736 20.017L60.5248 22.9469L60.7054 22.794L59.3372 20.4377ZM46.0799 9.52539L45.9167 9.6914L47.5269 12.3541L47.5667 12.4203L47.6436 12.4113L50.1098 12.1181L50.4671 12.0758L50.1562 11.8949L46.0799 9.52539ZM15.5492 30.1898L15.44 30.0466L15.3499 30.2026L14.0579 32.4376L13.9619 32.604L14.1536 32.6178L17.3333 32.8483L17.5998 32.8677L17.4373 32.6553L15.5492 30.1898Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.86006 32.154L6.64519 32.0469L2.96847 38.5648L2.96189 38.5765L2.95772 38.5894L1.15039 44.6709L1.37601 44.753L6.86006 32.154Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.0839 41.9118L18.9307 41.717L18.8725 41.9579L18.3693 44.047L18.3374 44.179L18.4722 44.1946L20.832 44.4639L21.1183 44.4968L20.9401 44.2702L19.0839 41.9118ZM14.1706 32.3791L13.9014 32.3594L14.067 32.5725L15.9929 35.0511L16.1041 35.1946L16.1929 35.0359L17.4467 32.7881L17.5386 32.6234L17.3504 32.6096L14.1706 32.3791Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.5893 1.46099L61.5914 1.22082L58.3562 1.09606L58.3493 1.0957L58.3423 1.0964L54.8545 1.35842L54.8659 1.59859L61.5893 1.46099Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.1623 14.7941L54.3405 14.7955L54.0414 14.5883L54.0203 14.5522L53.9891 14.5519L50.1855 11.9162L48.5899 9.23625L48.5494 9.16797L48.4704 9.17871L46.0031 9.51143L45.6559 9.55819L45.9588 9.73425L50.0084 12.0885L51.4623 14.53L51.2865 14.5287L51.585 14.7359L51.6061 14.7719H51.6369L55.4301 17.409L59.0098 20.3541L60.4972 22.9159L60.5191 22.9533L60.56 22.9682L62.4193 23.6499L62.5444 23.4507L59.1983 20.2006L57.6797 17.5857L57.6512 17.5362L57.5947 17.5271L55.5777 17.2034L54.1623 14.7941ZM18.4996 43.956L18.2113 43.923L18.3922 44.15L20.2526 46.4894L20.1306 48.4336L20.124 48.5414L20.2304 48.5598L22.2096 48.9001L22.5201 48.9538L22.324 48.707L20.5056 46.4207L20.963 44.3708L20.9921 44.2405L20.8594 44.2252L18.4996 43.956ZM16.1828 34.904L16.0574 34.7422L15.9773 34.9307L15.0957 37.007L14.9033 36.9882L15.0336 37.1539L14.9972 37.2391L15.1092 37.2499L17.0042 39.6578L17.1324 39.8207L17.2101 39.6287L18.056 37.5386L18.2446 37.5573L18.1156 37.3913L18.1499 37.3064L18.0415 37.2956L16.1828 34.904Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.5234 36.7667L12.6617 36.8748L12.7123 36.7064L13.3233 34.6745L13.3552 34.5684L13.2522 34.5282L6.7936 31.9941L6.67578 32.2007L12.5234 36.7667Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.3771 48.341L20.4866 46.595L20.673 46.6317L20.498 46.4117L20.5018 46.3531L20.4419 46.341L18.6165 44.0463L19.0806 42.1201L19.2677 42.1454L19.1197 41.9576L19.1374 41.8852L19.0535 41.8737L17.3827 39.7503L17.1928 39.5091L17.0431 39.3188L15.3099 37.1168L16.1632 35.1074L16.3586 35.1302L16.2259 34.9594L16.2609 34.8769L16.1517 34.8641L14.2567 32.4253L14.1333 32.2669L14.0519 32.4499L13.1342 34.5102L12.9346 34.487L13.0687 34.6575L13.0316 34.7404L13.1442 34.7535L15.0396 37.1615L14.482 39.1549L14.4456 39.2849L14.579 39.306L16.9489 39.6813L16.4709 41.5209L16.2796 41.4949L16.429 41.6824L16.4099 41.7552L16.4962 41.767L18.3622 44.1129L18.2197 45.9019L18.0287 45.8641L18.2052 46.0838L18.2003 46.1431L18.2623 46.1552L19.9581 48.2689L20.1567 48.5164L20.3507 48.7586L22.042 50.8661L22.2409 51.1142L22.2558 50.7968L22.3497 48.7874L22.3546 48.6813L22.2499 48.6633L20.3771 48.341ZM53.8606 14.7556L53.8765 14.7826L55.3986 17.3739L55.4274 17.4228L55.4832 17.4318L57.5002 17.7555L58.9984 20.3353L59.021 20.3738L59.0379 20.3797L62.3764 23.6232L62.5643 23.4769L61.0591 20.8611L61.0369 20.8222L61.0196 20.8164L57.9331 17.8251L57.9955 17.8352L57.6687 17.5666L56.2477 15.1202L56.4286 15.1458L56.1122 14.8869L56.0939 14.8554L56.0693 14.8519L52.7031 12.0978L52.7505 12.0974L52.4598 11.8968L52.4383 11.8597L50.8618 9.17658L50.8261 9.11523L50.755 9.11731L48.4829 9.17761L48.1149 9.18732L48.3963 9.38174L49.8848 11.8815L49.7157 11.8829L50.0054 12.0839L50.0272 12.1207H50.0581L53.8606 14.7556Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.18832 38.6579L2.96063 38.582L1.81316 41.6893L1.81039 41.697L1.80866 41.7049L1.14844 44.6788L1.38099 44.7391L3.18832 38.6579Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.6172 39.0684L14.3115 39.0199L14.5042 39.2618L16.4668 41.729L16.6165 41.9175L16.6772 41.6846L17.2154 39.6136L17.2487 39.4853L17.1177 39.4645L14.6172 39.0684ZM61.046 20.8418L60.8512 20.9808L62.3564 23.5966L62.3723 23.6237L62.3993 23.64L64.0095 24.5975L64.1616 24.4152L61.046 20.8418Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6572 1.43826L61.5241 1.23828L48.4242 9.19372L48.0371 9.42868L48.4897 9.41656L50.7615 9.35626L50.7989 9.35522L50.8294 9.33339L61.6572 1.43826Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.3447 48.3661L20.0803 48.0362L18.4528 46.0081L18.5952 44.2226L18.7921 44.2666L18.6101 44.0375L18.6146 43.9807L18.554 43.9672L16.6551 41.58L16.4701 41.3475L16.4413 41.6431L16.2639 43.4577L16.0625 43.4127L16.2458 43.6414L16.2403 43.6986L16.303 43.7125L18.2136 46.094L18.4067 47.8889L18.415 47.9662L18.4888 47.9901L20.1409 48.5318L20.3367 50.126L20.1405 50.0671L20.3631 50.3426L20.3686 50.3863L20.4081 50.3984L22.2993 52.7408L22.5707 53.0767L22.5121 52.649L22.2806 50.9602L22.4719 51.0174L22.2508 50.7418L22.2449 50.6982L22.2068 50.6867L20.5977 48.6815L20.3447 48.3661ZM62.6334 21.7498L59.5195 18.1847L59.5025 18.1553L59.4852 18.1494L56.1155 14.8916L54.6589 12.3839L54.8326 12.4054L54.5265 12.1558L54.5075 12.1233L54.4825 12.1201L50.8349 9.14453L50.655 9.2984L52.1432 11.8315L51.9723 11.8103L52.2777 12.0602L52.2967 12.0928L52.321 12.0955L55.927 15.0455L57.472 17.7065L57.4949 17.746L57.5122 17.7515L60.8597 20.9957L60.8673 21.0092L60.8781 21.0151L63.9805 24.5737L64.1753 24.435L62.6604 21.7845L62.6442 21.7561L62.6334 21.7498ZM13.3032 34.5659L13.16 34.3843L13.0935 34.6057L12.5133 36.5347L12.3047 36.4973L12.4641 36.6976L12.4437 36.7666L12.5317 36.7825L14.5043 39.2622L14.6506 39.4462L14.714 39.2199L15.2585 37.2732L15.4623 37.3096L15.3046 37.109L15.3236 37.0404L15.2384 37.0251L13.3032 34.5659Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6678 1.42957L61.5153 1.24414L50.6876 9.13927L50.4346 9.324L50.7461 9.35623L52.8037 9.56901L52.8578 9.57457L52.8976 9.53749L61.6678 1.42957Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.0884 19.0462L57.9558 15.4662L57.9385 15.4357L56.3672 12.7051L56.3429 12.6631L56.2964 12.6496L54.5137 12.1325L52.9205 9.3898L52.89 9.33677L52.829 9.33055L50.7715 9.11776L50.3715 9.07617L50.6831 9.3302L54.3411 12.3141L55.9163 15.0264L55.9398 15.0669L55.9571 15.0725L59.3261 18.3291L59.3341 18.3434L59.3448 18.3492L62.458 21.9137L63.967 24.5538L63.9764 24.5698L63.9899 24.5822L65.32 25.8164L65.4988 25.6573L62.6573 21.7778L61.1162 19.0812L61.0995 19.0521L61.0884 19.0462ZM18.564 47.7613L18.1776 47.6348L18.433 47.9512L20.3721 50.3526L20.9529 52.0213L20.9689 52.0667L20.9889 52.0771L22.9148 54.4531L23.1214 54.3367L22.5066 52.6243L22.4906 52.5796L22.4709 52.5696L20.5939 50.2448L20.3707 48.426L20.3613 48.3505L20.2889 48.3266L18.564 47.7613ZM14.6929 39.112L14.513 38.8861L14.4794 39.173L14.2697 40.9651L14.0576 40.9128L14.2482 41.1505L14.2416 41.206L14.3051 41.2216L16.2545 43.651L16.4164 45.4209L16.4233 45.4975L16.4957 45.5235L18.2895 46.1591L18.6881 46.3002L18.4233 45.9706L16.495 43.5671L16.6673 41.8042L16.8749 41.8555L16.6856 41.6174L16.6912 41.5626L16.6298 41.5474L14.6929 39.112Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6799 1.41784L61.5049 1.25391L52.7343 9.36183L52.5742 9.50982L52.7849 9.56632L54.6044 10.0546L54.68 10.0747L54.7292 10.0141L61.6799 1.41784Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.8996 9.36328L52.7121 9.51023L54.3295 12.2949L54.3538 12.3365L54.3999 12.35L56.2287 12.8802L56.3455 12.6782L52.8996 9.36328ZM16.5757 45.2967L16.1747 45.1546L16.4419 45.4856L18.419 47.934L18.9718 49.5768L18.9867 49.6218L19.0283 49.6443L20.4208 50.3988L20.5716 50.2179L18.6419 47.8273L18.4488 46.0328L18.4405 45.9576L18.3695 45.9323L16.5757 45.2967ZM12.6913 36.5968L12.5167 36.3767L12.4782 36.655L12.2356 38.4239L12.0117 38.3636L12.2099 38.6104L12.2027 38.6641L12.2668 38.6814L14.2793 41.1895L14.4592 41.4137L14.4924 41.1282L14.7014 39.3392L14.9201 39.3985L14.7232 39.151L14.7295 39.0976L14.6678 39.081L12.6913 36.5968ZM63.9776 22.9844L63.9682 22.9677L63.963 22.9632L61.108 19.0696L60.907 19.2002L62.4517 21.9031L62.461 21.9194L62.4662 21.9242L65.3043 25.7992L65.506 25.6689L63.9776 22.9844Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M67.1101 2.92706L67.1818 2.69764L64.5729 1.75359L64.5632 1.75012L64.5531 1.74841L61.6077 1.22266L61.5557 1.45728L67.1101 2.92706Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.5406 16.3249L59.5233 16.2951L59.5122 16.2892L56.3605 12.6946L54.7403 9.87846L54.7153 9.83514L54.6672 9.8223L52.8473 9.33398L52.733 9.53673L56.1668 12.8395L57.753 15.5964L57.77 15.6259L57.7811 15.6318L60.921 19.2201L61.1157 19.0812L59.5406 16.3249ZM19.1431 49.4331L18.9923 49.6144L20.9726 52.0577L21.1795 51.9423L20.5917 50.2538L20.5765 50.2098L20.5356 50.1876L19.1431 49.4331ZM14.4669 41.0393L14.2246 40.7374L14.253 41.1235L14.3854 42.9042L14.391 42.9804L14.4623 43.0078L16.3276 43.7165L16.7403 43.8731L16.4641 43.5287L14.4669 41.0393Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.7055 36.7229L12.7471 36.6355L12.6709 36.5763L6.82361 32.0103L6.65517 31.8789L6.63021 32.091L3.99805 54.4556L4.22609 54.5215L12.7055 36.7229Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5484 42.7827L14.1328 42.625L14.4122 42.9709L16.4423 45.4856L16.6919 45.7944L16.6555 45.3989L16.4905 43.593L16.4836 43.5181L16.4136 43.4914L14.5484 42.7827Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.8695 32.119L6.64008 32.057L1.156 44.6561L1.13867 44.696L1.15081 44.7379L4.00269 54.5031L4.23731 54.4837L6.8695 32.119ZM61.6911 1.40149L61.4932 1.26562L54.5425 9.8622L54.4482 9.97901L54.5827 10.0455L56.137 10.8128L56.2379 10.8624L56.2944 10.765L61.6911 1.40149Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.4184 20.2456L62.4094 20.23L62.4042 20.2252L59.5371 16.3194L57.9335 13.5143L57.9159 13.4834L57.9048 13.4775L54.7264 9.85938L54.5316 9.99869L56.1581 12.8253L56.1757 12.8558L56.1872 12.8617L59.3378 16.4552L60.9071 19.2007L60.9168 19.2177L60.922 19.2226L63.7721 23.1097L65.297 25.7883L65.2991 25.7921L65.3015 25.7959L66.3173 27.3059L66.4438 27.2248L66.5214 27.179L64.9768 24.463L64.9747 24.4588L64.9737 24.4574L62.4184 20.2456ZM19.9356 51.0935L20.9729 52.0597L21.9218 53.5413L21.9301 53.5545L21.9353 53.5593L23.8955 55.9742L24.0896 55.8335L23.1088 54.3124L23.1005 54.2996L23.0956 54.2951L21.1594 51.9065L19.193 49.4802L18.6402 47.8375L18.6253 47.7942L18.6052 47.7828L16.6291 45.3349L16.4211 45.4476L16.9514 47.0837L16.9659 47.1281L16.9867 47.1402L18.9881 49.6095L19.9159 51.0699L19.9242 51.0827L19.9356 51.0935ZM12.43 38.501L12.1954 38.2085L12.2162 38.5828L12.3032 40.1479L12.0488 40.045L12.316 40.3756L12.3181 40.413L12.36 40.43L14.4117 42.9714L14.6536 43.2708L14.6249 42.8868L14.5074 41.2988L14.7552 41.399L14.49 41.0687L14.4873 41.0313L14.4471 41.015L12.43 38.501Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.6981 1.38591L61.4821 1.28125L56.0858 10.6448L56.0352 10.7325L56.1128 10.7973L57.3694 11.8463L57.497 11.9527L57.5583 11.7985L61.6981 1.38591Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.8295 17.4531L60.8194 17.4355L60.8142 17.4306L57.9305 13.5075L56.2947 10.6455L56.2766 10.6137L56.2437 10.5974L54.6894 9.83008L54.5459 10.0172L57.7312 13.6433L59.332 16.4439L59.342 16.4613L59.3472 16.4661L62.2182 20.3768L62.4195 20.2461L60.8295 17.4531ZM20.0996 50.9165L19.9246 51.0805L21.9298 53.5515L22.1246 53.4108L21.1677 51.9164L21.1594 51.9036L21.1483 51.8935L20.0996 50.9165ZM14.5989 42.8194L14.3906 42.9306L14.89 44.5387L14.9039 44.583L14.925 44.5955L16.9725 47.1216L17.1805 47.009L16.6502 45.3725L16.636 45.3292L16.6156 45.3171L14.5989 42.8194Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.38606 44.6996L1.14589 44.6992L1.00068 47.5064L1 47.5185L1.00171 47.531L1.40374 50.1296L1.64251 50.1053L1.38606 44.6996Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M63.401 21.6921L63.3986 21.6879L63.3975 21.6865L60.8274 17.4512L60.6201 17.5732L62.2102 20.3661L62.2126 20.3703L62.2136 20.3717L64.7696 24.5849L64.9768 24.4632L63.401 21.6921ZM17.159 46.9713L16.9639 47.111L17.8691 48.5482L17.8774 48.5611L17.8882 48.5714L19.0031 49.6264L19.1791 49.4635L17.159 46.9713Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.0596 6.14135L71.1885 5.94034L67.2175 2.71727L67.1974 2.70099L67.1725 2.69441L61.6181 1.22461L61.5342 1.44885L71.0596 6.14135Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.2067 14.6043L59.1963 14.586L59.1911 14.5815L56.2865 10.6348L56.0855 10.7658L57.7248 13.6336L57.7352 13.652L57.7404 13.6565L60.6276 17.5848L60.829 17.4542L59.2067 14.6043ZM18.0533 48.3966L17.8773 48.5599L19.9241 51.0815L20.1189 50.9411L19.1869 49.4748L19.179 49.462L19.1682 49.4519L18.0533 48.3966ZM12.5273 40.2551L12.3184 40.3646L12.7859 41.9432L12.799 41.9876L12.8382 42.0115L14.4421 42.9982L14.5984 42.8204L12.5273 40.2551Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.7029 1.37311L61.4752 1.29688L57.3354 11.7098L57.3115 11.7694L57.3483 11.8224L58.2722 13.1567L58.4188 13.3688L58.4871 13.12L61.7029 1.37311Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.9639 41.807L12.8076 41.9852L14.9109 44.5803L15.1192 44.4687L14.6198 42.8606L14.6063 42.8176L14.5678 42.7937L12.9639 41.807ZM61.7923 18.864L61.7899 18.8598L61.7889 18.8584L59.2049 14.6016L58.9976 14.7236L60.6203 17.5734L60.6227 17.5775L60.6234 17.5789L63.1935 21.8139L63.4008 21.6923L61.7923 18.864Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.4431 38.6303L12.4528 38.6119L12.4556 38.5915L12.7169 36.6874L12.4892 36.6191L4.00977 54.4178L4.22499 54.5248L12.4431 38.6303Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.0978 44.4281L14.9023 44.5674L15.7802 45.9734L15.7882 45.9863L15.7934 45.9915L17.8776 48.5595L18.0724 48.4195L17.1671 46.9826L17.1595 46.9702L17.1543 46.965L15.0978 44.4281ZM57.5511 11.6953L57.5404 11.6765L57.5238 11.6627L56.2671 10.6133L56.0931 10.7769L59.0057 14.7346L59.2067 14.604L57.5511 11.6953Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.7049 1.36565L61.471 1.31055L58.2548 13.0574L58.2451 13.0931L58.2573 13.1278L58.8086 14.749L58.9528 15.1729L59.0405 14.7338L61.7049 1.36565Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.1509 15.9757L60.1481 15.9712L60.1471 15.9698L57.5492 11.6914L57.342 11.8134L58.9979 14.7218L59.0003 14.7263L59.0013 14.7277L61.585 18.9842L61.7922 18.8622L60.1509 15.9757ZM12.9943 41.832L12.7988 41.9709L13.6472 43.3447L13.6552 43.3572L13.6656 43.3676L14.9205 44.5889L15.0976 44.427L12.9943 41.832Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.5379 40.3906L12.5563 40.3594L12.5542 40.323L12.4565 38.5687L12.4322 38.1289L12.2299 38.5202L4.01172 54.4148L4.22208 54.5308L12.5379 40.3906Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.8333 43.1953L13.6562 43.3572L15.7883 45.9845L15.9838 45.8452L15.1063 44.4391L15.0983 44.4267L15.0879 44.4166L13.8333 43.1953Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.38117 44.6707L1.14551 44.7102L1.40196 50.116L1.4037 50.1461L1.41895 50.1717L4.01439 54.5316L4.23307 54.4362L1.38117 44.6707ZM71.0358 6.12767L71.1987 5.95161L69.4433 4.11065L69.433 4.0999L69.4201 4.09159L67.2045 2.70949L67.0649 2.90494L71.0358 6.12767ZM61.7049 1.36134L61.4685 1.31836L58.8041 14.6861L58.8006 14.7021L58.802 14.7184L58.9396 16.6262L59.1781 16.6369L61.7049 1.36134Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.4746 13.0288L58.4722 13.0243L58.4691 13.0198L57.5455 11.6855L57.3438 11.8165L59.9434 16.0977L60.1506 15.976L58.4746 13.0288Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.0001 41.9763L13.033 41.9288L13.0167 41.8734L12.5492 40.2948L12.4709 40.0293L12.3305 40.2681L4.01465 54.408L4.21668 54.538L13.0001 41.9763Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.208 64.5195L13.0361 64.6873L15.172 67.0914L15.1796 67.0997L15.1887 67.1066L17.6351 68.984L17.7903 68.8007L13.208 64.5195Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.7055 1.36108L61.4685 1.32227L61.4681 1.32263L58.9413 16.5979L58.941 16.6L58.6207 18.7896L58.6204 18.7917L58.8581 18.8284L58.8585 18.8263L61.7055 1.36144V1.36108ZM13.8412 43.3608L13.8987 43.2939L13.8523 43.219L13.0039 41.8452L12.9079 41.6896L12.8029 41.8397L4.01953 54.401L4.20944 54.5483L13.8412 43.3608Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M78.6265 20.4102L78.4026 20.493L79.1069 23.3917L79.0736 23.3982L79.1398 23.5268L79.1637 23.6252L79.1876 23.6204L80.6446 26.4546L80.8681 26.3707L80.1341 23.4284L80.166 23.4218L80.1001 23.2932L80.0759 23.1948L80.0523 23.1997L78.6265 20.4102ZM11.0274 62.0664L10.8555 62.2341L13.0364 64.6885L13.0444 64.6975L13.0489 64.7006L17.6264 68.9776L17.7986 68.8099L15.6319 66.3621L15.6239 66.3531L15.6194 66.3496L11.0274 62.0664Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.62628 50.0488L1.41211 50.1573L2.39808 52.4966L2.40466 52.5119L2.41507 52.5247L4.02418 54.5448L4.22172 54.4086L1.62628 50.0488Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.1285 17.4785L76.9043 17.5607L77.605 20.5335L77.6283 20.6312L77.7284 20.626L78.5259 20.5848L78.7113 20.5751L78.6267 20.4098L77.1285 17.4785Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.0728 44.506L15.1688 44.4173L15.0724 44.3293L13.8314 43.1932L13.7399 43.1094L13.6591 43.2033L4.02734 54.3911L4.19995 54.5578L15.0728 44.506Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.79984 59.5586L8.62793 59.7263L10.8553 62.233L10.8633 62.2421L10.873 62.2493L13.2556 64.0359L13.4099 63.8519L8.79984 59.5586Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.7454 18.3907L71.9683 18.3214L71.2322 6.02735L71.228 5.95767L71.1653 5.92683L61.6399 1.23397L61.2715 1.05273L61.4839 1.40414L71.7454 18.3907Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.4178 63.8614L13.4099 63.8521L13.3998 63.8448L11.0175 62.0582L10.8633 62.2423L15.4553 66.5258L17.6182 68.9695L17.6348 68.9885L17.6581 68.9989L20.7598 70.4313L20.8815 70.2254L15.623 66.3525L13.4178 63.8614ZM77.7156 20.3861L77.5298 20.3957L77.6147 20.5611L79.1459 23.5398L79.3698 23.4563L78.6361 20.4366L78.6129 20.3396L78.513 20.3448L77.7156 20.3861ZM75.5977 14.4844L75.3735 14.5658L76.0396 17.4891L76.0593 17.575L76.147 17.5823L77.011 17.653L77.2262 17.6707L77.1282 17.4787L75.5977 14.4844Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.7056 45.3786L16.8608 45.265L16.6903 45.1755L15.0465 44.313L14.9713 44.2734L14.909 44.331L4.03613 54.3828L4.18896 54.5678L16.7056 45.3786Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.52445 56.9984L6.35254 57.1662L8.62811 59.727L8.63642 59.7363L8.64093 59.7398L13.2457 64.0282L13.4177 63.8608L11.1556 61.3049L11.1473 61.2955L11.1424 61.2921L6.52445 56.9984ZM79.3595 23.4293L79.1325 23.4858L79.1772 26.9858L79.1793 27.1615L79.3422 27.096L80.7961 26.5107L80.9184 26.4614L80.8581 26.3443L79.3595 23.4293ZM76.1666 17.3419L75.951 17.3242L76.0498 17.5169L77.6146 20.5608L77.8385 20.478L77.1377 17.5051L77.1179 17.4202L77.0306 17.4129L76.1666 17.3419Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.8521 18.4486L72.0563 18.4413L71.9506 18.2663L61.6894 1.27968L61.5203 1L61.4676 1.32266L58.6202 18.7896L58.5967 18.9345L58.7433 18.9293L71.8521 18.4486Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.3991 63.8443L13.238 64.0207L15.4518 66.5219L15.4688 66.5413L15.4809 66.5465L20.739 70.4194L20.9009 70.2433L18.7116 67.747L18.6943 67.7276L18.6822 67.722L13.3991 63.8443ZM79.253 26.8736L79.1299 26.9232L79.1913 27.0407L80.7283 29.9934L80.955 29.9352L80.8718 26.3975L80.868 26.2238L80.7068 26.2886L79.253 26.8736ZM74.0344 11.4258L73.8098 11.5062L74.4402 14.3768L74.4572 14.4534L74.5338 14.469L75.4671 14.6572L75.7114 14.7064L75.5981 14.4846L74.0344 11.4258ZM4.19987 54.3827L4.02832 54.5508L6.35307 57.167L6.36174 57.1767L6.37215 57.1843L8.68304 58.8704L8.83587 58.6853L4.19987 54.3827Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.7506 45.9354L18.996 45.7898L18.7205 45.7157L16.666 45.1653L16.6103 45.1504L16.5635 45.1844L4.04688 54.3736L4.17927 54.5739L18.7506 45.9354Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.84413 58.6934L8.83546 58.6837L8.82471 58.6761L6.51382 56.99L6.36133 57.1751L10.9842 61.4732L11.1561 61.3055L8.84413 58.6934ZM77.8289 20.4512L77.6019 20.5063L77.6064 23.9667L77.6067 24.1303L77.7627 24.0811L79.2893 23.5994L79.4251 23.5564L79.36 23.4299L77.8289 20.4512Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.8088 11.2089L73.0399 11.1441L72.4473 8.32892L72.4439 8.31264L72.4362 8.29774L71.2188 5.97852L70.999 6.07417L72.8088 11.2089Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M74.6577 14.248L74.0886 11.6553L74.1635 11.6782L74.0412 11.4384L74.0294 11.3854L74.011 11.3794L72.4362 8.29883L72.2116 8.37853L72.762 10.9937L72.684 10.9695L72.8074 11.2093L72.8188 11.2627L72.8379 11.2689L74.3361 14.1828L74.4362 14.3779L74.3558 17.7192L74.3524 17.863L74.4945 17.8408L76.0349 17.6021L76.0003 20.7835L75.941 20.7974L75.9989 20.908L75.9975 21.031L76.0557 21.0175L77.5533 23.8943L77.6195 24.0211L79.1717 27.003L78.4505 30.8773L78.3906 30.904L78.4311 30.9802L78.3968 31.1649L78.5036 31.1168L80.0343 33.9991L80.1989 34.3093L80.2589 33.9631L80.942 30.0206L80.9999 29.9942L80.9597 29.9173L80.9915 29.7347L80.8886 29.7807L79.4171 26.9541L79.3727 23.4826L79.3706 23.3207L79.2164 23.3693L77.8461 23.8018L77.842 20.6009L77.8988 20.5877L77.8416 20.4765V20.3541L77.7855 20.3673L76.3399 17.5549L76.2706 17.4201L75.6076 14.5117L75.5903 14.4358L75.5141 14.4206L74.6577 14.248ZM13.2552 64.0399L13.2673 64.0451L18.5389 67.9145L18.5562 67.9343L20.7195 70.4007L20.7448 70.4295L20.7819 70.4385L24.5598 71.3413L24.5983 71.2433L24.6787 71.1459L22.4773 68.5976L22.4516 68.5678L22.4301 68.5629L16.4598 65.1794L16.4557 65.1745L14.1853 62.586L14.167 62.5652L14.1545 62.56L8.83503 58.683L6.47976 56.0221L6.47075 56.012L6.46 56.0044L4.18792 54.3724L4.03613 54.5582L8.66729 58.8566L10.9754 61.4642L10.9938 61.4846L13.2375 64.0198L13.2552 64.0399Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.1747 54.3633L4.04785 54.5671L6.31995 56.199L6.33207 56.2077L6.34629 56.2133L9.31776 57.3794L9.41826 57.1615L4.1747 54.3633Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.46027 56.0056L6.2998 56.1824L8.66339 58.853L8.68211 58.8742L8.69457 58.879L14.0241 62.7633L14.1852 62.587L11.8452 59.9191L11.8265 59.8976L11.8137 59.8924L6.46027 56.0056ZM77.8329 23.9112L77.6752 23.6086L77.6083 23.9431L76.8195 27.8887L76.7762 28.1046L76.9813 28.0242L79.3414 27.0972L79.4659 27.0483L79.4038 26.9298L77.8329 23.9112ZM74.4576 17.6044L74.292 17.63L74.3693 17.7787L76.0127 20.9356L76.2397 20.8815L76.2771 17.464L76.2785 17.3223L76.1385 17.3438L74.4576 17.6044Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.7279 18.335L71.9671 18.3457L73.0408 11.1864L73.0453 11.1566L73.0352 11.1285L71.2254 5.99414L70.9922 6.04127L71.7279 18.335Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.1542 62.5613L14.0048 62.745L16.2928 65.354L16.3191 65.3841L16.34 65.3883L22.3092 68.7715L24.4967 71.3038L24.5297 71.342L24.5803 71.3451L29.0437 71.6289L29.0984 71.3984L22.4593 68.5774L20.226 65.9923L20.1997 65.9618L20.1782 65.957L14.1542 62.5613ZM76.8934 27.8L76.768 27.8492L76.8314 27.9685L78.4259 30.9708L76.8775 35.2166L76.7836 35.4744L77.0369 35.3687L80.1868 34.0542L80.3088 34.0036L80.2468 33.8871L78.6831 30.9423L79.4157 27.0071L79.4552 26.794L79.2535 26.873L76.8934 27.8ZM73.0289 11.1146L72.8192 10.707L72.8018 11.1649L72.6785 14.3817L72.6043 14.3879L72.6733 14.5203L72.6695 14.6236L72.7249 14.6194L74.3694 17.7784L74.596 17.7257L74.6751 14.4621L74.7468 14.4562L74.6782 14.3231L74.6806 14.2202L74.6276 14.2247L73.0289 11.1146ZM76.2259 20.8243L76.072 20.5284L76.0021 20.8548L75.1627 24.7662L75.1183 24.9731L75.3186 24.9055L77.7647 24.0803L77.8978 24.0356L77.833 23.9109L76.2259 20.8243ZM9.45159 57.1896L9.43253 57.1674L9.40516 57.1566L6.43369 55.9908L6.31934 56.1998L11.6845 60.0952L11.8453 59.9188L9.45159 57.1896Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.8731 18.4467L71.844 18.209L58.7351 18.6893L58.6519 18.6925L58.6256 18.7715L57.8025 21.2397L57.7363 21.4387L57.9415 21.3953L71.8731 18.4467Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.8141 59.892L11.665 60.076L14.0047 62.7439L14.0318 62.7747L14.0529 62.7789L20.0582 66.1641L22.296 68.7544L22.3292 68.7932L22.3594 68.7946L29.0045 71.6184L29.1434 71.4302L26.9424 68.8261L26.9088 68.7863L26.8776 68.7845L20.2079 65.9697L17.9234 63.3251L17.8963 63.2935L17.8745 63.2894L11.8141 59.892ZM75.242 24.6763L75.1078 24.7217L75.1744 24.8469L76.8317 27.967L76.9894 28.2647L77.0555 27.9344L77.8443 23.9887L77.8852 23.785L77.688 23.8515L75.242 24.6763ZM76.9443 35.1454L76.8213 35.1971L76.8854 35.3142L78.5222 38.3137L78.6518 38.5511L78.7412 38.2961L80.2543 33.982L80.3438 33.7273L80.0946 33.8312L76.9443 35.1454ZM74.5828 17.666L74.4324 17.377L74.3592 17.6948L73.4672 21.5701L73.4218 21.7679L73.6173 21.7125L76.1524 20.9944L76.2945 20.9542L76.2262 20.8232L74.5828 17.666Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.14783 54.3537L4.06152 54.5761L9.30507 57.3743L9.3276 57.3865L9.3529 57.3882L17.5062 57.9791L17.5444 57.7427L4.14783 54.3537ZM57.8916 21.1595L57.8351 21.1717L57.8091 21.2233L56.4863 23.8697L56.3508 24.1403L56.635 24.036L71.8898 18.4414L71.8236 18.2109L57.8916 21.1595Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.0433 27.8548L76.9189 27.6206L76.8246 27.8687L75.2217 32.0937L75.1431 32.1235L75.1829 32.1959L75.1077 32.3938L75.2595 32.3366L76.8846 35.3147L77.0121 35.5479L77.1032 35.298L78.651 31.054L78.7273 31.0252L78.6881 30.9521L78.7598 30.756L78.6136 30.8111L77.0433 27.8548ZM9.41953 57.1629L9.27051 57.3473L11.6642 60.0765L11.692 60.108L11.7134 60.1119L17.7544 63.4985L20.0439 66.1487L20.0782 66.1882L20.1083 66.1896L26.7766 69.0037L28.9593 71.586L28.9991 71.6331L29.0608 71.6279L34.04 71.2193L34.0653 70.9847L26.919 68.7996L24.6896 66.1615L24.6553 66.1213L24.6241 66.1199L17.9042 63.3041L15.5659 60.5974L15.5381 60.5652L15.516 60.5614L9.41953 57.1629ZM73.5513 21.4818L73.4078 21.5224L73.4778 21.6537L75.1739 24.8473L75.3285 25.1381L75.3974 24.8161L76.2368 20.9045L76.2788 20.709L76.0864 20.7634L73.5513 21.4818Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.8029 11.1504L71.7397 18.2394L56.5518 23.8094L56.5147 23.8233L56.4928 23.8566L54.7226 26.5584L54.4834 26.9237L54.8758 26.7324L71.9005 18.4359L71.8579 18.3354L71.9646 18.3566L72.9114 14.5204L72.9142 14.5086L72.9149 14.4961L73.0421 11.1729L72.8029 11.1504ZM18.7182 45.7142L18.6703 45.7024L18.6277 45.7273L4.05664 54.3658L4.12977 54.589L39.248 51.07L39.265 50.8336L18.7182 45.7142Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M77.096 35.1992L76.9892 35.0037L76.8846 35.2002L74.557 39.568L74.4662 39.6002L74.5043 39.6675L74.3896 39.8823L74.5861 39.813L76.2323 42.7308L76.3415 42.9242L76.4437 42.727L78.7127 38.354L78.8007 38.3228L78.764 38.2552L78.8745 38.0417L78.6843 38.1093L77.096 35.1992ZM72.9015 14.4367L72.7545 14.1543L72.678 14.4634L71.7491 18.2278L71.6684 18.2462L71.7204 18.3439L71.6847 18.4895L71.7852 18.4663L73.4782 21.6536L73.6293 21.9382L73.7014 21.6242L74.5764 17.8224L74.6544 17.8043L74.6031 17.7059L74.6364 17.5617L74.5397 17.5839L72.9015 14.4367ZM75.3864 24.7343L75.2644 24.5042L75.1684 24.7467L73.5087 28.9485L73.4262 28.9766L73.4674 29.0525L73.3929 29.2417L73.543 29.1908L75.2096 32.2447L75.3347 32.4741L75.4276 32.2295L77.0298 28.0069L77.1098 27.9795L77.0693 27.9029L77.1403 27.7154L76.9958 27.7643L75.3864 24.7343ZM31.9359 68.3611L31.8954 68.3119L31.8552 68.3153L24.6667 66.1344L22.3842 63.4339L22.3496 63.3927L22.318 63.392L15.5479 60.5758L13.1538 57.8047L13.1254 57.7717L13.0827 57.7648L9.38106 57.1493L9.30273 57.3729L15.3981 60.7706L17.7413 63.4825L17.7759 63.523L17.8064 63.5237L24.525 66.3389L26.7586 68.9818L26.7991 69.0296L26.8379 69.0258L33.9953 71.2144L34.3761 71.3312L34.1235 71.0234L31.9359 68.3611Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.7706 26.5171L54.7467 26.5289L54.7298 26.5501L52.5502 29.2595L52.7037 29.4391L71.908 18.4331L71.7957 18.2207L54.7706 26.5171ZM21.1904 45.9141L21.1797 45.9113L21.1686 45.9103L18.699 45.7121L18.6602 45.9484L39.2046 51.0672L39.2074 51.0679L39.268 50.8354L39.2653 50.8347L21.1904 45.9141Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.8676 68.3211L31.5332 68.2511L31.7501 68.5148L33.9377 71.1771L33.9838 71.2333L34.0552 71.2184L39.3095 70.1132V69.8779L31.8676 68.3211ZM75.4206 32.1305L75.3153 31.9375L75.2096 32.1305L72.7995 36.5319L72.654 36.7978L72.9423 36.7038L77.0277 35.3723L77.1653 35.3276L77.0959 35.2004L75.4206 32.1305Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.5844 29.2305L52.5695 29.2392L52.5574 29.252L50.0146 31.9178L50.1657 32.1025L71.9124 18.4305L71.7887 18.2246L52.5844 29.2305Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M73.6908 21.5404L73.5706 21.3145L73.4732 21.5508L71.7331 25.78L71.6367 26.0139L71.8786 25.9411L75.3152 24.9052L75.4552 24.863L75.3866 24.7337L73.6908 21.5404ZM72.8681 36.4736L72.7284 36.519L72.8005 36.6469L74.5368 39.7244L74.6446 39.9154L74.7475 39.7216L77.0969 35.313L77.2372 35.0496L76.9538 35.1421L72.8681 36.4736ZM29.6966 65.6351L29.6554 65.5848L29.6152 65.5893L22.3273 63.3959L21.9395 63.2791L22.2011 63.5886L24.5065 66.3161L24.5474 66.365L24.5865 66.3608L31.8083 68.5518L32.1881 68.6669L31.9362 68.3605L29.6966 65.6351Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.37023 57.1484L9.3418 57.3869L13.0435 58.0024L13.0535 58.0041H13.0639L17.5156 57.9795L17.5235 57.7393L9.37023 57.1484Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.1086 57.7715L12.9717 57.9611L15.3841 60.7537L15.4198 60.7949L15.4739 60.7953L19.9307 60.8382L19.9779 60.6071L13.1086 57.7715Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.0368 31.8983L50.0282 31.9038L50.0205 31.9107L47.1679 34.4812L47.3145 34.6708L71.9117 18.43L71.9142 18.4286L71.7839 18.2266L71.7814 18.2279L50.0368 31.8983ZM24.0811 45.7211L24.0586 45.7134L24.0346 45.7152L21.1505 45.9099L21.127 46.1459L39.2042 51.0668L39.2742 50.8371L24.0811 45.7211Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.0235 60.6409L19.9881 60.5986L19.933 60.5983L15.4762 60.5553L15.4287 60.7864L22.246 63.622L22.3839 63.4335L20.0235 60.6409ZM71.8088 25.7108L71.6671 25.7538L71.7381 25.8837L73.4942 29.1016L73.6172 29.3269L73.7115 29.0881L75.3916 24.8343L75.4831 24.6035L75.2454 24.6753L71.8088 25.7108ZM74.7456 39.6064L74.6517 39.4397L74.5425 39.5967L71.456 44.0261L71.2599 44.3082L71.5891 44.2102L76.371 42.7865L76.5156 42.7435L76.4417 42.6122L74.7456 39.6064Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.1827 34.4707L47.1785 34.4731L47.1747 34.4762L44.0723 36.8984L44.2133 37.0928L71.9147 18.4292L71.9154 18.4288L71.782 18.2285L71.7813 18.2292L47.1827 34.4707Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.521 43.9813L71.374 44.025L71.4517 44.1574L73.2105 47.1576L73.3065 47.3212L73.4132 47.1645L76.4367 42.7406L76.628 42.4609L76.3032 42.5576L71.521 43.9813Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.0792 36.8928H44.0789L40.7914 39.1161L40.791 39.1164L40.9255 39.3157L71.9154 18.4278L71.8486 18.3283L71.7813 18.2285L71.7796 18.2296L44.0792 36.8928Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.2175 67.2005L37.1711 67.1416L37.1229 67.1523L29.6277 65.5945L29.2939 65.5252L29.5105 65.7886L31.7501 68.514L31.7968 68.5711L31.8436 68.5607L39.2601 70.1127L39.5859 70.1806L39.3787 69.92L37.2175 67.2005ZM73.7054 28.987L73.6014 28.7968L73.495 28.9856L71.0219 33.378L70.876 33.6373L71.1609 33.552L75.3495 32.3026L75.4916 32.26L75.4206 32.1297L73.7054 28.987ZM71.9542 18.2722L71.8363 18.0508L71.7376 18.2812L69.9344 22.4834L69.8374 22.71L70.0754 22.6473L73.6149 21.714L73.7615 21.6752L73.6905 21.5414L71.9542 18.2722Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.2811 45.1205L27.2454 45.1035L27.2069 45.1112L24.0202 45.7166L23.5693 45.8025L24.0043 45.9488L39.1974 51.0645L39.2875 50.842L27.2811 45.1205Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.0927 33.3212L70.9482 33.3645L71.0223 33.4955L72.8005 36.6475L72.9069 36.8357L73.0106 36.6461L75.421 32.2447L75.5613 31.9883L75.2809 32.0718L71.0927 33.3212ZM37.1389 67.1554L36.8467 67.119L37.0297 67.3495L39.1909 70.0693L39.2429 70.1348L39.3226 70.1089L44.7356 68.3337L44.7131 68.1005L37.1389 67.1554ZM27.4031 62.8445L27.3612 62.7939L27.3206 62.7984L19.9669 60.6036L19.5801 60.4882L19.8404 60.7966L22.2008 63.5892L22.2427 63.6385L22.2819 63.634L29.5692 65.827L29.9473 65.941L29.6967 65.6357L27.4031 62.8445Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.10619 54.3511L4.08887 54.5874L17.4854 57.9762L17.5187 57.9845L17.5512 57.9744L39.2726 51.0663L39.2245 50.832L4.10619 54.3511Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.6062 57.7817L17.5698 57.7387L17.5137 57.739L13.062 57.7636L13.0166 57.9951L19.8859 60.8304L20.0235 60.6419L17.6062 57.7817ZM70.014 22.4149L69.8656 22.4541L69.9391 22.5885L71.7381 25.8847L71.8587 26.1058L71.9547 25.873L73.6952 21.6438L73.787 21.4199L73.5534 21.4816L70.014 22.4149ZM73.0093 36.5299L72.9165 36.3649L72.8066 36.5195L69.6802 40.9188L69.5707 40.9489L69.6106 41.0169L69.4595 41.2296L69.6965 41.1638L71.4075 44.0826L67.7329 48.3384L67.6102 48.3682L67.6504 48.4337L67.4618 48.6521L67.7419 48.5834L69.5204 51.4922L69.6071 51.6336L69.7145 51.5074L73.3798 47.2038L73.499 47.1746L73.4606 47.1092L73.6463 46.8908L73.3719 46.958L71.6976 44.1016L74.7148 39.7706L74.8212 39.7412L74.7827 39.6732L74.9304 39.4615L74.6995 39.5256L73.0093 36.5299ZM35.0038 64.4153L34.9567 64.3557L34.8829 64.3727L29.5763 65.5954L29.5787 65.8303L37.0988 67.393L37.4242 67.4606L37.2173 67.2006L35.0038 64.4153Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.6436 44.1413L30.5948 44.1035L30.5359 44.1208L27.1953 45.1151L26.8955 45.2042L27.178 45.3387L39.1844 51.0605L39.3099 50.8567L30.6436 44.1413Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.1489 65.7009L42.6364 65.3277L42.618 65.3034L42.5622 65.3221L34.9294 64.3715L27.3757 62.8123L25.0533 59.986L25.0111 59.9347L24.9449 59.9434L19.9162 60.6005L19.8975 60.8348L27.2402 63.0261L29.5105 65.7889L29.5577 65.8465L29.6301 65.8298L34.8633 64.6241L37.0293 67.3502L37.082 67.4164L37.1357 67.3984L44.633 68.3334L44.6596 68.3684L44.7158 68.3438L44.9674 68.3754L44.8859 68.2686L50.1912 65.9307L50.1489 65.7009ZM71.9493 25.7695L71.8467 25.582L71.7396 25.7668L69.2257 30.1054L69.1241 30.1328L69.1664 30.2076L69.0545 30.4007L69.2461 30.3487L71.0219 33.4962L71.1266 33.682L71.2312 33.4962L73.6801 29.1479L73.7785 29.1212L73.7372 29.046L73.8454 28.854L73.66 28.9042L71.9493 25.7695Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.1018 42.8129L34.0481 42.7305L33.9565 42.7669L30.5262 44.123L30.3301 44.2006L30.4968 44.3299L39.163 51.0456L39.3377 50.8852L34.1018 42.8129Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.5485 57.7438L17.1631 57.6298L17.4223 57.9368L19.84 60.797L19.8822 60.8469L19.9471 60.8386L24.9757 60.1812L24.9941 59.9469L17.5485 57.7438ZM63.3049 52.5938L63.0831 52.8066L63.402 52.7456L65.2492 55.6332L65.3285 55.7572L65.4339 55.6547L69.6807 51.5406L69.8159 51.5146L69.7757 51.4484L69.9957 51.2352L69.6821 51.2952L67.9011 48.383L67.8231 48.2555L67.7153 48.3591L63.4016 52.5006L63.2626 52.5272L63.3049 52.5938ZM71.2309 33.3777L71.1391 33.2148L71.0289 33.3663L67.8359 37.7691L67.7233 37.7978L67.7642 37.8678L67.6145 38.0744L67.8498 38.0137L69.6495 41.0836L69.7438 41.2441L69.8512 41.0926L72.9773 36.6944L73.0861 36.666L73.0469 36.5963L73.1932 36.3904L72.9641 36.4494L71.2309 33.3777Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.5709 41.1714L37.5425 41.0155L37.3997 41.0851L33.9472 42.7712L33.8252 42.8304L33.8994 42.9445L39.135 51.0163L39.3543 50.9294L37.5709 41.1714ZM67.3312 26.844L67.5413 26.9608L70.1481 22.5923L70.1523 22.5854L70.1554 22.5781L71.9582 18.376L71.741 18.2734L67.3312 26.844Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M48.0419 62.8721L47.8343 62.8759L47.8835 62.9428L42.5259 65.3348L42.5689 65.5646L50.0801 65.9378L50.1088 65.977L50.1716 65.9423L50.3895 65.9534L50.3209 65.8595L55.5464 62.9636L55.4861 62.7383L48.1285 62.8707L48.1081 62.8426L48.0419 62.8721ZM65.7813 45.3768L65.5948 45.5899L65.8727 45.5261L67.6967 48.5087L67.7827 48.649L67.8901 48.5246L71.6209 44.2043L71.7418 44.1766L71.7027 44.1094L71.8864 43.8966L71.6147 43.9589L69.8579 40.9622L69.7734 40.8184L69.6635 40.9438L65.8651 45.2811L65.7404 45.3099L65.7813 45.3768ZM32.7368 61.5617L32.6894 61.5014L32.6149 61.5195L27.2822 62.8045L27.286 63.0392L34.8863 64.6077L35.211 64.6746L35.0048 64.415L32.7368 61.5617Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.977 39.2323L41.0106 38.9883L40.7975 39.112L37.3921 41.0888L37.3193 41.1311L37.3342 41.2143L39.1177 50.9722L39.3551 50.967L40.977 39.2323Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.4953 62.5282L40.4426 62.4596L40.3608 62.4877L34.8712 64.3764L34.8955 64.6093L42.5601 65.5641L42.8447 65.5995L42.6703 65.3718L40.4953 62.5282ZM58.6269 56.3718L58.4367 56.3978L58.5434 56.5576L60.3861 59.3079L53.46 59.8614L53.2503 59.878L53.3709 60.0503L55.3897 62.9278L55.4576 63.0245L55.5553 62.9583L60.5674 59.5786L60.5733 59.5876L60.6717 59.5082L61.0467 59.2552L60.9777 59.2608L65.4266 55.6624L65.762 55.391L65.3347 55.4499L58.6269 56.3718ZM24.9851 59.9449L24.6523 59.8766L24.8679 60.139L27.2173 62.9978L27.2651 63.056L27.3382 63.0383L32.6709 61.7536L32.6671 61.519L24.9851 59.9449ZM70.1506 22.4738L70.0498 22.2891L69.942 22.4696L67.3351 26.8381L67.1882 27.0842L67.4668 27.0166L71.8724 25.944L72.0245 25.9072L71.9496 25.7696L70.1506 22.4738Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.7908 39.1164L40.7464 39.1462L40.7388 39.1996L39.1172 50.9343L39.0684 51.2889L39.3213 51.0359L71.9332 18.4132L71.7811 18.2285L40.7908 39.1164Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M67.8584 48.3122L66.0784 45.401L69.8179 41.1313L69.9413 41.1047L69.9011 41.036L70.083 40.8284L69.8134 40.8863L68.0515 37.8809L71.1976 33.5429L71.3099 33.5162L71.2693 33.4442L71.4142 33.2445L71.1868 33.2982L69.4433 30.2079L71.9475 25.8862L72.0889 25.6426L71.8152 25.7091L67.4096 26.7818L67.2554 26.8192L67.3334 26.9575L69.1618 30.1985L65.9461 34.5407L65.8307 34.5681L65.8729 34.6398L65.7243 34.8405L65.9578 34.785L67.7603 37.8594L63.9502 42.1468L63.823 42.1742L63.8649 42.2428L63.6802 42.4507L63.9561 42.3914L65.7762 45.3685L61.465 49.4697L61.3239 49.4954L61.3672 49.5629L61.1478 49.7716L61.4639 49.7144L63.2564 52.5164L56.6224 53.3773L56.43 53.4022L56.5382 53.5634L58.4283 56.3851L51.3913 56.9112L51.1806 56.9268L51.3019 57.1001L53.2402 59.8632L46.0022 59.9714L45.9811 59.9426L45.9149 59.9724L45.7069 59.9755L45.7572 60.0438L40.437 62.4514L38.2661 59.613L38.2128 59.5437L38.1306 59.5728L32.6833 61.4935L30.4126 58.6364L30.3644 58.5754L30.3159 58.5879L22.619 57.0224L22.6031 57.003L22.5566 57.01L22.2454 56.9466L22.3248 57.0432L17.4975 57.7395L17.4805 57.9738L24.8911 60.1661L24.9161 60.1966L24.958 60.1859L25.3021 60.2878L25.2689 60.2472L32.5755 61.7444L34.8161 64.5637L34.8688 64.6303L34.9492 64.6026L40.3566 62.7422L42.479 65.5168L42.5369 65.5923L42.6235 65.5535L48.0282 63.1404L48.0338 63.1476L48.0962 63.1127L48.3145 63.1241L48.302 63.1071L55.4898 62.9778L55.7161 62.9737L55.5862 62.7886L53.6883 60.083L60.6093 59.5302L60.8138 59.5139L60.6994 59.3434L58.8501 56.5823L65.3669 55.6868L65.553 55.6611L65.4519 55.5028L63.6189 52.6373L63.8677 52.437L63.8064 52.445L67.856 48.5569L67.9929 48.5319L67.9516 48.4647L68.1696 48.2557L67.8584 48.3122Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4902 57.7419L17.5318 57.9786L22.5702 57.2522L22.5771 57.2511L22.5837 57.2494L27.9673 55.8309L27.9122 55.597L17.4902 57.7419ZM59.937 35.6857L60.1286 35.8302L67.5303 26.9768L67.5389 26.9668L67.5452 26.955L71.9549 18.384L71.7483 18.2617L59.937 35.6857Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M65.9898 45.256L64.1631 42.2693L67.9712 37.9836L68.0973 37.9587L68.0561 37.8883L68.2363 37.6856L67.9684 37.739L66.1607 34.6549L69.3748 30.3152L69.4898 30.2902L69.4479 30.216L69.5917 30.022L69.3661 30.0708L67.5428 26.8395L67.453 26.6797L67.3425 26.8259L64.0089 31.2315L63.89 31.2575L63.9333 31.3313L63.786 31.5257L64.0182 31.4755L65.8686 34.632L61.9877 38.9346L61.8581 38.9606L61.9011 39.0306L61.7181 39.2337L61.9915 39.1789L63.8605 42.2353L59.4796 46.3636L59.3365 46.3882L59.3808 46.4568L59.1639 46.6613L59.4772 46.6079L61.3178 49.4855L54.5681 50.3082L54.3736 50.3321L54.4828 50.4947L56.4225 53.3906L49.2715 53.8876L49.0591 53.9025L49.1814 54.0768L51.1714 56.9131L43.8228 57.0001L43.8016 56.971L43.7358 57.0011L43.5271 57.0036L43.5784 57.0732L38.2077 59.5366L35.981 56.6258L35.9276 56.5558L35.8448 56.5856L30.359 58.5686L28.0308 55.6384L27.9819 55.5771L27.906 55.5972L22.5225 57.0153L22.5294 57.2496L30.2519 58.8199L32.5483 61.7099L32.6017 61.7772L32.6824 61.7487L38.1283 59.8284L40.3041 62.673L40.3623 62.7489L40.4493 62.7094L45.9025 60.2419L45.9077 60.2491L45.9704 60.2141L46.1888 60.2256L46.1767 60.209L53.4708 60.1001L53.6978 60.0967L53.5675 59.9109L51.6205 57.1349L58.652 56.6092L58.8579 56.5939L58.7432 56.4224L56.8461 53.5906L63.4752 52.7304L63.663 52.7058L63.5611 52.5464L61.6813 49.6078L61.9267 49.411L61.8681 49.4182L65.986 45.5007L66.125 45.4771L66.0831 45.4085L66.2983 45.2036L65.9898 45.256Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.7633 18.2441L39.1722 50.846L17.4785 57.7447L17.5392 57.9773L27.9611 55.8324L27.9729 55.8299L27.9836 55.8251L39.2831 51.0622L39.256 50.9884L39.3073 51.0487L60.1079 35.85L60.1249 35.8376L60.1363 35.8202L71.948 18.3963L71.7633 18.2441Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.3759 46.4507L54.5112 50.3319L49.2163 53.9064L43.734 57.0002L35.896 56.5798L27.9513 55.5952L27.6592 55.5588L27.8422 55.7893L30.2245 58.7867L30.2782 58.8543L30.3593 58.8252L35.8437 56.8428L38.0749 59.7599L38.1335 59.8361L38.2204 59.7963L43.7894 57.2421L51.4018 57.152L51.6298 57.1492L51.4988 56.9627L49.5001 54.114L56.6463 53.617L56.8533 53.6028L56.7379 53.4302L54.7912 50.5246L61.5364 49.7018L61.726 49.6789L61.6231 49.5178L59.6906 46.4964L64.0695 42.3699L64.2102 42.3473L64.1675 42.2773L64.3803 42.077L64.0747 42.1255L62.153 38.9829L62.076 38.8574L61.9682 38.9579L57.4438 43.1801L57.2986 43.203L57.3436 43.2734L57.1291 43.4737L57.44 43.4241L59.3759 46.4507Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.9443 35.6757L60.1252 35.8341L64.1662 31.4219L64.17 31.4177L64.1735 31.4132L67.5341 26.972L67.346 26.8223L59.9443 35.6757Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.9304 50.8687L41.499 53.9531L33.6039 53.5189L33.5838 53.4922L33.5238 53.5144L33.2882 53.5016L33.3478 53.5795L27.8945 55.6007L27.9212 55.8329L35.8205 56.812L35.8482 56.8487L35.9047 56.8227L36.1549 56.8535L36.139 56.8331L43.699 57.2386L43.7285 57.2784L43.7909 57.2434L44.0092 57.2552L43.9392 57.1596L49.2437 54.1663L49.2489 54.1736L49.3165 54.1278L49.5092 54.1261L49.4482 54.0391L54.5493 50.5953L54.5548 50.6036L54.6276 50.5457L54.7981 50.5346L54.7486 50.4608L55.0168 50.2799L54.9558 50.2837L59.5832 46.5921L59.7405 46.574L59.6948 46.503L59.937 46.3096L59.596 46.3484L57.5998 43.2276L57.5281 43.1153L57.4237 43.1981L52.4266 47.166L52.2647 47.1844L52.3077 47.2488L47.0645 50.79L46.8833 50.8018L46.9304 50.8687ZM59.8864 35.7376L59.7052 35.9355L59.9769 35.8852L61.9475 39.1076L62.0313 39.2452L62.1391 39.1256L66.0782 34.7586L66.2068 34.7346L66.1645 34.6629L66.343 34.465L66.0775 34.5142L64.1811 31.2794L64.0986 31.1387L63.9887 31.2589L59.9748 35.6409L59.8424 35.6655L59.8864 35.7376Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.8896 55.6027L27.9784 55.8262L33.5841 53.7486L33.5889 53.7468L33.5938 53.7444L39.2872 51.0592L39.1894 50.8398L27.8896 55.6027Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.25 39.9998L50.2654 43.9382L50.1018 43.9555L50.1507 44.0286L49.9119 44.2172L50.2529 44.1811L52.2578 47.1741L44.9341 47.6042L44.9099 47.5695L44.8392 47.6097L44.652 47.6208L44.7002 47.6891L39.204 50.8307L38.9999 50.8317L39.0529 50.9042L33.4912 53.5273L33.5359 53.7561L41.4647 54.192L41.4941 54.2326L41.5565 54.1972L41.7752 54.2094L41.7048 54.113L47.0693 51.0667L47.0745 51.0736L47.1421 51.0282L47.3355 51.0268L47.2794 50.9471L47.5646 50.7853L47.5012 50.7856L52.4418 47.4489L52.4474 47.4569L52.5458 47.3786L52.9052 47.136L52.8473 47.1394L57.4694 43.4693L57.4753 43.4786L57.5516 43.4076L57.7051 43.3916L57.6587 43.3192L57.8978 43.1293L57.8437 43.1352L62.1048 39.1587L62.2476 39.1376L62.2039 39.0661L62.4143 38.8697L62.1114 38.9147L60.139 35.6896L60.0628 35.5645L59.955 35.6639L55.3557 39.9132L55.2084 39.9347L55.25 39.9998Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.165 50.8549L39.2995 51.0542L50.3758 44.1572L50.3837 44.1527L50.3886 44.1482L55.4845 40.1221L55.488 40.1193L55.4914 40.1162L60.1181 35.8417L60.0637 35.7803L59.9656 35.6562L39.165 50.8549Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.4126 43.9869L50.3453 43.8867L50.2455 43.9543L44.8072 47.6283L44.4443 47.8736L44.8817 47.848L52.4819 47.4016L52.6916 47.3891L52.5748 47.2145L50.4126 43.9869Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.1729 50.8498L39.2962 51.0564L44.9345 47.8336L44.9383 47.8312L44.9422 47.8288L50.3801 44.1548L50.2495 43.9531L39.1729 50.8498Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};
export default FeaturesRightGroupMobilen;
