import React, { type FC } from 'react';

import { motion } from 'framer-motion';

import type { IconProps, Props, PropTypes } from '../../typescript';

const FeaturesTopRightMobile: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <motion.svg
      initial={{ scale: 1.1, rotate: 12 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{ delay: 0, duration: 0.3 }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 1 }
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
      width="34"
      height="29"
      viewBox="0 0 34 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8654 4.89817L21.8639 4.8972L21.8623 4.89638L20.3849 4.07484L20.3839 4.07429L20.3828 4.07374L18.8742 3.3302L18.8732 3.32965L18.8588 3.3578L18.8709 3.32855L17.3356 2.66849L17.3343 2.66794L17.3331 2.66753L15.7781 2.09961L15.7441 2.18859L18.831 3.4152L24.6468 6.8355L27.2656 8.95889L27.3265 8.8857L26.0465 7.79023L26.0456 7.7894L26.0446 7.78858L24.7031 6.75847L24.6834 6.78332L24.701 6.75709L23.3066 5.79358L23.3056 5.79289L23.3045 5.79234L21.8654 4.89817Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.6958 10.5456L18.6682 10.4354L18.609 10.5323L18.0864 11.3871L17.6898 9.99817L17.6596 9.89258L17.6032 9.98677L17.0315 10.9404L17.0059 10.9831L17.0497 11.0069L18.0464 11.5457L19.0099 12.1455L19.9353 12.8037L20.0347 12.8744L20.0094 12.755L19.6705 11.1536L19.6461 11.038L19.5835 11.1384L19.0554 11.9853L18.6958 10.5456Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.5968 11.346L29.663 11.2775L27.3293 8.88906L27.3274 8.88714L27.3252 8.88536L24.7037 6.75977L24.6416 6.83199L29.5968 11.346Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.6647 11.1876L19.69 11.1471L19.6494 11.122L18.7152 10.5405L18.7167 10.5381L18.6738 10.5148L18.5753 10.4534L18.5774 10.4625L17.6671 9.96817L17.5674 9.91406L17.5986 10.0231L18.0249 11.5165L18.0547 11.6209L18.1114 11.5282L18.6313 10.6778L18.9905 12.1163L19.0177 12.2252L19.0771 12.1299L19.6647 11.1876ZM20.6069 11.8172L20.5862 11.6953L20.5199 11.7996L19.923 12.7382L19.9003 12.774L19.9333 12.8006L20.8123 13.514L20.9107 13.5937L20.8892 13.4691L20.6069 11.8172Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.6542 6.84035L24.698 6.75591L18.8761 3.33203L18.8717 3.32942L18.8668 3.32778L12.6311 1.2832L12.5967 1.37191L24.6542 6.84035Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.596 9.46948L16.5367 9.44174L16.5394 9.45012L15.6061 9.08473L15.5789 9.00742L15.5476 9.0618L15.4891 9.03887L15.5163 9.11631L14.9682 10.0676L14.9404 10.1158L14.9923 10.1359L16.0127 10.5288L16.0395 10.6048L16.0695 10.5536L17.0343 10.9997L17.06 11.0801L17.0921 11.0265L17.1516 11.0539L17.1252 10.9713L17.6622 10.0752L18.6264 10.5987L19.5824 11.1937L19.9172 12.7751L19.9413 12.8892L20.0039 12.7908L20.5344 11.9565L20.7928 13.4683L20.7811 13.4863L20.7987 13.5025L20.8164 13.6068L20.852 13.552L21.6337 14.2783L21.73 14.3677L21.7133 14.2374L21.4991 12.5536L21.513 12.5322L21.4945 12.5174L21.4805 12.4073L21.4384 12.4722L20.6236 11.8163L20.625 11.8139L20.6033 11.7987L20.2692 10.1956L20.2451 10.0793L20.1822 10.1801L19.6442 11.0429L19.2859 9.58235L19.2588 9.47126L19.199 9.56889L18.6668 10.4393L18.2723 9.02843L18.2425 8.92188L18.1855 9.01676L17.6261 9.95021L16.6828 9.50985L16.6846 9.50696L16.6537 9.49488L16.6281 9.41482L16.596 9.46948Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.5953 11.3448L29.6645 11.2793L28.5393 10.0517L28.5383 10.0506L28.5372 10.0496L27.3287 8.88867L27.2617 8.95637L29.5953 11.3448Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.2626 10.2291L20.2878 10.1887L20.2473 10.1635L19.2643 9.55189L19.165 9.49023L19.1929 9.60379L19.5781 11.174L19.605 11.2837L19.6647 11.1878L20.2626 10.2291Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.8372 3.41727L18.8696 3.32775L15.7792 2.09977L15.7765 2.09867L15.7736 2.09784L12.6283 1.28125L12.6016 1.3727L18.8372 3.41727Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.2224 9.71247L19.2549 9.65933L20.1797 10.2348L20.5095 11.8173L20.4971 11.837L20.517 11.8529L20.5374 11.9511L20.5718 11.897L21.4044 12.5673L21.6163 14.2317L21.6053 14.2483L21.6204 14.2645L21.6347 14.3767L21.6722 14.32L22.3777 15.0778L22.3873 15.1971L22.4299 15.1338L22.4822 15.1901L22.4726 15.0702L23.0927 14.1486L23.1139 14.1169L23.0877 14.0893L22.3439 13.3029L22.3455 13.3006L22.3293 13.2857L22.3199 13.1665L22.3145 13.1745L22.1287 11.7001L22.1342 11.7045L22.1168 11.6001L22.1302 11.5795L22.1116 11.5645L22.0977 11.4544L22.0936 11.4609L21.8323 9.89207L21.8118 9.76945L21.7451 9.87436L21.1936 10.7415L21.1903 10.7467L20.881 9.23861L20.8962 9.2143L20.873 9.20002L20.8532 9.10322L20.8152 9.16405L19.9041 8.59736L19.9055 8.59502L19.8802 8.58116L19.8583 8.48984L19.8233 8.5471L19.7653 8.51099L19.7674 8.51937L18.8584 8.02175L18.8342 7.93359L18.8004 7.99003L18.7422 7.95803L18.7664 8.04674L18.2086 8.97744L18.1497 8.94531L18.152 8.95368L17.257 8.53172L17.2587 8.52884L17.2282 8.51675L17.2029 8.43574L17.1704 8.49094L17.1118 8.46334L17.1142 8.47144L16.1747 8.09754L16.148 8.019L16.1161 8.0742L16.0583 8.05113L16.0848 8.12857L15.5459 9.06408L14.5475 8.75019L14.5196 8.67714L14.4887 8.73165L14.4316 8.71366L14.4588 8.78479L13.9158 9.7457L13.8867 9.79733L13.9433 9.81476L14.9729 10.1299L15.0003 10.2018L15.0315 10.1479L15.0883 10.1653L15.0617 10.0954L15.5888 9.18025L16.5768 9.56706L16.6033 9.64436L16.6339 9.59218L17.6053 10.0457L17.6307 10.1272L17.6632 10.0728L17.722 10.1002L17.6963 10.0176L18.21 9.16061L18.6042 10.5703L18.6336 10.6756L18.6907 10.5822L19.2224 9.71247Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.6814 6.84396L24.8362 6.81841L24.6932 6.7536L12.6357 1.28516L12.5908 1.36892L22.0544 7.2666L22.0696 7.27608L22.0873 7.27306L24.6814 6.84396Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.8199 7.5434L17.7883 7.43945L17.7332 7.5331L17.1484 8.52847L17.1221 8.57351L17.1692 8.59576L18.2057 9.08418L18.3028 9.13004L18.2715 9.02733L17.8199 7.5434ZM21.8254 9.92548L21.8499 9.88717L21.8126 9.86095L20.8579 9.19059L20.7597 9.1218L20.7839 9.2392L21.1207 10.8812L21.1443 10.9967L21.2075 10.8972L21.8254 9.92548ZM22.9517 12.3828L22.9742 12.3488L22.944 12.3213L22.0988 11.5546L22.0034 11.4679L22.0196 11.5957L22.2361 13.3142L22.2523 13.4418L22.3232 13.3344L22.9517 12.3828Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7008 6.83565L24.6662 6.74928L22.0721 7.17838L21.9565 7.19747L22.0528 7.26448L23.0272 7.94252L23.0542 7.96134L23.0813 7.9428L24.7008 6.83565ZM15.7496 2.19053L15.7752 2.09867L14.2064 1.63304L14.2049 1.63263L14.2032 1.63222L12.6267 1.28125L12.6045 1.37394L15.7496 2.19053Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.8585 8.07715L18.8854 8.03225L18.838 8.00973L17.7948 7.51321L17.6973 7.4668L17.7287 7.57006L18.1803 9.05399L18.2115 9.15656L18.2667 9.06456L18.8585 8.07715ZM23.1043 14.2445L23.1113 14.2518L23.1006 14.1183L23.0962 13.9714L23.0896 13.9812L22.9593 12.3517L22.9483 12.2139L22.8721 12.3293L22.2435 13.2809L22.2226 13.3124L22.2487 13.3398L22.9923 14.126L22.3841 15.0298L22.3648 15.0588L22.3863 15.086L23.0689 15.948L23.1582 16.0606L23.1539 15.917L23.1043 14.2445ZM14.5336 8.84459L15.5537 9.16535L15.6464 9.19446L15.6126 9.10342L15.1196 7.77328L15.0839 7.67702L15.0334 7.76641L14.4872 8.73323L13.4621 8.49417L13.3721 8.47316L13.4074 8.5587L13.9131 9.78627L13.9504 9.8769L13.9986 9.79163L14.5336 8.84459Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7185 6.81303L24.7715 6.67188L24.6471 6.75701L23.0275 7.86415L22.9736 7.90096L23.0251 7.94105L23.9566 8.66606L24.0077 8.70588L24.0305 8.64532L24.7185 6.81303Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.4956 8.5233L13.4579 8.43171L13.4099 8.51835L12.86 9.5103L12.8291 9.56604L12.8913 9.57992L13.947 9.8154L14.0362 9.83545L14.0015 9.75087L13.4956 8.5233ZM22.74 10.5949L22.7263 10.4847L22.6837 10.5503L21.8285 9.87321L21.4964 8.22725L21.4726 8.10972L21.4093 8.21146L20.8507 9.10715L20.4955 7.60358L20.4689 7.49099L20.4086 7.58972L19.8563 8.49309L19.4661 7.03635L19.4371 6.92773L19.3793 7.02427L18.777 8.02911L18.7515 8.07154L18.795 8.0953L19.815 8.65375L20.8043 9.26918L21.7416 9.92731L22.0168 11.5792L22.0048 11.5976L22.0225 11.6136L22.04 11.7184L22.0764 11.6625L22.8801 12.3917L22.9752 12.478L22.9593 12.3505L22.7447 10.6316L22.7587 10.6098L22.74 10.5949ZM15.6094 9.1445L16.1577 8.19293L17.1514 8.58852L17.1775 8.66693L17.2099 8.61173L17.2672 8.63452L17.2416 8.5579L17.8154 7.58106L17.8435 7.53356L17.7924 7.51282L16.754 7.09265L16.7275 7.01287L16.6953 7.06889L16.638 7.04568L16.664 7.1234L16.1148 8.07635L15.0898 7.74529L14.9964 7.71522L15.0305 7.80722L15.5234 9.13736L15.5587 9.23252L15.6094 9.1445Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7213 6.79262L24.6293 6.7793L23.9412 8.61159L23.9297 8.64248L23.9545 8.66418L24.8346 9.43326L24.922 9.50961L24.9134 9.39386L24.7213 6.79262Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.4758 8.19752L21.3793 8.1301L21.3811 8.13861L20.5144 7.59966L20.5157 7.59746L20.4732 7.57398L20.3762 7.5137L20.378 7.52181L19.4863 7.03133L19.4877 7.02899L19.4418 7.00689L19.3452 6.95375L19.3472 6.96144L18.4092 6.51064L18.3841 6.42578L18.3508 6.48263L18.2934 6.45503L18.3181 6.53824L17.7333 7.53348L17.707 7.57824L17.7539 7.60062L18.7781 8.08808L18.8029 8.17156L18.8364 8.11582L18.8938 8.14314L18.8694 8.06076L19.4031 7.17015L19.7928 8.62552L19.8216 8.73276L19.8794 8.63815L20.4294 7.73876L20.7842 9.24095L20.8104 9.35217L20.871 9.25523L21.4265 8.36408L21.7348 9.89098L21.722 9.91103L21.7419 9.92683L21.762 10.0257L21.7971 9.97049L22.6503 10.6461L22.8625 12.3454L22.8513 12.3625L22.8658 12.3777L23.0044 14.1096L22.994 14.125L23.006 14.1398L23.0588 15.9213L23.0632 16.0674L23.1457 15.9467L23.7514 15.0622L23.8001 15.1224L23.7963 14.9967L23.8075 14.9802L23.7953 14.9651L23.745 13.2925L23.7518 13.2995L23.7424 13.183L23.7547 13.1651L23.7407 13.1505L23.7368 13.0209L23.7304 13.0305L23.6005 11.4011L23.6141 11.3804L23.5977 11.3657L23.5882 11.2468L23.5424 11.3161L22.7394 10.5965L22.4641 8.9217L22.4788 8.89849L22.4579 8.88394L22.4409 8.78055L22.4006 8.84385L21.5139 8.22416L21.5152 8.22196L21.4758 8.19752Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7192 6.7832L24.626 6.80023L24.8181 9.40161L24.8194 9.41932L24.8321 9.43195L25.6516 10.2426L25.7308 10.1953L24.7192 6.7832Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.0976 7.84744L16.1002 8.17109L16.1269 8.24592L16.1591 8.19017L16.2147 8.20816L16.1894 8.13772L16.7365 7.18793L17.7361 7.59232L17.7619 7.67209L17.7949 7.61607L17.8513 7.639L17.8264 7.56224L18.3887 6.60532L19.3984 7.09058L20.4085 7.646L20.4315 7.73443L20.4649 7.67992L21.4078 8.26624L21.4294 8.35907L21.4637 8.304L22.3705 8.93797L22.6454 10.6093L22.6332 10.628L22.651 10.6441L22.6683 10.749L22.705 10.6924L23.5059 11.4103L23.6463 13.1718L23.7004 14.9673L23.0674 15.8919L23.0492 15.9182L23.0668 15.945L23.6442 16.825L23.7263 16.9503L23.7317 16.8006L23.7955 14.997L24.441 14.0542L24.4614 14.0246L24.4386 13.997L23.7532 13.1664L24.3854 12.2266L24.407 12.1945L24.3799 12.1667L23.6128 11.3817L24.2429 10.4279L24.2657 10.3933L24.2347 10.366L23.3972 9.62467L23.3986 9.62261L23.3797 9.60778L23.3662 9.49752L23.3622 9.50356L23.1043 7.91404L23.1191 7.89069L23.0981 7.87614L23.0814 7.77261L23.0409 7.83632L22.1443 7.21251L22.1456 7.21045L22.1227 7.19617L22.1032 7.0984L22.0659 7.158L22.0102 7.11914L22.0118 7.12724L21.1357 6.58266L21.137 6.5806L21.112 6.56673L21.0905 6.47405L21.0548 6.5324L20.9981 6.49725L20.9999 6.50481L20.0742 5.99359L20.0506 5.90379L20.016 5.96146L19.959 5.93002L19.9821 6.01804L19.4356 6.92992L19.0216 5.49886L18.9908 5.39258L18.9347 5.48801L18.3497 6.48366L17.3436 6.06966L17.3174 5.98851L17.2847 6.04536L17.2282 6.02215L17.2533 6.09987L16.694 7.07067L15.6862 6.73659L15.6588 6.66052L15.627 6.71695L15.5712 6.69855L15.5969 6.77009L15.0515 7.73539L14.0467 7.4907L14.0186 7.42026L13.9875 7.47642L13.9331 7.4631L13.9589 7.52792L13.426 8.4891L12.4293 8.34465L12.4008 8.28025L12.3703 8.33614L12.318 8.32858L12.3433 8.38584L11.8076 9.37009L11.7744 9.43092L11.8431 9.44012L12.8689 9.57661L12.8969 9.63991L12.9277 9.58444L12.9796 9.59144L12.9549 9.53542L13.476 8.59551L14.4764 8.82881L14.504 8.89815L14.5353 8.84254L14.5894 8.85517L14.5642 8.7916L15.0976 7.84744Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.0461 6.6387L20.0101 6.06639L20.0088 6.0657L20.0076 6.06502L12.5911 1.36785L12.5908 1.36771L12.6404 1.28876V1.28862L21.0461 6.6387ZM21.0461 6.6387L12.6404 1.28862L21.0461 6.6387ZM21.0461 6.6387L22.0546 7.26553L22.1049 7.18466V7.18452L12.6426 1.2878L12.6418 1.28725L21.0461 6.6387ZM12.6404 1.28862L12.6415 1.28711L12.6404 1.28862ZM24.7059 6.76077L24.6708 6.79442L24.6281 6.80952L25.6397 10.2216L25.6427 10.2316L25.6496 10.2396L26.3982 11.0899L26.478 11.0404L24.8353 7.06231L32.5519 16.2488L32.629 16.1936L29.6698 11.2853L29.6663 11.2794L29.6612 11.2748L24.7059 6.76077Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.074 6.04925L20.1008 6.00463L20.054 5.98197L18.9965 5.4687L18.9003 5.42188L18.9299 5.52472L19.3744 7.06083L19.4047 7.16587L19.461 7.07209L20.074 6.04925ZM24.3778 15.8848L24.3964 15.858L24.3782 15.8309L23.7879 14.9542L23.706 14.8325L23.7008 14.9792L23.6364 16.7967L23.6307 16.9583L23.7231 16.8255L24.3778 15.8848ZM24.3971 12.3202L24.4037 12.3269L24.3934 12.1968L24.3889 12.0501L24.3824 12.0595L24.2505 10.3972L24.2395 10.2592L24.1633 10.3747L23.512 11.3606L23.4909 11.3926L23.5178 11.4201L24.2847 12.2049L23.6543 13.142L23.6345 13.1715L23.657 13.1989L24.365 14.0569L24.4536 14.1644L24.4493 14.0252L24.3971 12.3202ZM15.6712 6.83138L16.7009 7.17274L16.7925 7.20309L16.7608 7.11191L16.2783 5.72424L16.2437 5.62469L16.1918 5.71641L15.6255 6.71879L14.6169 6.46256L14.5892 6.3902L14.5575 6.44746L14.5039 6.43386L14.5289 6.49895L13.9698 7.50751L13.9395 7.56216L14.0001 7.57685L15.0397 7.82991L15.0669 7.90104L15.0989 7.84433L15.1522 7.85738L15.1277 7.79353L15.6712 6.83138Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6416 1.28711L12.5898 1.36716L12.5905 1.36757L18.9493 5.55106L18.9519 5.55284L18.9547 5.55421L20.0121 6.06749L20.0584 5.98441L12.6423 1.28752L12.6416 1.28711Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.0308 8.6035L24.0172 8.4931L23.9742 8.55942L23.0836 7.86627L22.9879 7.79185L23.0073 7.91145L23.2849 9.62277L23.2726 9.64185L23.2906 9.65764L23.3076 9.76282L23.3447 9.7057L24.1716 10.4374L24.2656 10.5208L24.2504 10.396L24.0353 8.6403L24.0496 8.61819L24.0308 8.6035ZM17.3465 6.12928L17.3753 6.07902L17.3205 6.06034L16.2487 5.69551L16.1562 5.66406L16.1884 5.7562L16.6709 7.14401L16.7052 7.24233L16.7571 7.15211L17.3465 6.12928ZM24.4493 14.0291L24.455 13.8653L24.3624 14.0005L23.7091 14.9547L23.6908 14.9814L23.709 15.0081L24.2993 15.885L24.3814 16.0068L24.3863 15.86L24.4493 14.0291Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M31.4168 13.8357L31.4949 13.781L30.6654 12.54L30.6645 12.5385L30.6632 12.537L29.6666 11.2812L29.5908 11.3389L31.4168 13.8357Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.0527 7.55537L14.0857 7.49591L14.0185 7.48534L12.9863 7.32317L12.958 7.25671L12.927 7.31384L12.8753 7.30573L12.8999 7.36354L12.3541 8.36647L12.3213 8.42648L12.3891 8.43636L13.4179 8.58534L13.4456 8.65071L13.477 8.594L13.5281 8.60141L13.5042 8.54484L14.0527 7.55537ZM17.8843 5.00494L17.8285 4.98159L17.8529 5.05945L17.2636 6.0827L17.2364 6.1298L17.2866 6.15053L18.3306 6.58004L18.3562 6.66119L18.3895 6.60435L18.4453 6.62728L18.4212 6.55038L19.0165 5.53729L19.0441 5.49033L18.9938 5.46932L17.9435 5.02979L17.9176 4.94727L17.8843 5.00494Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6426 1.28906L12.5889 1.36774L17.8776 5.10373L17.8818 5.10675L17.8866 5.10867L18.9574 5.55685L19.0019 5.47309L12.6426 1.28906Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.3857 15.8631L24.2993 15.8305L23.6446 16.7712L23.6281 16.7949L23.6414 16.8204L24.0911 17.6863L24.1631 17.8248L24.1808 17.6697L24.3857 15.8631ZM25.0487 11.2395L25.0706 11.2071L25.043 11.1793L24.2364 10.3676L24.145 10.2754L24.1553 10.4049L24.2979 12.2022L24.3538 14.0282L24.3582 14.1741L24.4407 14.0537L25.1059 13.0822L25.1263 13.0523L25.103 13.0245L24.405 12.1964L25.0487 11.2395ZM12.4377 8.41031L12.4741 8.34358L12.3982 8.34001L11.3874 8.29263L11.3105 8.28906L11.3416 8.35937L11.8054 9.41159L11.8446 9.50029L11.8908 9.41516L12.4377 8.41031Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7169 6.77734L24.6299 6.81579L26.3899 11.0782L26.3923 11.0839L26.3959 11.0887L27.0416 11.9493L27.1229 11.9005L24.7169 6.77734Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.9113 9.37527L24.902 9.25663L24.8557 9.32666L24.0166 8.59328L23.9228 8.51144L23.9379 8.63502L24.1529 10.3904L24.1413 10.4081L24.157 10.4241L24.1708 10.5362L24.2099 10.4771L24.9476 11.2196L24.3056 12.1738L24.2856 12.2036L24.3088 12.2312L25.0298 13.0868L25.1181 13.1916L25.1138 13.0547L25.0603 11.3331L25.0666 11.3395L25.0566 11.2111L25.0519 11.0645L25.0456 11.0738L24.9142 9.41097L24.9281 9.38983L24.9113 9.37527ZM11.4285 8.32195L11.3891 8.23242L11.343 8.31866L10.7978 9.33724L10.7617 9.40466L10.8383 9.40727L11.8472 9.44091L11.9228 9.44338L11.8923 9.37404L11.4285 8.32195ZM25.0438 14.928L25.0628 14.901L25.0439 14.8738L24.4403 14.0006L24.3585 13.8823L24.3535 14.026L24.2905 15.8569L24.285 16.0183L24.3772 15.8858L25.0438 14.928Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.644 1.28906L12.5869 1.36513L16.7987 4.72461L16.805 4.72969L16.8127 4.7323L17.8895 5.1088L17.9327 5.02491L12.644 1.28906Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.2555 5.79942L17.2677 6.14394L17.2939 6.22166L17.327 6.16412L17.3812 6.18252L17.3573 6.11153L17.9464 5.08883L17.9751 5.03913L17.9209 5.02018L16.866 4.65136L16.8394 4.57227L16.8064 4.63062L16.7522 4.61167L16.7765 4.68376L16.2101 5.68614L15.1978 5.41783L15.1704 5.34368L15.1381 5.40204L15.0852 5.38804L15.1095 5.45367L14.5562 6.45179L13.5543 6.28194L13.5265 6.21328L13.4946 6.27178L13.4439 6.26312L13.4676 6.32148L12.9259 7.31672L11.9437 7.25699L11.8672 7.25232L11.8968 7.32303L12.3526 8.40766L12.3909 8.49897L12.4383 8.41192L12.979 7.41833L13.9777 7.57514L14.005 7.64242L14.0371 7.58448L14.0875 7.59244L14.0643 7.53546L14.6053 6.55958L15.6132 6.81553L15.6402 6.88844L15.6728 6.83077L15.7254 6.84409L15.7016 6.77983L16.2555 5.79942ZM25.1146 13.0584L25.1201 12.8952L25.0277 13.03L24.3626 14.0015L24.344 14.0285L24.3627 14.0554L24.9475 14.9014L24.3442 15.7684L24.3087 15.7025L24.293 15.842L24.2831 15.8562L24.2899 15.8688L24.0866 17.6606L24.1728 17.6934L24.7959 16.811L24.8318 16.8776L24.8474 16.7381L24.8574 16.724L24.8504 16.7112L25.0387 15.0332L25.0483 15.0471L25.0531 14.9031L25.1146 13.0584ZM25.7251 10.2364L25.7471 10.2037L25.7191 10.176L24.8996 9.36514L24.8086 9.27506L24.8187 9.40276L24.9622 11.2183L24.9731 11.3544L25.0493 11.2411L25.7251 10.2364Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6459 1.29102L12.583 1.36228L15.7192 4.42475L15.728 4.43326L15.7398 4.43656L16.8154 4.73356L16.8578 4.65049L12.6459 1.29102Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.7838 12.0933L25.8044 12.0631L25.7805 12.0352L25.0459 11.1821L24.958 11.0801L24.9623 11.2146L25.0195 13.0571L25.0241 13.2028L25.1064 13.0825L25.7838 12.0933Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.7168 6.77539L24.6309 6.81672L24.6313 6.81741L27.0368 11.9398L27.0379 11.9423L27.0394 11.9446L27.5574 12.784L27.6407 12.738L24.7171 6.77621L24.7168 6.77539Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.9842 7.28549L11.9454 7.19336L11.8982 7.28151L11.3432 8.31877L11.3076 8.38523L11.383 8.38879L12.3936 8.43617L12.469 8.43974L12.4398 8.37012L11.9842 7.28549ZM25.7229 13.9528L25.742 13.9254L25.7226 13.898L25.1054 13.0287L25.0236 12.9136L25.0189 13.0547L24.9574 14.8993L24.952 15.0604L25.044 14.9281L25.7229 13.9528ZM25.7326 10.2078L25.728 10.06L25.6456 10.1826L24.9696 11.1874L24.9494 11.2174L24.973 11.245L25.7078 12.0981L25.7957 12.2003L25.7914 12.0656L25.7326 10.2078Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6117 1.37541L12.6228 1.2808L11.0948 1.06618L11.0926 1.06591L11.0904 1.06577L9.66689 1L9.66016 1.09502L12.6117 1.37541Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.8461 16.7388L25.52 15.7843L25.5373 15.7599L25.5228 15.7338L25.047 14.8774L24.9746 14.7472L24.958 14.8952L24.7554 16.7021L24.0949 17.6375L24.0802 17.6582L24.0893 17.6818L24.4097 18.5275L24.4692 18.6844L24.5011 18.5199L24.8461 16.7388ZM16.8697 4.71122L16.8996 4.65808L16.8408 4.64187L15.789 4.35132L15.762 4.27539L15.729 4.33471L15.677 4.32043L15.7005 4.38634L15.1365 5.40368L14.1322 5.22037L14.1046 5.14965L14.0722 5.20938L14.0221 5.20032L14.0452 5.25923L13.4782 6.3006L13.4463 6.35924L13.5121 6.37036L14.5468 6.54584L14.5738 6.61519L14.6066 6.556L14.6562 6.56438L14.6338 6.50685L15.1853 5.51216L16.1968 5.78019L16.2235 5.85489L16.2567 5.79612L16.3085 5.80985L16.2854 5.74504L16.8697 4.71122ZM11.4276 8.3633L11.4681 8.28765L11.3824 8.29327L10.4895 8.35273L10.4641 8.28902L10.4284 8.35671L10.3895 8.35932L10.4057 8.39969L9.87156 9.41292L9.83105 9.48967L9.91756 9.48253L10.8093 9.40976L10.8343 9.4721L10.8702 9.40468L10.9087 9.40166L10.8929 9.36225L11.4276 8.3633ZM25.7918 12.0683L25.797 11.9051L25.7049 12.0398L25.0276 13.029L25.0088 13.0564L25.0281 13.0835L25.6453 13.9527L25.7272 14.0681L25.7318 13.9268L25.7918 12.0683Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.649 1.29492L12.5771 1.35699L14.6466 4.21046L14.6576 4.22571L14.676 4.22928L15.7427 4.43813L15.8994 4.46875L15.7852 4.35725L12.649 1.29492Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.7315 13.931L25.645 13.8984L24.9662 14.8738L24.9492 14.8982L24.9636 14.9242L25.4394 15.7804L25.5119 15.911L25.5284 15.7626L25.7315 13.931Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.561 16.2576L32.6246 16.1878L24.7101 6.76562L24.6309 6.81726L27.5548 12.7799L27.5603 12.791L27.5704 12.798L32.561 16.2576Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.5622 6.34564L13.5974 6.28096L13.524 6.27533L12.5098 6.19913L12.4336 6.19336L12.462 6.26421L12.9091 7.38234L12.9467 7.4764L12.9952 7.38742L13.5622 6.34564ZM26.4736 11.0856L26.4945 11.055L26.47 11.0273L25.7215 10.1768L25.6339 10.0774L25.6381 10.2099L25.6968 12.0677L25.7015 12.2133L25.7837 12.0931L26.4736 11.0856Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5471 16.244L32.631 16.1991L31.4978 13.7878L31.4958 13.7835L31.4932 13.7798L29.6671 11.2832L29.5879 11.3358L32.5471 16.244Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.5499 6.23047L12.5119 6.13545L12.4637 6.22567L11.911 7.25839L11.0447 7.30233L11.0196 7.23573L10.9829 7.30549L10.9449 7.30741L10.9602 7.34833L10.4157 8.38133L10.376 8.45671L10.461 8.45108L11.3532 8.39176L11.3778 8.45685L11.4149 8.38764L11.4525 8.38503L11.4375 8.34521L11.968 7.35396L12.9499 7.41369L13.025 7.41822L12.997 7.34847L12.5499 6.23047ZM25.1222 17.6592L25.1502 17.7298L25.179 17.5797L25.1881 17.5671L25.1836 17.5558L25.5257 15.7769L26.2123 14.8048L26.2297 14.7799L26.2146 14.7536L25.7402 13.9286L26.3763 13.0149L26.4185 13.073L26.4225 12.9485L26.4338 12.9321L26.4233 12.918L26.4814 11.0618L26.4865 10.8991L26.3945 11.0334L25.7427 11.9852L25.7008 11.9278L25.6968 12.0522L25.6856 12.0686L25.6959 12.0826L25.6364 13.9228L25.4348 15.7404L24.813 16.6213L24.7854 16.5517L24.7562 16.7015L24.7473 16.7142L24.7517 16.7252L24.4073 18.5027L24.4927 18.5394L25.1222 17.6592ZM15.7939 4.4148L15.8258 4.35754L15.7615 4.3449L14.7208 4.14113L14.6936 4.06836L14.6604 4.12933L14.6111 4.11971L14.6332 4.17917L14.0554 5.24073L14.0239 5.29854L14.0888 5.31035L15.1261 5.49956L15.1529 5.5711L15.1864 5.51068L15.2353 5.51947L15.2136 5.46153L15.7939 4.4148Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.6235 4.09206L13.6339 4.11925L13.6631 4.12227L14.6801 4.22978L14.7865 4.2409L14.7238 4.1544L12.6544 1.30078L12.5713 1.34582L13.6235 4.09206Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.3858 11.0581L26.3279 12.9166L25.6444 13.8984L25.6273 13.9231L25.6423 13.9494L26.1165 14.7741L25.4915 15.6593L25.4637 15.5926L25.4357 15.7381L25.4265 15.7512L25.4311 15.7623L25.089 17.5406L24.415 18.4834L24.4025 18.5008L24.4073 18.5219L24.5959 19.3402L24.6882 19.3423L25.1774 17.5807L25.8157 16.6883L25.8437 16.756L25.8716 16.6101L25.8809 16.5972L25.8762 16.5859L26.1958 14.9117L26.2044 14.9269L26.219 14.7941L26.9173 13.8051L26.9352 13.7798L26.9194 13.7533L26.4315 12.9342L27.1184 11.9475L27.138 11.9192L27.1173 11.8918L26.4715 11.0311L26.3901 10.9225L26.3858 11.0581ZM14.1388 5.28568L14.1734 5.22196L14.1012 5.21537L13.1151 5.12653L13.0885 5.05664L13.054 5.12104L13.0096 5.11706L13.0291 5.168L12.4762 6.20086L11.6102 6.23066L11.5855 6.16104L11.5477 6.23272L11.5104 6.23409L11.5251 6.27569L10.9699 7.32888L10.9309 7.40289L11.0144 7.39863L11.9069 7.35332L11.9311 7.42156L11.9693 7.35016L12.0062 7.34824L11.9919 7.30787L12.5327 6.29739L13.4858 6.36907L13.5118 6.43759L13.5466 6.3736L13.5907 6.37689L13.5718 6.32732L14.1388 5.28568ZM10.4996 8.42504L10.5465 8.33633L10.448 8.35624L9.65118 8.51718L9.6288 8.45127L9.58774 8.52995L9.55698 8.53613L9.56783 8.56812L9.03231 9.597L8.98535 9.68721L9.08477 9.66551L9.88173 9.49113L9.90356 9.55553L9.94489 9.47726L9.97537 9.47067L9.9648 9.43964L10.4996 8.42504Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6919 4.07574L12.694 4.12118H12.7395L13.6681 4.12132H13.7374L13.7126 4.05665L12.6604 1.31055L12.5684 1.32977L12.6919 4.07574Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.3135 18.4826L25.3325 18.5594L25.3786 18.3924L25.3866 18.3814L25.3842 18.3721L25.8729 16.6038L25.7882 16.5634L25.1555 17.4482L25.1368 17.3726L25.0905 17.5391L25.0826 17.5501L25.0849 17.5593L24.5966 19.3169L24.6812 19.3575L25.3135 18.4826ZM27.1269 11.9255L27.0404 11.8933L26.3361 12.9051L26.3186 12.9304L26.3343 12.9568L26.8378 13.8022L26.9107 13.9247L26.926 13.7828L27.1269 11.9255ZM14.7272 4.20504L14.7614 4.14243L14.6904 4.13487L13.6733 4.0275L13.5977 4.01953L13.6235 4.09107L14.0524 5.27909L14.0884 5.37878L14.1391 5.28568L14.7272 4.20504Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6166 1.37596L12.6207 1.28094L9.66908 1.00041L9.66386 1L9.6585 1.00069L7.16602 1.32639L7.17261 1.42127L12.6166 1.37596Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.0543 7.37226L11.1 7.28534L11.0035 7.30319L10.1661 7.45766L10.1133 7.46741L10.1295 7.51877L10.4121 8.41623L10.4464 8.52512L10.4997 8.42406L11.0543 7.37226ZM13.7128 4.05754L13.6765 3.95703L13.626 4.05137L13.0393 5.1478L13.0053 5.21124L13.077 5.21769L14.0927 5.30928L14.1671 5.31601L14.1417 5.24571L13.7128 4.05754Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5452 16.2393L32.6325 16.2014L32.1563 15.0103L32.1554 15.0078L32.154 15.0054L31.4971 13.7852L31.4121 13.828L32.5452 16.2393Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.5226 15.6997L26.5507 15.7644L26.5775 15.6228L26.5871 15.6094L26.5822 15.5981L26.9233 13.7966L27.6362 12.787L27.6543 12.7614L27.6377 12.7346L27.1198 11.8952L27.0469 11.777L27.0319 11.9151L26.8324 13.7602L26.183 14.6799L26.1552 14.616L26.1282 14.7575L26.1186 14.7709L26.1234 14.7821L25.7804 16.58L25.2912 18.3502L24.6037 19.3015L24.5936 19.3155L24.5949 19.3327L24.6477 20.1154L24.74 20.1286L25.3773 18.3938L26.0789 17.4227L26.0923 17.4044L26.0864 17.3826L25.8786 16.6002L26.5226 15.6997ZM10.2202 7.49106L10.1856 7.38135L10.1326 7.48337L9.58586 8.53367L8.86236 8.80294L8.82227 8.81791L8.83284 8.85924L9.02851 9.63052L9.05913 9.75163L9.1168 9.64082L9.65135 8.61414L10.467 8.4495L10.5189 8.43893L10.503 8.3884L10.2202 7.49106ZM13.1233 5.19369L13.1615 5.12216L13.0804 5.12353L12.1868 5.13918L12.1627 5.06641L12.1237 5.14028L12.087 5.14083L12.1011 5.18312L11.5349 6.25704L11.4966 6.32968L11.5787 6.32693L12.4715 6.29617L12.4951 6.36744L12.5344 6.29398L12.5707 6.29274L12.557 6.25182L13.1233 5.19369Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.8597 4.16865L11.8428 4.23621L11.9118 4.22742L12.7457 4.12114L12.7893 4.11565L12.7873 4.07184L12.6638 1.32574L12.57 1.31641L11.8597 4.16865Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.93601 8.87751L9.58549 8.63584L9.60225 8.7093L9.65374 8.61044L9.67557 8.60234L9.67022 8.57872L10.2067 7.5482L10.9787 7.4058L10.9995 7.47762L11.0437 7.39386L11.0729 7.3885L11.0635 7.35637L11.5628 6.40933L11.6195 6.30182L11.6643 6.21682L12.1821 5.23476L13.0467 5.21966L13.0699 5.29408L13.1104 5.21856L13.146 5.21801L13.1329 5.17627L13.7103 4.09741L13.7478 4.02724H13.6683L12.7747 4.0271L12.7511 3.95117L12.711 4.0271H12.675L12.6883 4.07008L12.1222 5.144L11.3108 5.26346L11.258 5.27129L11.2718 5.32292L11.5177 6.24112L10.7749 6.3647L10.7536 6.29124L10.7098 6.37541L10.6803 6.38036L10.6899 6.41345L10.1416 7.46691L9.47111 7.70363L9.45395 7.62839L9.40287 7.72766L9.38062 7.73549L9.38626 7.75993L8.89454 8.71425L8.83687 8.82602L8.78057 8.93532L8.29023 9.88717L8.23242 9.99921L8.34983 9.95335L9.09227 9.66349L9.1314 9.64811L9.12111 9.60733L8.93601 8.87751ZM26.4882 15.5847L26.4809 15.5948L25.7883 16.5634L25.7753 16.5815L25.781 16.6032L25.9889 17.3855L25.2965 18.3435L25.2862 18.3578L25.2868 18.3649L24.6509 20.096L24.7342 20.1405L25.4383 19.1741L25.4489 19.1598L25.4482 19.1526L26.0335 17.5534L26.0399 17.5776L26.0843 17.416L26.7408 16.5074L26.7607 16.5771L26.8034 16.4209L26.812 16.4092L26.8092 16.3997L27.2636 14.7374L27.2714 14.7545L27.2972 14.617L27.3071 14.6031L28.0243 13.6002L28.0406 13.5773L28.0285 13.552L27.6405 12.7394L27.5776 12.6077L27.5526 12.7409L26.8878 13.6825L26.8599 13.6215L26.8339 13.7588L26.824 13.7727L26.8291 13.7838L26.4882 15.5847Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.67122 1.09475L9.66189 1L8.35234 1.08541L8.34905 1.08568L8.34589 1.08623L7.16309 1.32667L7.17874 1.42045L9.67122 1.09475Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.3644 5.29805L11.3326 5.17969L11.2761 5.2883L10.6994 6.39627L10.6553 6.48086L10.7493 6.46534L11.5857 6.32624L11.6375 6.31773L11.6239 6.26692L11.3644 5.29805ZM25.4436 19.1659L25.3619 19.1178L24.6577 20.0841L24.6504 20.0942L24.6489 20.1066L24.5617 20.8438L24.6523 20.8695L25.4436 19.1659Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5645 16.2593L32.6155 16.1789L27.6249 12.7194L27.4775 12.6172L27.5547 12.7791L27.9428 13.5917L27.9491 13.605L27.962 13.6124L32.5645 16.2593Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.92154 8.86933L8.9983 8.72034L9.47024 7.80461L10.1394 7.56843L10.1551 7.6467L10.2087 7.54385L10.23 7.5363L10.2252 7.51227L10.7832 6.44014L10.8376 6.33579L10.726 6.373L10.0405 6.60121L10.0243 6.52116L9.97115 6.62441L9.94946 6.63155L9.95454 6.65655L9.40049 7.73183L8.7819 8.0909L8.75539 8.10642L8.75841 8.13704L8.82872 8.82223L8.28331 9.14999L8.27301 9.06967L8.20917 9.19448L8.19406 9.20355L8.19626 9.21975L7.65305 10.2819L7.5752 10.4341L7.72061 10.3441L8.29471 9.98815L8.30474 10.0665L8.36872 9.94215L8.38369 9.93294L8.38163 9.91729L8.84822 9.01172L8.92154 8.86933ZM25.3703 19.8866L26.1589 18.185L26.1668 18.1741L26.1661 18.1669L26.8023 16.4222L27.4751 15.4909L27.4955 15.5572L27.5364 15.4062L27.5452 15.394L27.5423 15.3845L28.0315 13.5846L27.9468 13.5445L27.2697 14.4913L27.2498 14.4261L27.2086 14.5767L27.2 14.5889L27.2027 14.5983L26.7161 16.3788L26.002 17.3669L25.9915 17.3814L25.9922 17.3886L25.3574 19.1231L25.3538 19.128L25.3532 19.1328L24.5652 20.8292L24.6469 20.8774L25.3622 19.9019L25.3697 19.8915L25.3703 19.8866ZM12.7818 4.09664L12.8245 4.01562L12.7336 4.02716L11.9419 4.12808L11.9218 4.04666L11.875 4.1366L11.8467 4.14017L11.8552 4.17463L11.2755 5.28823L11.2325 5.37089L11.3246 5.3573L12.1171 5.24072L12.1366 5.32036L12.1838 5.23084L12.2117 5.22672L12.2036 5.19349L12.7818 4.09664Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5698 16.2625L32.6124 16.1775L28.0099 13.5307L27.9023 13.4688L27.9408 13.5867L28.1954 14.3657L28.2021 14.3863L28.2219 14.3946L32.5698 16.2625Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.1001 18.8914L26.8912 17.1806L26.8993 17.1693L27.6344 16.1605L27.6458 16.145L27.6432 16.126L27.543 15.3975L28.2791 14.3789L28.2933 14.3592L28.2857 14.3362L28.0313 13.5572L27.9817 13.4057L27.94 13.5594L27.4495 15.3642L26.7216 16.3715L26.7107 16.3865L26.7116 16.3938L26.0757 18.1379L26.0718 18.1431L26.0714 18.148L25.283 19.8492L24.5705 20.8209L24.5662 20.8268L24.5639 20.8336L24.3315 21.5142L24.4179 21.5531L25.3641 19.8995L26.0919 18.907L26.0997 18.8963L26.1001 18.8914ZM8.85374 8.12706L8.83726 7.96682L8.76394 8.11018L8.20714 9.19906L7.69675 9.67828L7.68288 9.69132L7.68233 9.70025L7.13281 10.7802L7.20819 10.8362L7.72888 10.3378L7.74248 10.3247L7.74289 10.3161L8.28197 9.26195L8.90427 8.88805L8.93023 8.87253L8.92707 8.84246L8.85374 8.12706ZM11.3605 5.33194L11.4134 5.23047L11.3041 5.26452L10.6216 5.47763L10.6064 5.39222L10.5511 5.4996L10.5299 5.50619L10.5344 5.53159L9.96926 6.62872L9.35466 6.97254L9.32802 6.98737L9.33049 7.01786L9.38953 7.7695L9.40258 7.93648L9.47933 7.78763L10.0385 6.70231L10.7043 6.48056L10.7191 6.5639L10.7747 6.45708L10.7956 6.45021L10.7912 6.42563L11.3605 5.33194Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.576 16.2651L32.607 16.1753L28.2591 14.3075L28.1797 14.2734L28.1931 14.3587L28.3096 15.0961L28.3144 15.1267L28.3443 15.1347L32.576 16.2651Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.2853 14.367L28.2018 14.323L27.4546 15.3571L27.4435 15.3724L27.4461 15.3914L27.5488 16.1388L27.6408 16.1485L28.2853 14.367ZM9.4254 7.01013L9.41221 6.8422L9.3356 6.99215L8.76781 8.10245L8.26236 8.56725L8.2485 8.57989L8.24712 8.59856L8.19824 9.22429L8.28818 9.24969L8.84237 8.16589L9.46082 7.80682L9.48678 7.79171L9.48444 7.76178L9.4254 7.01013ZM11.9485 4.20224L11.9999 4.10352L11.8929 4.13455L11.2136 4.33173L11.1995 4.24096L11.1419 4.3526L11.1213 4.3585L11.1255 4.38459L10.5419 5.51714L10.4897 5.61834L10.5985 5.58442L11.2797 5.37173L11.2935 5.46043L11.3513 5.34935L11.3717 5.34303L11.3677 5.3179L11.9485 4.20224ZM25.1403 20.5719L25.1449 20.5657L25.1457 20.5631L26.0948 18.9023L26.0151 18.8505L25.2857 19.8452L25.2814 19.8513L25.2805 19.8539L24.3353 21.5056L24.4149 21.5575L25.1403 20.5719Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.9041 18.4735L32.9987 18.4624L32.9199 17.3659L32.9196 17.3618L32.9186 17.358L32.6342 16.207L32.541 16.2261L32.9041 18.4735Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.8352 17.8919L26.8433 17.8809L26.8437 17.8758L27.637 16.156L28.3951 15.1156L28.4068 15.0997L28.4036 15.08L28.2872 14.3428L28.1953 14.334L27.5532 16.1092L26.8111 17.1275L26.803 17.1385L26.8027 17.1434L26.01 18.8582L26.0915 18.9062L26.8352 17.8919ZM8.34164 8.60535L8.2517 8.58008L7.68652 9.69052L7.76163 9.74696L8.27806 9.26211L8.29152 9.24948L8.29289 9.23108L8.34164 8.60535ZM10.6262 5.56018L10.6965 5.42383L10.5613 5.49633L9.93802 5.8311L9.91138 5.84551L9.91304 5.87572L9.95711 6.66513L9.96686 6.83979L10.047 6.68421L10.6262 5.56018Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.9047 4.22828L11.9429 4.22924L11.9522 4.19217L12.6625 1.33992L12.683 1.25781L12.6021 1.28294L4.08301 3.9343L4.09605 4.02739L11.9047 4.22828Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.0083 5.87107L9.99843 5.69531L9.91824 5.85199L9.33535 6.99209L9.26367 7.13215L9.40098 7.05539L10.0281 6.70456L10.054 6.69L10.0524 6.66034L10.0083 5.87107Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.6304 1.37435L12.6159 1.28125L7.17185 1.3267L7.15469 1.32684L7.14151 1.33796L4.06641 3.94401L4.11144 4.0257L12.6304 1.37435ZM32.5833 16.2669L32.6007 16.1736L28.369 15.0431L28.3114 15.0277L28.3091 15.0871L28.2819 15.7733L28.2801 15.8179L28.3246 15.8226L32.5833 16.2669Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.8804 19.5668L25.8847 19.5612L25.8855 19.5585L26.8371 17.8912L27.5941 16.8589L27.6024 16.8475L27.6027 16.8424L28.4001 15.109L28.3183 15.061L27.5574 16.1052L27.5492 16.1165L27.5489 16.1216L26.7558 17.841L26.015 18.8514L26.0103 18.8578L26.0095 18.8604L25.0619 20.5186L24.3381 21.502L24.337 21.5035L24.3361 21.505L23.9532 22.1159L24.003 22.1487L24.0319 22.1695L24.7661 21.1726L24.7672 21.1712L24.7676 21.1705L25.8804 19.5668ZM7.8688 9.16135L7.68618 9.69261L7.3028 10.2747L7.29937 10.2799L7.29854 10.2825L6.74023 11.3811L6.82248 11.4288L7.21492 10.8286L7.21835 10.8235L7.21918 10.821L7.77172 9.73531L8.33292 8.63242L8.83836 8.16776L8.85182 8.1554L8.85264 8.14634L9.42015 7.03645L9.346 6.97932L8.83918 7.43506L8.82532 7.44742L8.82449 7.45689L8.25327 8.57914L7.87416 9.1505L7.87072 9.15558L7.8688 9.16135ZM11.2172 4.4153L11.2851 4.2832L11.153 4.35117L10.6006 4.63486L10.5968 4.52625L10.5202 4.67619L10.507 4.68306L10.5075 4.70091L9.91846 5.85295L9.84897 5.98889L9.9834 5.91667L10.5391 5.61815L10.5428 5.72402L10.6197 5.5749L10.6328 5.56776L10.6321 5.55059L11.2172 4.4153Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5905 16.2661L32.5935 16.1713L28.3348 15.7268L28.295 15.7227L28.284 15.7612L28.1067 16.3852L28.0889 16.4485L28.1545 16.4457L32.5905 16.2661Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.6344 18.5409L26.6392 18.5344L26.6401 18.5317L27.5955 16.8557L28.3677 15.8025L28.3764 15.7907L28.3769 15.7761L28.4041 15.09L28.3132 15.0683L27.5142 16.8055L26.7584 17.8362L26.7536 17.8427L26.7528 17.8453L25.8 19.5147L25.8798 19.5665L26.6344 18.5409ZM7.95849 9.19132L7.87102 9.15425L7.2998 10.2784L7.38205 10.3262L7.76873 9.73892L7.77202 9.73398L7.77394 9.72821L7.95849 9.19132ZM10.0029 5.89542L9.92905 5.83789L9.42745 6.27784L9.41372 6.28992L9.41262 6.29967L8.82822 7.44787L8.90251 7.50486L9.40933 7.04926L9.42278 7.03731L9.42388 7.02797L10.0029 5.89542Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.19405 1.417L7.1556 1.33008L6.11601 1.72979L6.11148 1.73158L6.10736 1.73419L5.23145 2.2984L5.27868 2.38106L7.19405 1.417Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5149 20.155L25.516 20.1535L25.5164 20.153L26.6351 18.5402L26.5576 18.4849L25.8028 19.5105L25.8017 19.512L25.8015 19.5125L24.6883 21.1167L24.7657 21.1721L25.5149 20.155ZM8.91293 7.49156L8.83095 7.44336L8.45664 8.00263L8.45334 8.00771L8.45128 8.01334L8.24902 8.58689L8.33649 8.62438L8.91293 7.49156Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.377 20.4208L32.4706 20.4349L32.9972 18.4782L32.9998 18.4683L32.9981 18.4583L32.6351 16.2109L32.5405 16.2167L32.377 20.4208Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.4045 17.4938L27.4095 17.4871L27.4103 17.4843L28.3708 15.7972L28.2909 15.7454L27.517 16.8009L27.5122 16.8077L27.5114 16.8103L26.5547 18.4885L26.6345 18.5403L27.4045 17.4938ZM8.54131 8.04401L8.45398 8.00653L7.87109 9.15363L7.95321 9.20155L8.33383 8.628L8.33713 8.62306L8.33905 8.61757L8.54131 8.04401ZM10.5977 4.73164L10.5244 4.67383L10.0283 5.09744L10.0144 5.10925L10.0121 5.12723L9.91333 5.86679L10.003 5.89481L10.5977 4.73164Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5957 16.2666L32.5866 16.1719L28.1505 16.3515L28.125 16.3526L28.1117 16.3743L27.7777 16.9237L27.7246 17.011L27.8255 16.9956L32.5957 16.2666Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.107 5.14115L10.0173 5.11328L9.41699 6.29279L9.49087 6.35019L9.99246 5.91024L10.0059 5.89843L10.0083 5.88072L10.107 5.14115ZM26.28 19.1175L26.2811 19.1159L26.2815 19.1153L27.4058 17.494L27.3284 17.4387L26.5583 18.4851L26.5571 18.4866L26.5568 18.4873L25.438 20.0999L25.5156 20.1554L26.28 19.1175Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.1722 4.43997L11.1804 4.44052L11.1883 4.43818L11.9196 4.2259L11.9075 4.13253L4.09885 3.93164L4.09473 4.0268L11.1722 4.43997Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.50152 6.33544L9.41968 6.28711L9.0521 6.83141L9.0488 6.83636L9.0477 6.8391L8.4541 8.00722L8.53621 8.05527L8.91039 7.496L8.91368 7.4912L8.91465 7.48859L9.50152 6.33544ZM28.1906 16.4265L28.1957 16.4196L28.198 16.4114L28.3753 15.7873L28.2881 15.7508L27.325 17.4427L27.4048 17.4945L28.1906 16.4265Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5992 16.2654L32.5815 16.1719L27.8114 16.9009L27.7968 16.9031L27.7861 16.9129L27.2884 17.3739L27.1582 17.4943L27.3312 17.4553L32.5992 16.2654Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.0596 18.0575L27.0608 18.0558L27.0611 18.0553L28.1911 16.4255L28.1136 16.37L27.3276 17.438L27.3264 17.4397L27.3261 17.4402L26.2018 19.0615L26.2793 19.1168L27.0596 18.0575ZM10.1014 5.15589L10.0196 5.10742L9.65916 5.63594L9.65587 5.64074L9.65381 5.64637L9.41406 6.29723L9.50112 6.3354L10.1014 5.15589Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.55 4.75771L10.5643 4.75936L10.577 4.75277L11.1963 4.43475L11.3515 4.35497L11.1772 4.34481L4.0999 3.93164L4.0918 4.02652L10.55 4.75771Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.74335 5.67866L9.65616 5.64062L9.04883 6.83593L9.1308 6.88412L9.49839 6.33982L9.50155 6.33501L9.5036 6.32965L9.74335 5.67866Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.20331 1.41085L7.15113 1.33203L5.23591 2.29596L5.2252 2.30132L5.21833 2.31106L4.05859 3.95304L4.12835 4.01689L7.20331 1.41085ZM32.3792 20.4114L32.4691 20.4419L32.8526 19.5099L32.8548 19.5043L32.8556 19.4984L32.9989 18.4736L32.9058 18.4547L32.3792 20.4114ZM32.6007 16.2658L32.5781 16.1733L27.3102 17.3632L27.3039 17.3646L27.2982 17.3676L26.6298 17.7249L26.6643 17.8129L32.6007 16.2658Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.8565 16.9758L27.8578 16.9742L27.8589 16.9724L28.193 16.423L28.1132 16.3711L26.9824 18.0019L27.06 18.0574L27.8565 16.9758Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.0501 5.18063L10.0726 5.18489L10.0901 5.17006L10.5862 4.74645L10.6695 4.67532L10.5606 4.66296L4.10231 3.93164L4.08789 4.0257L10.0501 5.18063Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M1.9239 8.88867L1.83547 8.85352L1.30956 10.0139L1.30778 10.0181L1.30668 10.0226L1.02148 11.2105L1.1128 11.2371L1.9239 8.88867Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.5999 16.2657L32.5758 16.1736H32.5756L26.6395 17.7207L26.6386 17.7209L25.7945 17.9578L25.7938 17.9581L25.8188 18.0499L25.8195 18.0497L32.5998 16.2657H32.5999ZM9.68465 5.70931L9.71801 5.71934L9.73765 5.69064L10.0982 5.16212L10.139 5.10226L10.0679 5.08853L4.1058 3.93359L4.08301 4.02601L9.68465 5.70931Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.4328 25.4599L28.3666 25.3922L27.431 26.1142L27.4232 26.1032L27.3873 26.1478L27.3555 26.1724L27.3612 26.1804L26.5702 27.1645L26.6366 27.2318L27.5833 26.4918L27.5908 26.5024L27.6268 26.4579L27.6584 26.4332L27.6529 26.4255L28.4328 25.4599ZM2.4615 7.70521L2.37308 7.66992L1.83605 8.85492L1.83413 8.85918L1.83372 8.86138L1.02344 11.2074L1.11173 11.2426L1.64862 10.064L1.65054 10.0597L1.65109 10.0574L2.4615 7.70521Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.29557 2.36469L5.22184 2.30469L4.53404 3.03862L4.52951 3.04329L4.52649 3.04919L4.05469 3.95724L4.13584 4.00667L5.29557 2.36469Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.252 24.4448L29.1861 24.377L28.223 25.1098L28.1914 25.1338L28.2094 25.1692L28.3529 25.4513L28.3862 25.5168L28.4324 25.4597L29.252 24.4448Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.46844 6.33861L9.51609 6.35893L9.53243 6.30991L9.74362 5.67759L9.75927 5.63104L9.71217 5.6169L4.11053 3.93359L4.07812 4.02298L9.46844 6.33861Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.00997 6.49414L2.92154 6.45898L2.37326 7.66912L2.3712 7.67351L2.3701 7.67818L2.10742 8.82844L2.19901 8.85453L3.00997 6.49414Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.0547 22.6437L28.1157 22.7131L32.447 20.4656L32.4716 20.4528L32.4726 20.4251L32.6361 16.2212L32.6424 16.0586L32.5495 16.1921L28.0547 22.6437Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.19684 8.85938L2.1989 8.85485L2.2 8.85018L2.46268 7.69992L2.37123 7.67383L1.56081 10.0261L1.02488 11.2029L1.02076 11.2119L1.02063 11.222L1.00195 12.5755L1.09615 12.5863L1.65048 10.0589L2.19684 8.85938ZM28.2945 25.1259L28.2611 25.0603L28.215 25.1177L27.3836 26.1519L27.4499 26.2195L28.4247 25.4673L28.456 25.4432L28.438 25.4079L28.2945 25.1259ZM30.0894 23.4083L30.0238 23.3403L29.073 24.0525L29.0452 24.0734L29.0567 24.1064L29.1703 24.4305L29.1985 24.5113L29.2522 24.4447L30.0894 23.4083Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.41632 7.07024L9.48251 7.10814L9.48745 7.03193L9.53468 6.298L9.53688 6.26449L9.50599 6.25118L4.11554 3.93555L4.07324 4.02068L9.41632 7.07024Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.56967 5.25767L3.48125 5.22266L2.92115 6.45874L2.91909 6.46327L2.91854 6.46547L2.1084 8.82339L2.19669 8.85868L2.7572 7.62795L2.75926 7.62342L2.75981 7.62122L3.56967 5.25767ZM27.4577 26.2109L27.4007 26.1377L26.1409 26.718L26.0777 26.7471L26.1277 26.7955L26.5738 27.2274L26.6113 27.2638L26.6441 27.223L27.4577 26.2109ZM29.1464 24.0742L29.1181 23.9933L29.0644 24.0601L28.2147 25.117L28.2807 25.1848L29.2439 24.452L29.2715 24.4311L29.2601 24.3984L29.1464 24.0742Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.0512 22.6922L28.0867 22.7648L28.133 22.6985L32.6278 16.2469L32.7018 16.1406L32.5766 16.1736L25.7954 17.9578L25.7393 17.9726L25.7648 18.0248L28.0512 22.6922Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.19988 8.85034L2.11007 8.82055L1.56151 10.0248L1.55726 10.0341V10.0393L1.00293 12.5667L1.09273 12.5968L1.64335 11.4021L1.64774 11.3927V11.3875L2.19988 8.85034ZM26.194 26.7285L26.1563 26.692L26.1236 26.733L25.3026 27.7652L25.3601 27.8378L26.6273 27.2376L26.6895 27.2083L26.64 27.1603L26.194 26.7285ZM30.9445 22.3502L30.8791 22.2818L29.9417 22.9726L29.9167 22.991L29.9234 23.0213L30.0057 23.3895L30.0272 23.4857L30.0893 23.409L30.9445 22.3502ZM4.14215 3.99595L4.05359 3.96094L3.48127 5.22393L3.47921 5.2286L3.47811 5.23368L3.24015 6.34179L3.33188 6.36719L4.14215 3.99595Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.54423 7.89963L9.63651 7.965L9.61879 7.85336L9.48697 7.02098L9.4834 6.99832L9.4635 6.98706L4.12041 3.9375L4.06934 4.01769L9.54423 7.89963Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.33024 6.36996L3.33244 6.36529L3.33353 6.36021L3.57136 5.2521L3.47977 5.22656L2.66895 7.59258L2.75738 7.62774L3.33024 6.36996ZM28.2892 25.1765L28.2326 25.1031L26.981 25.6624L26.9217 25.6889L26.9646 25.7374L27.3851 26.2124L27.4224 26.2547L27.4579 26.2107L28.2892 25.1765Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.8252 21.8712L30.8859 21.9444L31.8093 21.2763L31.8147 21.2724L31.8188 21.2672L32.4621 20.4528L32.3919 20.3887L30.8252 21.8712Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.0232 23.0305L30.87 22.4067L30.8737 22.4375L30.9407 22.3545L30.9582 22.3417L30.9572 22.3342L31.8186 21.2678L31.7535 21.1993L30.8956 21.8199L30.8917 21.7878L30.8248 21.8711L30.8073 21.8838L30.8082 21.8917L29.9949 22.9036L29.9405 22.9713L28.7181 23.4806L28.6655 23.5025L28.6964 23.5503L29.0311 24.0695L27.874 24.5696L27.8594 24.5505L27.8287 24.5892L27.7839 24.6085L27.7982 24.6274L26.9984 25.6331L26.9631 25.6774L26.1338 26.7198L24.6155 27.0831L24.5961 27.0658L24.5749 27.0927L24.5026 27.1101L24.5372 27.141L23.7406 28.1595L23.655 28.2692L23.7899 28.235L25.327 27.8468L25.3458 27.8636L25.3672 27.8367L25.4385 27.8187L25.4051 27.7889L26.1911 26.8008L27.4406 26.2253L27.4989 26.1985L27.4563 26.1505L27.079 25.7241L28.2369 25.2069L28.2509 25.2253L28.282 25.1867L28.3262 25.1669L28.3124 25.1488L29.0973 24.1722L29.1349 24.1255L30.0809 23.4169L30.1055 23.3983L30.0987 23.3683L30.0232 23.0305ZM2.10577 8.82981V8.83488L1.55474 11.3666L1.55048 11.376L1.00631 12.5566L1 12.5703L1.00274 12.5851L1.28479 14.0981L1.32639 14.0963L1.37459 14.1096L1.94223 12.9022L1.94883 12.8881L1.94718 12.8795L2.20972 10.1733L2.21082 10.1709L2.7819 8.9321L2.78643 8.92222L2.78629 8.91672L3.33238 6.36657L3.91595 5.08531L3.91815 5.08037L3.91925 5.07515L4.14375 3.98984L4.05203 3.96484L3.24258 6.33389L2.67067 7.5896L2.66614 7.59935L2.11017 8.82019L2.10577 8.82981Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.14513 3.98375L4.05094 3.9707L3.82629 5.05602L3.8252 5.06178L3.82547 5.06782L3.88218 6.33123L3.97734 6.33247L4.14513 3.98375Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.9194 5.07444L3.82946 5.04492L3.24383 6.33085L3.23916 6.34087L3.23943 6.34623L2.69238 8.90119L2.78218 8.93112L3.3707 7.65426L3.37551 7.64396L3.37523 7.63861L3.9194 5.07444ZM27.0378 25.7359L27.1221 25.63L26.9901 25.6597L25.4349 26.0101L25.3498 26.0292L25.412 26.0904L26.1278 26.7953L26.1656 26.8325L26.1985 26.791L27.0378 25.7359ZM28.7767 23.4979L28.7407 23.442L28.6994 23.494L27.8216 24.5976L27.8777 24.6709L29.1208 24.1338L29.1723 24.1115L29.1418 24.0643L28.7767 23.4979Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.0713 22.6285L28.1059 22.7167L30.8704 21.9516L30.8818 21.9484L30.8904 21.9403L32.4572 20.4578L32.4025 20.3809L28.0713 22.6285Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M2.78631 8.91576L2.69569 8.89132L2.12021 10.1399L2.11348 10.1542L2.11527 10.1626L1.85273 12.8683L1.28879 14.0681L1.28027 14.0862L1.28714 14.1051L1.90367 15.7664L1.99594 15.749L1.9472 12.8914L2.52294 11.6665L2.52981 11.652L2.52803 11.6435L2.78631 8.91576ZM25.4788 26.0226L25.4407 25.9851L25.4079 26.0272L24.5781 27.0883L22.7918 27.212L22.6834 27.2196L22.7624 27.2941L23.7457 28.2226L23.7838 28.2585L23.816 28.2173L24.6298 27.1767L26.1723 26.8078L26.2558 26.7877L26.1947 26.7275L25.4788 26.0226ZM30.8953 21.9349L31.0091 21.7934L30.8405 21.8608L29.6563 22.3347L29.6422 22.3088L29.6054 22.355L29.5674 22.3703L29.5779 22.3896L28.6994 23.494L28.755 23.5676L29.9491 23.0701L29.9627 23.0951L29.9997 23.049L30.0374 23.0333L30.0273 23.0148L30.8953 21.9349ZM27.8961 24.6569L27.9785 24.5534L27.849 24.5806L26.298 24.9072L26.216 24.9245L26.2727 24.9861L26.9656 25.7385L27.0032 25.7794L27.0378 25.7359L27.8961 24.6569ZM3.97282 6.34803L3.97776 6.33745L3.97722 6.32592L3.92037 5.06252L3.82631 5.05469L3.28091 7.62448L3.37071 7.65428L3.97282 6.34803Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.0546 22.699L28.1359 22.6502L25.8495 17.9828L25.835 17.9531L25.8022 17.9563L24.7762 18.0562L24.6934 18.0642L24.7422 18.1316L28.0546 22.699Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.37498 7.63954L3.28435 7.61523L2.6957 8.89223L2.68897 8.90693L2.69075 8.91516L2.43329 11.6344L1.85631 12.8616L1.84766 12.88L1.85191 12.8912L1.9008 15.7515L1.99129 15.7714L2.57912 14.555L2.58818 14.5364L2.58379 14.525L2.52776 11.6573L3.11683 10.404L3.12383 10.3892L3.12191 10.3807L3.37498 7.63954ZM26.3429 24.9224L26.3048 24.8812L26.2702 24.9255L25.4079 26.0281L25.3257 26.1333L25.4558 26.1039L27.011 25.7536L27.0915 25.7355L27.0356 25.6749L26.3429 24.9224ZM22.8278 27.2258L22.7894 27.1895L22.7574 27.2315L21.9354 28.3075L21.8703 28.3926L21.9772 28.3839L23.7824 28.2363L23.889 28.2277L23.8112 28.1542L22.8278 27.2258ZM28.774 23.5541L28.8544 23.4531L28.7276 23.4778L27.1813 23.7795L27.1022 23.7948L27.1538 23.8568L27.8223 24.6587L27.8598 24.7036L27.8961 24.6578L28.774 23.5541Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.14381 3.9707L4.04934 3.97537L3.88155 6.32423L3.88086 6.33426L3.88429 6.34373L4.9843 9.39L5.07602 9.36583L4.14381 3.9707ZM24.8194 18.0742L24.806 18.0556L24.783 18.0545L23.612 18.0022L23.4922 17.9968L23.5758 18.0829L28.059 22.7028L28.1318 22.6417L24.8194 18.0742Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.4825 26.0865L25.5473 26.0035L25.4424 26.0096L23.6548 26.1101L23.6313 26.0865L23.6114 26.1125L23.5277 26.1172L23.5729 26.1629L22.7569 27.2311L22.693 27.3148L22.7981 27.3076L24.5837 27.1838L24.6063 27.2068L24.6267 27.1808L24.7092 27.1752L24.6655 27.1311L25.4825 26.0865ZM3.97673 6.33276L3.88596 6.30859L3.28385 7.61484L3.27699 7.62995L3.27904 7.63832L3.02667 10.3707L2.4365 11.6264L2.42757 11.6452L2.4321 11.6563L2.48813 14.5234L1.9051 15.7296L1.89453 15.7517L1.9062 15.7731L2.85654 17.5095L2.94565 17.4808L2.58493 14.5421L3.18032 13.31L3.18952 13.291L3.18485 13.2795L3.12127 10.3936L3.72421 9.11099L3.73135 9.09575L3.72915 9.0871L3.97673 6.33276ZM27.2265 23.7954L27.1888 23.7501L27.1525 23.7965L26.2699 24.9251L26.1894 25.0279L26.3172 25.001L27.8683 24.6743L27.9458 24.658L27.8951 24.5973L27.2265 23.7954Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.8452 21.8601L28.1079 22.6176L23.6443 18.0181L23.6333 18.0067L23.6177 18.0042L22.3545 17.7989L22.1837 17.7712L22.3162 17.8824L28.0627 22.7076L28.0923 22.676L28.1017 22.718L29.6428 22.4424L29.6476 22.4416L29.6521 22.4398L30.8756 21.9503L30.8452 21.8601ZM9.61886 7.85397L9.61542 7.83461L9.59949 7.82335L4.1246 3.94141L4.05566 4.00388L10.9887 16.1479L11.077 16.116L9.61886 7.85397Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.8329 27.2887L22.8864 27.2186L22.7984 27.2124L20.8424 27.0738L20.816 27.0461L20.7979 27.0707L20.7017 27.064L20.7585 27.1238L19.9676 28.1899L19.9151 28.2606L20.003 28.2659L21.9515 28.3823L21.977 28.4092L21.9955 28.3849L22.0906 28.3905L22.0355 28.3326L22.8329 27.2887ZM29.672 22.4246L29.7505 22.3259L29.6262 22.3481L28.114 22.6185L28.0944 22.5923L28.0673 22.6267L28.009 22.6372L28.0336 22.67L27.1527 23.7964L27.074 23.8969L27.1993 23.8724L28.7164 23.5765L28.7355 23.6018L28.7628 23.5674L28.8204 23.5562L28.7967 23.5247L29.672 22.4246ZM26.3452 24.9836L26.4088 24.9023L26.3056 24.9067L24.5173 24.983L24.4938 24.9576L24.4729 24.9849L24.3925 24.9884L24.435 25.0344L23.5983 26.1298L23.5355 26.2121L23.6388 26.2063L25.4255 26.1057L25.4483 26.1304L25.4694 26.1032L25.5488 26.0988L25.5077 26.0543L26.3452 24.9836ZM3.55222 16.287L3.56348 16.2644L3.55565 16.2505L3.18669 13.2972L3.79636 12.0357L3.80556 12.0165L3.80075 12.005L3.72907 9.10068L4.34629 7.7877L4.35371 7.77205L4.34931 7.75543L3.97555 6.31641L3.88204 6.3241L3.63461 9.07789L3.03043 10.363L3.02151 10.3821L3.02618 10.3933L3.08975 13.2787L2.49327 14.5131L2.48242 14.5355L2.48997 14.5489L2.85124 17.4922L2.87046 17.6489L2.94118 17.5077L3.55222 16.287Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.3778 17.8088L22.3697 17.8019L22.3594 17.7992L21.0275 17.447L20.9872 17.5314L28.0657 22.7088L28.1243 22.6339L22.3778 17.8088ZM9.94543 8.78011L9.94488 8.77572L9.94337 8.7716L9.61711 7.8457L9.52539 7.86973L10.9834 16.1308L10.9835 16.1318L11.0776 16.1163L11.0773 16.1153L9.94543 8.78011Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.55597 16.2553L3.52741 16.123L3.46672 16.244L2.85568 17.4649L2.84277 17.4907L2.85967 17.514L4.10646 19.2378L4.19159 19.1998L3.55597 16.2553ZM23.6738 26.1873L23.7267 26.1181L23.6399 26.111L21.6584 25.9479L21.5386 25.938L21.6191 26.0272L22.7595 27.2915L22.7978 27.334L22.8326 27.2884L23.6738 26.1873Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.0435 17.4541L21.038 17.4501L21.0315 17.4478L19.6568 16.957L19.6143 17.0415L28.0673 22.7096L28.122 22.6316L21.0435 17.4541Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.2278 23.8548L27.2903 23.775L27.189 23.7779L25.3777 23.8295L25.2776 23.8323L25.3431 23.9082L26.2717 24.9851L26.3096 25.0291L26.3453 24.9834L27.2278 23.8548ZM21.6902 25.9636L21.6512 25.9204L21.6166 25.9671L20.7824 27.0915L20.7308 27.1613L20.8173 27.1674L22.7918 27.3072L22.9096 27.3156L22.8305 27.2277L21.6902 25.9636ZM4.17795 15.0369L4.18935 15.014L4.18138 15.0001L3.80075 12.0089L3.78057 11.8496L3.71068 11.9942L3.09497 13.2682L3.08398 13.2908L3.09181 13.3044L3.46242 16.2713L3.48192 16.4273L3.55236 16.2868L4.17795 15.0369Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.97371 6.3125L3.88281 6.34065L4.25657 7.77954L4.25753 7.78352L4.25918 7.78723L4.98556 9.3946L5.07372 9.35876L3.97371 6.3125Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.35076 7.767L4.26013 7.74805L3.63811 9.07132L3.62891 9.09068L3.63756 9.11046L4.34019 10.7306L4.43149 10.7103L4.35076 7.767Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.6673 16.9631L19.6639 16.9609L19.6602 16.9592L18.2702 16.3408L18.2253 16.4246L28.0674 22.7105L28.0683 22.711L28.1205 22.6312L28.1195 22.6307L19.6673 16.9631ZM10.4813 9.79588L10.4805 9.78654L10.4761 9.77816L9.94068 8.76562L9.85156 8.79515L10.9836 16.1313L11.078 16.1196L10.4813 9.79588Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.4269 10.7321L4.43637 10.7123L4.42772 10.6924L3.7251 9.07227L3.63379 9.09245L3.70601 12.0169L3.7965 12.0364L4.4269 10.7321ZM25.4152 23.8469L25.3769 23.8024L25.3413 23.8491L24.4595 25.0033L24.3978 25.0841L24.4994 25.0798L26.3098 25.0025L26.4081 24.9983L26.3438 24.9238L25.4152 23.8469ZM20.8589 27.1491L20.9041 27.0883L20.8296 27.074L18.729 26.6707L18.5953 26.645L18.6839 26.7486L19.9698 28.2499L20.0086 28.2952L20.0442 28.2473L20.8589 27.1491Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.2763 16.344L18.2746 16.343L18.273 16.3421L16.8963 15.6094L16.8486 15.6919L28.0681 22.7107L28.0684 22.711L28.1193 22.6304L28.1189 22.6301L18.2763 16.344Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.756 26.6868L18.7165 26.6406L18.6812 26.6901L17.8786 27.8101L17.835 27.8712L17.9088 27.8847L19.9974 28.2661L20.1293 28.2901L20.042 28.1883L18.756 26.6868Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.8989 15.6111L16.8987 15.611L15.5643 14.7794L15.5642 14.7793H15.5641L15.5137 14.86V14.8602L28.0682 22.711L28.0935 22.6706L28.1188 22.6302L28.1186 22.6301L28.1181 22.6298L16.8989 15.6111Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.82379 18.011L4.83752 17.9847L4.82598 17.969L4.18186 15.0051L4.1533 14.873L4.09274 14.9939L3.46715 16.2437L3.4541 16.2698L3.46536 16.2852L4.09892 19.2197L4.1268 19.3486L4.18776 19.2316L4.82379 18.011ZM24.5353 25.0598L24.5874 24.9916L24.502 24.9835L22.5135 24.796L22.3963 24.785L22.4729 24.8744L23.6002 26.1891L23.6385 26.2338L23.6742 26.187L24.5353 25.0598ZM28.1314 22.6991L28.1926 22.6209L28.0932 22.6222L26.2815 22.6468L26.1839 22.6481L26.2451 22.7243L27.1532 23.8549L27.1908 23.9017L27.2279 23.8545L28.1314 22.6991Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.214 10.8565L11.2144 10.841L11.2056 10.8281L10.4728 9.7723L10.3691 9.62305L10.3863 9.80402L10.9831 16.1277L11.0781 16.1244L11.214 10.8565Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.5449 24.8141L22.506 24.7688L22.4705 24.8167L21.6162 25.9682L21.5652 26.037L21.6506 26.0442L23.6321 26.2072L23.7476 26.2166L23.6721 26.1288L22.5449 24.8141ZM4.82683 17.9768L4.79291 17.8652L4.73895 17.9686L4.10278 19.1891L4.08754 19.2185L4.10978 19.2431L5.62447 20.9164L5.70535 20.8705L4.82683 17.9768ZM4.81818 13.7582L4.82985 13.735L4.82161 13.721L4.43096 10.7055L4.41036 10.5469L4.34074 10.6909L3.71034 11.9954L3.69922 12.0183L3.70718 12.0319L4.08781 15.0228L4.10745 15.1781L4.17761 15.0381L4.81818 13.7582Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M4.1381 3.95508L4.0498 3.9868L4.98202 9.38192L4.98435 9.39524L4.99341 9.4054L10.9942 16.1544L11.0711 16.0991L4.1381 3.95508Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.07271 9.39579L5.08232 9.37574L5.07326 9.35542L4.34688 7.74805L4.25598 7.76892L4.33658 10.7122L4.42706 10.7317L5.07271 9.39579ZM26.3195 22.6655L26.2814 22.6181L26.2444 22.6664L25.3415 23.8487L25.2808 23.9281L25.3806 23.9252L27.1919 23.8736L27.2876 23.8709L27.2276 23.7961L26.3195 22.6655ZM21.6932 26.0243L21.7378 25.9641L21.6644 25.9493L19.5682 25.5267L19.5395 25.4919L19.5214 25.5172L19.42 25.4967L19.4821 25.572L18.7013 26.6617L16.5687 26.0174L16.5381 25.9779L16.521 26.003L16.4115 25.9699L16.4814 26.0602L15.7153 27.1726L15.6781 27.2268L15.7412 27.2454L17.8895 27.8785L17.9192 27.917L17.9368 27.8924L18.0457 27.9245L17.9771 27.8361L18.7412 26.7696L20.7951 27.164L20.8228 27.1976L20.8412 27.1727L20.9417 27.1921L20.8813 27.1188L21.6932 26.0243ZM5.47544 16.7619L5.48931 16.7353L5.47118 16.7112L4.17359 14.9875L4.08887 15.0263L4.73519 18L4.76307 18.1287L4.8239 18.012L5.47544 16.7619Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.1104 11.9157L12.1163 11.892L12.1004 11.8735L11.2023 10.8247L11.1217 10.7305L11.1185 10.8543L10.9824 16.1222L11.0763 16.1348L12.1104 11.9157Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.45011 22.4502L6.37455 19.6708L6.38031 19.6603L6.36466 19.6432L5.47873 16.7273L4.82567 13.742L5.47447 12.4459L5.48628 12.4223L5.47241 12.3998L4.4243 10.6855L4.33643 10.7164L4.72653 13.7273L4.09228 14.9943L4.0791 15.0206L4.09682 15.0442L5.37643 16.744L4.73889 17.9673L4.72351 17.997L4.73875 18.0136L5.60835 20.8779L5.59997 20.8933L5.61796 20.9097L5.64721 21.0058L5.67261 20.9591L7.37377 22.5026L7.45011 22.4502ZM25.4166 23.9059L25.4679 23.8387L25.3837 23.8296L23.4084 23.6188L23.382 23.5866L23.3618 23.6139L23.2738 23.6045L23.3235 23.6655L22.4704 24.8155L22.42 24.8833L22.5042 24.8913L24.4727 25.0768L24.4982 25.1081L24.5188 25.0812L24.6057 25.0893L24.5577 25.0302L25.4166 23.9059Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M13.1484 12.954L13.1697 12.9213L13.1418 12.894L12.0981 11.871L12.0384 11.8125L12.0185 11.8937L10.9844 16.1127L11.0706 16.1499L13.1484 12.954Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.3015 13.9456L14.3534 13.9102L14.3052 13.8698L13.1386 12.8919L13.0974 12.8574L13.0682 12.9025L10.9902 16.0984L11.057 16.1638L14.3015 13.9456ZM24.2833 22.4086L24.275 22.5034L26.2762 22.7431L26.2794 22.7435L26.2825 22.7433L28.0941 22.7187L28.0961 22.6236L24.2833 22.4086Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.13483 21.2319L8.09995 21.1574L8.08361 21.186L6.35458 19.6323L6.27824 19.6849L7.35366 22.4638L7.34405 22.4804L7.36671 22.4976L7.39802 22.5782L7.42081 22.5382L9.31104 23.963L9.38286 23.9048L8.14938 21.263L8.15611 21.2511L8.13483 21.2319ZM17.3261 24.8334L17.2188 24.8002L17.2868 24.8904L16.5011 26.0313L16.4642 26.085L16.5266 26.104L18.6915 26.758L18.7212 26.7974L18.739 26.7724L18.8457 26.8047L18.7793 26.7162L19.5809 25.5974L19.6193 25.5437L19.5562 25.5242L17.3743 24.8482L17.3438 24.8077L17.3261 24.8334ZM6.14284 15.4811L6.15685 15.4542L6.13831 15.43L4.8138 13.707L4.72949 13.7462L5.38653 16.7499L5.41468 16.8783L5.47537 16.7617L6.14284 15.4811Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.5518 14.8655L15.6456 14.8385L15.5665 14.7812L14.3023 13.8673L14.2751 13.8477L14.2475 13.8666L11.0029 16.0846L11.043 16.1696L15.5518 14.8655Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.04291 18.4448L7.05925 18.4147L7.03591 18.3896L5.46753 16.707L5.38706 16.7534L6.27671 19.6814L6.3098 19.7902L6.36418 19.6904L7.04291 18.4448ZM12.1932 24.0155L12.153 23.9507L12.1125 24.0153L11.4138 25.1253L10.0974 22.7076L10.0576 22.6345L10.0148 22.7059L9.29853 23.9003L9.2745 23.9404L9.31405 23.9652L11.3452 25.2346L11.3428 25.2382L11.3875 25.261L11.5395 25.356L11.5263 25.3318L13.5457 26.3623L13.698 26.4401L13.6078 26.2948L12.1932 24.0155ZM5.47837 12.4145L5.44954 12.2832L5.38926 12.4034L4.73305 13.7145L4.71973 13.7412L4.73785 13.7648L6.06236 15.4879L6.14667 15.4487L5.47837 12.4145ZM26.3197 22.7238L26.3704 22.6575L26.2876 22.6476L24.2863 22.408L24.1735 22.3946L24.243 22.4845L25.3411 23.9062L25.379 23.9554L25.4166 23.9061L26.3197 22.7238Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.5644 14.7788L15.5463 14.7676L15.526 14.7735L11.0172 16.0775L10.8809 16.1169L11.0134 16.1677L28.0766 22.7145L28.1189 22.6296L15.5644 14.7788Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M16.5979 26.0587L17.3647 24.9452L19.5128 25.6108L19.5423 25.6511L19.5607 25.6256L19.6652 25.658L19.6008 25.5696L20.4046 24.4476L22.4819 24.8873L22.5096 24.9235L22.5291 24.8973L22.6247 24.9175L22.5686 24.844L23.4061 23.7149L25.3741 23.9249L25.485 23.9367L25.4168 23.8484L24.3186 22.4266L24.2801 22.3768L24.2426 22.4273L23.3642 23.6115L21.2743 23.1473L21.2459 23.1099L21.2267 23.1367L21.1302 23.1153L21.1878 23.1909L20.3655 24.3387L18.1997 23.6506L18.1693 23.6089L18.1512 23.6351L18.0461 23.6018L18.1121 23.6921L17.3279 24.8307L15.1486 23.9312L15.1166 23.8842L15.0992 23.9107L14.9882 23.865L15.0599 23.9702L14.3346 25.0705L12.9539 22.8081L12.914 22.7425L12.8731 22.8076L12.1563 23.9464L10.8319 21.4841L10.7921 21.4104L10.7491 21.4822L10.0613 22.6292L8.85572 20.0266L8.86258 20.0145L8.84116 19.9954L8.80656 19.9206L8.78995 19.9497L7.06119 18.4121L7.73868 17.1688L7.75516 17.1385L7.73141 17.1132L6.15836 15.4512L6.82666 14.1688L6.8408 14.1417L6.82858 14.126L6.15479 11.0878L6.15919 11.0789L6.1493 11.0633L6.12211 10.9404L6.09987 10.9847L5.06989 9.34961L4.98242 9.38119L5.3831 12.4169L5.3761 12.4308L5.38667 12.4442L5.4052 12.5852L5.41454 12.5667L6.05016 15.4526L5.39079 16.7179L5.37513 16.7477L5.39807 16.7724L6.9431 18.4298L6.2807 19.6451L6.26272 19.6782L6.29073 19.7033L8.03514 21.2708L8.03336 21.2739L8.05601 21.2909L8.08718 21.3717L8.0913 21.3645L9.29649 23.9453L9.33439 24.0265L9.38052 23.9496L10.0539 22.8266L11.3694 25.2426L11.4082 25.314L11.4515 25.2453L12.1527 24.1308L13.5272 26.3454L13.5665 26.4087L13.6074 26.3465L14.3493 25.2212L14.4618 25.279L14.449 25.258L16.5089 26.0973L16.54 26.1429L16.5577 26.117L16.6685 26.1622L16.5979 26.0587Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.06835 9.34766L4.98926 9.4008L6.06427 11.1074L6.06578 11.1098L6.06743 11.1118L7.44838 12.8318L7.52417 12.7743L5.06835 9.34766ZM19.892 21.1569L19.8706 21.2496L24.2679 22.502L24.2729 22.5034L24.2782 22.5036L28.091 22.7187L28.1022 22.6242L19.892 21.1569Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.4024 24.8888L18.1892 23.7462L20.3539 24.434L20.3834 24.4755L20.4021 24.4493L20.5045 24.4818L20.442 24.3937L21.267 23.2423L23.3558 23.7061L23.3834 23.7439L23.4034 23.7167L23.4968 23.7374L23.4429 23.6637L24.3186 22.4831L24.3619 22.4247L24.2913 22.4083L22.1593 21.9117L22.1308 21.8729L22.1112 21.9005L22.017 21.8785L22.0725 21.9543L21.2281 23.1329L19.0456 22.4216L19.0152 22.3787L18.9967 22.4057L18.8938 22.372L18.9577 22.4624L18.1526 23.6313L15.9523 22.7109L15.9205 22.6631L15.9026 22.6902L15.7937 22.6446L15.8635 22.7495L15.1187 23.8793L13.733 21.5689L13.6932 21.5024L13.6518 21.5679L12.9162 22.7368L11.5839 20.2286L11.5444 20.1542L11.501 20.2264L10.795 21.4037L9.57928 18.758L9.58642 18.7456L9.56487 18.7265L9.5304 18.6515L9.51351 18.6813L7.75633 17.1344L8.45113 15.8593L8.46788 15.8287L8.44372 15.8034L6.84197 14.1374L7.52729 12.8225L7.54157 12.7949L7.52222 12.7708L6.14127 11.0508L6.05764 11.0909L6.73363 14.1392L6.05764 15.4361L6.04199 15.4663L6.06533 15.4909L7.63797 17.1525L6.95896 18.3986L6.94083 18.4318L6.96912 18.457L8.74113 20.0332L8.73935 20.0362L8.76214 20.0532L8.79317 20.1341L8.79716 20.127L10.0122 22.7498L10.05 22.8313L10.0963 22.7542L10.7872 21.602L12.1106 24.0625L12.1494 24.1344L12.1929 24.0653L12.9121 22.9222L14.2918 25.1831L14.331 25.2472L14.3723 25.1846L15.133 24.0306L15.2437 24.0877L15.2316 24.0677L17.3132 24.9268L17.3441 24.9733L17.3623 24.947L17.471 24.9919L17.4024 24.8888Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.1101 22.6268L11.0576 16.0841L5.06452 9.34375L4.99023 9.4032L7.44606 12.8298L7.4488 12.8335L7.45224 12.8368L10.9972 16.1591L11.0195 16.1374L11.006 16.1657L19.8593 21.2453L19.8665 21.2494L19.8746 21.2508L28.0846 22.7181L28.1101 22.6268Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.9043 22.6879L13.7156 21.5524L11.5686 20.212L9.5652 18.7263L8.45421 15.8216L7.53037 12.7871L7.49646 12.6758L7.44263 12.779L6.74152 14.1244L6.72559 14.1548L6.74934 14.1793L8.35068 15.8449L7.65437 17.1228L7.6361 17.1561L7.66467 17.1813L9.48652 18.7853L10.7459 21.5261L10.7836 21.6082L10.8301 21.5308L11.5392 20.3482L12.8706 22.8547L12.9092 22.9274L12.953 22.8577L13.691 21.6848L15.0757 23.9937L15.1147 24.0587L15.1563 23.9955L15.9385 22.809L18.1379 23.729L18.1686 23.7764L18.187 23.7496L18.2938 23.7943L18.227 23.6915L19.0548 22.4895L19.0879 22.4414L19.0342 22.4186L16.7768 21.4614L16.7451 21.4127L16.7268 21.4403L16.6199 21.3948L16.6878 21.4994L15.9043 22.6879Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.8963 21.1582L19.8682 21.2492L22.1164 22.0009L22.1184 22.0016L22.1206 22.0021L24.2697 22.5028L24.2936 22.4105L19.8963 21.1582Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M12.2995 18.8951L10.3078 17.4263L9.19279 14.4987L9.19911 14.487L9.18139 14.469L9.14816 14.3815L9.12949 14.4157L7.51896 12.7676L7.43945 12.8148L8.35794 15.8316L8.34914 15.8476L8.36768 15.8638L8.39679 15.9594L8.40174 15.9503L9.47318 18.7519L9.46343 18.7691L9.48623 18.786L9.51726 18.867L9.54047 18.8262L11.4788 20.2638L11.4768 20.2669L11.5044 20.284L11.536 20.3535L11.5577 20.3173L13.6263 21.6088L13.6243 21.6121L13.6569 21.6291L13.6884 21.6891L13.7072 21.6592L13.8158 21.727L13.8047 21.7058L15.8865 22.7856L15.9183 22.8397L15.9367 22.8117L16.0458 22.8682L15.9767 22.7511L16.7846 21.5256L16.8137 21.4815L16.7669 21.4571L14.5254 20.2877L14.4926 20.2321L14.4763 20.2582L12.3496 18.931L12.3162 18.8672L12.2995 18.8951ZM19.8643 21.1462L19.7633 21.1124L19.8253 21.2026L18.9765 22.4353L18.9403 22.4878L19.001 22.5075L21.2164 23.2295L21.2456 23.2722L21.265 23.2453L21.3652 23.2781L21.3047 23.1898L22.17 21.9822L22.2075 21.9297L22.1464 21.9093L19.9133 21.1626L19.8832 21.1186L19.8643 21.1462Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.51777 12.7656L7.45117 12.8337L9.1066 14.528L9.10812 14.5294L9.10963 14.5308L10.999 16.1589L11.0628 16.088L7.51777 12.7656Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.5751 20.1547L15.3463 18.9851L15.3138 18.9286L15.2951 18.9581L15.1883 18.9021L15.2564 19.0198L14.4962 20.2277L13.1603 17.6463L13.1689 17.6319L13.143 17.6128L13.1088 17.5469L13.0918 17.5753L11.0689 16.092L11.0357 16.0184L11.0179 16.0493L9.17234 14.459L9.09668 14.512L10.2166 17.4521L10.2067 17.4694L10.2296 17.4862L10.2605 17.5673L10.2839 17.5263L12.2511 18.9771L12.2494 18.9801L12.2768 18.9971L12.3083 19.0669L12.3282 19.0338L12.4327 19.111L12.4224 19.088L14.4264 20.3387L14.4244 20.3418L14.4685 20.3649L14.6143 20.4559L14.6037 20.4355L16.677 21.5171L16.6745 21.5208L16.7124 21.5369L16.743 21.5897L16.7618 21.5613L16.8691 21.6173L16.8582 21.5987L18.9842 22.5002L19.0149 22.5484L19.0337 22.5213L19.1387 22.5657L19.0736 22.4632L19.9232 21.2296L19.9561 21.1818L19.9027 21.1588L17.6235 20.1789L17.5919 20.1291L17.5751 20.1547Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.0543 16.082L11.0039 16.1628L15.2852 19.0605L15.288 19.0624L15.2905 19.0636L17.5689 20.2592L17.5706 20.26L17.5722 20.2607L19.8649 21.2466L19.8784 21.2169L19.9075 21.1615L11.0543 16.082Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.352 19.0476L15.3774 19.0071L15.3369 18.9818L13.1306 17.6055L12.9834 17.5137L13.0632 17.6679L14.4496 20.3468L14.4878 20.4208L14.5321 20.3504L15.352 19.0476Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M11.0567 16.084L11.002 16.1618L13.077 17.6833L13.0784 17.6844L13.0799 17.6853L15.2862 19.0616L15.3381 18.9817L11.0567 16.084Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </motion.svg>
  );
};
export default FeaturesTopRightMobile;
