import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const Pending: FC<IconProps> = ({ className, ...props }) => {
  return (
    <section
      {...props}
      className={`fixed ${className} inset-0 z-[999] flex origin-center items-center justify-center bg-black/50 transition-transform`}
    >
      <div className="h-12 w-12 animate-ping rounded-full bg-blue-500"></div>
    </section>
  );
};

export default Pending;
