/* eslint-disable @typescript-eslint/no-empty-interface */
import { useGetWinnersQuery } from '@util/services/EditionsApiConnection';
import data from '@public/celebrated_professionals/professionals.json'
import { NextFont } from 'next/dist/compiled/@next/font';
import React, { useCallback, type FC, useRef, useEffect } from 'react';
import Editions, { IEditionsProps } from './Editions';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Loading } from './util';

export interface IWinnerCarouselProps {
  font: NextFont
}
const autoplayOptions = {
  delay: 4000,
  rootNode: (emblaRoot: HTMLElement) => emblaRoot.parentElement
};
const WinnerCarousel: FC<IWinnerCarouselProps> = (props) => {
  const { data: winnersData, isLoading: isWinnersLoading } = useGetWinnersQuery(undefined);
  const sliderRef = useRef(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const [isMobile, setIsMobile] = React.useState(false)
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    (sliderRef.current as any).swiper.slideNext();
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }, [])

  return isWinnersLoading ? <div className='min-h-[400px] w-full grid place-content-center'><Loading className="mt-8" /> </div> : (
    <div className="relative w-full flex flex-col space-y-12 py-12 items-center">
      <h1 className='text-4xl font-bold'>Celebrated Professionals</h1>
      <div className='relative w-full flex lg:gap-10 gap-2 overflow-x-scroll  items-center'>
        <div onClick={handlePrev} className='rotate-180 cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "24" : "55"} height={isMobile ? "24" : "55"} viewBox="0 0 55 56" fill="none">
            <circle cx={(isMobile ? 24 : 55) / 2} cy={(isMobile ? 24 : 56) / 2} r={(isMobile ? 24 : 55) / 2} fill="white" />
            <path d="M21.2885 13.5L19 16L31.0962 28.5L19 41L21.2885 43.5L36 28.5L21.2885 13.5Z" fill="black" />
          </svg>
        </div>
        <Swiper
          ref={sliderRef}
          slidesPerView={!isMobile ? 3 : 1}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            enabled: false,
          }}
          modules={[Pagination, Autoplay, Navigation]}
          className="mySwiper relative w-full"
        >
          {data.map((props) => (
            <SwiperSlide key={props.name}>
              <Editions
               Name={props?.name}
               user_image_url={`/celebrated_professionals${props?.image}`}
                 className='min-w-[280px] h-[374px] mx-auto' 
                 />
            </SwiperSlide>
          ))}
        </Swiper>
        <div onClick={handleNext} className='cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "24" : "55"} height={isMobile ? "24" : "56"} viewBox="0 0 55 56" fill="none">
            <circle cx={(isMobile ? 24 : 55) / 2} cy={(isMobile ? 24 : 56) / 2} r={(isMobile ? 24 : 55) / 2} fill="white" />
            <path d="M21.2885 13.5L19 16L31.0962 28.5L19 41L21.2885 43.5L36 28.5L21.2885 13.5Z" fill="black" />
          </svg>
        </div>
      </div>
    </div>
  );
}
export default WinnerCarousel;