import React, { type FC } from 'react';

import type { IconProps, Props, PropTypes } from '../../typescript';

const WhatsappIcon: FC<PropTypes<Props, IconProps>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.35983 4.76556e-05C4.27169 4.76556e-05 0.939782 3.56004 0.939782 7.92802C0.939782 9.32802 1.2842 10.688 1.92812 11.888L0.879883 16L4.81079 14.896C5.89647 15.528 7.11692 15.864 8.35983 15.864C12.448 15.864 15.7799 12.304 15.7799 7.93602C15.7799 5.81603 15.0087 3.82404 13.6085 2.32804C12.9221 1.58718 12.1044 0.999779 11.2033 0.600087C10.3021 0.200395 9.33553 -0.00358408 8.35983 4.76556e-05ZM8.36732 1.33604C10.0146 1.33604 11.557 2.02404 12.725 3.27204C13.2983 3.88432 13.7527 4.61149 14.0624 5.41184C14.372 6.21219 14.5308 7.06998 14.5295 7.93602C14.5295 11.568 11.7591 14.52 8.35983 14.52C7.25169 14.52 6.16601 14.208 5.2226 13.6L4.99797 13.464L2.66189 14.12L3.28335 11.688L3.1336 11.432C2.51509 10.383 2.18795 9.16788 2.19018 7.92802C2.19767 4.29603 4.96054 1.33604 8.36732 1.33604ZM5.73174 4.26403C5.61194 4.26403 5.40978 4.31203 5.23757 4.51203C5.07285 4.71203 4.58616 5.20003 4.58616 6.16803C4.58616 7.14403 5.25255 8.08002 5.33491 8.21602C5.43973 8.35202 6.6527 10.352 8.51707 11.2C8.95883 11.416 9.30325 11.536 9.5728 11.624C10.0146 11.776 10.4189 11.752 10.7408 11.704C11.1002 11.648 11.834 11.224 11.9912 10.76C12.1485 10.296 12.1485 9.90402 12.1036 9.81602C12.0511 9.73602 11.9313 9.68802 11.7442 9.60002C11.557 9.48802 10.6435 9.00802 10.4788 8.94402C10.3066 8.88002 10.2017 8.84802 10.0595 9.04002C9.93968 9.24002 9.58028 9.68802 9.47546 9.81602C9.36315 9.95202 9.25832 9.96802 9.07863 9.87202C8.88395 9.76802 8.28496 9.56002 7.58114 8.88802C7.02707 8.36002 6.66018 7.71202 6.54787 7.51203C6.45802 7.32003 6.54038 7.20003 6.63023 7.11203C6.7126 7.02403 6.8324 6.88003 6.90727 6.76003C7.00461 6.64803 7.03456 6.56003 7.09446 6.43203C7.15435 6.29603 7.1244 6.18403 7.07948 6.08803C7.03456 6.00003 6.66018 5.00803 6.50295 4.61603C6.3532 4.23204 6.20345 4.28004 6.08365 4.27204C5.97883 4.27204 5.85903 4.26403 5.73174 4.26403Z"
        fill="white"
      />
    </svg>
  );
};
export default WhatsappIcon;
