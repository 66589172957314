import React, { type FC, useId } from 'react';

import { Inter } from 'next/font/google';
import Image from 'next/image';
import Link from 'next/link';

import { type Props } from '../typescript';

import {
  FooCenter,
  FootBottom,
  FooTopLeft,
  LinkedInIcon,
  MailIcon,
  WhatsappIcon,
  Xcom,
} from './icons';
import { FootLink } from './util';
import { FacebookIcon, Linkedin, LinkedinIcon, Twitter } from 'lucide-react';
import Facebook from 'next-auth/providers/facebook';

// If loading a variable font, you don't need to specify the font weight
const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

const about = [
  {
    text: 'About us',
    link: '/about-us',
  },
  {
    text: 'Editions',
    link: '/editions',
  },
  { text: 'Celebrated Techies', link: '/the-celebrated-techies' },
];
const legal = [
  { text: 'Terms Of Service', link: '/terms-of-service' },
  { text: 'Privacy Policy', link: '/privacy-policy' },
];
const quickLinks = [
  {
    text: 'Categories',
    link: '/attest',
  },
  {
    text: 'Winners',
    link: '/winners',
  },
  {
    text: 'FAQs',
    link: '/information',
  },
];
const items = [
  { section: 'Quick Links', links: quickLinks },
  { section: 'About', links: about },
  { section: 'Legal', links: legal },
];
const contact = [
  { Icon: Xcom, link: 'https://twitter.com/africatechtrend' },
  {
    Icon: LinkedInIcon,
    link: 'https://www.linkedin.com/company/theconnectedawards/?originalSubdomain=uk',
  },
  { Icon: WhatsappIcon, link: 'https://wa.link/vx8sts' },
  { Icon: MailIcon, link: 'mailto:hello@theconnectedawards.com' },
];
const Footer: FC<Props> = () => {
  const Id = useId();
  return (
    <div
      className={`relative flex w-full flex-col justify-between gap-20 bg-[#1F0231] p-6 text-xs font-light text-white lg:flex-row lg:px-12 lg:pb-36 lg:pt-16 lg:text-[15px] ${inter.className}`}
    >
      <div className="z-10 flex w-fit flex-col items-start justify-between gap-8">
        <span className="flex flex-col gap-3">
          <Image
            src="/connectedAwards.png"
            width={160}
            height={97.53}
            className="mr-auto h-auto w-28 lg:w-36"
            alt="Logo"
          />
          <span className="font-light">Celebrating Techies Globally</span>
          <div className="flex space-x-4">
            {contact.map(({ Icon, link }, i) => (
              <Link
                href={link}
                key={`${Id}-${i}`}
                className="mr-2"
                target="_blank"
              >
                <Icon className="h-6 w-6 text-white" />
              </Link>
            ))}
          </div>
        </span>
        <span className="z-10 text-xs lg:text-base">
          &copy; {new Date().getFullYear()} The Connected Awards - All rights
          reserved
        </span>
      </div>
      <div className="z-10 grid w-fit basis-6/12 grid-cols-2 gap-8 lg:grid-cols-3">
        {items.map(({ section, links }, i) => (
          <div className="flex flex-col gap-4" key={`${Id}-${i}`}>
            <span className="w-fit text-xl font-medium text-golden">
              {section}
            </span>
            {links.map((data: { text: string; link: string }, i) => (
              <FootLink
                className="w-fit cursor-pointer"
                key={`${i} + ${Id}`}
                {...{ ...data, sameSite: true }}
              />
            ))}
          </div>
        ))}
      </div>
      <FooTopLeft className="absolute right-0 top-0 z-0" />
      <FooCenter className="absolute bottom-1/3 right-[40%] z-0" />
      <FootBottom className="absolute bottom-0 left-[25%] z-0" />
    </div>
  );
};

export default Footer;
