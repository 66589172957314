import React, { type FC } from 'react';

import { type IconProps } from '../../typescript';

const FooCenter: FC<IconProps> = (props) => {
  return (
    <svg
      {...props}
      width="74"
      height="64"
      viewBox="0 0 74 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.9466 9.77209L47.9432 9.76993L47.9398 9.76808L44.6155 7.91962L44.6133 7.91838L44.6108 7.91714L41.2164 6.24417L41.2142 6.24293L41.1818 6.30627L41.209 6.24046L37.7546 4.75533L37.7518 4.7541L37.749 4.75317L34.2501 3.47534L34.1738 3.67555L41.1194 6.43541L54.2047 14.1311L60.0971 18.9087L60.2342 18.744L57.3542 16.2792L57.352 16.2774L57.3499 16.2755L54.3314 13.9578L54.2872 14.0137L54.3268 13.9547L51.1894 11.7868L51.1872 11.7852L51.1847 11.784L47.9466 9.77209Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.8164 22.4772L40.7543 22.2291L40.6212 22.4472L39.4453 24.3704L38.553 21.2454L38.4851 21.0078L38.3581 21.2197L37.0719 23.3654L37.0142 23.4615L37.1127 23.515L39.3554 24.7273L41.5233 26.0768L43.6053 27.5579L43.829 27.717L43.7722 27.4482L43.0097 23.8452L42.9547 23.5851L42.8138 23.8109L41.6256 25.7165L40.8164 22.4772Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M65.343 24.2787L65.4919 24.1245L60.2413 18.7506L60.237 18.7463L60.232 18.7423L54.3335 13.9597L54.1938 14.1222L65.343 24.2787Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.9958 23.9241L43.0526 23.833L42.9615 23.7764L40.8594 22.468L40.8628 22.4628L40.7664 22.4103L40.5446 22.2722L40.5495 22.2926L38.5011 21.1803L38.2769 21.0586L38.347 21.3039L39.3063 24.6641L39.3733 24.8989L39.5009 24.6903L40.6706 22.777L41.4788 26.0136L41.54 26.2586L41.6738 26.0442L42.9958 23.9241ZM45.1158 25.3407L45.0692 25.0663L44.9199 25.3011L43.5769 27.4128L43.5259 27.4934L43.6001 27.5534L45.578 29.1584L45.7992 29.3379L45.751 29.0574L45.1158 25.3407Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.2217 14.1403L54.3202 13.9503L41.221 6.24659L41.2111 6.24072L41.2 6.23701L27.1698 1.63672L27.0923 1.8363L54.2217 14.1403Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.0903 20.0549L35.9568 19.9924L35.963 20.0113L33.863 19.1892L33.8019 19.0152L33.7314 19.1376L33.5998 19.086L33.661 19.2602L32.4276 21.4007L32.3652 21.5091L32.482 21.5542L34.7778 22.4384L34.8381 22.6093L34.9057 22.494L37.0764 23.4978L37.1342 23.6789L37.2065 23.5581L37.3403 23.6199L37.281 23.4339L38.4893 21.4176L40.6587 22.5957L42.8097 23.9344L43.5629 27.4926L43.6173 27.7493L43.7581 27.5278L44.9516 25.6506L45.5331 29.0522L45.5068 29.0926L45.5464 29.1291L45.5862 29.3639L45.6662 29.2406L47.4251 30.8747L47.6417 31.0758L47.6043 30.7826L47.1223 26.9942L47.1535 26.946L47.1118 26.9127L47.0803 26.6649L46.9858 26.811L45.1524 25.3352L45.1555 25.3299L45.1067 25.2956L44.355 21.6886L44.3007 21.4269L44.1592 21.6537L42.9487 23.5951L42.1426 20.3088L42.0815 20.0589L41.9471 20.2785L40.7496 22.237L39.862 19.0625L39.7949 18.8228L39.6667 19.0362L38.408 21.1365L36.2855 20.1457L36.2895 20.1392L36.22 20.112L36.1626 19.9319L36.0903 20.0549Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M65.3385 24.2743L65.4942 24.1269L62.9624 21.3649L62.9602 21.3624L62.9577 21.3602L60.2387 18.748L60.0879 18.9004L65.3385 24.2743Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M44.341 21.7672L44.3979 21.6764L44.3067 21.6195L42.095 20.2435L41.8716 20.1047L41.9343 20.3602L42.8009 23.8931L42.8615 24.14L42.9959 23.9243L44.341 21.7672Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.1331 6.44082L41.2061 6.23939L34.2528 3.47643L34.2466 3.47396L34.2401 3.4721L27.1633 1.63477L27.103 1.84053L41.1331 6.44082Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M42.0004 20.6021L42.0736 20.4825L44.1544 21.7773L44.8965 25.338L44.8684 25.3822L44.9132 25.418L44.9592 25.6389L45.0364 25.5172L46.9099 27.0255L47.3866 30.7703L47.3619 30.8077L47.3959 30.8441L47.428 31.0966L47.5124 30.969L49.0998 32.6741L49.1214 32.9425L49.2172 32.8001L49.3349 32.9268L49.3133 32.6571L50.7085 30.5834L50.7564 30.512L50.6974 30.4499L49.0238 28.6806L49.0275 28.6753L48.991 28.6419L48.9697 28.3738L48.9576 28.3917L48.5396 25.0742L48.552 25.084L48.5128 24.8492L48.543 24.8029L48.501 24.7692L48.4698 24.5214L48.4606 24.536L47.8726 21.0062L47.8266 20.7303L47.6764 20.9663L46.4357 22.9174L46.4283 22.9291L45.7322 19.5359L45.7665 19.4812L45.7143 19.4491L45.6698 19.2313L45.5842 19.3681L43.5343 18.0931L43.5374 18.0878L43.4806 18.0566L43.4311 17.8512L43.3523 17.98L43.222 17.8987L43.2266 17.9176L41.1813 16.798L41.127 16.5996L41.051 16.7266L40.92 16.6546L40.9743 16.8542L39.7194 18.9483L39.5868 18.876L39.5921 18.8948L37.5783 17.9454L37.582 17.9389L37.5135 17.9117L37.4566 17.7294L37.3834 17.8536L37.2515 17.7915L37.257 17.8098L35.1432 16.9685L35.0829 16.7918L35.0113 16.916L34.8812 16.8641L34.9408 17.0383L33.7282 19.1432L31.4818 18.4369L31.4191 18.2726L31.3496 18.3952L31.221 18.3548L31.2822 18.5148L30.0606 20.6768L29.9951 20.793L30.1224 20.8322L32.4389 21.5413L32.5007 21.7032L32.5709 21.5818L32.6988 21.621L32.6388 21.4637L33.8249 19.4046L36.0478 20.2749L36.1074 20.4488L36.1763 20.3314L38.3618 21.3519L38.419 21.5351L38.4922 21.4128L38.6245 21.4746L38.5667 21.2886L39.7225 19.3604L40.6095 22.5321L40.6756 22.7691L40.8041 22.559L42.0004 20.6021Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.2834 14.1484L54.6316 14.0909L54.31 13.9451L27.1806 1.64111L27.0796 1.82957L48.3726 15.0994L48.4068 15.1207L48.4467 15.1139L54.2834 14.1484Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.845 15.7217L38.7739 15.4878L38.6501 15.6985L37.3342 17.9381L37.2749 18.0394L37.3809 18.0895L39.7132 19.1884L39.9316 19.2916L39.8611 19.0605L38.845 15.7217ZM47.8574 21.0814L47.9124 20.9952L47.8287 20.9362L45.6806 19.4279L45.4597 19.2731L45.514 19.5372L46.2719 23.2317L46.325 23.4915L46.4672 23.2678L47.8574 21.0814ZM50.3915 26.6104L50.4421 26.5337L50.3742 26.472L48.4726 24.7468L48.2578 24.5518L48.2943 24.8395L48.7815 28.706L48.818 28.993L48.9774 28.7514L50.3915 26.6104Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3262 14.1314L54.2483 13.9371L48.4116 14.9026L48.1515 14.9455L48.368 15.0963L50.5604 16.6219L50.6212 16.6642L50.6821 16.6225L54.3262 14.1314ZM34.1859 3.67991L34.2434 3.47322L30.7136 2.42557L30.7102 2.42464L30.7065 2.42371L27.1594 1.63403L27.1094 1.84258L34.1859 3.67991Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M41.1819 16.9248L41.2424 16.8238L41.1359 16.7731L38.7884 15.6559L38.5691 15.5515L38.6398 15.7838L39.656 19.1227L39.7261 19.3535L39.8503 19.1465L41.1819 16.9248ZM50.735 30.8014L50.7507 30.8178L50.7266 30.5174L50.7168 30.1869L50.7019 30.2088L50.4087 26.5425L50.384 26.2326L50.2125 26.4921L48.7982 28.6332L48.7512 28.7042L48.8099 28.7657L50.4829 30.5347L49.1145 32.5683L49.071 32.6335L49.1195 32.6946L50.6553 34.6342L50.8561 34.8876L50.8465 34.5644L50.735 30.8014ZM31.4508 18.6515L33.746 19.3733L33.9546 19.4388L33.8786 19.2339L32.7694 16.2411L32.6891 16.0245L32.5754 16.2256L31.3464 18.401L29.0401 17.8631L28.8374 17.8158L28.9168 18.0083L30.0547 20.7703L30.1387 20.9742L30.2472 20.7824L31.4508 18.6515Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3663 14.0805L54.4855 13.7629L54.2056 13.9545L50.5615 16.4456L50.4404 16.5284L50.5563 16.6186L52.6522 18.2499L52.7671 18.3394L52.8184 18.2032L54.3663 14.0805Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.1149 17.9269L29.0299 17.7209L28.9221 17.9158L27.6848 20.1477L27.6152 20.2731L27.7552 20.3043L30.1304 20.8342L30.3312 20.8793L30.2531 20.689L29.1149 17.9269ZM49.9148 22.5881L49.8839 22.34L49.7881 22.4877L47.8639 20.9642L47.1166 17.2608L47.0631 16.9964L46.9207 17.2253L45.6639 19.2406L44.8646 15.8576L44.8047 15.6042L44.6691 15.8264L43.4265 17.859L42.5484 14.5813L42.4832 14.3369L42.3532 14.5541L40.9981 16.815L40.9406 16.9105L41.0386 16.9639L43.3335 18.2204L45.5595 19.6052L47.6684 21.086L48.2875 24.8027L48.2606 24.8441L48.3005 24.8802L48.3397 25.1159L48.4216 24.9902L50.2299 26.6307L50.444 26.8251L50.4082 26.538L49.9253 22.6706L49.9568 22.6215L49.9148 22.5881ZM33.8709 19.3246L35.1045 17.1836L37.3404 18.0737L37.3991 18.2501L37.472 18.1259L37.6009 18.1772L37.5434 18.0048L38.8345 15.8069L38.8975 15.7L38.7826 15.6534L36.4463 14.708L36.3867 14.5285L36.3141 14.6545L36.1852 14.6023L36.2436 14.7772L35.0081 16.9213L32.7018 16.1764L32.4917 16.1088L32.5684 16.3158L33.6775 19.3086L33.7569 19.5227L33.8709 19.3246Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3729 14.0351L54.1659 14.0051L52.6177 18.1278L52.5918 18.1973L52.6477 18.2461L54.6278 19.9765L54.8246 20.1483L54.8051 19.8879L54.3729 14.0351Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M47.0706 17.1925L46.8534 17.0408L46.8575 17.0599L44.9073 15.8473L44.9104 15.8423L44.8147 15.7895L44.5965 15.6539L44.6006 15.6721L42.5942 14.5685L42.5973 14.5633L42.4941 14.5135L42.2766 14.394L42.2813 14.4113L40.1708 13.397L40.1143 13.2061L40.0392 13.334L39.9101 13.2719L39.9657 13.4591L38.6498 15.6984L38.5908 15.7991L38.6962 15.8494L41.0007 16.9462L41.0566 17.1341L41.132 17.0086L41.2611 17.0701L41.2061 16.8847L42.407 14.8809L43.2838 18.1555L43.3487 18.3968L43.4788 18.1839L44.7161 16.1603L45.5144 19.5402L45.5734 19.7904L45.7097 19.5723L46.9597 17.5672L47.6533 21.0028L47.6246 21.0479L47.6694 21.0834L47.7145 21.3058L47.7936 21.1816L49.7131 22.7017L50.1907 26.5253L50.1654 26.5636L50.1981 26.5979L50.5099 30.4947L50.4864 30.5293L50.5136 30.5627L50.6322 34.571L50.6421 34.8997L50.8278 34.6282L52.1906 32.6379L52.3002 32.7735L52.2916 32.4905L52.3169 32.4534L52.2894 32.4195L52.1764 28.6561L52.1915 28.6719L52.1705 28.4099L52.198 28.3694L52.1665 28.3367L52.1578 28.045L52.1433 28.0666L51.851 24.4006L51.8816 24.354L51.8449 24.3209L51.8235 24.0534L51.7204 24.2094L49.9136 22.5902L49.2942 18.8219L49.3272 18.7697L49.2802 18.7369L49.2419 18.5043L49.1514 18.6467L47.1562 17.2524L47.1593 17.2475L47.0706 17.1925Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3685 14.0125L54.1587 14.0508L54.5909 19.9039L54.594 19.9437L54.6224 19.9721L56.4663 21.7962L56.6445 21.6896L54.3685 14.0125Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M32.719 16.4072L34.9746 17.1354L35.0349 17.3038L35.1072 17.1784L35.2323 17.2188L35.1754 17.0604L36.4063 14.9233L38.6555 15.8332L38.7135 16.0127L38.7877 15.8866L38.9147 15.9382L38.8588 15.7655L40.1239 13.6125L42.3956 14.7043L44.6683 15.954L44.7202 16.153L44.7953 16.0303L46.9168 17.3495L46.9653 17.5584L47.0426 17.4345L49.0829 18.8609L49.7014 22.6215L49.6739 22.6635L49.7141 22.6997L49.753 22.9357L49.8355 22.8084L51.6376 24.4236L51.9534 28.3871L52.0751 32.427L50.6508 34.5072L50.61 34.5665L50.6496 34.6267L51.9487 36.6068L52.1335 36.8886L52.1455 36.5518L52.2892 32.4937L53.7416 30.3725L53.7873 30.3057L53.736 30.2436L52.194 28.3748L53.6165 26.2603L53.665 26.188L53.6041 26.1256L51.878 24.3593L53.2958 22.2134L53.347 22.1355L53.2772 22.074L51.3929 20.406L51.396 20.4014L51.3537 20.368L51.3231 20.1199L51.3141 20.1335L50.7339 16.5571L50.7673 16.5045L50.72 16.4718L50.6823 16.2389L50.5912 16.3822L48.5741 14.9786L48.5768 14.974L48.5252 14.9419L48.4814 14.7219L48.3976 14.856L48.2722 14.7686L48.2759 14.7868L46.3045 13.5615L46.3076 13.5568L46.2513 13.5256L46.2028 13.3171L46.1225 13.4484L45.9949 13.3693L45.9989 13.3863L43.9163 12.2361L43.8632 12.034L43.7853 12.1638L43.6571 12.093L43.709 12.2911L42.4794 14.3428L41.5479 11.1229L41.4787 10.8838L41.3523 11.0985L40.0362 13.3387L37.7725 12.4072L37.7135 12.2246L37.6399 12.3525L37.5127 12.3003L37.5692 12.4752L36.3108 14.6595L34.0431 13.9078L33.9816 13.7366L33.91 13.8636L33.7845 13.8222L33.8423 13.9832L32.6151 16.1551L30.3542 15.6046L30.2912 15.4461L30.2211 15.5724L30.0987 15.5425L30.1568 15.6883L28.9578 17.851L26.7151 17.5259L26.6511 17.381L26.5825 17.5068L26.4648 17.4898L26.5217 17.6186L25.3165 19.8332L25.2417 19.9701L25.3962 19.9908L27.7044 20.2979L27.7674 20.4403L27.8366 20.3155L27.9534 20.3312L27.8978 20.2052L29.0702 18.0904L31.3213 18.6153L31.3834 18.7713L31.4538 18.6462L31.5755 18.6746L31.5187 18.5316L32.719 16.4072Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.1034 13.689L43.7724 12.4013L43.7696 12.3998L43.7668 12.3982L27.0797 1.82961L27.0791 1.8293L27.1906 1.65165V1.65135L46.1034 13.689ZM46.1034 13.689L27.1906 1.65135L46.1034 13.689ZM46.1034 13.689L48.3727 15.0994L48.4858 14.9174V14.9171L27.1956 1.6495L27.1937 1.64826L46.1034 13.689ZM27.1906 1.65135L27.1931 1.64795L27.1906 1.65135ZM54.3379 13.9637L54.2591 14.0394L54.1631 14.0734L56.4391 21.7505L56.4459 21.7731L56.4614 21.791L58.1458 23.7043L58.3253 23.5928L54.6293 14.6422L71.9914 35.3116L72.1651 35.1875L65.5068 24.144L65.4988 24.1307L65.4874 24.1202L54.3379 13.9637Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.916 12.3625L43.9763 12.2621L43.8709 12.2111L41.4917 11.0563L41.2751 10.9509L41.3418 11.1823L42.3419 14.6386L42.4102 14.8749L42.5369 14.6639L43.916 12.3625ZM53.5995 34.4924L53.6415 34.4322L53.6004 34.3713L52.2722 32.3987L52.0881 32.1249L52.0764 32.4549L51.9315 36.5442L51.9185 36.9078L52.1264 36.6091L53.5995 34.4924ZM53.6431 26.472L53.6579 26.4872L53.6347 26.1946L53.6245 25.8643L53.61 25.8857L53.3131 22.1455L53.2884 21.835L53.1169 22.0948L51.6515 24.3131L51.604 24.3851L51.6645 24.4469L53.39 26.2128L51.9716 28.3211L51.9271 28.3876L51.9778 28.4493L53.5708 30.3797L53.77 30.6216L53.7605 30.3083L53.6431 26.472ZM34.0098 14.1223L36.3266 14.8904L36.5327 14.9587L36.4613 14.7535L35.3757 11.6312L35.2978 11.4073L35.181 11.6136L33.9069 13.869L31.6376 13.2925L31.5752 13.1297L31.5039 13.2585L31.3834 13.2279L31.4396 13.3743L30.1816 15.6436L30.1133 15.7666L30.2498 15.7996L32.5889 16.369L32.6501 16.5291L32.7221 16.4015L32.8419 16.4308L32.7869 16.2871L34.0098 14.1223Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1936 1.64819L27.0771 1.82831L27.0787 1.82924L41.3859 11.2421L41.3918 11.2461L41.398 11.2492L43.7772 12.404L43.8813 12.2171L27.1952 1.64912L27.1936 1.64819Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M52.8188 18.1079L52.7883 17.8595L52.6915 18.0087L50.6877 16.4491L50.4724 16.2817L50.5159 16.5508L51.1406 20.4012L51.1128 20.4442L51.1533 20.4797L51.1916 20.7164L51.275 20.5878L53.1355 22.2342L53.3471 22.4218L53.3129 22.1409L52.829 18.1907L52.8612 18.1409L52.8188 18.1079ZM37.7791 12.5409L37.8439 12.4278L37.7207 12.3858L35.309 11.5649L35.1011 11.4941L35.1734 11.7014L36.259 14.824L36.3363 15.0452L36.453 14.8423L37.7791 12.5409ZM53.7605 30.3155L53.7732 29.9469L53.565 30.2512L52.095 32.3981L52.0539 32.4581L52.0947 32.5183L53.4228 34.4913L53.6076 34.7653L53.6187 34.4351L53.7605 30.3155Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M69.4371 29.8802L69.6129 29.7573L67.7465 26.965L67.7443 26.9616L67.7415 26.9582L65.4992 24.1328L65.3286 24.2626L69.4371 29.8802Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.3692 15.7476L30.4437 15.6138L30.2923 15.5901L27.9699 15.2252L27.9062 15.0757L27.8364 15.2042L27.7203 15.1859L27.7756 15.316L26.5475 17.5726L26.4736 17.7076L26.6263 17.7299L28.9409 18.0651L29.0033 18.2121L29.0741 18.0845L29.189 18.1012L29.1353 17.9739L30.3692 15.7476ZM38.9905 10.0092L38.8648 9.95663L38.9198 10.1318L37.5937 12.4341L37.5326 12.5401L37.6456 12.5867L39.9946 13.5531L40.0521 13.7357L40.1271 13.6078L40.2526 13.6594L40.1985 13.4864L41.5378 11.207L41.5999 11.1013L41.4868 11.054L39.1237 10.0651L39.0653 9.87939L38.9905 10.0092Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1955 1.64917L27.0747 1.8262L38.9743 10.2322L38.9839 10.239L38.9947 10.2433L41.4039 11.2517L41.504 11.0632L27.1955 1.64917Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M53.6182 34.4433L53.4239 34.3701L51.9508 36.4867L51.9137 36.5399L51.9437 36.5973L52.9555 38.5456L53.1174 38.8573L53.1573 38.5082L53.6182 34.4433ZM55.1102 24.0403L55.1593 23.9674L55.0972 23.905L53.2824 22.0785L53.0766 21.8712L53.0998 22.1625L53.4208 26.2064L53.5466 30.3148L53.5564 30.6432L53.7421 30.3723L55.2387 28.1864L55.2847 28.1191L55.2322 28.0567L53.6618 26.1934L55.1102 24.0403ZM26.7354 17.6747L26.8173 17.5245L26.6464 17.5165L24.3722 17.4099L24.1992 17.4019L24.269 17.56L25.3127 19.9275L25.4007 20.1271L25.5049 19.9356L26.7354 17.6747Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3629 13.9973L54.167 14.0838L58.1271 23.6743L58.1324 23.687L58.1404 23.6978L59.5934 25.6343L59.7763 25.5243L54.3629 13.9973Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.8014 19.8439L54.7804 19.5769L54.6763 19.7345L52.7883 18.0844L52.5773 17.9003L52.6112 18.1783L53.0951 22.1279L53.0688 22.1678L53.1043 22.2037L53.1352 22.4561L53.2233 22.3229L54.883 23.9937L53.4386 26.1406L53.3935 26.2077L53.4457 26.2698L55.068 28.1948L55.2667 28.4306L55.2571 28.1225L55.1366 24.2489L55.1508 24.2634L55.1283 23.9746L55.1178 23.6446L55.1036 23.6656L54.8079 19.9242L54.8391 19.8766L54.8014 19.8439ZM24.4651 17.4739L24.3764 17.2725L24.2726 17.4665L23.0461 19.7583L22.9648 19.91L23.1372 19.9159L25.4071 19.9916L25.5773 19.9971L25.5088 19.8411L24.4651 17.4739ZM55.0995 32.3376L55.1422 32.2767L55.0999 32.2155L53.7417 30.2509L53.5576 29.9846L53.5464 30.3081L53.4046 34.4276L53.3923 34.7907L53.5996 34.4925L55.0995 32.3376Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1983 1.65137L27.0698 1.82253L36.5463 9.38134L36.5605 9.39277L36.5778 9.39864L39.0006 10.2458L39.0979 10.057L27.1983 1.65137Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M35.3245 11.7967L37.6018 12.5719L37.6608 12.7468L37.7352 12.6173L37.8573 12.6587L37.8035 12.499L39.1289 10.1979L39.1935 10.0861L39.0714 10.0434L36.6981 9.2136L36.6381 9.03564L36.564 9.16695L36.442 9.12431L36.4966 9.28651L35.2222 11.5419L32.9446 10.9382L32.8828 10.7713L32.8102 10.9026L32.6913 10.8711L32.746 11.0188L31.5009 13.2646L29.2468 12.8824L29.1841 12.7279L29.1124 12.8595L28.9984 12.8401L29.0515 12.9714L27.8327 15.2107L25.6228 15.0763L25.4507 15.0658L25.5174 15.2249L26.5428 17.6653L26.629 17.8707L26.7356 17.6749L27.9523 15.4393L30.1993 15.7921L30.2608 15.9435L30.333 15.8131L30.4464 15.831L30.3942 15.7028L31.6115 13.5071L33.8792 14.083L33.9401 14.247L34.0133 14.1173L34.1316 14.1473L34.0782 14.0027L35.3245 11.7967ZM55.2574 28.1295L55.2698 27.7621L55.0619 28.0655L53.5653 30.2514L53.5236 30.3122L53.5656 30.3728L54.8814 32.2762L53.5239 34.227L53.4442 34.0787L53.4087 34.3926L53.3864 34.4244L53.4019 34.4528L52.9443 38.4843L53.1383 38.5582L54.5403 36.5728L54.621 36.7227L54.6562 36.4088L54.6788 36.377L54.663 36.3482L55.0866 32.5728L55.1082 32.604L55.119 32.2799L55.2574 28.1295ZM56.631 21.7799L56.6805 21.7064L56.6174 21.644L54.7736 19.8196L54.5688 19.6169L54.5916 19.9043L54.9145 23.9892L54.9389 24.2954L55.1104 24.0405L56.631 21.7799Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.203 1.65503L27.0615 1.81537L34.118 8.70593L34.1378 8.72508L34.1643 8.7325L36.5843 9.40076L36.6798 9.21385L27.203 1.65503Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.7628 25.9609L56.8092 25.8929L56.7554 25.8302L55.1025 23.9107L54.9048 23.6812L54.9144 23.9839L55.0432 28.1294L55.0534 28.4572L55.2388 28.1866L56.7628 25.9609Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.3624 13.9954L54.1689 14.0884L54.1699 14.0899L59.5824 25.6154L59.5849 25.6209L59.5883 25.6262L60.7536 27.5148L60.9412 27.4113L54.363 13.9972L54.3624 13.9954Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.7151 15.1416L25.6279 14.9343L25.5217 15.1327L24.2729 17.4665L24.1929 17.616L24.3625 17.6241L26.6364 17.7306L26.806 17.7387L26.7402 17.582L25.7151 15.1416ZM56.6273 30.1431L56.6702 30.0813L56.6266 30.0198L55.2379 28.0639L55.0538 27.8049L55.0433 28.1222L54.9049 32.2727L54.8928 32.6351L55.0998 32.3376L56.6273 30.1431ZM56.6492 21.7167L56.6387 21.3843L56.4533 21.6602L54.9323 23.9208L54.8869 23.9885L54.9401 24.0506L56.5933 25.9701L56.791 26.1999L56.7814 25.8969L56.6492 21.7167Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1263 1.84516L27.1513 1.63229L23.7133 1.1494L23.7083 1.14878L23.7034 1.14848L20.5005 1.00049L20.4854 1.21428L27.1263 1.84516Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M54.6535 36.4123L56.1699 34.2647L56.2088 34.2097L56.176 34.151L55.1055 32.2241L54.9427 31.9312L54.9053 32.2643L54.4493 36.3298L52.9632 38.4343L52.9302 38.481L52.9506 38.5341L53.6717 40.437L53.8054 40.7898L53.8771 40.4197L54.6535 36.4123ZM36.7065 9.35024L36.7739 9.23067L36.6416 9.19422L34.2751 8.54048L34.2142 8.36963L34.14 8.50309L34.0229 8.47096L34.0758 8.61926L32.8069 10.9083L30.5472 10.4958L30.4851 10.3367L30.4122 10.4711L30.2995 10.4507L30.3514 10.5833L29.0757 12.9264L29.004 13.0583L29.152 13.0833L31.48 13.4781L31.5408 13.6342L31.6147 13.501L31.7262 13.5199L31.6758 13.3904L32.9166 11.1524L35.1926 11.7554L35.2526 11.9235L35.3273 11.7913L35.4438 11.8222L35.3919 11.6763L36.7065 9.35024ZM24.4618 17.5674L24.553 17.3972L24.3602 17.4099L22.3511 17.5436L22.2939 17.4003L22.2136 17.5526L22.1261 17.5585L22.1626 17.6493L20.9608 19.9291L20.8696 20.1018L21.0643 20.0857L23.0706 19.922L23.1268 20.0622L23.2078 19.9105L23.2943 19.9037L23.2588 19.8151L24.4618 17.5674ZM56.7813 25.9036L56.793 25.5365L56.5857 25.8396L55.062 28.0653L55.0196 28.1268L55.0629 28.188L56.4516 30.1436L56.6361 30.4031L56.6463 30.0852L56.7813 25.9036Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.2114 1.66211L27.0498 1.80175L31.706 8.22208L31.7307 8.25638L31.7721 8.26441L34.1724 8.73432L34.5249 8.80322L34.2678 8.55235L27.2114 1.66211Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.6458 30.0937L56.4512 30.0205L54.9241 32.215L54.8857 32.27L54.9182 32.3284L55.9887 34.255L56.1518 34.5488L56.1889 34.2148L56.6458 30.0937Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0128 35.3308L72.1559 35.1738L54.3482 13.9739L54.1699 14.09L60.7487 27.506L60.7611 27.531L60.784 27.5467L72.0128 35.3308Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.2639 13.0291L29.3433 12.8836L29.178 12.871L26.8961 12.6995L26.7246 12.6865L26.7886 12.8459L27.7945 15.3617L27.8792 15.5734L27.9882 15.3732L29.2639 13.0291ZM58.3146 23.6942L58.3616 23.6253L58.3066 23.5629L56.6225 21.6492L56.4254 21.4255L56.4347 21.7237L56.5669 25.9038L56.5774 26.2313L56.7625 25.9609L58.3146 23.6942Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.9815 35.298L72.1702 35.197L69.6205 29.7715L69.6161 29.7619L69.6103 29.7536L65.5015 24.1362L65.3232 24.2546L71.9815 35.298Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.9875 12.7664L26.9019 12.5526L26.7935 12.7556L25.55 15.0792L23.6008 15.178L23.5443 15.0282L23.4618 15.1852L23.3762 15.1895L23.4108 15.2815L22.1855 17.6058L22.0962 17.7754L22.2874 17.7627L24.295 17.6293L24.3503 17.7757L24.4337 17.62L24.5184 17.6141L24.4847 17.5245L25.6782 15.2942L27.8875 15.4286L28.0565 15.4388L27.9935 15.2819L26.9875 12.7664ZM55.2752 38.481L55.3382 38.6398L55.4031 38.3022L55.4235 38.2737L55.4133 38.2484L56.1832 34.2459L57.7279 32.0586L57.7672 32.0026L57.7332 31.9433L56.6657 30.0871L58.0968 28.0314L58.192 28.1621L58.2009 27.8818L58.2263 27.8451L58.2028 27.8132L58.3335 23.6368L58.3449 23.2707L58.1379 23.5729L56.6713 25.7145L56.5771 25.5854L56.5681 25.8653L56.5428 25.9021L56.566 25.9336L56.4322 30.0742L55.9786 34.1637L54.5794 36.1457L54.5173 35.989L54.4518 36.3261L54.4317 36.3548L54.4416 36.3796L53.6667 40.3789L53.8589 40.4614L55.2752 38.481ZM34.2865 8.6811L34.3582 8.55226L34.2136 8.52384L31.872 8.06536L31.8109 7.90161L31.7361 8.03879L31.6252 8.01716L31.6749 8.15093L30.3749 10.5394L30.3041 10.6695L30.4499 10.6961L32.7841 11.1218L32.8443 11.2828L32.9197 11.1468L33.0297 11.1666L32.9809 11.0362L34.2865 8.6811Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M29.4036 7.95616L29.4271 8.01733L29.4926 8.02413L31.781 8.26604L32.0205 8.29106L31.8793 8.09642L27.223 1.67578L27.0361 1.77712L29.4036 7.95616Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.1188 23.6301L57.9884 27.8117L56.4507 30.0207L56.4121 30.0763L56.4458 30.1354L57.5129 31.9909L56.1066 33.9827L56.0442 33.8326L55.9811 34.1601L55.9604 34.1894L55.9706 34.2144L55.201 38.2157L53.6844 40.337L53.6563 40.3762L53.6671 40.4235L54.0916 42.2648L54.2992 42.2694L55.4 38.3059L56.836 36.298L56.899 36.4503L56.9618 36.1219L56.9828 36.0929L56.9723 36.0675L57.6912 32.3005L57.7106 32.3348L57.7434 32.036L59.3147 29.8106L59.3549 29.7538L59.3194 29.6942L58.2217 27.8513L59.767 25.6312L59.8112 25.5675L59.7646 25.5057L58.3116 23.5692L58.1284 23.3248L58.1188 23.6301ZM30.563 10.642L30.6409 10.4987L30.4784 10.4839L28.2598 10.284L28.1999 10.1267L28.1223 10.2716L28.0222 10.2626L28.0661 10.3773L26.8223 12.7012L24.8737 12.7682L24.8181 12.6116L24.7331 12.7729L24.6491 12.776L24.6821 12.8696L23.4331 15.2392L23.3453 15.4058L23.5332 15.3962L25.5413 15.2942L25.5957 15.4478L25.6816 15.2871L25.7647 15.2828L25.7326 15.192L26.9492 12.9184L29.0937 13.0797L29.1524 13.2338L29.2305 13.0899L29.3297 13.0973L29.2874 12.9857L30.563 10.642ZM22.3749 17.7056L22.4803 17.506L22.2587 17.5508L20.4659 17.9129L20.4155 17.7646L20.3232 17.9416L20.2539 17.9556L20.2783 18.0275L19.0734 20.3425L18.9678 20.5455L19.1915 20.4967L20.9846 20.1043L21.0337 20.2492L21.1267 20.0731L21.1953 20.0583L21.1715 19.9885L22.3749 17.7056Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.3074 7.92211L27.312 8.02437H27.4143L29.5037 8.02468H29.6597L29.6038 7.87917L27.2363 1.70044L27.0293 1.74369L27.3074 7.92211Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M55.7059 40.3349L55.7485 40.5076L55.8524 40.1319L55.8703 40.1072L55.865 40.0862L56.9646 36.1075L56.774 36.0166L55.3503 38.0075L55.3083 37.8373L55.2042 38.2121L55.1863 38.2368L55.1915 38.2575L54.0929 42.2121L54.2832 42.3035L55.7059 40.3349ZM59.7859 25.5815L59.5913 25.5089L58.0067 27.7856L57.9674 27.8424L58.0027 27.9017L59.1356 29.8039L59.2996 30.0795L59.3339 29.7604L59.7859 25.5815ZM31.8867 8.21036L31.9636 8.06948L31.8039 8.05249L29.5154 7.81089L29.3452 7.79297L29.4033 7.95393L30.3685 10.627L30.4494 10.8513L30.5634 10.6418L31.8867 8.21036Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1374 1.84591L27.1466 1.63212L20.5054 1.00092L20.4937 1L20.4816 1.00155L14.8735 1.73438L14.8884 1.94786L27.1374 1.84591Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6231 15.339L23.726 15.1435L23.5088 15.1836L21.6248 15.5312L21.5059 15.5531L21.5423 15.6687L22.1781 17.688L22.2554 17.933L22.3752 17.7056L23.6231 15.339ZM29.6047 7.88094L29.5232 7.65479L29.4095 7.86704L28.0893 10.334L28.013 10.4768L28.1743 10.4913L30.4596 10.6973L30.627 10.7125L30.5699 10.5543L29.6047 7.88094Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.9762 35.288L72.1727 35.2027L71.1013 32.5226L71.0991 32.517L71.096 32.5118L69.618 29.7661L69.4268 29.8625L71.9762 35.288Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.4268 34.0739L58.4902 34.2194L58.5504 33.9008L58.572 33.8706L58.5609 33.8452L59.3283 29.7918L60.9324 27.5204L60.9732 27.4626L60.9358 27.4023L59.7705 25.5137L59.6064 25.2477L59.5727 25.5585L59.1238 29.7099L57.6628 31.7793L57.6001 31.6356L57.5395 31.9538L57.5179 31.9841L57.5287 32.0091L56.7569 36.0545L55.6561 40.0376L54.1092 42.178L54.0867 42.2095L54.0894 42.2481L54.2084 44.0091L54.416 44.0388L55.8499 40.1355L57.4286 37.9506L57.4586 37.9095L57.4453 37.8604L56.9778 36.1L58.4268 34.0739ZM21.7465 15.6044L21.6687 15.3575L21.5494 15.5871L20.3192 17.9503L18.6913 18.5561L18.6011 18.5898L18.6249 18.6828L19.0651 20.4182L19.134 20.6907L19.2638 20.4414L20.4665 18.1313L22.3017 17.7609L22.4185 17.7371L22.3826 17.6234L21.7465 15.6044ZM28.2784 10.4353L28.3643 10.2744L28.182 10.2774L26.1713 10.3127L26.1169 10.1489L26.0292 10.3151L25.9467 10.3164L25.9785 10.4115L24.7044 12.8278L24.6182 12.9913L24.803 12.9851L26.8118 12.9159L26.8649 13.0762L26.9533 12.911L27.0352 12.9082L27.0043 12.8161L28.2784 10.4353Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.4345 8.1302L25.3965 8.2822L25.5519 8.26243L27.4282 8.0233L27.5261 8.01094L27.5218 7.91238L27.2437 1.73366L27.0327 1.71265L25.4345 8.1302Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.856 18.7234L20.3174 18.1797L20.3551 18.345L20.4709 18.1225L20.52 18.1043L20.508 18.0511L21.7151 15.7325L23.452 15.4121L23.4989 15.5737L23.5984 15.3852L23.6639 15.3732L23.6429 15.3009L24.7663 13.17L24.8939 12.9281L24.9946 12.7369L26.1596 10.5272L28.1051 10.4933L28.1573 10.6607L28.2485 10.4908L28.3285 10.4895L28.2991 10.3956L29.5983 7.96819L29.6826 7.81032H29.5037L27.4931 7.81001L27.4399 7.63916L27.3497 7.81001H27.2688L27.2988 7.90671L26.0249 10.323L24.1993 10.5918L24.0804 10.6094L24.1116 10.7256L24.6649 12.7916L22.9935 13.0696L22.9456 12.9043L22.8471 13.0937L22.7806 13.1048L22.8023 13.1793L21.5686 15.5496L20.06 16.0822L20.0214 15.9129L19.9065 16.1363L19.8564 16.1539L19.8691 16.2089L18.7627 18.3561L18.633 18.6076L18.5063 18.8535L17.403 20.9951L17.2729 21.2473L17.5371 21.1441L19.2076 20.4919L19.2957 20.4573L19.2725 20.3655L18.856 18.7234ZM58.3484 33.8145L58.332 33.8374L56.7737 36.0167L56.7443 36.0575L56.7573 36.1063L57.2251 37.8664L55.667 40.0219L55.6438 40.0541L55.6454 40.0701L54.2146 43.9651L54.4019 44.0652L55.9862 41.8908L56.01 41.8587L56.0084 41.8423L57.3255 38.2442L57.3397 38.2986L57.4398 37.935L58.9169 35.8906L58.9617 36.0476L59.0578 35.696L59.0769 35.6697L59.0707 35.6484L60.0931 31.9083L60.1107 31.9466L60.1688 31.6373L60.191 31.6061L61.8047 29.3495L61.8414 29.2979L61.8142 29.2411L60.9411 27.4127L60.7996 27.1164L60.7434 27.4161L59.2475 29.5346L59.1847 29.3974L59.1263 29.7064L59.1041 29.7376L59.1155 29.7626L58.3484 33.8145Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.5095 1.21415L20.4885 1.00098L17.542 1.19315L17.5346 1.19377L17.5275 1.195L14.8662 1.73598L14.9014 1.94699L20.5095 1.21415Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M24.3186 10.6701L24.2472 10.4038L24.1199 10.6482L22.8223 13.1411L22.7231 13.3314L22.9348 13.2965L24.8166 12.9836L24.9331 12.9644L24.9025 12.8501L24.3186 10.6701ZM55.997 41.8727L55.8131 41.7646L54.2285 43.9387L54.2121 43.9615L54.2087 43.9893L54.0126 45.6481L54.2165 45.7059L55.997 41.8727Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.02 35.3353L72.1346 35.1545L60.9057 27.3705L60.5742 27.1406L60.7479 27.5049L61.6209 29.3333L61.6352 29.3632L61.6642 29.3799L72.02 35.3353Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M18.8237 18.7057L18.9964 18.3705L20.0583 16.3101L21.5638 15.7787L21.5993 15.9548L21.7198 15.7234L21.7677 15.7064L21.7569 15.6524L23.0125 13.2401L23.1348 13.0053L22.8836 13.089L21.3414 13.6025L21.3049 13.4224L21.1853 13.6547L21.1365 13.6708L21.148 13.727L19.9013 16.1464L18.5095 16.9543L18.4499 16.9892L18.4567 17.0581L18.6149 18.5998L17.3877 19.3372L17.3645 19.1565L17.2209 19.4373L17.1869 19.4577L17.1918 19.4942L15.9696 21.8839L15.7944 22.2266L16.1216 22.0239L17.4133 21.2231L17.4359 21.3995L17.5799 21.1196L17.6135 21.0989L17.6089 21.0637L18.6587 19.0261L18.8237 18.7057ZM55.8334 43.4945L57.6077 39.666L57.6256 39.6416L57.6241 39.6252L59.0554 35.6996L60.5693 33.6043L60.615 33.7535L60.7071 33.4137L60.7269 33.3862L60.7204 33.3649L61.8212 29.3151L61.6305 29.2249L60.1071 31.3552L60.0623 31.2084L59.9696 31.5473L59.9501 31.5748L59.9563 31.5958L58.8614 35.602L57.2549 37.8252L57.2311 37.858L57.2326 37.874L55.8043 41.7767L55.7963 41.7878L55.7951 41.7987L54.022 45.6154L54.2058 45.7239L55.8151 43.5291L55.8321 43.5056L55.8334 43.4945ZM27.5093 7.96719L27.6054 7.78491L27.4008 7.81086L25.6194 8.03794L25.5743 7.85473L25.469 8.0571L25.4053 8.06513L25.4245 8.14268L24.1201 10.6483L24.0234 10.8343L24.2307 10.8037L26.0136 10.5414L26.0575 10.7206L26.1638 10.5191L26.2265 10.5099L26.2083 10.4351L27.5093 7.96719Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0312 35.3409L72.127 35.1496L61.7712 29.1943L61.5293 29.0549L61.6158 29.3203L62.1886 31.073L62.2037 31.1193L62.2482 31.1382L72.0312 35.3409Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M57.4748 41.2558L59.2547 37.4066L59.2729 37.3812L60.927 35.1114L60.9527 35.0765L60.9468 35.0338L60.7212 33.3945L62.3775 31.1027L62.4094 31.0585L62.3924 31.0066L61.8199 29.2539L61.7084 28.9132L61.6144 29.2589L60.5109 33.3198L58.8731 35.5862L58.8487 35.6199L58.8505 35.6363L57.4198 39.5606L57.4111 39.5723L57.4102 39.5831L55.6362 43.411L54.0331 45.5972L54.0235 45.6105L54.0182 45.6259L53.4955 47.1571L53.6898 47.2448L55.8188 43.5241L57.4562 41.291L57.4739 41.2669L57.4748 41.2558ZM18.6704 17.0361L18.6334 16.6756L18.4684 16.9981L17.2156 19.4481L16.0672 20.5264L16.036 20.5557L16.0348 20.5758L14.7983 23.0057L14.9679 23.1318L16.1395 22.0103L16.1701 21.9809L16.171 21.9614L17.384 19.5896L18.7841 18.7483L18.8425 18.7134L18.8354 18.6458L18.6704 17.0361ZM24.3107 10.7471L24.4296 10.5188L24.1837 10.5954L22.6482 11.0749L22.6139 10.8827L22.4894 11.1243L22.4418 11.1392L22.452 11.1963L21.1804 13.6649L19.7975 14.4385L19.7376 14.4718L19.7431 14.5404L19.876 16.2316L19.9053 16.6073L20.078 16.2724L21.3361 13.8305L22.8342 13.3315L22.8675 13.519L22.9927 13.2787L23.0396 13.2632L23.0297 13.2079L24.3107 10.7471Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.046 35.3461L72.1158 35.144L62.3329 30.9414L62.1543 30.8647L62.1846 31.0566L62.4466 32.7157L62.4574 32.7846L62.5247 32.8025L72.046 35.3461Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.3913 31.0766L62.2034 30.9774L60.5221 33.3041L60.4971 33.3387L60.5029 33.3813L60.734 35.063L60.941 35.0849L62.3913 31.0766ZM19.9564 14.5235L19.9267 14.1457L19.7544 14.4831L18.4768 16.9812L17.3396 18.027L17.3084 18.0555L17.3053 18.0975L17.1953 19.5054L17.3977 19.5625L18.6446 17.124L20.0361 16.3161L20.0945 16.2821L20.0893 16.2147L19.9564 14.5235ZM25.6334 8.20578L25.7489 7.98364L25.5083 8.05347L23.9799 8.49712L23.9481 8.2929L23.8186 8.54408L23.7723 8.55736L23.7815 8.61607L22.4685 11.1643L22.3511 11.392L22.5958 11.3157L24.1285 10.8371L24.1597 11.0367L24.2898 10.7868L24.3355 10.7725L24.3265 10.716L25.6334 8.20578ZM55.315 45.0375L55.3252 45.0236L55.3271 45.0177L57.4626 41.2809L57.2834 41.1644L55.6422 43.4025L55.6323 43.4161L55.6305 43.422L53.5036 47.1384L53.6828 47.2551L55.315 45.0375Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.7846 40.3164L72.9975 40.2913L72.8202 37.8244L72.8195 37.8151L72.8174 37.8064L72.1775 35.2168L71.9678 35.2597L72.7846 40.3164Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.1295 39.0087L59.1477 38.984L59.1486 38.9726L60.9335 35.1029L62.6392 32.762L62.6654 32.7262L62.6583 32.682L62.3963 31.0232L62.1897 31.0034L60.745 34.9976L59.0751 37.2888L59.0569 37.3135L59.0563 37.3246L57.2727 41.1828L57.4562 41.2909L59.1295 39.0087ZM17.5189 18.114L17.3166 18.0571L16.0449 20.5556L16.2139 20.6826L17.3759 19.5917L17.4062 19.5633L17.4092 19.5219L17.5189 18.114ZM22.6593 11.2624L22.8175 10.9556L22.5131 11.1187L21.1108 11.8719L21.0509 11.9044L21.0546 11.9723L21.1537 13.7485L21.1757 14.1415L21.3561 13.7914L22.6593 11.2624Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.5354 8.26339L25.6212 8.26555L25.6422 8.18213L27.2405 1.76458L27.2865 1.57983L27.1045 1.63637L7.93652 7.60192L7.96587 7.81139L25.5354 8.26339Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.2687 11.9606L21.2465 11.5652L21.066 11.9177L19.7545 14.4829L19.5933 14.7981L19.9022 14.6254L21.3132 13.836L21.3716 13.8032L21.3679 13.7365L21.2687 11.9606Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M27.1684 1.84106L27.1357 1.63159L14.8867 1.73386L14.848 1.73416L14.8184 1.75919L7.89941 7.62279L8.00075 7.80661L27.1684 1.84106ZM72.0625 35.3494L72.1015 35.1393L62.5802 32.5957L62.4507 32.5611L62.4455 32.6949L62.3843 34.2387L62.3803 34.3391L62.4804 34.3496L72.0625 35.3494Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.9805 42.7739L56.99 42.7613L56.9919 42.7551L59.1329 39.0038L60.8362 36.6811L60.855 36.6555L60.8556 36.644L62.6497 32.7438L62.4656 32.6357L60.7537 34.9853L60.7351 35.0106L60.7345 35.022L58.95 38.8907L57.2832 41.1643L57.2727 41.1785L57.2709 41.1844L55.1388 44.9153L53.5103 47.128L53.5078 47.1314L53.5057 47.1348L52.6443 48.5093L52.7562 48.5832L52.8213 48.6298L54.4733 46.3868L54.4758 46.3837L54.4767 46.3822L56.9805 42.7739ZM16.4543 19.3616L16.0434 20.5569L15.1808 21.8666L15.1731 21.8783L15.1712 21.8842L13.915 24.3561L14.1001 24.4633L14.9831 23.1129L14.9908 23.1014L14.9927 23.0959L16.2359 20.653L17.4986 18.1715L18.6358 17.126L18.6661 17.0982L18.668 17.0778L19.9449 14.5805L19.778 14.452L18.6377 15.4774L18.6065 15.5052L18.6046 15.5265L17.3194 18.0516L16.4664 19.3372L16.4586 19.3486L16.4543 19.3616ZM23.9881 8.68296L24.141 8.38574L23.8438 8.53868L22.6009 9.17697L22.5923 8.93259L22.4199 9.26997L22.3902 9.28541L22.3914 9.32558L21.066 11.9177L20.9097 12.2235L21.2122 12.061L22.4625 11.3894L22.4708 11.6276L22.6439 11.2921L22.6732 11.276L22.6717 11.2374L23.9881 8.68296Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0779 35.3495L72.0847 35.1361L62.5025 34.136L62.413 34.1267L62.3882 34.2135L61.9894 35.6174L61.9492 35.7598L62.0969 35.7536L72.0779 35.3495Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M58.6777 40.4677L58.6885 40.4532L58.6904 40.447L60.8401 36.676L62.5776 34.3063L62.5971 34.2797L62.5983 34.247L62.6595 32.7032L62.4549 32.6543L60.6572 36.5632L58.9567 38.8822L58.9459 38.8967L58.944 38.9026L56.8002 42.6588L56.9797 42.7753L58.6777 40.4677ZM16.6568 19.4312L16.46 19.3478L15.1748 21.8772L15.3599 21.9847L16.2299 20.6633L16.2373 20.6522L16.2416 20.6392L16.6568 19.4312ZM21.2568 12.0154L21.0906 11.886L19.962 12.8759L19.9311 12.9031L19.9286 12.925L18.6137 15.5084L18.7809 15.6367L19.9212 14.6116L19.9515 14.5847L19.954 14.5637L21.2568 12.0154Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.9354 1.93653L14.8489 1.74097L12.5098 2.64032L12.4996 2.64434L12.4903 2.65021L10.5195 3.9197L10.6258 4.10569L14.9354 1.93653Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.1588 44.0985L56.1612 44.0951L56.1622 44.0939L58.6792 40.4653L58.505 40.3408L56.8066 42.6483L56.8042 42.6517L56.8036 42.653L54.2989 46.2624L54.4731 46.3869L56.1588 44.0985ZM18.8043 15.6058L18.6199 15.4973L17.7777 16.7557L17.7703 16.7671L17.7656 16.7798L17.3105 18.0703L17.5073 18.1546L18.8043 15.6058Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M71.5986 44.6975L71.8093 44.7293L72.9942 40.3268L73 40.3045L72.9963 40.282L72.1795 35.2253L71.9666 35.2383L71.5986 44.6975Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.4102 38.1131L60.4213 38.0979L60.4232 38.0917L62.5843 34.2956L62.4045 34.1792L60.6632 36.5541L60.6524 36.5692L60.6506 36.5751L58.4981 40.3511L58.6776 40.4676L60.4102 38.1131ZM17.9679 16.851L17.7715 16.7666L16.46 19.3476L16.6447 19.4554L17.5011 18.165L17.5085 18.1538L17.5129 18.1415L17.9679 16.851ZM22.5948 9.39814L22.4298 9.26807L21.3136 10.2212L21.2824 10.2478L21.2771 10.2882L21.055 11.9522L21.2567 12.0153L22.5948 9.39814Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0899 35.3487L72.0693 35.1355L62.0882 35.5396L62.0308 35.5421L62.0008 35.5909L61.2494 36.827L61.1299 37.0235L61.357 36.9889L72.0899 35.3487Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.49 10.3169L21.2882 10.2542L19.9375 12.908L20.1037 13.0372L21.2323 12.0473L21.2626 12.0207L21.2678 11.9809L21.49 10.3169ZM57.8793 41.7635L57.8817 41.7601L57.8827 41.7586L60.4124 38.1108L60.2381 37.9863L58.5055 40.3408L58.5027 40.3442L58.5021 40.3457L55.9848 43.9741L56.1593 44.0989L57.8793 41.7635Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.8867 8.74091L23.9052 8.74214L23.9229 8.73689L25.5683 8.25925L25.5411 8.04916L7.97167 7.59717L7.9624 7.81127L23.8867 8.74091Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.1282 13.0052L19.944 12.8965L19.117 14.1212L19.1096 14.1323L19.1071 14.1385L17.7715 16.7667L17.9562 16.8749L18.7981 15.6165L18.8055 15.6057L18.8077 15.5998L20.1282 13.0052ZM62.1787 35.7101L62.1901 35.6947L62.1954 35.6761L62.5942 34.2719L62.398 34.1898L60.231 37.9967L60.4105 38.1131L62.1787 35.7101Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.0972 35.3471L72.0573 35.1367L61.3247 36.7769L61.2919 36.7819L61.2678 36.8041L60.1479 37.8413L59.855 38.1122L60.2443 38.0245L72.0972 35.3471Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M59.6346 39.3798L59.6374 39.376L59.638 39.3748L62.1804 35.7079L62.0061 35.583L60.2377 37.9861L60.2349 37.9898L60.2343 37.991L57.7046 41.6388L57.8788 41.7633L59.6346 39.3798ZM21.4787 10.3513L21.2946 10.2422L20.4836 11.4313L20.4762 11.4422L20.4716 11.4548L19.9321 12.9193L20.128 13.0051L21.4787 10.3513Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M22.4875 9.45606L22.5196 9.45977L22.5483 9.44494L23.9417 8.72941L24.2908 8.54991L23.8988 8.52704L7.97477 7.59741L7.95654 7.8109L22.4875 9.45606Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.673 11.5287L20.4768 11.4431L19.1104 14.1325L19.2948 14.241L20.1219 13.0163L20.129 13.0055L20.1336 12.9934L20.673 11.5287Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.9565 1.92245L14.8391 1.74512L10.5298 3.91396L10.5057 3.92601L10.4903 3.94794L7.88086 7.64239L8.03781 7.78605L14.9565 1.92245ZM71.6022 44.6737L71.8045 44.7423L72.6675 42.6454L72.6724 42.6327L72.6742 42.6194L72.9965 40.3137L72.787 40.2711L71.6022 44.6737ZM72.1005 35.3461L72.0499 35.1379L60.1969 37.8152L60.1827 37.8183L60.17 37.8251L58.6661 38.629L58.7436 38.8271L72.1005 35.3461Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.4272 36.9463L61.43 36.9426L61.4325 36.9386L62.1842 35.7025L62.0047 35.5857L59.4604 39.2551L59.635 39.3799L61.4272 36.9463Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M21.3632 10.4079L21.4139 10.4175L21.4531 10.3841L22.5694 9.43098L22.7569 9.27094L22.5119 9.24314L7.98068 7.59766L7.94824 7.80929L21.3632 10.4079Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.07975 18.7515L2.88079 18.6724L1.69749 21.2833L1.69348 21.2926L1.69101 21.3028L1.04932 23.9755L1.25477 24.0355L3.07975 18.7515Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M72.1011 35.3461L72.0467 35.1388H72.0464L58.6901 38.6198L58.688 38.6204L56.7888 39.1534L56.7873 39.154L56.8435 39.3607L56.8451 39.3601L72.1008 35.3461H72.1011ZM20.5417 11.5942L20.6168 11.6168L20.6609 11.5522L21.4722 10.3631L21.564 10.2284L21.404 10.1975L7.98928 7.59888L7.93799 7.8068L20.5417 11.5942Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M62.7234 56.0336L62.5744 55.8813L60.4692 57.5057L60.4516 57.481L60.371 57.5814L60.2993 57.6367L60.3123 57.6547L58.5324 59.8689L58.682 60.0203L60.8119 58.3554L60.8289 58.3792L60.9098 58.2791L60.9809 58.2234L60.9685 58.2061L62.7234 56.0336ZM4.2879 16.0855L4.08894 16.0061L2.88062 18.6724L2.8763 18.6819L2.87537 18.6869L1.05225 23.9653L1.2509 24.0447L2.45891 21.3927L2.46322 21.3831L2.46446 21.3779L4.2879 16.0855Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.6655 4.07153L10.4996 3.93652L8.95209 5.58787L8.94188 5.59837L8.93509 5.61166L7.87354 7.65476L8.05612 7.76598L10.6655 4.07153Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M64.5675 53.75L64.4192 53.5974L62.2522 55.2463L62.1812 55.3004L62.2216 55.3801L62.5445 56.0147L62.6195 56.162L62.7234 56.0335L64.5675 53.75Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.0545 13.0131L20.1617 13.0588L20.1985 12.9485L20.6736 11.5258L20.7088 11.4211L20.6029 11.3892L7.99918 7.60181L7.92627 7.80293L20.0545 13.0131Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.52146 13.3623L5.32249 13.2832L4.08885 16.006L4.08422 16.0159L4.08175 16.0264L3.49072 18.6145L3.69679 18.6732L5.52146 13.3623Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.8726 49.6973L62.0097 49.8536L71.7553 44.7967L71.8106 44.7679L71.8128 44.7055L72.1807 35.2467L72.195 34.8809L71.9858 35.1812L61.8726 49.6973Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.69289 18.6831L3.69752 18.6729L3.7 18.6624L4.29102 16.0743L4.08526 16.0156L2.26183 21.3083L1.05599 23.956L1.04672 23.9764L1.04641 23.9989L1.00439 27.0443L1.21634 27.0687L2.46357 21.3821L3.69289 18.6831ZM62.4126 55.2828L62.3375 55.1351L62.2337 55.2642L60.363 57.5913L60.5122 57.7433L62.7055 56.0508L62.7759 55.9968L62.7354 55.9174L62.4126 55.2828ZM66.4512 51.4181L66.3035 51.2652L64.1643 52.8677L64.1016 52.9147L64.1276 52.9888L64.3831 53.7183L64.4467 53.8999L64.5675 53.7501L66.4512 51.4181Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M19.9375 14.6588L20.0864 14.744L20.0975 14.5726L20.2038 12.9212L20.2087 12.8458L20.1392 12.8159L8.01069 7.60571L7.91553 7.79726L19.9375 14.6588Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.78201 10.581L6.58305 10.5022L5.32283 13.2834L5.31819 13.2936L5.31695 13.2985L3.49414 18.6038L3.6928 18.6832L4.95394 15.9141L4.95857 15.9039L4.95981 15.899L6.78201 10.581ZM60.53 57.7258L60.4018 57.5611L57.5672 58.8668L57.4251 58.9323L57.5375 59.041L58.5413 60.013L58.6257 60.0948L58.6995 60.0031L60.53 57.7258ZM64.3295 52.9182L64.2659 52.7362L64.1451 52.8864L62.2333 55.2644L62.3819 55.417L64.5489 53.7681L64.611 53.7212L64.5854 53.6476L64.3295 52.9182Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.8639 49.8057L61.9439 49.9692L62.0481 49.8199L72.1613 35.3038L72.3278 35.0647L72.0461 35.1388L56.7885 39.1534L56.6621 39.1867L56.7196 39.3041L61.8639 49.8057Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M3.69997 18.6613L3.49791 18.5943L2.26365 21.3038L2.25407 21.3248V21.3365L1.00684 27.0231L1.20889 27.0908L2.44779 24.4029L2.45767 24.3816V24.3698L3.69997 18.6613ZM57.6868 58.8872L57.6019 58.805L57.5283 58.8973L55.6811 61.2197L55.8106 61.3832L58.6616 60.0328L58.8015 59.9666L58.6903 59.8588L57.6868 58.8872ZM68.3753 49.0359L68.2283 48.8821L66.119 50.4364L66.0628 50.4778L66.078 50.5461L66.263 51.3744L66.3115 51.591L66.4512 51.4182L68.3753 49.0359ZM8.07009 7.73894L7.87082 7.66016L6.58311 10.5019L6.57847 10.5124L6.576 10.5238L6.04059 13.0171L6.24697 13.0742L8.07009 7.73894Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M20.2253 16.5261L20.4329 16.6732L20.393 16.422L20.0964 14.5491L20.0884 14.4982L20.0436 14.4728L8.02166 7.61133L7.90674 7.79176L20.2253 16.5261Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.2428 13.0841L6.24774 13.0736L6.25021 13.0622L6.78532 10.5689L6.57924 10.5115L4.75488 15.835L4.95385 15.9141L6.2428 13.0841ZM62.4004 55.3988L62.2731 55.2338L59.457 56.4922L59.3235 56.5518L59.4202 56.6609L60.3662 57.7295L60.4503 57.8247L60.53 57.7258L62.4004 55.3988Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.1069 47.9595L68.2435 48.1242L70.3212 46.6208L70.3332 46.6122L70.3425 46.6004L71.79 44.7681L71.6321 44.6238L68.1069 47.9595Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M66.3022 50.5673L68.2075 49.1638L68.2159 49.2333L68.3666 49.0464L68.4059 49.0176L68.4037 49.0006L70.3418 46.6013L70.1953 46.4472L68.265 47.8436L68.2564 47.7713L68.1059 47.9589L68.0663 47.9873L68.0685 48.0052L66.2386 50.2819L66.1162 50.4342L63.3656 51.5801L63.2473 51.6295L63.3168 51.737L64.07 52.9052L61.4665 54.0304L61.4338 53.9874L61.3646 54.0746L61.2638 54.1178L61.296 54.1604L59.4963 56.4232L59.4169 56.523L57.5512 58.8683L54.1348 59.6857L54.0912 59.6468L54.0436 59.7074L53.8808 59.7466L53.9587 59.8161L52.1664 62.1076L51.9737 62.3545L52.2774 62.2775L55.7358 61.4041L55.7781 61.4418L55.8263 61.3813L55.9866 61.3408L55.9116 61.2738L57.68 59.0505L60.4915 57.7557L60.6225 57.6955L60.5267 57.5873L59.6777 56.628L62.2831 55.4642L62.3146 55.5056L62.3844 55.4188L62.4839 55.3743L62.453 55.3335L64.219 53.1363L64.3036 53.0312L66.432 51.4367L66.4873 51.395L66.4721 51.3274L66.3022 50.5673ZM3.48799 18.6158V18.6273L2.24817 24.3237L2.23859 24.3447L1.01421 27.0011L1 27.032L1.00618 27.0654L1.64077 30.4694L1.73438 30.4654L1.84282 30.4953L3.12003 27.7787L3.13486 27.7469L3.13115 27.7278L3.72187 21.6386L3.72434 21.6334L5.00926 18.846L5.01947 18.8238L5.01916 18.8114L6.24785 13.0736L7.5609 10.1907L7.56585 10.1796L7.56832 10.1679L8.07345 7.72591L7.86707 7.66968L6.0458 13L4.75901 15.8254L4.74882 15.8473L3.49787 18.5942L3.48799 18.6158Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.07582 7.71196L7.86388 7.68262L7.35843 10.1246L7.35596 10.1375L7.35658 10.1511L7.48417 12.9938L7.69828 12.9966L8.07582 7.71196Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.56792 10.1685L7.36556 10.1021L6.04788 12.9954L6.03737 13.0179L6.038 13.03L4.80713 18.7787L5.00918 18.846L6.33335 15.9731L6.34417 15.9499L6.34354 15.9378L7.56792 10.1685ZM59.5843 56.6568L59.774 56.4186L59.4771 56.4853L55.9779 57.2738L55.7863 57.3167L55.9263 57.4545L57.5369 59.0403L57.6218 59.1241L57.696 59.0308L59.5843 56.6568ZM63.4969 51.6212L63.4159 51.4954L63.3229 51.6125L61.3478 54.0956L61.4741 54.2605L64.2711 53.0519L64.387 53.0019L64.3184 52.8956L63.4969 51.6212Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.9111 49.6637L61.989 49.862L68.2091 48.1405L68.2348 48.1334L68.2542 48.1152L71.7794 44.7795L71.6564 44.6064L61.9111 49.6637Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M5.01846 18.8114L4.81456 18.7564L3.51974 21.5658L3.5046 21.5979L3.50862 21.6167L2.91791 27.7046L1.64904 30.4042L1.62988 30.445L1.64533 30.4873L3.03253 34.2254L3.24014 34.1861L3.13046 27.7565L4.42589 25.0007L4.44135 24.9679L4.43733 24.9488L5.01846 18.8114ZM56.0765 57.3019L55.9909 57.2176L55.917 57.3121L54.05 59.6997L50.0309 59.978L49.7868 59.995L49.9648 60.1628L52.1772 62.2519L52.2628 62.3326L52.3354 62.2399L54.1662 59.8986L57.637 59.0685L57.8248 59.0234L57.6873 58.8878L56.0765 57.3019ZM68.2637 48.1044L68.5198 47.7862L68.1404 47.9379L65.476 49.0041L65.4442 48.9457L65.3614 49.0498L65.2758 49.0841L65.2996 49.1277L63.3229 51.6126L63.4481 51.7781L66.1347 50.6588L66.1653 50.715L66.2487 50.6112L66.3334 50.576L66.3108 50.5343L68.2637 48.1044ZM61.5156 54.2291L61.7009 53.9961L61.4096 54.0573L57.9197 54.7923L57.7352 54.8312L57.8628 54.9696L59.4218 56.6627L59.5065 56.7547L59.5843 56.6568L61.5156 54.2291ZM7.68812 13.034L7.69924 13.0103L7.698 12.9843L7.5701 10.1416L7.35847 10.124L6.13131 15.9061L6.33337 15.9731L7.68812 13.034Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.8739 49.8214L62.0568 49.7114L56.9124 39.2098L56.8797 39.1431L56.8058 39.1502L54.4973 39.3751L54.311 39.393L54.421 39.5447L61.8739 49.8214Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.34321 15.9377L6.13931 15.8831L4.81483 18.7563L4.79969 18.7894L4.80371 18.8079L4.22442 24.9261L2.92621 27.6875L2.90674 27.7289L2.91632 27.7539L3.02631 34.1897L3.22991 34.2345L4.55253 31.4975L4.57292 31.4558L4.56304 31.4301L4.43698 24.9777L5.76238 22.1579L5.77814 22.1245L5.77381 22.1053L6.34321 15.9377ZM58.021 54.8243L57.9354 54.7316L57.8575 54.8311L55.9173 57.312L55.7323 57.5486L56.0251 57.4825L59.5243 56.6944L59.7054 56.6536L59.5796 56.5173L58.021 54.8243ZM50.1121 60.0069L50.0256 59.9251L49.9536 60.0196L48.1042 62.4406L47.9578 62.6321L48.1982 62.6126L52.2599 62.2805L52.4997 62.2611L52.3248 62.0958L50.1121 60.0069ZM63.491 51.7456L63.672 51.5182L63.3865 51.5738L59.9074 52.2526L59.7295 52.2872L59.8456 52.4265L61.3496 54.2308L61.434 54.3318L61.5158 54.2289L63.491 51.7456Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.07407 7.68604L7.86151 7.69654L7.48397 12.9815L7.48242 13.004L7.49014 13.0253L9.96516 19.8794L10.1715 19.8251L8.07407 7.68604ZM54.5942 39.4188L54.5639 39.3771L54.5123 39.3746L51.8776 39.2569L51.6079 39.2449L51.796 39.4386L61.8833 49.8333L62.047 49.6958L54.5942 39.4188Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M56.0857 57.4443L56.2315 57.2577L55.9955 57.2713L51.9732 57.4974L51.9204 57.4443L51.8756 57.503L51.6874 57.5135L51.7891 57.6164L49.953 60.0197L49.8093 60.2082L50.0457 60.1918L54.0633 59.9134L54.1143 59.965L54.16 59.9066L54.3457 59.894L54.2474 59.7948L56.0857 57.4443ZM7.69764 12.9985L7.49342 12.9441L6.13866 15.8832L6.12322 15.9171L6.12785 15.936L5.56 22.0838L4.23213 24.9092L4.21204 24.9515L4.22224 24.9765L4.34829 31.4274L3.03648 34.1413L3.0127 34.191L3.03895 34.2392L5.17721 38.1462L5.37772 38.0816L4.5661 31.4695L5.90572 28.6972L5.92642 28.6546L5.91591 28.6286L5.77287 22.1354L7.12947 19.2495L7.14554 19.2152L7.1406 19.1957L7.69764 12.9985ZM60.0097 52.2894L59.9247 52.1875L59.8431 52.2919L57.8572 54.8312L57.6762 55.0626L57.9638 55.002L61.4537 54.267L61.628 54.2303L61.514 54.0937L60.0097 52.2894Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M68.152 47.9337L61.993 49.6382L51.9499 39.2892L51.9252 39.2636L51.89 39.258L49.048 38.7961L48.6636 38.7337L48.9618 38.984L61.8914 49.8405L61.9578 49.7695L61.9791 49.864L65.4465 49.2439L65.4573 49.2421L65.4675 49.2381L68.2203 48.1367L68.152 47.9337ZM20.3927 16.42L20.3849 16.3764L20.3491 16.3511L8.03059 7.6167L7.87549 7.75727L23.4748 35.0813L23.6734 35.0096L20.3927 16.42Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M50.1235 60.1497L50.244 59.9918L50.0459 59.9779L45.6449 59.6662L45.5856 59.6038L45.5448 59.6591L45.3282 59.6439L45.4561 59.7786L43.6766 62.1773L43.5586 62.3364L43.7563 62.3482L48.1403 62.6102L48.1978 62.6707L48.2395 62.616L48.4533 62.6287L48.3294 62.4983L50.1235 60.1497ZM65.5114 49.2054L65.6882 48.9832L65.4086 49.0333L62.0061 49.6416L61.9619 49.5826L61.901 49.6601L61.7697 49.6836L61.825 49.7575L59.8431 52.2918L59.6661 52.5179L59.9478 52.463L63.3614 51.7972L63.4044 51.854L63.4659 51.7768L63.5953 51.7514L63.5422 51.6807L65.5114 49.2054ZM58.0261 54.963L58.1692 54.7801L57.9372 54.79L53.9134 54.9618L53.8606 54.9046L53.8136 54.9661L53.6326 54.9738L53.7283 55.0773L51.8456 57.5421L51.7044 57.7272L51.9367 57.7142L55.9568 57.4877L56.0081 57.5434L56.0557 57.4822L56.2342 57.4723L56.1419 57.3722L58.0261 54.963ZM6.742 35.3958L6.76733 35.3449L6.74972 35.3137L5.91957 28.6687L7.29132 25.8304L7.31202 25.7871L7.3012 25.7612L7.13993 19.2265L8.52867 16.2723L8.54535 16.2371L8.53546 16.1997L7.6945 12.9619L7.48411 12.9792L6.92737 19.1752L5.56799 22.0667L5.5479 22.1097L5.55841 22.135L5.70145 28.627L4.35936 31.4045L4.33496 31.4548L4.35195 31.4851L5.1648 38.1075L5.20806 38.46L5.36716 38.1424L6.742 35.3958Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.0995 38.82L49.0813 38.8046L49.0581 38.7984L46.0613 38.0059L45.9708 38.1959L61.8973 49.845L62.0292 49.6766L49.0995 38.82ZM21.1267 18.5055L21.1255 18.4956L21.1221 18.4863L20.388 16.4031L20.1816 16.4571L23.4621 35.0446L23.4624 35.0468L23.674 35.0119L23.6734 35.0097L21.1267 18.5055Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M6.75118 35.3251L6.68693 35.0276L6.55036 35.2998L5.17553 38.0467L5.14648 38.1047L5.18449 38.1573L7.98977 42.0358L8.18132 41.9503L6.75118 35.3251ZM52.0163 57.6722L52.1353 57.5165L51.94 57.5004L47.4815 57.1334L47.2121 57.1112L47.3932 57.312L49.959 60.1565L50.0452 60.2523L50.1237 60.1497L52.0163 57.6722Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M46.0972 38.0229L46.0849 38.014L46.07 38.0087L42.9771 36.9045L42.8813 37.0946L61.9007 49.8478L62.0237 49.6723L46.0972 38.0229Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M60.0121 52.4238L60.1527 52.2443L59.9247 52.2508L55.8493 52.3669L55.6241 52.3731L55.7714 52.544L57.8609 54.9671L57.9461 55.0659L58.0265 54.9631L60.0121 52.4238ZM47.5524 57.1687L47.4646 57.0713L47.3868 57.1764L45.5099 59.7064L45.3937 59.8633L45.5884 59.8772L50.0311 60.1917L50.2962 60.2106L50.1182 60.0129L47.5524 57.1687ZM8.1499 32.5835L8.17554 32.5319L8.15762 32.5007L7.30121 25.7705L7.2558 25.4121L7.09854 25.7374L5.7132 28.6039L5.68848 28.6549L5.70609 28.6855L6.53995 35.361L6.58382 35.712L6.74231 35.3959L8.1499 32.5835Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M7.69183 12.9526L7.4873 13.016L8.32827 16.2535L8.33043 16.2624L8.33414 16.2708L9.96849 19.8874L10.1668 19.8067L7.69183 12.9526Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.5392 16.2235L8.3353 16.1809L6.93574 19.1583L6.91504 19.2018L6.93451 19.2463L8.51542 22.8917L8.72086 22.8459L8.5392 16.2235Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.0005 36.9163L42.9927 36.9114L42.9844 36.9074L39.8569 35.5162L39.7558 35.7046L61.9006 49.8478L61.9028 49.8491L62.0202 49.6696L62.018 49.6683L43.0005 36.9163ZM22.3319 20.79L22.33 20.769L22.3202 20.7501L21.1156 18.4719L20.915 18.5383L23.462 35.0447L23.6746 35.0185L22.3319 20.79Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.70979 22.8957L8.73111 22.8513L8.71165 22.8065L7.13074 19.1611L6.92529 19.2065L7.08779 25.7866L7.2914 25.8305L8.70979 22.8957ZM55.9334 52.404L55.8472 52.3039L55.7672 52.409L53.7831 55.006L53.6444 55.1877L53.873 55.1781L57.9462 55.0042L58.1674 54.9946L58.0228 54.8272L55.9334 52.404ZM45.6818 59.834L45.7834 59.6972L45.616 59.665L40.8896 58.7576L40.5887 58.6999L40.788 58.9328L43.6813 62.3109L43.7687 62.4128L43.8487 62.305L45.6818 59.834Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M39.8709 35.5237L39.8672 35.5216L39.8634 35.5194L36.7659 33.8708L36.6587 34.0565L61.9025 49.8489L61.9031 49.8495L62.0178 49.6682L62.0168 49.6676L39.8709 35.5237Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M40.9509 58.7933L40.8619 58.6895L40.7825 58.8007L38.9767 61.3208L38.8784 61.4583L39.0446 61.4886L43.7438 62.3468L44.0407 62.4009L43.8442 62.1716L40.9509 58.7933Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.7725 33.875L36.7722 33.8747L33.7698 32.0037L33.7695 32.0034H33.7691L33.6558 32.1851V32.1854L61.9035 49.8497L61.9604 49.7589L62.0172 49.668L62.0169 49.6677L62.0157 49.6671L36.7725 33.875Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M9.60279 39.2763L9.63369 39.217L9.60773 39.1818L8.15845 32.513L8.09418 32.2158L7.95793 32.4877L6.55035 35.2998L6.521 35.3585L6.54633 35.3931L7.97184 41.9957L8.03456 42.2858L8.17173 42.0226L9.60279 39.2763ZM53.9538 55.136L54.0709 54.9825L53.8787 54.9643L49.4048 54.5425L49.1409 54.5178L49.3133 54.7189L51.8498 57.6769L51.936 57.7776L52.0163 57.6722L53.9538 55.136ZM62.0449 49.8245L62.1827 49.6484L61.959 49.6515L57.8827 49.7068L57.663 49.7096L57.8008 49.8811L59.8439 52.425L59.9286 52.5303L60.012 52.4241L62.0449 49.8245Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.9811 23.1784L23.982 23.1435L23.9622 23.1144L22.3133 20.7389L22.0801 20.4031L22.1187 20.8103L23.4614 35.0384L23.6752 35.031L23.9811 23.1784Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M49.476 54.5796L49.3885 54.4776L49.3085 54.5855L47.3865 57.1763L47.2716 57.3311L47.4638 57.3472L51.9222 57.7139L52.1821 57.7352L52.0121 57.5375L49.476 54.5796ZM9.61036 39.1957L9.53405 38.9445L9.41263 39.1771L7.98126 41.9234L7.94696 41.9895L7.99701 42.0448L11.4051 45.8097L11.587 45.7065L9.61036 39.1957ZM9.5909 29.7037L9.61715 29.6515L9.59862 29.62L8.71965 22.8351L8.67331 22.4783L8.51666 22.8024L7.09827 25.7374L7.07324 25.789L7.09117 25.8196L7.94758 32.5492L7.99176 32.8986L8.14963 32.5835L9.5909 29.7037Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M8.06194 7.65112L7.86328 7.72249L9.96076 19.8615L9.96601 19.8915L9.9864 19.9144L23.4882 35.0996L23.6612 34.9751L8.06194 7.65112Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.1629 19.89L10.1845 19.8449L10.1641 19.7992L8.52974 16.1826L8.32522 16.2296L8.50656 22.852L8.71016 22.8958L10.1629 19.89ZM57.9682 49.7468L57.8823 49.6403L57.7992 49.749L55.7676 52.4091L55.631 52.5877L55.8556 52.5812L59.931 52.465L60.1463 52.4591L60.0113 52.2907L57.9682 49.7468ZM47.559 57.3041L47.6594 57.1688L47.4941 57.1354L42.7777 56.1845L42.7131 56.1063L42.6723 56.1632L42.4443 56.1171L42.584 56.2864L40.8273 58.7383L36.0289 57.2887L35.96 57.1997L35.9214 57.2562L35.6752 57.1818L35.8324 57.3851L34.1088 59.8879L34.0251 60.0099L34.1669 60.0516L39.0007 61.4762L39.0675 61.5627L39.107 61.5074L39.352 61.5797L39.1978 61.3807L40.9169 58.9811L45.5382 59.8684L45.6006 59.9441L45.642 59.8882L45.8681 59.9317L45.7322 59.7668L47.559 57.3041ZM11.069 36.4638L11.1002 36.4038L11.0594 36.3498L8.13984 32.4715L7.94922 32.5586L9.40346 39.2496L9.46617 39.5391L9.60304 39.2765L11.069 36.4638Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M25.999 25.5612L26.0123 25.5077L25.9764 25.466L23.9556 23.1062L23.7742 22.8943L23.7671 23.173L23.4609 35.0256L23.6723 35.054L25.999 25.5612Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.513 49.2636L13.093 43.0101L13.1059 42.9863L13.0707 42.948L11.0774 36.3871L9.608 29.6702L11.0678 26.754L11.0944 26.7008L11.0632 26.6502L8.70493 22.7932L8.50721 22.8627L9.38494 29.6371L7.95788 32.4878L7.92822 32.5471L7.96809 32.6003L10.8472 36.4248L9.41274 39.1772L9.37814 39.244L9.41243 39.2814L11.369 45.7261L11.3502 45.7607L11.3906 45.7975L11.4565 46.0137L11.5136 45.9087L15.3412 49.3816L15.513 49.2636ZM55.9375 52.5391L56.053 52.3877L55.8636 52.3673L51.4191 51.8931L51.3598 51.8205L51.3143 51.882L51.1163 51.8609L51.2281 51.9981L49.3086 54.5856L49.1952 54.7382L49.3846 54.7561L53.8138 55.1735L53.8712 55.244L53.9176 55.1834L54.1131 55.2016L54.005 55.0688L55.9375 52.5391Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M28.333 27.8957L28.3808 27.8222L28.3181 27.7607L25.9698 25.459L25.8354 25.3274L25.7906 25.51L23.4639 35.0028L23.6579 35.0866L28.333 27.8957Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M30.9284 30.1262L31.0452 30.0465L30.9367 29.9556L28.3119 27.7553L28.2192 27.6777L28.1534 27.7791L23.478 34.9699L23.6282 35.117L30.9284 30.1262ZM53.3873 49.1678L53.3688 49.3813L57.8715 49.9204L57.8786 49.9213L57.8857 49.921L61.9617 49.8657L61.9663 49.6516L53.3873 49.1678Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M17.0526 46.5217L16.9742 46.3542L16.9374 46.4185L13.0471 42.9227L12.8753 43.041L15.295 49.2936L15.2734 49.331L15.3244 49.3696L15.3948 49.551L15.4461 49.4611L19.6991 52.6667L19.8607 52.5357L17.0854 46.5918L17.1005 46.5649L17.0526 46.5217ZM37.7329 54.6252L37.4917 54.5504L37.6446 54.7534L35.8768 57.3205L35.7937 57.4413L35.9342 57.4839L40.8052 58.9555L40.8719 59.0441L40.9121 58.9879L41.1521 59.0605L41.0026 58.8615L42.8062 56.3442L42.8928 56.2234L42.7506 56.1795L37.8414 54.6586L37.7728 54.5674L37.7329 54.6252ZM12.5707 33.5825L12.6022 33.5219L12.5605 33.4675L9.58033 29.5908L9.39062 29.6789L10.869 36.4372L10.9323 36.7261L11.0689 36.4638L12.5707 33.5825Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.7423 32.197L33.9533 32.1361L33.7753 32.0073L30.9308 29.9509L30.8696 29.9067L30.8075 29.9494L23.5073 34.9399L23.5975 35.1311L33.7423 32.197Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M14.5968 40.251L14.6335 40.1834L14.581 40.1269L11.0522 36.3409L10.8711 36.4454L12.8728 43.0335L12.9473 43.2782L13.0696 43.0536L14.5968 40.251ZM26.185 52.7852L26.0944 52.6394L26.0033 52.7846L24.4313 55.2822L21.4694 49.8425L21.3798 49.6778L21.2834 49.8384L19.6719 52.526L19.6179 52.6162L19.7069 52.6718L24.2769 55.5281L24.2716 55.5361L24.372 55.5874L24.714 55.8012L24.6844 55.7468L29.2281 58.0655L29.5708 58.2404L29.3678 57.9135L26.185 52.7852ZM11.0766 26.6828L11.0117 26.3875L10.8761 26.6578L9.3996 29.608L9.36963 29.6679L9.41042 29.721L12.3906 33.5981L12.5803 33.5097L11.0766 26.6828ZM57.9696 49.8789L58.0836 49.7297L57.8973 49.7074L53.3943 49.1683L53.1407 49.138L53.297 49.3404L55.7677 52.5393L55.853 52.6499L55.9376 52.539L57.9696 49.8789Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.7695 32.0034L33.7287 31.978L33.683 31.9913L23.5382 34.9254L23.2314 35.0141L23.5296 35.1284L61.9218 49.8586L62.017 49.6677L33.7695 32.0034Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M36.0948 57.3816L37.82 54.8763L42.6532 56.3737L42.7197 56.4646L42.7611 56.4071L42.9962 56.48L42.8513 56.2811L44.6599 53.7566L49.3337 54.7459L49.3961 54.8275L49.44 54.7684L49.655 54.8139L49.529 54.6486L51.4133 52.1081L55.8412 52.5804L56.0908 52.607L55.9372 52.4084L53.4663 49.2095L53.3797 49.0973L53.2954 49.211L51.319 51.8754L46.6168 50.8309L46.5528 50.7468L46.5096 50.8071L46.2924 50.7589L46.4221 50.9291L44.5718 53.5116L39.6987 51.9635L39.6305 51.8695L39.5897 51.9286L39.3533 51.8535L39.5016 52.0568L37.7372 54.6186L32.8338 52.5947L32.7618 52.489L32.7226 52.5486L32.473 52.4457L32.6342 52.6824L31.0024 55.158L27.8958 50.0677L27.8059 49.9201L27.7139 50.0665L26.1011 52.6289L23.1213 47.0888L23.0317 46.9229L22.935 47.0845L21.3875 49.6652L18.6749 43.8093L18.6903 43.7821L18.6421 43.7392L18.5643 43.5708L18.5269 43.6363L14.6372 40.1766L16.1616 37.3794L16.1986 37.3111L16.1452 37.2543L12.6058 33.5147L14.1095 30.6294L14.1413 30.5682L14.1138 30.533L12.5978 23.6972L12.6077 23.6771L12.5854 23.6419L12.5243 23.3653L12.4742 23.4651L10.1568 19.7861L9.95996 19.8572L10.8615 26.6875L10.8457 26.7187L10.8695 26.749L10.9112 27.0663L10.9322 27.0246L12.3624 33.5178L10.8788 36.3648L10.8436 36.4318L10.8952 36.4874L14.3715 40.2165L12.8811 42.951L12.8406 43.0255L12.9036 43.082L16.8286 46.6087L16.8246 46.6158L16.8755 46.6541L16.9457 46.8358L16.9549 46.8197L19.6666 52.6265L19.7519 52.8091L19.8557 52.6361L21.3708 50.1094L24.3306 55.5454L24.418 55.7061L24.5153 55.5513L26.0931 53.0439L29.1857 58.0266L29.2741 58.1691L29.3661 58.0291L31.0354 55.4973L31.2885 55.6273L31.2597 55.5801L35.8946 57.4684L35.9644 57.5709L36.0043 57.5129L36.2536 57.6145L36.0948 57.3816Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M10.1535 19.781L9.97559 19.9006L12.3944 23.7405L12.3978 23.7458L12.4015 23.7504L15.5086 27.6203L15.6791 27.4909L10.1535 19.781ZM43.5068 46.3518L43.4586 46.5603L53.3524 49.3783L53.3639 49.3814L53.3756 49.382L61.9546 49.8658L61.9796 49.6532L43.5068 46.3518Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M37.906 54.7518L39.6763 52.181L44.547 53.7285L44.6134 53.8218L44.6554 53.7628L44.8859 53.836L44.7453 53.6377L46.6015 51.0471L51.3013 52.0908L51.3634 52.1757L51.4085 52.1145L51.6185 52.1612L51.4971 51.9953L53.4676 49.3389L53.565 49.2076L53.4062 49.1705L48.6091 48.0534L48.5451 47.9659L48.5009 48.028L48.289 47.9786L48.4138 48.1491L46.5141 50.8009L41.6033 49.2005L41.535 49.1041L41.4933 49.1647L41.2619 49.089L41.4055 49.2923L39.5942 51.9224L34.6435 49.8515L34.5718 49.744L34.5317 49.8048L34.2867 49.7022L34.4436 49.9383L32.7679 52.4803L29.6499 47.2819L29.5603 47.1324L29.4673 47.2798L27.8123 49.9099L24.8145 44.2662L24.7255 44.0988L24.6279 44.2613L23.0396 46.9103L20.3041 40.9574L20.3202 40.9296L20.2717 40.8866L20.1941 40.7179L20.1561 40.785L16.2025 37.3043L17.7658 34.4354L17.8035 34.3665L17.7491 34.3097L14.1452 30.5611L15.6871 27.6026L15.7193 27.5405L15.6757 27.4861L12.5686 23.6162L12.3804 23.7064L13.9014 30.5652L12.3804 33.4832L12.3452 33.5512L12.3977 33.6065L15.9362 37.3451L14.4084 40.1488L14.3676 40.2236L14.4313 40.2801L18.4183 43.8266L18.4143 43.8334L18.4656 43.8717L18.5354 44.0537L18.5443 44.0376L21.2782 49.9389L21.3632 50.1224L21.4673 49.9488L23.022 47.3564L25.9996 52.8925L26.0868 53.0544L26.1847 52.899L27.803 50.327L30.9073 55.4138L30.9954 55.5581L31.0884 55.4172L32.8 52.8208L33.049 52.9493L33.0218 52.9042L37.7055 54.8373L37.775 54.9418L37.8158 54.8828L38.0605 54.9838L37.906 54.7518Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M61.9988 49.6587L23.6307 34.9378L10.1462 19.772L9.979 19.9057L15.5046 27.6156L15.5108 27.624L15.5185 27.6314L23.4947 35.1065L23.5448 35.0577L23.5145 35.1213L43.4345 46.5504L43.4505 46.5596L43.4688 46.5627L61.9413 49.8642L61.9988 49.6587Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M34.536 49.7982L29.6113 47.2435L24.7805 44.2275L20.2729 40.8846L17.7732 34.3491L15.6946 27.5216L15.6182 27.271L15.4971 27.5033L13.9196 30.5304L13.8838 30.5987L13.9372 30.654L17.5402 34.4016L15.9735 37.2767L15.9325 37.3518L15.9967 37.4083L20.0959 41.0175L22.9296 47.1842L23.0142 47.3689L23.119 47.1947L24.7144 44.534L27.71 50.1736L27.7968 50.337L27.8954 50.1804L29.556 47.5413L32.6715 52.7364L32.7592 52.8825L32.8528 52.7404L34.6129 50.0707L39.5614 52.1407L39.6306 52.2473L39.672 52.187L39.9124 52.2878L39.7619 52.0563L41.6246 49.3518L41.699 49.2437L41.5782 49.1924L36.4991 47.0387L36.4277 46.929L36.3866 46.9911L36.1459 46.8888L36.2989 47.1242L34.536 49.7982Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M43.517 46.3542L43.4536 46.5591L48.5121 48.2503L48.5167 48.2518L48.5217 48.2531L53.3571 49.3795L53.4108 49.1719L43.517 46.3542Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M26.4248 41.265L21.9434 37.9601L19.4348 31.373L19.449 31.3467L19.4091 31.3062L19.3343 31.1094L19.2923 31.1864L15.6686 27.478L15.4897 27.5843L17.5563 34.372L17.5366 34.4081L17.5783 34.4446L17.6438 34.6596L17.6549 34.6392L20.0656 40.9428L20.0437 40.9814L20.095 41.0194L20.1648 41.2017L20.217 41.1099L24.5782 44.3446L24.5739 44.3514L24.636 44.39L24.707 44.5464L24.7558 44.4648L29.4102 47.3708L29.4056 47.3782L29.4791 47.4165L29.5498 47.5516L29.5922 47.4842L29.8365 47.6368L29.8115 47.5889L34.4955 50.0185L34.5672 50.1403L34.6086 50.0772L34.8539 50.2045L34.6985 49.941L36.5164 47.1836L36.5819 47.0844L36.4765 47.0294L31.4332 44.3984L31.3594 44.2733L31.3226 44.332L26.5376 41.3456L26.4625 41.2023L26.4248 41.265ZM43.4456 46.33L43.2185 46.254L43.3578 46.4569L41.4482 49.2304L41.3666 49.3484L41.5032 49.3929L46.4878 51.0174L46.5536 51.1135L46.5972 51.0529L46.8227 51.1267L46.6865 50.9281L48.6335 48.2109L48.7178 48.0928L48.5803 48.0468L43.5559 46.3667L43.4882 46.2679L43.4456 46.33Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M15.665 27.4746L15.5151 27.6279L19.2399 31.44L19.2433 31.4431L19.2467 31.4462L23.4978 35.1094L23.6412 34.95L15.665 27.4746Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M38.2929 44.0995L33.2779 41.4679L33.2047 41.3409L33.1627 41.4073L32.9223 41.2812L33.0756 41.546L31.3652 44.2639L28.3594 38.4556L28.3789 38.4231L28.3205 38.3802L28.2436 38.2319L28.2053 38.2958L23.6538 34.9585L23.579 34.7929L23.5392 34.8625L19.3865 31.2842L19.2163 31.4034L21.7361 38.0187L21.7139 38.0576L21.7655 38.0953L21.835 38.2779L21.8875 38.1855L26.3139 41.4499L26.3098 41.4567L26.3716 41.495L26.4424 41.652L26.4872 41.5775L26.7223 41.7512L26.6991 41.6996L31.2083 44.5135L31.2036 44.5206L31.3028 44.5725L31.6309 44.7773L31.6071 44.7313L36.272 47.1649L36.2664 47.1733L36.3517 47.2094L36.4206 47.3284L36.4629 47.2644L36.7042 47.3905L36.6798 47.3484L41.4633 49.377L41.5322 49.4855L41.5745 49.4243L41.8109 49.5244L41.6644 49.2936L43.5759 46.518L43.6501 46.4105L43.5299 46.3589L38.4016 44.1539L38.3305 44.042L38.2929 44.0995Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6217 34.9355L23.5083 35.1172L33.1411 41.637L33.1476 41.6413L33.1532 41.6441L38.2796 44.3342L38.2833 44.336L38.287 44.3376L43.4456 46.5558L43.4759 46.4891L43.5414 46.3643L23.6217 34.9355Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M33.2918 41.6054L33.349 41.5142L33.2578 41.4574L28.2936 38.3607L27.9624 38.1541L28.1419 38.501L31.2614 44.5287L31.3473 44.6952L31.4471 44.5367L33.2918 41.6054Z"
        fill="#1F0231"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
      <path
        d="M23.6282 34.9397L23.5049 35.1149L28.1738 38.5381L28.1768 38.5405L28.1802 38.5427L33.1445 41.6393L33.2613 41.4595L23.6282 34.9397Z"
        fill="#D8CDE0"
        fillOpacity="0.1"
        stroke="#D8CDE0"
        strokeOpacity="0.1"
        strokeWidth="0.693723"
      />
    </svg>
  );
};

export default FooCenter;
